import { eventOnce } from '@power/power-components/utils/event-once';
import { app } from './app.module';
import template from './golfleet-app.component.html';
import './golfleet-app.component.scss';

class GolfleetAppController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      '$location',
      'deviceDetector',
      'urlApi',
      'tipoApp',
      'version',
      'playStore',
      'isProgressiveWebApp',
      'commonServices',
      'isTrustedWebActivity',
      '$transitions',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    $location,
    deviceDetector,
    urlApi,
    tipoApp,
    version,
    playStore,
    isProgressiveWebApp,
    commonServices,
    isTrustedWebActivity,
    $transitions,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      $location,
      deviceDetector,
      urlApi,
      tipoApp,
      version,
      playStore,
      isProgressiveWebApp,
      commonServices,
      isTrustedWebActivity,
      $transitions,
    });

    if (isTrustedWebActivity) {
      this.$ngRedux.dispatch({
        type: 'UPDATE_USAGE_INFORMATION',
        data: {
          applicationType: 'TWA',
          operationalSystem: deviceDetector.os,
        },
      });
      window.dataLayer.push({ application_type: 'TWA' });
    } else if (isProgressiveWebApp) {
      this.$ngRedux.dispatch({
        type: 'UPDATE_USAGE_INFORMATION',
        data: {
          applicationType: 'PWA',
          operationalSystem: deviceDetector.os,
        },
      });
      window.dataLayer.push({ application_type: 'PWA' });
    } else {
      this.$ngRedux.dispatch({
        type: 'UPDATE_USAGE_INFORMATION',
        data: {
          applicationType: 'Browser',
          operationalSystem: deviceDetector.os,
        },
      });
      window.dataLayer.push({ application_type: 'WEB' });
    }

    window.dataLayer.push({ gfbrowser_name: deviceDetector.browser });
    window.dataLayer.push({ gfbrowser_version: deviceDetector.browser_version });
    window.dataLayer.push({ gfop_system: deviceDetector.os });
    window.dataLayer.push({ gfapp_version: this.version });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        /* Session Storage */
        sessionData: store.session,
        token: store.session.token,
        isTrip: store.session.isTrip,
        isAdm: store.session.isAdm,
        userEmail: store.session.userEmail,
        hasUpdate: store.session.hasUpdate,
        keepSession: store.session.keepSession,
        themeColors: store.session.themeColors,
        isSingleSignon: store.session.isSingleSignon,
        hasRequiredUpdate: store.session.hasRequiredUpdate,
        privacyPolicyStatus: store.session.privacyPolicyStatus,
        onAdmNavigationMode: store.session.onAdmNavigationMode,
        headerIconAllowColorChange: store.session.headerIconAllowColorChange,
        needSelectAccount: store.session.needSelectAccount,
        hasChangeToken: store.session.hasChangeToken,
        application: store.session.application,
        loginId: store.session.loginId,

        /* State Storage */
        currentState: store.state.routeList[store.state.routeList.length - 1],
        applicationType: store.state.applicationType,
      }),
    )(this);

    if (this.hasChangeToken) {
      window.localStorage.removeItem('authorizationData');
      this.$ngRedux.dispatch({ type: 'SIGNOUT' });
      this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
      window.location.reload();
    }

    if (this.userEmail && process.env.NODE_ENV === 'production') {
      const params = {
        extra: {
          token: this.token,
          isTrip: this.isTrip,
          keepSession: this.keepSession,
          isSingleSignon: this.isSingleSignon,
          tipoApp: this.tipoApp,
          isAdm: this.isAdm,
        },
        user: {
          email: this.userEmail,
        },
      };

      window.dispatchEvent(
        new CustomEvent('updateSentryScope', { detail: params, bubbles: true, composed: true }),
      );
    }

    this.pwaBannerType = 'default';
    this.pwaInstallable = false;
    this.pwaInstallPrompt = null;

    this.navigator = window.navigator;
    this.signoutEvent = this.signout.bind(this);
    this.toggleLoaderEvent = this._onToggleLoader.bind(this);
    this.toggleAppInfoEvent = this._onToggleAppInfo.bind(this);
    this.toggleDownloadInfoEvent = this._onToggleDownloadInfo.bind(this);
    this.authorizedSigninEvent = this._onAuthorizedSignin.bind(this);

    // Atualizar as funcionalidades do usuário a cada 10 minutos
    this.intervalRealTimeDefault = 600000;
    this._refreshFunctionalitiesRealTime();

    // Verificar se o usuário possui comunicados não vistos a cada 10 minutos
    this.intervalNewAnnouncementsDefault = 600000;
    this._refreshNewAnnouncementsStatusRealTime();
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      /* Public */
      reload: this.reload.bind(this),
      signout: this.signout.bind(this),
      callPwaBanner: this.callPwaBanner.bind(this),
      toggleFilterMenu: this.toggleFilterMenu.bind(this),
      toggleNavigationMenu: this.toggleNavigationMenu.bind(this),
    });

    this.signoutEvent = this.$.addEventListener('signout', this.signoutEvent);
    this.toggleLoaderEvent = this.$.addEventListener('toggleLoader', this.toggleLoaderEvent);
    this.toggleAppInfoEvent = this.$.addEventListener('toggleAppInfo', this.toggleAppInfoEvent);
    this.authorizedSigninEvent = this.$.addEventListener(
      'authorizedSignin',
      this.authorizedSigninEvent,
    );

    this.$scope.$watch(() => this.token, this.__tokenChanged.bind(this));
    this.$scope.$watch(() => this.hasRequiredUpdate, this.__hasRequiredUpdateChanged.bind(this));
    this.$scope.$watch(
      () => this.privacyPolicyStatus?.needUserAccept,
      this.__privacyPolicyStatusChanged.bind(this),
    );
    this.$scope.$watch(
      () =>
        this.currentState ? this.currentState.userReportId || this.currentState.routeLink : null,
      this.__routeChanged.bind(this),
    );
    this.$scope.$watch(
      () =>
        this.currentState && this.currentState.stateConfig
          ? this.currentState.stateConfig.filterConfig
          : null,
      this.__filterConfigChanged.bind(this),
      true,
    );

    this.getParams = this.$location.search();
    if (this.token) {
      const typelog =
        this.keepSession && window.sessionStorage.getItem('logged') !== 'true'
          ? 'LoginPermanecerConectado'
          : 'AtualizacaoPagina';
      window.sessionStorage.setItem('logged', true);
      this._requestSessionData({ logtype: typelog });
      this._refreshFunctionalities();
    } else if (!this.getParams.login && !this.getParams.client_guid) {
      this.$.removeAttribute('unresolved');
      document.querySelector('#splash-container')?.remove();
    }

    window.addEventListener(
      'offline',
      () => {
        document.querySelector('power-toast#app-status').toggle(true);
      },
      false,
    );

    window.addEventListener(
      'online',
      () => {
        document.querySelector('power-toast#app-status').toggle(false);
      },
      false,
    );

    this._beforeInstallPwa();
  }

  $onDestroy() {
    this.__appStore();
    this.$.removeEventListener('signout', this.signoutEvent);
    this.$.removeEventListener('toggleLoader', this.toggleLoaderEvent);
    this.$.removeEventListener('authorizedSignin', this.authorizedSigninEvent);
  }
  /* */

  /* Public */
  reload() {
    this.$ngRedux.dispatch({ type: 'APP_UPDATED' });

    window.location.reload();
  }

  signout() {
    this._goToLogin();
  }

  callPwaBanner() {
    const pwaBanner = document.querySelector('.pwa-install-banner');
    const pwaBannerVideo = document.querySelector('.pwa-install-banner video');
    const pwaBannerContainer = document.querySelector('.pwa-install-banner-container');

    switch (this.pwaBannerType) {
      case 'iOS':
        pwaBanner.setAttribute('ios-banner', '');
        break;
      case 'Android':
        pwaBanner.setAttribute('android-banner', '');
        break;
      default:
        break;
    }

    pwaBannerVideo.play();
    pwaBannerContainer.setAttribute('open', '');
  }

  toggleFilterMenu() {
    this.$.querySelector('power-filter-menu').toggle();
  }

  toggleNavigationMenu() {
    this.$.querySelector('golfleet-navigation-menu').toggle();
  }

  getLoader() {
    return this.$.querySelector('power-loader');
  }
  /* */

  /* Private */
  async _installPwa() {
    this.pwaInstallPrompt.prompt();

    await this.pwaInstallPrompt.userChoice;

    this.pwaInstallPrompt = null;
    this._dismissPwaBanner();
  }

  _beforeInstallPwa() {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isMobile = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|windows phone|opera mini/i.test(
        userAgent,
      );
    };
    const isInStandaloneMode = () => {
      const twaApplication = document.referrer.startsWith('android-app://');
      const navigatorStandalone = navigator.standalone;
      const displayModeStandalone =
        window.matchMedia('(display-mode: standalone)').matches ||
        window.innerHeight / window.screen.height > 0.95;

      return twaApplication || navigatorStandalone || displayModeStandalone;
    };

    const pwaDontAskAgain = window.localStorage.getItem('pwaDontAskAgain');

    let getParams = {};
    let callPwaBanner = true;

    if (window.location.hash.split('?')[1]) {
      getParams = JSON.parse(
        `{"${window.location.hash.split('?')[1].split('&').join('","').split('=').join('":"')}"}`,
      );
    }

    if (window.isIframe() || getParams.single_sign_on || this.isSingleSignon) {
      return;
    }

    if (pwaDontAskAgain) {
      callPwaBanner = false;
    } else {
      window.localStorage.removeItem('pwaDontAskAgain');
    }

    // A2HS Override Capable (Desktop: Edge, Chrome / Mobile: WebView, Chrome, Samsung)
    if ('BeforeInstallPromptEvent' in window) {
      window.addEventListener(
        'appinstalled',
        () => {
          this.pwaInstallable = false;
        },
        { once: true },
      );

      window.addEventListener(
        'beforeinstallprompt',
        event => {
          event.preventDefault();

          this.pwaBannerType = 'default';
          this.pwaInstallable = true;
          this.pwaInstallPrompt = event;

          if (callPwaBanner) {
            this.callPwaBanner();
          }

          event.userChoice.then(choiceResult => {
            if (choiceResult === 'accepted') {
              this.pwaInstallable = false;
            }
          });
        },
        { once: this.pwaInstallable },
      );
    } else if (isMobile() && !isInStandaloneMode()) {
      if (isIos()) {
        this.pwaBannerType = 'iOS';
        this.pwaInstallable = true;
      } else {
        this.pwaBannerType = 'Android';
        this.pwaInstallable = true;
      }

      if (callPwaBanner) {
        this.callPwaBanner();
      }
    }
  }

  _dismissPwaBanner() {
    const videoElement = document.querySelector('.pwa-install-banner video');
    const pwaBannerContainer = document.querySelector('.pwa-install-banner-container');

    localStorage.setItem('pwaDontAskAgain', true);

    eventOnce({
      event: 'transitionend',
      element: pwaBannerContainer,
      action: () => {
        videoElement.pause();
        videoElement.currentTime = 0;
      },
    });

    pwaBannerContainer.removeAttribute('open');
  }

  _onAuthorizedSignin() {
    if (this.needSelectAccount) return;
    this._updateImages(this.sessionData);
    this.$.setAttribute('authorized', '');
    this._loadMenuNavigationList();
  }

  _onToggleLoader(evt) {
    this.$.querySelector('power-loader').toggle(evt.detail.showLoader);
  }

  _onToggleAppInfo() {
    if (this.$.querySelector('#popup-app-info').toggle)
      this.$.querySelector('#popup-app-info').toggle();
  }

  _onToggleDownloadInfo() {
    if (this.$.querySelector('#popup-download-info').toggle) {
      this.$.querySelector('#popup-download-info').toggle();
    }
  }

  _requestSessionData(logtype) {
    const fetchSessionData = () =>
      this.$http({
        url: `${this.urlApi}/MenuConfiguration/GetMenuConfiguration`,
        method: 'POST',
      });
    const insertLog = () =>
      this.$http({
        url: `${this.urlApi}/Log/CreateLogLoginAccess`,
        method: 'POST',
        data: Object.assign(logtype, {
          tipoApp: this.tipoApp,
          browser: this.deviceDetector.browser,
          browserVersion: this.deviceDetector.browser_version,
          applicationType: this.applicationType,
          operatingSystem: this.deviceDetector.os,
        }),
      });
    const validateFetchResponse = response => {
      if (response.status != 200) throw new Error(`Server Status ${response.status}`);
      return response.data;
    };
    const dispatchSession = sessionData => {
      this.dispatch({
        type: 'SIGNIN',
        data: {
          keepSession: this.keepSession,
          userPhoto: sessionData.data ? sessionData.data.userPhoto : '',
          headerIcon: sessionData.data ? sessionData.data.headerIcon : '',
          headerLogo: sessionData.data ? sessionData.data.headerLogo : '',
          tradeMarkIcon: sessionData.data ? sessionData.data.tradeMarkIcon : '',
          applicationName: sessionData.data ? sessionData.data.applicationName : '',
          applicationNameVisibility: sessionData.data
            ? sessionData.data.applicationNameVisibility
            : '',
          navigationList: sessionData.data ? sessionData.data.navigationList : [],
          adminNavigationList: sessionData.data ? sessionData.data.adminNavigationList : [],
        },
      });

      const {
        userReportQuantity,
        userReportSharedQuantity,
        userAutoSendQuantity,
        isVideoTelemetry,
        headerIconAllowColorChange,
      } = sessionData.data;
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_REPORT_QUANTITY',
        data: { userReportQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_REPORT_SHARED_QUANTITY',
        data: { userReportSharedQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_AUTO_SEND_QUANTITY',
        data: { userAutoSendQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_IS_VIDEOTELEMETRY',
        data: { isVideoTelemetry },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_HEADER_ICON_ALLOW_COLOR_CHANGE',
        data: { headerIconAllowColorChange },
      });

      this._updateImages(sessionData.data);
      this._updateThemeColors();
      this._loadMenuNavigationList();
    };
    fetchSessionData()
      .then(validateFetchResponse)
      .then(response => {
        dispatchSession(response);
        return insertLog();
      })
      .then(validateFetchResponse)
      .then(() => {
        if (!this.needSelectAccount && this.token) {
          this.$.setAttribute('authorized', '');
        }
        this.$.removeAttribute('unresolved');
        document.querySelector('#splash-container')?.remove();
      })
      .catch(() => {
        this._goToLogin();
      });
  }

  _goToLogin() {
    this.$.removeAttribute('authorized');
    this.$ngRedux.dispatch({ type: 'SIGNOUT' });
    this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
    this.$state.go('login');
    this.getLoader()?.toggle(false);
    this.$.removeAttribute('unresolved');
    document.querySelector('#splash-container')?.remove();
  }

  _updateImages(sessionData) {
    if (this.application) {
      let faviconSrc = '';

      if (this.application.toLowerCase() === 'golfleet') {
        faviconSrc = sessionData.isVideoTelemetry
          ? `/assets/images/favicon-golfleet-videotelemetry.png`
          : `/assets/images/favicon-golfleet-telemetry.png`;
      } else {
        faviconSrc = `/assets/images/favicon-golfleet-${this.application.toLowerCase()}.png`;
      }

      document.querySelector('[rel="shortcut icon"]').href = faviconSrc;
    }
    if (sessionData.logoHeight) {
      this.$.querySelector(
        '#shell-header-logo',
      ).style.backgroundSize = `auto ${sessionData.logoHeight}`;
    }

    document.documentElement.style.setProperty(
      '--user-photo',
      `url(${sessionData.userPhoto || '/assets/images/user-photo-placeholder.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--header-icon',
      `url(${sessionData.headerIcon || '/assets/images/telemetry-icon.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--header-logo',
      `url(${sessionData.headerLogo || '/assets/images/golfleet.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--trade-mark-icon',
      `url(${sessionData.tradeMarkIcon || '/assets/images/golfleet-icon.svg'})`,
    );
  }

  _updateThemeColors() {
    const hexToRgb = hex => {
      const curry =
        fn =>
        (...args) =>
          fn.bind(null, ...args);
      const compose =
        (...fns) =>
        x =>
          fns.reduceRight((v, f) => f(v), x);
      const exec = curry((regex, str) => regex.exec(str));
      const replace = curry((param, fn, str) => str.replace(param, fn));
      const sumMrgb = (m, r, g, b) => r + r + g + g + b + b;
      const rgbArrToRgb = rgbArr =>
        rgbArr
          ? { r: parseInt(rgbArr[1], 16), g: parseInt(rgbArr[2], 16), b: parseInt(rgbArr[3], 16) }
          : { r: 0, g: 0, b: 0 };
      const execRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
      const replaceRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      return compose(rgbArrToRgb, exec(execRegex), replace(replaceRegex, sumMrgb))(hex);
    };

    /* Common Colors */
    document.documentElement.style.setProperty(
      '--primary-color',
      this.themeColors.primaryColor || '#B400FF',
    );
    document.documentElement.style.setProperty(
      '--primary-text-color',
      this.themeColors.primaryTextColor || '#FFF',
    );
    /* Header Colors */
    document.documentElement.style.setProperty(
      '--header-color',
      this.themeColors.headerColor || '#FFF',
    );
    document.documentElement.style.setProperty(
      '--header-logo-color',
      this.themeColors.headerLogoColor || this.themeColors.primaryColor || '#B400FF',
    );
    document.documentElement.style.setProperty(
      '--header-text-color',
      this.themeColors.headerTextColor || '#949494',
    );
    document.documentElement.style.setProperty(
      '--header-badge-color',
      this.themeColors.headerBadgeColor || '#B400FF',
    );
    /* Navigation Colors */
    document.documentElement.style.setProperty(
      '--navigation-color',
      this.themeColors.navigationColor || '#9300D0',
    );
    document.documentElement.style.setProperty(
      '--navigation-dark-color',
      this.themeColors.navigationDarkColor || '#9300D0',
    );
    document.documentElement.style.setProperty(
      '--navigation-light-color',
      this.themeColors.navigationLightColor || '#B400FF',
    );
    document.documentElement.style.setProperty(
      '--navigation-selected-color',
      this.themeColors.navigationSelectedColor || 'rgba(0, 0, 0, 0.1)',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-color',
      this.themeColors.navigationTextColor || 'rgba(255, 255, 255, 0.66)',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-dark-color',
      this.themeColors.navigationTextDarkColor || 'rgba(0, 0, 0, 0.33)',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-light-color',
      this.themeColors.navigationTextLightColor || '#FFF',
    );
    document.documentElement.style.setProperty(
      '--navigation-text-selected-color',
      this.themeColors.navigationTextSelectedColor || '#FFF',
    );
    /* Admin Navigation Colors */
    document.documentElement.style.setProperty(
      '--navigation-admin-color',
      this.themeColors.navigationAdminColor || '#282828',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-dark-color',
      this.themeColors.navigationAdminDarkColor || '#202020',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-light-color',
      this.themeColors.navigationAdminLightColor || '#323232',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-text-color',
      this.themeColors.navigationAdminTextColor || 'rgba(255, 255, 255, 0.66)',
    );
    document.documentElement.style.setProperty(
      '--navigation-admin-text-light-color',
      this.themeColors.navigationAdminTextLightColor || '#FFF',
    );
    /* Calendar */
    const primaryRgb = hexToRgb(this.themeColors.primaryColor || '#B400FF');
    document.documentElement.style.setProperty(
      '--primary-faded-color',
      `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0.1)`,
    );
  }

  _openNavigationMenu() {
    document.querySelector('golfleet-navigation-menu').toggle();
  }

  _filterWarningToggle() {
    this.$.querySelector('#power-popup-filter-warning').toggle();
  }

  _refreshFunctionalitiesRealTime() {
    setInterval(() => {
      if (this.token && !this.needSelectAccount) {
        this._refreshFunctionalities();
      }
    }, this.intervalRealTimeDefault);
  }

  _refreshFunctionalities() {
    const popupAppInfo = this.$.querySelector('#popup-app-info');
    if (popupAppInfo && popupAppInfo.hasAttribute('open')) {
      return;
    }

    this.commonServices.genericRequest('User/GetUserFunctionalities').then(response => {
      if (response.status === 200 && response.data) {
        const { data } = response.data;
        if (data.qtdUser !== this.sessionData.qtdUser) {
          this.$ngRedux.dispatch({
            type: 'UPDATE_QUANTITY_USER',
            data: {
              qtdUser: data.qtdUser,
            },
          });
        }
        if (this.sessionData.permissions && data.permissions !== this.sessionData.permissions) {
          this.$ngRedux.dispatch({
            type: 'UPDATE_PERMISSIONS',
            data: {
              permissions: data.permissions ? data.permissions.split(',') : [],
            },
          });
        }
        if (this.sessionData.modules && data.modules !== this.sessionData.modules) {
          this.$ngRedux.dispatch({
            type: 'UPDATE_MODULES',
            data: {
              modules: data.modules ? data.modules.split(',') : [],
            },
          });
        }
      }
    });
  }

  async _refreshNewAnnouncementsStatusRealTime() {
    setInterval(async () => {
      if (this.loginId) {
        const checkForNewAnnouncements = this.commonServices.needCheckAnnouncements(
          this.token,
          this.needSelectAccount,
          this.loginId,
        );
        let hasNewAnnouncement = false;

        if (checkForNewAnnouncements) {
          hasNewAnnouncement = await this.commonServices.checkNewAnnouncementsLogin(this.loginId);
        }

        if (hasNewAnnouncement) {
          this.$transitions.onBefore(
            {},
            transition => {
              const nextState = transition.to();
              if (
                nextState &&
                nextState.name != 'dashboardAnnouncements' &&
                nextState.name != 'login'
              ) {
                return transition.router.stateService.target('dashboardAnnouncements');
              }
              return '';
            },
            { invokeLimit: 1 },
          );
        }
      }
    }, this.intervalNewAnnouncementsDefault);
  }

  _loadMenuNavigationList() {
    this.$.querySelector('golfleet-navigation-menu').loadMenuNavigationList();
  }

  _getNavigationMenuNode() {
    return this.$.querySelector('golfleet-navigation-menu');
  }
  /* */

  /* Observers */
  __filterConfigChanged() {
    if (
      !this.currentState ||
      !this.currentState.stateConfig ||
      !this.currentState.stateConfig.filterConfig ||
      this.currentState.stateConfig.filterConfig.length == 0
    ) {
      this.$.querySelector('#navigation-filter-toggle').setAttribute('disabled', '');
    } else this.$.querySelector('#navigation-filter-toggle').removeAttribute('disabled');
  }

  __routeChanged() {
    const navigationMenuNode = this._getNavigationMenuNode();
    const filterMenuNode = this.$.querySelector('power-filter-menu');
    if (
      this.currentState &&
      this.currentState.stateConfig &&
      this.currentState.stateConfig.isAdm != this.onAdmNavigationMode
    ) {
      this.$ngRedux.dispatch({ type: 'TOGGLE_NAVIGATION_MODE' });
    }
    if (navigationMenuNode.hasAttribute('open')) navigationMenuNode.toggle();
    if (filterMenuNode.hasAttribute('open')) filterMenuNode.toggle();
  }

  __tokenChanged(token) {
    if (!token) {
      const popupElement = this.$.querySelector('power-popup-defaulter-warning');
      popupElement.terminate();
      this._goToLogin();
    }
    this._updateThemeColors();
  }

  __hasRequiredUpdateChanged(hasRequiredUpdate) {
    if (hasRequiredUpdate) this._onToggleAppInfo();
  }

  __privacyPolicyStatusChanged(needUserAccept) {
    if (needUserAccept) {
      this._interval = setInterval(() => {
        const privacyPolicyPopup = this.$.querySelector('#golfleet-popup-privacy-policy');

        if (privacyPolicyPopup) {
          privacyPolicyPopup.show();
          clearInterval(this._interval);
        }
      }, 300);
    }
  }
  /* */
}

class GolfleetApp {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetAppController;
  }
}

export { GolfleetAppController, GolfleetApp };
app.component('golfleetApp', new GolfleetApp());
