import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '../golfleet-grid-fuel-module/golfleet-grid-fuel-module';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-consumption-by-segment.html';
import './golfleet-report-consumption-by-segment.scss';

class GolfleetReportConsumptionBySegmentController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.legacyEquipaments = {
      status: 0, // 0. loading, 1. ok, 2. no data, 3. error
      list: [],
      count: 0,
      highlight: true,
    };
    this.msgLegacyEquipament = ' equipamentos incompatíveis não constam nesta listagem';
  }

  /* Lifecycle */
  $onInit() {
    const filterEquipaments = this.stateConfig.filterConditions.filter(
      item => item.field == 'vehicles' || item.field == 'driver',
    );

    if (
      filterEquipaments.value &&
      (filterEquipaments.value.length === 0 ||
        filterEquipaments.value.length == filterEquipaments.total)
    ) {
      this.commonServices.getLegacyEquipaments('LegacyEquipaments/GetLegacyEquipaments').then(
        success => {
          if (success.data && success.data.hasError) this.legacyEquipaments.status = 3;
          else if (success.data.data && success.data.data.total > 0)
            Object.assign(this.legacyEquipament, {
              status: 1,
              list: success.data.data.equipaments,
              count: success.data.data.total,
              highlight: success.data.data.highlight,
            });
          else this.legacyEquipaments.status = 2;
        },
        () => {
          this.legacyEquipaments.status = 3;
          return null;
        },
      );
    } else this.legacyEquipaments.status = 0;

    super.$onInit();
  }
  /* */

  /* Private */
  _evtClickPopupLegacyEquipament() {
    this.$.querySelector('#popup-legacy-equipament').toggle();
  }
  /* */
}

class GolfleetReportConsumptionBySegment {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportConsumptionBySegmentController;
  }
}

angular
  .module('golfleet-report-consumption-by-segment', [
    'ngRedux',
    'ng-tippy',
    'power-fab',
    'power-toolbar-report',
    'power-dropdown',
    'golfleet-grid-fuel-module',
    'power-footer',
    'power-pagination',
    'power-popup',
  ])
  .component('golfleetReportConsumptionBySegment', new GolfleetReportConsumptionBySegment());
