import angular from 'angular';

import { PowerFilterController, PowerFilter } from '../power-filter/power-filter';
import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-filter-multi-select.html';
import './power-filter-multi-select.scss';

class PowerFilterMultiSelectController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi', '$ngRedux'];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor($element, $scope, $http, urlApi, $ngRedux) {
    super($element, $scope, $http, urlApi);

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
      });
    })(this);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setFilterValue: this.setFilterValue.bind(this),
    });

    this.description = this.description || 'Carregando...';

    if (!this.requestApi) {
      this.options = this.options.map(option => Object.assign(option, { selected: false }));
      this._hasSearch();
      this._initializeFilter();
    } else this._getOptions();
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }
  /* */

  /* Private */
  _hasSearch() {
    if (this.options.length > 7) {
      this.$.setAttribute('hasSearch', '');
      this.$scope.$watch(() => this._search, this._searchChanged.bind(this));
    }
  }

  _getOptions() {
    const data = this.useRouteTailToRequest
      ? { id: this.currentState.routeTail }
      : { ...this.requestData };
    return this.$http({
      url: `${this.urlApi}/${this.requestApi}`,
      method: 'POST',
      data,
    })
      .then(
        success => {
          this.options = Object.assign([], success.data.data);
          const { additionalOptions } = this;
          if (additionalOptions && additionalOptions.length > 0) {
            this.options = [...additionalOptions, ...this.options];
          }
        },
        () => {
          this.options = [];
        },
      )
      .finally(() => {
        this._hasSearch();
        this._initializeFilter();
      });
  }
  /* */
}

class PowerFilterMultiSelect extends PowerFilter {
  constructor() {
    super();
    Object.assign(this.bindings, {
      useRouteTailToRequest: '=?',
      additionalOptions: '=?',
    });
    this.controller = PowerFilterMultiSelectController;
    this.template = template;
  }
}

angular
  .module('power-filter-multi-select', ['infinite-scroll'])
  .component('powerFilterMultiSelect', new PowerFilterMultiSelect());
