import angular from 'angular';
import '@power/power-components/helpers/is-iframe/is-iframe';

import { GolfleetMapSuspectedAccidentHereProvider } from './providers/here/golfleet-map-suspected-accident.here-provider';
import { GolfleetMapSuspectedAccidentLeafletProvider } from './providers/leaflet/golfleet-map-suspected-accident.leaflet-provider';
import { PowerMapController } from '@power/power-components/components/power-map/power-map';

import template from './golfleet-map-suspected-accident.html';

class GolfleetMapSuspectedAccidentController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new GolfleetMapSuspectedAccidentHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new GolfleetMapSuspectedAccidentLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }
}

class GolfleetMapSuspectedAccident {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetMapSuspectedAccidentController;
  }
}

angular
  .module('golfleet-map-suspected-accident', ['power-map'])
  .component('golfleetMapSuspectedAccident', new GolfleetMapSuspectedAccident());
