import angular from 'angular';
import 'angular-easy-masks';

import template from './power-addon-request.html';
import './power-addon-request.scss';

class PowerAddonRequestController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', '$http', 'urlApi', 'addonServices'];
  }

  constructor($element, $scope, $ngRedux, $http, urlApi, addonServices) {
    Object.assign(this, { $: $element[0], $scope, $http, urlApi, addonServices });

    this.powerAddonRequestController = {
      fields: [
        {
          field: 'addon-request-name',
          label: 'Nome',
          type: 'input',
          value: null,
          size: 100,
          error: null,
          inputMaskFormat: null,
        },
        {
          field: 'addon-request-email',
          label: 'Endereço de email',
          type: 'input',
          value: null,
          size: 100,
          error: null,
          inputMaskFormat: null,
        },
        {
          field: 'addon-request-phone',
          label: 'Telefone',
          type: 'input',
          value: null,
          size: 100,
          error: null,
          inputMaskFormat: '(99)09999-9999',
        },
      ],
    };

    this.dayOfWeekOptions = [
      { value: 1, description: 'DOM', selected: false },
      { value: 2, description: 'SEG', selected: false },
      { value: 3, description: 'TER', selected: false },
      { value: 4, description: 'QUA', selected: false },
      { value: 5, description: 'QUI', selected: false },
      { value: 6, description: 'SEX', selected: false },
      { value: 7, description: 'SAB', selected: false },
    ];

    this.periodsOptions = [
      { value: 1, description: 'Período da manhã', selected: false },
      { value: 2, description: 'Período da tarde', selected: false },
      { value: 3, description: 'Período da noite', selected: false },
    ];

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({ session: behavior.session, state: behavior.state }),
    )(this);

    this.fillUserData();
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      clear: this.clear.bind(this),
      actionEventHandler: this.actionEventHandler.bind(this),
      showToast: this.showToast.bind(this),
    });
  }

  $onDestroy() {
    this.__appInheritBehavior();
  }
  /* Lifecycle */

  clear() {
    this.periodsOptions.forEach(option => {
      option.selected = false;
    });
    this.dayOfWeekOptions.forEach(option => {
      option.selected = false;
    });

    this.powerAddonRequestController.fields.forEach(field => {
      field.value = null;
      field.error = null;
    });

    this.fillUserData();

    this.$.dispatchEvent(new CustomEvent('clear'));
  }

  fillUserData() {
    this.powerAddonRequestController.fields[0].value = this.session.userName;
    this.powerAddonRequestController.fields[1].value = !this.session.isSingleSignon
      ? this.session.userEmail
      : '';
  }

  actionEventHandler(action) {
    if (action == 'cancel') {
      this.clear();
    } else if (action == 'send') {
      const emptyValues = [];

      this.powerAddonRequestController.fields.forEach(field => {
        if (!field.value) {
          field.error = true;
          emptyValues.push(field);
        }
      });

      if (emptyValues.length > 0) {
        this.showToast('Por favor verifique os campos');
      } else {
        const getSessionData = session => ({
          userId: session.userId,
          userName: session.userName,
          userEmail: session.userEmail,
          companyName: session.companyName,
        });

        const getStateData = state => ({
          routeList: state.routeList.map(route => ({
            routeName: route.routeName,
            routeLink: route.routeLink,
            routeTail: route.routeTail,
            stateConfig: route.stateConfig,
          })),
        });

        const session = getSessionData(this.session);
        const state = getStateData(this.state);

        const daysOfWeekSelected = this.dayOfWeekOptions
          .filter(x => x.selected)
          .map(x => x.description);

        const periodSelected = this.periodsOptions.filter(x => x.selected).map(x => x.description);

        const description = `Entrar em contato com o cliente. Dia(s): ${
          daysOfWeekSelected.length === 0 ? 'qualquer dia' : daysOfWeekSelected.join(', ')
        }. Período(s): ${
          periodSelected.length === 0 ? 'qualquer período' : periodSelected.join(', ')
        }`;

        let services = [];
        if (this.serviceList) {
          if (this.serviceList.length > 1) {
            services = this.serviceList
              .filter(service => service.selected)
              .map(service => ({
                serId: service.serId,
                service: service.service,
              }));
          } else {
            services = this.serviceList;
          }
        } else {
          services = [
            {
              service: state.routeList[state.routeList.length - 1].stateConfig.serviceName,
              serId: state.routeList[state.routeList.length - 1].stateConfig.serviceId,
            },
          ];
        }

        const data = {
          userName: this.powerAddonRequestController.fields[0].value,
          userEmail: this.powerAddonRequestController.fields[1].value,
          telephone: this.powerAddonRequestController.fields[2].value,
          companyName: session.companyName,
          description,
          services,
        };

        this.$.dispatchEvent(new CustomEvent('beforeSubmit'));
        this.addonServices.requestAddon(data).then(success => {
          let msg = '';
          if (success.status && success.data.data) {
            msg = 'Agendamento efetuado com sucesso!';
          } else {
            msg = 'Ops! Ocorreu um erro. Tente novamente.';
          }
          this.showToast(msg);
          this.clear();
          this.$.dispatchEvent(new CustomEvent('afterSubmit'));
        });
      }
    }
  }

  showToast(message) {
    this.$.dispatchEvent(
      new CustomEvent('showToast', {
        detail: { message },
      }),
    );
  }

  _removeErrorAttr(item) {
    item.error = false;
  }

  _toggleDayOfWeekOption(option) {
    option.selected = !option.selected;
  }

  _togglePeriodsOption(option) {
    option.selected = !option.selected;
  }
}

class PowerAddonRequest {
  constructor() {
    this.template = template;
    this.bindings = {
      serviceList: '=?',
    };
    this.controller = PowerAddonRequestController;
  }
}

angular
  .module('power-addon-request', ['wt.easy'])
  .component('powerAddonRequest', new PowerAddonRequest());

export { PowerAddonRequestController };
