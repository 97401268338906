import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '../golfleet-grid-workshop/golfleet-grid-workshop';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '../golfleet-popup-workshop/golfleet-popup-workshop';
import '@power/power-components/components/power-header-selector/power-header-selector';
import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-workshops.html';
import './golfleet-report-workshops.scss';

class GolfleetReportWorkshopsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'workshopServices',
      '$rootScope',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    workshopServices,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { workshopServices, $rootScope });

    this.cantDeleteWorkshops = [];
    this.deleteWorkshops = [];
    this.onDeleteEvent = this._deleteSelectedItens.bind(this);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.addEventListener('delete', this.onDeleteEvent);
  }

  _callAction(action) {
    switch (action.actionType) {
      case 'add':
        this.$.querySelector('golfleet-popup-workshop').setDataSet({});
        this.$.querySelector('golfleet-popup-workshop').togglePopup();
        break;
      case 'delete':
        this.cantDeleteWorkshops = [];
        this.deleteWorkshops = [];

        this._showLoader(true);

        this.workshopServices
          .validateWorkshopDelete({ id: JSON.stringify(this.selectedRows.map(row => row.id)) })
          .then(success => {
            if (success.status && success.status !== 200) {
              this.toastText = 'Não foi possivel Excluir.';
              this.$.querySelector('power-toast#report-toast').toggle(true);
            }
            const cantDeleteWorkshopsIds = success.data.data;

            this.cantDeleteWorkshops = this.selectedRows.filter(item =>
              cantDeleteWorkshopsIds.includes(item.id),
            );
            this.deleteWorkshops = this.selectedRows.filter(
              item => !cantDeleteWorkshopsIds.includes(item.id),
            );

            if (this.deleteWorkshops.length > 0) {
              this.$.querySelector('power-popup-delete').setData({
                deleteMethod: action.actionMethod,
                objects: this.deleteWorkshops.map(row => ({
                  id: row.id,
                  description: row[this.stateConfig.gridConfig.descriptionField],
                  value: row.id,
                })),
              });
              this.$.querySelector('power-popup-delete').toggle();
            } else if (this.cantDeleteWorkshops.length > 0) {
              this._evtClickPopupCantDeleteWorkshop();
            }
          })
          .finally(() => {
            this._showLoader(false);
          });
        break;
      default:
        break;
    }
  }

  _evtClickPopupCantDeleteWorkshop() {
    this.searchTextWorkshop = '';
    this.$.querySelector('#popup-cant-delete-workshop').toggle();
  }

  _deleteSelectedItens(evt) {
    this.$.querySelector('power-popup-delete').toggle();
    this._showLoader(true);

    const payload = {
      objects: evt.detail.objects,
    };

    return this.workshopServices.deleteWorkshop(evt.detail.method, payload).then(
      success => {
        if (!success.data.hasError) {
          this.toastText = 'Excluido com sucesso.';

          if (this.cantDeleteWorkshops.length > 0) {
            this._evtClickPopupCantDeleteWorkshop();
          }

          this.requestDataset();
        } else this.toastText = 'Não foi possivel Excluir.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
      () => {
        this._showLoader(false);
        this.toastText = 'Não foi possivel Excluir.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _showLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __reloadDataSet() {
    this.requestDataset();
  }
}

class GolfleetReportWorkshops {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportWorkshopsController;
  }
}

angular
  .module('golfleet-report-workshops', [
    'power-fab',
    'power-toolbar-report',
    'power-dropdown',
    'golfleet-grid-workshop',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-popup-delete',
    'golfleet-popup-workshop',
    'power-header-selector',
    'power-toast',
  ])
  .component('golfleetReportWorkshops', new GolfleetReportWorkshops());
