import angular from 'angular';
import '../../helpers/is-iframe/is-iframe';

import { PowerMapViolationHereProvider } from './providers/here/power-map-violation.here-provider';
import { PowerMapViolationLeafletProvider } from './providers/leaflet/power-map-violation.leaflet-provider';
import { PowerMapController } from '../power-map/power-map';

import template from './power-map-violation.html';

class PowerMapViolationController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapViolationHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapViolationLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  /* Public */
  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
    icon,
  }) {
    return this.provider.renderDataset({
      dataset,
      layerName,
      type,
      useCluster,
      clusterColor,
      icon,
    });
  }
  /**/
}

class PowerMapViolation {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerMapViolationController;
  }
}

angular
  .module('power-map-violation', ['power-map'])
  .component('powerMapViolation', new PowerMapViolation());

export { PowerMapViolationController };
