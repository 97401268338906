import angular from 'angular';

import template from './power-period-preview.html';
import './power-period-preview.scss';

class PowerPeriodPreviewController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$http', 'urlApi'];
  }

  constructor($element, $scope, $rootScope, $http, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $rootScope,
      $http,
      urlApi,
    });

    this.periodMap = [
      {
        description: 'DOM',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'SEG',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'TER',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'QUA',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'QUI',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'SEX',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
      {
        description: 'SAB',
        bitArray: [],
        periods: [[0, 0]],
        extensiveDescription: '',
      },
    ];

    this.numberOfPeriods = 48;
    this.hoursArray = [];
  }

  /* Lifecycle */
  $onInit() {
    this.period.items.forEach((value, index) => {
      this._computeBitArray(index, value);
      this._computePeriod(index);
    });

    this.hoursArray = this._initializeHoursArray();
    this._generateExtensiveDescription();
  }

  _computeBitArray(weekdayIndex, value) {
    const bitArray = [];
    for (let bitIndex = 0; bitIndex <= this.numberOfPeriods; bitIndex += 1) {
      // eslint-disable-next-line no-restricted-properties
      const indexValue = Math.pow(2, this.numberOfPeriods - bitIndex);
      if (value >= indexValue) {
        value -= indexValue;
        bitArray[bitIndex] = 1;
        bitArray[bitIndex - 1] = 1;
      } else bitArray[bitIndex] = 0;
    }
    this.periodMap[weekdayIndex].bitArray = Object.assign([], bitArray);
  }

  _computePeriod(weekdayIndex) {
    let onPeriod = false;
    let openPeriodIndex = 0;
    const periods = this.periodMap[weekdayIndex].bitArray.reduce(
      (acc, periodBit, periodIndex, arr) => {
        if (!onPeriod && periodBit) {
          onPeriod = true;
          openPeriodIndex = periodIndex;
        } else if (onPeriod && !periodBit) {
          onPeriod = false;
          acc.push([openPeriodIndex, periodIndex - 1]);
        } else if (onPeriod && periodIndex == arr.length - 1) {
          onPeriod = false;
          acc.push([openPeriodIndex, periodIndex]);
        }
        return acc;
      },
      [],
    );
    this.periodMap[weekdayIndex].periods = !periods.length ? [[0, 0]] : periods;
  }

  _initializeHoursArray() {
    const hoursArray = [];

    for (let index = 0; index <= this.numberOfPeriods; index++) {
      let hours;

      if (this.numberOfPeriods > 24) {
        hours = `${index < 20 ? '0' : ''}${parseInt(index / 2)}:${index % 2 ? '30' : '00'}`;
      } else {
        hours = `${index < 20 ? '0' : ''}${parseInt(index / 2)}:00`;
      }

      if (hours === '24:00') {
        hours = '23:59';
      }

      hoursArray.push(hours);
    }

    return hoursArray;
  }

  _generateExtensiveDescription() {
    // this.periodMap
    // this.hoursArray

    this.periodMap.forEach(day => {
      let count = 0;
      let dayExtensiveDescription = '';

      day.bitArray.forEach((bit, index, array) => {
        if (!bit || index == array.length - 1) {
          if (count > 0 || index == array.length - 1) {
            if (dayExtensiveDescription) {
              if (count > 0) {
                dayExtensiveDescription = dayExtensiveDescription.concat(
                  `; ${this.hoursArray[index - count]} às ${this.hoursArray[index - 1]}`,
                );
              }
            } else if (count == 0 && index == array.length - 1 && !dayExtensiveDescription) {
              dayExtensiveDescription = 'Sem expediente';
            } else if (count !== 0 && index == array.length - 1) {
              const ini = this.hoursArray[index - count];
              const end = this.hoursArray[index];
              dayExtensiveDescription = `Das ${ini} às ${end}`;
            } else {
              const ini = this.hoursArray[index - count];
              const end = this.hoursArray[index - 1];
              dayExtensiveDescription = `Das ${ini} às ${end}`;
            }
            count = 0;
          }
        } else {
          count += 1;
        }
      });

      day.extensiveDescription = dayExtensiveDescription;
    });
  }
}

class PowerPeriodPreview {
  constructor() {
    this.template = template;
    this.bindings = {
      period: '=?',
    };
    this.controller = PowerPeriodPreviewController;
  }
}

angular
  .module('power-period-preview', [])
  .component('powerPeriodPreview', new PowerPeriodPreview());
