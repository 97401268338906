import 'ng-redux';
import 'angular-sanitize';
import 'moment/src/locale/pt-br';
import { isValidEmail } from '../../utils/email-util.js';

import { PowerGridController } from '../power-grid/power-grid';
import '../../directives/ng-resize/ng-resize';

import './power-grid-driver.scss';

class PowerGridDriverController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      'driverServices',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    driverServices,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, {
      $: $element[0],
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      driverServices,
      $ngRedux,
    });

    this.popupConfig = [
      {
        title: 'Condutor sem email',
        subTitle: 'Por favor informe o email deste condutor',
        action: 'primaryBtn',
      },
      {
        title: 'Convite enviado',
        subTitle:
          'O convite para a instalação do App Power Driver foi enviado para o seguinte email',
        action: 'close',
      },
    ];

    this.driverId = null;
    this.driverEmail = '';
    this.driverEmailWarning = '';
    // 0 - Cadastrar email | 1 - Mensagem de confirmação Cadastro
    this.popupContent = 0;
    this.privacyPolicyStatus = null;
    this.powerIdInvite = false;
  }

  /* Lifecycle */
  /* */

  /* Public */
  /* */

  /* Private */
  _selectRow(column, row) {
    if (column.type === 'Action') {
      return this._hasLink(column, row);
    }
    return super._selectRow(column, row);
  }

  _hasLink(column, row) {
    if (!column.link) return false;
    if (column.action) return !row[column.action.field];
    return false;
  }

  _evtCallPopupEmail() {
    this.$.querySelector('#popup-add-email').toggle();
  }

  _toggleLoader(show) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: show },
        bubbles: true,
        composed: true,
      }),
    );
  }
  /* */

  /* Observers */
  /* */
}

export { PowerGridDriverController };
