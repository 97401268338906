/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-period/power-period';
import '../golfleet-programmed-block/golfleet-programmed-block';
import '@power/power-components/directives/ng-tippy/ng-tippy';

import { PowerFormFleetPolicyController } from '@power/power-components/components/power-form-fleet-policy/power-form-fleet-policy';

import template from './golfleet-form-fleet-policy.html';
import './golfleet-form-fleet-policy.scss';

class GolfleetFormFleetPolicyController extends PowerFormFleetPolicyController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
    ];
  }

  /**
   * Creates an instance of GolfleetFormFleetPolicyController.
   * @memberof GolfleetFormFleetPolicyController
   */

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    urlApi,
  ) {
    super(
      $element,
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi
    );
  }

  _toggleHelperModal(modal, sectionHelperConfig) {
    if (sectionHelperConfig) {
      this.sectionHelperConfig = {
        ...sectionHelperConfig,
        shouldRequest: sectionHelperConfig.id !== this.sectionHelperConfig.id,
      };
    }

    this.$.querySelector(`golfleet-form-fleet-policy > #popup-policy-helper-${modal}`).toggle();
  }

  _confirmCrudForm() {
    const parsedData = {};
    const emptyValues = [];
    const limitValuesInvalid = [];

    parsedData.id = this.policyId;
    parsedData.name = '';
    parsedData.description = '';
    parsedData.items = this.formViewData.reduce((acc, mainSections) => {
      if (mainSections.sections && mainSections.sections.length > 0) {
        mainSections.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.forEach(item => {
              let value = '';
              if (item.field == 'fleetPolicyName') {
                if (!item.value) {
                  item.error = true;
                  emptyValues.push(item);
                }
                parsedData.name = item.value;
              } else if (item.field == 'fleetPolicyDescription') {
                if (!item.value) {
                  item.error = true;
                  emptyValues.push(item);
                }
                parsedData.description = item.value;
              } else if (item.field == 'period') {
                value = this.$.querySelector('power-period').getValue().join();
                item.hasUpdated = item.value.toString() !== value;
              } else if (item.type == 'combobox') {
                const selectedValue = item.options.filter(option => option.selected);
                if (selectedValue.length == 0 && item.isRequired) {
                  item.error = true;
                  emptyValues.push(item);
                } else {
                  value = selectedValue[0].value;
                }
              } else if (item.type == 'number') {
                if (
                  !!item.simpleValue &&
                  ((item.minValue && item.simpleValue < item.minValue) ||
                    (item.maxValue && item.simpleValue > item.maxValue))
                ) {
                  item.limitValuesInvalid = true;
                  limitValuesInvalid.push(item);
                }

                if (!item.simpleValue && item.isRequired) {
                  item.error = true;
                  emptyValues.push(item);
                } else {
                  value = item.simpleValue;
                }
              }
              if (item.field == 'programmedBlock') {
                value = this.modules.includes('BLOQUEIO_PROGRAMADO')
                  ? this.$.querySelector('golfleet-programmed-block').getValue()
                  : 'Desabilitado';
                item.hasUpdated = item.value.toString() !== value;
                if (item.hasUpdated && item.value !== 'Desabilitado') {
                  this.arrayValue = value.split(';').map(itemarray => itemarray);
                  if (this.arrayValue[0] === '1' && this.arrayValue[6] === '0000000') {
                    item.error = true;
                    this.$.querySelector('golfleet-programmed-block').validateHasEmptyParams();
                    emptyValues.push(item);
                  }
                }
              }

              const currentItem = this.staticFormData.fleetPolicyItems.find(
                staticItem => staticItem.objectId == item.objectId,
              );

              if (currentItem && item.type == 'combobox') {
                const [filteredItem] = item.options.filter(
                  filteredOption => filteredOption.selected === true,
                );
                item.hasUpdated = currentItem.value !== filteredItem.value;
              } else if (currentItem && item.type == 'number') {
                item.hasUpdated = item.simpleValue !== currentItem.simpleValue;
              }
              if (item.field != 'fleetPolicyName' && item.field != 'fleetPolicyDescription') {
                acc.push({
                  configurationId: item.id,
                  value,
                  hasUpdated: item.hasUpdated,
                });
              }
            });
          }
        });
      }

      return acc;
    }, []);

    if (emptyValues.length > 0) {
      this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
        },
      });
    } else if (limitValuesInvalid.length > 0) {
      this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações precisam ser preenchidas respeitando o limite mínimo e máximo de valores.',
        },
      });
    } else {
      this._upsertFleetPolicy(parsedData);
    }
  }
}

class GolfleetFormFleetPolicy {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormFleetPolicyController;
  }
}

angular
  .module('golfleet-form-fleet-policy', [
    'ng-tippy',
    'power-toast',
    'power-period',
    'golfleet-programmed-block',
  ])
  .component('golfleetFormFleetPolicy', new GolfleetFormFleetPolicy());
