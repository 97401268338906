class powerInput extends HTMLInputElement {
  get decimalLength() {
    return parseInt(this.getAttribute('decimallength'), 10);
  }

  set decimalLength(val) {
    this.setAttribute('decimallength', val || '');
  }

  get numberLength() {
    return parseInt(this.getAttribute('numberlength'), 10);
  }

  set numberLength(val) {
    this.setAttribute('numberlength', val || '');
  }

  get type() {
    return this.getAttribute('type');
  }

  set type(val) {
    this.setAttribute('type', val);
  }

  connectedCallback() {
    this.addEventListener('input', this._valueChanged);
  }

  _valueChanged() {
    switch (this.type) {
      case 'number':
        if (this.value.length > this.numberLength) {
          const resultValue = this.value.split('');
          resultValue.splice(this.numberLength, resultValue.length);
          this.value = resultValue.join('');
        }
        break;
      case 'decimal': {
        this.value = this.value.split('.').join('');
        const values = this.value.split('');
        const valuesLength = values.length;
        for (let index = 0; index < valuesLength; index += 1) {
          if (values[0] === '0') {
            values.splice(0, 1);
          } else {
            break;
          }
        }
        if (values.length === 0) this.value = null;
        else if (values.length == this.decimalLength) values.splice(0, 0, '0.');
        else if (values.length < this.decimalLength) {
          const fillLength = values.length - this.decimalLength + 1;
          values.splice(0, 0, '0.');
          for (let index = 0; index <= fillLength; index += 1) {
            values.splice(1, 0, '0');
          }
        } else if (this.numberLength) {
          values.splice(this.numberLength + this.decimalLength, values.length);
          values.splice(-this.decimalLength, 0, '.');
        } else values.splice(-this.decimalLength, 0, '.');
        this.value = values.join('');
        break;
      }
      default:
        break;
    }
  }
}

window.customElements.define('power-input', powerInput, { extends: 'input' });
