import tippy from 'tippy.js';
import 'ng-redux';
import 're-tree';
import 'ua-device-detector';
import 'ng-device-detector';

import { ImportRouteConfig } from '../../utils/get-route-config';

class PowerLoginController {
  static get $inject() {
    return [
      '$element',
      '$timeout',
      '$ngRedux',
      '$state',
      '$http',
      'urlApi',
      'tipoApp',
      'deviceDetector',
      'commonServices',
      '_applications',
      '$scope',
    ];
  }

  otpError = false;

  constructor(
    $element,
    $timeout,
    $ngRedux,
    $state,
    $http,
    urlApi,
    tipoApp,
    deviceDetector,
    commonServices,
    _applications,
    $scope,
  ) {
    Object.assign(this, {
      $: $element[0],
      $timeout,
      $ngRedux,
      $state,
      $http,
      urlApi,
      tipoApp,
      deviceDetector,
      commonServices,
      _applications,
      $scope,
    });

    this._appBehavior = $ngRedux.connect(behavior =>
      Object({
        token: behavior.session.token,
        isSingleSignon: behavior.session.isSingleSignon,
        state: behavior.state,
        needSelectAccount: behavior.session.needSelectAccount || false,
        keepSession: behavior.session.keepSession,
        application: behavior.session.application,
      }),
    )(this);

    this.needSelectAccount = this.keepSession ? false : this.needSelectAccount;

    this.$ngRedux.dispatch({
      type: 'SIGNIN',
      data: { needSelectAccount: this.needSelectAccount },
    });

    this.keepSessionKeyEvent = this._keepSessionKey.bind(this);
    this.resetPassConfirmEvent = this._resetPassConfirm.bind(this);

    this.tokenToChangePassword = '';

    if (window.location.hash.split('?')[1]) {
      this.getParams = JSON.parse(
        `{"${window.location.hash.split('?')[1].split('&').join('","').split('=').join('":"')}"}`,
      );
    } else {
      this.getParams = {};
    }
    if (!this.isSingleSignon && this.getParams.single_sign_on)
      this.$ngRedux.dispatch({
        type: 'SINGLE_SIGNON',
        data: { isSingleSignon: true },
      });
    if (this.getParams.single_sign_on) {
      this.$.setAttribute('single-signon', '');
      this._changeView('request-single-signon');
    } else if (this.isSingleSignon) {
      this.$.setAttribute('single-signon', '');
      this._changeView('refresh-single-signon');
    }

    this.isMobile = window.innerWidth < 768;
    this.os = deviceDetector.os;
    this.browser = deviceDetector.browser;
    this.browserVersion = deviceDetector.browser_version.split('.')[0];
    this.osBits = deviceDetector.raw.userAgent.includes('x64') ? '64' : '32';
    this.osFolder = this.os == 'windows' ? 'Windows' : this.os == 'mac' ? 'Mac' : 'Linux';
    this.fileExtension = this.os == 'windows' ? '.exe' : this.os == 'mac' ? '.dmg' : '.deb';
    this.fileSizeDescription = (() => {
      let size = '27 MB';
      if (this.osBits == '64') {
        if (this.os == 'windows') {
          size = '32 MB';
        } else if (this.os == 'mac') {
          size = '39 MB';
        }
      }
      return size;
    })();

    const tippyOpt = { trigger: 'manual' };

    this.tippyLogin = tippy(this.$.querySelector('.tippy-js-login'), tippyOpt);
    this.tippyRecover = tippy(this.$.querySelector('.tippy-js-recover'), tippyOpt);
    this.tippyReset = tippy(this.$.querySelector('.tippy-js-reset'), tippyOpt);

    this.toastObj = { text: '' };

    const viewContainer = this.$.querySelector('#view-container');
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini|Edge|ms-edge,ms-edge-beta/i.test(
        navigator.userAgent,
      )
    ) {
      switch (this.browser) {
        case 'chrome':
          if (this.browserVersion < 64) viewContainer.setAttribute('view', 'download');
          else if (this.getParams.login || this.getParams.client_guid) {
            this._directLogin(this.getParams);
          }
          break;
        case 'firefox':
          if (this.browserVersion < 62) viewContainer.setAttribute('view', 'download');
          else if (this.getParams.login || this.getParams.client_guid) {
            this._directLogin(this.getParams);
          }
          break;
        case 'safari':
          if (this.browserVersion < 11) viewContainer.setAttribute('view', 'download');
          else if (this.getParams.login || this.getParams.client_guid) {
            this._directLogin(this.getParams);
          }
          break;
        case 'ms-edge':
          if (this.browserVersion < 79) viewContainer.setAttribute('view', 'download');
          else if (this.getParams.login || this.getParams.client_guid) {
            this._directLogin(this.getParams);
          }
          break;
        case 'Edge':
          if (this.browserVersion < 79) viewContainer.setAttribute('view', 'download');
          else if (this.getParams.login || this.getParams.client_guid) {
            this._directLogin(this.getParams);
          }
          break;
        case 'ms-edge-beta':
          if (this.browserVersion < 79) viewContainer.setAttribute('view', 'download');
          else if (this.getParams.login || this.getParams.client_guid) {
            this._directLogin(this.getParams);
          }
          break;
        default:
          viewContainer.setAttribute('view', 'download');
          break;
      }
    } else {
      switch (this.browser) {
        case 'safari':
          if (this.browserVersion <= 11) viewContainer.setAttribute('view', 'download');
          break;
        case 'ms-edge':
          if (this.browserVersion <= 79) viewContainer.setAttribute('view', 'download');
          break;
        case 'Edge':
          if (this.browserVersion <= 79) viewContainer.setAttribute('view', 'download');
          break;
        case 'ms-edge-beta':
          if (this.browserVersion <= 79) viewContainer.setAttribute('view', 'download');
          break;
        default:
          if (this.getParams.login || this.getParams.client_guid) {
            this._directLogin(this.getParams);
          } else viewContainer.setAttribute('view', 'login');
      }
    }
  }

  /* Lifecycle */
  $onInit() {
    this.$.querySelector('power-single-checkbox').addEventListener(
      'keydown',
      this.keepSessionKeyEvent,
    );
    this.$.querySelector('#email-reset-confirm').addEventListener(
      'keydown',
      this.resetPassConfirmEvent,
    );

    this._configElementSwitchAccount();

    this.$timeout(() => {
      if (this.token) {
        if (this.needSelectAccount) {
          this.$.querySelector('#section-switch-account #power-switch-account').dispatchEvent(
            new CustomEvent('getSwitchAccounts', { bubbles: true, composed: true }),
          );
        } else if (this.keepSession) {
          document
            .querySelector('#app-component')
            .dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
        }
        const { nextRouteName } = this.state;
        this.state.nextRouteName = null;
        let hasNewAnnouncement = false;

        if (this.loginId) {
          const checkForNewAnnouncements = this.commonServices.needCheckAnnouncements(
            this.token,
            this.needSelectAccount,
            this.loginId,
          );

          if (checkForNewAnnouncements) {
            hasNewAnnouncement = this.commonServices.checkNewAnnouncementsLogin(this.loginId);
          }
        }

        if (nextRouteName) {
          this.$state.go(nextRouteName);
        } else if (hasNewAnnouncement) {
          this.$state.go('dashboardAnnouncements');
        } else {
          this.$state.go('dashboard');
        }
      }
      this.$.querySelector('#login-card-email').focus();
    });

    this.$scope.$on('opt_register_finished', (event, confirm) => {
      if (confirm) {
        this._changeRouteAfterLogin(this.resonseData);
      }
    });
  }

  $onDestroy() {
    this._appBehavior();
    this.$.removeEventListener('keydown', this.keepSessionKeyEvent);
    this.$.removeEventListener('keydown', this.eventResetPassConfirm);
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _directLogin(getParams) {
    if (getParams.login && getParams.pass && getParams.usuID)
      this._login(getParams.login, getParams.pass, false, [getParams.usuID]);
    else if (getParams.client_guid && getParams.user_guid && getParams.single_sign_on) {
      this._login(getParams.client_guid, getParams.user_guid, false, [0, getParams.single_sign_on]);
    }
  }

  _changeView(view) {
    /* views:
      - login
      - recover
      - reset
      - download
      - request-single-signon
      - fail-request-single-signon
    */
    this.$.querySelector('#view-container').setAttribute('view', view);
    if (view == 'recover') this.emailRecover = this.email;
    if (view == 'login') this.$timeout(() => this.$.querySelector('#login-card-email').focus());
  }

  _btnRequest() {
    const type = this.$.querySelector('#view-container').getAttribute('view');
    if (this._validateBeforeSend(type)) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      if (type == 'login') this._login(this.email, this.password, this.keepSession);
      else if (type == 'recover') this._recover(this.emailRecover);
      else if (type == 'reset') this._reset(this.email, this.resetPasswordConfirm);
    }
  }

  _keepSessionKey(evt) {
    if (evt.key == 'Enter') this._login(this.email, this.password, this.keepSession);
  }

  _resetPassConfirm(evt) {
    if (
      (evt.ctrlKey || evt.metaKey) &&
      (evt.keyCode == 67 || evt.keyCode == 86 || evt.keyCode == 91)
    ) {
      return evt.preventDefault();
    }
    return null;
  }
  /* */

  /* Protected */

  _loginPasswordOnchange() {
    this.contentStrongForce = '';

    const classStrongPass = this.$.querySelector('#class-strong');
    const stringPass = this.$.querySelector('#email-reset').value;

    const hasNumber = /[0-9]/.test(stringPass);
    const hasLetterInPassword = /[a-z]/.test(stringPass);
    const hasLetterUppercase = /[A-Z]/.test(stringPass);

    const hasSpecialCharacter = /(?=.*[!@#\\$%\\^&\\*])/.test(stringPass);

    const strongRegularExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})',
    );
    const mediumRegularExp = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
    );

    if (stringPass.length > 0) {
      if (stringPass.length >= 10) {
        document.querySelector('.ten-character').setAttribute('checked', '');
        document.querySelector('.ten-character').removeAttribute('disabled', '');
        document
          .querySelector('.requirements-eigth-number-sucess')
          .classList.add('requirements-text');

        document.querySelector('.content-strentgh-warning').classList.remove('pass-low-warning');
      } else {
        document.querySelector('.ten-character').removeAttribute('checked', '');
        document.querySelector('.ten-character').setAttribute('disabled', '');
        document
          .querySelector('.requirements-eigth-number-sucess')
          .classList.remove('requirements-text');

        document.querySelector('.content-strentgh-warning').classList.add('pass-low-warning');
      }

      if (hasNumber) {
        document.querySelector('.has-number').setAttribute('checked', '');
        document.querySelector('.has-number').removeAttribute('disabled', '');
        document
          .querySelector('.requirements-has-number-sucess')
          .classList.add('requirements-text');
      } else {
        document.querySelector('.has-number').removeAttribute('checked', '');
        document.querySelector('.has-number').setAttribute('disabled', '');
        document
          .querySelector('.requirements-has-number-sucess')
          .classList.remove('requirements-text');
      }

      if (hasLetterInPassword) {
        document.querySelector('.has-letter').setAttribute('checked', '');
        document.querySelector('.has-letter').removeAttribute('disabled', '');
        document.querySelector('.requirements-has-letter').classList.add('requirements-text');
      } else {
        document.querySelector('.has-letter').removeAttribute('checked', '');
        document.querySelector('.has-letter').setAttribute('disabled', '');
        document.querySelector('.requirements-has-letter').classList.remove('requirements-text');
      }

      if (hasLetterUppercase) {
        document.querySelector('.has-uppercase').setAttribute('checked', '');
        document.querySelector('.has-uppercase').removeAttribute('disabled', '');
        document.querySelector('.requirements-text-uppercase').classList.add('requirements-text');
      } else {
        document.querySelector('.has-uppercase').removeAttribute('checked', '');
        document.querySelector('.has-uppercase').setAttribute('disabled', '');
        document
          .querySelector('.requirements-text-uppercase')
          .classList.remove('requirements-text');
      }

      if (hasSpecialCharacter) {
        document.querySelector('.has-special-character').setAttribute('checked', '');
        document.querySelector('.has-special-character').removeAttribute('disabled', '');
        document
          .querySelector('.requirements-has-special-character-sucess')
          .classList.add('requirements-text');
      } else {
        document.querySelector('.has-special-character').removeAttribute('checked', '');
        document.querySelector('.has-special-character').setAttribute('disabled', '');
        document
          .querySelector('.requirements-has-special-character-sucess')
          .classList.remove('requirements-text');
      }

      if (strongRegularExp.test(stringPass)) {
        classStrongPass.setAttribute('class', 'regular-strong-pass');
        this.contentStrongForce = 'Forte';
      } else if (mediumRegularExp.test(stringPass)) {
        classStrongPass.setAttribute('class', 'medium-strong-pass');
        this.contentStrongForce = 'Fraca';
      } else {
        classStrongPass.setAttribute('class', 'low-strong-pass');
        this.contentStrongForce = ' Muito Fraca';
      }
    } else {
      classStrongPass.removeAttribute('class', 'low-strong-pass');
      document.querySelector('.content-strentgh-warning').classList.remove('pass-low-warning');
    }
  }

  _validateBeforeSend(form) {
    const commonMsg = 'Por favor, preencha todos os campos corretamente.';

    if (form == 'login') {
      let message = commonMsg;
      this.email = this.$.querySelector('#login-card-email').value;
      this.password = this.$.querySelector('#login-card-password').value;

      const { email } = this;
      const { password } = this;

      if (email && password) return true;

      if (!email && password) message = 'O email precisa ser preenchido.';
      else if (!password && email) message = 'A senha precisa ser preenchida.';
      else message = 'Os campos precisam ser preenchidos.';

      this.tippyLogin.setContent(message);

      if (this.isMobile) {
        Object.assign(this.toastObj, { text: message });
        this.$.querySelector('#toast-obj').toggle(true);
      } else this.$timeout(() => this.tippyLogin.show());

      return false;
    }
    if (form == 'recover') {
      let message = commonMsg;
      const { emailRecover } = this;
      if (emailRecover) return true;

      message = 'O email precisa ser preenchido.';
      this.tippyRecover.setContent(message);
      if (this.isMobile) {
        Object.assign(this.toastObj, { text: message });
        this.$.querySelector('#toast-obj').toggle(true);
      } else this.$timeout(() => this.tippyRecover.show());
      return false;
    }
    if (form == 'reset') {
      const { resetPassword } = this;
      const { resetPasswordConfirm } = this;
      const hasNumberInPassword = /\d/.test(resetPassword);
      const hasLetterInPassword = /[a-zA-Z]/.test(resetPassword);
      const hasLetterLowercase = /[a-z]/.test(resetPassword);
      const hasLetterUppercase = /[A-Z]/.test(resetPassword);
      const hasSpecialCharacter = /(?=.*[!@#\\$%\\^&\\*])/.test(resetPassword);

      const error = { hasError: false, message: commonMsg };

      if (!hasNumberInPassword || !hasLetterInPassword)
        Object.assign(error, {
          hasError: true,
          message: 'Digite ao menos uma letra e um número nos dois campos.',
        });
      else if (resetPassword.length < 10)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 10 caracteres nos dois campos.',
        });
      else if (!hasLetterUppercase)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 1 letra maiúscula.',
        });
      else if (!hasLetterLowercase)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 1 letra minúscula.',
        });
      else if (!hasSpecialCharacter)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 1 caracter especial. Ex: "*".',
        });
      else if (resetPassword !== resetPasswordConfirm)
        Object.assign(error, {
          hasError: true,
          message: 'As senhas estão diferentes.',
        });
      else if (!resetPassword || !resetPasswordConfirm) Object.assign(error, { hasError: true });

      if (error.hasError) {
        this.tippyReset.setContent(error.message);
        if (this.isMobile) {
          Object.assign(this.toastObj, { text: error.message });
          this.$.querySelector('#toast-obj').toggle(true);
        } else this.$timeout(() => this.tippyReset.show());
        return false;
      }
      return true;
    }
    return false;
  }

  _login(loginEmail, loginPassword, keepSession, args = []) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    const fetchSessionData = () =>
      this.$http({
        url: `${this.urlApi}/MenuConfiguration/GetMenuConfiguration`,
        method: 'POST',
      });
    window.sessionStorage.setItem('logged', true);

    const validateFetchResponse = response => {
      if (response.status != 200) throw new Error(`Server Status ${response.status}`);
      return response.data;
    };

    const dispatchSession = async (sessionData, shouldKeepSession) => {
      this.dispatch({
        type: 'SIGNIN',
        data: {
          updateLocalStorage: true,
          keepSession: shouldKeepSession,
          userPhoto: sessionData.data ? sessionData.data.userPhoto : '',
          headerIcon: sessionData.data ? sessionData.data.headerIcon : '',
          headerLogo: sessionData.data ? sessionData.data.headerLogo : '',
          tradeMarkIcon: sessionData.data ? sessionData.data.tradeMarkIcon : '',
          applicationName: sessionData.data ? sessionData.data.applicationName : '',
          applicationNameVisibility: sessionData.data
            ? sessionData.data.applicationNameVisibility
            : '',
          navigationList: sessionData.data ? sessionData.data.navigationList : [],
          adminNavigationList: sessionData.data ? sessionData.data.adminNavigationList : [],
        },
      });
      this._updateImages(sessionData.data);

      const {
        userReportQuantity,
        userReportSharedQuantity,
        userAutoSendQuantity,
        isVideoTelemetry,
        headerIconAllowColorChange,
        userDefaultScreen,
        userDefaultActiveView,
      } = sessionData.data;
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_REPORT_QUANTITY',
        data: { userReportQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_REPORT_SHARED_QUANTITY',
        data: { userReportSharedQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_AUTO_SEND_QUANTITY',
        data: { userAutoSendQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_IS_VIDEOTELEMETRY',
        data: { isVideoTelemetry },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_HEADER_ICON_ALLOW_COLOR_CHANGE',
        data: { headerIconAllowColorChange },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_DEFAULT_SCREEN',
        data: { userDefaultScreen, userDefaultActiveView },
      });

      if (!this.needSelectAccount && !this.needRegiesterOtp) {
        this._changeRouteAfterLogin(sessionData);
      }
    };

    this._fetchAuthorizationData(loginEmail, loginPassword, args)
      .then(validateFetchResponse)
      .then(response => {
        if (JSON.parse(response.otpPending)) {
          this.clearOtp();
          this.tokenToOtp = response.access_token;
          this._changeView('otp');
          throw new Error('User need to validate otp');
        }
        if (JSON.parse(response.changePassword)) {
          this.tokenToChangePassword = response.access_token;
          this._changeView('reset');
          throw new Error('User need to change the password');
        }
        this._dispatchAuthorization(response, loginEmail, keepSession);
        this._needRegisterOtp(response);
        this._switchAccount(response);
        return fetchSessionData();
      })
      .then(validateFetchResponse)
      .then(response => {
        dispatchSession(response, keepSession);
        return this._insertLog();
      })
      .then(validateFetchResponse)
      .catch(error => {
        if (
          error.message != 'User need to change the password' &&
          error.message != 'User need to validate otp'
        ) {
          if (this.getParams.single_sign_on || this.isSingleSignon)
            this._changeView('fail-request-single-signon');
          else {
            const msg =
              error.data && error.data.error_description
                ? error.data.error_description
                : 'Ops! Ocorreu um erro. Tente novamente.';
            // error.preventDefault();
            this.tippyLogin.setContent(msg);
            if (this.isMobile) {
              Object.assign(this.toastObj, { text: msg });
              this.$.querySelector('#toast-obj').toggle(true);
            } else this.$timeout(() => this.tippyLogin.show());
          }
        }
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        const unresolvedApp = document.querySelector('[unresolved]');
        const splashLoader = document.querySelector('#splash-container');
        if (unresolvedApp) unresolvedApp.removeAttribute('unresolved');
        if (splashLoader) splashLoader.remove();
      });
  }

  _changeRouteAfterLogin(sessionData) {
    this._goToRoute(sessionData);
    document
      .querySelector('#app-component')
      .dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
  }

  _otp(loginEmail, otpCode, keepSession) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    const fetchSessionData = () =>
      this.$http({
        url: `${this.urlApi}/MenuConfiguration/GetMenuConfiguration`,
        method: 'POST',
      });
    window.sessionStorage.setItem('logged', true);

    const validateFetchResponse = response => {
      if (response.status != 200) throw new Error(`Server Status ${response.status}`);
      return response.data;
    };

    const dispatchSession = async (sessionData, shouldKeepSession) => {
      this.dispatch({
        type: 'SIGNIN',
        data: {
          updateLocalStorage: true,
          keepSession: shouldKeepSession,
          userPhoto: sessionData.data ? sessionData.data.userPhoto : '',
          headerIcon: sessionData.data ? sessionData.data.headerIcon : '',
          headerLogo: sessionData.data ? sessionData.data.headerLogo : '',
          tradeMarkIcon: sessionData.data ? sessionData.data.tradeMarkIcon : '',
          applicationName: sessionData.data ? sessionData.data.applicationName : '',
          applicationNameVisibility: sessionData.data
            ? sessionData.data.applicationNameVisibility
            : '',
          navigationList: sessionData.data ? sessionData.data.navigationList : [],
          adminNavigationList: sessionData.data ? sessionData.data.adminNavigationList : [],
        },
      });
      this._updateImages(sessionData.data);

      const {
        userReportQuantity,
        userReportSharedQuantity,
        userAutoSendQuantity,
        isVideoTelemetry,
        headerIconAllowColorChange,
        userDefaultScreen,
        userDefaultActiveView,
      } = sessionData.data;
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_REPORT_QUANTITY',
        data: { userReportQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_REPORT_SHARED_QUANTITY',
        data: { userReportSharedQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_AUTO_SEND_QUANTITY',
        data: { userAutoSendQuantity },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_IS_VIDEOTELEMETRY',
        data: { isVideoTelemetry },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_HEADER_ICON_ALLOW_COLOR_CHANGE',
        data: { headerIconAllowColorChange },
      });
      this.$ngRedux.dispatch({
        type: 'UPDATE_USER_DEFAULT_SCREEN',
        data: { userDefaultScreen, userDefaultActiveView },
      });

      if (!this.needSelectAccount) {
        this._goToRoute(sessionData);
        /* HOTFIX */
        // this.$.dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
        document
          .querySelector('#app-component')
          .dispatchEvent(new CustomEvent('authorizedSignin', { bubbles: true, composed: true }));
      }
    };

    this._fetchAuthorizationDataOtp(otpCode)
      .then(validateFetchResponse)
      .then(response => {
        if (JSON.parse(response.changePassword)) {
          this._changeView('reset');
          this.tokenToChangePassword = response.access_token;
          throw new Error('User need to change the password');
        }
        this._dispatchAuthorization(response, loginEmail, keepSession);
        this._switchAccount(response);
        return fetchSessionData();
      })
      .then(validateFetchResponse)
      .then(response => {
        dispatchSession(response, keepSession);
        return this._insertLog();
      })
      .then(validateFetchResponse)
      .catch(error => {
        this.otpError = true;
        console.error(error);
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        const unresolvedApp = document.querySelector('[unresolved]');
        const splashLoader = document.querySelector('#splash-container');
        if (unresolvedApp) unresolvedApp.removeAttribute('unresolved');
        if (splashLoader) splashLoader.remove();
      });
  }

  async _goToRoute(sessionData) {
    const { nextRouteName } = this.state;
    this.state.nextRouteName = null;
    let hasNewAnnouncement = false;
    const checkForNewAnnouncements = this.commonServices.needCheckAnnouncements(
      this.token,
      this.needSelectAccount,
      this.loginId,
    );

    if (checkForNewAnnouncements) {
      hasNewAnnouncement = await this.commonServices.checkNewAnnouncementsLogin(this.loginId);
    }

    if (nextRouteName) {
      const route = this._getNextRoute(nextRouteName, sessionData.data);
      if (route.showInfo) {
        this.$state.go(route.routeInfoAddon, {
          routeId: route.id,
          serviceId: route.serviceId,
          serviceName: route.description,
          serviceIcon: route.icon,
        });
      } else {
        this.$state.go(nextRouteName);
      }
    } else if (hasNewAnnouncement) {
      this.$state.go('dashboardAnnouncements');
    } else if (sessionData.userDefaultScreen) {
      this._directToDefaultRoute(sessionData.userDefaultScreen, sessionData.userDefaultActiveView);
    } else {
      this.$state.go('dashboard');
    }
  }

  _fetchAuthorizationData(email, password, args) {
    return this.$http({
      url: `${this.urlApi.replace('/api', '')}/token`,
      method: 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      data:
        `grant_type=password&username=${encodeURIComponent(email)}&` +
        `password=${encodeURIComponent(password)}${args.length > 0 ? `&scope=${args}` : ''}&` +
        `application=${this._applications.map(app => app.id)}`,
    });
  }

  _fetchAuthorizationDataOtp(otpCode) {
    return this.$http({
      url: `${this.urlApi.replace('/api', '')}/token`,
      method: 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      data:
        `grant_type=otp&otpCode=${encodeURIComponent(otpCode)}&` +
        `token=${encodeURIComponent(this.tokenToOtp)}`,
    });
  }

  _getNextRoute(routeId, sessionData) {
    const { navigationList, adminNavigationList } = sessionData;

    const routes = navigationList
      .map(item => [item].concat(item.itens))
      .concat(adminNavigationList.map(item => [item].concat(item.itens)))
      .flat();

    return routes.find(item => item.route === routeId);
  }

  _recover(loginEmail) {
    const validateFetchResponse = response => {
      if (response.status != 200) throw new Error(`Server Status ${response.status}`);
      return response.data;
    };
    this._fetchRecoverPassword(loginEmail)
      .then(validateFetchResponse)
      .then(() => {
        this.password = '';
        Object.assign(this.toastObj, {
          text:
            'Solicitação realizada. Foi enviado instruções ' +
            'de recuperação de senha para o e-mail informado.',
        });
        this.$.querySelector('#toast-obj').toggle(true);
        this._changeView('login');
      })
      .catch(error => {
        Object.assign(this.toastObj, {
          text: 'Erro ao solicitar recuperação de senha.  Tente novamente!',
        });
        this.$.querySelector('#toast-obj').toggle(true);
        console.error(error);
      })
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _reset(loginEmail, passwordConfirm) {
    const fetchRecoverPassword = (email, password) =>
      this.$http({
        url: `${this.urlApi}/User/ReplacePassword`,
        method: 'POST',
        data: { email, password },
        headers: { Authorization: `Bearer ${this.tokenToChangePassword}` },
      });
    const validateFetchResponse = response => {
      if (response.status != 200) throw new Error(`Server Status ${response.status}`);
      return response.data;
    };
    fetchRecoverPassword(loginEmail, passwordConfirm)
      .then(validateFetchResponse)
      .then(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this._login(this.email, passwordConfirm, this.keepSession);
      })
      .catch(error => {
        Object.assign(this.toastObj, {
          text: 'Erro ao trocar senha. Tente novamente!',
        });
        this.$.querySelector('#toast-obj').toggle(true);
        console.error(error);
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _switchAccount(response) {
    if (
      JSON.parse(response.isTrip) &&
      !JSON.parse(response.hasUserDefault) &&
      JSON.parse(response.qtdUser) > 1
    ) {
      this.needSelectAccount = true;
      this.dispatch({
        type: 'SIGNIN',
        data: {
          needSelectAccount: true,
        },
      });
      this._openSwitchAccount();
    }
  }

  _needRegisterOtp(response) {
    this.needRegiesterOtp = JSON.parse(response.otpClientRequest);
    if (this.needRegiesterOtp) {
      this.resonseData = response;
      this.$.querySelector('#popup-otp').togglePopup();
    }
  }

  _openSwitchAccount() {
    this.$.querySelector('#section-switch-account power-switch-account').dispatchEvent(
      new CustomEvent('getSwitchAccounts', { bubbles: true, composed: true }),
    );
  }

  async _directToDefaultRoute(route, activeView) {
    if (activeView) {
      const routeConfig = await ImportRouteConfig(route);
      if (routeConfig) {
        const routeData = {
          ...routeConfig.data,
          stateConfig: routeConfig.data.stateConfig
            ? routeConfig.data.stateConfig({
                isCustomReport: false,
                customReportId: '',
              })
            : null,
        };
        if (routeData.stateConfig) {
          routeData.stateConfig.viewMode = activeView;
        }

        this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: routeData,
        });
      }
    }

    this.$state.go(route);
  }

  _dispatchAuthorization(authorizationData, loginEmail, keepSession) {
    const params = {
      extra: {
        isSingleSignon: this.isSingleSignon,
        tipoApp: this.tipoApp,
        isAdm: JSON.parse(authorizationData.isAdm),
        token: authorizationData.access_token,
        isTrip: JSON.parse(authorizationData.isTrip),
        keepSession: !!keepSession,
      },
      user: {
        email: loginEmail,
      },
    };

    if (process.env.NODE_ENV === 'production') {
      window.dispatchEvent(new CustomEvent('updateSentryScope', { detail: params }));
    }

    window.localStorage.setItem(
      `refresh_token-${authorizationData.loginId}`,
      authorizationData.refresh_token,
    );

    this.dispatch({
      type: 'SIGNIN',
      data: {
        qtdUser: JSON.parse(authorizationData.qtdUser),
        loginId: JSON.parse(authorizationData.loginId),
        clientId: JSON.parse(authorizationData.clientId),
        application: authorizationData.application,
        mapProvider: authorizationData.mapProvider,
        token: authorizationData.access_token,
        isAdm: JSON.parse(authorizationData.isAdm),
        isTrip: JSON.parse(authorizationData.isTrip),
        userId: JSON.parse(authorizationData.userId),
        userEmail: loginEmail,
        userName: authorizationData.userName,
        companyName: authorizationData.clientName,
        permissions: authorizationData.permissions ? authorizationData.permissions.split(',') : [],
        modules: authorizationData.modules ? authorizationData.modules.split(',') : [],
        otpActive: JSON.parse(authorizationData.otpActive),
        otpClientRequest: JSON.parse(authorizationData.otpClientRequest),
        otpBlockSwitch: JSON.parse(authorizationData.otpBlockSwitch),
        themeColors: {
          primaryColor: authorizationData.primaryColor,
          primaryTextColor: authorizationData.primaryTextColor,
          headerColor: authorizationData.headerColor,
          headerLogoColor: authorizationData.headerLogoColor,
          headerTextColor: authorizationData.headerTextColor,
          headerBadgeColor: authorizationData.headerBadgeColor,
          headerButtonColor: authorizationData.headerButtonColor,
          navigationColor: authorizationData.navigationColor,
          navigationDarkColor: authorizationData.navigationDarkColor,
          navigationLightColor: authorizationData.navigationLightColor,
          navigationSelectedColor: authorizationData.navigationSelectedColor,
          navigationTextColor: authorizationData.navigationTextColor,
          navigationTextDarkColor: authorizationData.navigationTextDarkColor,
          navigationTextLightColor: authorizationData.navigationTextLightColor,
          navigationTextSelectedColor: authorizationData.navigationTextSelectedColor,
          navigationAdminColor: authorizationData.navigationAdminColor,
          navigationAdminDarkColor: authorizationData.navigationAdminDarkColor,
          navigationAdminLightColor: authorizationData.navigationAdminLightColor,
          navigationAdminTextColor: authorizationData.navigationAdminTextColor,
          navigationAdminTextLightColor: authorizationData.navigationAdminTextLightColor,
        },
        trainingMode:
          typeof authorizationData.cliHashType != 'undefined' &&
          authorizationData.cliHashType != '' &&
          authorizationData.cliHashType != '0',
      },
    });

    this.loginId = JSON.parse(authorizationData.loginId);

    const { mapProvider } = authorizationData;
    this.dispatch({ type: 'UPDATE_MAP_PROVIDER', data: { mapProvider } });
  }

  /* */

  /* Observers */
  /* */
}

export { PowerLoginController };
