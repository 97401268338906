import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-crud-textbox/power-crud-textbox';
import '../golfleet-dashboard-block/golfleet-dashboard-block';
import '../golfleet-dashboard-chart-grid/golfleet-dashboard-chart-grid';
import '../golfleet-dashboard-chart-list/golfleet-dashboard-chart-list';
import '../golfleet-dashboard-error/golfleet-dashboard-error';
import '../golfleet-dashboard-grid/golfleet-dashboard-grid';
import '../golfleet-dashboard-list/golfleet-dashboard-list';
import '../golfleet-dashboard-slider/golfleet-dashboard-slider';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '../power-loader/power-loader';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import { FilterCondition } from '@power/power-components/components/power-filter/power-filter';
import { ReportStateConfig } from '@power/power-components/components/power-state-config/power-state-config';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/helpers/is-iframe/is-iframe';

import template from './golfleet-dashboard.html';
import './golfleet-dashboard.scss';

class GolfleetDashboardController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'urlApi',
      'filterServices',
      'commonServices',
      'dashboardServices',
      '_today',
      '_months',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $http,
    urlApi,
    filterServices,
    commonServices,
    dashboardServices,
    _today,
    _months,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      urlApi,
      filterServices,
      commonServices,
      dashboardServices,
      _today,
      _months,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        lastState:
          behavior.state.routeList.length > 1
            ? behavior.state.routeList[behavior.state.routeList.length - 2]
            : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.__appStore = $ngRedux.connect(store =>
      Object({
        /* Session Storage */
        loginId: store.session.loginId,
        isTrip: store.session.isTrip,
        isAdm: store.session.isAdm,
        refreshFilter: store.session.refreshFilter,
        application: store.session.application,
        isSingleSignon: store.session.isSingleSignon,
      }),
    )(this);

    this.legacyEquipaments = { status: 0, list: [], count: 0, highlight: true }; // 0. loading, 1. ok, 2. no data, 3. error
    this.msgLegacyEquipament = ' equipamentos incompatíveis não constam neste Dashboard';
    this.configDataVariables = { status: 0 };
    this.configDataGraphicIndicatorsEvolution = { status: 0 };
    this.configDataTravelledDistanceCost = { status: 0 };
    this.configDataViolationCost = { status: 0 };
    this.configDataViolation = { status: 0 };
    this.configDataMaxViolations = { status: 0 };
    this.toastDashboard = {};
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    this.toastDashboard = {
      text: '',
    };

    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    if (
      !this.stateConfig.filterConfig ||
      this.stateConfig.filterConfig.length == 0 ||
      this.refreshFilter
    )
      this._getStateConfig();
    else this._getDashboardData();
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  requestDataset() {
    this._getDashboardData();
  }

  /* Chrome Locked-Scroll Workaround */
  scrollWorkaround() {
    const scrollArea = this.$.querySelector(
      '.popup-legacy-equipament .body .right-box .list-equipaments',
    );
    setTimeout(() => {
      scrollArea.style.overflowY = 'hidden';
    }, 500);
    setTimeout(() => {
      scrollArea.style.overflowY = '';
    }, 600);
  }
  /* */

  /* Private */
  _getStateConfig() {
    this.filterServices
      .getPowerFilters(this.stateConfig.screenName, 'Home')
      .then(getFiltersResult => {
        if (getFiltersResult) {
          const { drilldownMap } = getFiltersResult;
          const filterConfigList = getFiltersResult.filters;
          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );
          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(
                // eslint-disable-next-line no-shadow
                drilldown => drilldown.toId == actualFilter.id,
              )[0];
              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];
                return {
                  ...actualFilter,
                  condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                };
              }
              return actualFilter;
            });
          }
          if (this.stateConfig.getDataFilters) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition => condition.id == actualFilter.id,
              )[0];
              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field || actualFilter.filters[fixedFilters.activeView].field,
                      fixedFilters.default,
                    ),
                  }
                : actualFilter;
            });
          }
        }
        this.commonServices
          .getToolbarOptions(
            this.stateConfig.screenName,
            this.stateConfig.toolbarName,
            getToolbarResult => {
              const { addConfig, viewsConfig, exportConfig, importConfig, actionConfig } =
                getToolbarResult;
              Object.assign(this.stateConfig.toolbarConfig, {
                addConfig,
                viewsConfig,
                exportConfig,
                actionConfig,
                importConfig,
              });
              this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
            },
          )
          .then(() =>
            document
              .querySelector('#app-component')
              .querySelector('power-filter-menu')
              .applyFilters(),
          );
      })
      .finally(() => {
        this.$ngRedux.dispatch({
          type: 'SIGNIN',
          data: {
            refreshFilter: false,
          },
        });

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _getDashboardData() {
    this.__clearData();

    const filters = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
    };

    const filterEquipaments = this.stateConfig.filterConditions.filter(
      item => item.field == 'vehicles' || item.field == 'driver',
    )[0];

    if (
      filterEquipaments &&
      (filterEquipaments.value.length === 0 ||
        filterEquipaments.value.length == filterEquipaments.total)
    ) {
      this.commonServices.getLegacyEquipaments('LegacyEquipaments/GetLegacyEquipaments').then(
        success => {
          if (success.data && success.data.hasError) {
            this.legacyEquipaments.status = 3;
          } else if (success.data.data && success.data.data.total > 0) {
            this.legacyEquipaments.list = success.data.data.equipaments;
            this.legacyEquipaments.count = success.data.data.total;
            this.legacyEquipaments.highlight = success.data.data.highlight;
            this.legacyEquipaments.status = 1;
          } else this.legacyEquipaments.status = 2;
        },
        () => {
          this.legacyEquipaments.status = 3;
        },
      );
    } else this.legacyEquipaments.status = 0;

    this.dashboardServices
      .getDashboardConfig('Home')
      .then(dbResponse => {
        // Após carregar as configurações do Dashboard, carrega as informações dos Block's
        if (dbResponse.status && dbResponse.status === 500) {
          this.toastDashboard.text =
            'Ops! Ocorreu um erro ao carregar as configurações do seu Dashboard. Por favor, atualize a página.';
          this.$.querySelector('#toast-obj').toggle(true);
          return;
        }

        this.dashboardServices.getVariablesConfig().then(variablesResult => {
          if (variablesResult.status && variablesResult.status === 500) {
            this.configDataVariables.status = 2;
            return;
          }

          variablesResult.forEach(item => {
            item.dacValorVariavelFormatado = item.dacValorVariavelFormatado.toString();
            [item.config] = dbResponse.popup.body.fields.filter(
              field => field.id == item.dacIdentificador,
            );
          });

          variablesResult = variablesResult.filter(
            item =>
              dbResponse.popup.body.fields.filter(field => field.id == item.dacIdentificador)
                .length > 0,
          );

          this.stateConfig.toolbarConfig.actionConfig.actions.forEach(item => {
            if (item.value == 'mediaConsumo') {
              item.description = `${item.description.split('(')[0]} (${variablesResult
                .filter(result => result.dacIdentificador == 'RENDMOV')[0]
                .dacValorVariavelFormatado.toString()
                .replace('.', ',')} km/litro)`;
            } else if (item.value == 'mediaPreco') {
              item.description = `${item.description.split('(')[0]} (R$ ${variablesResult
                .filter(result => result.dacIdentificador == 'CUSTCOM')[0]
                .dacValorVariavel.toString()
                .replace('.', ',')} litro)`;
            }
          });

          this.configDataVariables.status = 1;
          this.configDataVariables.values = {
            config: dbResponse.popup,
            data: variablesResult,
          };
        });

        /* Status
         * 0: show loader
         * 1: ok, success
         * 2: not ok, error
         */

        // prevent unecessary requests if switch module too fast
        if (filters && filters.filter.conditions && filters.filter.conditions.length === 0) {
          return;
        }

        // ChartGrid (Contém todas as Infrações e o gráfico)
        this.dashboardServices.getIndicatorsEvolution(filters).then(ieResponse => {
          if (ieResponse.status && ieResponse.status === 500) {
            this.configDataGraphicIndicatorsEvolution.status = 2;
            return;
          }

          const graphicData = { ...filters, typeReport: ieResponse[0].tipoInfracao };

          this.dashboardServices.getGraphic(graphicData).then(graphicResponse => {
            if (graphicResponse.status && graphicResponse.status === 500) {
              this.configDataGraphicIndicatorsEvolution.status = 2;
              return;
            }

            ieResponse.forEach(item => {
              item.selected = false;
            });
            ieResponse[0].selected = true; // Seleciona default o primeiro

            const configs = dbResponse.blocks.filter(item => item.type == 'chartGrid')[0];
            ieResponse.forEach(item => {
              [item.config] = configs.gridConfig.custom.filter(
                gridConfig => gridConfig.id == item.tipoInfracao,
              );
            });

            this.finishPageLoading += 1;
            this.configDataGraphicIndicatorsEvolution.status = 1;

            const lastItem = graphicResponse[graphicResponse.length - 1];

            const lastItemPeriod = lastItem.period.split('-');

            const hasLastMonth =
              this._today.getFullYear() == parseInt(lastItemPeriod[0], 10) &&
              this._today.getMonth() == parseInt(lastItemPeriod[1], 10) - 1;

            this.configDataGraphicIndicatorsEvolution.values = {
              params: graphicData,
              config: configs,
              data: {
                chartConfig: {
                  comparative: [
                    {
                      desc: `${
                        this._months[parseInt(graphicResponse[0].period.split('-')[1], 10) - 1].desc
                      } de ${graphicResponse[0].period.split('-')[0]}`,
                      formattedValue: graphicResponse[0].formattedValue,
                      value: graphicResponse[0].value,
                    },
                    {
                      desc: `${
                        this._months[
                          parseInt(
                            graphicResponse[graphicResponse.length - 1].period.split('-')[1],
                            10,
                          ) - 1
                        ].desc
                      } de ${graphicResponse[graphicResponse.length - 1].period.split('-')[0]}${
                        hasLastMonth ? ' (parcial)' : ''
                      }`,
                      formattedValue: graphicResponse[graphicResponse.length - 1].formattedValue,
                      value: graphicResponse[graphicResponse.length - 1].value,
                    },
                  ],
                  categories: graphicResponse.map(item => {
                    const date = item.period.split('-');
                    return this._months[parseInt(date[1], 10) - 1].desc.substr(0, 3).toUpperCase();
                  }),
                },
                chart: graphicResponse,
                grid: ieResponse,
              },
            };
          });
        });

        // ChartList (Contém gráfico Pie e lista Custo Distância Percorrida)
        this.dashboardServices.getTravelledDistanceCost(filters).then(tdcResponse => {
          if (tdcResponse.status && tdcResponse.status === 500) {
            this.configDataTravelledDistanceCost.status = 2;
            return;
          }

          const configs = dbResponse.blocks.filter(item => item.type == 'chartList')[0];
          tdcResponse.forEach(item => {
            item.valorFormatado = parseFloat(item.valorFormatado);
            [item.config] = configs.listConfig.filter(config => config.id == item.tipoInfracao);
          });

          let total1 = 0;
          let total2 = 0;

          tdcResponse.forEach(item => {
            total1 += item.valorFormatado;
            total2 += item.custoAssociado;
          });

          this.finishPageLoading += 1;
          this.configDataTravelledDistanceCost.status = 1;
          this.configDataTravelledDistanceCost.values = {
            params: filters.filter.conditions,
            config: configs,
            data: tdcResponse,
            total1,
            total2,
          };
        });

        // List (Lista Custo estimado com Infrações)
        this.dashboardServices.getViolationCost(filters).then(vcResponse => {
          if (vcResponse.status && vcResponse.status === 500) {
            this.configDataViolationCost.status = 2;
            return;
          }

          const configs = dbResponse.blocks.filter(item => item.type == 'list')[0];
          vcResponse.forEach(item => {
            [item.config] = configs.listConfig.filter(config => config.id == item.tipoInfracao);
          });

          this.finishPageLoading += 1;
          this.configDataViolationCost.status = 1;
          this.configDataViolationCost.values = {
            params: filters.filter.conditions,
            config: configs,
            data: vcResponse,
            total: vcResponse[0].custoAssociado + vcResponse[1].custoAssociado,
          };
        });

        // Slider
        this.dashboardServices.getMaxViolations(filters).then(mvResponse => {
          if (mvResponse.status && mvResponse.status === 500) {
            this.configDataMaxViolations.status = 2;
            return;
          }

          const configs = dbResponse.blocks.filter(item => item.type == 'slider')[0];
          mvResponse.forEach(item => {
            [item.config] = configs.sliderConfig.filter(config => config.id == item.id);
          });

          this.finishPageLoading += 1;
          this.configDataMaxViolations.status = 1;
          this.configDataMaxViolations.values = {
            params: filters.filter.conditions,
            config: configs,
            data: mvResponse,
          };
        });

        // Grid (Infrações: Condutores, Veículos e Grupos)
        const violationData = {
          ...filters,
          typeReport: dbResponse.blocks
            .filter(item => item.type == 'grid')
            .filter(item => item.optionsConfig.selected)[0],
        };
        this.dashboardServices.getViolation(violationData).then(violationResponse => {
          if (violationResponse.status && violationResponse.status === 500) {
            this.configDataViolation.status = 2;
            return;
          }

          this.finishPageLoading += 1;
          this.configDataViolation.status = 1;
          this.configDataViolation.values = {
            params: filters.filter.conditions,
            config: dbResponse.blocks.filter(item => item.type == 'grid')[0],
            data: violationResponse,
          };
        });

        if (dbResponse.popupModuleInfo && this.isTrip && this.isAdm) {
          const { popupModuleInfo } = dbResponse;

          this.dashboardServices.getFleetPolicyStatus().then(fleetPolicyStatusReponse => {
            if (fleetPolicyStatusReponse.status && fleetPolicyStatusReponse.status !== 200) {
              return;
            }

            const { hasFleetPolicy, hasVehicleInFleetPolicy } = fleetPolicyStatusReponse;

            if (!hasFleetPolicy || !hasVehicleInFleetPolicy) {
              Object.assign(this, {
                popupModuleInfo,
                fleetPolicyStatus: {
                  hasFleetPolicy,
                  hasVehicleInFleetPolicy,
                },
              });
              this.$.querySelector('#popup-module-info').toggle();
            }
          });
        }
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _evtClickPopupLegacyEquipament() {
    this.scrollWorkaround();
    this.$.querySelector('#popup-legacy-equipament').toggle();
  }

  _evtClickConfigDashboard(itemReturn) {
    if (itemReturn.value == 'mediaConsumo' || itemReturn.value == 'mediaPreco') {
      this.$.querySelector('#popup-config').toggle();
    }
  }

  _evtClickBackToStandard() {
    this.configDataVariables.status = 0;

    this.dashboardServices.getVariablesConfig(true).then(variablesResult => {
      if (variablesResult.status && variablesResult.status === 500) {
        this.configDataVariables.status = 2;
        return;
      }

      // eslint-disable-next-line consistent-return, array-callback-return
      variablesResult = variablesResult.map(item => {
        if (item) {
          item.dacValorVariavelFormatado = item.dacValorVariavelFormatado.toString();
          [item.config] = this.configDataVariables.values.config.body.fields.filter(
            field => field.id == item.dacIdentificador,
          );

          return item;
        }
      });

      variablesResult = variablesResult.reduce((acc, item) => {
        this.configDataVariables.values.config.body.fields.forEach(field => {
          if (item && item.dacIdentificador == field.id) {
            acc.push({ ...field, ...item });
          }
        });

        return acc;
      }, []);

      this.configDataVariables.status = 1;
      this.configDataVariables.values.data = variablesResult.filter(item => item);
    });
  }

  _evtClickBtnsPopup(btn) {
    if (btn.method == 'cancel') this.$.querySelector('#popup-config').toggle();
    else if (btn.method == 'save') {
      if (
        this.configDataVariables.values.data.filter(item => !item.dacValorVariavelFormatado)
          .length > 0 ||
        this.configDataVariables.values.data.filter(
          item => !item.dacValorVariavel && item.dacIdentificador == 'CUSTCOM',
        ).length > 0
      ) {
        this.toastDashboard = {
          text: 'Favor preencher todos os campos antes de salvar.',
        };
        this.$.querySelector('#toast-obj').toggle(true);
        return;
      }
      if (
        this.configDataVariables.values.data.filter(
          item => parseFloat(item.dacValorVariavelFormatado.replace(',', '.')) == 0,
        ).length > 0
      ) {
        this.toastDashboard = {
          text: 'Não é permitido valor zero (0) nos campos.',
        };
        this.$.querySelector('#toast-obj').toggle(true);
        return;
      }

      const variables = {};
      this.configDataVariables.status = 0;

      this.configDataVariables.values.data.forEach(item => {
        if (item.dacIdentificador == 'RENDMOV') {
          variables.valorRendMov = item.dacValorVariavelFormatado.replace(',', '.');
        } else if (item.dacIdentificador == 'CUSTCOM') {
          variables.valorCustoCom = item.dacValorVariavel.toString().replace(',', '.');
        }
      });

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      this.dashboardServices
        .setVariablesConfig(variables)
        .then(result => {
          this.$.querySelector('#popup-config').toggle();

          this.configDataVariables.status = 1;

          if (!result || result.status === 500) {
            this.toastDashboard.text =
              'Ocorreu um erro ao salvar as configurações. Tente novamente, se o erro persistir, nos contate!';
            return;
          }

          this.toastDashboard.text = 'Perfeito! As configurações foram atualizadas com sucesso!';

          this.stateConfig.toolbarConfig.actionConfig.actions.forEach(item => {
            if (item.value == 'mediaConsumo') {
              item.description = `${item.description.split('(')[0]}(${variables.valorRendMov
                .toString()
                .replace('.', ',')} km/litro)`;
            } else if (item.value == 'mediaPreco') {
              item.description = `${item.description.split('(')[0]}(R$ ${variables.valorCustoCom
                .toString()
                .replace('.', ',')} litro)`;
            }
          });

          this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });

          this.$.querySelector('#toast-obj').toggle(true);
        })
        .finally(() => {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    }
  }

  _evtClickPopupModuleInfoAction(action) {
    if (action.route) {
      if (action.stateConfig) {
        const { stateConfig: config } = action;

        const stateConfig = new ReportStateConfig({ ...config, isAdm: this.isAdm });

        this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE_SINGLE',
          data: {
            routeName: action.routeName,
            routeLink: action.route,
            routeTail: null,
            stateConfig,
          },
        });
      }

      this.$state.go(action.route);
    }
  }

  _evtClickPopupModuleInfoClose() {
    this.$.querySelector('#popup-module-info').toggle();
  }
  /* */

  /* Protected */
  __clearData() {
    this.configDataGraphicIndicatorsEvolution = { status: 0 };
    this.configDataTravelledDistanceCost = { status: 0 };
    this.configDataViolationCost = { status: 0 };
    this.configDataViolation = { status: 0 };
    this.configDataMaxViolations = { status: 0 };
  }
  /* */
}

class GolfleetDashboard {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetDashboardController;
  }
}

angular
  .module('golfleet-dashboard', [
    'ngRedux',
    'ng-tippy',
    'power-dropdown',
    'power-toolbar',
    'golfleet-dashboard-block',
    'golfleet-dashboard-chart-grid',
    'golfleet-dashboard-list',
    'power-loader',
    'golfleet-dashboard-error',
    'golfleet-dashboard-chart-list',
    'golfleet-dashboard-slider',
    'golfleet-dashboard-grid',
    'power-popup',
    'power-crud-textbox',
    'power-toast',
  ])
  .component('golfleetDashboard', new GolfleetDashboard());

export { GolfleetDashboardController };
