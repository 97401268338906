/* global $: true */
import angular from 'angular';
import 'jquery';
import 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import template from './golfleet-dashboard-slider.html';

class GolfleetDashboardSliderController {
  static get $inject() {
    return ['$element', '$timeout', 'commonServices'];
  }

  constructor($element, $timeout, commonServices) {
    Object.assign(this, { $: $element[0], $timeout, commonServices });

    this.slider = null;
  }

  /* Lifecycle */
  $onInit() {
    this.$timeout(() => {
      const imgs = this.$.querySelectorAll('.gs-s-dashboard-icon img');

      imgs.forEach(item => {
        const imgUrl = item.getAttribute('src');

        if (imgUrl) {
          this.commonServices.getSvg(imgUrl, svg => {
            let svgElement = document.createElement('svg');

            svgElement.innerHTML = svg;
            svgElement = svgElement.firstChild;
            svgElement.setAttribute('width', '50px');
            svgElement.setAttribute('height', '46px');

            item.parentNode.replaceChild(svgElement, item);
          });
        }
      });

      this.slider = $(this.$).find('.gs-s-dashboard').slick({
        vertical: true,
        autoplay: true,
        arrows: false,
        speed: 0,
        cssEase: 'linear',
      });
    });
  }
  /* */
}

class GolfleetDashboardSlider {
  constructor() {
    this.template = template;
    this.bindings = {
      configData: '=',
    };
    this.controller = GolfleetDashboardSliderController;
  }
}

angular
  .module('golfleet-dashboard-slider', [])
  .component('golfleetDashboardSlider', new GolfleetDashboardSlider());
