import angular from 'angular';
import '../power-popup/power-popup';

import template from './power-popup-pdf-export-prompt.html';
import './power-popup-pdf-export-prompt.scss';

class PowerPopupPdfExportPromptController {
  static get $inject() {
    return ['$element', '$http', '$ngRedux'];
  }

  constructor($element, $http, $ngRedux) {
    Object.assign(this, { $: $element[0], $http, $ngRedux });

    this.selectedOption = {};

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);
  }

  $onInit() {
    Object.assign(this.$, {
      setup: this.setup.bind(this),
      toggle: this.toggle.bind(this),
    });
  }

  $onDestroy() {
    this.__appBehavior();
  }

  setup({ option }) {
    this.selectedOption = option;
  }

  toggle() {
    const popupElement = this.$.querySelector('#power-popup-pdf-export-prompt');

    if (!popupElement.hasAttribute('open')) {
      const { gridTotal, gridHeaders } = this.stateConfig.gridConfig;

      const totalOfColumns = gridHeaders.reduce((acc, header) => {
        if (!header.title || !header.show) {
          return acc;
        }

        if (header.show && Array.isArray(header.columns)) {
          return acc + header.columns.filter(column => column.show).length;
        }

        return acc + 1;
      }, 0);

      const numberOfCells = gridTotal * totalOfColumns;

      const numberFormatter = new Intl.NumberFormat('pt-BR');
      const gridTotalFormatted = numberFormatter.format(gridTotal);
      const totalOfColumnsFormatted = numberFormatter.format(totalOfColumns);
      const numberOfCellsFormatted = numberFormatter.format(numberOfCells);

      const outputMessageElement = popupElement.querySelector('.output-message');
      const exportButtonElement = popupElement.querySelector('.footer .export-btn');

      if (numberOfCells > 40000) {
        outputMessageElement.setAttribute('error', '');
        exportButtonElement.setAttribute('disabled', '');
      } else {
        outputMessageElement.removeAttribute('error');
        exportButtonElement.removeAttribute('disabled');
      }

      outputMessageElement.innerHTML = `O relatório atual possui ${gridTotalFormatted} registro${
        gridTotal === 1 ? '' : 's'
      }, ${totalOfColumnsFormatted} coluna${
        totalOfColumns === 1 ? '' : 's'
      } e ${numberOfCellsFormatted} célula${numberOfCells === 1 ? '' : 's'}`;
    }

    popupElement.toggle();
  }

  export() {
    this.$.dispatchEvent(
      new CustomEvent('export', {
        detail: { option: this.selectedOption },
        bubbles: true,
        composed: true,
      }),
    );

    this.toggle();
  }
}

class PowerPopupPdfExportPrompt {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerPopupPdfExportPromptController;
  }
}

angular
  .module('power-popup-pdf-export-prompt', [])
  .component('powerPopupPdfExportPrompt', new PowerPopupPdfExportPrompt());
