import angular from 'angular';

import template from './power-fab.html';
import './power-fab.scss';

class PowerFabController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux'];
  }

  constructor($element, $scope, $ngRedux) {
    Object.assign(this, { $: $element[0], $scope, $ngRedux });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const routeListSize = behavior.state.routeList.length;
      const currentState = behavior.state.routeList[routeListSize - 1];
      return Object({
        /* State Storage */
        lastState: routeListSize > 1 ? behavior.state.routeList[routeListSize - 2] : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.items = [];
    this.actionsElement = {};
    this._autoCloseEvent = this.toggle.bind(this);
  }

  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });

    this.$scope.$watch(() => this.stateConfig.viewMode, this.__onViewModeChanged.bind(this));
  }

  $onDestroy() {
    this.__appBehavior();
  }

  toggle() {
    if (this.$.hasAttribute('open')) {
      this.$.removeAttribute('open');
      document.removeEventListener('click', this._autoCloseEvent);
    } else {
      this.$.setAttribute('open', '');
      setTimeout(() => {
        document.addEventListener('click', this._autoCloseEvent);
      }, 100);
    }
  }

  _loadItems() {
    this.$.style.display = '';

    if (['map', 'split'].includes(this.stateConfig?.viewMode)) {
      this.$.style.display = 'none';
      return;
    }

    this.items = [...this.$.parentElement.querySelectorAll('[power-fab-item]')].filter(
      item => window.getComputedStyle(item).display !== 'none',
    );
    this.actionsElement = this.$.querySelector('.power-fab-container-actions');
    this.actionsElement.style.setProperty(
      '--container-actions-height',
      `${58 * this.items.length}px`,
    );

    const actions = [];
    this.actionsElement.innerHTML = '';

    this.items.forEach(element => {
      const _viewmode = element.getAttribute('power-fab-item-viewmode') || '';

      if (this.stateConfig?.viewMode && _viewmode) {
        if (!_viewmode.split('|').includes(this.stateConfig.viewMode)) {
          return;
        }
      }

      const sourceIconElement = element.querySelector('i.material-icons');

      if (sourceIconElement && element.toggle) {
        const _color = element.getAttribute('power-fab-item-color') || '';
        const _bgColor = element.getAttribute('power-fab-item-bg-color') || '';

        const newIconElement = document.createElement('i');
        newIconElement.classList.add('material-icons');
        newIconElement.innerHTML = sourceIconElement.innerHTML;
        newIconElement.style.color = _color;

        const buttonElement = document.createElement('button');
        buttonElement.classList.add('action');
        buttonElement.style.backgroundColor = _bgColor;
        buttonElement.addEventListener('click', () => {
          element.toggle();
        });

        buttonElement.appendChild(newIconElement);
        actions.push(buttonElement);
      }
    });

    this.actionsElement.append(...actions);
  }

  __onViewModeChanged() {
    setTimeout(() => {
      this._loadItems();
    }, 250);
  }
}

class PowerFab {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerFabController;
  }
}

angular.module('power-fab', []).component('powerFab', new PowerFab());
