import angular from 'angular';
import moment from 'moment';
import * as Comlink from 'comlink';
import { toDateTimeOffset } from '@power/power-components/utils/date-util.js';

import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';
import { GetRouteConfig } from '../../configs/routes/routes.config';

import template from './golfleet-grid-tco-entry.html';
import './golfleet-grid-tco-entry.scss';

class GolfleetGridTcoEntryController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, { $ngRedux });

    this.pageRows = [];
    this.selectedRows = [];
    this.isAllSelected = false;

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./golfleet-grid-tco-entry.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.customActionsController = {
      linkToReport: data => {
        const routeConfig = GetRouteConfig(data.stateRouteId);

        // let initialDate;
        // let finalDate;

        // if (data.validaTimezone) {
        //   initialDate = new Date(data.dataPesquisaInicial);
        //   finalDate = new Date(data.dataPesquisaFinal);
        // } else {
          const initDate = moment(
            new Date(
              toDateTimeOffset({
                date: data.dataPesquisaInicial,
                language: 'en-US',
                timezone: 'America/Sao_Paulo',
              }),
            ),
          );
          const endDate = moment(
            new Date(
              toDateTimeOffset({
                date: data.dataPesquisaInicial,
                language: 'en-US',
                timezone: 'America/Sao_Paulo',
              }),
            ),
          );
          const initialDate = initDate.subtract('minutes', initDate.utcOffset() * -1)._d;
          const finalDate = endDate.subtract('minutes', endDate.utcOffset() * -1)._d;
        // }

        if (routeConfig) {
          const result = {
            ...routeConfig.data,
            stateConfig: routeConfig.data.stateConfig({
              filters: [
                {
                  id: 0,
                  default: [initialDate, finalDate],
                },
                {
                  id: 1,
                  /* INI - FIX - TEMPORÁRIO  - TROCAR POR FILTRO ENTIDADE */
                  activeView: 'entity',
                  activeEntity: 'vehicle',
                  /* END - FIX - TEMPORÁRIO  - TROCAR POR FILTRO ENTIDADE */
                  default: [data.veiculoId],
                  incluedHistoricalData: data.veiculoHistorico,
                },
              ],
            }),
          };

          this.$state.go(data.stateRouteId, {}, { reload: true });

          this.$ngRedux.dispatch({
            type: 'NEXT_ROUTE',
            data: result,
          });
        }
      },
    };
  }

  /* Lifecycle */
  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /**/

  _customAction(options) {
    const defaults = { action: '', data: {} };
    const result = Object.assign(defaults, options);

    const customAction = this.customActionsController[result.action];
    if (customAction) {
      customAction(result.data);
    }
  }

  _selectRow(column, row) {
    if (column.action && !row.lancamentoManual) return true && !!row[column.field];
    if (!column.link || (!row.lancamentoManual && column.field != 'placa')) return false;
    if (!column.linkValues || column.linkValues.length === 0) return true;

    return (
      column.linkValues.filter(ele => {
        const eleValue = ele.value || ele;
        const rowValue = ele.column ? row[ele.column] : row[column.field];
        return rowValue == eleValue || (eleValue == 'notNull' && !!rowValue);
      }).length > 0 && !!row[column.field]
    );
  }

  _hasRowSelection(row) {
    return !!row.lancamentoManual;
  }
}

class GolfleetGridTcoEntry {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridTcoEntryController;
  }
}

angular
  .module('golfleet-grid-tco-entry', ['ng-resize'])
  .component('golfleetGridTcoEntry', new GolfleetGridTcoEntry());
