import angular from 'angular';

import template from './golfleet-popup-driver-app-invite.html';
import './golfleet-popup-driver-app-invite.scss';

class GolfleetPopupDriverAppInviteController {
  static get $inject() {
    return ['$element', '$http', 'urlApi'];
  }

  constructor($element, $http, urlApi) {
    Object.assign(this, { $: $element[0], $http, urlApi });

    this.method = '';
    this.objects = [];
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setData: this.setData.bind(this),
    });
  }

  $onDestroy() {}
  // #endregion Lifecycle

  // #region Public
  toggle() {
    if (!this.$.hasAttribute('open')) this.$.setAttribute('open', '');
    else this.$.removeAttribute('open');
  }

  setData(data) {
    Object.assign(this, data);
  }
  // #endregion Public

  // #region Private
  _delete() {
    this.$.dispatchEvent(
      new CustomEvent('delete', {
        detail: {
          method: this.deleteMethod,
          objects: this.objects.map(ele => ({ objectId: ele.value })),
        },
        bubbles: true,
        composed: true,
      }),
    );
  }
  // #endregion Private
}

class GolfleetPopupDriverAppInvite {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetPopupDriverAppInviteController;
  }
}
angular
  .module('golfleet-popup-driver-app-invite', [])
  .component('golfleetPopupDriverAppInvite', new GolfleetPopupDriverAppInvite());
