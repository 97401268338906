/* global L: true */
import { PowerMapLeafletProvider } from '@power/power-components/components/power-map/providers/leaflet/power-map.leaflet-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';

import './golfleet-map-reverse-geocode.leaflet-provider.scss';

class GolfleetMapReverseGeocodeLeafletProvider extends PowerMapLeafletProvider {
  constructor(context, $element, $ngRedux, $scope, $rootScope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    Object.assign(this, { $rootScope });

    this.context.address = {};
    this.context.addressSearchText = null;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    Object.assign(this.$, {
      setMarker: this.setMarker.bind(this),
      resetMap: this.resetMap.bind(this),
    });

    const clickToLatLng = evt => {
      const { lat, lng } = evt.latlng;
      this._removeMarker();
      this.setMarker({ lat, lng });
      this.requesReverseGeocode({ lat, lng });
    };

    this._clickToLatLng = clickToLatLng.bind(this);
    this.mapLayer.on('click', this._clickToLatLng);
  }
  /**/

  /* Public */
  setMarker({ lat, lng }) {
    this._clickToLatLngMarker = L.marker([lat, lng]);
    this._clickToLatLngMarker.addTo(this.mapLayer);
    // this.mapLayer.setView([lat, lng], 18, { animate: true });
  }

  resetMap() {
    this._removeMarker();
    this.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
  }

  requestGeocoding() {
    this.$http({
      url: `${this.urlApi}/GeoCoding/Post`,
      method: 'POST',
      data: {
        request: { street: this.context.addressSearchText },
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data) {
          const { x: lat, y: lng } = success.data.data;
          this._removeMarker();
          this.setMarker({ lat, lng });
          this.requesReverseGeocode({ lat, lng });
          this.context.addressSearchText = '';
        }
      },
      () => {},
    );
  }

  requesReverseGeocode({ lat, lng }) {
    this.$http({
      url: `${this.urlApi}/GeoCoding/GetReverseGeocode`,
      method: 'POST',
      data: {
        latitude: lat,
        longitude: lng,
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data) {
          this.context.address = success.data.data;
          Object.assign(this.context.address, { latitude: lat, longitude: lng });
        }
      },
      () => {},
    );
  }
  /**/

  /* Private */
  _removeMarker() {
    if (this._clickToLatLngMarker) {
      this.mapLayer.removeLayer(this._clickToLatLngMarker);
      this._clickToLatLngMarker = null;
    }
  }
  /* */

  /* Observers */
  /**/
}

export { GolfleetMapReverseGeocodeLeafletProvider };
