import angular from 'angular';
import 'ng-redux';

import template from './power-loader.html';
import './power-loader.scss';

class PowerLoaderController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });
  }

  $onDestroy() {}
  /* */

  /* Public */
  toggle(show) {
    if (show) this.$.setAttribute('show', '');
    else this.$.removeAttribute('show');
  }
  /* */
}

class PowerLoader {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerLoaderController;
  }
}

angular.module('power-loader', ['ngRedux']).component('powerLoader', new PowerLoader());
