import 'ng-redux';

import '../power-dropdown/power-dropdown';
import '../power-toast/power-toast';

import { ImportRouteConfig } from '../../utils/get-route-config';

class PowerUserMenuController {
  static get $inject() {
    return ['$element', '$ngRedux', '$state', '$scope', '$http', 'urlApi', 'commonServices'];
  }

  constructor($element, $ngRedux, $state, $scope, $http, urlApi, commonServices) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      urlApi,
      commonServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        token: behavior.session.token,
        isAdm: behavior.session.isAdm,
        userName: behavior.session.userName,
        companyName: behavior.session.companyName,
        isSingleSignon: behavior.session.isSingleSignon,
        userReportQuantity: behavior.session.userReportQuantity,
        userReportSharedQuantity: behavior.session.userReportSharedQuantity,
        userAutoSendQuantity: behavior.session.userAutoSendQuantity,
        showSwitchAccount:
          !behavior.session.isSingleSignon &&
          behavior.session.isTrip &&
          behavior.session.qtdUser > 1,
        otpClientRequest: behavior.session.otpClientRequest,
        otpActive: behavior.session.otpActive,
        otpBlockSwitch: behavior.session.otpBlockSwitch,
      }),
    )(this);

    this.toastText = '';
    this.pwaInstallable = false;

    if (this.isSingleSignon) {
      this.$.setAttribute('single-signon', '');
    }

    this.$scope.$watch(() => this.token, this.__onUserSessionChanged.bind(this));
  }

  /* Lifecycle */
  $onInit() {
    this.$.addEventListener('switchAccountPopup', this._togglePopup.bind(this));
    this.$.addEventListener('showToastAccountPopup', this._showToast.bind(this));
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  async _newRoute(route) {
    this.$.querySelector('power-dropdown').toggle();
    const routeConfig = await ImportRouteConfig(route);

    if (routeConfig) {
      let routeData = {};

      if (route === 'reports') {
        const filterObj = {
          field: 'autoSendsCountRange',
          default: [1, null],
        };
        routeData = {
          ...routeConfig.data,
          stateConfig: routeConfig.data.stateConfig({ filters: [filterObj] }),
        };
      } else {
        routeData = {
          ...routeConfig.data,
          stateConfig: routeConfig.data.stateConfig({ filters: [] }),
        };
      }

      this.$ngRedux.dispatch({
        type: 'NEXT_ROUTE_SINGLE',
        data: routeData,
      });
      this.$state.go(routeConfig.name, {}, { reload: true });
    }
  }

  _installPwa() {
    document.querySelector('#app-component').callPwaBanner();
  }

  _togglePopup() {
    this.$.querySelector('#popup-switch-account').toggle();
  }

  _showToast(evt) {
    this.toastText = evt.detail.message;
    this.$.querySelector('power-toast#toast-user-menu').toggle(true);
  }

  __onUserSessionChanged(token) {
    if (token !== '') return;

    const popupElement = this.$.querySelector('power-popup');
    const dropdownElement = this.$.querySelector('power-dropdown');

    if (popupElement.hasAttribute('open')) {
      popupElement.toggle();
    }

    if (dropdownElement.hasAttribute('open')) {
      dropdownElement.toggle();
    }
  }
}

export { PowerUserMenuController };
