import angular from 'angular';
import 'ng-redux';
import 're-tree';
import 'ua-device-detector';
import 'ng-device-detector';
import 'angular-easy-masks';

import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-single-checkbox/power-single-checkbox';
import '../golfleet-switch-account/golfleet-switch-account';
import { PowerLoginController } from '@power/power-components/components/power-login/power-login';
import '../golfleet-popup-otp/golfleet-popup-otp';

import template from './golfleet-login.html';
import './golfleet-login.scss';

class GolfleetLoginController extends PowerLoginController {
  static get $inject() {
    return [
      '$element',
      '$timeout',
      '$ngRedux',
      '$state',
      '$http',
      'urlApi',
      'tipoApp',
      'deviceDetector',
      'commonServices',
      '_applications',
      '$scope',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $timeout,
    $ngRedux,
    $state,
    $http,
    urlApi,
    tipoApp,
    deviceDetector,
    commonServices,
    _applications,
    $scope,
  ) {
    super(
      $element,
      $timeout,
      $ngRedux,
      $state,
      $http,
      urlApi,
      tipoApp,
      deviceDetector,
      commonServices,
      _applications,
      $scope,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    this._appBehavior();
    this.$.removeEventListener('keydown', this.keepSessionKeyEvent);
    this.$.removeEventListener('keydown', this.eventResetPassConfirm);
  }
  /* */

  /* Public */
  number1 = '';

  number2 = '';

  number3 = '';

  number4 = '';

  number5 = '';

  number6 = '';
  /* */

  /* Private */
  _directLogin(getParams) {
    if (getParams.login && getParams.pass && getParams.usuID)
      this._login(getParams.login, getParams.pass, false, [getParams.usuID]);
    else if (getParams.client_guid && getParams.user_guid && getParams.single_sign_on) {
      this._login(getParams.client_guid, getParams.user_guid, false, [0, getParams.single_sign_on]);
    }
  }

  _changeView(view) {
    /* views:
      - login
      - recover
      - reset
      - download
      - request-single-signon
      - fail-request-single-signon
      - otp
    */
    this.$.querySelector('#view-container').setAttribute('view', view);
    if (view == 'recover') this.emailRecover = this.email;
    if (view == 'login') this.$timeout(() => this.$.querySelector('#login-card-email').focus());
    if (view == 'otp') this.$timeout(() => this.otpInputFocus(1));
  }

  _btnRequest() {
    const type = this.$.querySelector('#view-container').getAttribute('view');
    if (this._validateBeforeSend(type)) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      if (type == 'login') this._login(this.email, this.password, this.keepSession);
      else if (type == 'recover') this._recover(this.emailRecover);
      else if (type == 'reset') this._reset(this.email, this.resetPasswordConfirm);
      else if (type == 'otp') {
        const code = `${this.number1}${this.number2}${this.number3}${this.number4}${this.number5}${this.number6}`;
        if (code.length === 6) {
          this._otp(this.email, code, this.keepSession);
        }
      }
    }
  }

  _keepSessionKey(evt) {
    if (evt.key == 'Enter') this._login(this.email, this.password, this.keepSession);
  }

  _resetPassConfirm(evt) {
    if (
      (evt.ctrlKey || evt.metaKey) &&
      (evt.keyCode == 67 || evt.keyCode == 86 || evt.keyCode == 91)
    ) {
      return evt.preventDefault();
    }
    return null;
  }
  /* */

  /* Protected */

  _loginPasswordOnchange() {
    this.contentStrongForce = '';

    const classStrongPass = this.$.querySelector('#class-strong');
    const stringPass = this.$.querySelector('#email-reset').value;

    const hasNumber = /[0-9]/.test(stringPass);
    const hasLetterInPassword = /[a-z]/.test(stringPass);
    const hasLetterUppercase = /[A-Z]/.test(stringPass);

    const hasSpecialCharacter = /(?=.*[!@#\\$%\\^&\\*])/.test(stringPass);

    const strongRegularExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})',
    );
    const mediumRegularExp = new RegExp(
      '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
    );

    if (stringPass.length > 0) {
      if (stringPass.length >= 10) {
        document.querySelector('.ten-character').setAttribute('checked', '');
        document.querySelector('.ten-character').removeAttribute('disabled', '');
        document
          .querySelector('.requirements-eigth-number-sucess')
          .classList.add('requirements-text');

        document.querySelector('.content-strentgh-warning').classList.remove('pass-low-warning');
      } else {
        document.querySelector('.ten-character').removeAttribute('checked', '');
        document.querySelector('.ten-character').setAttribute('disabled', '');
        document
          .querySelector('.requirements-eigth-number-sucess')
          .classList.remove('requirements-text');

        document.querySelector('.content-strentgh-warning').classList.add('pass-low-warning');
      }

      if (hasNumber) {
        document.querySelector('.has-number').setAttribute('checked', '');
        document.querySelector('.has-number').removeAttribute('disabled', '');
        document
          .querySelector('.requirements-has-number-sucess')
          .classList.add('requirements-text');
      } else {
        document.querySelector('.has-number').removeAttribute('checked', '');
        document.querySelector('.has-number').setAttribute('disabled', '');
        document
          .querySelector('.requirements-has-number-sucess')
          .classList.remove('requirements-text');
      }

      if (hasLetterInPassword) {
        document.querySelector('.has-letter').setAttribute('checked', '');
        document.querySelector('.has-letter').removeAttribute('disabled', '');
        document.querySelector('.requirements-has-letter').classList.add('requirements-text');
      } else {
        document.querySelector('.has-letter').removeAttribute('checked', '');
        document.querySelector('.has-letter').setAttribute('disabled', '');
        document.querySelector('.requirements-has-letter').classList.remove('requirements-text');
      }

      if (hasLetterUppercase) {
        document.querySelector('.has-uppercase').setAttribute('checked', '');
        document.querySelector('.has-uppercase').removeAttribute('disabled', '');
        document.querySelector('.requirements-text-uppercase').classList.add('requirements-text');
      } else {
        document.querySelector('.has-uppercase').removeAttribute('checked', '');
        document.querySelector('.has-uppercase').setAttribute('disabled', '');
        document
          .querySelector('.requirements-text-uppercase')
          .classList.remove('requirements-text');
      }

      if (hasSpecialCharacter) {
        document.querySelector('.has-special-character').setAttribute('checked', '');
        document.querySelector('.has-special-character').removeAttribute('disabled', '');
        document
          .querySelector('.requirements-has-special-character-sucess')
          .classList.add('requirements-text');
      } else {
        document.querySelector('.has-special-character').removeAttribute('checked', '');
        document.querySelector('.has-special-character').setAttribute('disabled', '');
        document
          .querySelector('.requirements-has-special-character-sucess')
          .classList.remove('requirements-text');
      }

      if (strongRegularExp.test(stringPass)) {
        classStrongPass.setAttribute('class', 'regular-strong-pass');
        this.contentStrongForce = 'Forte';
      } else if (mediumRegularExp.test(stringPass)) {
        classStrongPass.setAttribute('class', 'medium-strong-pass');
        this.contentStrongForce = 'Fraca';
      } else {
        classStrongPass.setAttribute('class', 'low-strong-pass');
        this.contentStrongForce = ' Muito Fraca';
      }
    } else {
      classStrongPass.removeAttribute('class', 'low-strong-pass');
      document.querySelector('.content-strentgh-warning').classList.remove('pass-low-warning');
    }
  }

  _validateBeforeSend(form) {
    const commonMsg = 'Por favor, preencha todos os campos corretamente.';

    if (form == 'login') {
      let message = commonMsg;
      this.email = this.$.querySelector('#login-card-email').value;
      this.password = this.$.querySelector('#login-card-password').value;

      const { email } = this;
      const { password } = this;

      if (email && password) return true;

      if (!email && password) message = 'O email precisa ser preenchido.';
      else if (!password && email) message = 'A senha precisa ser preenchida.';
      else message = 'Os campos precisam ser preenchidos.';

      this.tippyLogin.setContent(message);

      if (this.isMobile) {
        Object.assign(this.toastObj, { text: message });
        this.$.querySelector('#toast-obj').toggle(true);
      } else this.$timeout(() => this.tippyLogin.show());

      return false;
    }
    if (form == 'recover') {
      let message = commonMsg;
      const { emailRecover } = this;
      if (emailRecover) return true;

      message = 'O email precisa ser preenchido.';
      this.tippyRecover.setContent(message);
      if (this.isMobile) {
        Object.assign(this.toastObj, { text: message });
        this.$.querySelector('#toast-obj').toggle(true);
      } else this.$timeout(() => this.tippyRecover.show());
      return false;
    }
    if (form == 'reset') {
      const { resetPassword } = this;
      const { resetPasswordConfirm } = this;
      const hasNumberInPassword = /\d/.test(resetPassword);
      const hasLetterInPassword = /[a-zA-Z]/.test(resetPassword);
      const hasLetterLowercase = /[a-z]/.test(resetPassword);
      const hasLetterUppercase = /[A-Z]/.test(resetPassword);
      const hasSpecialCharacter = /(?=.*[!@#\\$%\\^&\\*])/.test(resetPassword);

      const error = { hasError: false, message: commonMsg };

      if (!hasNumberInPassword || !hasLetterInPassword)
        Object.assign(error, {
          hasError: true,
          message: 'Digite ao menos uma letra e um número nos dois campos.',
        });
      else if (resetPassword.length < 10)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 10 caracteres nos dois campos.',
        });
      else if (!hasLetterUppercase)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 1 letra maiúscula.',
        });
      else if (!hasLetterLowercase)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 1 letra minúscula.',
        });
      else if (!hasSpecialCharacter)
        Object.assign(error, {
          hasError: true,
          message: 'Digite no mínimo 1 caracter especial. Ex: "*".',
        });
      else if (resetPassword !== resetPasswordConfirm)
        Object.assign(error, {
          hasError: true,
          message: 'As senhas estão diferentes.',
        });
      else if (!resetPassword || !resetPasswordConfirm) Object.assign(error, { hasError: true });

      if (error.hasError) {
        this.tippyReset.setContent(error.message);
        if (this.isMobile) {
          Object.assign(this.toastObj, { text: error.message });
          this.$.querySelector('#toast-obj').toggle(true);
        } else this.$timeout(() => this.tippyReset.show());
        return false;
      }
      return true;
    }
    return false;
  }

  _insertLog() {
    return this.commonServices.platformLog({ logtype: 'LoginGolfleet' });
  }

  _updateImages(sessionData) {
    if (this.application) {
      let faviconSrc = '';

      if (this.application.toLowerCase() === 'golfleet') {
        faviconSrc = sessionData.isVideoTelemetry
          ? `/assets/images/favicon-golfleet-videotelemetry.png`
          : `/assets/images/favicon-golfleet-telemetry.png`;
      } else {
        faviconSrc = `/assets/images/favicon-golfleet-${this.application.toLowerCase()}.png`;
      }

      document.querySelector('[rel="shortcut icon"]').href = faviconSrc;
    }
    if (sessionData.logoHeight) {
      document.querySelector(
        '#shell-header-logo',
      ).style.backgroundSize = `auto ${sessionData.logoHeight}`;
    }

    document.documentElement.style.setProperty(
      '--user-photo',
      `url(${sessionData.userPhoto || '/assets/images/user-photo-placeholder.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--header-icon',
      `url(${sessionData.headerIcon || '/assets/images/telemetry-icon.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--header-logo',
      `url(${sessionData.headerLogo || '/assets/images/golfleet.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--trade-mark-icon',
      `url(${sessionData.tradeMarkIcon || '/assets/images/golfleet-icon.svg'})`,
    );
  }

  _fetchRecoverPassword(email) {
    return this.$http({
      url: `${this.urlApi}/User/RedefinePassword`,
      method: 'POST',
      data: { email, application: 'GOLFLEET' },
    });
  }

  _openSwitchAccount() {
    this.$.querySelector('#section-switch-account golfleet-switch-account').dispatchEvent(
      new CustomEvent('getSwitchAccounts', { bubbles: true, composed: true }),
    );
  }

  _configElementSwitchAccount() {
    this.$.querySelector('golfleet-switch-account .footer').setAttribute('login-screen', '');
    this.$.querySelector('golfleet-switch-account .footer .confirm span').innerText = 'ENTRAR';
  }
  /* */

  _otpInputKeyDown(event) {
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
    const isCtrlV = (event.ctrlKey || event.metaKey) && (event.key === 'v' || event.key === 'V'); // Ctrl+V or Cmd+V
    if (!validKeys.includes(event.key) && !isCtrlV) {
      event.preventDefault();
      return;
    }
    if (event.key === 'Backspace') {
      if (this.number6) {
        this.otpInputFocus(6);
      } else if (this.number5) {
        this.otpInputFocus(5);
      } else if (this.number4) {
        this.otpInputFocus(4);
      } else if (this.number3) {
        this.otpInputFocus(3);
      } else if (this.number2) {
        this.otpInputFocus(2);
      } else {
        this.otpInputFocus(1);
      }
    } else if (!this.number1) {
      this.otpInputFocus(1);
    } else if (!this.number2) {
      this.otpInputFocus(2);
    } else if (!this.number3) {
      this.otpInputFocus(3);
    } else if (!this.number4) {
      this.otpInputFocus(4);
    } else if (!this.number5) {
      this.otpInputFocus(5);
    } else {
      this.otpInputFocus(6);
    }
  }

  _otpInputKeyUp(event, number) {
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
    if (!validKeys.includes(event.key)) {
      event.preventDefault();
    } else if (number === 6 && event.key !== 'Backspace') {
      this._checkCode();
    } else if (event.key !== 'Backspace') {
      this.otpInputFocus(number + 1);
    }
  }

  _otpPaste(event) {
    const value = event.clipboardData.getData('text');

    event.preventDefault();

    if (value.length !== 6) return;

    const [number1, number2, number3, number4, number5, number6] = value.split('');

    this.number1 = parseInt(number1);
    this.number2 = parseInt(number2);
    this.number3 = parseInt(number3);
    this.number4 = parseInt(number4);
    this.number5 = parseInt(number5);
    this.number6 = parseInt(number6);

    this._checkCode();
  }

  _checkCode() {
    const code = `${this.number1}${this.number2}${this.number3}${this.number4}${this.number5}${this.number6}`;
    if (code.length === 6) {
      this._otp(this.email, code, this.keepSession);
    }
  }

  otpInputFocus(number) {
    this.$.querySelector(`#number-${number}`).focus();
  }

  clearOtp() {
    this.number1 = '';
    this.number2 = '';
    this.number3 = '';
    this.number4 = '';
    this.number5 = '';
    this.number6 = '';
    this.otpError = false;
  }
}

class GolfleetLogin {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetLoginController;
  }
}

angular
  .module('golfleet-login', [
    'ngRedux',
    'ng.deviceDetector',
    'power-toast',
    'power-single-checkbox',
    'golfleet-switch-account',
    'golfleet-popup-otp',
  ])
  .component('golfleetLogin', new GolfleetLogin());
