/* eslint-disable no-undef */
import angular from 'angular';
import moment from 'moment';

import template from './power-crud-datepicker.html';
import './power-crud-datepicker.scss';

class PowerCrudDatepickerController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope'];
  }

  constructor($element, $scope, $rootScope) {
    Object.assign(this, { $: $element[0], $scope, $rootScope });
    this.value = null;

    this.minTriggerLength = null;

    $.fn.datepicker.language['pt-BR'] = {
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      daysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yyyy',
      timeFormat: 'hh:ii',
      firstDay: 0,
    };
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      setDate: this.setDate.bind(this),
      setWarning: this.setWarning.bind(this),
    });

    setTimeout(() => {
      const element = $(this.$.querySelector(`#datepicker-${this.key}`));

      element.on('click touch', () => {
        const coordinates = element[0].getBoundingClientRect();
        const datePicker = document.querySelector('.power-crud-datepicker.active');
        if (coordinates.top < 360) {
          datePicker.classList.add('open-in-top');
        } else {
          datePicker.classList.remove('open-in-top');
        }

        if (coordinates.right < 340) {
          datePicker.classList.add('open-in-left');
        } else {
          datePicker.classList.remove('open-in-left');
        }
      });

      element
        .datepicker({
          language: 'pt-BR',
          classes: `power-crud-datepicker`,
          toggleSelected: false,
          dateFormat: this.dateFormat || 'dd/mm/yyyy',
          position: this.position || 'top left',
          autoClose: true,
          navTitles: {
            days: 'MM / yyyy',
          },
          onSelect: (_formattedDate, date) => {
            this.value = date;
            this.inputtedValue = _formattedDate;
            this.setWarning('');

            if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
              this.$rootScope.$apply();
            }
          },
        })
        .data('datepicker');
    }, 350);
  }
  // #endregion Lifecycle

  // #region Public
  setDate(date) {
    const datepicker = $(this.$.querySelector(`#datepicker-${this.key}`))
      .datepicker()
      .data('datepicker');
    if (date) {
      const formattedValue = date ? moment(date)._d : null;
      datepicker.selectDate(formattedValue);
    }
  }

  setWarning(text) {
    this.warning = text;
  }
  // #endRegion Public

  // #region observers
  __onDateInputChanged() {
    if (this.inputtedValue) {
      if (this.inputtedValue.length >= 10) {
        if (moment(this.inputtedValue, 'DD/MM/YYYY', true).isValid()) {
          const datepicker = $(this.$.querySelector(`#datepicker-${this.key}`))
            .datepicker()
            .data('datepicker');

          const formattedValue = moment(this.inputtedValue, 'DD/MM/YYYY', true)._d;
          datepicker.date = formattedValue;
          datepicker.selectDate(formattedValue);
        } else {
          this.setWarning(`Data inválida`);
        }
      } else {
        this.setWarning('');
      }
    }
  }
  // #endRegion observers
}

class PowerCrudDatepicker {
  constructor() {
    this.template = template;
    this.bindings = {
      key: '=',
      value: '=?',
      name: '=',
      warning: '=?',
      hint: '=?',
      required: '=?',
      disabled: '=?',
      icon: '=?',
      position: '=?',
    };
    this.controller = PowerCrudDatepickerController;
  }
}

angular
  .module('power-crud-datepicker', [])
  .component('powerCrudDatepicker', new PowerCrudDatepicker());
