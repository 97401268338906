import angular from 'angular';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-grid-vehicles/power-grid-vehicles';
import '@power/power-components/components/power-map-vehicles/power-map-vehicles';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-move-vehicles/power-popup-move-vehicles';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-header-drag-n-drop/power-header-drag-n-drop';
import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerReportVehiclesController } from '@power/power-components/components/power-report-vehicles/power-report-vehicles';

import template from './golfleet-report-vehicles.html';
import './golfleet-report-vehicles.scss';

class GolfleetReportVehiclesController extends PowerReportVehiclesController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  // constructor(
  //   $element,
  //   $scope,
  //   $rootScope,
  //   $ngRedux,
  //   $http,
  //   $state,
  //   $timeout,
  //   urlApi,
  //   commonServices,
  //   filterServices,
  //   reportServices,
  //   recordServices,
  // ) {
  //   super(
  //     $element,
  //     $scope,
  //     $rootScope,
  //     $ngRedux,
  //     $http,
  //     $state,
  //     $timeout,
  //     urlApi,
  //     commonServices,
  //     filterServices,
  //     reportServices,
  //     recordServices,
  //   );
  // }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  /* */

  /* Observers */
  /* */
}

class GolfleetReportVehicles {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportVehiclesController;
  }
}

angular
  .module('golfleet-report-vehicles', [
    'power-fab',
    'power-report',
    'power-toolbar-report',
    'power-dropdown',
    'power-grid-vehicles',
    'power-map-vehicles',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-popup-share',
    'power-popup-move-vehicles',
    'power-toast',
    'power-header-drag-n-drop',
    'ng-resize',
  ])
  .component('golfleetReportVehicles', new GolfleetReportVehicles());
