import angular from 'angular';
import moment from 'moment';
import 'ng-redux';

import '../power-toast/power-toast';
import '../power-popup/power-popup';
import '../power-popup-event-medias/power-popup-event-medias';
import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-media-events.html';
import './power-media-events.scss';

class PowerMediaEventsController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', '$http', '$state', 'urlApi'];
  }

  constructor($element, $scope, $ngRedux, $http, $state, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $ngRedux,
      $http,
      $state,
      urlApi,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        /* Session Storage */
        session: behavior.session,
      });
    })(this);

    this.toastText = '';
    this.optionsList = [];
    this._optionsList = [];
    this.viewPhotoList = [];
    this.photoEventList = [];
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getDataset: this.getDataset.bind(this),
    });

    this.$scope.$watch(() => this.page, this.__onPageChanged.bind(this));
    this.$scope.$watch(() => this.pageSize, this.__onPageSizeChanged.bind(this));
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  getDataset(dataset) {
    this.photoEventList = dataset.map((data, index) => {
      const { id, dataHora, eventoGerador, placa, alias, fotosPorEvento, videosPorEvento } = data;

      const photoEventItem = {
        data: [],
        params: {
          mrvId: id,
          dataHora,
        },
        fotosPorEvento,
        videosPorEvento,
        placa,
        dataHora,
        alias,
        eventoGerador,
        ready: false,
        error: false,
      };

      if (index >= (this.page - 1) * this.pageSize && index < this.page * this.pageSize)
        this._requestPhotoEventItemData(photoEventItem);

      return photoEventItem;
    });
  }
  /* */

  /* Private */
  _requestPhotoEventItemData(photoEventItem) {
    if (!this.session.isTrip || this.session.isSingleSignon) {
      photoEventItem.error = true;
      photoEventItem.ready = true;
      return;
    }

    if (photoEventItem.fotosPorEvento?.length === 0) {
      return;
    }

    this.$http({
      url: `${this.urlApi}/TelematicsReport/GetMidiaByEvents`,
      method: 'POST',
      data: {
        request: {
          mrvId: photoEventItem.params.mrvId,
          dataHora: new Date(photoEventItem.params.dataHora),
        },
      },
    }).then(
      response => {
        const { data: result } = response.data;
        photoEventItem.data = [...result.data];
        photoEventItem.error = false;
        photoEventItem.ready = true;
      },
      () => {
        photoEventItem.error = true;
        photoEventItem.ready = true;
      },
    );
  }

  _toDate(date) {
    if (!date) return '- - -';
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _viewMedia(eventMediaList) {
    if (eventMediaList.length === 0) {
      return;
    }

    this.$.querySelector('#popup-view-media').viewMediaList(
      eventMediaList.data,
      eventMediaList.eventoGerador,
    );
  }

  _getCardPhotoBackground(eventPhotoList) {
    if (eventPhotoList.length === 0) {
      return '';
    }

    let background = '';
    eventPhotoList.forEach(eventPhotoItem => {
      if (!eventPhotoItem.image.hasError) {
        if (background.length === 0) {
          background += eventPhotoItem.image.isUrl
            ? `url(${eventPhotoItem.image.result}) no-repeat`
            : `url(data:image/jpeg;base64,${eventPhotoItem.image.result}) no-repeat`;
        } else {
          background += eventPhotoItem.image.isUrl
            ? `, url(${eventPhotoItem.image.result}) no-repeat`
            : `, url(data:image/jpeg;base64,${eventPhotoItem.image.result}) no-repeat`;
        }
      } else {
        background += `url(${'assets/images/img_wait.png'}) no-repeat`;
      }
    });

    return background;
  }

  _getImageUrl(mediaList, data) {
    return data.isUrl ? data.result : `data:image/jpeg;base64,${data.result}`;
  }

  _hasNotLoadedMedia(mediaList) {
    return mediaList.every(media => media.image.hasError);
  }

  _hasEmptyMediaList(item) {
    return (
      (!item.fotosPorEvento || item.fotosPorEvento.length === 0) &&
      (!item.videosPorEvento || item.videosPorEvento.length === 0)
    );
  }

  _hasOnlyVideos(eventMediaList) {
    return eventMediaList.every(
      media => media.tipoMidiaDescricao === 'VIDEO' && !media.image.hasError,
    );
  }
  /* */

  /* Observers */
  __onPageChanged(newValue) {
    if (!this.photoEventList || this.photoEventList.length === 0) return;
    for (let index = (newValue - 1) * this.pageSize; index < newValue * this.pageSize; index += 1) {
      if (index >= this.photoEventList.length) break;
      if (!this.photoEventList[index].ready)
        this._requestPhotoEventItemData(this.photoEventList[index]);
    }
  }

  __onPageSizeChanged(newValue) {
    if (!this.photoEventList || this.photoEventList.length === 0) return;
    for (let index = (this.page - 1) * newValue; index < this.page * newValue; index += 1) {
      if (index >= this.photoEventList.length) break;
      if (!this.photoEventList[index].ready)
        this._requestPhotoEventItemData(this.photoEventList[index]);
    }
  }
  /* */
}

class PowerMediaEvents {
  constructor() {
    this.template = template;
    this.bindings = {
      page: '=',
      pageSize: '=',
      updateFunction: '&',
    };
    this.controller = PowerMediaEventsController;
  }
}

angular
  .module('power-media-events', [
    'ngRedux',
    'power-toast',
    'power-popup',
    'power-popup-event-medias',
    'infinite-scroll',
  ])
  .component('powerMediaEvents', new PowerMediaEvents());
