import 'ng-redux';

class PowerFormFleetPolicyController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
    ];
  }

  /**
   * Creates an instance of PowerFormFleetPolicyController.
   * @memberof PowerFormFleetPolicyController
   */
  constructor($element, $sce, $ngRedux, $rootScope, $state, $http, $scope, commonServices, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior => {
      const { modules } = behavior.session;

      return Object({
        /* Session Storage */
        state: behavior.state,
        modules,
      });
    })(this);

    this.fleetPoliciesNames = [];
    this.formConfig = [];
    this.form = [];
    this.staticFormData = [];
    this.policyId = this._getPolicyIdFromState();
    this.policyIdToCopy = this._getPolicyIdFromState('copy');
    this.canSubmit = true;

    this.formReady = false;
    this.formConfigReady = false;

    this.formViewData = [];

    this.hours = '';
    this.periodConfig = {
      type: 'period',
      field: 'periodBits',
      icon: 'gavel',
      plural: 'Períodos',
      singular: 'Período',
      description: 'Período',
      options: [],
      halfhour: true,
      visible: true,
      autoInitialize: true,
      default: [0, 0, 0, 0, 0, 0, 0],
    };

    this.programmedBlockValue = [];
    this.programmedBlockOptions = [];

    this.sectionHelperConfig = {};
    this.vehicleListPopup = {};
    this.driverListPopup = {};
    this.fetchingConfigurationVehicles = false;
  }

  /* Lifecycle */
  $onInit() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this._getFleetPoliciesToCopyPeriod();
    this._getFormConfig();
    this._getFormData();
    this._checkReady();
    this._removeFilter();

    this.$scope.$on('change_selected_item', (event, option) => {
      this._onItemChange(option);
    });
  }

  _onItemChange(newOption) {
    const needChanges = [];
    this.formConfig.forEach(config => {
      config.sections.forEach(s => {
        s.items.forEach(i => {
          if (i.conditions)
            i.conditions.forEach(con => {
              if (con.objectIdTarget === newOption.objectId) needChanges.push(i);
            });
        });
      });
    });

    if (needChanges.length > 0) {
      needChanges.forEach(i => {
        this._filterOptionsForItens(i, newOption);
      });
    }
  }

  _filterOptionsForItens(item, newOption) {
    item.conditions
      .filter(c => c.objectIdTarget === newOption.objectId)
      .forEach(c => {
        const oldOption = item.options.filter(o => o.selected)[0];
        if (c.condition === '<') {
          const original = this._getOriginalItem(item.field);
          item.options = original.options.filter(
            o => o.simpleValue < newOption.simpleValue || !o.simpleValue,
          );
          if (oldOption.simpleValue && oldOption.simpleValue < newOption.simpleValue) {
            item.options[0].selected = false;
            item.options.find(o => o.simpleValue === oldOption.simpleValue).selected = true;
          }
        }
        if (c.condition === '!=') {
          const original = this._getOriginalItem(item.field);
          item.options = original.options.filter(
            o => o.simpleValue != newOption.simpleValue || !o.simpleValue,
          );
          if (oldOption.simpleValue && oldOption.simpleValue != newOption.simpleValue) {
            item.options[0].selected = false;
            item.options.find(o => o.simpleValue === oldOption.simpleValue).selected = true;
          }
        }
        if (c.condition === 'disabled') {
          const original = this._getOriginalItem(item.field);
          if (newOption.value === c.targetValue) {
            item.options = [];
            item.options.push(original.options[0]);
          } else {
            item.options = original.options;
            item.options[0].selected = false;
            item.options.find(o => o.value === oldOption.value).selected = true;
          }
        }
      });
  }

  _getOriginalItem(field) {
    let original = null;
    this.formConfigOriginal.forEach(config => {
      config.sections.forEach(s => {
        s.items.forEach(i => {
          if (i.field === field) original = i;
        });
      });
    });
    return JSON.parse(JSON.stringify(original));
  }

  _toggleHelperModal(modal, sectionHelperConfig) {
    if (sectionHelperConfig) {
      this.sectionHelperConfig = {
        ...sectionHelperConfig,
        shouldRequest: sectionHelperConfig.id !== this.sectionHelperConfig.id,
      };
    }

    this.$.querySelector(`power-form-fleet-policy > #popup-policy-helper-${modal}`).toggle();
  }

  _removeFilter() {
    this.state.routeList[0].stateConfig.filterCondition = [];
    this.state.routeList[0].stateConfig.filterConfig = [];
  }

  _checkReady() {
    setTimeout(() => {
      if (this.formReady && this.formConfigReady) {
        this._formViewDataParser();
        this._setDeleteButtonState();
      } else {
        this._checkReady();
      }
    }, 300);
  }

  _getPolicyIdFromState(copy) {
    const route = this.state.routeList[0];
    const { stateConfig } = route;

    if (route) {
      if (copy) {
        return stateConfig.policyIdToCopy ? stateConfig.policyIdToCopy : null;
      }

      return stateConfig.policyId ? stateConfig.policyId : null;
    }

    return null;
  }

  _getFormConfig() {
    this.commonServices.getFormConfiguration('FleetPolicyAdm').then(data => {
      if (data.form) {
        this.formConfig = data.form;
      }
      this.formConfigReady = true;
    });
  }

  _formViewDataParser() {
    this.formViewData = this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              const field = this._getFormViewData(item.field);
              if (item.field == 'fleetPolicyName') {
                item.value = this.form.fleetPolicyName;
              } else if (item.field == 'fleetPolicyDescription') {
                item.value = this.form.fleetPolicyDescription;
              } else if (item.field == 'period') {
                item.complementId = field.complementId;
                item.id = field.id;
                item.isRequired = field.isRequired;
                item.value = field.value.split(',');
              } else if (field) {
                item.complement = field.complement;
                item.complementId = field.complementId;
                item.id = field.id;
                item.isRequired = field.isRequired;
                item.label = field.label;
                item.minValue = field.minValue;
                item.objectId = field.objectId;
                item.options = field.options;
                item.simpleValue = field.simpleValue;
                item.value = field.value;
                if (field.conditions) {
                  item.conditions = field.conditions;
                }
              }

              if (item.type == 'combobox' && item.value) {
                // eslint-disable-next-line no-return-assign
                item.options.forEach(option => {
                  option.selected = option.value == item.value;
                });
              }

              if (item.type == 'number' && item.value) {
                // eslint-disable-next-line radix
                item.value = parseInt(item.value);
                // eslint-disable-next-line radix
                item.simpleValue = parseInt(item.simpleValue);
                // eslint-disable-next-line radix
                field.value = parseInt(field.value);
              }

              return item;
            });
          }
        });
      }
      return config;
    });

    this.formConfigOriginal = JSON.parse(JSON.stringify(this.formViewData));
    const itensAsTargetCondition = [];
    this.formConfig.forEach(config => {
      config.sections.forEach(s => {
        s.items.forEach(i => {
          if (i.conditions)
            i.conditions.forEach(con => {
              itensAsTargetCondition.push(this._getOriginalItem(con.objectIdTarget));
            });
        });
      });
    });

    const itens = this._distinctByProp(itensAsTargetCondition, 'field');
    itens.forEach(i => this._onItemChange(i));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: false },
        bubbles: true,
        composed: true,
      }),
    );

    this._syncScope();
  }

  _distinctByProp(array, prop) {
    return array.filter(
      (item, index, self) => index === self.findIndex(obj => obj[prop] === item[prop]),
    );
  }

  _syncScope() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) this.$scope.$apply();
  }

  _getFormViewData(field) {
    let filtredField = null;

    if (field) {
      if (field == 'fleetPolicyName') {
        filtredField = this.form.fleetPolicyName;
      } else if (field == 'fleetPolicyDescription') {
        filtredField = this.form.fleetPolicyDescription;
      } else {
        filtredField = this.form.fleetPolicyItems.find(item => item.objectId == field);
      }
    }

    return filtredField;
  }

  _getFormData() {
    let id = null;

    if (this.policyIdToCopy !== null && this.policyId == null) {
      id = this.policyIdToCopy;
    } else if (this.policyId !== null && this.policyIdToCopy == null) {
      id = this.policyId;
    } else if (this.policyId && this.policyIdToCopy) {
      this.policyIdToCopy = null;
      id = this.policyId;
    }

    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/GetFormFleetPolicy`,
      method: 'POST',
      data: {
        request: {
          id,
        },
      },
    }).then(success => {
      const { data } = success.data;

      if (success.status && success.status !== 200) {
        this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.text } });
        return;
      }

      this.form = data;
      this.staticFormData = JSON.parse(JSON.stringify(data));

      if (this.policyIdToCopy !== null && this.policyId == null) {
        if (this.form.fleetPolicyName) {
          this.form.fleetPolicyName = `(Cópia) - ${this.form.fleetPolicyName}`;
        }
        if (this.form.fleetPolicyDescription) {
          this.form.fleetPolicyDescription = `(Cópia) - ${this.form.fleetPolicyDescription}`;
        }
      }

      this.formReady = true;

      const [programmedBlock] = this.form.fleetPolicyItems.filter(
        item => item.objectId == 'programmedBlock',
      );
      if (programmedBlock) {
        const programmedBlockValue = programmedBlock.value.split(';').map(item => item);
        const programmedBlockOptions = programmedBlock.options;

        this.programmedBlockValue = programmedBlockValue;
        this.programmedBlockOptions = programmedBlockOptions;
      }
    });
  }

  _generateHoursDescription() {
    const periodElement = this.$.querySelector('power-period');
    this.hours = periodElement.getHoursDescription();

    return this.hours;
  }

  _setDeleteButtonState() {
    const button = this.$.querySelector('.delete-btn');

    if (button) {
      if (!this.policyId) {
        button.setAttribute('disabled', '');
      } else if (this.policyId && button.hasAttribute('disabled')) {
        button.removeAttribute('disabled');
      }
    }
  }

  _deleteCrudForm() {
    if (!this.canSubmit) return;

    this.canSubmit = false;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/DeleteFleetPolicy`,
      method: 'POST',
      data: {
        request: {
          id: this.policyId,
        },
      },
    })
      .then(success => {
        const { data } = success.data;

        this.canSubmit = true;

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );

        if ((success.status && success.status !== 200) || !success.data) {
          this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
          Object.assign(this, { toast: { text: data } });
          return;
        }

        this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: 'Política de frota excluída' } });

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        if (data) {
          this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
          Object.assign(this, { toast: { text: 'Política de frota excluída' } });

          setTimeout(() => {
            this._cancelCrudForm();
          }, 1500);
        }
      })
      .catch(error => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );

        this.$.querySelector('#form-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: error.data.data[0] } });
      });
  }

  _validateNumberFields(item) {
    if (item.simpleValue == 0) {
      item.simpleValue = null;
    }

    return item.simpleValue;
  }

  _confirmCrudForm() {
    const parsedData = {};
    const emptyValues = [];
    const limitValuesInvalid = [];

    parsedData.id = this.policyId;
    parsedData.name = '';
    parsedData.description = '';
    parsedData.items = this.formViewData.reduce((acc, mainSections) => {
      if (mainSections.sections && mainSections.sections.length > 0) {
        mainSections.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.forEach(item => {
              let value = '';
              if (item.field == 'fleetPolicyName') {
                if (!item.value) {
                  item.error = true;
                  emptyValues.push(item);
                }
                parsedData.name = item.value;
              } else if (item.field == 'fleetPolicyDescription') {
                if (!item.value) {
                  item.error = true;
                  emptyValues.push(item);
                }
                parsedData.description = item.value;
              } else if (item.field == 'period') {
                value = this.$.querySelector('power-period').getValue().join();
                item.hasUpdated = item.value.toString() !== value;
              } else if (item.type == 'combobox') {
                const selectedValue = item.options.filter(option => option.selected);
                if (selectedValue.length == 0 && item.isRequired) {
                  item.error = true;
                  emptyValues.push(item);
                } else {
                  value = selectedValue[0].value;
                }
              } else if (item.type == 'number') {
                if (
                  !!item.simpleValue &&
                  ((item.minValue && item.simpleValue < item.minValue) ||
                    (item.maxValue && item.simpleValue > item.maxValue))
                ) {
                  item.limitValuesInvalid = true;
                  limitValuesInvalid.push(item);
                }

                if (!item.simpleValue && item.isRequired) {
                  item.error = true;
                  emptyValues.push(item);
                } else {
                  value = item.simpleValue;
                }
              }
              if (item.field == 'programmedBlock') {
                value = this.modules.includes('BLOQUEIO_PROGRAMADO')
                  ? this.$.querySelector('power-programmed-block').getValue()
                  : 'Desabilitado';
                item.hasUpdated = item.value.toString() !== value;
                if (item.hasUpdated && item.value !== 'Desabilitado') {
                  this.arrayValue = value.split(';').map(itemarray => itemarray);
                  if (this.arrayValue[0] === '1' && this.arrayValue[6] === '0000000') {
                    item.error = true;
                    this.$.querySelector('power-programmed-block').validateHasEmptyParams();
                    emptyValues.push(item);
                  }
                }
              }

              const currentItem = this.staticFormData.fleetPolicyItems.find(
                staticItem => staticItem.objectId == item.objectId,
              );

              if (currentItem && item.type == 'combobox') {
                const [filteredItem] = item.options.filter(
                  filteredOption => filteredOption.selected === true,
                );
                item.hasUpdated = currentItem.value !== filteredItem.value;
              } else if (currentItem && item.type == 'number') {
                item.hasUpdated = item.simpleValue !== currentItem.simpleValue;
              }
              if (item.field != 'fleetPolicyName' && item.field != 'fleetPolicyDescription') {
                acc.push({
                  configurationId: item.id,
                  value,
                  hasUpdated: item.hasUpdated,
                });
              }
            });
          }
        });
      }

      return acc;
    }, []);

    if (emptyValues.length > 0) {
      this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
        },
      });
    } else if (limitValuesInvalid.length > 0) {
      this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações precisam ser preenchidas respeitando o limite mínimo e máximo de valores.',
        },
      });
    } else {
      this._upsertFleetPolicy(parsedData);
    }
  }

  _removeErrorAttr(item) {
    item.error = false;
  }

  _removeLimitValuesErrorAttr(item) {
    // to clear the zeros when click in field uncomment the line below
    // this._validateNumberFields(item);

    item.limitValuesInvalid = false;
  }

  _getFleetPoliciesToCopyPeriod() {
    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/GetFleetPolicyNames`,
      method: 'POST',
    }).then(success => {
      const { data } = success.data;

      if (success.status && success.status !== 200) {
        this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.text } });
        return;
      }

      this.fleetPoliciesNames = data;
    });
  }

  _upsertFleetPolicy(request) {
    if (!this.canSubmit) return;

    this.canSubmit = false;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/UpsertFleetPolicy`,
      method: 'POST',
      data: {
        request,
      },
    }).then(success => {
      const { data } = success.data;

      this.canSubmit = true;

      if (success.status && success.status !== 200) {
        this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.text } });
        return;
      }

      if (data) {
        this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
        Object.assign(this, {
          toast: {
            text: `Política de frota ${this.policyId ? 'atualizada' : 'cadastrada'}`,
          },
        });

        setTimeout(() => {
          this._cancelCrudForm();
        }, 1500);
      }
    });
  }

  _cancelCrudForm() {
    this._backTo(1);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }

  _copyPeriodFromFleetPolicy(fleetPolicy) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$http({
      url: `${this.urlApi}/FleetPolicyAdm/GetFleetPolicyDetail`,
      method: 'POST',
      data: {
        request: {
          id: fleetPolicy.id,
        },
      },
    }).then(success => {
      const { data } = success.data;

      if (success.status && success.status !== 200) {
        this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.text } });
        return;
      }

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      if (data) {
        const [period] = data.items.filter(item => item.objectId == 'period');
        const periodValue = period.value.split(',').map(item => Number(item));

        const periodElement = this.$.querySelector('power-period');
        periodElement.setValue(periodValue);
      }
    });
  }

  _clearPeriodValue() {
    this.$.querySelector('power-period').clearValue();
  }

  /**
   * Voice Recognition functions
   */
  _switchVoiceRecognition() {
    if (this.voiceRecognitionStarted) {
      this._stopVoiceRecognition();
    } else {
      this._startVoiceRecognition();
    }
  }

  _startVoiceRecognition() {
    this.recognition.start();

    setTimeout(() => {
      this.voiceRecognitionStarted = true;
      this._syncScope();
    }, 500);
  }

  _stopVoiceRecognition() {
    this.voiceRecognitionStarted = false;
    this.recognition.stop();
    this._syncScope();
  }

  _filterItemByModule(itemList) {
    return itemList.filter(
      item =>
        !item.validateModule || this.modules.some(module => item.validateModule.includes(module)),
    );
  }

  _getConfigurationVehicles() {
    if (this.sectionHelperConfig.shouldRequest) {
      const [configurationList] = this.sectionHelperConfig.sections.map(section =>
        this._filterItemByModule(section.items.filter(item => item.identifier)).map(
          item => item.identifier,
        ),
      );

      if (configurationList.length > 0) {
        this.fetchingConfigurationVehicles = true;

        this.$http({
          url: `${this.urlApi}/${this.sectionHelperConfig.method}`,
          method: 'POST',
          data: {
            request: {
              identifier: configurationList,
            },
          },
        })
          .then(response => {
            const { data: result } = response.data;

            result.data.forEach(item => {
              const aux = this.sectionHelperConfig.sections
                .find(section =>
                  section.items.find(sectionItem => sectionItem.identifier === item.identifier),
                )
                .items?.find(sectionItem => sectionItem.identifier === item.identifier);

              if (aux) {
                aux.data = { ...item };
              }

              this.fetchingConfigurationVehicles = false;
            });
          })
          .catch(() => {
            this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
            Object.assign(this, {
              toast: {
                text: 'Ops! Ocorreu um erro ao carregar os veículos compatíveis com o item.',
              },
            });
            this.fetchingConfigurationVehicles = false;
          });
      }
    }
  }

  _toggleVehicleListPopup(actionType, list) {
    if (list?.length === 0) return;

    if (actionType && list) {
      this.vehicleListPopup = {
        title: `Você tem ${list.length} ${
          list.length == 1 ? actionType.singular : actionType.plural
        }`,
        list,
      };
    }

    this.$.querySelector('#popup-vehicle-list').toggle();
  }

  _toggleDriverListPopup(actionType, list) {
    if (list?.length === 0) return;

    if (actionType && list) {
      this.driverListPopup = {
        title: `Você tem ${list.length} ${
          list.length == 1 ? actionType.singular : actionType.plural
        }`,
        list,
      };
    }

    this.$.querySelector('#popup-driver-list').toggle();
  }

  _checkItem(item, itemCount) {
    if (item.validateQuantity) {
      return itemCount > item.validateQuantity;
    }
    return true;
  }

  _getActionTippyTitle(action, count) {
    if (count == 0) {
      return `Nenhum ${action.singular}`;
    }

    return `${count} ${count == 1 ? action.singular : action.plural}`;
  }
}

export { PowerFormFleetPolicyController };
