import angular from 'angular';
import 'ng-redux';

import { PowerUserMenuController } from '@power/power-components/components/power-user-menu/power-user-menu';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toast/power-toast';
import '../golfleet-switch-account/golfleet-switch-account';
import '../golfleet-popup-otp/golfleet-popup-otp';

import template from './golfleet-user-menu.html';
import './golfleet-user-menu.scss';

class GolfleetUserMenuController extends PowerUserMenuController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'urlApi',
      'commonServices',
      'version',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor($element, $ngRedux, $state, $scope, $http, urlApi, commonServices, version) {
    super($element, $ngRedux, $state, $scope, $http, urlApi, commonServices);

    Object.assign(this, {
      version,
    });
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  async signout() {
    await this.commonServices.platformLog({ logtype: 'LogOutGolfleet' });
    this.$.dispatchEvent(new CustomEvent('signout', { bubbles: true, composed: true }));
  }

  openSupport() {
    document.querySelector('golfleet-support-helper').tooglePopupFromMenu();
  }

  _openPopup() {
    this.$.querySelector('#popup-switch-account golfleet-switch-account').dispatchEvent(
      new CustomEvent('getSwitchAccounts', {
        bubbles: true,
        composed: true,
        detail: {
          callback: () => {
            this._togglePopup();
          },
        },
      }),
    );
  }

  _openOtpPopup() {
    if (!this.otpBlockSwitch) {
      if (this.otpActive) {
        this.$.querySelector('#popup-otp').togglePopup(false, true, true);
      } else {
        this.$.querySelector('#popup-otp').togglePopup(true, true, false);
      }
    }
  }
}

class GolfleetUserMenu {
  constructor() {
    this.template = template;
    this.bindings = {
      pwaInstallable: '=?',
    };
    this.controller = GolfleetUserMenuController;
  }
}
angular
  .module('golfleet-user-menu', [
    'ngRedux',
    'power-dropdown',
    'golfleet-switch-account',
    'power-toast',
    'golfleet-popup-otp',
  ])
  .component('golfleetUserMenu', new GolfleetUserMenu());
