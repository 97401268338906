import angular from 'angular';
import moment from 'moment';

import $ from 'jquery';
import * as Comlink from 'comlink';
import {
  add,
  sub,
  isEqual,
  isAfter,
  isBefore,
  isSameMonth,
  endOfMonth,
  startOfMonth,
} from 'date-fns';
import 'air-datepicker';
import 'air-datepicker/dist/css/datepicker.min.css';

import { PowerFilterController, PowerFilter } from '../power-filter/power-filter';
import '../../directives/infinite-scroll/infinite-scroll';
import '../power-dropdown/power-dropdown';

import template from './power-filter-calendar-utc.html';
import './power-filter-calendar-utc.scss';
import { toUTCDate } from '../../utils/date-util';

class PowerFilterCalendarUtcController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$ngRedux', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super($element, $scope, $http, urlApi);
    Object.assign(this, { $ngRedux, $rootScope });

    this.__powerStore = $ngRedux.connect(store => Object({ isTrip: store.session.isTrip }))(
      this,
    );

    this.icon = '';
    this.filterId = '';
    this.timepicker = false;
    this.allowFutureDate = false;
    this.activeView = 'preset';
    this.calendarView = 'days';
    this.activeCalendar = 'startDate';
    this.minInterval = 0;
    this.maxInterval = 3;
    this.minIntervalType = 'day';
    this.maxIntervalType = 'month';

    this.startDate = {
      calendar: null,
      hour: 0,
      minute: 0,
    };
    this.endDate = {
      calendar: null,
      hour: 23,
      minute: 59,
    };
    this.search = '';
    this.options = [];
    this.hourList = [];
    this.minuteList = [];
    this.default = [];
    this.condition = {};
    this.description = 'Carregando...';
    this.previewDescription = this.description;

    this.worker = new Worker('./power-filter-calendar-utc.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    $.fn.datepicker.language['pt-BR'] = {
      daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      daysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yyyy',
      timeFormat: 'hh:ii',
      firstDay: 0,
    };
    for (let hour = 0; hour < 24; hour += 1) {
      this.hourList.push({ id: hour, description: `${hour < 10 ? '0' : ''}${hour}`, value: hour });
    }
    for (let minute = 0; minute < 60; minute += 1) {
      this.minuteList.push({
        id: minute,
        description: `${minute < 10 ? '0' : ''}${minute}`,
        value: minute,
      });
    }

    this.dateDescriptionFormat = new Intl.DateTimeFormat(navigator.language, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  /* Lifecycle */
  $onInit() {
    this.$.loading = true;

    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setPresetFilterValue: this.setPresetFilterValue.bind(this),
      setCalendarFilterValue: this.setCalendarFilterValue.bind(this),
      getNumberOfDays: this.getNumberOfDays.bind(this),
    });

    const _calendarConfig = {
      language: 'pt-BR',
      navTitles: { days: 'MM de yyyy' },
      view: this.calendarView,
      minView: this.calendarView,
      minDate: new Date(new Date(2008, 0, 1).setHours(0, 0, 0, 0)),
      maxDate: this._setMaxDate(),
      range: false,
      timepicker: false,
      allowFutureDate: false,
      toggleSelected: false,
      onSelect: this.__onSelect.bind(this),
      onChangeView: this.__onChangeView.bind(this),
      onChangeYear: this.__onChangeYear.bind(this),
      onChangeMonth: this.__onChangeMonth.bind(this),
      onChangeDecade: this.__onChangeDecade.bind(this),
    };

    this.startDate.calendar = $(this.$.querySelector('#startdate-calendar'))
      .datepicker({ ..._calendarConfig })
      .data('datepicker');
    this.endDate.calendar = $(this.$.querySelector('#enddate-calendar'))
      .datepicker({ ..._calendarConfig })
      .data('datepicker');

    this._setup().then(() => {
      this.$.loading = false;
    });
  }

  $onDestroy() {
    this.__powerStore();
  }
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }

  async setPresetFilterValue(preset = {}) {
    await this.workerService.setFilterValue({ preset });

    await this._changeActiveView('preset');

    await this._syncState();

    this.requestUpdate();

    this.$.dispatchEvent(new CustomEvent('setPresetFilterValue'));
  }

  async setCalendarFilterValue(value = []) {
    await this._changeActiveView('calendar');

    const endDate = new Date(value[1]);
    const startDate = new Date(value[0]);

    this.startDate.hour = startDate.getHours();
    this.startDate.minute = startDate.getMinutes();
    this.startDate.calendar.selectDate(startDate);

    this.endDate.hour = endDate.getHours();
    this.endDate.minute = endDate.getMinutes();
    this.endDate.calendar.selectDate(endDate);

    this.$.dispatchEvent(new CustomEvent('setCalendarFilterValue'));
  }

  getNumberOfDays() {
    if (this.condition) {
      const startDate = new Date(this.condition.value[0]);
      const endDate = new Date(this.condition.value[1]);

      return Math.round(((endDate.getTime() - startDate.getTime()) / 24) * 60 * 60 * 1000);
    }

    return 0;
  }
  /* */

  /* Private */
  async _setup() {
    await this.workerService.setup({
      id: this.filterId,
      field: this.field,
      options: this.options,
      usePreset: true,
      condition: this.condition,
      timepicker: this.timepicker,
      defaultCondition: this.default,
      allowFutureDate: this.allowFutureDate,
    });

    await this._syncState();

    const endDate = new Date(this.condition.value[1]);
    const startDate = new Date(this.condition.value[0]);

    this.startDate.hour = startDate.getHours();
    this.startDate.minute = startDate.getMinutes();
    this.startDate.calendar.selectDate(startDate);

    this.endDate.hour = endDate.getHours();
    this.endDate.minute = endDate.getMinutes();
    this.endDate.calendar.selectDate(endDate);

    this.activeView = !this.condition.preset ? 'calendar' : 'preset';

    this.requestUpdate();

    this.$.dispatchEvent(new CustomEvent('setup'));
    this.$.dispatchEvent(new CustomEvent('activeViewChanged'));
  }

  async _syncState() {
    this.options = await this.workerService.filteredOptions;
    this.condition = await this.workerService.condition;
    this.description = await this.workerService.description;
    this.previewDescription = await this.workerService.previewDescription;

    this.$.dispatchEvent(new CustomEvent('syncState'));
    this.$.dispatchEvent(new CustomEvent('presetChanged'));
    this.$.dispatchEvent(new CustomEvent('optionsChanged'));
    this.$.dispatchEvent(new CustomEvent('conditionChanged'));
    this.$.dispatchEvent(new CustomEvent('descriptionChanged'));
  }

  async _selectTime(value, calendar, type) {
    this[calendar][type] = value;

    await this.workerService.setFilterValue({
      value: [
        new Date(
          this.startDate.calendar.selectedDates[0].setHours(
            this.startDate.hour,
            this.startDate.minute,
            0,
          ),
        ),
        new Date(
          this.endDate.calendar.selectedDates[0].setHours(
            this.endDate.hour,
            this.endDate.minute,
            59,
          ),
        ),
      ],
    });

    await this._syncState();

    this.requestUpdate();

    this.$.dispatchEvent(new CustomEvent('timeChanged'));
  }

  async _changeActiveView(view) {
    this.activeView = view;

    await this.workerService.setUsePreset({ usePreset: this.activeView === 'preset' });

    await this._syncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('activeViewChanged'));
  }

  async _toggleOptionSelection(selectedOption) {
    await this.workerService.toggleOptionsSelection(selectedOption);

    await this._syncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleOptionSelection'));
  }

  _toDate(date) {
    return moment(date).utcOffset(-3)._d;
  }

  requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  _updateOtherSelected() {
    const [endSelectedDate] = this.endDate.calendar.selectedDates;
    const [startSelectedDate] = this.startDate.calendar.selectedDates;

    const betweenSelected = async (calendar, startSelected, endSelected) => {
      const endYear = endSelected.getFullYear();
      const endDate = endSelected.getDate();
      const endMonth = endSelected.getMonth();
      const startYear = startSelected.getFullYear();
      const startDate = startSelected.getDate();
      const startMonth = startSelected.getMonth();

      let currentViewYear = null;
      let isBetweenSelected = false;

      calendar.el.querySelectorAll('.datepicker--cell').forEach(node => {
        switch (calendar.currentView) {
          case 'days':
            isBetweenSelected =
              /* Between Dates on the same Month and same Year */
              (node.getAttribute('data-date') > startDate &&
                node.getAttribute('data-date') < endDate &&
                node.getAttribute('data-month') === `${startMonth}` &&
                node.getAttribute('data-month') === `${endMonth}` &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              /* Between Months on the same Year */
              (node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-month') < endMonth &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              (node.getAttribute('data-date') > startDate &&
                node.getAttribute('data-month') === `${startMonth}` &&
                node.getAttribute('data-month') < endMonth &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              (node.getAttribute('data-date') < endDate &&
                node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-month') === `${endMonth}` &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') === `${endYear}`) ||
              /* Between Years */
              (node.getAttribute('data-year') > startYear &&
                node.getAttribute('data-year') < endYear) ||
              (node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') < endYear) ||
              (node.getAttribute('data-month') < endMonth &&
                node.getAttribute('data-year') > startYear &&
                node.getAttribute('data-year') === `${endYear}`) ||
              (node.getAttribute('data-date') > startDate &&
                node.getAttribute('data-month') === `${startMonth}` &&
                node.getAttribute('data-year') === `${startYear}` &&
                node.getAttribute('data-year') < endYear) ||
              (node.getAttribute('data-date') < endDate &&
                node.getAttribute('data-month') === `${endMonth}` &&
                node.getAttribute('data-year') > startYear &&
                node.getAttribute('data-year') === `${endYear}`);
            break;
          case 'months':
            currentViewYear = calendar.views.months.d.currentDate.getFullYear();
            isBetweenSelected =
              /* Months between same Year */
              (node.getAttribute('data-month') > startMonth &&
                node.getAttribute('data-month') < endMonth &&
                currentViewYear === startYear &&
                currentViewYear === endYear) ||
              /* Months between Years */
              (currentViewYear > startYear && currentViewYear < endYear) ||
              (node.getAttribute('data-month') > startMonth &&
                currentViewYear === startYear &&
                currentViewYear < endYear) ||
              (node.getAttribute('data-month') < endMonth &&
                currentViewYear > startYear &&
                currentViewYear === endYear);
            break;
          case 'years':
            isBetweenSelected =
              node.getAttribute('data-year') > startYear &&
              node.getAttribute('data-year') < endYear;
            break;
          default:
            break;
        }

        if (isBetweenSelected) {
          node.classList.add('-between-selected-');
        } else {
          node.classList.remove('-between-selected-');
        }
      });
    };
    const setOtherSelected = async (calendar, otherSelected) => {
      const otherYear = otherSelected.getFullYear();
      const otherDate = otherSelected.getDate();
      const otherMonth = otherSelected.getMonth();

      let otherDateNode = null;
      let currentViewYear = null;

      switch (calendar.currentView) {
        case 'days':
          otherDateNode = calendar.el.querySelector(
            `.datepicker--cells-days [data-date="${otherDate}"][data-month="${otherMonth}"][data-year="${otherYear}"]`,
          );
          if (otherDateNode) {
            otherDateNode.classList.add('-other-selected-');
          }
          break;
        case 'months':
          currentViewYear = calendar.views.months.d.currentDate.getFullYear();
          if (currentViewYear === otherYear) {
            otherDateNode = calendar.el.querySelector(
              `.datepicker--cells-months [data-month="${otherMonth}"]`,
            );
            if (otherDateNode) {
              otherDateNode.classList.add('-other-selected-');
            }
          }
          break;
        case 'years':
          otherDateNode = calendar.el.querySelector(
            `.datepicker--cells-years [data-year="${otherYear}"]`,
          );
          if (otherDateNode) {
            otherDateNode.classList.add('-other-selected-');
          }
          break;
        default:
          break;
      }
    };
    const clearOtherSelected = calendar => {
      const otherSelected = calendar.el.querySelector('.-other-selected-');
      if (otherSelected) otherSelected.classList.remove('-other-selected-');
    };

    clearOtherSelected(this.startDate.calendar);
    setOtherSelected(this.startDate.calendar, endSelectedDate);
    betweenSelected(this.startDate.calendar, startSelectedDate, endSelectedDate);

    clearOtherSelected(this.endDate.calendar);
    setOtherSelected(this.endDate.calendar, startSelectedDate);
    betweenSelected(this.endDate.calendar, startSelectedDate, endSelectedDate);
  }

  _changeActiveCalendar(calendar) {
    this.activeCalendar = calendar;

    this.requestUpdate();

    this.$.dispatchEvent(new CustomEvent('activeCalendarChanged'));
  }

  _setMaxDate() {
    const dateNow = new Date(new Date().setHours(23, 59, 59, 999));
    if (this.allowFutureDate) {
      return this._addYears(dateNow, 2);
    }
    return dateNow;
  }

  _addYears(date, years) {
    const futureDate = new Date(date);
    futureDate.setFullYear(futureDate.getFullYear() + years);
    return futureDate;
  }
  /* */

  /* Observers */
  async __onSelect() {
    let addParams = {};
    let minDate = new Date();
    let maxDate = new Date();
    let [endDate] = this.endDate.calendar.selectedDates;
    let [startDate] = this.startDate.calendar.selectedDates;

    endDate = endDate || startDate;
    startDate = startDate || endDate;

    if (this.maxInterval || this.maxInterval === 0) {
      addParams = {};
      addParams[this.maxIntervalType] = this.maxInterval;

      if (this.activeCalendar === 'startDate') {
        maxDate = add(startDate, addParams);

        if (isBefore(maxDate, endDate)) {
          this.endDate.calendar.selectDate(maxDate);
          return;
        }
      } else {
        maxDate = sub(endDate, addParams);

        if (isAfter(maxDate, startDate)) {
          this.startDate.calendar.selectDate(maxDate);
          return;
        }
      }
    }

    if (this.minInterval || this.minInterval === 0) {
      addParams = {};
      addParams[this.minIntervalType] = this.minInterval;

      if (this.activeCalendar === 'startDate') {
        minDate = add(startDate, addParams);

        if (isEqual(startDate, this.endDate.calendar.maxDate)) {
          if (this.minInterval > 0) {
            minDate = sub(startDate, addParams);

            this.startDate.calendar.selectDate(minDate);
            return;
          }
        }
        if (isAfter(minDate, endDate) && !isAfter(minDate, this._setMaxDate())) {
          this.endDate.calendar.selectDate(minDate);
          return;
        }
      } else {
        minDate = sub(endDate, addParams);

        if (isEqual(endDate, this.startDate.calendar.minDate)) {
          if (this.minInterval > 0) {
            minDate = add(endDate, addParams);

            this.endDate.calendar.selectDate(minDate);
            return;
          }
        }
        if (isBefore(minDate, startDate) && !isAfter(minDate, this._setMaxDate())) {
          this.startDate.calendar.selectDate(minDate);
          return;
        }
      }
    }

    if (this.activeView === 'calendar') {
      endDate = new Date(endDate.setHours(this.endDate.hour, this.endDate.minute, 59));
      startDate = new Date(startDate.setHours(this.startDate.hour, this.startDate.minute, 0));

      if (this.calendarView === 'month') {
        endDate = isSameMonth(new Date(), endDate) ? endDate : endOfMonth(endDate);
        startDate = startOfMonth(startDate);
      }

      await this.workerService.setFilterValue({
        value: [startDate, endDate],
      });

      await this._syncState();

      this._changeActiveCalendar(this.activeCalendar === 'startDate' ? 'endDate' : 'startDate');

      this.$.dispatchEvent(new CustomEvent('calendarDateChanged'));
    }

    requestAnimationFrame(() => this._updateOtherSelected());
  }

  async __onSearchChanged(evt) {
    this.search = evt.target.value;

    await this.workerService.filterOptions({ search: this.search });

    await this._syncState();

    this.requestUpdate();
  }

  __onChangeView() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  __onChangeYear() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  __onChangeMonth() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  __onChangeDecade() {
    requestAnimationFrame(() => this._updateOtherSelected());
  }

  _searchFilterOption(value) {
    if (this.condition && this.condition.preset && this.condition.preset._search) {
      return new RegExp(
        this.preset._search
          .toLowerCase()
          .replace(/^( *, *)+|( *, *)+$/g, '')
          .replace(/( *, *)+/g, '|'),
      ).test(value.description.toLowerCase());
    }
    return true;
  }
  /* */
}

class PowerFilterCalendarUtc extends PowerFilter {
  constructor() {
    super();
    Object.assign(this.bindings, {
      previewDescription: '=?',
      minInterval: '=?',
      maxInterval: '=?',
      minIntervalType: '=?',
      maxIntervalType: '=?',
      calendarView: '=?',
      timepicker: '=?',
      allowFutureDate: '=?',
    });
    this.template = template;
    this.controller = PowerFilterCalendarUtcController;
  }
}

angular
  .module('power-filter-calendar-utc', ['infinite-scroll', 'power-dropdown'])
  .component('powerFilterCalendarUtc', new PowerFilterCalendarUtc());
