import angular from 'angular';

import { PowerMapController } from '@power/power-components/components/power-map/power-map';
import { PowerMapGeofencesHereProvider } from './providers/here/power-map-geofences.here-provider';
import { PowerMapGeofencesLeafletProvider } from './providers/leaflet/power-map-geofences.leaflet-provider';

import template from './power-map-geofences.html';

class PowerMapGeofencesController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapGeofencesHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapGeofencesLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      startDraw: this.startDraw.bind(this),
      endDraw: this.endDraw.bind(this),
      cancelDraw: this.cancelDraw.bind(this),
    });

    super.$onInit();
  }
  /**/

  /* Public */
  startDraw({ callback, dataset }) {
    this.provider.startDraw({ callback, dataset });
  }

  endDraw() {
    this.provider.endDraw();
  }

  cancelDraw() {
    this.provider.cancelDraw();
  }

  selectDrawShape(shape) {
    this.provider.selectDrawShape(shape);
  }
  /**/
}

class PowerMapGeofences {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerMapGeofencesController;
  }
}

angular
  .module('power-map-geofences', [])
  .component('powerMapGeofences', new PowerMapGeofences());
