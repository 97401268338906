import angular from 'angular';

import '@power/power-components/directives/ng-tippy/ng-tippy';

import template from './golfleet-dashboard-list.html';

class GolfleetDashboardListController {
  static get $inject() {
    return ['$element', '$timeout', 'commonServices'];
  }

  constructor($element, $timeout, commonServices) {
    Object.assign(this, { $: $element[0], $timeout, commonServices });
  }

  /* Lifecycle */
  $onInit() {
    this.$timeout(() => {
      const imgs = this.$.querySelectorAll('.gs-l-dashboard-list-item img');

      imgs.forEach(item => {
        const imgUrl = item.getAttribute('src');

        if (imgUrl) {
          this.commonServices.getSvg(imgUrl, svg => {
            let svgElement = document.createElement('svg');

            svgElement.innerHTML = svg;
            svgElement = svgElement.firstChild;
            svgElement.setAttribute('width', '50px');
            svgElement.setAttribute('height', '46px');

            item.parentNode.replaceChild(svgElement, item);
          });
        }
      });
    });
  }
  /* Lifecycle */
}

class GolfleetDashboardList {
  constructor() {
    this.template = template;
    this.bindings = {
      configData: '=',
    };
    this.controller = GolfleetDashboardListController;
  }
}

angular
  .module('golfleet-dashboard-list', ['ng-tippy'])
  .component('golfleetDashboardList', new GolfleetDashboardList());
