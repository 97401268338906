import angular from 'angular';
import { toDateTimeOffset } from '../../utils/date-util';

import { PowerMapPointsOfInterestHereProvider } from './providers/here/power-map-points-of-interest.here-provider';
import { PowerMapPointsOfInterestLeafletProvider } from './providers/leaflet/power-map-points-of-interest.leaflet-provider';
import { PowerMapController } from '../power-map/power-map';

import template from './power-map-points-of-interest.html';

class PowerMapPointsOfInterestController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapPointsOfInterestHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapPointsOfInterestLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      startDraw: this.startDraw.bind(this),
      endDraw: this.endDraw.bind(this),
      cancelDraw: this.cancelDraw.bind(this),
    });

    super.$onInit();
  }
  /**/

  /* Public */
  startDraw({ callback, dataset }) {
    this.provider.startDraw({ callback, dataset });
  }

  endDraw() {
    this.provider.endDraw();
  }

  cancelDraw() {
    this.provider.cancelDraw();
  }

  selectDrawShape(shape) {
    this.provider.selectDrawShape(shape);
  }

  toggleNearBySearch(isClickIcon) {
    this.provider.toggleNearBySearch(isClickIcon);
  }

  setCurrentPoint(dataset) {
    this.provider.setCurrentPoint(dataset);
  }
  /**/

  /* Private */
  _toDateTimeOffset(dateJson) {
    // const dateTimeFormat = new Intl.DateTimeFormat(navigator.language, {
    //   day: '2-digit',
    //   month: '2-digit',
    //   year: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    // });
    // return dateTimeFormat.format(new Date(dateJson));
    return new Date(dateJson).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  }

  _showMarker(vehicle) {
    this.provider._showMarker(vehicle);
  }
  /**/
}

class PowerMapPointsOfInterest {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerMapPointsOfInterestController;
  }
}

angular
  .module('power-map-points-of-interest', [])
  .component('powerMapPointsOfInterest', new PowerMapPointsOfInterest());
