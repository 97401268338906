import angular from 'angular';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import 'highcharts-regression';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/helpers/format-number/format-number';

import template from './golfleet-dashboard-chart-list.html';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);

class GolfleetDashboardChartListController {
  static get $inject() {
    return ['$element', '$timeout'];
  }

  constructor($element, $timeout) {
    Object.assign(this, { $: $element[0], $timeout });

    this.chart = null;
    this.chartTitle = null;
    this.resizeSensor = null;
    this.viewElement = null;
    this.data = null;
    this.matchTablet = null;
    this.matchTabletEvt = null;
  }

  /* Lifecycle */
  $onInit() {
    this.$timeout(() => {
      this.viewElement = document.querySelector('ui-view');

      if (this.configData.data.filter(item => item.porcentagem == 0).lenght > 0) {
        this.data = [
          {
            y: 0,
            color: '#777',
          },
        ];
      } else {
        this.data = this.configData.data.map(item => ({
          name: item.metrica,
          y: item.porcentagem,
          color: item.config.color,
        }));
      }

      if (this.viewElement.clientWidth <= 1024)
        this._createChart('gs-cl-dashboard-chart-container', 'column', this.data);
      else this._createChart('gs-cl-dashboard-chart-container', 'pie', this.data);
    });
  }

  $onDestroy() {
    this.matchTablet.removeListener(this.matchTabletEvt);
  }
  /* */

  /* Private */
  _createChart(element, type, data) {
    this.chart = new Highcharts.Chart({
      chart: {
        renderTo: this.$.querySelector(`#${element}`),
        type,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        style: {
          fontFamily: 'Ubuntu',
          fontSize: '12px',
        },
        spacingBottom: 0,
        marginBottom: 10,
      },
      title: {
        text: null,
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          enableMouseTracking: true,
          dataLabels: {
            enabled: false,
          },
          slicedOffset: 0,
          size: '100%',
          innerSize: '80%',
          point: {
            events: {
              mouseOver() {
                this.series.chart.customOptions.chartTitle.attr({
                  text: this.series.chart.customOptions.setChartTitle(
                    this.series.chart.customOptions.dataset,
                    this.y,
                    this.color,
                  ),
                });
                this.series.chart.customOptions.setChartTitlePosition();
              },
            },
          },
        },
        column: {
          borderRadius: 5,
          colorByPoint: true,
          pointWidth: 8,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 0,
          shadow: false,
        },
      },
      exporting: { enabled: false },
      series: [
        {
          borderColor: null,
          data,
        },
      ],
    });

    data.forEach((item, index) => {
      const el = document.querySelector(`.highcharts-series .highcharts-color-${index}`);

      el.style.fill = item.color;
    });

    this.chartTitle = this.chart.renderer
      .label(this.__setChartTitle(data), null, null, null, null, null, true)
      .add();

    this.chart.customOptions = {
      chartTitle: this.chartTitle,
      setChartTitle: this.__setChartTitle.bind(this),
      setChartTitlePosition: this.__setChartTitlePosition.bind(this),
      dataset: data,
    };

    this.matchTablet = window.matchMedia('(max-width: 1024px)');
    this.matchTabletEvt = evt => {
      const listHeight = this.$.querySelector('.gs-cl-dashboard-list ul').clientHeight;

      if (evt.matches) {
        if (this.chart.options.chart.type == 'column') this.chart.setSize(undefined, listHeight);
        else {
          this._createChart('gs-cl-dashboard-chart-container', 'column', this.data);
          this.chart.setSize(undefined, listHeight);
        }
      } else if (this.chart.options.chart.type == 'pie') this.__setChartTitlePosition();
      else this._createChart('gs-cl-dashboard-chart-container', 'pie', this.data);
    };
    this.matchTablet.addListener(this.matchTabletEvt);

    this.$timeout(() => {
      if (document.querySelector('.gs-cl-dashboard-list ul')) {
        this.chart.setSize(
          undefined,
          document.querySelector('.gs-cl-dashboard-list ul').clientHeight,
        );
      }

      this.__setChartTitlePosition();
    }, 600);
  }
  /* */

  // #region Protected
  __setChartTitle(data, value, color) {
    const maxItemValues = data.reduce(
      (acc, item) => {
        if (item.y >= acc.y) return { ...item };

        return acc;
      },
      { name: '', y: 0, color: '' },
    );
    const maxValue = value || maxItemValues.y;

    const maxColor = color || maxItemValues.color;

    const title = `<div class='flex-column d-center i-center gs-cl-dashboard-chart-title'>
        <span><i class='material-icons' style='color: ${maxColor}'>gs_fleet</i></span>
        <span style='color: ${maxColor}'>${parseFloat(maxValue.toFixed(2)).formatNumber(
      2,
      ',',
      '.',
    )}%</span>
      </div>`;

    return title;
  }

  __setChartTitlePosition() {
    const textBBox = this.chartTitle.getBBox();

    const x = this.chart.plotLeft + this.chart.plotWidth * 0.5 - textBBox.width * 0.5;

    const y = this.chart.plotTop + this.chart.plotHeight * 0.5 - textBBox.height * 0.5;

    this.chartTitle.attr({ x, y });
  }
  // #region Protected
}

class GolfleetDashboardChartList {
  constructor() {
    this.template = template;
    this.bindings = {
      configData: '=',
    };
    this.controller = GolfleetDashboardChartListController;
  }
}

angular
  .module('golfleet-dashboard-chart-list', ['power-dropdown'])
  .component('golfleetDashboardChartList', new GolfleetDashboardChartList());
