import angular from 'angular';
import * as Comlink from 'comlink';

import '../power-dropdown/power-dropdown';
import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-filter-entity.html';
import './power-filter-entity.scss';

class PowerFilterEntityController {
  static get $inject() {
    return ['$element', '$rootScope', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $rootScope, $scope, $http, urlApi) {
    Object.assign(this, { $: $element[0], $rootScope, $scope, $http, urlApi });

    this.icon = '';
    this.isTrip = false;
    this.filterId = 0;
    this.entityName = '';
    this.activeView = 'entity';
    this.description = 'Carregando...';
    this.requestMethod = '';
    this.entityHasGroup = false;
    this.extraInfoVisible = false;

    this.group = {
      search: '',
      description: '',
      options: [],
      default: [],
      condition: {},
      isAllSelected: false,
    };
    this.entity = {
      search: '',
      description: '',
      options: [],
      default: [],
      condition: {},
      isAllSelected: false,
      incluedHistoricalData: false,
    };

    this.filters = {};
    this.default = [];
    this.condition = {};
    this.requestParams = {};

    this.groupWorker = new Worker('./power-filter-entity.worker.js');
    this.entityWorker = new Worker('./power-filter-entity.worker.js');

    this.groupWorkerService = Comlink.wrap(this.groupWorker);
    this.entityWorkerService = Comlink.wrap(this.entityWorker);
  }

  /* Lifecycle */
  $onInit() {
    this.$.loading = true;

    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      getOptions: this.getOptions.bind(this),
      requestUpdate: this.requestUpdate.bind(this),
      setGroupFilterValue: this.setGroupFilterValue.bind(this),
      setEntityFilterValue: this.setEntityFilterValue.bind(this),
    });

    this._initializeFilter().then(() => {
      this.$.loading = false;
    });
  }

  $onDestroy() {
    this.groupWorker.terminate();
    this.entityWorker.terminate();
  }
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }

  requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  getOptions() {
    return {
      group: this.group.options,
      entity: this.entity.options,
    };
  }

  async setGroupFilterValue(value = []) {
    await this.groupWorkerService.setFilterValue({ value });

    await this._groupSyncState();

    await this._changeActiveView('group');

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setGroupFilterValue'));
  }

  async setEntityFilterValue(value = []) {
    await this.entityWorkerService.setFilterValue({ value });

    await this._entitySyncState();

    await this._changeActiveView('entity');

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setEntityFilterValue'));
  }
  /* */

  /* Private */
  async _initializeFilter() {
    if (this.requestMethod !== '') {
      await this._requestOptions();
    }

    if (this.filters.group) {
      this.entityHasGroup = true;
    }

    await Promise.all([this._groupSetup(), this._entitySetup()]);

    if (
      (this.default && this.default[0] && this.default[0].entity === 'group') ||
      (this.condition &&
        this.condition.field &&
        this.condition.field === (this.filters.group || {}).field)
    ) {
      await this._changeActiveView('group');
    } else {
      await this._changeActiveView('entity');
    }

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('setup'));
  }

  async _groupSetup() {
    if (!this.filters.group) {
      return;
    }

    await this.groupWorkerService.setup({
      id: this.filterId,
      field: this.filters.group.field,
      plural: this.filters.group.plural,
      singular: this.filters.group.singular,
      isTrip: this.isTrip,
      options: this.group.options,
      condition:
        this.condition && this.condition.field && this.condition.field === this.filters.group.field
          ? this.condition
          : {},
      defaultCondition:
        this.default && this.default[0] && this.default[0].entity === 'group'
          ? this.default[0].value
          : [],
    });

    await this.groupWorkerService.filterOptions({ search: this.group.search });

    await this._groupSyncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('groupSetup'));
  }

  async _entitySetup() {
    if (!this.filters.entity) {
      return;
    }

    await this.entityWorkerService.setup({
      id: this.filterId,
      field: this.filters.entity.field,
      plural: this.filters.entity.plural,
      singular: this.filters.entity.singular,
      isTrip: this.isTrip,
      options: this.entity.options,
      extraInfoVisible: this.extraInfoVisible,
      condition:
        this.condition &&
        this.condition.field &&
        this.filters.entity &&
        this.condition.field === this.filters.entity.field
          ? this.condition
          : {},
      defaultCondition:
        this.default && this.default[0] && this.default[0].entity === 'entity'
          ? this.default[0].value
          : [],
      incluedHistoricalData:
        this.condition &&
        this.condition.field &&
        this.condition.field === this.filters.entity.field &&
        this.condition.incluedHistoricalData
          ? this.condition.incluedHistoricalData
          : this.entity.incluedHistoricalData,
      hasIncluedHistoricalData: this.filters.entity.hasIncluedHistoricalData,
    });

    await this.entityWorkerService.filterOptions({ search: this.entity.search });

    await this._entitySyncState();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('entitySetup'));
  }

  async _groupSyncState() {
    this.group.options = await this.groupWorkerService.filteredOptions;
    this.group.condition = await this.groupWorkerService.condition;
    this.group.description = await this.groupWorkerService.description;
    this.group.isAllSelected = await this.groupWorkerService.filteredIsAllSelected;

    this.$.dispatchEvent(new CustomEvent('syncState'));
    this.$.dispatchEvent(new CustomEvent('groupSyncState'));
    this.$.dispatchEvent(new CustomEvent('groupOptionsChanged'));
    this.$.dispatchEvent(new CustomEvent('groupConditionChanged'));
    this.$.dispatchEvent(new CustomEvent('groupDescriptionChanged'));
  }

  async _entitySyncState() {
    this.entity.options = await this.entityWorkerService.filteredOptions;
    this.entity.condition = await this.entityWorkerService.condition;
    this.entity.description = await this.entityWorkerService.description;
    this.entity.isAllSelected = await this.entityWorkerService.filteredIsAllSelected;
    this.entity.incluedHistoricalData = await this.entityWorkerService.incluedHistoricalData;

    this.$.dispatchEvent(new CustomEvent('syncState'));
    this.$.dispatchEvent(new CustomEvent('entitySyncState'));
    this.$.dispatchEvent(new CustomEvent('entityOptionsChanged'));
    this.$.dispatchEvent(new CustomEvent('entityConditionChanged'));
    this.$.dispatchEvent(new CustomEvent('entityDescriptionChanged'));
  }

  async _changeActiveView(view) {
    this.activeView = view;

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('activeViewChanged'));
  }

  async _toggleHistoricalData() {
    await this.entityWorkerService.toggleHistoricalData({
      incluedHistoricalData: !this.entity.incluedHistoricalData,
    });

    await this._entitySyncState();

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleHistoricalData'));
  }

  async _toggleOptionSelection(selectedOption) {
    if (this.activeView === 'group') {
      await this.groupWorkerService.toggleOptionsSelection({ options: [selectedOption] });
      await this._groupSyncState();
    } else {
      await this.entityWorkerService.toggleOptionsSelection({ options: [selectedOption] });
      await this._entitySyncState();
    }

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleOptionSelection'));
  }

  async _toggleAllOptionsSelection() {
    if (this.activeView === 'group') {
      await this.groupWorkerService.toggleAllOptionsSelection();
      await this._groupSyncState();
    } else {
      await this.entityWorkerService.toggleAllOptionsSelection();
      await this._entitySyncState();
    }

    this._formatIcon();
    this._formatCondition();
    this._formatDescription();

    this.requestUpdate();
    this.$.dispatchEvent(new CustomEvent('toggleAllOptionsSelection'));
  }

  _formatIcon() {
    this.icon = this.filters.entity.icon;
  }

  _requestOptions() {
    return this.$http({
      url: `${this.urlApi}/${this.requestMethod}`,
      method: 'POST',
      data: { ...this.requestParams },
    }).then(async success => {
      let groupOptions = success.data.data.groups;
      let entityOptions = success.data.data[this.entityName];

      if (this.filters.group && groupOptions) {
        const { additionalOptions } = this.filters.group;

        if (additionalOptions && additionalOptions.length > 0) {
          groupOptions = [...additionalOptions, ...groupOptions];
        }
      }

      if (this.filters.entity && entityOptions) {
        const { additionalOptions } = this.filters.entity;

        if (additionalOptions && additionalOptions.length > 0) {
          entityOptions = [...additionalOptions, ...entityOptions];
        }
      }

      await Promise.all([
        this.groupWorkerService.setOptions({ options: groupOptions }),
        this.entityWorkerService.setOptions({ options: entityOptions }),
      ]);

      await Promise.all([this._groupSyncState(), this._entitySyncState()]);

      this.requestUpdate();
      this.$.dispatchEvent(new CustomEvent('requestOptions'));
    });
  }

  _formatCondition() {
    if (this.activeView === 'group') {
      this.condition = this.group.condition;
    } else {
      this.condition = this.entity.condition;
    }

    this.$.dispatchEvent(new CustomEvent('conditionChanged'));
  }

  _formatDescription() {
    if (this.activeView === 'group') {
      this.description = this.group.description;
    } else {
      this.description = this.entity.description;
    }

    this.$.dispatchEvent(new CustomEvent('descriptionChanged'));
  }
  /* */

  /* Observers */
  async __onGroupSearchChanged() {
    await this.groupWorkerService.filterOptions({ search: this.group.search });

    await this._groupSyncState();

    this.requestUpdate();
  }

  async __onEntitySearchChanged() {
    await this.entityWorkerService.filterOptions({ search: this.entity.search });

    await this._entitySyncState();

    this.requestUpdate();
  }
  /* */
}

class PowerFilterEntity {
  constructor() {
    this.template = template;
    this.bindings = {
      icon: '=',
      isTrip: '=',
      default: '=',
      filters: '=',
      filterId: '=',
      condition: '=',
      entityName: '=',
      activeView: '=',
      description: '=',
      requestMethod: '=',
      requestParams: '=',
      extraInfoVisible: '=?',
    };
    this.controller = PowerFilterEntityController;
  }
}

angular
  .module('power-filter-entity', ['infinite-scroll', 'power-dropdown'])
  .component('powerFilterEntity', new PowerFilterEntity());
