import angular from 'angular';

import template from './power-switch.html';
import './power-switch.scss';

class PowerSwitchController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });
  }

  // #region Lifecycle
  $onInit() {}
  // #endregion Lifecycle
}

class PowerSwitch {
  constructor() {
    this.template = template;
    this.bindings = {
      key: '=',
      value: '=?',
      name: '=',
      disabled: '=?',
    };
    this.controller = PowerSwitchController;
  }
}

angular.module('power-switch', []).component('powerSwitch', new PowerSwitch());
