/* eslint-disable guard-for-in */
import angular from 'angular';
import 'ng-redux';

import { PowerNavigationMenuController } from '@power/power-components/components/power-navigation-menu/power-navigation-menu';

import template from './golfleet-navigation-menu.html';
import './golfleet-navigation-menu.scss';

class GolfleetNavigationMenuController extends PowerNavigationMenuController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$rootScope',
      '$scope',
      '$http',
      'urlApi',
      'menuConfigurationServices',
      'recordServices',
      'version',
      '_applications',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $ngRedux,
    $state,
    $rootScope,
    $scope,
    $http,
    urlApi,
    menuConfigurationServices,
    recordServices,
    version,
    _applications,
  ) {
    super(
      $element,
      $ngRedux,
      $state,
      $rootScope,
      $scope,
      $http,
      urlApi,
      menuConfigurationServices,
      recordServices,
      version,
      _applications,
    );
  }

  /* Lifecycle */
  /* */

  /* Public */
  /* */

  /* Private */
  _openTicket() {
    document.querySelector('golfleet-issue-tracker').openTicket();
  }
  /* */
}

class GolfleetNavigationMenu {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.transclude = {
      supportSlot: '?supportSlot',
    };
    this.controller = GolfleetNavigationMenuController;
  }
}

angular
  .module('golfleet-navigation-menu', ['ngRedux'])
  .component('golfleetNavigationMenu', new GolfleetNavigationMenu());
