import angular from 'angular';
import 'ng-redux';

import '../power-fab/power-fab';
import '../power-toolbar-report/power-toolbar-report';
import '../power-dropdown/power-dropdown';
import '../power-grid/power-grid';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-crud/power-crud';
import '../power-popup/power-popup';
import '../power-popup-share/power-popup-share';
import '../power-popup-delete/power-popup-delete';
import '../power-toast/power-toast';
import { PowerReportController } from '../power-report/power-report';

import template from './power-report-groups.html';
import './power-report-groups.scss';

class PowerReportGroupsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }
}

class PowerReportGroups {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportGroupsController;
  }
}

angular
  .module('power-report-groups', [
    'power-fab',
    'power-toolbar-report',
    'power-dropdown',
    'power-grid',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-crud',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
  ])
  .component('powerReportGroups', new PowerReportGroups());
