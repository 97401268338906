/* eslint-disable no-restricted-syntax */
import angular from 'angular';
import '../../helpers/is-iframe/is-iframe';

import { PowerMapHereProvider } from './providers/here/power-map.here-provider';
import { PowerMapLeafletProvider } from './providers/leaflet/power-map.leaflet-provider';

import template from './power-map.html';

class PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux,', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    Object.assign(this, { $: $element[0], $ngRedux, $scope, $http, urlApi });

    this.mapConfigs = { here: {}, leaflet: {} };

    this.mapConfigs.here = {
      app_id: 'Iajt61WMPoEvbbMdvMH8',
      app_code: 'Z7ncDhO8GxIIHGGDmzCkWQ',
      useHTTPS: true,
    };

    this.mapConfigs.leaflet = {
      tileUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      satelliteTileUrl: `https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${this.mapConfigs.here.app_id}&app_code=${this.mapConfigs.here.app_code}&ppi=320`,
    };

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        providerName: behavior.session.mapProvider || 'HERE',
        isTrip: behavior.session.isTrip,
      }),
    )(this);

    this.$.setAttribute('provider', this.providerName);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapHereProvider(this, $element, $ngRedux, $scope, $http, urlApi);
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }

    this.resizeObserver = new ResizeObserver(this.__onResize.bind(this));
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      awaitRender: this.awaitRender.bind(this),
      getMap: this.getMap.bind(this),
      getLayer: this.getLayer.bind(this),
      getBounds: this.getBounds.bind(this),
      sortDatasetByBounds: this.sortDatasetByBounds.bind(this),
      getDataFromLayer: this.getDataFromLayer.bind(this),
      zoomTo: this.zoomTo.bind(this),
      resizeMap: this.resizeMap.bind(this),
      fitBounds: this.fitBounds.bind(this),
      fitLayers: this.fitLayers.bind(this),
      removeLayers: this.removeLayers.bind(this),
      renderDataset: this.renderDataset.bind(this),
      requestGeocoding: this.requestGeocoding.bind(this),
      openPopup: this.openPopup.bind(this),
      getBubbleController: this.getBubbleController.bind(this),
    });

    this.provider.$onInit();

    this.resizeObserver.observe(this.$);
  }

  $onDestroy() {
    this.provider.$onDestroy();

    this.__appBehavior();

    this.resizeObserver.unobserve(this.$);
  }
  /* */

  /* Public */
  async awaitRender() {
    return this.provider.awaitRender();
  }

  requestGeocoding(address) {
    this.provider.requestGeocoding(address);
  }

  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
  }) {
    return this.provider.renderDataset({ dataset, layerName, type, useCluster, clusterColor });
  }

  removeLayers(layerNameList = ['default']) {
    this.provider.removeLayers(layerNameList);
  }

  resizeMap() {
    this.provider.resizeMap();
  }

  async zoomTo(data, zoom = 18) {
    return this.provider.zoomTo(data, zoom);
  }

  fitBounds(bounds) {
    this.provider.fitBounds(bounds);
  }

  fitLayers(layerNameList = ['default'], heatLayerName) {
    this.provider.fitLayers(layerNameList, heatLayerName);
  }

  getBounds(layerNameList, heatLayerName) {
    return this.provider.getBounds(layerNameList, heatLayerName);
  }

  sortDatasetByBounds(a, b) {
    return this.provider.sortDatasetByBounds(a, b);
  }

  getMap() {
    return this.provider.getMap();
  }

  getLayer(layerName) {
    return this.provider.getLayer(layerName);
  }

  getDataFromLayer(layerName = 'default', filter) {
    return this.provider.getDataFromLayer(layerName, filter);
  }

  openPopup(index = 0, layer = 'default') {
    this.provider.openPopup(index, layer);
  }

  enterFullscreen(fullscreenExitCallback) {
    this.provider.enterFullscreen(fullscreenExitCallback);
  }

  exitFullscreen() {
    this.provider.exitFullscreen();
  }

  getBubbleController() {
    return this.provider.getBubbleController();
  }
  /* */

  /* Observers */
  __onResize() {
    if (this.__onResizeTimeout) {
      clearTimeout(this.__onResizeTimeout);
    }

    this.__onResizeTimeout = setTimeout(() => {
      requestAnimationFrame(() => {
        this.resizeMap();

        clearTimeout(this.__onResizeTimeout);
      });
    }, 300);
  }
  /* */
}

class PowerMap {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.provider = PowerMapController;
  }
}

angular.module('power-map', []).component('powerMap', new PowerMap());

export { PowerMapController };
