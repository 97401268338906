function eventOnce(params = {}) {
  const { event, action, element, trigger } = params;

  return new Promise(resolve => {
    element.addEventListener(event, async function __onEvent() {
      element.removeEventListener(event, __onEvent);

      if (action) {
        await action();
      }

      resolve();
    });

    if (trigger) {
      trigger();
    }
  });
}

export { eventOnce };
