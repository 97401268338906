/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '../golfleet-card-announcement/golfleet-card-announcement';
import '@power/power-components/components/power-custom-checkbox-list/power-custom-checkbox-list';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-period/power-period';
import '@power/power-components/components/power-radio-button-group/power-radio-button-group';
import '@power/power-components/components/power-simple-card-list/power-simple-card-list';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-announcements.html';
import './golfleet-report-announcements.scss';

class GolfleetReportAnnouncementsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      '$sce',
      '$rootScope',
    ];
  }

  /**
   * Creates an instance of ReportAnnouncementsController.
   * @memberof ReportAnnouncementsController
   */
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    $sce,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $sce, $rootScope });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        session: behavior.session,
      }),
    )(this);

    this.toastObj = {
      text: '',
    };
  }

  $onInit() {
    super.$onInit();

    this.stateConfig.gridConfig.page = 0;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.$scope.$on('showToast', (evt, data) => {
      this.$.querySelector('power-toast#report-toast').toggle(true);
      this.toastObj = { text: data.text };
    });
  }

  $onDestroy() {
    this.__appInheritBehavior();
  }

  changePage(page) {
    this.$scope.$broadcast('changePage', {
      page,
      payload: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
      },
    });
  }

  _callAction(action) {
    if (action.actionType === 'add' && action.type === 'Announcement') {
      this._addAnnouncement();
    } else {
      super._callAction(action);
    }
  }

  _addAnnouncement() {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Comunicados',
        routeLink: 'announcementForm',
        routeTail: null,
        stateConfig: { ...this.stateConfig, gridName: 'Formulário', filterConfig: [] },
      },
    });

    this.$state.go('announcementForm');
  }
}

class GolfleetReportAnnouncements {
  /**
   * Creates an instance of ReportAnnouncements.
   * @memberof ReportAnnouncements
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportAnnouncementsController;
  }
}

angular
  .module('golfleet-report-announcements', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-fab',
    'power-toolbar',
    'power-simple-card-list',
    'golfleet-card-announcement',
    'power-dropdown',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-radio-button-group',
    'power-custom-checkbox-list',
    'power-toast',
  ])
  .component('golfleetReportAnnouncements', new GolfleetReportAnnouncements());
