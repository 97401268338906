import angular from 'angular';
import '../../helpers/is-iframe/is-iframe';

import { PowerMapVehiclesHereProvider } from './providers/here/power-map-vehicles.here-provider';
import { PowerMapVehiclesLeafletProvider } from './providers/leaflet/power-map-vehicles.leaflet-provider';
import { PowerMapController } from '../power-map/power-map';

import template from './power-map-vehicles.html';

class PowerMapVehiclesController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapVehiclesHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapVehiclesLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  $onInit() {
    super.$onInit();
    Object.assign(this.$, {
      addMessageInfo: this.addMessageInfo.bind(this),
      removeMessageInfo: this.removeMessageInfo.bind(this),
    });
  }

  /* Public */
  enterFullscreen() {
    this.provider.enterFullscreen();
  }

  requestGeocoding(address) {
    this.provider.requestGeocoding(address);
  }

  addMessageInfo(message) {
    return this.provider.addMessageInfo(message);
  }

  removeMessageInfo(element) {
    this.provider.removeMessageInfo(element);
  }
  /* */

  /* Private */
  _toggleNearBySearch() {
    this.provider._toggleNearBySearch();
  }

  _searchNearBy(lat, lng) {
    this.provider._searchNearBy(lat, lng);
  }
  /* */
}

class PowerMapVehicles {
  constructor() {
    this.template = template;
    this.bindings = {
      reportDataset: '=?',
    };
    this.controller = PowerMapVehiclesController;
  }
}

angular
  .module('power-map-vehicles', [])
  .component('powerMapVehicles', new PowerMapVehicles());

export { PowerMapVehiclesController };
