import angular from 'angular';
import tippy from 'tippy.js';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/directives/ng-tippy/ng-tippy';

import template from './golfleet-dashboard-grid.html';

class GolfleetDashboardGridController {
  static get $inject() {
    return ['$element', 'dashboardServices'];
  }

  constructor($element, dashboardServices) {
    Object.assign(this, { $: $element[0], dashboardServices });

    this.optSelected = null;
    this.gridTabSelected = null;
    this.showInsideLoader = false;
    this.titleTippy = null;
  }

  /* Lifecycle */
  $onInit() {
    this.optSelected = this.configData.config.optionsConfig.actions.filter(
      item => item.selected,
    )[0];
    this.gridTabSelected = this.configData.config.gridConfig.tabs.filter(
      item => item.id == 'vehicleViolation',
    )[0];
  }
  /* */

  /* Private */
  _evtClickOptionSelected(itemReturn) {
    this.optSelected = itemReturn;

    this.configData.data = [];
    this.showInsideLoader = true;
    this.dashboardServices
      .getViolation({
        typeReport: parseInt(itemReturn.value, 10),
        filter: {
          conditions: this.configData.params,
        },
      })
      .then(success => {
        if (typeof success.status !== 'undefined') {
          if (success.status === 500) {
            return;
          }
        }

        if (itemReturn.tooltip) {
          this.titleTippy = tippy(this.$.querySelector('.gs-dashboard-header-title'), {
            placement: 'left',
            arrow: true,
          });
        } else if (this.titleTippy) this.titleTippy.disable();

        this.showInsideLoader = false;
        this.configData.data = success;
      });
  }

  _evtClickTabGrid(tab) {
    this.gridTabSelected = tab;
  }

  _exportAs() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const [tab] = this.configData.config.gridConfig.tabs.filter(
      item => item.id === this.gridTabSelected.id,
    );

    const requestTime = new Date();
    const filterConditions = this.configData.params;
    const typeReport = this.optSelected.value;
    const exportOptions = { exportType: 'xlsx' };

    const request = {
      exportOptions,
      typeReport,
      filter: { conditions: filterConditions },
      requestTime: new Date(requestTime - requestTime.getTimezoneOffset() * 60000),
    };

    this.dashboardServices.getViolationExport(request, tab.exportMethod).then(() => {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    });
  }
  /* */
}

class GolfleetDashboardGrid {
  constructor() {
    this.template = template;
    this.bindings = {
      configData: '=',
    };
    this.controller = GolfleetDashboardGridController;
  }
}

Object.assign(window, {
  GolfleetDashboardGrid,
  GolfleetDashboardGridController,
});

angular
  .module('golfleet-dashboard-grid', ['power-dropdown', 'ng-tippy'])
  .component('golfleetDashboardGrid', new GolfleetDashboardGrid());
