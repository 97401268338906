/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ua-device-detector';
import 'ng-device-detector';

import { PowerSwitchAccountController } from '@power/power-components/components/power-switch-account/power-switch-account';

import template from './golfleet-switch-account.html';
import './golfleet-switch-account.scss';

class GolfleetSwitchAccountController extends PowerSwitchAccountController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'urlApi',
      '_applications',
      'switchAccountServices',
      'commonServices',
      'tipoApp',
    ];
  }

  /**
   * Creates an instance of PowerSwitchAccountController.
   * @memberof GolfleetSwitchAccountController
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    urlApi,
    _applications,
    switchAccountServices,
    commonServices,
    tipoApp,
  ) {
    super(
      $element,
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      urlApi,
      _applications,
      switchAccountServices,
      commonServices,
      tipoApp,
    );
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  _updateImages(sessionData) {
    if (this.session.application) {
      let faviconSrc = '';

      if (this.session.application.toLowerCase() === 'golfleet') {
        faviconSrc = sessionData.isVideoTelemetry
          ? `/assets/images/favicon-golfleet-videotelemetry.png`
          : `/assets/images/favicon-golfleet-telemetry.png`;
      } else {
        faviconSrc = `/assets/images/favicon-golfleet-${this.session.application.toLowerCase()}.png`;
      }

      document.querySelector('[rel="shortcut icon"]').href = faviconSrc;
    }

    const defaultHeightValue = '64px';
    document.querySelector('#shell-header-logo').style.backgroundSize = `auto ${
      sessionData.logoHeight || defaultHeightValue
    }`;

    document.documentElement.style.setProperty(
      '--user-photo',
      `url(${sessionData.userPhoto || '/assets/images/user-photo-placeholder.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--header-icon',
      `url(${sessionData.headerIcon || '/assets/images/telemetry-icon.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--header-logo',
      `url(${sessionData.headerLogo || '/assets/images/golfleet.svg'})`,
    );
    document.documentElement.style.setProperty(
      '--trade-mark-icon',
      `url(${sessionData.tradeMarkIcon || '/assets/images/golfleet-icon.svg'})`,
    );
  }

  _insertLog() {
    this.commonServices.platformLog({ logtype: 'LoginGolfleet' });
  }
  // end controller
}

class GolfleetSwitchAccount {
  constructor() {
    this.template = template;
    this.bindings = {
      isInLogin: '=?',
    };
    this.controller = GolfleetSwitchAccountController;
  }
}

angular
  .module('golfleet-switch-account', [])
  .component('golfleetSwitchAccount', new GolfleetSwitchAccount());
