import angular from 'angular';
import moment from 'moment';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import Treemap from 'highcharts/modules/treemap';
import 'highcharts-regression';
import 'jquery';
import 'ng-redux';

import '../golfleet-dashboard-block/golfleet-dashboard-block';
import '../golfleet-dashboard-error/golfleet-dashboard-error';
import '../golfleet-dashboard-no-data/golfleet-dashboard-no-data';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '../power-loader/power-loader';
import '@power/power-components/components/power-popup/power-popup';
import '../golfleet-popup-fuel-exceptions/golfleet-popup-fuel-exceptions';
import '@power/power-components/components/power-radio-button-group/power-radio-button-group';
import '@power/power-components/components/power-single-checkbox/power-single-checkbox';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/helpers/format-number/format-number';
import '@power/power-components/helpers/power-input/power-input';
import { FilterCondition } from '@power/power-components/components/power-filter/power-filter';
import { ReportStateConfig } from '@power/power-components/components/power-state-config/power-state-config';

import template from './golfleet-dashboard-fuel-module.html';
import './golfleet-dashboard-fuel-module.scss';

Exporting(Highcharts);
OfflineExporting(Highcharts);
Treemap(Highcharts);

class GolfleetDashboardFuelModuleController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$rootScope',
      '$http',
      'urlApi',
      'filterServices',
      'commonServices',
      'dashboardServices',
      '_today',
      '_months',
      '$timeout',
      '$interval',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $state,
    $scope,
    $rootScope,
    $http,
    urlApi,
    filterServices,
    commonServices,
    dashboardServices,
    _today,
    _months,
    $timeout,
    $interval,
  ) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $rootScope,
      $http,
      urlApi,
      filterServices,
      commonServices,
      dashboardServices,
      _today,
      _months,
      $timeout,
      $interval,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        lastState:
          behavior.state.routeList.length > 1
            ? behavior.state.routeList[behavior.state.routeList.length - 2]
            : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.__appStore = $ngRedux.connect(store =>
      Object({
        application: store.session.application,
      }),
    )(this);

    this.legacyEquipaments = { status: 0, list: [], count: 0, highlight: true }; // 0. loading, 1. ok, 2. no data, 3. error
    this.msgLegacyEquipament = ' equipamentos incompatíveis não constam neste Dashboard';
    this.toastDashboard = {};

    this.dashboardConfig = {};
    this.finishPageLoading = 0;
    this.configDataFuelSupply = 0;
    this.configDataMonthlyResume = 0;
    this.configDataFuelExceptions = 0;
    this.configDataFuelKpiEvolution = 0;
    this.configDataLessPriceSupplies = 0;

    this.popupConfigConsumptionAverages = {};

    this.dataMonthlyResume = [];
    this.dataLessPriceSupplies = [];
    this.dataFuelKpiEvolution = [];
    this.dataFuelExceptions = [];
    this.dataFuelSupply = [];
    this.monthlyResumeChartSeries = [];
    this.monthlyResumeChartSeriesMobile = [];
    this.monthlyResumeChartTotal = [];
    this.lessPriceSuppliesItemVisible = 0;
    this.moneyChartInfo = {};
    this.litersChartInfo = {};
    this.fuelExceptionsInfo = {};
    this.sortListFuelSupplyPropertyName = 'dataAbastecimento'; // Campo default quando carrega pela primeira vez
    this.sortListFuelSupplyReverse = true;
    this.dataPopupManualEntryError = {};
    this.popupConfigConsumptionAverages = {};
    this.showInputConsumptionAverages = false;
    this.showInputPanel = 2; // 1. Uma linha apenas, 2. Duas linhas (gasolina e etanol), 3. GNV
    this.checkboxRemoveGnv = false;
    this.paramsForSaveConsumptionAverages = null;
    this.popupConfigAverageOpened = false;
    this.configAveragesForFlex = {
      gasolineMin: '',
      gasolineMax: '',
      gasolineAverage: '',
      etanolMin: '',
      etanolMax: '',
      etanolAverage: '',
    };
    this.configConsumptionAverages = [
      {
        id: 1,
        description: 'Gasolina',
        value: 18,
        color: '#ff2d55',
      },
      {
        id: 2,
        description: 'Etanol',
        value: 19,
        color: '#4a90e2',
      },
      {
        id: 3,
        description: 'Diesel',
        value: 53,
        color: '#ffcc00',
      },
      {
        id: 4,
        description: 'Flex',
        value: 999,
        parentIds: [1, 2],
      },
      {
        id: 4,
        description: 'GNV',
        value: 54,
        color: '#b8e986',
      },
    ];
    this.monthlyResumeChartFooterOptions = [
      {
        id: 1,
        description: 'Trechos validados',
        value: 'ok',
        selected: false,
      },
      {
        id: 2,
        description: 'Trechos com exceções',
        value: 'ex',
        selected: false,
      },
      {
        id: 3,
        description: 'Todos os trechos',
        value: 'all',
        selected: true,
      },
    ];
    this.monthlyResumeChartOptFooter = this.monthlyResumeChartFooterOptions[2];
    this.hasSwipeableCards = false;

    this.monthlyResumeChart = null;
    this.moneyChart = null;
    this.litersChart = null;
    this.moneyChartTitle = null;
    this.litersChartTitle = null;
    this.intervalLessPriceSupplies = null;
    this.inputManualEntry = null;
    this.formDataManualEntry = new FormData();
    this.cardAnimated = false;
    this.watchPopupAveragePreset = null;
    this.watchPopupAverageFuelPreset = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    this.toastDashboard = {
      text: '',
    };

    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0)
      this._getStateConfig();
    else this._getDashboardData();

    this.$scope.$watch(
      () => this.monthlyResumeChartTotal,
      this._onMonthlyResumeChartTotalChanged.bind(this),
    );
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  requestDataset() {
    this._getDashboardData();
  }

  toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }
  /* */

  /* Private */
  _getStateConfig() {
    this.filterServices
      .getPowerFilters(this.stateConfig.screenName, 'FuelModule')
      .then(getFiltersResult => {
        if (getFiltersResult) {
          const { drilldownMap } = getFiltersResult;
          const filterConfigList = getFiltersResult.filters;
          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );
          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(
                // eslint-disable-next-line no-shadow
                drilldown => drilldown.toId == actualFilter.id,
              )[0];
              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];
                return {
                  ...actualFilter,
                  condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                };
              }
              return actualFilter;
            });
          }
          if (this.stateConfig.getDataFilters) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition => condition.id == actualFilter.id,
              )[0];
              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field || actualFilter.filters[fixedFilters.activeView].field,
                      fixedFilters.default,
                    ),
                  }
                : actualFilter;
            });
          }
        }
        this.commonServices
          .getToolbarOptions(
            this.stateConfig.screenName,
            this.stateConfig.toolbarName,
            getToolbarResult => {
              const { addConfig, viewsConfig, exportConfig, importConfig, actionConfig } =
                getToolbarResult;
              Object.assign(this.stateConfig.toolbarConfig, {
                addConfig,
                viewsConfig,
                exportConfig,
                actionConfig,
                importConfig,
              });
              this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
            },
          )
          .then(() =>
            document
              .querySelector('#app-component')
              .querySelector('power-filter-menu')
              .applyFilters(),
          );
      })
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _getDashboardData() {
    this.__clearData();

    const filters = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
    };

    let finalDateObj = null;

    const filterConditionsLength = this.stateConfig.filterConditions.length;
    for (let conditionIndex = 0; conditionIndex < filterConditionsLength; conditionIndex += 1) {
      if (this.stateConfig.filterConditions[conditionIndex].field == 'date') {
        finalDateObj = new Date(this.stateConfig.filterConditions[conditionIndex].value[1]);
        break;
      }
    }

    const filterEquipaments = this.stateConfig.filterConditions.filter(
      item => item.field == 'vehicles' || item.field == 'driver',
    )[0];

    if (
      filterEquipaments &&
      (filterEquipaments.value.length === 0 ||
        filterEquipaments.value.length == filterEquipaments.total)
    ) {
      this.commonServices.getLegacyEquipaments('LegacyEquipaments/GetLegacyEquipaments').then(
        success => {
          if (success.data && success.data.hasError) {
            this.legacyEquipaments.status = 3;
            return;
          }
          if (success.data.data && success.data.data.total > 0) {
            this.legacyEquipaments.list = success.data.data.equipaments;
            this.legacyEquipaments.count = success.data.data.total;
            this.legacyEquipaments.highlight = success.data.data.highlight;
            this.legacyEquipaments.status = 1;
          } else this.legacyEquipaments.status = 2;
        },
        () => {
          this.legacyEquipaments.status = 3;
        },
      );
    } else this.legacyEquipaments.status = 0;

    this.dashboardServices
      .getDashboardConfig('FuelModule')
      .then(result => {
        // Após carregar as configurações do Dashboard, carrega as informações dos Block's
        if (result.status && result.status === 500) {
          this.toastDashboard.text =
            'Ops! Ocorreu um erro ao carregar as configurações do seu Dashboard. Por favor, atualize a página.';
          this.$.querySelector('#toast-obj').toggle(true);
          return;
        }

        this.dashboardConfig = Object.clone({}, result);
        this.popupConfigConsumptionAverages = Object.clone(
          {},
          this.dashboardConfig.popupConfigMedias,
        );

        /* Status
         * 0: show loader
         * 1: ok, success
         * 2: not ok, error
         * 3: ok, success, but no data
         */

        const moduleName = 'FuelModule';

        this.dashboardServices.getGenericData(moduleName, 'GetFuelMonthlyResume', filters).then(
          success => {
            if (success.data && success.data.hasError) {
              this.configDataMonthlyResume = 2;
              this.configDataLessPriceSupplies = 2;
              this.configDataFuelKpiEvolution = 2;
              return;
            }

            if (success.data.totalVehiclesSupplies.qtdAbastecimentos === 0) {
              this.configDataMonthlyResume = 3;
              this.configDataLessPriceSupplies = 3;
              this.configDataFuelKpiEvolution = 3;
              this.$.querySelector('#popup-module-info').toggle();
              return;
            }

            this.dataMonthlyResume = success.data;
            this.dataMonthlyResume.hasLastMonth =
              this._today.getFullYear() === finalDateObj.getFullYear() &&
              this._today.getMonth() === finalDateObj.getMonth();

            const dataSeries = {
              hasLastMonth: this.dataMonthlyResume.hasLastMonth,
              data: [],
              categories: [],
            };

            this._monthlyResumeChartSetData(
              this.dashboardConfig.mainChart.optionSelected.value,
              success.data.monthlySuppliesDash,
              dataSeries.hasLastMonth,
              chartDataReturn => {
                this._createChart(
                  'monthly-resume-chart',
                  'line',
                  chartDataReturn.data,
                  chartDataReturn.categories,
                  chartObj => {
                    this.monthlyResumeChart = chartObj;

                    const chartElement = this.$.querySelector('#monthly-resume-chart');

                    const chartTotalElement = this.$.querySelector('.chart-total');

                    const xAxisWidth = Math.floor(
                      chartElement.querySelector('.highcharts-xaxis').getBoundingClientRect().width,
                    );

                    chartTotalElement.style.paddingLeft = `${
                      chartElement.clientWidth - xAxisWidth - 8
                    }px`;
                    chartTotalElement.style.paddingRight = '8px';

                    this.monthlyResumeChartSeries = this.monthlyResumeChart.series.map(serie => ({
                      color: serie.color,
                      name: serie.name,
                      visible: serie.visible,
                      index: serie.index,
                    }));
                    this.monthlyResumeChartSeries.push(
                      this.monthlyResumeChartSeries.splice(0, 1)[0],
                    );

                    this.monthlyResumeChartSeriesMobile = this.monthlyResumeChartSeries.map(
                      serie => ({
                        id: serie.index,
                        color: serie.color,
                        description: serie.name,
                        value: serie.index,
                        selected: serie.visible,
                      }),
                    );

                    this.configDataMonthlyResume = 1;
                  },
                );
              },
            );

            this.dataLessPriceSupplies = success.data.lessPriceSupplies.sort(
              (a, b) => a.idCombustivel - b.idCombustivel,
            );
            this.configDataLessPriceSupplies = 1;
            this.$timeout(() => {
              this.hasSwipeableCards = false;
              this.lessPriceSuppliesItemVisible = 0;
              if (typeof this._lessPriceSuppliesIntervalControl == 'function') {
                this._lessPriceSuppliesIntervalControl('start');
              }
            }, 100);

            this._fuelKpiEvolutionChartSetData(success.data.fuelKpiEvolution, chartDataReturn => {
              this.moneyChart = this._createChartWithNew(
                'fuel-kpi-money-chart',
                'pie',
                chartDataReturn.dataMoney.dataChart,
              );
              this.litersChart = this._createChartWithNew(
                'fuel-kpi-liters-chart',
                'pie',
                chartDataReturn.dataLiters.dataChart,
              );

              this.moneyChart.dataAll = chartDataReturn.dataMoney.fullData;
              this.litersChart.dataAll = chartDataReturn.dataLiters.fullData;

              this.moneyChartTitle = this.moneyChart.renderer
                .label('', null, null, null, null, null, true)
                .add();
              this.litersChartTitle = this.litersChart.renderer
                .label('', null, null, null, null, null, true)
                .add();

              this._setChartTitlePosition(this.moneyChart, this.moneyChartTitle);
              this._setChartTitlePosition(this.litersChart, this.litersChartTitle);

              this.moneyChart.customOptions = {
                setChartScopeApply: this._setChartScopeApply.bind(this),
                moneyChart: this.moneyChart,
                moneyChartTitle: this.moneyChartTitle,
                moneyChartInfo: this.moneyChartInfo,
                setChartTitle: this._setChartTitle.bind(this),
                setChartTitlePosition: this._setChartTitlePosition.bind(this),
              };
              this.litersChart.customOptions = {
                setChartScopeApply: this._setChartScopeApply.bind(this),
                litersChart: this.litersChart,
                litersChartTitle: this.litersChartTitle,
                litersChartInfo: this.litersChartInfo,
                setChartTitle: this._setChartTitle.bind(this),
                setChartTitlePosition: this._setChartTitlePosition.bind(this),
              };

              this.moneyChartInfo = {
                desc: '',
                value: chartDataReturn.dataMoney.fullData.y.formatNumber(2, ',', '.'),
                fuel: chartDataReturn.dataMoney.fullData.name,
                color: chartDataReturn.dataMoney.fullData.color,
              };
              this.litersChartInfo = {
                desc: 'Litros total',
                value: chartDataReturn.dataLiters.fullData.y.formatNumber(2, ',', '.'),
                fuel: chartDataReturn.dataLiters.fullData.name,
                sufix: ' L',
                color: chartDataReturn.dataLiters.fullData.color,
              };

              this.configDataFuelKpiEvolution = 1;
            });
          },
          () => {
            this.configDataMonthlyResume = 2;
            this.configDataLessPriceSupplies = 2;
            this.configDataFuelKpiEvolution = 2;
          },
        );

        this.dashboardServices.getGenericData(moduleName, 'GetFuelExceptions', filters).then(
          success => {
            if (success.hasError) {
              this.configDataFuelExceptions = 2;
              return;
            }

            if (!success.data.filter(item => item.qtd > 0).length > 0) {
              this.configDataFuelExceptions = 3;
              return;
            }

            this.dataFuelExceptions = success.data;

            this._fuelExceptionsSetData(success.data, chartDataReturn => {
              const expectionChart = this._createChartWithNew(
                'fuel-exceptions-chart',
                'treemap',
                chartDataReturn.data,
              );

              expectionChart.customOptions = {
                setChartTreemapScopeApply: this._setChartTreemapScopeApply.bind(this),
              };
            });

            this.configDataFuelExceptions = 1;
          },
          () => {
            this.configDataFuelExceptions = 2;
          },
        );

        const filterFuelSupply = { ...filters, lastSupply: true, countResult: 10 };
        this.dashboardServices
          .getGenericData(moduleName, 'GetResultFuelSupplyManagement', filterFuelSupply)
          .then(
            success => {
              if ((success.status && success.status === 500) || success.hasError) {
                this.configDataFuelSupply = 2;
                return;
              }

              if (success.data.data.length === 0) {
                this.configDataFuelSupply = 3;
                return;
              }

              this.dataFuelSupply = success.data.data;

              this.configDataFuelSupply = 1;
            },
            () => {
              this.configDataFuelSupply = 2;
            },
          );
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _goToLink(
    routeName,
    routeLink,
    stateFixedParams,
    fixedFilters,
    linkStateConfig,
    getDataMethod,
    backPagination,
    data,
  ) {
    let getDataFilters = [];
    const getDataFixedParams = {};
    if (backPagination === null) backPagination = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const attr in stateFixedParams) {
      if (data && data[stateFixedParams[attr]])
        getDataFixedParams[attr] = data[stateFixedParams[attr]];
      else if (
        this.stateConfig.getDataFixedParams &&
        this.stateConfig.getDataFixedParams[stateFixedParams[attr]]
      )
        getDataFixedParams[attr] = this.stateConfig.getDataFixedParams[stateFixedParams[attr]];
      else getDataFixedParams[attr] = stateFixedParams[attr];
    }
    if (fixedFilters) {
      getDataFilters = fixedFilters.map(filter => {
        const filterDefault = filter.condition.map(
          // eslint-disable-next-line array-callback-return, consistent-return
          condition => {
            if (filter.type == 'DateTime') {
              return this.stateConfig.filterConfig.filter(
                config => config.id == condition.fromId,
              )[0].condition;
            }
            if (filter.type == 'Multiselect') {
              if (condition.fromField) {
                if (condition.value == 'allItems')
                  return this.dataFuelExceptions
                    .filter(exception => exception.modulo == data)
                    .map(exception => exception.id);
                if (data.id === 999 && condition.value) return [];
                return data[condition.fromField];
              }
              if (data.modulo && data.id != 999) return [condition.value];
            }
          },
        );
        return {
          id: filter.id,
          default: filterDefault,
        };
      });
    }
    const stateConfig = new ReportStateConfig({
      viewMode: 'grid',
      getDataMethod,
      backPagination,
      getDataFilters,
      getDataFixedParams,
    });
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const attr in linkStateConfig) {
      stateConfig[attr] = linkStateConfig[attr];
    }
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: data && data[routeName] ? data[routeName] : routeName,
        routeLink,
        routeSubName: linkStateConfig?.routeSubName,
        routeTail: getDataFixedParams && getDataFixedParams.id ? getDataFixedParams.id : null,
        stateConfig,
      },
    });
    this.$state.go(routeLink, {
      tail: getDataFixedParams && getDataFixedParams.id ? getDataFixedParams.id : null,
    });
  }

  _dayDiff(firstDate, secondDate) {
    return Math.abs(Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24)));
  }

  _getFuelColor(idFuel) {
    let color = '';
    switch (idFuel) {
      case 18:
        // gasolina
        color = '#ff2d55';
        break;
      case 19:
        // etanol
        color = '#4a90e2';
        break;
      case 53:
        // diesel
        color = '#ffcc00';
        break;
      case 54:
        // gnv
        color = '#b8e986';
        break;
      default:
        // todos
        color = '#555';
        break;
    }
    return color;
  }

  _setChartScopeApply(chartId, chartInfo) {
    switch (chartId) {
      case 'money':
        this.moneyChartInfo = chartInfo;
        break;
      case 'liters':
        this.litersChartInfo = chartInfo;
        break;
      default:
        break;
    }
    this.$scope.$apply();
  }

  _setChartTreemapScopeApply(info) {
    this.fuelExceptionsInfo = info;
    this.$scope.$apply();
  }

  _setChartTitle(value, color) {
    const title = `<div class='flex-column d-center i-center chart-pie-title'><span style='color: ${color}'>${
      value === 0 || value === 100 ? value : parseFloat(value.toFixed(2)).formatNumber(2, ',', '.')
    }</span></div>`;
    return title;
  }

  _setChartTitlePosition(chartObj, chartTitleObj) {
    const textBBox = chartTitleObj.getBBox();

    const x = chartObj.plotLeft + chartObj.plotWidth * 0.5 - textBBox.width * 0.5;

    const y = chartObj.plotTop + chartObj.plotHeight * 0.5 - textBBox.height * 0.5;

    chartTitleObj.attr({ x, y });
  }

  _createChart(element, chartType, dataset, categories, callback) {
    Highcharts.chart(
      {
        chart: {
          type: chartType || 'line',
          renderTo: this.$.querySelector(`#${element}`),
          spacing: [8, 8, 10, 8],
          style: {
            fontFamily: 'Ubuntu',
            fontSize: '12px',
          },
        },
        title: {
          text: null,
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        exporting: { enabled: false },
        tooltip: {
          shared: true,
          borderRadius: 8,
          padding: 0,
          useHTML: true,
          formatter() {
            let tooltipHtml = "<div class='flex-column chart-tooltip'><span class='tooltip-title'>";

            if (this.points[0].point.tooltipOptions) {
              tooltipHtml += `${this.points[0].point.tooltipOptions.titlePrefix} ${this.x} ${this.points[0].point.tooltipOptions.titleSufix}`;
            } else {
              tooltipHtml += this.x;
            }

            tooltipHtml += '</span>';

            this.points.forEach(point => {
              tooltipHtml += `${
                "<span class='tooltip-text-content'>" +
                "<i class='material-icons md-13' style='color:"
              }${point.color}'>fiber_manual_record</i>`;

              if (point.point.tooltipOptions) {
                tooltipHtml += `${point.point.tooltipOptions.prefix} ${point.y.formatNumber(
                  point.point.tooltipOptions.decimalPlaces,
                  ',',
                  '.',
                )} ${point.point.tooltipOptions.sufix}`;

                if (point.point.percentFromY) {
                  tooltipHtml += ` <span class='tooltip-text-percent'>${point.point.percentFromY}%</span>`;
                }
              } else {
                tooltipHtml +=
                  point.y +
                  (point.point.percentFromY
                    ? ` <span class='tooltip-text-percent'>${point.point.percentFromY}%</span>`
                    : '');
              }
              tooltipHtml += '</span>';
            });

            tooltipHtml += '</div>';
            return tooltipHtml;
          },
        },
        xAxis: {
          alternateGridColor: 'rgba(0, 0, 0, 0.05)',
          categories,
          labels: {
            y: 28,
            formatter() {
              return this.value.toString().substr(0, 3);
            },
          },
        },
        yAxis: {
          type: 'linear',
          title: { text: null },
          min: 0,
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver() {
                  if (this.series.xAxis.labelGroup.element.children)
                    this.series.xAxis.labelGroup.element.children[this.x].classList.add(
                      'main-chart-label-selected',
                    );
                  else if (this.series.xAxis.labelGroup.element.getElementsByTagName('text'))
                    this.series.xAxis.labelGroup.element
                      .getElementsByTagName('text')
                      [this.x].classList.add('main-chart-label-selected');
                },
                mouseOut() {
                  if (this.series.xAxis.labelGroup.element.children)
                    this.series.xAxis.labelGroup.element.children[this.x].classList.remove(
                      'main-chart-label-selected',
                    );
                  else if (this.series.xAxis.labelGroup.element.getElementsByTagName('text'))
                    this.series.xAxis.labelGroup.element
                      .getElementsByTagName('text')
                      [this.x].classList.remove('main-chart-label-selected');
                },
              },
            },
          },
        },
        series: dataset,
      },
      function () {
        if (typeof callback === 'function') {
          callback(this);
        }
      },
    );
  }

  _createChartWithNew(element, chartType, dataset) {
    return new Highcharts.Chart({
      chart: {
        type: chartType || 'line',
        renderTo: this.$.querySelector(`#${element}`),
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        margin: chartType == 'treemap' ? [0, 0, 0, 0] : undefined,
        spacing: chartType == 'treemap' ? [0, 0, 0, 0] : [10, 10, 15, 10],
        spacingBottom: 0,
        marginBottom: chartType == 'pie' ? 10 : 0,
        style: {
          fontFamily: 'Ubuntu',
          fontSize: '12px',
        },
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          enableMouseTracking: true,
          dataLabels: {
            enabled: false,
          },
          slicedOffset: 0,
          size: '100%',
          innerSize: '60%',
          point: {
            events: {
              mouseOver() {
                let chartInfo = null;
                switch (this.chartId) {
                  case 'money':
                    this.series.chart.customOptions.moneyChartTitle.attr({
                      text: this.series.chart.customOptions.setChartTitle(this.y, this.color),
                    });
                    this.series.chart.customOptions.setChartTitlePosition(
                      this.series.chart.customOptions.moneyChart,
                      this.series.chart.customOptions.moneyChartTitle,
                    );
                    chartInfo = {
                      value: this.valueForY.formatNumber(2, ',', '.'),
                      fuel: this.name,
                      color: this.color,
                    };
                    break;
                  case 'liters':
                    this.series.chart.customOptions.litersChartTitle.attr({
                      text: this.series.chart.customOptions.setChartTitle(this.y, this.color),
                    });
                    this.series.chart.customOptions.setChartTitlePosition(
                      this.series.chart.customOptions.litersChart,
                      this.series.chart.customOptions.litersChartTitle,
                    );
                    chartInfo = {
                      value: this.valueForY.formatNumber(2, ',', '.'),
                      fuel: this.name,
                      color: this.color,
                    };
                    break;
                  default:
                    break;
                }
                this.series.chart.customOptions.setChartScopeApply(this.chartId, chartInfo);
              },
              mouseOut() {
                let chartInfo = null;
                switch (this.chartId) {
                  case 'money':
                    this.series.chart.customOptions.moneyChartTitle.attr({ text: '' });
                    this.series.chart.customOptions.setChartTitlePosition(
                      this.series.chart.customOptions.moneyChart,
                      this.series.chart.customOptions.moneyChartTitle,
                    );
                    chartInfo = {
                      value: this.series.chart.dataAll.y.formatNumber(2, ',', '.'),
                      fuel: this.series.chart.dataAll.name,
                      color: this.series.chart.dataAll.color,
                    };
                    break;
                  case 'liters':
                    this.series.chart.customOptions.litersChartTitle.attr({ text: '' });
                    this.series.chart.customOptions.setChartTitlePosition(
                      this.series.chart.customOptions.litersChart,
                      this.series.chart.customOptions.litersChartTitle,
                    );
                    chartInfo = {
                      value: this.series.chart.dataAll.y.formatNumber(2, ',', '.'),
                      fuel: this.series.chart.dataAll.name,
                      color: this.series.chart.dataAll.color,
                    };
                    break;
                  default:
                    break;
                }

                this.series.chart.customOptions.setChartScopeApply(this.chartId, chartInfo);
              },
            },
          },
        },
        treemap: {
          tooltip: {
            padding: 0,
            useHTML: true,
            followPointer: true,
            pointFormatter() {
              const tooltipHtml = `${
                "<div class='flex-column chart-tooltip'>" +
                "<span class='tooltip-text-content'>" +
                "<i class='material-icons md-13' style='color:"
              }${this.color}'>fiber_manual_record</i>${this.name}: ${this.value}</span></div>`;

              return tooltipHtml;
            },
          },
          dataLabels: {
            useHTML: true,
            formatter() {
              let labelHtml = '';

              if (this.point.shapeArgs) {
                if (this.point.shapeArgs.width >= 30 && this.point.shapeArgs.height >= 30) {
                  labelHtml = `<i class='material-icons'>${this.point.exceptionObj.icon}</i>`;
                }
              }

              return labelHtml;
            },
          },
          point: {
            events: {
              select() {
                this.series.chart.customOptions.setChartTreemapScopeApply(this.exceptionObj);
              },
            },
          },
        },
      },
      tooltip: {
        enabled: chartType != 'pie',
        useHTML: true,
      },
      exporting: { enabled: false },
      series: dataset,
    });
  }

  _monthlyResumeChartSetData(optionChart, originalData, hasLastMonth, callback) {
    const chartDataReturn = { data: [], categories: [] };

    let fuels = [];

    const tempData = originalData.map(item => ({
      ano: item.ano,
      mes: item.mes,
      mesNome: item.mesNome,
      idCombustivel: item.idCombustivel,
      combustivel: item.combustivel,
      color: this._getFuelColor(item.idCombustivel),
    }));

    switch (optionChart) {
      case 'CustoKm':
        tempData.forEach((item, index) => {
          item.value = originalData[index].custoKm;
          item.valueOk = originalData[index].custoKmOk;
          item.valueEx = originalData[index].custoKmEx;
          item.prefix = 'R$';
          item.sufix = '/ Km';
          item.decimalPlaces = 2;
          item.type = 'money';
        });
        break;
      case 'ValorTotal':
        tempData.forEach((item, index) => {
          item.value = originalData[index].valorTotal;
          item.valueOk = originalData[index].valorTotalOk;
          item.valueEx = originalData[index].valorTotalEx;
          item.percent = originalData[index].percentValorTotal;
          item.percentOk = originalData[index].percentValorTotalOk;
          item.percentEx = originalData[index].percentValorTotalEx;
          item.prefix = 'R$';
          item.decimalPlaces = 2;
          item.type = 'money';
        });
        break;
      case 'PrecoMedio':
        tempData.forEach((item, index) => {
          item.value = originalData[index].precoMedio;
          item.valueOk = originalData[index].precoMedioOk;
          item.valueEx = originalData[index].precoMedioEx;
          item.prefix = 'R$';
          item.sufix = 'L';
          item.decimalPlaces = 3;
          item.type = 'money';
        });
        break;
      case 'LitrosTotal':
        tempData.forEach((item, index) => {
          item.value = originalData[index].litrosTotal;
          item.valueOk = originalData[index].litrosTotalOk;
          item.valueEx = originalData[index].litrosTotalEx;
          item.percent = originalData[index].percentLitrosTotal;
          item.percentOk = originalData[index].percentLitrosTotalOk;
          item.percentEx = originalData[index].percentLitrosTotalEx;
          item.sufix = 'L';
          item.decimalPlaces = 2;
          item.type = 'liters';
        });
        break;
      default:
        return;
    }

    if (tempData.length > 0) {
      const manipulatedData = tempData.reduce((acc, item) => {
        if (!(item.idCombustivel in acc)) {
          acc[item.idCombustivel] = item;
        }

        return acc;
      }, {});
      fuels = Object.keys(manipulatedData)
        .sort((a, b) => b - a)
        .map(key => manipulatedData[key].combustivel);

      fuels.forEach(fuel => {
        let serieIndex = 0;

        let serieVisible = true;

        const countItens = tempData.filter(item => item.combustivel == fuel).length;

        const valuesFromFuel = [];

        if (fuel === 'Etanol') {
          serieIndex = 1;
        } else if (fuel === 'Diesel') {
          serieIndex = 2;
        } else if (fuel === 'GNV') {
          serieIndex = 3;
        } else if (fuel === 'Todos') {
          serieIndex = 4;
          serieVisible = false;
        }

        tempData
          .filter(item => item.combustivel == fuel)
          .forEach((fuelValue, indexFuel) => {
            let markerObj = {};
            if (hasLastMonth && indexFuel + 1 == countItens) {
              markerObj = {
                fillColor: countItens % 2 === 0 ? '#EBEBEB' : '#f7f7f7',
                lineColor: fuelValue.color,
                lineWidth: 3,
                radius: 5,
                symbol: 'circle',
              };
            } else {
              markerObj = {
                radius: 5,
                lineColor: fuelValue.color,
                symbol: 'circle',
              };
            }

            valuesFromFuel.push({
              marker: markerObj,
              color: fuelValue.color,
              x: indexFuel,
              y:
                this.monthlyResumeChartOptFooter.value == 'ok'
                  ? fuelValue.valueOk
                  : this.monthlyResumeChartOptFooter.value == 'ex'
                  ? fuelValue.valueEx
                  : fuelValue.value,
              percentFromY: fuelValue.percent
                ? this.monthlyResumeChartOptFooter.value == 'ok'
                  ? fuelValue.percentOk
                  : this.monthlyResumeChartOptFooter.value == 'ex'
                  ? fuelValue.percentEx
                  : fuelValue.percent
                : null,
              tooltipOptions: {
                titlePrefix: '',
                titleSufix: `/ ${fuelValue.ano}`,
                prefix: fuelValue.prefix || '',
                sufix:
                  (optionChart == 'PrecoMedio' || optionChart == 'LitrosTotal') &&
                  fuelValue.idCombustivel == 54
                    ? 'M³'
                    : fuelValue.sufix || '',
                decimalPlaces: fuelValue.decimalPlaces,
                type: fuelValue.type,
                hasLastMonth,
              },
            });
          });

        if (fuel !== 'Todos') {
          serieVisible = valuesFromFuel.filter(item => item.y > 0).length > 0;
        }

        chartDataReturn.data.push({
          name: fuel,
          animation: false,
          data: valuesFromFuel,
          legendIndex: serieIndex,
          visible: serieVisible,
          color: valuesFromFuel[0].color,
          zIndex: serieIndex,
          zoneAxis: 'x',
          zones: hasLastMonth
            ? [
                {
                  value: valuesFromFuel.length - 2,
                },
                {
                  dashStyle: 'Dash',
                },
              ]
            : [],
        });
      });

      const parsedData = tempData.reduce((acc, item) => {
        if (!(item.mes in acc)) {
          acc[item.mes] = item;
        }

        return acc;
      }, {});

      chartDataReturn.categories = Object.keys(parsedData)
        .map(key => parsedData[key])
        .sort((a, b) => a.ano - b.ano)
        .map(item => item.mesNome);

      // eslint-disable-next-line no-unused-expressions
      chartDataReturn.data
        .filter(item => item.name == 'Todos')
        .map(item => {
          this.monthlyResumeChartTotal = item.data;
          return item;
        })[0];
    }

    const chartBlockElement = this.$.querySelector('.block-main-chart');
    chartBlockElement.classList.remove('chart-categories-3', 'chart-categories-6');
    if (chartDataReturn.categories.length <= 3) {
      chartBlockElement.classList.add('chart-categories-3');
    } else if (chartDataReturn.categories.length > 3 && chartDataReturn.categories.length <= 6) {
      chartBlockElement.classList.add('chart-categories-6');
    }

    if (typeof callback === 'function') {
      callback(chartDataReturn);
    }
  }

  _fuelKpiEvolutionChartSetData(originalData, callback) {
    const chartDataReturn = {
      dataMoney: { dataChart: [], fullData: {} },
      dataLiters: { dataChart: [], fullData: {} },
    };

    if (originalData.length > 0) {
      const fuels = originalData
        .filter(item => item.idCombustivel !== 0)
        .sort((a, b) => a.idCombustivel - b.idCombustivel);

      const fuelsAll = originalData.filter(item => item.idCombustivel == 0)[0];

      const valuesMoney = [];

      const valuesLiters = [];

      fuels.forEach((fuel, fuelIndex) => {
        valuesMoney.push({
          chartId: 'money',
          name: fuel.combustivel,
          color: this._getFuelColor(fuel.idCombustivel),
          x: fuelIndex,
          y: fuel.valorPercent,
          valueForY: fuel.valorTotal,
        });
        valuesLiters.push({
          chartId: 'liters',
          name: fuel.combustivel,
          color: this._getFuelColor(fuel.idCombustivel),
          x: fuelIndex,
          y: fuel.litrosPercent,
          valueForY: fuel.litrosTotal,
        });
      });

      chartDataReturn.dataMoney.dataChart.push({
        name: 'money',
        animation: false,
        borderColor: null,
        data: valuesMoney,
      });
      chartDataReturn.dataMoney.fullData = {
        y: fuelsAll.valorTotal,
        name: fuelsAll.combustivel,
        color: this._getFuelColor(fuelsAll.idCombustivel),
      };

      chartDataReturn.dataLiters.dataChart.push({
        name: 'liters',
        animation: false,
        borderColor: null,
        data: valuesLiters,
      });
      chartDataReturn.dataLiters.fullData = {
        y: fuelsAll.litrosTotal,
        name: fuelsAll.combustivel,
        color: this._getFuelColor(fuelsAll.idCombustivel),
      };
    }

    if (typeof callback === 'function') {
      callback(chartDataReturn);
    }
  }

  _fuelExceptionsSetData(originalData, callback) {
    const chartDataReturn = { data: [], categories: [] };

    let dataFiltered = [];

    const tempData = [];

    dataFiltered = originalData
      .reduce((acc, item) => {
        this.dashboardConfig.exceptionsChart.exceptions.forEach(item2 => {
          if (item.id == item2.id) {
            acc = acc.concat({ ...item, ...item2 });
          }
        });

        return acc;
      }, [])
      .map(original => {
        const tempConfig = this.dashboardConfig.exceptionsChart.exceptions.filter(
          item => item.id == original.id,
        )[0];

        // eslint-disable-next-line no-restricted-syntax
        for (const propConfig in tempConfig) {
          if (propConfig != 'id') {
            original[propConfig] = tempConfig[propConfig];
          }
        }
        return original;
      });

    dataFiltered = dataFiltered.sort((a, b) => a.order - b.order);
    dataFiltered.forEach(item => {
      tempData.push({
        id: item.id.toString(),
        name: item.descricao,
        color: item.color,
        value: item.qtd,
        exceptionObj: item,
        selected: item.id == 999,
      });

      if (item.id === 999) {
        this.fuelExceptionsInfo = item;
      }
    });

    chartDataReturn.data.push({
      type: 'treemap',
      cursor: 'pointer',
      allowPointSelect: true,
      data: tempData,
    });

    if (typeof callback === 'function') {
      callback(chartDataReturn);
    }
  }

  _lessPriceSuppliesIntervalControl(option) {
    if (this.hasSwipeableCards) {
      if (option == 'start') {
        this.intervalLessPriceSupplies = this.$interval(() => {
          // eslint-disable-next-line no-new
          new this.__CardAnimation(
            this.lessPriceSuppliesItemVisible === 0
              ? this.dataLessPriceSupplies.length - 1
              : this.lessPriceSuppliesItemVisible - 1,
            this.lessPriceSuppliesItemVisible === this.dataLessPriceSupplies.length - 1
              ? 0
              : this.lessPriceSuppliesItemVisible + 1,
          );
        }, this.dashboardConfig.sliderInterval || 3000);
        this.$scope.$on('$destroy', () => {
          this.$interval.cancel(this.intervalLessPriceSupplies);
        });
      } else {
        this.$interval.cancel(this.intervalLessPriceSupplies);
        this.intervalLessPriceSupplies = null;
      }
    } else if (option == 'start') {
      this.intervalLessPriceSupplies = this.$interval(() => {
        this.lessPriceSuppliesItemVisible =
          this.lessPriceSuppliesItemVisible == this.dataLessPriceSupplies.length - 1
            ? 0
            : this.lessPriceSuppliesItemVisible + 1;
      }, this.dashboardConfig.sliderInterval || 3000);
      this.$scope.$on('$destroy', () => {
        this.$interval.cancel(this.intervalLessPriceSupplies);
      });
    } else {
      this.$interval.cancel(this.intervalLessPriceSupplies);
      this.intervalLessPriceSupplies = null;
    }
  }

  _saveConfigAverages(msgErrorRequest) {
    const inputValues = this.popupConfigConsumptionAverages.body.inputs
      .filter(item => item.type == 'text')
      .map(item => item.values)[0];

    const params = [];
    if (this.showInputPanel == 1) {
      params.push(Object.clone({}, this.paramsForSaveConsumptionAverages));
      params.push(Object.clone({}, this.paramsForSaveConsumptionAverages));
      // 0 - Gasolina, 1 - Etanol;
      params[0] = Object.assign(params[0], {
        fuelType: 18,
        minPercent: this.configAveragesForFlex.gasolineMin,
        maxPercent: this.configAveragesForFlex.gasolineMax,
        vehicleAverage: parseFloat(this.configAveragesForFlex.gasolineAverage),
      });
      params[1] = Object.assign(params[1], {
        fuelType: 19,
        minPercent: this.configAveragesForFlex.etanolMin,
        maxPercent: this.configAveragesForFlex.etanolMax,
        vehicleAverage: parseFloat(this.configAveragesForFlex.etanolAverage),
      });
    } else if (this.showInputPanel == 2) {
      params.push(
        Object.assign(this.paramsForSaveConsumptionAverages, {
          minPercent: inputValues[2].value,
          maxPercent: inputValues[0].value,
          vehicleAverage: parseFloat(inputValues[1].value),
        }),
      );
    }
    params.forEach(param => {
      this.dashboardServices.saveConsumptionPreset(param).then(
        success => {
          if ((success.status && success.status === 500) || success.hasError) {
            Object.assign(this.toastDashboard, { open: true, text: msgErrorRequest });
            return;
          }
          Object.assign(this.toastDashboard, {
            open: true,
            text: 'Configurações aplicadas com sucesso.',
          });
          this._evtClickClosePopupConfigAverage();
        },
        () => Object.assign(this.toastDashboard, { open: true, text: msgErrorRequest }),
      );
    });
  }

  _evtClickBtnsPopup(btn) {
    const labelManualEntry = this.$.querySelector('#label-manual-entry');

    if (btn.method == 'cancel') {
      this.$.querySelector('#popup-manual-entry').toggle();
    } else if (btn.method == 'more') {
      this.statusPopupManualEntry = 0;
    } else if (btn.method == 'reform') {
      this.__clearPopupManualEntry(labelManualEntry, false);
      this.statusPopupManualEntry = 0;
    } else if (btn.method == 'cancelConfirm') {
      this.$.querySelector('#popup-confirm-save-configuration-averages').toggle();
    } else if (btn.method == 'confirm') {
      this._saveConfigAverages('Ocorreu um erro ao aplicar as configurações.');
      this.$.querySelector('#popup-confirm-save-configuration-averages').toggle();
    } else {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      this.dashboardServices
        .sendFileManualEntry(this.formDataManualEntry)
        .then(success => {
          if (success.data.hasError) {
            this.statusPopupManualEntry = 2;

            this.dataPopupManualEntryError.fileName = labelManualEntry.children[0].innerHTML;
            this.dataPopupManualEntryError.countError = success.data.data.length;
            this.dataPopupManualEntryError.data = success.data.data;
          } else {
            this.statusPopupManualEntry = 1;

            this.__clearPopupManualEntry(labelManualEntry, false);
          }
        })
        .finally(() => {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    }
  }

  _evtClickOpenPopupManualEntry() {
    this.statusPopupManualEntry = 0;
    this.$.querySelector('#popup-manual-entry').toggle();

    this.inputManualEntry = this.$.querySelector('#file-manual-entry');

    const labelManualEntry = this.$.querySelector('#label-manual-entry');

    const ctrl = this;

    // eslint-disable-next-line func-names
    this.inputManualEntry.onchange = function () {
      const { files } = this;

      if (this.files && this.files.length > 0) {
        const file = files[0];

        labelManualEntry.children[0].innerHTML = file.name;
        labelManualEntry.children[0].classList.add('has-file');

        if (file.name.indexOf('.xls') > -1 || file.name.indexOf('.xlsx') > -1) {
          ctrl.formDataManualEntry.append('inputManualEntry', file);

          if (labelManualEntry.nextElementSibling.style.display == 'block') {
            labelManualEntry.nextElementSibling.style.display = 'none';
          }

          // eslint-disable-next-line no-unused-expressions
          ctrl.dashboardConfig.popupManualEntry.footer.buttons
            .filter(item => item.method == 'save')
            .map(item => {
              item.disabled = false;
              return item;
            })[0];
        } else {
          labelManualEntry.nextElementSibling.style.display = 'block';
          // eslint-disable-next-line no-unused-expressions
          ctrl.dashboardConfig.popupManualEntry.footer.buttons
            .filter(item => item.method == 'save')
            .map(item => {
              item.disabled = true;
              return item;
            })[0];
        }

        ctrl.$scope.$apply();
      } else {
        ctrl.__clearPopupManualEntry(labelManualEntry, true);
      }
    };
  }

  _evtClickPopupLegacyEquipament() {
    this.$.querySelector('#popup-legacy-equipament').toggle();
    this.popupConfigAverageOpened = false;
  }

  _evtClickClosePopupConfigAverage() {
    if (this.popupConfigAverageOpened) {
      this.$.querySelector('#popup-configuration-averages').toggle();
      this.popupConfigAverageOpened = false;
    }
    this.__clearConfigAverages();
  }

  _evtClickConfigConsumptionAverages() {
    this.$.querySelector('#popup-configuration-averages').toggle();
    this.popupConfigAverageOpened = !this.popupConfigAverageOpened;
    this.__createWatchPopupAverage();
  }

  _evtClickPopupAveragePreset() {
    this.__popupAveragePresetChanged();
  }

  _evtClickDropdownAverages(evt, itemReturn, option) {
    evt.preventDefault();

    if (option.hasMethod) {
      const nextDropdown = this.popupConfigConsumptionAverages.body.options.filter(
        item => item.type == 'dropdown' && item.parent == option.id,
      )[0];

      // 1. Limpa todos os filhos
      let clearFields = true;

      let nextDropdownNext = null;

      while (clearFields) {
        if (nextDropdown.optionSelected) {
          nextDropdown.values = [];
          nextDropdown.loading = false;
          nextDropdown.optionSelected = null;
        }

        [nextDropdownNext] = this.popupConfigConsumptionAverages.body.options
          .filter(
            // eslint-disable-next-line no-loop-func
            item =>
              item.type == 'dropdown' &&
              item.parent == (nextDropdownNext ? nextDropdownNext.id : nextDropdown.id),
          )
          .map(next => {
            next.loading = false;
            next.values = [];
            next.optionSelected = null;
            next.enabled = false;
            return next;
          });

        if (nextDropdownNext) {
          clearFields = true;
        } else {
          clearFields = false;
        }
      }

      // 2. Carregando... o próximo dropdown
      nextDropdown.loading = true;

      // 3. GetParams para chamar o método e popular o próximo dropdown
      const params = {};
      if (option.methodParam) {
        params[option.methodParam] = itemReturn.value;
      }
      if (option.fixedParams) {
        option.fixedParams.forEach(fixedParam => {
          if (fixedParam.fromParentId) {
            const parentValue = this.popupConfigConsumptionAverages.body.options.filter(
              item => item.id == fixedParam.fromParentId,
            )[0];
            Object.assign(params, {
              [parentValue.methodParam]: parentValue.optionSelected.value
                ? parentValue.optionSelected.value
                : parentValue.optionSelected,
            });
          } else {
            Object.assign(params, fixedParam);
          }
        });
      }

      option.optionSelected = itemReturn;

      const buttonSave = this.popupConfigConsumptionAverages.footer.buttons.filter(
        item => item.method == 'save',
      )[0];

      if (option.canSave) {
        buttonSave.disabled = false;
        this.paramsForSaveConsumptionAverages = Object.clone({}, params);
      } else {
        buttonSave.disabled = true;
        this.paramsForSaveConsumptionAverages = null;
      }

      this.dashboardServices.getConsumptionPreset(params).then(
        success => {
          if ((success.status && success.status === 500) || success.hasError) {
            nextDropdown.error = true;
            return;
          }
          if (option.title == 'Filtrar por') {
            nextDropdown.title = itemReturn.description.split('(')[0].replace(' ', '');
          }
          if (success.data.length > 0) {
            nextDropdown.values = success.data;
            nextDropdown.error = false;
            nextDropdown.loading = false;
            nextDropdown.enabled = true;
          }
        },
        () => {
          nextDropdown.error = true;
        },
      );
    }

    if (option.dropdownType === 'Checkbox') {
      option.values
        .filter(item => item.id === itemReturn.id)
        .forEach(item => {
          item.selected = !itemReturn.selected;
        });

      option.optionSelected = option.values.filter(item => item.selected);
      option.checkAll = option.optionSelected.length === option.values.length;
    }
  }

  _evtClickDropdownAveragesCheckAll(evt, option) {
    evt.preventDefault();
    option.checkAll = !option.checkAll;

    option.values.forEach(item => {
      item.selected = option.checkAll;
    });

    option.optionSelected = option.values.filter(item => item.selected);
  }

  _evtClickBtnsPopupConfigAverage(btn) {
    if (btn.method == 'cancel') {
      this._evtClickClosePopupConfigAverage();
    } else if (this.showInputPanel != 3 && this.__validateBeforeSaveConfigAverages()) {
      this.toastDashboard.text = 'Todos os campos de configurações de médias são obrigatórios!';
      this.$.querySelector('#toast-obj').toggle(true);
    } else {
      if (this.showInputPanel == 3) {
        if (!this.checkboxRemoveGnv) {
          this.toastDashboard.text =
            'Para remover a configuração de GNV marque o campo "REMOVER GNV DOS VEÍCULOS SELECIONADOS"!';
          this.$.querySelector('#toast-obj').toggle(true);
          return;
        }

        Object.assign(this.paramsForSaveConsumptionAverages, { removeGnv: true });
        this.dashboardServices.saveConsumptionPreset(this.paramsForSaveConsumptionAverages).then(
          success => {
            if ((success.status && success.status === 500) || success.hasError) {
              this.toastDashboard.text = 'Ocorreu um erro ao tentar remover a configuração de GNV.';
              this.$.querySelector('#toast-obj').toggle(true);
              return;
            }

            this.toastDashboard.text = 'Configurações aplicadas com sucesso.';
            this.$.querySelector('#toast-obj').toggle(true);
            this._evtClickClosePopupConfigAverage();
          },
          () => {
            this.toastDashboard.text = 'Ocorreu um erro ao tentar remover a configuração de GNV.';
            this.$.querySelector('#toast-obj').toggle(true);
          },
        );
        return;
      }

      const fuelSelected = this.popupConfigConsumptionAverages.body.inputs.filter(
        item => item.type == 'radioGroup' && item.optionSelected,
      )[0];

      const msgErrorRequest = 'Ocorreu um erro ao aplicar as configurações.';

      Object.assign(this.paramsForSaveConsumptionAverages, {
        fuelType: fuelSelected.optionSelected,
      });

      this.dashboardServices.validateConsumptionPreset(this.paramsForSaveConsumptionAverages).then(
        success => {
          if ((success.status && success.status === 500) || success.hasError) {
            this.toastDashboard.text = msgErrorRequest;
            this.$.querySelector('#toast-obj').toggle(true);
            return;
          }

          if (success.data > 0) {
            this.$.querySelector('#popup-confirm-save-configuration-averages').toggle();
          } else {
            this._saveConfigAverages(msgErrorRequest);
          }
        },
        () => {
          this.toastDashboard.text = 'Ocorreu um erro ao aplicar as configurações.';
          this.$.querySelector('#toast-obj').toggle(true);
        },
      );
    }
  }

  _evtSelectOptionMonthlyResume(itemReturn) {
    this.dashboardConfig.mainChart.optionSelected = itemReturn;

    this._monthlyResumeChartSetData(
      itemReturn.value,
      this.dataMonthlyResume.monthlySuppliesDash,
      this.dataMonthlyResume.hasLastMonth,
      chartDataReturn => {
        chartDataReturn.data.forEach((data, indexData) => {
          this.monthlyResumeChart.series[indexData].setData(data.data, false);
        });
        this.monthlyResumeChart.redraw();
      },
    );
  }

  _evtClickOptionMonthlyResumeFooter(option) {
    if (this.monthlyResumeChartOptFooter.id == option.id) return;

    this.monthlyResumeChartOptFooter = option;

    this._monthlyResumeChartSetData(
      this.dashboardConfig.mainChart.optionSelected.value,
      this.dataMonthlyResume.monthlySuppliesDash,
      this.dataMonthlyResume.hasLastMonth,
      chartDataReturn => {
        chartDataReturn.data.forEach((data, indexData) => {
          this.monthlyResumeChart.series[indexData].setData(data.data, false);
        });
        this.monthlyResumeChart.redraw();
      },
    );
  }

  _evtClickMonthlyResumeChartSerie(event, chartSerie) {
    event.preventDefault();
    // eslint-disable-next-line no-prototype-builtins
    if (chartSerie.hasOwnProperty('visible') && chartSerie.visible) {
      this.monthlyResumeChart.series[chartSerie.index].hide();
    } else if (chartSerie.selected) {
      this.monthlyResumeChart.series[chartSerie.value].hide();
      // eslint-disable-next-line no-prototype-builtins
    } else if (chartSerie.hasOwnProperty('value')) {
      this.monthlyResumeChart.series[chartSerie.value].show();
    } else {
      this.monthlyResumeChart.series[chartSerie.index].show();
    }

    // eslint-disable-next-line no-prototype-builtins
    if (chartSerie.hasOwnProperty('value')) {
      chartSerie.selected = !chartSerie.selected;

      // eslint-disable-next-line no-unused-expressions
      this.monthlyResumeChartSeries
        .filter(item => item.index == chartSerie.value)
        .map(item => {
          item.visible = true;
          return item;
        })[0];
    } else {
      chartSerie.visible = !chartSerie.visible;

      // eslint-disable-next-line no-unused-expressions
      this.monthlyResumeChartSeriesMobile
        .filter(item => item.index == chartSerie.index)
        .map(item => {
          item.visible = true;
          return item;
        })[0];
    }
  }

  _evtClickLinkExceptions() {
    if (this.fuelExceptionsInfo.modulo == 1 || this.fuelExceptionsInfo.id == 999) {
      this._goToLink(
        this.dashboardConfig.linkAbastecimentos.linkName,
        this.dashboardConfig.linkAbastecimentos.linkRoute,
        this.dashboardConfig.linkAbastecimentos.linkParameters,
        this.dashboardConfig.linkAbastecimentos.linkFilters,
        this.dashboardConfig.linkAbastecimentos.linkStateConfig,
        this.dashboardConfig.linkAbastecimentos.linkGetDataMethod,
        this.dashboardConfig.linkAbastecimentos.backPagination,
        this.fuelExceptionsInfo,
      );
    } else if (this.fuelExceptionsInfo.modulo == 2) {
      this._goToLink(
        this.dashboardConfig.linkConsumoTrecho.linkName,
        this.dashboardConfig.linkConsumoTrecho.linkRoute,
        this.dashboardConfig.linkConsumoTrecho.linkParameters,
        this.dashboardConfig.linkConsumoTrecho.linkFilters,
        this.dashboardConfig.linkConsumoTrecho.linkStateConfig,
        this.dashboardConfig.linkConsumoTrecho.linkGetDataMethod,
        this.dashboardConfig.linkConsumoTrecho.backPagination,
        this.fuelExceptionsInfo,
      );
    }
  }

  _onControlFocusBlurInput(control, event) {
    if (control == 'focus') {
      event.target.parentElement.classList.add('selected');
      event.target.nextElementSibling.classList.add('selected');
    } else {
      event.target.parentElement.classList.remove('selected');
      event.target.nextElementSibling.classList.remove('selected');
    }
  }

  _evtClickPopupModuleInfoAction(action) {
    if (action.route) {
      this.$state.go(action.route);
    } else if (action.method) {
      if (action.method == 'openManualEntry') {
        this._evtClickOpenPopupManualEntry();
      }
    }
  }

  _evtClickPopupModuleInfoClose() {
    this.$.querySelector('#popup-module-info').toggle();
  }

  _evtClickPopupFuelExceptions() {
    this.$.querySelector('#golfleet-popup-fuel-exceptions').toggle();
  }

  /* */

  // #region Protected
  __clearData() {
    this.configDataFuelSupply = 0;
    this.configDataMonthlyResume = 0;
    this.configDataFuelExceptions = 0;
    this.configDataFuelKpiEvolution = 0;
    this.configDataLessPriceSupplies = 0;
    if (typeof this._lessPriceSuppliesIntervalControl == 'function') {
      this._lessPriceSuppliesIntervalControl('stop');
    }
  }

  __clearPopupManualEntry(labelManualEntry, applyScope) {
    labelManualEntry.children[0].innerHTML = 'Nenhum arquivo selecionado';
    labelManualEntry.children[0].classList.remove('has-file');
    this.inputManualEntry.value = '';
    this.formDataManualEntry.delete('inputManualEntry');
    // eslint-disable-next-line no-unused-expressions
    this.dashboardConfig.popupManualEntry.footer.buttons
      .filter(item => item.method == 'save')
      .map(item => {
        item.disabled = true;
        return item;
      })[0];

    if (applyScope) {
      this.$scope.$apply();
    }
  }

  __clearConfigAverages() {
    // 1. Limpa dropdowns e inputs comuns
    this.popupConfigConsumptionAverages = Object.clone({}, this.dashboardConfig.popupConfigMedias);

    // 3. Limpa inputs Flex
    this.configAveragesForFlex = {
      gasolineMin: '',
      gasolineMax: '',
      gasolineAverage: '',
      etanolMin: '',
      etanolMax: '',
      etanolAverage: '',
    };

    // 4. Desmarca opção de GNV
    this.checkboxRemoveGnv = false;
  }

  __validateBeforeSaveConfigAverages() {
    if (this.showInputPanel == 1) {
      return (
        this.configAveragesForFlex.filter(
          item => item.value == '' || item.value == undefined || item.valule == 0,
        ).length > 0
      );
    }
    if (this.showInputPanel == 2) {
      const inputValues = this.popupConfigConsumptionAverages.body.inputs
        .filter(item => item.type == 'text')
        .map(
          objInput =>
            objInput.values.filter(
              item => item.value == '' || item.value == undefined || item.valule == 0,
            ).length > 0,
        )[0];

      return inputValues;
    }

    return null;
  }

  __createWatchPopupAverage() {
    this.watchPopupAveragePreset = this.$scope.$watch(
      () => this.popupConfigConsumptionAverages.body.options[0].optionSelected,
      this._onPopupAveragePresetChanged.bind(this),
    );
    this.watchPopupAverageFuelPreset = this.$scope.$watch(
      () => this.popupConfigConsumptionAverages.body.inputs[0].optionSelected,
      this._onPopupAverageFuelPresetChanged.bind(this),
    );
  }

  __SwipeableCards(cardsContainer) {
    this.targetBCR = null;
    this.target = null;
    this.startX = 0;
    this.currentX = 0;
    this.screenX = 0;
    this.targetX = 0;
    this.isScroll = null;
    this.draggingCard = false;

    this.onStart = function (evt) {
      const cardTarget = evt.target.classList.contains('card')
        ? evt.target
        : evt.target.closest('.card');

      if (!cardTarget || this.cardAnimated) return;

      this.isScroll = null;
      this.target = cardTarget;
      this.targetBCR = cardTarget.getBoundingClientRect();
      this.startY = evt.pageY || evt.touches[0].pageY;
      this.startX = evt.pageX || evt.touches[0].pageX;
      this.currentX = this.startX;
      this.draggingCard = true;
      cardTarget.style.willChange = 'transform';

      if (typeof this._lessPriceSuppliesIntervalControl == 'function') {
        this._lessPriceSuppliesIntervalControl('stop');
      }
    };

    this.onMove = evt => {
      if (this.isScroll === null) {
        let diffY = (evt.pageY || evt.touches[0].pageY) - this.startY;

        let diffX = (evt.pageX || evt.touches[0].pageX) - this.startX;
        diffY = diffY < 0 ? diffY * -1 : diffY;
        diffX = diffX < 0 ? diffX * -1 : diffX;
        if (diffY > diffX) this.isScroll = true;
        else this.isScroll = false;
      }
      if (this.isScroll === true) this.target = null;
      else if (this.isScroll === false) evt.preventDefault();
      if (!this.target || !this.draggingCard) return;
      this.currentX = evt.pageX || evt.touches[0].pageX;
      if (this.currentX - this.startX < 0) {
        if (this.target.nextElementSibling)
          Object.assign(this.target.nextElementSibling.style, {
            zIndex: 2,
            opacity: 1,
            transform: 'translateX(0)',
          });
        if (this.target.previousElementSibling)
          Object.assign(this.target.previousElementSibling.style, {
            zIndex: 1,
            opacity: 0,
            pointerEvents: 'none',
          });
      } else {
        if (this.target.previousElementSibling)
          Object.assign(this.target.previousElementSibling.style, {
            zIndex: 2,
            opacity: 1,
            transform: 'translateX(0)',
          });
        if (this.target.nextElementSibling)
          Object.assign(this.target.nextElementSibling.style, {
            zIndex: 1,
            opacity: 0,
            pointerEvents: 'none',
          });
      }
    };

    this.onEnd = () => {
      if (!this.target) return;
      const threshold = this.targetBCR.width * 0.35;
      const screenX = this.currentX - this.startX;
      this.targetX = 0;
      if (
        Math.abs(screenX) > threshold &&
        ((screenX > 0 && this.target.previousElementSibling) ||
          (screenX < 0 && this.target.nextElementSibling))
      )
        this.targetX = screenX > 0 ? this.targetBCR.width : -this.targetBCR.width;
      this.draggingCard = false;
      if (typeof this._lessPriceSuppliesIntervalControl == 'function') {
        this._lessPriceSuppliesIntervalControl('start');
      }
    };

    this.update = () => {
      requestAnimationFrame(this.update.bind(this));
      if (!this.target) return;
      if (this.draggingCard) this.screenX = this.currentX - this.startX;
      else this.screenX += (this.targetX - this.screenX) / 4;
      const normalizedDragDistance = Math.abs(this.screenX) / this.targetBCR.width;
      // eslint-disable-next-line no-restricted-properties
      const opacity = 1 - Math.pow(normalizedDragDistance, 3);
      Object.assign(this.target.style, { opacity, transform: `translateX(${this.screenX}px)` });
      if (this.draggingCard) return;
      const isNearlyAtStart = Math.abs(this.screenX) < 0.1;
      const isNearlyInvisible = opacity < 0.01;
      if (isNearlyInvisible) {
        if (this.targetX < 0) {
          this.lessPriceSuppliesItemVisible += 1;
          this.$scope.$apply();
          Object.assign(this.target.style, {
            zIndex: 1,
            opacity: 0,
            transform: 'translateX(0)',
            pointerEvents: 'none',
          });
          if (this.target.nextElementSibling)
            Object.assign(this.target.nextElementSibling.style, {
              zIndex: 3,
              pointerEvents: 'all',
            });
        } else if (this.targetX > 0) {
          this.lessPriceSuppliesItemVisible -= 1;
          if (this.$scope) {
            this.$scope.$apply();
          }
          Object.assign(this.target.style, {
            zIndex: 1,
            opacity: 0,
            transform: 'translateX(0)',
            pointerEvents: 'none',
          });
          if (this.target.previousElementSibling)
            Object.assign(this.target.previousElementSibling.style, {
              zIndex: 3,
              pointerEvents: 'all',
            });
        }
        this.target = null;
      } else if (isNearlyAtStart) this.target = null;
    };

    // Mobile
    cardsContainer.addEventListener('touchstart', this.onStart.bind(this));
    cardsContainer.addEventListener('touchmove', this.onMove.bind(this));
    cardsContainer.addEventListener('touchend', this.onEnd.bind(this));

    // Desktop
    cardsContainer.addEventListener('mousedown', this.onStart.bind(this));
    cardsContainer.addEventListener('mousemove', this.onMove.bind(this));
    cardsContainer.addEventListener('mouseup', this.onEnd.bind(this));

    requestAnimationFrame(this.update.bind(this));
  }

  __FirstAnimation() {
    if (!this.$) {
      return;
    }
    [this.cardElement] = this.$.querySelectorAll('.card');
    if (!this.cardElement) {
      return;
    }
    this.translateXPosition = 0;
    this.rightCardAnimation = () => {
      const lastXPosition = this.translateXPosition;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
        willChange: 'transform',
      });
      this.translateXPosition += 1;
      if (lastXPosition == 10) {
        requestAnimationFrame(this.leftCardAnimation.bind(this));
      } else requestAnimationFrame(this.rightCardAnimation.bind(this));
    };
    this.leftCardAnimation = () => {
      const lastXPosition = this.translateXPosition;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
      });
      this.translateXPosition -= 1;
      if (lastXPosition === 0) {
        if (this.cardElement.nextElementSibling)
          Object.assign(this.cardElement.nextElementSibling.style, { zIndex: 2, opacity: 1 });
        requestAnimationFrame(this.leftCardAnimation.bind(this));
      } else if (lastXPosition == -10) requestAnimationFrame(this.centerCardAnimation.bind(this));
      else requestAnimationFrame(this.leftCardAnimation.bind(this));
    };
    this.centerCardAnimation = () => {
      const lastXPosition = this.translateXPosition;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
      });
      this.translateXPosition += 1;
      if (lastXPosition === 0) {
        Object.assign(this.cardElement.style, { willChange: null });
        if (this.cardElement.nextElementSibling)
          Object.assign(this.cardElement.nextElementSibling.style, { zIndex: 1, opacity: 0 });
      } else requestAnimationFrame(this.centerCardAnimation.bind(this));
    };
    if (this.cardElement) {
      requestAnimationFrame(this.rightCardAnimation.bind(this));
    }
  }

  __CardAnimation(previousIndex, nextIndex) {
    if (this.cardAnimated) return;
    if (!this.$) {
      return;
    }

    this.element = this.$.querySelectorAll('.card');

    if (!this.element) {
      return;
    }

    this.cardElement = this.element[this.lessPriceSuppliesItemVisible];
    this.translateXPosition = 0;
    this.previousCard = this.element[previousIndex];
    this.nextCard = this.element[nextIndex];

    this.cardAnimation = () => {
      const opacity = 1 + this.translateXPosition / 100;
      Object.assign(this.cardElement.style, {
        transform: `translateX(${this.translateXPosition}%)`,
        opacity,
        willChange: 'transform, opacity',
      });
      this.translateXPosition -= 5;
      if (opacity == 1) {
        Object.assign(this.previousCard.style, { zIndex: 1, opacity: 0 });
        Object.assign(this.nextCard.style, { zIndex: 2, opacity: 1 });

        requestAnimationFrame(this.cardAnimation.bind(this));
      } else if (opacity === 0) {
        Object.assign(this.cardElement.style, {
          transform: 'translateX(0)',
          opacity,
          zIndex: 1,
          willChange: null,
        });
        Object.assign(this.nextCard.style, { zIndex: 3, pointerEvents: 'all' });

        this.cardAnimated = false;
      } else requestAnimationFrame(this.cardAnimation.bind(this));
    };
    if (this.cardElement) {
      this.cardAnimated = true;
      requestAnimationFrame(this.cardAnimation.bind(this));
      this.lessPriceSuppliesItemVisible = nextIndex;
    }
  }
  // #endregion Protected

  /* Observers */
  _onMonthlyResumeChartTotalChanged(chartValue) {
    if (chartValue.length > 0) {
      this.$timeout(() => {
        const chartElement = this.$.querySelector('#monthly-resume-chart');

        const chartTotalElement = this.$.querySelector('.chart-total');

        const xAxisWidth = Math.floor(
          chartElement.querySelector('.highcharts-xaxis').getBoundingClientRect().width,
        );

        const chartTotalDivChildren = chartTotalElement.children;

        for (let index = 0; index < chartTotalDivChildren.length; index += 1) {
          chartTotalDivChildren[index].style.maxWidth = `${
            Math.floor(xAxisWidth / chartTotalDivChildren.length) + 1
          }px`;
        }
      });
    }
  }

  _onPopupAveragePresetChanged(optionSelected) {
    let firstDropdown = null;
    this.popupConfigConsumptionAverages.body.options
      .filter(item => item.type == 'dropdown')
      .map(dropdown => {
        if (dropdown.parent == this.popupConfigConsumptionAverages.body.options[0].id)
          firstDropdown = dropdown;
        dropdown.optionSelected = null;
        dropdown.values = [];
        dropdown.loading = false;
        dropdown.enabled = false;

        return dropdown;
      });

    // eslint-disable-next-line no-unused-expressions
    this.popupConfigConsumptionAverages.footer.buttons
      .filter(item => item.method == 'save')
      .map(item => {
        item.disabled = true;
        return item;
      })[0];

    this.dashboardServices.getConsumptionPreset({ presetOk: optionSelected }).then(
      success => {
        if ((success.status && success.status === 500) || success.hasError) {
          this.statusPopupConsumptionAverages = 2;
          return;
        }

        if (firstDropdown) {
          firstDropdown.values = success.data;
          firstDropdown.enabled = true;

          this.statusPopupConsumptionAverages = 1;
        } else {
          this.statusPopupConsumptionAverages = 2;
        }
      },
      () => {
        this.statusPopupConsumptionAverages = 2;
      },
    );
  }

  _onPopupAverageFuelPresetChanged(optionSelected) {
    if (optionSelected == 3) this.showInputPanel = 1;
    else if (optionSelected == 54) this.showInputPanel = 3;
    else this.showInputPanel = 2;
  }
  /* */
}

class GolfleetDashboardFuelModule {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetDashboardFuelModuleController;
  }
}
angular
  .module('golfleet-dashboard-fuel-module', [
    'ngRedux',
    'power-dropdown',
    'power-toolbar',
    'golfleet-dashboard-block',
    'power-single-checkbox',
    'power-loader',
    'power-radio-button-group',
    'golfleet-dashboard-error',
    'golfleet-dashboard-no-data',
    'power-popup',
    'power-toast',
    'golfleet-popup-fuel-exceptions',
  ])
  .component('golfleetDashboardFuelModule', new GolfleetDashboardFuelModule());
