import angular from 'angular';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '../golfleet-grid-tco-parking/golfleet-grid-tco-parking';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-tco-parking.html';
import './golfleet-report-tco-parking.scss';

class GolfleetReportTcoParkingController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.formDataManualEntry = new FormData();
    this.dataImportPopupEntryError = {};
  }

  _onClickImportButton() {
    this.statusImport = 0;
    this.$.querySelector('#popup-import').toggle();

    const inputManualEntry = this.$.querySelector('#file-manual-entry');

    const labelManualEntry = this.$.querySelector('#label-manual-entry');

    inputManualEntry.onchange = () => {
      const { files } = inputManualEntry;

      if (files && files.length > 0) {
        const file = files[0];
        const { footer } = this.stateConfig.toolbarConfig.importConfig.popup;

        labelManualEntry.children[0].innerHTML = file.name;
        labelManualEntry.children[0].classList.add('has-file');

        if (file.name.indexOf('.xls') > -1 || file.name.indexOf('.xlsx') > -1) {
          this.formDataManualEntry.append('importFile', file);

          this.formDataManualEntry.append(
            'timezone',
            // Intl.DateTimeFormat().resolvedOptions().timeZone,
            'America/Sao_Paulo',
          );

          if (labelManualEntry.nextElementSibling.style.display === 'block') {
            labelManualEntry.nextElementSibling.style.display = 'none';
          }

          footer.buttons.map(item => {
            item.disabled = item.method !== 'save' && item.method !== 'cancel';
            return item;
          });
        } else {
          labelManualEntry.nextElementSibling.style.display = 'block';

          footer.buttons.map(item => {
            item.disabled = item.method == 'save';
            return item;
          });
        }

        this.$scope.$apply();
      } else {
        this.clearPopupManualEntry(inputManualEntry, labelManualEntry, true);
      }
    };
  }

  _amountShowingHeaders() {
    return this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders
      ? this.stateConfig.gridConfig.gridHeaders.filter(ele => ele.show && !ele.isEdit).length
      : 0;
  }

  _addItem(method, data) {
    const payload = {};
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const attr in data) {
      payload[attr] = data[attr];
    }

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: { request: payload },
    }).then(
      () => {
        this.$.querySelector('#popup-add').toggle();
        this.$.querySelector('#popup-add power-crud').clearKeys();
        this.$timeout(() => {
          this.toastText = `${this.stateConfig.toolbarConfig.addConfig.title} adicionado.`;
          this.$.querySelector('power-toast#report-toast').toggle(true);
          this.requestDataset();
        });
      },
      error => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        switch (error.status) {
          case 406:
          case 422:
            this.toastText = error.data.data.toString();
            break;
          default:
            this.toastText = 'Erro ao adicionar o item.';
            break;
        }
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  clearPopupManualEntry(input, label, applyScope) {
    const { popup } = this.stateConfig.toolbarConfig.importConfig;
    label.children[0].innerHTML = 'Nenhum arquivo selecionado';
    label.children[0].classList.remove('has-file');
    input.value = '';
    this.formDataManualEntry.delete('importFile');
    this.formDataManualEntry.delete('timezone');

    popup.footer.buttons.map(item => {
      item.disabled = item.method == 'save';
      return item;
    });

    if (applyScope) this.$scope.$apply();
  }

  evtClickBtnsPopup(method) {
    const labelManualEntry = document.querySelector('#label-manual-entry');
    const inputManualEntry = document.querySelector('#file-manual-entry');

    if (method === 'cancel') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
      this.$.querySelector('#popup-import').toggle();
    } else if (method === 'ok') {
      this.$.querySelector('#popup-import').toggle();
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      this.$scope.$broadcast('getDataset', null);
    } else if (method === 'more') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else if (method === 'reform') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else {
      const methodUrl = this.stateConfig.toolbarConfig.importConfig.dataPostMethod;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      this.reportServices
        .sendFileManualEntry(methodUrl, this.formDataManualEntry)
        .then(
          () => {
            this.statusImport = 1;

            this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
          },
          ({ data }) => {
            if (data && data.hasError) {
              this.statusImport = 2;
              this.dataImportPopupEntryError.fileName = labelManualEntry.children[0].innerHTML;
              this.dataImportPopupEntryError.countError = data.data.length;
              this.dataImportPopupEntryError.data = data.data;
            }
          },
        )
        .finally(() => {
          this.$scope.$broadcast('getDataset', null);
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    }
  }
}

class GolfleetReportTcoParking {
  constructor() {
    this.template = template;
    this.transclude = {
      addActionSlot: '?addActionSlot',
      footerActionSlot: '?footerActionSlot',
    };
    this.bindings = {
      actualView: '=?',
      hasMapView: '=?',
      hasAddAction: '=?',
      hasFooterAction: '=?',
    };
    this.controller = GolfleetReportTcoParkingController;
  }
}

angular
  .module('golfleet-report-tco-parking', [
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'golfleet-grid-tco-parking',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
  ])
  .component('golfleetReportTcoParking', new GolfleetReportTcoParking());
