import angular from 'angular';
import 'angular-drag-and-drop-lists';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '../golfleet-grid-tco-accident/golfleet-grid-tco-accident';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-crud/power-crud';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-share/power-popup-share';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-tco-accident.html';
import './golfleet-report-tco-accident.scss';

class GolfleetReportTcoAccidentController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'crudServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    crudServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
      crudServices,
    );
    Object.assign(this, {
      $: $element[0],
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
      crudServices,
    });

    this.formDataManualEntry = new FormData();
    this.dataImportPopupEntryError = {};
    this.popupItems = null;
    this.fileList = [];
    this.actualFileIndex = 0;
    this.recordId = 0;
    this.countFiles = 0;
    this.actualAccidentIndex = 0;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$scope.$on('showPopupItemsList', this.__showPopupItemsList.bind(this));
  }
  /* */

  _onClickImportButton() {
    this.statusImport = 0;
    this.$.querySelector('#popup-import').toggle();

    const inputManualEntry = this.$.querySelector('#file-manual-entry');

    const labelManualEntry = this.$.querySelector('#label-manual-entry');

    inputManualEntry.onchange = () => {
      const { files } = inputManualEntry;

      if (files && files.length > 0) {
        const file = files[0];
        const { footer } = this.stateConfig.toolbarConfig.importConfig.popup;

        labelManualEntry.children[0].innerHTML = file.name;
        labelManualEntry.children[0].classList.add('has-file');

        if (file.name.indexOf('.xls') > -1 || file.name.indexOf('.xlsx') > -1) {
          this.formDataManualEntry.append('importFile', file);

          this.formDataManualEntry.append(
            'timezone',
            // Intl.DateTimeFormat().resolvedOptions().timeZone,
            'America/Sao_Paulo',
          );

          if (labelManualEntry.nextElementSibling.style.display === 'block') {
            labelManualEntry.nextElementSibling.style.display = 'none';
          }

          footer.buttons.map(item => {
            item.disabled = item.method !== 'save' && item.method !== 'cancel';
            return item;
          });
        } else {
          labelManualEntry.nextElementSibling.style.display = 'block';

          footer.buttons.map(item => {
            item.disabled = item.method == 'save';
            return item;
          });
        }

        this.$scope.$apply();
      } else {
        this.clearPopupManualEntry(inputManualEntry, labelManualEntry, true);
      }
    };
  }

  clearPopupManualEntry(input, label, applyScope) {
    const { popup } = this.stateConfig.toolbarConfig.importConfig;
    label.children[0].innerHTML = 'Nenhum arquivo selecionado';
    label.children[0].classList.remove('has-file');
    input.value = '';
    this.formDataManualEntry.delete('importFile');
    this.formDataManualEntry.delete('timezone');

    popup.footer.buttons.map(item => {
      item.disabled = item.method == 'save';
      return item;
    });

    if (applyScope) this.$scope.$apply();
  }

  evtClickBtnsPopup(method) {
    const labelManualEntry = document.querySelector('#label-manual-entry');
    const inputManualEntry = document.querySelector('#file-manual-entry');

    if (method === 'cancel') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
      this.$.querySelector('#popup-import').toggle();
    } else if (method === 'ok') {
      this.$.querySelector('#popup-import').toggle();
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      this.$scope.$broadcast('getDataset', null);
    } else if (method === 'more') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else if (method === 'reform') {
      this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else {
      const methodUrl = this.stateConfig.toolbarConfig.importConfig.dataPostMethod;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      this.reportServices
        .sendFileManualEntry(methodUrl, this.formDataManualEntry)
        .then(
          () => {
            this.statusImport = 1;

            this.clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
          },
          ({ data }) => {
            if (data && data.hasError) {
              this.statusImport = 2;
              this.dataImportPopupEntryError.fileName = labelManualEntry.children[0].innerHTML;
              this.dataImportPopupEntryError.countError = data.data.length;
              this.dataImportPopupEntryError.data = data.data;
            }
          },
        )
        .finally(() => {
          this.$scope.$broadcast('getDataset', null);
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    }
  }

  _addTcoAccident() {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Sinistro',
        routeLink: 'tcoAccidentForm',
        routeSubName: 'Formulário de Sinistro',
        routeTail: null,
        stateConfig: { ...this.stateConfig, gridName: 'Formulário', filterConfig: [] },
      },
    });

    this.$state.go('tcoAccidentForm');
  }

  __showPopupItemsList(evt, evtParams) {
    const { popupId } = evtParams;
    if (evtParams.type == 'close') {
      this.$scope.$broadcast('getDataset', null);
      this.$.querySelector(`#${popupId}`).toggle();
      this.$timeout(() => {
        this.popupItems = null;
      }, 300);
    } else if (evtParams.type == 'open') {
      this.popupItems = evtParams.dataset;
      if (popupId == 'file-list-popup') {
        const recordId = evtParams.option;
        const file = { accidentId: recordId, ...this.popupItems };
        if (this.fileList.filter(item => item.accidentId == recordId).length == 0) {
          this.fileList.push(file);
        }
        if (this.recordId != recordId) {
          this.actualFileIndex = 0;
          this.recordId = recordId;
        }
        this.actualAccidentIndex = this.fileList.findIndex(
          item => item.accidentId == this.recordId,
        );
        this.countFiles =
          Object.getOwnPropertyNames(this.fileList[this.actualAccidentIndex]).length - 1;
        this._updateFileContent(this.actualAccidentIndex, this.actualFileIndex);
      }
      this.$.querySelector(`#${popupId}`).toggle();
    }
  }

  _amountShowingHeaders() {
    return this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders
      ? this.stateConfig.gridConfig.gridHeaders.filter(ele => ele.show && !ele.isEdit).length
      : 0;
  }

  async _updateFileContent(indexRecord, indexFile) {
    if (this.fileList[indexRecord][indexFile].conteudoBase64 == null) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      const payload = {
        id: this.fileList[indexRecord][indexFile].id,
      };
      this.crudServices
        .getData('Accident/GetFileContent', payload)
        .then(data => {
          this.fileList[indexRecord][indexFile].conteudoBase64 = data.conteudoBase64;
        })
        .finally(() =>
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          ),
        );
    }
  }

  _updateFileIndex(value = 1) {
    this.actualFileIndex += value;
    this._updateFileContent(this.actualAccidentIndex, this.actualFileIndex);
  }

  _closePopUp() {
    const video = document.querySelector('video');
    if (video && video.currentTime > 0) {
      video.pause();
    }
  }

  async _downloadFile(file) {
    const blob = await (await fetch(file.conteudoBase64)).blob();
    const fileUrl = window.URL.createObjectURL(blob, { type: file.tipo });
    const downloadLink = document.createElement('a');

    downloadLink.download = `${file.nome}${file.extensao}`;
    downloadLink.href = fileUrl;
    downloadLink.click();
  }
}

class GolfleetReportTcoAccident {
  constructor() {
    this.template = template;
    this.transclude = {
      addActionSlot: '?addActionSlot',
      footerActionSlot: '?footerActionSlot',
    };
    this.bindings = {
      actualView: '=?',
      hasMapView: '=?',
      hasAddAction: '=?',
      hasFooterAction: '=?',
    };
    this.controller = GolfleetReportTcoAccidentController;
  }
}

angular
  .module('golfleet-report-tco-accident', [
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'golfleet-grid-tco-accident',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-crud',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
    'dndLists',
  ])
  .component('golfleetReportTcoAccident', new GolfleetReportTcoAccident());
