/* eslint-disable linebreak-style */
import angular from 'angular';
import 'angular-easy-masks';

import { toDateTimeInput } from '../../utils/date-util.js';

import template from './power-crud-textbox.html';
import './power-crud-textbox.scss';

class PowerCrudTextboxController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });

    this.value = null;
    this.disabled = false;
    this.minTriggerLength = null;
    this.customIcon = null;
  }

  // #region Lifecycle
  $onInit() {
    if (this.value) {
      if (this.inputType == 'date') {
        this.value = new Date(this.value).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      } else if (this.inputType == 'datetime') {
        this.value = toDateTimeInput({ date: this.value });
      } else {
        this.value = this.value.toString();
      }
    }
    this.disabled = this.triggerBlock ? true : !!this.disabled;
    this.minTriggerLength = this.triggerOf ? this.minTriggerLength || 0 : null;

    Object.assign(this.$, {
      setWarning: this.setWarning.bind(this),
      setDisabled: this.setDisabled.bind(this),
    });

    if (this.triggerOf) this.$scope.$watch(() => this.value, this._onValueChanged.bind(this));
  }
  // #endregion Lifecycle

  // #region Public
  setWarning(text) {
    this.warning = text;
  }

  setDisabled(value) {
    this.disabled = value;
  }

  // #endregion Public

  // #region Private
  _onValueChanged(newValue) {
    if (newValue && newValue.length >= this.minTriggerLength)
      this.$scope.$emit('triggerRequest', {
        triggerKey: this.key,
        triggerOf: this.triggerOf,
        triggerValue: newValue,
        triggerCondition: this.triggerCondition,
      });
  }

  _validateTimeKeyDown(event) {
    event.target.setSelectionRange(this.value.length, this.value.length);
    const tecla = event.which || event.keyCode;
    const regex = /^[\d:]*$/;
    const match = regex.exec(event.key);
    if (tecla === 37 || tecla === 38 || tecla === 39 || tecla === 40 || (!match && tecla !== 8)) {
      event.preventDefault();
    }
  }

  _validateTime() {
    if (this.isValidTime()) {
      this.value = this.value.slice(0, this.value.length - 1);
      if (this.isValidTime()) {
        this.value = '';
      }
    }
  }

  isValidTime() {
    const splits = this.value.split(':');
    const hours = splits[0];
    const minutes = splits[1];
    const seconds = splits[2];
    return (
      splits.length > 3 ||
      !hours ||
      hours < 0 ||
      hours.length > 15 ||
      (minutes && (minutes < 0 || minutes > 59 || minutes.length > 2)) ||
      (seconds && (seconds < 0 || seconds > 59 || seconds.length > 2))
    );
  }
  // #endregion Private
}

class PowerCrudTextbox {
  constructor() {
    this.template = template;
    this.bindings = {
      key: '=',
      value: '=?',
      name: '=',
      hint: '=?',
      warning: '=?',
      required: '=?',
      disabled: '=?',
      inputType: '=?',
      whiteMark: '=?',
      rightLabel: '=?',
      maxLength: '=?',
      decimalLength: '=?',
      inputMaskFormat: '=?',
      minTriggerLength: '=?',
      triggerOf: '=?',
      triggerBlock: '=?',
      customIcon: '=?',
    };
    this.controller = PowerCrudTextboxController;
  }
}

angular
  .module('power-crud-textbox', ['wt.easy'])
  .component('powerCrudTextbox', new PowerCrudTextbox());
