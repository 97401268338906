import angular from 'angular';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';

import template from './golfleet-popup-terms-of-use.html';
import './golfleet-popup-terms-of-use.scss';

class GolfleetPopupTermsOfUseController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });
    this.acceptTermsSelected = false;
  }

  /**
   * Lifecycle
   */
  $onInit() {
    Object.assign(this.$, {
      togglePopup: this.togglePopup.bind(this),
    });
  }

  /* Lifecycle */

  /* Public */
  togglePopup() {
    setTimeout(() => {
      this.acceptTermsSelected = false;
      this.$.querySelector('power-popup').toggle();
    });
  }

  acceptTerms() {
    this.togglePopup();
    this.$.dispatchEvent(new CustomEvent('acceptTerms'));
  }

  /* */

  /* Private */
  _evtClickCheckBoxAcceptTerms() {
    this.acceptTermsSelected = !this.acceptTermsSelected;
  }
  /* */
}

class GolfleetPopupTermsOfUse {
  constructor() {
    this.template = template;
    this.bindings = {
      terms: '=',
    };
    this.controller = GolfleetPopupTermsOfUseController;
  }
}

angular
  .module('golfleet-popup-terms-of-use', ['power-popup'])
  .component('golfleetPopupTermsOfUse', new GolfleetPopupTermsOfUse());
