import { PowerMapLeafletProvider } from '@power/power-components/components/power-map/providers/leaflet/power-map.leaflet-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-map-speed-limit.leaflet-provider.scss';

class GolfleetMapSpeedLimitLeafletProvider extends PowerMapLeafletProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
  }) {
    const objectList = await this._createDatasetLayer({ type, dataset });

    if (this.mapFeatureLayers[layerName]) {
      this.mapLayer.removeLayer(this.mapFeatureLayers[layerName]);
    }

    if (useCluster) {
      this.mapFeatureLayers[layerName] = this._createCluster({ clusterColor }).addLayer(objectList);
    } else {
      this.mapFeatureLayers[layerName] = objectList;
    }

    setTimeout(() => {
      this.mapLayer.addLayer(this.mapFeatureLayers[layerName]);
    }, 500);
  }
}

export { GolfleetMapSpeedLimitLeafletProvider };
