import 'ng-redux';

import { PowerRecordController } from '../power-record/power-record';

import '../../directives/infinite-scroll/infinite-scroll';
import '../../directives/ng-tippy/ng-tippy';

import './power-record-driver.scss';

class PowerRecordDriverController extends PowerRecordController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      'recordServices',
      'urlApi',
      'driverServices',
    ];
  }

  constructor($element, $ngRedux, $state, $scope, $http, recordServices, urlApi, driverServices) {
    super($element, $ngRedux, $state, $scope, $http, recordServices, urlApi);
    Object.assign(this, { driverServices });

    this.popupConfig = [
      {
        title: 'Condutor sem email',
        subTitle: 'Por favor informe o email deste condutor',
        action: 'primaryBtn',
      },
      {
        title: 'Convite enviado',
        subTitle:
          'O convite para a instalação do App Power Driver foi enviado para o seguinte email',
        action: 'close',
      },
      {
        title: 'Remover acesso ao Power Driver?',
        subTitle:
          'O usuário será desconectado do aplicativo mobile e não poderá mais efetuar o login. Tem certeza que deseja remover o acesso?',
        action: 'primaryBtn',
      },
    ];

    this.driverId = null;
    this.driverEmail = '';
    this.driverEmailWarning = '';
    // 0 - Cadastrar email | 1 - Mensagem de confirmação Cadastro | 2 - Remover acesso
    this.popupContent = 0;

    this.selectedPhotos = [];
    this.privacyPolicyStatus = null;
    this.mailIsReadOnly = true;
    this.indexationDescription = 'Remover as imagens marcadas e reindexar este condutor';
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _actionFunction(itemReturn) {
    switch (itemReturn.actionType) {
      case 'removeAndReindex': {
        this.$.querySelector('power-photo-indexation-stand-alone').removeAndReindex();
        this._toggleRemoveImagePopup();
        break;
      }
      default:
        super._actionFunction(itemReturn);
        break;
    }
  }

  _toggleLoader(show) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: show },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _selectTab(index) {
    // Tab 2 - Fotos
    if (index === 2) {
      this.selectedPhotos = [];
    }
    super._selectTab(index);
  }

  _toggleRemoveImagePopup() {
    this.$.querySelector('#popup-delete-image').toggle();
  }
  /* */

  /* region Protected */

  /* */
}

export { PowerRecordDriverController };
