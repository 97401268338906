import angular from 'angular';

import template from './power-pagination.html';
import './power-pagination.scss';

class PowerPaginationController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });
  }

  $onInit() {
    this.type = this.type || 'page';
  }

  changePage(toPage) {
    if (toPage > 0 && toPage <= this.lastPage) {
      this.changePageFunction({ toPage });
    }
  }

  changeRow(toRow) {
    const downThreshold = this.pageSize * (this.actualPage - 1);
    const upperThreshold = this.pageSize * this.actualPage;

    if (toRow > this.actualRow && toRow >= upperThreshold) {
      this.changePageFunction({ page: this.actualPage + 1, row: toRow });
    } else if (toRow < this.actualRow && toRow < downThreshold) {
      this.changePageFunction({ page: this.actualPage - 1, row: toRow });
    } else {
      this.changePageFunction({ page: this.actualPage, row: toRow });
    }
  }

  _rowOnPage() {
    if (this.actualRow >= this.pageSize) {
      return this.actualRow - this.pageSize * (this.actualPage - 1);
    }

    return this.actualRow;
  }
}

class PowerPagination {
  constructor() {
    this.template = template;
    this.bindings = {
      type: '=?',
      pageSize: '=?',
      actualRow: '=?',
      lastRow: '=?',
      actualPage: '=',
      lastPage: '=',
      changePageFunction: '&',
    };
    this.controller = PowerPaginationController;
  }
}

angular.module('power-pagination', []).component('powerPagination', new PowerPagination());
