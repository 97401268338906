import angular from 'angular';

import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';

import template from './golfleet-grid-tco-accident.html';
import './golfleet-grid-tco-accident.scss';

class GolfleetGridTcoAccidentController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      'crudServices',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    crudServices,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      crudServices,
    });

    // Object.assign(this, { $ngRedux });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.customActionsController = {
      openItemsList: data => {
        // recover items list
        const payload = {
          id: data.id,
        };
        this.crudServices.getData('Accident/GetAllItems', payload).then(result => {
          this.$scope.$emit('showPopupItemsList', {
            type: 'open',
            popupId: 'popup-items-list',
            dataset: result,
            option: null,
          });
        });
      },

      openFilesList: data => {
        // recover images list
        const payload = {
          id: data.id,
        };
        this.crudServices.getData('Accident/GetAccidentFiles', payload).then(result => {
          this.$scope.$emit('showPopupItemsList', {
            type: 'open',
            popupId: 'file-list-popup',
            dataset: result,
            option: data.id,
          });
        });
      },
    };
  }

  /* Lifecycle */
  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /**/

  _selectRow(column, row) {
    return (
      (super._selectRow(column, row) || !!column.action) &&
      (!Object.prototype.hasOwnProperty.call(row, column.field) || !!row[column.field])
    );
  }

  _customAction(options) {
    const defaults = { action: '', data: {} };
    const result = Object.assign(defaults, options);

    const customAction = this.customActionsController[result.action];
    if (customAction) {
      customAction(result.data);
    }
  }
}

class GolfleetGridTcoAccident {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridTcoAccidentController;
  }
}

angular
  .module('golfleet-grid-tco-accident', ['ng-resize'])
  .component('golfleetGridTcoAccident', new GolfleetGridTcoAccident());
