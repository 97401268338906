/* eslint-disable no-restricted-syntax */
import angular from 'angular';
import moment from 'moment/src/moment';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsDumbell from 'highcharts/modules/dumbbell.src';
import HighchartsLollipop from 'highcharts/modules/lollipop.src';
import * as Comlink from 'comlink';
import 'moment/src/locale/pt-br';

import template from './golfleet-main-dashboard.html';
import './golfleet-main-dashboard.scss';

class GolfleetMainDashboardController {
  static get $inject() {
    return ['$element', '$rootScope', '$state', '$scope', '$http', '$ngRedux', 'urlApi'];
  }

  constructor($element, $rootScope, $state, $scope, $http, $ngRedux, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $rootScope,
      $state,
      $scope,
      $http,
      $ngRedux,
      urlApi,
    });

    this._appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        modules: behavior.session.modules,
      }),
    )(this);

    /* Attribute */
    this.await = true;
    // chart || details
    this.rankingView = 'details';
    // chart || list || detail
    this.mobileRankingView = 'list';
    this.fetchingChartContent = true;
    this.fetchingRankingContent = true;
    this.fetchingRankingDetails = true;
    /* */

    /* Property */
    this.endDate = moment().endOf('month')._d;
    this.startDate = moment(this.endDate)
      .month(moment().month() - 6)
      .startOf('month')._d;
    this.gaugeFilter = [];
    this.entityFilter = [];
    this.metricsFilter = [];
    this.operatorFilter = [];
    this.rankingFilter = {};
    /* */

    /* */
    this.navigationDate = {};
    this.navigationHierarchy = {};

    this.dimensionFilter = [];
    this.dataScopeFilter = [];
    this.ordenationFilter = [];

    this.chartCondition = {};
    this.rankingCondition = {};
    this.selectedChartOptions = {};
    this.selectedRankingOptions = {};
    this.temporaryChartSelection = {};
    this.temporaryRankingSelection = {};

    this.chartSeries = [];
    this.chartSeriesType = 'string';
    this.chartSeriesSufix = '';
    this.chartSeriesPrefix = '';

    this.ranking = [];
    this.chartType = '';
    this.rankingColor = '';
    this.activeRanking = 0;
    this.chartCategories = [];
    this.rankingDetailData = { values: [], median: {}, chartSeries: {}, chartCategories: [] };
    /* */

    const _this = this;

    this.chartConfig = {
      title: { text: '' },
      tooltip: {
        style: {
          color: '#fff',
          fontSize: 13,
        },
        split: false,
        shared: true,
        enabled: true,
        outside: true,
        useHTML: true,
        padding: 0,
        className: 'dashboard-chart-tooltip',
        borderWidth: 0,
        borderColor: '#333',
        borderRadius: 8,
        backgroundColor: '#333',
        formatter() {
          const {
            endDate: _endDate,
            startDate: _startDate,
            granularity: _granularity,
          } = _this.navigationDate;
          const serieIndex = _this.chartCategories.findIndex(item => item.description === this.x);

          let endDate = _this._toDate(_endDate).format('MMM [de] YYYY');
          let startDate = _this._toDate(_startDate).format('MMM [de] YYYY');
          let dateDescription = '';

          if (_this.metricsType === 'data') {
            switch (_granularity) {
              case 'mes':
                startDate = _this
                  ._toDate(_startDate)
                  .add(serieIndex, 'month')
                  .format('MMMM [de] YYYY');
                dateDescription = `${startDate}`;
                break;
              case 'dia':
                startDate = _this._toDate(_startDate).add(serieIndex, 'day').format('DD [de] MMMM');
                dateDescription = `${startDate}`;
                break;
              case 'hora':
                endDate = _this
                  ._toDate(_startDate)
                  .add(serieIndex + 1, 'hour')
                  .format('HH:mm DD [de] MMMM');
                startDate = _this
                  ._toDate(_startDate)
                  .add(serieIndex, 'hour')
                  .format('HH:mm DD [de] MMMM');
                dateDescription = `${startDate} até ${endDate}`;
                break;
              default:
                endDate = _this._toDate(_endDate).format('DD-MM-YYYY');
                startDate = _this._toDate(_startDate).format('DD-MM-YYYY');
                dateDescription = `${startDate} até ${endDate}`;
                break;
            }
          } else {
            switch (_granularity) {
              case 'mes':
                endDate = _this._toDate(_endDate).format('MMM [de] YYYY');
                startDate = _this._toDate(_startDate).format('MMM [de] YYYY');
                dateDescription = `${startDate} até ${endDate}`;
                break;
              case 'dia':
                startDate = _this._toDate(_startDate).format('MMMM [de] YYYY');
                dateDescription = `${startDate}`;
                break;
              case 'hora':
                startDate = _this._toDate(_startDate).format('DD [de] MMMM');
                dateDescription = `${startDate}`;
                break;
              default:
                endDate = _this._toDate(_endDate).format('DD-MM-YYYY');
                startDate = _this._toDate(_startDate).format('DD-MM-YYYY');
                dateDescription = `${startDate} até ${endDate}`;
                break;
            }
          }

          return `
            <section class="chart-tooltip">
              <section class="chart-tooltip-header">
                <div class="chart-tooltip-header-item">
                  <i class="material-icons">gs_date</i>
                  <span>${dateDescription}</span>
                </div>
                </b>
                ${Object.keys(_this.navigationHierarchy).reduce((acc, hierarchyKey) => {
                  const {
                    icon = 'gs_units',
                    id,
                    description,
                  } = _this.navigationHierarchy[hierarchyKey];

                  if (!id || id === 0 || id === '0') {
                    return acc;
                  }

                  return `
                      ${acc}
                      <div class="chart-tooltip-header-item">
                        <i class="material-icons">${icon}</i> <span>${description}</span>
                      </div>
                      </b>
                    `;
                }, '')}
              </section>
              <table class="chart-tooltip-content">
                ${
                  _this.chartType === 'boxplot' && 'point' in this
                    ? `
                  <tbody>
                    <tr class="chart-tooltip-content-item">
                      <td class="chart-tooltip-content-item-serie">
                        <span>
                          <div class="chart-tooltip-serie-legend" style="background-color: ${
                            this.point.series.userOptions.color
                          }"></div>
                          ${this.point.series.userOptions.description}
                        </span>
                      </td>
                    </tr>
                    <tr class="chart-tooltip-content-item">
                      <td class="chart-tooltip-content-item-serie">
                        <span>Máximo</span>
                      </td>
                      <td class="chart-tooltip-content-item-value">
                        ${_this.chartSeriesPrefix} <b>${_this._formatSeriesValue(
                        this.point.high,
                      )}</b> ${_this.chartSeriesSufix}
                      </td>
                    </tr>
                    <tr class="chart-tooltip-content-item">
                      <td class="chart-tooltip-content-item-serie">
                        <span>3º Quartil</span>
                      </td>
                      <td class="chart-tooltip-content-item-value">
                        ${_this.chartSeriesPrefix} <b>${_this._formatSeriesValue(
                        this.point.q3,
                      )}</b> ${_this.chartSeriesSufix}
                      </td>
                    </tr>
                    <tr class="chart-tooltip-content-item">
                      <td class="chart-tooltip-content-item-serie">
                        <span>Mediana</span>
                      </td>
                      <td class="chart-tooltip-content-item-value">
                        ${_this.chartSeriesPrefix} <b>${_this._formatSeriesValue(
                        this.point.median,
                      )}</b> ${_this.chartSeriesSufix}
                      </td>
                    </tr>
                    <tr class="chart-tooltip-content-item">
                      <td class="chart-tooltip-content-item-serie">
                        <span>1º Quartil</span>
                      </td>
                      <td class="chart-tooltip-content-item-value">
                        ${_this.chartSeriesPrefix} <b>${_this._formatSeriesValue(
                        this.point.q1,
                      )}</b> ${_this.chartSeriesSufix}
                      </td>
                    </tr>
                    <tr class="chart-tooltip-content-item">
                      <td class="chart-tooltip-content-item-serie">
                        <span>Mínimo</span>
                      </td>
                      <td class="chart-tooltip-content-item-value">
                        ${_this.chartSeriesPrefix} <b>${_this._formatSeriesValue(
                        this.point.low,
                      )}</b> ${_this.chartSeriesSufix}
                      </td>
                    </tr>
                  </tbody>
                `
                    : `
                  <tbody>
                    ${this.points.reduce(
                      (acc, point, index) => `
                        ${acc}
                        <tr class="chart-tooltip-content-item">
                          <td class="chart-tooltip-content-item-serie">
                            <span>${point.series.userOptions.description}</span>
                          </td>
                          <td class="chart-tooltip-content-item-serie-legend">
                            <div class="chart-tooltip-serie-legend" style="background-color: ${
                              _this.chartSeries[index].color
                            }"></div>
                          </td>
                          <td class="chart-tooltip-content-item-value">
                            ${_this.chartSeriesPrefix} <b>${_this._formatSeriesValue(
                        point.y,
                      )}</b> ${_this.chartSeriesSufix}
                          </td>
                        </tr>
                      `,
                      '',
                    )}
                  </tbody>
                `
                }
              </table>
            </section>
          `;
        },
      },
      chart: {
        type: '',
        spacing: [0, 0, 0, 0],
        marginBottom: 48,
        styledMode: false,
      },
      xAxis: {
        labels: {
          style: {
            color: '#6E6E6E',
            fontSize: '14px',
            fontWeight: 500,
          },
          useHTML: true,
          autoRotation: false,
          formatter() {
            if (this.pos in _this.chartCategories) {
              const { hasDrillDown = false } = _this.chartCategories[this.pos];
              const addDrillDown =
                _this.$.querySelector('#chart-container').querySelector(
                  `#${this.chart.container.id}`,
                ) && hasDrillDown;

              return `<span ${addDrillDown ? 'class="hasDrillDown"' : ''}>${this.value}</span>`;
            }

            return `<span>${this.value}</span>`;
          },
        },
      },
      yAxis: {
        min: 0,
        title: { text: '' },
        labels: { enabled: false },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver() {
                const labelGroup = this.series.xAxis.labelGroup.element.querySelectorAll('text');
                const plotBandGroup =
                  this.series.chart.renderTo.querySelectorAll('.highcharts-plot-band');

                if (labelGroup.length > 0) {
                  labelGroup[this.x].setAttribute('hover', '');
                }
                if (plotBandGroup.length > 0) {
                  plotBandGroup[this.x].setAttribute('hover', '');
                }
              },
              mouseOut() {
                const hoverElements = this.series.chart.renderTo.querySelectorAll('[hover]');

                for (const element of hoverElements) {
                  element.removeAttribute('hover');
                }
              },
            },
          },
          states: {},
        },
        line: {
          marker: {
            symbol: 'circle',
            radius: 6,
          },
          lineWidth: 3,
        },
        boxplot: {
          fillColor: '#F7F7F7',
          lineWidth: 3,
          stemWidth: 3,
          medianWidth: 3,
          whiskerWidth: 3,
          whiskerLength: '100%',
          maxPointWidth: 16,
        },
        lollipop: {
          marker: {
            symbol: 'circle',
            radius: 6,
          },
          connectorWidth: 3,
        },
        column: {
          stacking: 'normal',
        },
      },
    };

    moment.locale('pt-BR');

    HighchartsMore(Highcharts);
    HighchartsDumbell(Highcharts);
    HighchartsLollipop(Highcharts);

    Highcharts.setOptions({
      lang: {
        months: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
        loading: ['Atualizando o gráfico...'],
        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        resetZoom: 'Limpar Zoom',
        exportData: {
          categoryHeader: 'Categoria',
          categoryDatetimeHeader: 'Data',
        },
        printChart: 'Imprimir gráfico',
        shortMonths: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez',
        ],
        downloadSVG: 'Baixar vetor SVG',
        downloadPNG: 'Baixar imagem PNG',
        downloadPDF: 'Baixar arquivo PDF',
        downloadJPEG: 'Baixar imagem JPEG',
        decimalPoint: ',',
        thousandsSep: '.',
        shortWeekdays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        viewFullscreen: 'Ver em tela cheia',
        exitFullscreen: 'Sair da tela cheia',
        resetZoomTitle: 'Voltar Zoom para nível 1:1',
        rangeSelectorTo: 'Para',
        rangeSelectorFrom: 'De',
        rangeSelectorZoom: 'Zoom',
        contextButtonTitle: 'Exportar gráfico',
      },
    });
    Highcharts.AST.allowedTags.push('section');

    this.queryTablet = window.matchMedia('(max-width: 768px)');
    this.queryMobile = window.matchMedia('(max-width: 425px)');

    this.worker = new Worker('./golfleet-main-dashboard.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      setup: this.setup.bind(this),
      updateNavigationDate: this.updateNavigationDate.bind(this),
      updateNavigationHierarchy: this.updateNavigationHierarchy.bind(this),
    });

    this.queryMobile.addListener(this.__onMobileMediaQuery.bind(this));
    this.queryTablet.addListener(this.__onTabletMediaQuery.bind(this));
  }

  $onDestroy() {
    this.worker.terminate();
  }
  /* */

  /* Private */
  async _syncState() {
    this.gaugeFilter = await this.workerService.gaugeFilter;
    this.entityFilter = await this.workerService.entityFilter;
    this.metricsFilter = await this.workerService.metricsFilter;
    this.operatorFilter = await this.workerService.operatorFilter;

    this.dimensionFilter = await this.workerService.dimensionFilter;
    this.dataScopeFilter = await this.workerService.dataScopeFilter;
    this.ordenationFilter = await this.workerService.ordenationFilter;

    this.metricsType = await this.workerService.metricsType;
    this.navigationDate = await this.workerService.navigationDate;
    this.navigationHierarchy = await this.workerService.navigationHierarchy;

    this.chartSeries = await this.workerService.chartSeries;
    this.chartSeriesType = await this.workerService.chartSeriesType;
    this.chartSeriesSufix = await this.workerService.chartSeriesSufix;
    this.chartSeriesPrefix = await this.workerService.chartSeriesPrefix;

    this.chartType = await this.workerService.chartType;
    this.rankingColor = await this.workerService.rankingColor;
    this.chartCondition = await this.workerService.chartCondition;
    this.rankingCondition = await this.workerService.rankingCondition;
    this.rankingSummarized = await this.workerService.rankingSummarized;

    this.selectedChartOptions = await this.workerService.selectedChartOptions;
    this.selectedRankingOptions = await this.workerService.selectedRankingOptions;
    this.temporaryChartSelection = { ...this.chartCondition };
    this.temporaryRankingSelection = { ...this.rankingCondition };

    this.$.dispatchEvent(new CustomEvent('syncState'));
  }

  async _requestChartDataset() {
    this.fetchingChartContent = true;

    this._requestUpdate();

    const { gauge, entity, metrics, operator } = this.chartCondition;
    const hierarchy = [];

    Object.keys(this.navigationHierarchy).forEach(hierarchyKey => {
      hierarchy.push({ ...this.navigationHierarchy[hierarchyKey], field: hierarchyKey });
    });

    this.$http({
      url: `${this.urlApi}/${this.apiMethodChart}`,
      method: 'POST',
      data: {
        request: {
          filter: {
            conditions: [
              ...this.filterConditions,
              { field: 'gauge', value: [gauge] },
              { field: 'entity', value: [entity] },
              { field: 'metrics', value: [metrics] },
              { field: 'operator', value: [operator] },
            ],
          },
          navigation: {
            date: this.navigationDate,
            hierarchy,
          },
        },
      },
    })
      .then(
        success => {
          const { data } = success.data;
          const { series, categories } = data;

          this.chartCategories = categories;
          this.chartSeriesValues = series;
        },
        () => {
          this.chartCategories = [];
          this.chartSeriesValues = [];
        },
      )
      .finally(() => {
        this.fetchingChartContent = false;

        this._updateChart();

        this._requestUpdate();

        this.$.dispatchEvent(new CustomEvent('requestChartDataset'));
      });
  }

  async _requestRankingDataset() {
    this.fetchingRankingContent = true;
    this.fetchingRankingDetails = true;

    this._requestUpdate();

    const { gauge, entity, metrics, operator } = this.chartCondition;
    const { dimension, dataScope, ordenation } = this.rankingCondition;
    const hierarchy = [];

    Object.keys(this.navigationHierarchy).forEach(hierarchyKey => {
      hierarchy.push({ ...this.navigationHierarchy[hierarchyKey], field: hierarchyKey });
    });

    this.$http({
      url: `${this.urlApi}/${this.apiMethodRanking}`,
      method: 'POST',
      data: {
        request: {
          filter: {
            conditions: [
              ...this.filterConditions,
              { field: 'gauge', value: [gauge] },
              { field: 'entity', value: [entity] },
              { field: 'metrics', value: [metrics] },
              { field: 'operator', value: [operator] },
              { field: 'dimension', value: [dimension] },
              { field: 'dataScope', value: [dataScope] },
              { field: 'ordenation', value: [ordenation] },
            ],
          },
          navigation: {
            date: this.navigationDate,
            hierarchy,
          },
        },
      },
    })
      .then(
        success => {
          const { items: ranking } = success.data.data;

          this.ranking = ranking;
        },
        () => {
          this.ranking = [];
        },
      )
      .finally(() => {
        this.fetchingRankingContent = false;

        if (this.queryTablet.matches === false) {
          if (this.activeRanking === 0) {
            const [item] = this.ranking;
            this._requestRankingDetailDataset(item ? item.id : 0);
          }

          this._setActiveRanking(0);
        } else if (this.mobileRankingView === 'detail') {
          this._setMobileRankingView('list');
        }

        this._requestUpdate();

        this.$.dispatchEvent(new CustomEvent('requestRankingDataset'));
      });
  }

  async _requestRankingDetailDataset(entityId) {
    this.fetchingRankingDetails = true;

    this._requestUpdate();

    const { gauge, entity, metrics, operator } = this.chartCondition;
    const { dimension, dataScope, ordenation } = this.rankingCondition;
    const hierarchy = [];

    Object.keys(this.navigationHierarchy).forEach(hierarchyKey => {
      hierarchy.push({ ...this.navigationHierarchy[hierarchyKey], field: hierarchyKey });
    });

    this.$http({
      url: `${this.urlApi}/${this.apiMethodRankingDetail}`,
      method: 'POST',
      data: {
        request: {
          filter: {
            conditions: [
              ...this.filterConditions,
              { field: 'gauge', value: [gauge] },
              { field: 'entity', value: [entity] },
              { field: 'entityId', value: [entityId] },
              { field: 'metrics', value: [metrics] },
              { field: 'operator', value: [operator] },
              { field: 'dimension', value: [dimension] },
              { field: 'dataScope', value: [dataScope] },
              { field: 'ordenation', value: [ordenation] },
            ],
          },
          navigation: {
            date: this.navigationDate,
            hierarchy,
          },
        },
      },
    })
      .then(
        success => {
          const { data } = success.data;

          this.rankingDetailData = data;
          this.rankingDetailData.values = data.values.filter(detailItem =>
            this.chartSeries.some(visibleDetailItem => visibleDetailItem.value === detailItem.key),
          );
        },
        () => {
          this.ranking = [];
        },
      )
      .finally(() => {
        this.fetchingRankingDetails = false;

        this._updateRankingChart();
        this._requestUpdate();

        this.$.dispatchEvent(new CustomEvent('requestRankingDetailDataset'));
      });
  }

  async _toggleChartSerie(index = 0) {
    const hasChange = await this.workerService.toggleChartSerie({ index });

    if (hasChange) {
      await this._syncState();

      this._updateChart();
      this._updateRankingChart();

      this._requestUpdate();

      this.$.dispatchEvent(new CustomEvent('toggleChartSerie'));
    }
  }

  async _applyChartFilter() {
    await this.workerService.setChartFilterOptions({
      gauge: this.temporaryChartSelection.gauge,
      entity: this.temporaryChartSelection.entity,
      metrics: this.temporaryChartSelection.metrics,
      operator: this.temporaryChartSelection.operator,
    });

    await this._syncState();

    this.$.querySelector('#chart-filter-selection').toggle();

    this._requestChartDataset();
    this._requestRankingDataset();

    this.$.dispatchEvent(new CustomEvent('applyChartFilter'));
  }

  async _applyRankingFilter() {
    await this.workerService.setRankingFilterOptions({
      dimension: this.temporaryRankingSelection.dimension,
      dataScope: this.temporaryRankingSelection.dataScope,
      ordenation: this.temporaryRankingSelection.ordenation,
    });

    await this._syncState();

    this.$.querySelector('#ranking-filter-selection').toggle();

    this._requestRankingDataset();

    this.$.dispatchEvent(new CustomEvent('applyRankingFilter'));
  }

  async _setDateDrillDown(xAxisIndex = 0) {
    const { startDate, granularity } = this.navigationDate;
    let date = new Date();

    switch (granularity) {
      case 'mes':
        date = this._toDate(startDate).add(xAxisIndex, 'months');
        break;
      case 'dia':
        date = this._toDate(startDate).add(xAxisIndex, 'days');
        break;
      case 'hora':
        date = this._toDate(startDate).add(xAxisIndex, 'hours');
        break;
      default:
        break;
    }

    this.$.dispatchEvent(new CustomEvent('setDateDrillDown', { detail: { date: date._d } }));
  }

  async _setHierarchyDrillDown(xAxisIndex = 0) {
    const hierarchy = this.chartCategories[xAxisIndex];

    this.$.dispatchEvent(
      new CustomEvent('setHierarchyDrillDown', {
        detail: { field: this.chartCondition.metrics, hierarchy },
      }),
    );
  }

  _requestUpdate() {
    if (!this.$scope.$$phase && !this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }

  _updateChart() {
    const _this = this;
    const element = this.$.querySelector('#chart-container');
    const config = {
      ...this.chartConfig,
      chart: {
        ...this.chartConfig.chart,
        events: {
          load() {
            const [xAxis] = this.xAxis;
            const [series] = this.series;

            for (const point of series.points) {
              xAxis.ticks[point.index].label.element.onclick = () => {
                if (!_this.chartCategories[point.index].hasDrillDown) {
                  return;
                }

                if (_this.metricsType === 'data') {
                  _this._setDateDrillDown(point.index);
                } else if (_this.metricsType === 'hierarquia') {
                  _this._setHierarchyDrillDown(point.index);
                }
              };
            }
          },
        },
        scrollablePlotArea: {
          minWidth: (() => {
            if (this.queryMobile.matches) {
              return (element.offsetWidth / 4.5) * (this.chartCategories.length - 1);
            }
            if (this.queryTablet.matches) {
              return (element.offsetWidth / 6.5) * (this.chartCategories.length - 1);
            }
            if (this.chartCategories.length > 15) {
              return (element.offsetWidth / 9.5) * (this.chartCategories.length - 1);
            }
            if (this.chartCategories.length > 10) {
              return (element.offsetWidth / 7.5) * (this.chartCategories.length - 1);
            }
            return undefined;
          })(),
          scrollPositionX: 0,
        },
      },
      xAxis: {
        ...this.chartConfig.xAxis,
        plotBands: this.chartCategories.map((_, index) => ({
          from: index - 0.5,
          to: index + 0.5,
        })),
        categories: this.chartCategories.map(categories => categories.description),
      },
      tooltip: {
        ...this.chartConfig.tooltip,
        shared: this.chartType !== 'boxplot',
      },
    };

    config.series = this.chartSeries
      .filter(chartSerie => chartSerie.show)
      .map(chartSerie => ({
        ...chartSerie,
        data: this.chartSeriesValues[chartSerie.value],
      }));

    if (this.chartCategories.length === 1 && this.chartType === 'line') {
      config.chart.type = 'lollipop';
    } else if (this.chartType === 'boxplot') {
      let flat = true;

      for (const serie of this.chartSeries) {
        flat = this.chartSeriesValues[serie.value]?.every(cluster =>
          cluster.every(value => value === cluster[0]),
        );

        if (!flat) {
          break;
        }
      }

      if (!flat) {
        config.chart.type = 'boxplot';
      } else {
        config.chart.type = 'lollipop';
        config.tooltip.shared = true;

        config.series = this.chartSeries
          .filter(chartSerie => chartSerie.show)
          .map(chartSerie => ({
            ...chartSerie,
            data: this.chartSeriesValues[chartSerie.value].map(serieValue => serieValue[0]),
          }));
      }
    } else {
      config.chart.type = this.chartType;
    }

    Highcharts.chart(element, config);

    this.$.dispatchEvent(new CustomEvent('updateChart'));
  }

  _updateRankingChart() {
    if (Object.keys(this.rankingDetailData.chartSeries).length === 0) {
      return;
    }

    const element = this.$.querySelector('#ranking-detail-chart-container');
    const config = {
      ...this.chartConfig,
      chart: {
        ...this.chartCondition.chart,
        scrollablePlotArea: {
          minWidth: (() => {
            if (this.queryMobile.matches) {
              return (element.offsetWidth / 4.5) * (this.chartCategories.length - 1);
            }
            if (this.queryTablet.matches) {
              return (element.offsetWidth / 6.5) * (this.chartCategories.length - 1);
            }
            if (this.chartCategories.length > 6) {
              return (element.offsetWidth / 6.5) * (this.chartCategories.length - 1);
            }
            return undefined;
          })(),
          scrollPositionX: 0,
        },
      },
      xAxis: {
        ...this.chartConfig.xAxis,
        plotBands: this.rankingDetailData.chartCategories.map((_, index) => ({
          from: index - 0.5,
          to: index + 0.5,
        })),
        categories: this.rankingDetailData.chartCategories.map(
          categories => categories.description,
        ),
      },
      tooltip: {
        ...this.chartConfig.tooltip,
        shared: this.chartType !== 'boxplot',
      },
    };

    config.series = this.chartSeries
      .filter(chartSerie => chartSerie.show)
      .map(chartSerie => ({
        ...chartSerie,
        data: this.rankingDetailData.chartSeries[chartSerie.value],
      }));

    if (this.chartCategories.length === 1 && this.chartType === 'line') {
      config.chart.type = 'lollipop';
    } else if (this.chartType === 'boxplot') {
      config.chart.type = 'lollipop';
      config.tooltip.shared = true;

      config.series = this.chartSeries
        .filter(chartSerie => chartSerie.show)
        .map(chartSerie => ({
          ...chartSerie,
          data: this.rankingDetailData.chartSeries[chartSerie.value]?.map(
            serieValue => serieValue[0],
          ),
        }));
    } else {
      config.chart.type = this.chartType;
    }

    Highcharts.chart(element, config);

    this.$.dispatchEvent(new CustomEvent('updateRankingChart'));
  }

  _setRankingView(view = '') {
    if (view === '') {
      return;
    }

    this.rankingView = view;

    this.$.dispatchEvent(new CustomEvent('setRankingView'));
  }

  _setMobileRankingView(view = '') {
    if (view === '') {
      return;
    }

    this.mobileRankingView = view;

    this.$.dispatchEvent(new CustomEvent('setRankingView'));
  }

  _closeChartFilter() {
    this.$.querySelector('#chart-filter-selection').toggle();
  }

  _closeRankingFilter() {
    this.$.querySelector('#ranking-filter-selection').toggle();
  }

  _setActiveRanking(index = 0) {
    if (this.ranking.length > 0 && index !== this.activeRanking) {
      this._requestRankingDetailDataset(this.ranking[index].id);
    }

    if (this.queryTablet.matches) {
      this.mobileRankingView = 'detail';

      if (index === this.activeRanking) {
        this._requestRankingDetailDataset(this.ranking[index].id);
      }
    } else {
      this.mobileRankingView = 'list';
    }

    this.activeRanking = index;

    this._requestUpdate();

    this.$.dispatchEvent(new CustomEvent('setActiveRanking'));
  }

  _setChartFilterOption(params = {}) {
    const { filterName = '', value = '' } = params;

    if (filterName !== '' && value !== '') {
      this.temporaryChartSelection[filterName] = value;

      this._requestUpdate();

      this.$.dispatchEvent(new CustomEvent('setChartFilterOption'));
    }
  }

  _setRankingFilterOption(params = {}) {
    const { filterName = '', value = '' } = params;

    if (filterName !== '' && value !== '') {
      this.temporaryRankingSelection[filterName] = value;

      this._requestUpdate();

      this.$.dispatchEvent(new CustomEvent('setRankingFilterOption'));
    }
  }

  _resetChartFilterSelection() {
    this.temporaryChartSelection = { ...this.chartCondition };

    this.$.querySelectorAll(`#chart-filter-selection power-accordion[open]`).forEach(
      nodeItem => {
        nodeItem.toggle();
      },
    );

    this._requestUpdate();

    this.$.dispatchEvent(new CustomEvent('resetChartFilterSelection'));
  }

  _resetRankingFilterSelection() {
    this.temporaryRankingSelection = { ...this.rankingCondition };

    this.$.querySelectorAll(`#ranking-filter-selection power-accordion[open]`).forEach(
      nodeItem => {
        nodeItem.toggle();
      },
    );

    this._requestUpdate();

    this.$.dispatchEvent(new CustomEvent('resetRankingFilterSelection'));
  }

  _getSerieColor(key = '') {
    if (key === '') {
      return '#555';
    }

    const [serie = {}] = this.chartSeries.filter(serieItem => serieItem.value === key);
    const { color = '#555' } = serie;

    return color;
  }

  _formatSeriesValue(value = '') {
    switch (this.chartSeriesType) {
      case 'time':
        return this._formatTime(value, false);
      case 'timeWithMiliseconds':
        return this._formatTime(value, true);
      case 'float':
        return Number(value).formatNumber(2, ',', '.');
      case 'int':
        return Number(value);
      case 'timeString':
        return new Date(value).toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      case 'dateString':
        return new Date(value).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      case 'dateTimeString':
        return new Date(value).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
      default:
        return value;
    }
  }

  _formatTime(value, showMiliseconds) {
    const formatDays = days => (days > 0 ? `${days}d` : '');
    const formatTime = time => (time > 9 ? time : `0${time}`);

    const milisecondsToDays = 24 * 60 * 60;
    const milisecondsToHours = 60 * 60;
    const milisecondsToMinutes = 60;

    let amount = value;

    const days = Math.floor(value / milisecondsToDays);
    amount -= days * milisecondsToDays;

    const hours = Math.floor(amount / milisecondsToHours);
    amount -= hours * milisecondsToHours;

    const minutes = Math.floor(amount / milisecondsToMinutes);
    amount -= minutes * milisecondsToMinutes;

    const seconds = Math.floor(amount);
    amount -= seconds;

    if (showMiliseconds) {
      if (amount) amount = Number(amount.toFixed(3));
      return `${formatDays(days)} ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(
        seconds,
      )}:${String(Math.ceil(amount * 1000)).padStart(3, '0')}`;
    }

    return `${formatDays(days)} ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60);
  }

  _getEmptyRankingRows() {
    return Array(10 - this.ranking.length);
  }

  _linkClick(dataset, linkConfiguration) {
    const { download: isDownload } =
      linkConfiguration.operators[this.temporaryChartSelection.operator];

    if (isDownload) {
      const { gauge, entity, metrics, operator } = this.chartCondition;
      const { dimension, dataScope, ordenation } = this.rankingCondition;
      const hierarchy = [];

      Object.keys(this.navigationHierarchy).forEach(hierarchyKey => {
        hierarchy.push({ ...this.navigationHierarchy[hierarchyKey], field: hierarchyKey });
      });

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      this.$http({
        url: `${this.urlApi}/${this.apiMethodExportRanking}`,
        method: 'POST',
        data: {
          request: {
            filter: {
              conditions: [
                ...this.filterConditions,
                { field: 'gauge', value: [gauge] },
                { field: 'entity', value: [entity] },
                { field: 'metrics', value: [metrics] },
                { field: 'operator', value: [operator] },
                { field: 'dimension', value: [dimension] },
                { field: 'dataScope', value: [dataScope] },
                { field: 'ordenation', value: [ordenation] },
              ],
            },
            navigation: {
              date: this.navigationDate,
              hierarchy,
            },
          },
        },
      })
        .then(async success => {
          const blob = await (
            await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
          ).blob();
          const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
          const downloadLink = document.createElement('a');

          downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
          downloadLink.href = fileUrl;
          downloadLink.click();
        })
        .finally(() =>
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          ),
        );
    } else {
      const config = Object.clone(
        {},
        linkConfiguration.entities[this.selectedChartOptions.entity.value],
      );
      const { gauge, operator } = this.chartCondition;

      if (
        this.drilldownGridMapping &&
        this.drilldownGridMapping[gauge] &&
        this.drilldownGridMapping[gauge][operator]
      ) {
        const { dimension, dataScope, ordenation } = this.rankingCondition;
        const { sort } =
          this.drilldownGridMapping[gauge][operator][dimension][ordenation][dataScope];
        config.linkParameters.sort = sort;

        const { additionalLinkFilters } = this.drilldownGridMapping[gauge];
        if (additionalLinkFilters) {
          const filters = additionalLinkFilters.map(filter => {
            const filterId = filter.entityFilterId.find(
              item => item.linkRoute === config.linkRoute,
            )?.id;
            Object.assign(filter, { id: filterId });
            return filter;
          });

          config.linkFilters.push(...filters);
        }
      }

      this._goToLink({ ...dataset }, config);
    }
  }

  _goToLink(dataset, config) {
    const payload = {
      routeName: config.linkName,
      routeLink: config.linkRoute,
      stateFixedParams: config.linkParameters,
      fixedFilters: config.linkFilters,
      linkStateConfig: config.linkStateConfig,
      getDataMethod: config.linkGetDataMethod,
      backPagination: config.backPagination,
      dataset,
    };

    this.$scope.$emit('goToLink', payload);
  }

  _toggleAccordion(elementId) {
    if (this.queryTablet.matches) {
      this.$.querySelector(`#${elementId}`).toggle();
    }
  }

  _boxplotInfoTogglePopup() {
    this.$.querySelector('#golfleet-boxplot-info-popup').toggle();
  }
  /* */

  /* Public */
  async setup(params = {}) {
    const { navigationDate = {}, navigationHierarchy = {} } = params;

    await this.workerService.setup({
      navigationDate,
      navigationHierarchy,
      endDate: this.endDate,
      startDate: this.startDate,
      gaugeFilter: this.gaugeFilter,
      entityFilter: this.entityFilter,
      metricsFilter: this.metricsFilter,
      operatorFilter: this.operatorFilter,
      rankingFilter: this.rankingFilter,
      chartCondition: this.chartCondition,
      rankingCondition: this.rankingCondition,
      modules: this.modules,
    });

    await this._syncState();

    this._requestUpdate();

    this._requestChartDataset();
    this._requestRankingDataset();

    this.await = false;

    this.$.dispatchEvent(new CustomEvent('setup'));
  }

  async updateNavigationDate(params = {}) {
    const { navigationDate = {} } = params;

    await this.workerService.updateNavigationDate({ navigationDate });

    await this._syncState();

    if (!this.await) {
      this._requestChartDataset();
      this._requestRankingDataset();
    }
  }

  async updateNavigationHierarchy(params = {}) {
    const { field = '', navigationHierarchy = {} } = params;

    await this.workerService.updateNavigationHierarchy({ field, navigationHierarchy });

    await this._syncState();

    if (!this.await) {
      this._requestChartDataset();
      this._requestRankingDataset();
    }
  }
  /* */

  /* Observers */
  __onToggleMenuItem(evt, query) {
    if (evt.target.hasAttribute('open')) {
      this.$.querySelector(query || `power-dropdown`)
        .querySelectorAll(`power-accordion[open]:not([id=${evt.target.id}])`)
        .forEach(nodeItem => {
          nodeItem.toggle();
        });
    }
  }

  __onMobileMediaQuery() {
    this._updateChart();
    this._updateRankingChart();

    this._requestUpdate();
  }

  __onTabletMediaQuery(query) {
    if (query.matches === false) {
      this._setActiveRanking(this.activeRanking);
    }

    this._updateChart();
    this._updateRankingChart();

    this._requestUpdate();
  }

  _onPlotBandClick(evt) {
    const activePlotBand = this.$.querySelector('#chart-container .highcharts-plot-band[active]');

    // eslint-disable-next-line no-unused-expressions
    activePlotBand.removeAttribute('active');

    if (activePlotBand !== evt.target) {
      evt.target.setAttribute('active', '');
    }
  }

  _onRankingPlotBandClick(evt) {
    const activePlotBand = this.$.querySelector(
      '#ranking-detail-chart-container .highcharts-plot-band[active]',
    );

    // eslint-disable-next-line no-unused-expressions
    activePlotBand.removeAttribute('active');

    if (activePlotBand !== evt.target) {
      evt.target.setAttribute('active', '');
    }
  }
  /* */
}

class GolfleetMainDashboard {
  constructor() {
    this.template = template;
    this.bindings = {
      apiMethodChart: '=?',
      apiMethodRanking: '=?',
      apiMethodExportRanking: '=?',
      apiMethodRankingDetail: '=?',
      rankingLeftLink: '=?',
      rankingRightLink: '=?',
      rankingDetailLink: '=?',
      gaugeFilter: '=?',
      operatorFilter: '=?',
      entityFilter: '=?',
      metricsFilter: '=?',
      rankingFilter: '=?',
      chartCondition: '=?',
      rankingCondition: '=?',
      drilldownGridMapping: '=?',
      filterConditions: '=?',
    };
    this.controller = GolfleetMainDashboardController;
  }
}

angular
  .module('golfleet-main-dashboard', ['power-dropdown'])
  .component('golfleetMainDashboard', new GolfleetMainDashboard());
