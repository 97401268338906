import angular from 'angular';
import 'ng-redux';
import moment from 'moment';

import '../power-popup/power-popup';
import '../power-popup-list/power-popup-list';
import '../power-popup-pdf-export-prompt/power-popup-pdf-export-prompt';

import { AutoSendConfig } from '../power-popup-report-auto-send/power-popup-report-auto-send';
import { UserRouteConfig } from '../power-popup-report-save-and-share/power-popup-report-save-and-share';
import { ImportRouteConfig } from '../../utils/get-route-config';

// Presentation
import template from './power-toolbar-report.html';
import './power-toolbar-report.scss';

class PowerToolbarReportController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$transclude',
      '$http',
      'reportServices',
      'urlApi',
    ];
  }

  constructor($element, $ngRedux, $scope, $state, $transclude, $http, reportServices, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $scope,
      $state,
      $transclude,
      $http,
      reportServices,
      urlApi,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      const { userId, userEmail, userName, isTrip, isSingleSignon } = behavior.session;

      return Object({
        sessionState: {
          userId,
          userEmail,
          userName,
          isTrip,
          isSingleSignon,
        },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        newRouteLink: behavior.state.routeList[behavior.state.routeList.length - 1]?.routeLink,
      });
    })(this);

    ImportRouteConfig(this.newRouteLink).then(newRouteConfig => {
      this.metadata = newRouteConfig ? newRouteConfig.__metadata__ : {};
      this.reportConfigController = {
        reportName: this.currentState.routeName,
        reportCenterLinks: [
          {
            description: this.currentState.routeName.toLowerCase().includes('relatório')
              ? `Todos os ${this.currentState.routeName
                  .toLowerCase()
                  .replace('relatório', 'relatórios')}`
              : `Todas as listagens de ${this.currentState.routeName.toLowerCase()}`,
            baseReport: this.currentState.routeLink,
          },
          {
            description: `Todos os meus relatórios`,
            baseReport: '',
          },
        ],
        saveConfig: {
          button: {
            default: {
              icons: ['gs_star'],
              class: 'button-default',
            },
            hasUpdate: {
              icons: ['gs_star_sys'],
              class: 'button-default',
            },
          },
          title: 'Relatório',
          options: [
            {
              value: 'save',
              description: 'Salvar',
              icon: 'gs_star_sys',
            },
            {
              value: 'saveAs',
              description: 'Salvar como...',
              icon: 'gs_star',
            },
            {
              value: 'share',
              description: 'Compartilhar com...',
              icon: 'gs_star_shared',
            },
            {
              value: 'toggleMenu',
              description: 'Incluir no menu',
              icon: 'menu',
            },
            {
              value: 'restore',
              description: 'Restaurar',
              icon: 'refresh',
            },
            {
              value: 'delete',
              description: 'Excluir',
              icon: 'delete',
            },
          ],
          sections: {
            userReportsActive: {
              title: 'Últimos Relatórios',
            },
          },
        },
        exportConfig: {
          button: {
            default: {
              icons: ['gs_download'],
              class: 'button-default',
            },
            hasDownload: {
              icons: ['gs_download'],
              class: 'button-has-download',
            },
            hasNewDownload: {
              icons: ['gs_download'],
              class: 'button-has-new-download',
            },
          },
          title: 'Exportar para...',
          options: [
            {
              value: 'csv',
              description: 'Arquivo CSV',
              icon: 'insert_drive_file',
              method: this.metadata.exportMethod,
              digital: true,
            },
            {
              value: 'xlsx',
              description: 'Arquivo XLSX',
              icon: 'insert_drive_file',
              method: this.metadata.exportMethod,
              digital: true,
            },
            {
              value: 'pdf',
              description: 'Arquivo PDF (digital)',
              icon: 'insert_drive_file',
              method: this.metadata.exportMethod,
              digital: true,
            },
            {
              value: 'pdf',
              description: 'Arquivo PDF (impressão)',
              icon: 'insert_drive_file',
              method: this.metadata.exportMethod,
              digital: false,
            },
          ],
        },
        autoSendConfig: {
          button: {
            default: {
              icons: ['gs_autosend'],
              class: 'button-default',
            },
            hasAppointment: {
              icons: ['gs_autosend_b'],
              class: 'button-default',
            },
          },
          title: 'Envio Automático',
          options: [
            {
              value: 'new',
              description: 'Novo',
              icon: 'gs_add',
            },
          ],
          sections: {
            autoSendActive: {
              title: 'Envios Ativos',
            },
          },
        },
      };
      this._saveAndShareGetCustomReports();
      this._autoSendGetMenuOptions();
    });

    this.slots = {
      hasHeader: this.$transclude.isSlotFilled('headerSlot'),
      hasRight: this.$transclude.isSlotFilled('rightSlot'),
      hasLeft: this.$transclude.isSlotFilled('leftSlot'),
      hasHeaderRight: this.$transclude.isSlotFilled('headerRightSlot'),
    };

    this.savedCustomReports = [];
    this.savedAutoSendReports = [];

    if (this.slots.hasRight) {
      this.$.setAttribute('has-right-slot', '');
    }
  }

  // #region /* Lifecycle */
  $onInit() {
    this.$.querySelector('#power-popup-report-auto-send').addEventListener(
      'save',
      this._autoSendOnSave.bind(this),
    );

    this.$.querySelector('#power-popup-report-auto-send').addEventListener(
      'saved',
      this._autoSendOnSaved.bind(this),
    );

    this.$.querySelector('#save-share-action').addEventListener(
      'open',
      this._validateSaveAndShareMenuOptions.bind(this),
    );

    this.$scope.$watch(
      () => JSON.stringify(this.stateConfig),
      this._validateSaveAndShareHasUpdates.bind(this),
    );
  }

  $onDestroy() {
    this.__appBehavior();

    this.$.querySelector('#power-popup-report-auto-send').removeEventListener(
      'save',
      this._autoSendOnSave.bind(this),
    );

    this.$.querySelector('#power-popup-report-auto-send').removeEventListener(
      'saved',
      this._autoSendOnSaved.bind(this),
    );

    this.$.querySelector('#save-share-action').removeEventListener(
      'open',
      this._validateSaveAndShareMenuOptions.bind(this),
    );
  }
  // #endregion

  /*
  ------------------------------------------------------------------------------
  # SAVE AND SHARE FUNCTIONS
  ------------------------------------------------------------------------------
  */

  _saveAndShareGetCustomReports() {
    if (this.sessionState.isTrip && this.savedCustomReports.length === 0) {
      this.reportServices
        .getAllUserReportConfigurations({
          module: this.currentState.routeLink,
          limit: 3,
        })
        .then(response => {
          if (response.status && response.status !== 200) {
            Object.assign(this, {
              _toastText: 'Ocorreu um erro inesperado ao carregar os últimos relatórios salvos.',
            });
            return;
          }
          const { data } = response.data;
          this.savedCustomReports = data;
        });
    }
  }

  /**
   * Validate save and share menu options
   */
  _validateSaveAndShareHasUpdates() {
    const filterHasUpdate =
      !!this.currentState.userReportConfig &&
      JSON.stringify(
        this.currentState.userReportConfig.filterConfig,
        UserRouteConfig.removeFilterIncomparableProps,
      ) !=
        JSON.stringify(
          UserRouteConfig.getFilterConditions(this.stateConfig.filterConfig),
          UserRouteConfig.removeFilterIncomparableProps,
        );

    const gridHasUpdate =
      !!this.currentState.userReportConfig &&
      JSON.stringify(
        this.currentState.userReportConfig.gridConfig,
        UserRouteConfig.removeGridIncomparableProps,
      ) !=
        JSON.stringify(
          UserRouteConfig.getGridConfigurations(this.stateConfig.gridConfig),
          UserRouteConfig.removeGridIncomparableProps,
        );

    let iconsSaveAndShare = [];
    if (filterHasUpdate || gridHasUpdate) {
      iconsSaveAndShare = this.reportConfigController?.saveConfig.button.hasUpdate.icons ?? [];
    } else {
      iconsSaveAndShare = this.reportConfigController?.saveConfig.button.default.icons ?? [];
    }

    this.$.querySelector('#save-share-action button-slot').innerHTML = '';

    iconsSaveAndShare.forEach(value => {
      const icon = document.createElement('i');
      icon.classList.add('material-icons');
      icon.innerText = value;

      this.$.querySelector('#save-share-action button-slot').appendChild(icon);
    });

    return {
      filterHasUpdate,
      gridHasUpdate,
    };
  }

  _validateSaveAndShareMenuOptions() {
    const { filterHasUpdate, gridHasUpdate } = this._validateSaveAndShareHasUpdates();

    const enableMenuItem = (item, enableValidation, next) => {
      if (enableValidation()) {
        item.setAttribute('dropdown-auto-close', '');
        item.removeAttribute('disabled');
      } else {
        item.removeAttribute('dropdown-auto-close');
        item.setAttribute('disabled', '');
      }

      if (next) next();
    };

    this.$.querySelectorAll('#save-share-action .power-dropdown-list-item').forEach(item => {
      if (
        ['save', 'share'].find(opt => opt == item.getAttribute('data-type')) &&
        !!this.currentState.userReportConfig &&
        !!this.currentState.userReportCanEdit &&
        !!this.currentState.userReportId
      ) {
        enableMenuItem(
          item,
          () =>
            (item.getAttribute('data-type') == 'save' && (filterHasUpdate || gridHasUpdate)) ||
            (item.getAttribute('data-type') == 'share' && !filterHasUpdate && !gridHasUpdate),
        );
      } else if (item.getAttribute('data-type') == 'toggleMenu') {
        enableMenuItem(
          item,
          () => !!this.currentState.userReportConfig,
          () => {
            if (this.currentState.userReportConfig) {
              this.currentState.userReportConfig.sharedUsers
                .filter(user => user.userId == this.sessionState.userId)
                .forEach(user => {
                  item.querySelector('i').innerHTML = user.isMenuItem ? 'close' : 'menu';
                  item.querySelector('span').innerHTML = user.isMenuItem
                    ? 'Remover do menu'
                    : 'Incluir no menu';
                });
            }
          },
        );
      } else if (item.getAttribute('data-type') == 'restore') {
        enableMenuItem(
          item,
          () => !!this.currentState.userReportId && (filterHasUpdate || gridHasUpdate),
        );
      } else if (item.getAttribute('data-type') == 'delete') {
        enableMenuItem(
          item,
          () => !!this.currentState.userReportConfig && !!this.currentState.userReportId,
        );
      } else if (item.getAttribute('data-type') && item.getAttribute('data-type') != 'saveAs') {
        enableMenuItem(item, () => false);
      } else {
        enableMenuItem(item, () => true);
      }
    });
  }

  /**
   * Menu item click
   * @param {Any} selectedItem
   */
  async _saveAndShareMenuItemClick(selectedItem) {
    const popupSaveAndShare = this.$.querySelector('#power-popup-report-save-and-share');

    switch (selectedItem.value) {
      case 'save':
        popupSaveAndShare.loadModel(true);
        popupSaveAndShare.save(true);
        break;
      case 'saveAs':
        popupSaveAndShare.loadModel(false);
        popupSaveAndShare.toggle({ tab: 0 }); // Tab: Data
        break;
      case 'share':
        popupSaveAndShare.loadModel(true);
        popupSaveAndShare.toggle({ tab: 1 }); // Tab: Users
        break;
      case 'toggleMenu':
        popupSaveAndShare.toggleMenu();
        break;
      case 'restore':
        {
          const routeConfig = await ImportRouteConfig(this.currentState.routeLink);

          if (routeConfig) {
            const stateParams = {
              isCustomReport: true,
              customReportId: this.stateConfig.customReportId,
            };

            const routeData = {
              ...routeConfig.data,
              stateConfig: routeConfig.data.stateConfig(stateParams),
            };

            this.$ngRedux.dispatch({
              type: 'NEXT_ROUTE_SINGLE',
              data: routeData,
            });

            this.$state.go(routeConfig.name, {}, { reload: true });
          }
        }
        break;
      case 'delete':
        popupSaveAndShare.toggleDeleteConfirmation();
        break;
      default:
    }
  }

  async _saveAndShareCustomReportClick(item) {
    const routeConfig = await ImportRouteConfig(item.module);

    if (routeConfig) {
      const stateParams = { isCustomReport: true, customReportId: item._id };

      const routeData = {
        ...routeConfig.data,
        stateConfig: routeConfig.data.stateConfig(stateParams),
      };

      this.$ngRedux.dispatch({
        type: 'NEXT_ROUTE_SINGLE',
        data: routeData,
      });

      this.$state.go(routeConfig.name, {}, { reload: true });
    }
  }

  _saveAndShareSeeAllOptions() {
    this._toogleLoader(true);
    this.reportServices
      .getAllUserReportConfigurations({
        module: this.currentState.routeLink,
      })
      .then(response => {
        if (response.status && response.status !== 200) {
          Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
          this._toogleLoader(false);
          return;
        }
        const { data } = response.data;
        this.savedCustomReports = data;

        const listItems = this.savedCustomReports.map(item => ({
          _id: item._id,
          description: item.reportName,
          info: moment(item.updatedAt).format('DD-MM-YYYY'),
          module: item.module,
          icon: 'gs_star',
        }));

        this.$.querySelector('#save-and-share-popup-list-items').toggle();
        this.$.querySelector('#save-and-share-popup-list-items').setListItems(listItems);
        this._toogleLoader(false);
      });
  }

  _saveAndShareListItemClick(item) {
    this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
    this.$state.go(
      item.module,
      { isCustomReport: true, customReportId: item._id },
      { reload: true },
    );
  }

  // END SAVE AND SHARE FUNCTIONS

  /*
  ------------------------------------------------------------------------------
  # EXPORT DATA FUNCTIONS
  ------------------------------------------------------------------------------
  */
  _exportOptionIsAvailable(option) {
    if (Array.isArray(this.downloadExtensions)) {
      return this.downloadExtensions.includes(option.value.toLowerCase());
    }

    return true;
  }

  _exportData(selectedItem) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.closeReportControlMoreOptions = true;

    const tableOffsetWidth = (document.querySelector('#report-body-grid table') || {}).offsetWidth;
    const requestTime = new Date();
    const headers = Object.clone([], this.stateConfig.gridConfig.gridHeaders);
    const payload = {
      name: this.currentState.routeName,
      columns:
        this.stateConfig.gridConfig.replaceHeaderText &&
        this.stateConfig.gridConfig.complementHeaderText
          ? headers.map(col => {
              col.title = (Array.isArray(col.title) ? col.title : [col.title]).map(str =>
                str.replace(
                  this.stateConfig.gridConfig.replaceHeaderText,
                  this.stateConfig.gridConfig.complementHeaderText,
                ),
              );
              return col;
            })
          : headers,

      request: {
        ...this.stateConfig.getDataFixedParams,
        isPaginated: true,
        page: 0,
        length: this.stateConfig.gridConfig.gridTotal,
        sort: {
          name: this.stateConfig.gridConfig.sortField,
          direction: this.stateConfig.gridConfig.sortDirection,
        },
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        navigation: (() => {
          const result = {};
          const { navigation } = this.stateConfig;

          if (!!navigation && !!navigation.date && navigation.date.length > 0) {
            result.date = navigation.date[navigation.date.length - 1];
          }

          return result;
        })(),
        exportOptions: {
          exportType: selectedItem.value || 'xlsx',
          digital: selectedItem.digital,
          offsetWidth: tableOffsetWidth || null,
        },
      },
      requestTime: new Date(requestTime - requestTime.getTimezoneOffset() * 60000),
    };

    this.$http({
      url: `${this.urlApi}/${selectedItem.method}`,
      method: 'POST',
      data: payload,
    })
      .then(async success => {
        const blob = await (
          await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
        ).blob();
        const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
        const downloadLink = document.createElement('a');

        downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
        downloadLink.href = fileUrl;
        downloadLink.click();
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _showPdfExportPrompt(option) {
    this.$.querySelector('power-popup-pdf-export-prompt').setup({ option });
    this.$.querySelector('power-popup-pdf-export-prompt').toggle();
  }
  // END EXPORT DATA FUNCTIONS

  /*
  ------------------------------------------------------------------------------
  # AUTO SEND FUNCTIONS
  ------------------------------------------------------------------------------
  */

  _autoSendGetMenuOptions() {
    if (
      this.sessionState.isTrip &&
      !this.sessionState.isSingleSignon &&
      this.stateConfig.isCustomReport &&
      this.stateConfig.customReportId &&
      this.savedAutoSendReports.length === 0
    ) {
      this.reportServices
        .getAllAutoSendConfigurations({
          userReportConfigurationId: this.stateConfig.customReportId,
          limit: 3,
        })
        .then(response => {
          if (response.status && response.status !== 200) {
            Object.assign(this, {
              _toastText: 'Ocorreu um erro inesperado ao tentar carregar os envios automáticos.',
            });
            return;
          }

          const { data } = response.data;
          this.savedAutoSendReports = [...data.map(item => AutoSendConfig.parse(item))];

          const buttonKey = this.savedAutoSendReports.length > 0 ? 'hasAppointment' : 'default';

          const icons = this.reportConfigController.autoSendConfig.button[buttonKey].icons.map(
            value => {
              const icon = document.createElement('i');
              icon.classList.add('material-icons');
              icon.innerText = value;
              return icon;
            },
          );

          const autoSendButtonElement = this.$.querySelector('#auto-send-action button-slot');
          autoSendButtonElement.innerHTML = '';

          icons.forEach(icon => autoSendButtonElement.appendChild(icon));
        });
    }
  }

  _autoSendMenuItemClick(selectedItem) {
    switch (selectedItem.value) {
      case 'new':
        if (!this.sessionState.isSingleSignon) {
          this.$.querySelector('#power-popup-report-auto-send')
            .loadData()
            .then(() => {
              this.$.querySelector('#power-popup-report-auto-send').toggle();
            });
        } else {
          this.$.querySelector('#power-popup-report-auto-send-info').toggle();
        }
        break;
      default:
    }
  }

  _autoSendUserCanEditReport(option) {
    return option._data.owner.userId == this.sessionState.userId;
  }

  _autoSendListItemClick(data) {
    this.$.querySelector('#power-popup-report-auto-send')
      .loadData(data)
      .then(() => {
        setTimeout(this.$.querySelector('#power-popup-report-auto-send').toggle, 300);
      });
  }

  _autoSendSeeAllOptions() {
    this._toogleLoader(true);
    this.reportServices
      .getAllAutoSendConfigurations({
        userReportConfigurationId: this.currentState.userReportId,
      })
      .then(response => {
        if (response.status && response.status !== 200) {
          Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
          this._toogleLoader(false);
          return;
        }
        const { data } = response.data;
        this.savedAutoSendReports = [...data.map(item => AutoSendConfig.parse(item))];

        const listItems = this.savedAutoSendReports.map(item => ({
          _id: item._id,
          description: item.fileName,
          info: moment(item._data.updatedAt).format('DD-MM-YYYY'),
          icon: 'gs_autosend',
          data: item,
        }));

        this.$.querySelector('#auto-send-popup-list-items').toggle();
        this.$.querySelector('#auto-send-popup-list-items').setListItems(listItems);
        this._toogleLoader(false);
      });
  }

  _autoSendOnSave(e) {
    this.$.querySelector('#power-popup-report-save-and-share')
      .quickSave()
      .then(customReportId => {
        // save auto send configuration
        e.detail.callback(customReportId);

        setTimeout(() => {
          this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
          this.$state.go(
            this.$state.current,
            { customReportId, isCustomReport: true },
            {
              reload: true,
              inherit: false,
              notify: true,
            },
          );
        }, 1500);
      });
  }

  _autoSendOnSaved() {
    this.savedAutoSendReports = [];
    this._autoSendGetMenuOptions();
  }

  /* Remover após integração */
  _autoSendInfoCloseButtonClick() {
    this.$.querySelector('#power-popup-report-auto-send-info').toggle();
  }
  // END AUTO SEND FUNCTIONS

  _toogleLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  async _goToReportCenter(data) {
    const routeConfig = await ImportRouteConfig('reports');

    if (routeConfig) {
      const filters = data.baseReport ? [{ id: 1, default: [data.baseReport] }] : null;

      const routeData = {
        ...routeConfig.data,
        stateConfig: routeConfig.data.stateConfig({ filters }),
      };

      this.$state.go(routeConfig.name, {}, { reload: true });
      this.$ngRedux.dispatch({
        type: 'NEXT_ROUTE_SINGLE',
        data: routeData,
      });
    }
  }

  __onPdfConfirmExport(event) {
    this._exportData(event.detail.option);
  }
}

class PowerToolbarReport {
  constructor() {
    this.template = template;
    this.bindings = {
      downloadExtensions: '=?',
    };
    this.transclude = {
      headerSlot: '?headerSlot',
      rightSlot: '?rightSlot',
      leftSlot: '?leftSlot',
      complementaryActions: '?complementaryActions',
    };
    this.controller = PowerToolbarReportController;
  }
}

angular
  .module('power-toolbar-report', [
    'ngRedux',
    'power-popup',
    'power-popup-list',
    'power-popup-pdf-export-prompt',
    'power-popup-report-auto-send',
    'power-popup-report-save-and-share',
  ])
  .component('powerToolbarReport', new PowerToolbarReport());
