/* eslint-disable camelcase */
import ToolBar_Activities_Report from './default/ToolBar_Activities_Report.json';
import ToolBar_Activities_Trip_Report from './default/ToolBar_Activities_Trip_Report.json';
import ToolBar_Announcements_Report from './default/ToolBar_Announcements_Report.json';
import ToolBar_AttendanceDetail_Report from './default/ToolBar_AttendanceDetail_Report.json';
import ToolBar_AttendanceDetail_Trip_Report from './default/ToolBar_AttendanceDetail_Trip_Report.json';
import ToolBar_Attendance_Report from './default/ToolBar_Attendance_Report.json';
import ToolBar_Attendance_Trip_Report from './default/ToolBar_Attendance_Trip_Report.json';
import ToolBar_ConsuptionBySegmentReport_List from './default/ToolBar_ConsuptionBySegmentReport_List.json';
import ToolBar_ConsuptionBySegmentReport_Trip_List from './default/ToolBar_ConsuptionBySegmentReport_Trip_List.json';
import ToolBar_Dashboard_Dashboard from './default/ToolBar_Dashboard_Dashboard.json';
import ToolBar_Dashboard_FuelModule from './default/ToolBar_Dashboard_FuelModule.json';
import ToolBar_Dashboard_SpeedLimitModule from './default/ToolBar_Dashboard_SpeedLimitModule.json';
import ToolBar_Dashboard_Trip_Dashboard from './default/ToolBar_Dashboard_Trip_Dashboard.json';
import ToolBar_Dashboard_Trip_FuelModule from './default/ToolBar_Dashboard_Trip_FuelModule.json';
import ToolBar_Dashboard_Trip_SpeedLimitModule from './default/ToolBar_Dashboard_Trip_SpeedLimitModule.json';
import ToolBar_TempDriver_List from './default/ToolBar_TempDriver_List.json';
import ToolBar_TempDriver_Trip_List from './default/ToolBar_TempDriver_Trip_List.json';
import ToolBar_Driver_Trip_List from './default/ToolBar_Driver_Trip_List.json';
import ToolBar_FuelSupplyManagementReport_List from './default/ToolBar_FuelSupplyManagementReport_List.json';
import ToolBar_FuelSupplyManagementReport_Trip_List from './default/ToolBar_FuelSupplyManagementReport_Trip_List.json';
import ToolBar_GeneratedAlerts_Report from './default/ToolBar_GeneratedAlerts_Report.json';
import ToolBar_GeneratedAlerts_Trip_Report from './default/ToolBar_GeneratedAlerts_Trip_Report.json';
import ToolBar_GeoFence_List from './default/ToolBar_GeoFence_List.json';
import ToolBar_GeoFence_Trip_List from './default/ToolBar_GeoFence_Trip_List.json';
import ToolBar_GolCamReport_List from './default/ToolBar_GolCamReport_List.json';
import ToolBar_GroupAdm_List from './default/ToolBar_GroupAdm_List.json';
import ToolBar_GroupAdm_Trip_List from './default/ToolBar_GroupAdm_Trip_List.json';
import ToolBar_Group_List from './default/ToolBar_Group_List.json';
import ToolBar_Group_Trip_List from './default/ToolBar_Group_Trip_List.json';
import ToolBar_KmControl_Report from './default/ToolBar_KmControl_Report.json';
import ToolBar_KmControl_Trip_Report from './default/ToolBar_KmControl_Trip_Report.json';
import ToolBar_MotionLog_Trip_Report from './default/ToolBar_MotionLog_Trip_Report.json';
import ToolBar_MyAlertsAdm_Report from './default/ToolBar_MyAlertsAdm_Report.json';
import ToolBar_MyAlertsAdm_Trip_Report from './default/ToolBar_MyAlertsAdm_Trip_Report.json';
import ToolBar_MyAlerts_Report from './default/ToolBar_MyAlerts_Report.json';
import ToolBar_MyAlerts_Trip_Report from './default/ToolBar_MyAlerts_Trip_Report.json';
import ToolBar_OrderService_Report from './default/ToolBar_OrderService_Report.json';
import ToolBar_PeriodActivitiesDetail_Report from './default/ToolBar_PeriodActivitiesDetail_Report.json';
import ToolBar_PeriodActivitiesDetail_Trip_Report from './default/ToolBar_PeriodActivitiesDetail_Trip_Report.json';
import ToolBar_PeriodActivities_Report from './default/ToolBar_PeriodActivities_Report.json';
import ToolBar_PeriodActivities_Trip_Report from './default/ToolBar_PeriodActivities_Trip_Report.json';
import ToolBar_FleetPolicyAdm_Trip_List from './default/ToolBar_FleetPolicyAdm_Trip_List.json';
import ToolBar_FleetPolicyStatusAdm_Trip_List from './default/ToolBar_FleetPolicyStatusAdm_Trip_List.json';
import ToolBar_FleetPolicyStatus_Trip_List from './default/ToolBar_FleetPolicyStatus_Trip_List.json';
import ToolBar_Profiles_List from './default/ToolBar_Profiles_List.json';
import ToolBar_Profiles_Trip_List from './default/ToolBar_Profiles_Trip_List.json';
import ToolBar_RankingSpeedLimitDetail_Report from './default/ToolBar_RankingSpeedLimitDetail_Report.json';
import ToolBar_RankingSpeedLimitDetail_Trip_Report from './default/ToolBar_RankingSpeedLimitDetail_Trip_Report.json';
import ToolBar_RankingSpeedLimitReportByDriver_List from './default/ToolBar_RankingSpeedLimitReportByDriver_List.json';
import ToolBar_RankingSpeedLimitReportByDriver_Trip_List from './default/ToolBar_RankingSpeedLimitReportByDriver_Trip_List.json';
import ToolBar_RankingSpeedLimitReportByRoute_List from './default/ToolBar_RankingSpeedLimitReportByRoute_List.json';
import ToolBar_RankingSpeedLimitReportByRoute_Trip_List from './default/ToolBar_RankingSpeedLimitReportByRoute_Trip_List.json';
import ToolBar_RankingSpeedLimitReportByVehicle_List from './default/ToolBar_RankingSpeedLimitReportByVehicle_List.json';
import ToolBar_RankingSpeedLimitReportByVehicle_Trip_List from './default/ToolBar_RankingSpeedLimitReportByVehicle_Trip_List.json';
import ToolBar_Reports_Trip_List from './default/ToolBar_Reports_Trip_List.json';
import ToolBar_SuspectedAccident_Trip_Report from './default/ToolBar_SuspectedAccident_Trip_Report.json';
import ToolBar_TcoAccidentReport_List from './default/ToolBar_TcoAccidentReport_List.json';
import ToolBar_TcoCostReport_List from './default/ToolBar_TcoCostReport_List.json';
import ToolBar_TcoEntryReport_List from './default/ToolBar_TcoEntryReport_List.json';
import ToolBar_TcoRentReport_List from './default/ToolBar_TcoRentReport_List.json';
import ToolBar_TcoTicketReport_List from './default/ToolBar_TcoTicketReport_List.json';
import ToolBar_TcoParkingReport_List from './default/ToolBar_TcoParkingReport_List.json';
import ToolBar_TcoTollReport_List from './default/ToolBar_TcoTollReport_List.json';
import ToolBar_PositionsReport_List from './default/ToolBar_PositionsReport_List.json';
import ToolBar_PositionsReport_Trip_List from './default/ToolBar_PositionsReport_Trip_List.json';
import ToolBar_TravelsByDriver_Trip_Report from './default/ToolBar_TravelsByDriver_Trip_Report.json';
import ToolBar_TravelsByGroup_Trip_Report from './default/ToolBar_TravelsByGroup_Trip_Report.json';
import ToolBar_TravelsByUtilization_Trip_Report from './default/ToolBar_TravelsByUtilization_Trip_Report.json';
import ToolBar_TravelsByVehicle_Trip_Report from './default/ToolBar_TravelsByVehicle_Trip_Report.json';
import ToolBar_TravelsByVehicleDriver_Trip_Report from './default/ToolBar_TravelsByVehicleDriver_Trip_Report.json';
import ToolBar_Trip_Report from './default/ToolBar_Trip_Report.json';
import ToolBar_Trip_Trip_Report from './default/ToolBar_Trip_Trip_Report.json';
import ToolBar_TripsDetail_Report from './default/ToolBar_TripsDetail_Report.json';
import ToolBar_TripsDetail_Trip_Report from './default/ToolBar_TripsDetail_Trip_Report.json';
import ToolBar_TripsDriver_Report from './default/ToolBar_TripsDriver_Report.json';
import ToolBar_TripsDriver_Trip_Report from './default/ToolBar_TripsDriver_Trip_Report.json';
import ToolBar_Trips_Report from './default/ToolBar_Trips_Report.json';
import ToolBar_Trips_Trip_Report from './default/ToolBar_Trips_Trip_Report.json';
import ToolBar_User_List from './default/ToolBar_User_List.json';
import ToolBar_User_Trip_List from './default/ToolBar_User_Trip_List.json';
import ToolBar_TempUtilizationDetail_Report from './default/ToolBar_TempUtilizationDetail_Report.json';
import ToolBar_TempUtilizationDetail_Trip_Report from './default/ToolBar_TempUtilizationDetail_Trip_Report.json';
import ToolBar_TempUtilization_Report from './default/ToolBar_TempUtilization_Report.json';
import ToolBar_TempUtilization_Trip_Report from './default/ToolBar_TempUtilization_Trip_Report.json';
import ToolBar_Utilization_Trip_Report from './default/ToolBar_Utilization_Trip_Report.json';
import ToolBar_VehicleAdm_List from './default/ToolBar_VehicleAdm_List.json';
import ToolBar_VehicleAdm_Trip_List from './default/ToolBar_VehicleAdm_Trip_List.json';
import ToolBar_Vehicle_List from './default/ToolBar_Vehicle_List.json';
import ToolBar_Vehicle_Trip_List from './default/ToolBar_Vehicle_Trip_List.json';
import ToolBar_TempViolation_Trip_Report from './default/ToolBar_TempViolation_Trip_Report.json';
import ToolBar_ViolationsSpeedLimitReport_List from './default/ToolBar_ViolationsSpeedLimitReport_List.json';
import ToolBar_ViolationsSpeedLimitReport_Trip_List from './default/ToolBar_ViolationsSpeedLimitReport_Trip_List.json';
import ToolBar_WifiAdm_Trip_List from './default/ToolBar_WifiAdm_Trip_List.json';
import ToolBar_RealTimeVehicle_Trip_Report from './default/ToolBar_RealTimeVehicle_Trip_Report.json';
import ToolBar_RealTimeVehicleHistory_Trip_Report from './default/ToolBar_RealTimeVehicleHistory_Trip_Report.json';
import ToolBar_RealTimeVehicleHistoryAdm_Trip_Report from './default/ToolBar_RealTimeVehicleHistoryAdm_Trip_Report.json';
import ToolBar_RealTimePermissionAdm_Trip_Report from './default/ToolBar_RealTimePermissionAdm_Trip_Report.json';
import ToolBar_RealTimeGroupByVehiclesAdm_Trip_Report from './default/ToolBar_RealTimeGroupByVehiclesAdm_Trip_Report.json';
import ToolBar_RealTimeGroupByClientDealerAdm_Trip_Report from './default/ToolBar_RealTimeGroupByClientDealerAdm_Trip_Report.json';
import ToolBar_ViolationRankingReportSPAL_Trip_Report from './default/ToolBar_ViolationRankingReportSPAL_Trip_Report.json';
import ToolBar_VisitReport_Trip_Report from './default/ToolBar_VisitReport_Trip_Report.json';
import ToolBar_RevisionPlan_Trip_Report from './default/ToolBar_RevisionPlan_Trip_Report.json';
import ToolBar_RevisionStatus_Trip_Report from './default/ToolBar_RevisionStatus_Trip_Report.json';
import ToolBar_RevisionHistory_Trip_Report from './default/ToolBar_RevisionHistory_Trip_Report.json';
import ToolBar_Workshop_Trip_List from './default/ToolBar_Workshop_Trip_List.json';
import ToolBar_PointsOfInterest_Trip_List from './default/ToolBar_PointsOfInterest_Trip_List.json';
import ToolBar_UnitaryViolation_Trip_Report from './default/ToolBar_UnitaryViolation_Trip_Report.json';
import ToolBar_ViolationByVehicle_Trip_Report from './default/ToolBar_ViolationByVehicle_Trip_Report.json';
import ToolBar_ViolationByDriver_Trip_Report from './default/ToolBar_ViolationByDriver_Trip_Report.json';
import ToolBar_ViolationByVehicleDriver_Trip_Report from './default/ToolBar_ViolationByVehicleDriver_Trip_Report.json';
import ToolBar_ViolationByGroup_Trip_Report from './default/ToolBar_ViolationByGroup_Trip_Report.json';
import ToolBar_DetailedViolation_Trip_Report from './default/ToolBar_DetailedViolation_Trip_Report.json';
import ToolBar_Requester_List from './default/ToolBar_Requester_List.json';
import ToolBar_DriversAppStatusAdm_Trip_List from './default/ToolBar_DriversAppStatusAdm_Trip_List.json';
import ToolBar_GolfleetIdAppStatusAdm_Trip_List from './default/ToolBar_GolfleetIdAppStatusAdm_Trip_List.json';
import ToolBar_Blockage_Report from './default/ToolBar_Blockage_Report.json';
import ToolBar_BlockageAdm_Report from './default/ToolBar_BlockageAdm_Report.json';
import ToolBar_GolfleetIdPermissionHistoricAdm_Trip_List from './default/ToolBar_GolfleetIdPermissionHistoricAdm_Trip_List.json';
import ToolBar_UtilizationSimplified_Trip_List from './default/ToolBar_UtilizationSimplified_Trip_List.json';

// Tracking
import ToolBar_TempDriver_Tracking_List from './tracking/ToolBar_TempDriver_Tracking_List.json';
import ToolBar_Driver_Tracking_List from './tracking/ToolBar_Driver_Tracking_List.json';
import ToolBar_User_Tracking_List from './tracking/ToolBar_User_Tracking_List.json';

export default {
  ToolBar_Activities_Report,
  ToolBar_Activities_Trip_Report,
  ToolBar_Announcements_Report,
  ToolBar_AttendanceDetail_Report,
  ToolBar_AttendanceDetail_Trip_Report,
  ToolBar_Attendance_Report,
  ToolBar_Attendance_Trip_Report,
  ToolBar_ConsuptionBySegmentReport_List,
  ToolBar_ConsuptionBySegmentReport_Trip_List,
  ToolBar_Dashboard_Dashboard,
  ToolBar_Dashboard_FuelModule,
  ToolBar_Dashboard_SpeedLimitModule,
  ToolBar_Dashboard_Trip_Dashboard,
  ToolBar_Dashboard_Trip_FuelModule,
  ToolBar_Dashboard_Trip_SpeedLimitModule,
  ToolBar_TempDriver_List,
  ToolBar_TempDriver_Trip_List,
  ToolBar_Driver_Trip_List,
  ToolBar_FuelSupplyManagementReport_List,
  ToolBar_FuelSupplyManagementReport_Trip_List,
  ToolBar_GeneratedAlerts_Report,
  ToolBar_GeneratedAlerts_Trip_Report,
  ToolBar_GeoFence_List,
  ToolBar_GeoFence_Trip_List,
  ToolBar_GolCamReport_List,
  ToolBar_GroupAdm_List,
  ToolBar_GroupAdm_Trip_List,
  ToolBar_Group_List,
  ToolBar_Group_Trip_List,
  ToolBar_KmControl_Report,
  ToolBar_KmControl_Trip_Report,
  ToolBar_MotionLog_Trip_Report,
  ToolBar_MyAlertsAdm_Report,
  ToolBar_MyAlertsAdm_Trip_Report,
  ToolBar_MyAlerts_Report,
  ToolBar_MyAlerts_Trip_Report,
  ToolBar_OrderService_Report,
  ToolBar_PeriodActivitiesDetail_Report,
  ToolBar_PeriodActivitiesDetail_Trip_Report,
  ToolBar_PeriodActivities_Report,
  ToolBar_PeriodActivities_Trip_Report,
  ToolBar_FleetPolicyAdm_Trip_List,
  ToolBar_FleetPolicyStatusAdm_Trip_List,
  ToolBar_FleetPolicyStatus_Trip_List,
  ToolBar_Profiles_List,
  ToolBar_Profiles_Trip_List,
  ToolBar_RankingSpeedLimitDetail_Report,
  ToolBar_RankingSpeedLimitDetail_Trip_Report,
  ToolBar_RankingSpeedLimitReportByDriver_List,
  ToolBar_RankingSpeedLimitReportByDriver_Trip_List,
  ToolBar_RankingSpeedLimitReportByRoute_List,
  ToolBar_RankingSpeedLimitReportByRoute_Trip_List,
  ToolBar_RankingSpeedLimitReportByVehicle_List,
  ToolBar_RankingSpeedLimitReportByVehicle_Trip_List,
  ToolBar_Reports_Trip_List,
  ToolBar_TcoAccidentReport_List,
  ToolBar_TcoCostReport_List,
  ToolBar_TcoEntryReport_List,
  ToolBar_TcoRentReport_List,
  ToolBar_TcoTicketReport_List,
  ToolBar_TcoParkingReport_List,
  ToolBar_TcoTollReport_List,
  ToolBar_PositionsReport_List,
  ToolBar_PositionsReport_Trip_List,
  ToolBar_TravelsByDriver_Trip_Report,
  ToolBar_TravelsByGroup_Trip_Report,
  ToolBar_TravelsByUtilization_Trip_Report,
  ToolBar_TravelsByVehicle_Trip_Report,
  ToolBar_TravelsByVehicleDriver_Trip_Report,
  ToolBar_Trip_Report,
  ToolBar_Trip_Trip_Report,
  ToolBar_TripsDetail_Report,
  ToolBar_TripsDetail_Trip_Report,
  ToolBar_TripsDriver_Report,
  ToolBar_TripsDriver_Trip_Report,
  ToolBar_Trips_Report,
  ToolBar_Trips_Trip_Report,
  ToolBar_User_List,
  ToolBar_User_Trip_List,
  ToolBar_TempUtilizationDetail_Report,
  ToolBar_TempUtilizationDetail_Trip_Report,
  ToolBar_TempUtilization_Report,
  ToolBar_TempUtilization_Trip_Report,
  ToolBar_Utilization_Trip_Report,
  ToolBar_VehicleAdm_List,
  ToolBar_VehicleAdm_Trip_List,
  ToolBar_Vehicle_List,
  ToolBar_Vehicle_Trip_List,
  ToolBar_TempViolation_Trip_Report,
  ToolBar_ViolationsSpeedLimitReport_List,
  ToolBar_ViolationsSpeedLimitReport_Trip_List,
  ToolBar_WifiAdm_Trip_List,
  ToolBar_RealTimeVehicle_Trip_Report,
  ToolBar_RealTimeVehicleHistory_Trip_Report,
  ToolBar_RealTimeVehicleHistoryAdm_Trip_Report,
  ToolBar_RealTimePermissionAdm_Trip_Report,
  ToolBar_RealTimeGroupByVehiclesAdm_Trip_Report,
  ToolBar_RealTimeGroupByClientDealerAdm_Trip_Report,
  ToolBar_SuspectedAccident_Trip_Report,
  ToolBar_ViolationRankingReportSPAL_Trip_Report,
  ToolBar_VisitReport_Trip_Report,
  ToolBar_RevisionPlan_Trip_Report,
  ToolBar_RevisionStatus_Trip_Report,
  ToolBar_RevisionHistory_Trip_Report,
  ToolBar_Workshop_Trip_List,
  ToolBar_PointsOfInterest_Trip_List,
  ToolBar_UnitaryViolation_Trip_Report,
  ToolBar_ViolationByVehicle_Trip_Report,
  ToolBar_ViolationByDriver_Trip_Report,
  ToolBar_ViolationByVehicleDriver_Trip_Report,
  ToolBar_ViolationByGroup_Trip_Report,
  ToolBar_DetailedViolation_Trip_Report,
  ToolBar_Requester_List,
  ToolBar_DriversAppStatusAdm_Trip_List,
  ToolBar_GolfleetIdAppStatusAdm_Trip_List,
  ToolBar_Blockage_Report,
  ToolBar_BlockageAdm_Report,
  ToolBar_GolfleetIdPermissionHistoricAdm_Trip_List,
  ToolBar_UtilizationSimplified_Trip_List,
  // Tracking
  ToolBar_TempDriver_Tracking_List,
  ToolBar_Driver_Tracking_List,
  ToolBar_User_Tracking_List,
};
