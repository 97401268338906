import angular from 'angular';

function toBytes() {
  return (bytes = 0, precision = 1) => {
    if (bytes === 0) {
      return '0 bytes';
    }

    if (Number.isNaN(parseFloat(bytes))) {
      return '-';
    }

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const number = Math.floor(Math.log(bytes) / Math.log(1024));

    const val = (bytes / 1024 ** Math.floor(number)).toFixed(precision);

    return `${val.match(/\.0*$/) ? val.substr(0, val.indexOf('.')) : val} ${units[number]}`;
  };
}

angular.module('to-bytes', []).filter('toBytes', toBytes);
