import angular from 'angular';
import 'angular-sanitize';

import '../../directives/ng-resize/ng-resize';
import { PowerGridController } from '../power-grid/power-grid';

import template from './power-grid-alert.html';
import './power-grid-alert.scss';

class PowerGridAlertController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    this.openPopupGeneric = false;
  }

  /* Lifecycle */
  /**/

  hasLink(column, row) {
    if (!column.link) return false;
    if (column.action) return row[column.action.field].length > column.action.fieldLength;
    if (!column.linkValues || column.linkValues.length === 0) return true;

    return column.linkValues.reduce((acc, ele) => {
      const eleValue = ele.value || ele;

      const rowValue = ele.column ? row[ele.column] : row[column.field];
      if (rowValue == eleValue || (eleValue == 'notNull' && !!rowValue)) acc = true;

      return acc;
    }, false);
  }

  evtClickBtnPowerPopup(type, popupId, dataset, option) {
    this.$scope.$emit('showPopupGeneric', { type, popupId, dataset, option });
  }

  _selectRow(column, row) {
    return (
      (super._selectRow(column, row) || this.hasLink(column, row)) &&
      (!!row[column.field] || column.icons?.length > 0)
    );
  }
}

class PowerGridAlert {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = PowerGridAlertController;
  }
}

angular
  .module('power-grid-alert', ['ngSanitize', 'ng-resize'])
  .component('powerGridAlert', new PowerGridAlert());
