import angular from 'angular';

import '../power-dropdown/power-dropdown';
import '../power-single-checkbox/power-single-checkbox';
import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-share-tree.html';
import './power-share-tree.scss';

class PowerShareTreeController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $scope, $http, urlApi) {
    Object.assign(this, { $: $element[0], $scope, $http, urlApi });

    this._searchItem = '';

    this._getItemMethod = '';
    this._getTypesMethod = '';

    this.selectedType = null;
    this.selectedItemList = [];

    this.historicalData = false;
    this.isNotTrip = false;
  }

  $onInit() {
    Object.assign(this.$, {
      getTypes: this.getTypes.bind(this),
    });

    this._getTypesMethod = this.methodType == 'sharedWith' ? 'GetSharedTypes' : 'GetBelongingTypes';
    this._getItemMethod =
      this.methodType == 'sharedWith' ? 'GetSharedItens' : 'GetDirectBelongingItens';

    this.getTypes();

    this.$scope.$watch(() => this._searchItem, this.__onSearchItem.bind(this));
  }

  // #region Private
  _getItens(typeItem, getItemMethod, showLoader) {
    if (showLoader)
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

    const msgError = 'Ops! Ocorreu um erro ao carregar as informações.';
    const url = `${this.urlApi}/${typeItem.method + this._getItemMethod}`;
    const params = { typeName: typeItem.name, IncluedHistoricalData: true };

    this.$http({
      url,
      method: 'POST',
      data: { ...this.methodParam, ...params },
    })
      .then(
        success => {
          this.itemList = [];
          this.allItems = [];
          this.selectedItem = null;

          if (success.status && success.status !== 200) {
            this.$scope.$emit('showToast', { text: msgError });
          } else if (success.data && !success.data.hasError) {
            const { data } = success.data;
            this.allItems = data;
            if (typeItem.name == 'VehicleAdm') {
              if (this.historicalData) {
                this.itemList = data;
                // eslint-disable-next-line no-shadow
              } else if (data.every(data => data.isActive === null)) {
                this.isNotTrip = true;
                this.itemList = data;
              } else {
                this.itemList = data.filter(item => item.isActive == true);
              }
            } else {
              this.itemList = data;
            }
          } else
            this.$scope.$emit('showToast', {
              text: success.data && success.data.hasError ? success.data.data[0] : msgError,
            });
        },
        error => {
          console.error(error);
          this.itemList = [];
          this.selectedItem = null;
          this.$scope.$emit('showToast', { text: msgError });
        },
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _typeSelection(typeIndex) {
    this.selectedItemList = [];

    if (this.selectedType)
      this.typeList.forEach(item => {
        item.selected = false;
        return item;
      });

    this.typeList[typeIndex].selected = true;
    this.selectedType = this.typeList[typeIndex];

    if (this.selectedType.method) this._getItens(this.selectedType, this._getItemMethod, false);
  }

  getTypes() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    const msgError = 'Ops! Ocorreu um erro ao carregar as informações.';

    this.$http({
      url: `${this.urlApi}/${this.tabMethod + this._getTypesMethod}`,
      method: 'POST',
      data: { ...this.methodParam },
    }).then(
      success => {
        if (success.status && success.status !== 200) {
          this.$scope.$emit('showToast', { text: msgError });
        } else if (success.data && !success.data.hasError) {
          this.typeList = success.data.data;
          this._typeSelection(0);
        } else
          this.$scope.$emit('showToast', {
            text: success.data && success.data.hasError ? success.data.data[0] : msgError,
          });
      },
      error => {
        console.error(error);
        this.$scope.$emit('showToast', { text: msgError });
      },
    );
  }

  _itemSelection(itemReturn) {
    this.itemList.forEach(item => {
      if (item.id == itemReturn.id) {
        item.selected = !item.selected;
        if (item.selected) {
          this.selectedItemList.push(item);
        } else {
          this.selectedItemList.forEach((selectedItem, index) => {
            if (selectedItem.id == item.id) {
              this.selectedItemList.splice(index, 1);
            }
          });
        }
      }
    });
  }

  _checkAllItems() {
    this.checkAllItems = !this.checkAllItems;
    this.itemList = this.itemList.map(item => ({
      ...item,
      selected: item.description.toLowerCase().includes(this._searchItem.toLowerCase())
        ? this.checkAllItems
        : item.selected,
    }));
    this.selectedItemList = this.itemList.filter(item => item.selected);
  }

  _parseFinalDate(option) {
    return !option.isActive && option.finalDate
      ? `(até ${new Date(option.finalDate).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })})`
      : '';
  }

  _actionFunction(itemReturn) {
    const objectList = [];
    let payload = {};

    switch (itemReturn.actionType) {
      case 'share': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        const msgError = 'Ops! Ocorreu um erro na ação.';

        this.$http({
          url: `${this.urlApi}/${itemReturn.getDataMethod}`,
          method: 'POST',
          data: {},
        })
          .then(
            success => {
              if (success.status && success.status !== 200) {
                this.$scope.$emit('showToast', { text: msgError });
              } else if (success.data && !success.data.hasError) {
                this.$scope.$emit('showToast', {
                  text: success.data.data[0] || 'Ação realizada com sucesso!',
                });
              } else
                this.$scope.$emit('showToast', {
                  text: success.data && success.data.hasError ? success.data.data[0] : msgError,
                });
            },
            error => {
              console.error(error);
              this.$scope.$emit('showToast', { text: msgError });
            },
          )
          .finally(() =>
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            ),
          );
        break;
      }
      case 'edit': {
        break;
      }
      case 'delete': {
        if (this.selectedItemList.length == 0) {
          this.$scope.$emit('showToast', {
            text: 'Selecione ao menos um item para executar essa ação',
          });
          return;
        }

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        const msgError = 'Ops! Ocorreu um erro na ação.';

        const method = this.tabMethod + itemReturn.actionMethod;
        const toastText =
          this.selectedItemList.length > 1
            ? `${this.selectedItemList[0].description} e outros ${
                this.selectedItemList.length - 1
              } foram ${itemReturn.actionText}s`
            : `${this.selectedItemList[0].description} foi ${itemReturn.actionText}`;
        payload = {
          toId: this.methodParam.id,
          type: this.selectedType.name,
          objects: objectList,
        };
        this.selectedItemList.forEach(selectedItem => {
          objectList.push({
            objectId: selectedItem.id,
          });
        });

        this.$http({
          url: `${this.urlApi}/${method}`,
          method: 'POST',
          data: payload,
        }).then(
          success => {
            if (success.status && success.status !== 200)
              this.$scope.$emit('showToast', { text: msgError });
            else if (success.data && !success.data.hasError) {
              this.getTypes();
              this.$scope.$emit('stateDataRequest', {});
              this.$scope.$emit('showToast', { text: toastText });
            } else
              this.$scope.$emit('showToast', {
                text: success.data && success.data.hasError ? success.data.data[0] : msgError,
              });
          },
          error => {
            console.error(error);
            this.$scope.$emit('showToast', { text: msgError });
          },
        );
        break;
      }
      case 'historic': {
        this.historicalData = !this.historicalData;
        if (this.historicalData) this.itemList = Object.assign([], this.allItems);
        else this.itemList = this.allItems.filter(item => item.isActive !== false);
        break;
      }
      default:
        break;
    }
  }
  // #endregion Private

  // #region Observers
  __onSearchItem(newValue) {
    if (!this.itemList) return;
    const filteredList = this.itemList.filter(item =>
      item.description.toLowerCase().includes(newValue.toLowerCase()),
    );
    const selectedFilteredList = filteredList.filter(item => item.selected);
    this.checkAllItems =
      filteredList.length > 0 && selectedFilteredList.length == filteredList.length;
  }
  // #endregion Observers
}

class PowerShareTree {
  constructor() {
    this.template = template;
    this.bindings = {
      tabActive: '=',
      tabMethod: '=',
      methodType: '=',
      methodParam: '=',
      actions: '=?',
      historicalData: '=?',
    };
    this.controller = PowerShareTreeController;
  }
}

angular
  .module('power-share-tree', [
    'infinite-scroll',
    'power-dropdown',
    'power-single-checkbox',
  ])
  .component('powerShareTree', new PowerShareTree());
