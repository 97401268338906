/** @typedef {import('@power/power-components/components/power-popup-users/power-popup-users').User} User */

import angular from 'angular';
import moment from 'moment';

import { toTimeOffset, toDateOffset, getUTCDateTime } from '@power/power-components/utils/date-util.js';

import 'ng-redux';

import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-users/power-popup-users';
import '../golfleet-popup-workshop/golfleet-popup-workshop';
import '@power/power-components/components/power-switch/power-switch';
import '@power/power-components/components/power-crud-datepicker/power-crud-datepicker';
import '../golfleet-map-reverse-geocode/golfleet-map-reverse-geocode';

import template from './golfleet-form-revision-vehicle-crud.html';
import './golfleet-form-revision-vehicle-crud.scss';

export class SharedUser {
  /**
   * - Constructor
   * @param {String} icon
   * @param {Number} userId
   * @param {String} userName
   * @param {String} userEmail
   */
  constructor(icon, userId, userName, userEmail) {
    this.icon = icon;
    this.id = userId;
    this.name = userName;
    this.email = userEmail;
  }
}

// #region /* Filters */
/**
 * Validation: Value contains filter?
 * @param {String} value
 * @param {String} filter
 */
const ValueContainsFilter = (value = '', filter = '') =>
  value.toLowerCase().indexOf(filter.toLowerCase()) != -1;

/**
 * Validation: Any value contains filter?
 * @param {Array<String>} values
 * @param {String} filter
 */
const AnyValueContainsFilter = (values = [], filter = '') => {
  const result = values.map(value => ValueContainsFilter(value, filter));
  return !!result.find(item => item === true);
};

/**
 * Angular custom filter
 */
const WorkShopCustomFilter =
  () =>
  (array = [], filter = '') => {
    if (filter) {
      return array
        .filter(item => AnyValueContainsFilter([item.name, item.cnpj], filter))
        .reduce((acc, item) => acc.concat(item), []);
    }
    return array;
  };

/**
 * Item Type (Enum)
 */
export const ItemsRevisionTypeEnum = {
  REVISION: 1,
  EXCEPTION: 2,
};

class GolfleetFormRevisionVehicleCrudController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'maintenanceServices',
      'urlApi',
      'crudServices',
    ];
  }

  /**
   * Creates an instance of GolfleetFormRevisionVehicleCrudController.
   * @memberof GolfleetFormRevisionVehicleCrudController
   */
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    maintenanceServices,
    urlApi,
    crudServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      maintenanceServices,
      urlApi,
      crudServices,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        session: behavior.session,
        /* State Storage */
        state: behavior.state,
      }),
    )(this);

    this.canSubmit = true;
    this.formReady = false;
    this.showRevisionItems = false;

    this.exceptionItemObj = this._newExceptionItemObj();

    this.revisionVehicleConfig = this._getRevisionFromState();

    this.costs = [
      {
        description: 'Custo dos itens preventivos',
        field: 'costPreventiveItems',
        action: 'TROCA_PREVENTIVA',
      },
      {
        description: 'Custo dos itens corretivos',
        field: 'costFixItems',
        action: 'TROCA_CORRETIVA',
      },
      {
        description: 'Custo de mão de obra',
        field: 'labourCost',
        action: 'MAO_OBRA',
      },
      {
        description: 'Custo total',
        field: 'totalCost',
        action: 'TOTAL',
      },
    ];

    this.currentItemsPopup = null;
    this.gridItems = [
      {
        type: 1,
        title: 'Itens da revisão',
        visible: false,
        dataset: 'revisionItems',
        actionAddDeleteItem: false,
        field: 'revisionItems',
      },
      {
        type: 2,
        title: 'Itens avulsos',
        visible: true,
        dataset: 'exceptionItems',
        actionAddDeleteItem: true,
        field: 'exceptionItems',
      },
    ];

    this.dropdownOptionsItems = [
      {
        id: 1,
        titleDescription: 'Item',
        options: [],
        filteredOptions: [],
        field: 'item',
      },
      {
        id: 2,
        titleDescription: 'Serviço',
        options: [],
        filteredOptions: [],
        field: 'service',
      },
    ];

    this.editOdometerOptions = [
      // {
      //   value: 'ALLPOSITIONS',
      //   description:
      //     'Reprocessar todas as posições do veículo utilizando o novo valor de hodômetro como referência',
      //   selected: false,
      // },
      {
        value: 'ODOMETER',
        description:
          'Editar o hodômetro desta revisão sem considerar ou modificar os dados da telemetria',
        selected: true,
      },
    ];

    this.model = {};
    this.workshops = [];
    this.itemActionOptions = [];
    this.sharedUsersTemp = [];
    this.revisionDescription = null;
    this.workshopSelected = null;
    this.odometerTemp = null;
    this.initialStartDateRevision = null;
  }

  /* Lifecycle */
  $onInit() {
    this._showLoader(true);

    setTimeout(() => {
      this._getFormData();
    }, 300);

    this.$scope.$watch(
      () => this.model.startDateRevision,
      this.__startDateRevisionChanged.bind(this),
    );
  }

  $onDestroy() {
    this.__appInheritBehavior();
  }

  _createInitialRequests() {
    this._getWorkshops();
    this._getAllItems();
    this._getAllItemActions();
  }

  _getRevisionFromState() {
    const route = this.state.routeList.filter(x => x.routeLink === 'revisionVehicleForm');
    if (route[0]) {
      const { stateConfig } = route[0];
      return {
        vehicleId: stateConfig.getDataFixedParams.vehicleId,
        revisionId: stateConfig.getDataFixedParams.revisionId,
        revisionDoneId: stateConfig.getDataFixedParams.revisionDoneId,
        getDataMethod: stateConfig.getDataMethod,
      };
    }
    return null;
  }

  _getWorkshops() {
    this.maintenanceServices.callApiMethod('Workshop/GetWorkshops', {}).then(success => {
      if (success.data && success.data.hasError) return;
      if (success.data.data && success.data.data.length > 0) {
        this.workshops = success.data.data;
        if (this.workshopSelected) {
          this.workshops.forEach(workshop => {
            if (this.workshopSelected.id === workshop.id) {
              workshop.selected = true;
            }
          });
        }
      }
    });
  }

  _getAllItems() {
    this.maintenanceServices.callApiMethod('Maintenance/GetAllItems', {}).then(success => {
      if (success.data && success.data.hasError) return;
      if (success.data.data && success.data.data.length > 0) {
        this.dropdownOptionsItems.find(option => option.field === 'item').options =
          success.data.data;
      }
    });
  }

  _getAllItemActions() {
    this.maintenanceServices.callApiMethod('Maintenance/GetAllItemActions', {}).then(success => {
      if (success.data && success.data.hasError) return;
      if (success.data.data && success.data.data.length > 0) {
        this.itemActionOptions = success.data.data;
        this.dropdownOptionsItems.find(option => option.field === 'service').options = Object.clone(
          [],
          this.itemActionOptions,
        );
      }
    });
  }

  _getCosts(action, field) {
    if (this.formReady) {
      const itemAction = this.itemActionOptions.find(opt => opt.identifier === action);
      if (itemAction || action === 'TOTAL') {
        const items = [...this.model.revisionItems, ...this.model.exceptionItems]
          .filter(item => action === 'TOTAL' || itemAction.id === item.action)
          .map(item => (item.cost ? parseFloat(item.cost) : 0));

        this.model[field] = items.length > 0 ? items.reduce(this._sumCosts) : 0;
        return this.model[field].toFixed(2);
      }
    }
    return 0;
  }

  _sumCosts(total, num) {
    return total + num;
  }

  _getFormData() {
    if (this.revisionVehicleConfig) {
      const { vehicleId, revisionId, revisionDoneId, getDataMethod } = this.revisionVehicleConfig;
      const payload = {
        vehicleId,
        revisionId,
        revisionDoneId,
      };
      this.maintenanceServices
        .callApiMethod(getDataMethod, payload)
        .then(success => {
          this.$.querySelector('#golfleet-popup-revision-users').requestData();

          if (success.status && success.status !== 200) {
            this._showToast('Ocorreu um erro ao tentar recuperar as informações');
            return;
          }

          const { data } = success.data;
          this.model = data;
          this.model.startHourRevision = toTimeOffset({ date: this.model.startDateRevision });
          this.model.endHourRevision = toTimeOffset({ date: this.model.endDateRevision });
          this.model.scheduleHourRevision = toTimeOffset({ date: this.model.scheduleDateRevision });
          this.model.paymentHourRevision = toTimeOffset({ date: this.model.paymentDateRevision });
          this.revisionDescription = `Incluir itens da revisão preventiva dos ${this.model.revisionOdometerAndPeriod}`;
          this.workshopSelected = this.model.workshop;
          this.initialStartDateRevision = JSON.parse(JSON.stringify(this.model.startDateRevision));
          this.model.lastRevisionDate = toDateOffset({ date: this.model.lastRevisionDate });

          this._createInitialRequests();

          this.model.revisionItems = data.items
            .filter(item => item.typeItemRevision === ItemsRevisionTypeEnum.REVISION)
            .map(item => ({
              ...item,
              cost: item.cost ? item.cost.toFixed(2) : null,
            }));
          this.model.exceptionItems = data.items
            .filter(item => item.typeItemRevision === ItemsRevisionTypeEnum.EXCEPTION)
            .map(item => ({
              ...item,
              cost: item.cost ? item.cost.toFixed(2) : null,
            }));

          const datepickers = this.$.querySelectorAll('power-crud-datepicker');
          for (let i = 0; i < datepickers.length; i++) {
            const field = datepickers[i].getAttribute('field');
            datepickers[i].setDate(this.model[field]);
          }

          if (this.model.revisionFiles) {
            this._loadFiles(this.model.revisionFiles);
          }

          this.formReady = true;
        })
        .finally(() => this._showLoader(false));
    }
  }

  _loadFiles(files) {
    for (let i = 0, len = files.length; i < len; i++) {
      const file = {
        id: files[i].id,
        name: files[i].nome,
        extension: files[i].extensao,
        size: files[i].tamanho,
        type: files[i].tipo,
        contentBase64: files[i].conteudoBase64,
      };
      this.$.querySelector('golfleet-file-list').addFileList(file);
    }
  }

  _recoverFiles() {
    const files = this.$.querySelector('golfleet-file-list').getFileList();
    return files.map(item => ({
      id: item.id,
      nome: item.name,
      extensao: item.extension,
      tamanho: item.size,
      conteudoBase64: item.contentBase64,
      tipo: item.type,
    }));
  }

  _workshopItemClick(workshop) {
    this.workshops.forEach(item => {
      item.selected = item.id == workshop.id;
    });
  }

  _hasSelectedWorkshop() {
    return this.workshops.filter(item => item.selected).length > 0;
  }

  _getItemActionOption(item) {
    const result = this.itemActionOptions.find(option =>
      item.action ? option.id === item.action : option.selected,
    );
    if (result) {
      return result.description;
    }
    return result ? result.description : 'Selecione';
  }

  _selectItemActionOption(option, item) {
    item.action = option.id;
    item.hasCost = option.hasCost;
    if (!item.hasCost) {
      item.cost = 0;
    }
  }

  _getExceptionItemActionOption(dataset) {
    const result = dataset.find(option => option.selected);
    return result ? result.description : 'Selecione';
  }

  _selectDropdownExceptionItem(option, dataset, field) {
    this.exceptionItemObj[field] = option;
    if (field === 'service' && !option.hasCost) {
      this.exceptionItemObj.cost = 0;
    }
    dataset.forEach(opt => {
      opt.selected = opt.id === option.id;
    });
  }

  _evtClickAddExceptionItem() {
    const item = {
      id: this.exceptionItemObj.item.id,
      description: this.exceptionItemObj.item.description,
      action: this.exceptionItemObj.service ? this.exceptionItemObj.service.id : null,
      hasCost: this.exceptionItemObj.service ? this.exceptionItemObj.service.hasCost : null,
      cost: this.exceptionItemObj.cost,
      typeItemRevision: ItemsRevisionTypeEnum.EXCEPTION,
    };

    this.model.exceptionItems.push(Object.clone({}, item));

    this.dropdownOptionsItems.forEach(option => {
      option.options.forEach(opt => {
        opt.selected = false;
      });
    });

    this.exceptionItemObj = this._newExceptionItemObj();
  }

  _newExceptionItemObj() {
    return {
      service: null,
      item: null,
      cost: null,
    };
  }

  _evtClickDeleteExceptionItem(itemIndex) {
    this.model.exceptionItems.splice(itemIndex, 1);
  }

  /**
   * - Add selected items - users pop-up callback
   * @param {Array<User>} selectedItems
   */
  _addSelectedUsersPopupCallback(selectedItems) {
    selectedItems.forEach(item => {
      if (!this.sharedUsersTemp.find(user => user.id == item.id)) {
        this.sharedUsersTemp.push(new SharedUser(item.icon, item.id, item.name, item.email));
      }
    });
  }

  /**
   * - Request data - users pop-up callback
   * @param {Array<User>} userItems
   */
  _requestUsersPopupCallback(userItems) {
    this.model.sharedUsers = [];
    if (this.model.recipients) {
      userItems.forEach(item => {
        if (this.model.recipients.find(user => user == item.id)) {
          this.model.sharedUsers.push(new SharedUser(item.icon, item.id, item.name, item.email));
        }
      });
    }
  }

  /**
   * Remove user by id
   * @param {Number} id
   */
  _removeUser(id) {
    this.sharedUsersTemp = this.sharedUsersTemp.filter(user => user.id != id);
  }

  _evtClickBtnPowerPopup(type, popupId, data) {
    if (type == 'close') {
      switch (popupId) {
        case 'golfleet-popup-recipients':
          this.sharedUsersTemp = [];
          this.$.querySelector('#golfleet-popup-recipients').toggle();
          break;
        case 'golfleet-popup-select-workshop':
          this.$.querySelector('#golfleet-popup-select-workshop').toggle();
          break;
        case 'golfleet-popup-odometer':
          this.$.querySelector('#golfleet-popup-odometer').toggle();
          break;
        case 'golfleet-popup-items':
          if (this.newItemActiveView) {
            this._switchNewItemActiveView();
          } else {
            this.$.querySelector('#golfleet-popup-items').toggle();
          }
          break;

        default:
          break;
      }
    } else if (type == 'open') {
      switch (popupId) {
        case 'golfleet-popup-recipients':
          this.sharedUsersTemp = [...(this.model.sharedUsers || [])];
          this.$.querySelector('#golfleet-popup-recipients').toggle();
          break;
        case 'golfleet-popup-revision-users':
          this.$.querySelector('#golfleet-popup-revision-users').toggle();
          break;
        case 'golfleet-popup-select-workshop':
          this.$.querySelector('#golfleet-popup-select-workshop').toggle();
          break;
        case 'golfleet-popup-odometer':
          if (!this.model.isHistory) {
            this.odometerTemp = JSON.parse(JSON.stringify(this.model.revisionOdometer));
            this.$.querySelector('#golfleet-popup-odometer').toggle();
          }
          break;
        case 'golfleet-popup-items':
          this.popupItemsTitle = data.title;
          this.canAddRemoveItem = data.actionAddDeleteItem;
          this.currentItemsPopup = this.model[data.dataset];
          this.$.querySelector('#golfleet-popup-items').toggle();
          break;
        case 'golfleet-popup-add-workshop':
          this.$.querySelector('#golfleet-popup-add-workshop').setDataSet({});
          this.$.querySelector('#golfleet-popup-add-workshop').togglePopup();
          break;
        default:
          break;
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'golfleet-popup-recipients': {
          this.model.sharedUsers = [...this.sharedUsersTemp];
          this.$.querySelector('#golfleet-popup-recipients').toggle();
          break;
        }
        case 'golfleet-popup-select-workshop': {
          this.workshopSelected = this.workshops.find(workshop => workshop.selected);
          this.$.querySelector('#golfleet-popup-select-workshop').toggle();
          break;
        }
        case 'golfleet-popup-odometer':
          this.model.revisionOdometer = this.odometerTemp;
          this.odometerTemp = null;
          this.$.querySelector('#golfleet-popup-odometer').toggle();
          break;
        case 'golfleet-popup-items':
          this._evtClickAddExceptionItem();
          this._showToast('Item adicionado');
          break;
        default:
          break;
      }
    }
  }

  _selectOdometerOption(option) {
    this.editOdometerOptions.forEach(opt => {
      opt.selected = opt.value == option.value;
    });
  }

  _confirmCrudForm() {
    this._showLoader(true);

    if (this._validateFields()) {
      this._upsertRevisionVehicle();
    } else {
      this._showLoader(false);
    }
  }

  _upsertRevisionVehicle() {
    let items = this.model.activeRevisionItems ? [...this.model.revisionItems] : [];
    items = [...items, ...this.model.exceptionItems];

    const objRequest = {
      id: this.model.id,
      revisionId: this.model.revisionId,
      vehicleId: this.model.vehicleId,
      workshopId: this.workshopSelected.id,
      activeRevisionItems: this.model.activeRevisionItems,
      revisionOdometer: this.model.revisionOdometer,
      revisionPeriod: this.model.revisionPeriod,
      vehicleRevisionOdometer: this.model.vehicleOdometer,
      daysToRevision: this.model.daysToRevision,
      reasonRevision: this.model.reasonRevision,
      items,
      recipients: this.model.sharedUsers ? this.model.sharedUsers.map(user => user.id) : [],
      revisionFiles: this._recoverFiles(),
      revisionOdometerOriginal: this.model.revisionOdometerOriginal,
      revisionPeriodOriginal: this.model.revisionPeriodOriginal,
      revisionExpectedCost: this.model.expectedCost,
      isHistory: this.model.isHistory,
      startDateTimeRevision: getUTCDateTime(
        this.model.startDateRevision,
        this.model.startHourRevision,
      ),
      endDateTimeRevision: getUTCDateTime(this.model.endDateRevision, this.model.endHourRevision),
      scheduleDateTimeRevision: getUTCDateTime(
        this.model.scheduleDateRevision,
        this.model.scheduleHourRevision,
      ),
      paymentDateTimeRevision: getUTCDateTime(
        this.model.paymentDateRevision,
        this.model.paymentHourRevision,
      ),
    };

    if (!this.canSubmit) return;

    let toggleLoader = true;
    this.canSubmit = false;

    this._showLoader(true);

    this.maintenanceServices
      .callApiMethod('Maintenance/UpsertRevisionVehicle', { request: objRequest })
      .then(success => {
        const { data } = success.data;
        this.canSubmit = true;

        if (success.status && success.status !== 200) {
          this._showToast('Ocorreu um erro ao salvar a revisão');
          return;
        }

        if (data) {
          const message = `Revisão ${this.model.id ? 'atualizada' : 'cadastrada'}`;
          this._showToast(message);
          toggleLoader = false;

          setTimeout(() => {
            this._cancelCrudForm();
          }, 1500);
        }
      })
      .finally(() => {
        if (toggleLoader) {
          this._showLoader(false);
        }
      });
  }

  _validateHour(hour) {
    const regexInv = new RegExp('^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$');
    return regexInv.test(hour);
  }

  _convertDateToJson(date) {
    return new Date(new Date(date)).toJSON();
  }

  _convertDate(date) {
    return date ? moment(date)._d : null;
  }

  /* Cancel */
  _cancelCrudForm() {
    this._backTo(1);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }

  _validateFields() {
    const requiredInfo = 'Campo obrigatório';
    const invalidDate = 'Data inválida';
    const invalidHour = 'Hora inválida';
    const listValidationFields = [
      {
        id: 'start-date-revision',
        message: invalidDate,
      },
      {
        id: 'start-hour-revision',
        message: invalidHour,
      },
      {
        id: 'end-date-revision',
        message: invalidDate,
      },
      {
        id: 'end-hour-revision',
        message: invalidHour,
      },
      {
        id: 'schedule-date-revision',
        message: invalidDate,
      },
      {
        id: 'schedule-hour-revision',
        message: invalidHour,
      },
      {
        id: 'payment-date-revision',
        message: invalidDate,
      },
      {
        id: 'payment-hour-revision',
        message: invalidHour,
      },
      {
        id: 'revision-odometer',
        message: requiredInfo,
      },
    ];
    const listInvalidField = [];
    let messageToast = 'Algumas informações são obrigatórias e precisam ser preenchidas.';

    if (!this.workshopSelected) {
      this.$.querySelector('.warning-workshop').innerText = requiredInfo;
      listInvalidField.push('workshop-id');
    } else {
      this.$.querySelector('.warning-workshop').innerText = '';
    }

    if (!this.model.scheduleDateRevision && !this.model.startDateRevision) {
      listInvalidField.push('start-date-revision');
      if (!this._validateHour(this.model.startHourRevision)) {
        listInvalidField.push('start-hour-revision');
      }
    } else if (this.model.startDateRevision) {
      if (!moment(this.model.startDateRevision).isValid()) {
        listInvalidField.push('start-date-revision');
      }
      if (!this._validateHour(this.model.startHourRevision)) {
        listInvalidField.push('start-hour-revision');
      }
    }

    if (this.model.scheduleDateRevision) {
      if (!moment(this.model.scheduleDateRevision).isValid()) {
        listInvalidField.push('schedule-date-revision');
      }
      if (!this._validateHour(this.model.scheduleHourRevision)) {
        listInvalidField.push('schedule-hour-revision');
      }
    }

    if (this.model.endDateRevision) {
      if (!moment(this.model.startDateRevision).isValid()) {
        listInvalidField.push('start-date-revision');
      }
      if (!this._validateHour(this.model.startHourRevision)) {
        listInvalidField.push('start-hour-revision');
      }
      if (!moment(this.model.endDateRevision).isValid()) {
        listInvalidField.push('end-date-revision');
      }
      if (!this._validateHour(this.model.endHourRevision)) {
        listInvalidField.push('end-hour-revision');
      }
      if (!moment(this.model.paymentDateRevision).isValid()) {
        listInvalidField.push('payment-date-revision');
      }
      if (!this._validateHour(this.model.paymentHourRevision)) {
        listInvalidField.push('payment-hour-revision');
      }
      if (!this.model.revisionOdometer) {
        listInvalidField.push('revision-odometer');
      }

      // const startDateTime = getDateTime(this.model.startDateRevision, this.model.startHourRevision);
      // const endDateTime = getDateTime(this.model.endDateRevision, this.model.endHourRevision);

      const startDateTime = getUTCDateTime(this.model.startDateRevision, this.model.startHourRevision);
      const endDateTime = getUTCDateTime(this.model.endDateRevision, this.model.endHourRevision);

      if (startDateTime && endDateTime && startDateTime > endDateTime) {
        const index = listValidationFields.findIndex(obj => obj.id === 'end-date-revision');
        listValidationFields[index].message =
          'A data de saída não pode ser anterior a data de entrada';
        listInvalidField.push('end-date-revision');
      }

      const items = [
        ...(this.model.activeRevisionItems ? this.model.revisionItems : []),
        ...this.model.exceptionItems,
      ];
      if (items.length === 0) {
        messageToast =
          listInvalidField.length > 0 ? messageToast : 'Selecione ao menos um item para a revisão.';
        listInvalidField.push('item');
      } else if (items.findIndex(item => !item.action) > -1) {
        messageToast =
          listInvalidField.length > 0 ? messageToast : 'Selecione ação para todos os itens.';
        listInvalidField.push('item');
      }
    }

    listValidationFields.forEach(field => {
      this.$.querySelector(`#${field.id}`).setWarning(
        listInvalidField.includes(field.id) ? field.message : null,
      );
    });

    if (listInvalidField.length > 0) {
      this._showToast(messageToast);
    }

    return listInvalidField.length === 0;
  }

  /**
   * Add new item
   */
  _switchNewItemActiveView() {
    this.newItemActiveView = !this.newItemActiveView;
  }

  _showGridItems(grid) {
    if (!this.model.isHistory) {
      return grid.visible || this.model.activeRevisionItems;
    }
    return this.model[grid.dataset].length > 0;
  }

  _showToast(message) {
    Object.assign(this, { toast: { text: message } });
    this.$.querySelector('power-toast#form-revision-vehicle-toast').toggle(true);
  }

  _showLoader(show) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: show },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __startDateRevisionChanged() {
    if (
      this.formReady &&
      this.initialStartDateRevision !== this.model.startDateRevision &&
      !this.model.isHistory &&
      moment(this.model.startDateRevision).isValid()
    ) {
      const { startDateRevision, vehicleId } = this.model;
      this.initialStartDateRevision = startDateRevision;
      const payload = {
        date: this._convertDateToJson(startDateRevision),
        vehicleId,
      };
      this.maintenanceServices
        .callApiMethod('Maintenance/GetOdometerByDate', payload)
        .then(success => {
          if (success.status && success.status !== 200) return;
          if (success.data.data) {
            this.model.revisionOdometer = success.data.data;
          }
        });
    }
  }

  __onSavedWorkshop() {
    this._getWorkshops();
  }
}

class GolfleetFormRevisionVehicleCrud {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormRevisionVehicleCrudController;
  }
}

angular
  .module('golfleet-form-revision-vehicle-crud', [
    'ngRedux',
    'power-toast',
    'power-popup',
    'power-popup-users',
    'golfleet-popup-workshop',
    'power-switch',
    'power-crud-datepicker',
    'golfleet-map-reverse-geocode',
    'ng-tippy',
  ])
  .filter('workShopCustomFilter', WorkShopCustomFilter)
  .component('golfleetFormRevisionVehicleCrud', new GolfleetFormRevisionVehicleCrud());
