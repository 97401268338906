/* eslint new-cap: ["error", { "newIsCap": false }] */
import 'angular-drag-and-drop-lists';

import '../power-fab/power-fab';
import '../power-toolbar-report/power-toolbar-report';
import '../power-dropdown/power-dropdown';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-crud/power-crud';
import '../power-popup/power-popup';
import '../power-popup-share/power-popup-share';
import '../power-popup-delete/power-popup-delete';
import '../power-toast/power-toast';
import '../power-grid-fleet-policy-status/power-grid-fleet-policy-status';
import '../power-popup-move-vehicles/power-popup-move-vehicles';
import { PowerReportController } from '../power-report/power-report';
import { ReportStateConfig } from '../power-state-config/power-state-config';

class PowerReportFleetPolicyStatusController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  /**
   * Creates an instance of ReportFleetPolicyStatusController.
   * @memberof ReportFleetPolicyStatusController
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    const checkIsAdm = behavior =>
      behavior.state.routeList.length > 0
        ? behavior.state.routeList[behavior.state.routeList.length - 1].stateConfig?.isAdm
        : false;

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isAdm: checkIsAdm(behavior),
      }),
    )(this);

    this.fleetPolicies = [];

    this.tabs = [
      { name: 'hours', label: 'Horário', selected: true },
      { name: 'rules', label: 'Regras' },
    ];

    this.fleetPolicyData = {};
  }

  /* Private */
  _getStateConfig() {
    super._getStateConfig().then(() => {
      if (this.stateConfig.getDataFilters && this.stateConfig.getDataFilters.length > 0) {
        this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
          const fixedFilters = this.stateConfig.getDataFilters.filter(
            condition =>
              condition.id == actualFilter.id ||
              (!!actualFilter.field && condition.field == actualFilter.field),
          )[0];

          if (actualFilter.type === 'compound') {
            return fixedFilters
              ? {
                  ...actualFilter,
                  activeView: fixedFilters.activeView || actualFilter.activeView,
                }
              : actualFilter;
          }

          return actualFilter;
        });
      }
    });
  }

  _getSelectedTab() {
    return this.tabs.filter(item => item.selected)[0].name;
  }

  _selectTab(tab) {
    this.tabs.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  _generateFleetPolicyHistoricDescription() {
    const { updateUserName } = this.fleetPolicyData;
    const updateDate = new Date(this.fleetPolicyData.updateDate).toLocaleDateString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
    });

    let description;

    if (updateUserName && updateDate) {
      description = `Política de frota modificada por ${updateUserName} em ${updateDate}`;
    } else if (updateUserName && !updateDate) {
      description = `Política de frota modificada por ${updateUserName}`;
    } else if (!updateUserName && updateDate) {
      description = `Política de frota modificada em ${updateDate}`;
    }

    return description;
  }

  _callAction(action) {
    let scrollWorkaround;
    switch (action.actionType) {
      case 'add':
        /* Chrome Locked-Scroll Workaround */
        scrollWorkaround = () => {
          const scrollArea = this.$.querySelector('#popup-add .div-section-crud');
          setTimeout(() => {
            scrollArea.style.overflow = 'auto';
          }, 500);
          setTimeout(() => {
            scrollArea.style.overflow = '';
          }, 600);
        };

        this.stateConfig.toolbarConfig.addConfig.dataset = null;
        this.$.querySelector('#popup-add').toggle();

        if (this.$.querySelector('#popup-add')) {
          scrollWorkaround();
        }
        break;
      case 'share':
        /* Chrome Locked-Scroll Workaround */
        scrollWorkaround = () => {
          const vehiclesList = this.$.querySelector('power-popup-share .popup-share-content-list');
          setTimeout(() => {
            vehiclesList.style.overflowY = 'hidden';
          }, 300);
          setTimeout(() => {
            vehiclesList.style.overflowY = 'auto';
          }, 400);
        };

        if (this.$.querySelector('power-popup-share')) {
          scrollWorkaround();
        }

        this.$.querySelector('power-popup-share').setData({
          type: this.stateConfig.type,
          getMethod: action.getDataMethod,
          shareMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({ objectId: row.id })),
        });
        this.$.querySelector('power-popup-share').toggle();
        break;
      case 'move':
        // eslint-disable-next-line no-case-declarations
        const selectedRowsKeys = Object.keys(this.selectedRows);

        this.cantMoveObjectList = [];
        this.moveObjectList = [];

        selectedRowsKeys.reduce((acc, item) => {
          if (!this.selectedRows[item][action.validationField]) {
            this.cantMoveObjectList.push(this.selectedRows[item]);
          } else {
            this.moveObjectList.push(this.selectedRows[item]);
          }

          return acc;
        }, []);

        this.$.querySelector('power-popup-move-vehicles').setData({
          type: this.stateConfig.type,
          title: action.actionTitle,
          showPreviewItem: action.showPreviewItem,
          actionIcon: action.actionIcon,
          getMethod: action.getDataMethod,
          moveMethod: action.actionMethod,
          cantMoveTitle: action.cantMoveTitle.split('<item>').join(this.cantMoveObjectList.length),
          cantMoveList: this.cantMoveObjectList.map(row => ({
            description: row.vehiclePlateAndAlias,
            icon: row.icon || 'directions_car',
          })),
          objects: this.moveObjectList.map(row => ({ objectId: row.vehicleId })),
        });

        this.$.querySelector('power-popup-move-vehicles').toggle();
        break;
      case 'edit':
        break;
      case 'delete':
        this.$.querySelector('power-popup-delete').setData({
          deleteMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({
            id: row.id,
            description: row[this.stateConfig.gridConfig.descriptionField],
            value: row.id,
          })),
        });
        this.$.querySelector('power-popup-delete').toggle();
        break;
      default:
        break;
    }
  }

  _getFleetPolicyIconTitle(status, amount) {
    let title = '';

    switch (status) {
      case 'broadcasting':
        title = `${amount} itens em processo de atualização`;
        break;

      case 'notHired':
        title = `${amount} itens de serviço não contratado`;
        break;

      case 'incompatible':
        title = `${amount} itens incompatíveis com uma ou mais regras`;
        break;

      default:
        break;
    }

    return title;
  }

  _showGlobalLoader(state = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: state },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _drilldownIcons(id, type = [], config = []) {
    const stateConfig = new ReportStateConfig({
      type: 'FleetPolicyStatus',
      isAdm: this.isAdm,
      viewMode: 'grid',
      gridName: 'List',
      screenName: `FleetPolicyStatusAdm`,
      toolbarName: 'List',
      getDataMethod: 'FleetPolicyAdm/GetFleetPolicyVehicles',
      backPagination: false,
      getDataFilters: [
        { id: 1, default: [id] },
        { id: 2, default: [] },
        {
          id: 3,
          activeView: 'secondary',
          field: { primary: 'fleetPolicyItemStatus', secondary: 'fleetPolicyItemConfigs' },
          default: [type, config],
        },
      ],
    });

    this.$ngRedux.dispatch({ type: 'CLEAR_ROUTE_LIST' });
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE_SINGLE',
      data: {
        routeName: 'Status de Regra por Veículo',
        routeLink: `fleetPolicyStatusAdm`,
        routeTail: null,
        stateConfig,
      },
    });

    this.$state.go(`fleetPolicyStatusAdm`, {}, { reload: true });
  }

  _getFleetPolicyData(fleetPolicy) {
    const { id } = fleetPolicy;

    this.fleetPolicyData = {};

    if (id) {
      this._showGlobalLoader(true);

      this.$http({
        url: `${this.urlApi}/FleetPolicy${this.isAdm ? 'Adm' : ''}/GetFleetPolicyDetail`,
        method: 'POST',
        data: {
          request: {
            id,
          },
        },
      }).then(success => {
        const { data } = success.data;
        let period = {};

        const items = data.items.reduce((acc, item) => {
          if (item.objectId == 'period') {
            item.items = item.value.split(',');
            period = item;
          } else {
            acc.push(item);
          }

          return acc;
        }, []);

        this.fleetPolicyData = {
          id,
          name: data.name,
          broadcastingItemsCount: data.broadcastingItemsCount,
          incompatibleItemsCount: data.incompatibleItemsCount,
          notHiredItemsCount: data.notHiredItemsCount,
          totalVehicles: data.totalVehicles,
          period,
          items,
          updateDate: data.updateDate,
          updateUserName: data.updateUserName,
        };

        if (success.status && success.status !== 200) {
          this.$.querySelector('power-toast#report-toast').toggle(true);
          Object.assign(this, { toastText: data.text });
          return;
        }

        if (this.fleetPolicyData.name) {
          this._showGlobalLoader(false);
          this._eventModalReadOnlyFleetPoliciesSwitch();
        }
      });
    }
  }

  _eventModalReadOnlyFleetPoliciesSwitch() {
    this.$.querySelector(
      'power-report-fleet-policy-status > #popup-readonly-fleet-policies',
    ).toggle();
  }
  /* End Private */
}

export { PowerReportFleetPolicyStatusController };
