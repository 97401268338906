import angular from 'angular';

import '../power-popup/power-popup';

import template from './power-popup-list.html';
import './power-popup-list.scss';

class PowerPopupListController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
    this.listItems = [];
    this.searchText = '';
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      setListItems: this.setListItems.bind(this),
      toggle: this.toggle.bind(this),
    });
  }

  $onDestroy() {}
  // #endregion Lifecycle

  // #region Public
  toggle() {
    this.$.querySelector('#power-popup-list').toggle();
  }

  setListItems(items) {
    this.listItems = items;
  }
  // #endregion Public

  // #region Private
  _itemClick(item) {
    this.onItemClick({ item });
    this.toggle();
  }
  // #endregion Private
}

class PowerPopupList {
  constructor() {
    this.template = template;
    this.bindings = {
      popupIcon: '=',
      popupTitle: '=',
      onItemClick: '&',
    };
    this.controller = PowerPopupListController;
  }
}

angular
  .module('power-popup-list', ['power-popup'])
  .component('powerPopupList', new PowerPopupList());
