import angular from 'angular';

import '../power-popup/power-popup';

import template from './power-popup-users.html';
import './power-popup-users.scss';

/**
 * User Item
 */
export class User {
  /**
   * - Constructor
   * @param {Number} id
   * @param {String} description
   * @param {String} icon
   * @param {Boolean} selected
   */
  constructor(id, name, email, icon, selected = false) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.icon = icon;
    this.selected = selected;
  }
}

/**
 * User Functionality (Enum)
 */
export const UserFunctionalityEnum = {
  ADMIN: 'ADMINISTRATOR',
  COMMON: 'LOGIN',
  EMAIL: 'RECEIVE_NOTIFICATION',
};

// #region /* Filters */
/**
 * Validation: Value contains filter?
 * @param {String} value
 * @param {String} filter
 */
const ValueContainsFilter = (value = '', filter = '') =>
  value.toLowerCase().indexOf(filter.toLowerCase()) != -1;

/**
 * Validation: Any value contains filter?
 * @param {Array<String>} values
 * @param {String} filter
 */
const AnyValueContainsFilter = (values = [], filter = '') => {
  const result = values.map(value => ValueContainsFilter(value, filter));
  return !!result.find(item => item === true);
};

/**
 * Angular custom filter
 */
const UserCustomFilter =
  () =>
  (array = [], filter = '') => {
    if (filter) {
      return array
        .filter(item => AnyValueContainsFilter([item.name, item.email], filter))
        .reduce((acc, item) => acc.concat(item), []);
    }
    return array;
  };
// #endregion

/**
 * - Component Controller
 */
export class PowerPopupUsersController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', 'userServices'];
  }

  /**
   * - Constructor
   */
  constructor($element, $scope, $ngRedux, userServices) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      userServices,
    });
    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
      }),
    )(this);
    this.users = [];
    this.initialNewEmailText =
      'Para incluir multiplos destinatários, coloque os emails separados por vírgula ou ponto e vírgula.';
    this.newEmailText = null;
    this.textareaNewEmailValue = '';
    this.newEmailSuccessIcon = false;
  }

  // #region /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      requestData: this.requestData.bind(this),
    });

    if (this.requestDataOnInit) {
      this.requestData();
    }
  }

  $onDestroy() {
    this.__appInheritBehavior();
  }
  // #endregion

  // #region /* Public */
  /**
   * Open / Close users pop-up
   */
  toggle() {
    if (this.$.querySelector('#power-popup-users').getAttribute('open') == null) {
      const userSelectedIds = this.selectedItems ? this.selectedItems.map(user => user.userId) : [];
      this.users = this.users.map(user => ({
        ...user,
        selected: !!userSelectedIds.includes(user.id),
      }));
      this.$.querySelector('#power-popup-users').toggle();
      this._scrollWorkaround('#power-popup-users .power-popup-users-list');
    } else {
      this.$.querySelector('#power-popup-users').toggle();
    }
  }

  /**
   * - Load users request
   */
  async requestData() {
    const result = [];
    const userFunctionality = [];

    if (this.userFunctionalities) {
      this.userFunctionalities.split(this.userFunctionalitiesSeparator || ' ').forEach(item => {
        const functionality = this.userFunctionalitiesIgnoreCase ? item.toUpperCase() : item;

        // ADMIN COMMON EMAIL
        if (functionality in UserFunctionalityEnum) {
          userFunctionality.push(UserFunctionalityEnum[functionality]);
        }
      });
    }

    const response = await this.userServices.GetAllUsers({ request: { userFunctionality } });

    const { data } = response.data;

    if (data && data.users && data.users.length > 0) {
      response.data.data.users.forEach(item => {
        result.push(new User(item.id, item.name, item.email, item.icon));
      });

      if (this.requestDataOnInitCallback) {
        this.requestDataOnInitCallback({ data: result });
      }
    }

    this.users = result;
    return result;
  }
  // #endregion

  // #region /* Private */
  /**
   * Select user
   * @param {User} user
   */
  _userItemClick(user) {
    this.users
      .filter(item => item.id == user.id)
      .forEach(item => {
        item.selected = !item.selected;
      });
    if (!this.multiSelect) {
      this.users
        .filter(item => item.selected && item.id != user.id)
        .forEach(item => {
          item.selected = false;
        });
    }
  }

  _hasSelectedUsers() {
    return this.users.filter(item => item.selected).length > 0;
  }

  /**
   * Add selected users
   */
  _addUsers() {
    if (this.confirmCallback) {
      this.confirmCallback({
        data: this.users.filter(user => user.selected),
      });
    }
    this.$.querySelector('#power-popup-users').toggle();
  }

  _resizeNewEmailsTextArea() {
    const textAreaElement = this.$.querySelector('#power-add-new-email');
    const characters =
      this.textareaNewEmailValue.length > 45
        ? this.textareaNewEmailValue.length - 1
        : this.textareaNewEmailValue.length;
    let height = parseInt(characters / 45 + 1, 10) * 15;

    if (characters > 405 && textAreaElement.style.height !== '18px') {
      return;
    }

    if (characters > 405) {
      height = parseInt(405 / 45 + 1, 10) * 15;
    }

    if (characters > 45) {
      if (textAreaElement.style.height != `${height}px`) {
        textAreaElement.style.height = `${height}px`;
      }
    } else if (textAreaElement.style.height != '18px') {
      textAreaElement.style.height = '18px';
    }
  }

  /**
   * Add new e-mail
   */
  _switchNewEmailActiveView() {
    this.newEmailActiveView = !this.newEmailActiveView;

    if (this.newEmailActiveView) {
      this._resizeNewEmailsTextArea();

      setTimeout(() => {
        this.$.querySelector('#power-add-new-email').focus();
      }, 300);
    } else {
      this.newEmailText = null;
      this.newEmailSuccessIcon = false;
      this.textareaNewEmailValue = '';
    }
  }

  _addNewMail() {
    const regexInv = new RegExp(
      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.{1})+[a-z0-9](?:\.?[a-z0-9-]*[a-z0-9])$/,
    );
    const emailsValue = this.textareaNewEmailValue
      .replace(/[ ;]+/g, ',')
      .split(/[ ,]+/)
      .map(item => item.trim())
      .filter(item => item)
      .filter((item, index, self) => item && self.indexOf(item) == index);

    this.textareaNewEmailValue = emailsValue.join(', ');

    const allEmails = emailsValue.reduce(
      (acc, email) => {
        const splittedMail = email.split(/(@)/);
        if (
          !email.includes('@') ||
          !regexInv.test(email) ||
          (!splittedMail[2] && splittedMail[2] == '')
        ) {
          acc.invalidEmails = acc.invalidEmails.concat(email);
        } else {
          acc.validEmails = acc.validEmails.concat(email);
        }
        return acc;
      },
      { validEmails: [], invalidEmails: [] },
    );

    if (allEmails.invalidEmails.length > 0) {
      this.hasInvalidEmails = true;
      this.textareaNewEmailValue = allEmails.invalidEmails.join().replace(',', ', ');
    } else {
      this.hasInvalidEmails = false;
    }

    this._resizeNewEmailsTextArea();

    if (allEmails.validEmails.length > 0) {
      this.userServices
        .CreateUserEmails({
          request: {
            emails: allEmails.validEmails,
          },
        })
        .then(success => {
          const emailsCount = allEmails.validEmails.length;
          if (!success.data || success.data.hasError) {
            this.newEmailText = 'Ops! Falha ao salvar, tente novamente.';
            this.newEmailSuccessIcon = false;
            return; // error
          }

          if (allEmails.invalidEmails.length == 0) {
            this.textareaNewEmailValue = '';
            this.newEmailText = `${
              emailsCount > 1 ? 'Foram incluídos' : 'Foi incluído'
            } <b>${emailsCount} ${
              emailsCount > 1 ? 'emails' : 'email'
            }</b> na listagem de destinatários.`;
          } else if (allEmails.invalidEmails.length > 0) {
            this.newEmailText = `${
              emailsCount > 1 ? 'Foram incluídos' : 'Foi incluído'
            } <b>${emailsCount} ${
              emailsCount > 1 ? 'emails' : 'email'
            }</b> na listagem de destinatários. Os endereços acima não puderam ser cadastrados, por favor corrija os e-mails e tente novamente.`;
          }
          this.requestData();
          this.newEmailSuccessIcon = true;
        });
    } else {
      this.newEmailText =
        'Não há emails válidos a serem cadastrados, por favor verifique o campo de email e tente novamente';
    }
  }

  _emailInfo() {
    return this.newEmailText == null ? this.initialNewEmailText : this.newEmailText;
  }

  /**
   * Chrome Locked-Scroll Workaround
   * @param {String} selector
   */
  _scrollWorkaround(selector) {
    const element = this.$.querySelector(selector);
    setTimeout(() => {
      element.style.overflowY = 'hidden';
    }, 1100);
    setTimeout(() => {
      element.style.overflowY = 'auto';
    }, 1200);
  }
  // #endregion
}

/**
 * Component Class
 */
export class PowerPopupUsers {
  constructor() {
    this.template = template;
    this.bindings = {
      headerDescription: '=?',
      userFunctionalities: '=?',
      userFunctionalitiesSeparator: '=?',
      selectedItems: '=?',
      userFunctionalitiesIgnoreCase: '<',
      requestDataOnInit: '<',
      showButtonNewEmail: '<',
      multiSelect: '<',

      /* Callbacks */
      openPopupCallback: '&?',
      confirmCallback: '&?',
      requestDataOnInitCallback: '&?',
    };
    this.controller = PowerPopupUsersController;
  }
}

angular
  .module('power-popup-users', ['power-popup'])
  .filter('userCustomFilter', UserCustomFilter)
  .component('powerPopupUsers', new PowerPopupUsers());
