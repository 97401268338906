import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import { toDateTimeInput, toDate } from '@power/power-components/utils/date-util.js';
import { RoutesConfig } from '../../configs/routes/routes.config';

import template from './golfleet-card-announcement.html';
import './golfleet-card-announcement.scss';

class GolfleetCardAnnouncementController {
  static get $inject() {
    return ['$element', '$scope', '$state', '$ngRedux', '$timeout', 'reportServices'];
  }

  constructor($element, $scope, $state, $ngRedux, $timeout, reportServices) {
    Object.assign(this, {
      $: $element,
      $scope,
      $state,
      $ngRedux,
      $timeout,
      reportServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        lastState:
          behavior.state.routeList.length > 1
            ? behavior.state.routeList[behavior.state.routeList.length - 2]
            : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.toastComponent = {
      open: false,
      text: '',
    };
  }

  evtClickSwitchButtonControl(card) {
    card.updatingAnnouncement = true;

    this.reportServices
      .genericRequest(`/Announcement/UpsertAnnouncements`, {
        request: {
          id: card.id,
          active: card.active,
        },
      })
      .then(
        success => {
          card.updatingAnnouncement = false;
          if ((success.data && success.data.hasError) || !success.data.data) {
            this.$scope.$emit('showToast', {
              text: 'Ops! Falha ao salvar, tente novamente.',
            });
            card.active = !card.active; // Volta ao status anterior
            return;
          }

          if (success.data.data) {
            this.$scope.$emit('showToast', { text: 'Comunicado atualizado!' });
          }
        },
        error => {
          console.error(error);
          card.updatingAnnouncement = false;
          this.$scope.$emit('showToast', {
            text: 'Ops! Falha ao salvar, tente novamente.',
          });
          card.active = !card.active; // Volta ao status anterior
        },
      );
  }

  _parseDate(date) {
    return toDateTimeInput({ date });
  }

  _goToLink(index, tableRowData) {
    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: 'Comunicado',
        routeSubName: 'Edição',
        routeLink: 'announcementForm',
        routeTail: tableRowData.id,
        stateConfig: {
          ...this.stateConfig,
          getDataMethod: 'Announcement/GetAnnouncementById',
          gridName: 'Formulário',
          filterConfig: [],
          getDataFixedParams: { id: tableRowData.id },
        },
      },
    });

    this.$state.go('announcementForm', { tail: tableRowData.id });
  }

  _isExpired(date) {
    const today = new Date();
    return toDate(date) < today;
  }

  _hasExpiration(date) {
    const expirationYear = toDate(date).getFullYear();
    return expirationYear < 2099;
  }

  _switchEnable(card) {
    return (card.announcementtype == 3 && card.itemsCount > 0) || card.announcementtype != 3;
  }

  _getRouteDescription(routeName) {
    const route = RoutesConfig[routeName];
    let description = '';

    if (route?.data && route.data.routeLink && route.data.routeName) {
      description =
        route.data.routeLink.toUpperCase().includes('ADM') &&
        !route.data.routeName.toUpperCase().includes('ADM')
          ? `${route.data.routeName} Adm`
          : route.data.routeName;
      if (
        !route.data.routeLink.toUpperCase().includes('TEMP') &&
        !route.data.routeLink.toUpperCase().includes('FORM') &&
        !route.data.routeLink.toUpperCase().includes('INFOADDON') &&
        !route.data.routeLink.toUpperCase().includes('TRAVELSBYUTILIZATION')
      ) {
        description = route.data.routeSubName ? route.data.routeSubName : description;
      }
    }
    return description;
  }
}

class GolfleetCardAnnouncement {
  constructor() {
    this.template = template;
    this.bindings = {
      cardDataset: '=',
    };
    this.controller = GolfleetCardAnnouncementController;
  }
}

angular
  .module('golfleet-card-announcement', ['ngRedux', 'power-toast', 'ng-tippy'])
  .component('golfleetCardAnnouncement', new GolfleetCardAnnouncement());
