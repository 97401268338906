import angular from 'angular';

import '../power-crud-section/power-crud-section';

import template from './power-crud.html';
import './power-crud.scss';
import { toUTCDate } from '../../utils/date-util';

class PowerDropdownItem {
  /**
   * Creates an instance of PowerDropdownItem.
   * @param {Number} id
   * @param {String} description
   * @param {Boolean} selected
   * @param {any} value
   * @memberof PowerDropdownItem
   */
  constructor(id, description, selected, value) {
    this.id = id;
    this.description = description;
    this.selected = selected;
    this.value = value;
  }
}

class PowerCrudController {
  static get $inject() {
    return ['$element', '$scope', 'crudServices'];
  }

  constructor($element, $scope, crudServices) {
    Object.assign(this, { $: $element[0], $scope, crudServices });

    this.dataset = null;
    this.autoClear = true;
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      clearKeys: this._clearKeys.bind(this),
    });
    this.dataset = Object.clone({}, this.crudDataset);

    if (this.crudConfig && this.crudConfig.lenght > 0) this._prerenderSections();

    this.$scope.$on('triggerRequest', (event, eventObject) =>
      this._callTrigger(
        eventObject.triggerKey,
        eventObject.triggerOf,
        eventObject.triggerValue,
        eventObject.triggerCondition,
      ),
    );

    this.$scope.$watch(() => this.crudDataset, this._crudDatasetChanged.bind(this));
  }
  // #endregion Lifecycle

  // #region Public
  cancelActionButton() {
    this._clearKeys();
    this.crudConfig.open = false;
    this.cancelAction({});
  }

  confirmActionButton() {
    const confirmButton = this.crudConfig.buttons[1];
    this._createRequestObject(payload => {
      if (!payload) return;
      if (this.confirmAction) {
        this.confirmAction({ method: confirmButton.method, payload });
        if (!this.autoClear) {
          return;
        }
      } else {
        this.crudServices.callApiMethod(confirmButton.method, payload).then(() => {
          this.$rootScope.gsLoader = false;
          this.crudConfig.open = false;
        });
      }
      this._clearKeys();
    });
  }
  // #endregion Public

  // #region Private
  _requestObjectRecursion(
    payload,
    validationError,
    sectionsLength,
    sectionIndex,
    horizontalIndex,
    verticalIndex,
    callback,
  ) {
    const element = this.crudConfig.sections[sectionIndex][horizontalIndex][verticalIndex];

    const horizontalGroupLength = this.crudConfig.sections[sectionIndex].length;

    const verticalGroupLength = this.crudConfig.sections[sectionIndex][horizontalIndex].length;
    if (element.validation) {
      this.crudServices
        .callApiMethod(element.validation, {
          field: element.key,
          value: element.value,
        })
        .then(
          result => {
            if (element.required && !element.value && element.value !== 0) {
              element.warning = 'Campo obrigatório';
              validationError = true;
            } else if (result.data.hasError) {
              element.warning =
                (Array.isArray(result.data.data) ? result.data.data[0] : result.data.data) ||
                'Valor incorreto';
              validationError = true;
            } else element.warning = null;

            if (element.type == 'TextBox') {
              if (element.inputType == 'date') {
                const dateStringArr = element.value.split('/');
                element.value = new Date(
                  `${dateStringArr[1]}/${dateStringArr[0]}/${dateStringArr[2]}`,
                );
              } else payload[element.key] = element.value;
            }
            if (element.type == 'ComboBox') {
              if (element.value || element.value === 0) payload[element.key] = element.value;
              else payload[element.key] = null;
            }
            if (element.type == 'ImageInput') {
              if (element.value) payload[element.key] = element.value;
              else payload[element.key] = null;
            }

            if (verticalIndex + 1 <= verticalGroupLength - 1)
              this._requestObjectRecursion(
                payload,
                validationError,
                sectionsLength,
                sectionIndex,
                horizontalIndex,
                verticalIndex + 1,
                callback,
              );
            else if (horizontalIndex + 1 <= horizontalGroupLength - 1)
              this._requestObjectRecursion(
                payload,
                validationError,
                sectionsLength,
                sectionIndex,
                horizontalIndex + 1,
                0,
                callback,
              );
            else if (sectionIndex + 1 <= sectionsLength - 1)
              this._requestObjectRecursion(
                payload,
                validationError,
                sectionsLength,
                sectionIndex + 1,
                0,
                0,
                callback,
              );
            else callback({ payload, validationError });
          },
          () => {
            element.warning = 'Valor incorreto';
            validationError = true;
          },
        );
    } else {
      if (element.required && !element.value && element.value !== 0 && element.value !== false) {
        element.warning = 'Campo obrigatório';
        validationError = true;

        if (this.errorAction) {
          this.errorAction();
        }
      } else element.warning = null;

      if (element.type == 'TextBox') {
        if (element.inputType == 'date') {
          const dateStringArr = element.value.split('/');
          element.value = new Date(`${dateStringArr[1]}/${dateStringArr[0]}/${dateStringArr[2]}`);
        } else if (element.inputType == 'datetime') {
          payload[element.key] = toUTCDate({ date: element.value });
        } else {
          payload[element.key] = element.value;
        }
      }
      if (element.type == 'ComboBox') {
        if (element.value || element.value === 0) payload[element.key] = element.value;
        else payload[element.key] = null;
      }
      if (element.type == 'ComboBoxMultiSelect') {
        if (element.value && element.value.length > 0) payload[element.key] = element.value;
        else payload[element.key] = null;
      }
      if (element.type == 'Map') {
        if (!element.value) payload[element.key] = null;
        else if (element.value[0].properties.radius) {
          payload[element.key] = element.value[0].geometry;
          payload.radius = parseInt(element.value[0].properties.radius, 10);
        } else payload[element.key] = element.value[0].geometry;
      }
      if (element.type == 'ImageInput') {
        if (element.value) payload[element.key] = element.value;
        else payload[element.key] = null;
      }

      if (verticalIndex + 1 <= verticalGroupLength - 1)
        this._requestObjectRecursion(
          payload,
          validationError,
          sectionsLength,
          sectionIndex,
          horizontalIndex,
          verticalIndex + 1,
          callback,
        );
      else if (horizontalIndex + 1 <= horizontalGroupLength - 1)
        this._requestObjectRecursion(
          payload,
          validationError,
          sectionsLength,
          sectionIndex,
          horizontalIndex + 1,
          0,
          callback,
        );
      else if (sectionIndex + 1 <= sectionsLength - 1)
        this._requestObjectRecursion(
          payload,
          validationError,
          sectionsLength,
          sectionIndex + 1,
          0,
          0,
          callback,
        );
      else callback({ payload, validationError });
    }
  }

  _createRequestObject(callback) {
    const payload = {};

    const validationError = false;

    const sectionsLength = this.crudConfig.sections.length;
    this._requestObjectRecursion(
      payload,
      validationError,
      sectionsLength,
      0,
      0,
      0,
      requestObject => {
        requestObject.payload.id =
          requestObject.payload[requestObject.payload.idIs] || requestObject.payload.id;
        if (typeof callback === 'function') {
          if (requestObject.validationError) callback(null);
          else callback(Object.clone({}, requestObject.payload));
        }
      },
    );
  }

  _clearKeys() {
    this.crudConfig.sections.forEach(horizontalGroup => {
      horizontalGroup.forEach(verticalGroup => {
        verticalGroup.forEach(groupItem => {
          if (groupItem.options && groupItem.options.length > 0) {
            groupItem.options.forEach(item => {
              item.selected = false;
            });
          }
          groupItem.value = null;
          if (groupItem.warning) groupItem.warning = null;
        });
      });
    });
  }

  _prerenderSections() {
    const sectionsLength = this.crudConfig.sections.length;
    for (let si = sectionsLength - 1; si >= 0; si -= 1) {
      const horizontalGroupLength = this.crudConfig.sections[si].length;
      for (let hi = horizontalGroupLength - 1; hi >= 0; hi -= 1) {
        const _horizontalGroupLength = this.crudConfig.sections[si][hi].length;
        for (let vi = _horizontalGroupLength - 1; vi >= 0; vi -= 1) {
          if (
            this.crudConfig.sections[si][hi][vi].hideCondition &&
            !this.dataset[this.crudConfig.sections[si][hi][vi].hideCondition]
          )
            this.crudConfig.sections[si][hi].splice(vi, 1);
        }
        if (this.crudConfig.sections[si][hi].length === 0)
          this.crudConfig.sections[si].splice(hi, 1);
      }
      if (this.crudConfig.sections[si].length === 0) this.crudConfig.sections.splice(si, 1);
    }
  }

  _callTrigger(triggerKey, triggerOf, triggerValue, triggerCondition) {
    if (!this.crudConfig) return;
    this.crudConfig.sections.forEach(horizontalGroup => {
      horizontalGroup.forEach(verticalGroup => {
        verticalGroup.forEach(groupItem => {
          if (groupItem.key == triggerOf) {
            if (!triggerCondition || (triggerCondition && triggerValue == triggerCondition)) {
              if (groupItem.required === false) groupItem.required = true;
              if (groupItem.type == 'ComboBox') {
                const payload = { ...groupItem.getDataParams };
                if (this.dataset) {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const attr in payload) {
                    if (payload[attr] == triggerKey) payload[attr] = triggerValue;
                    else payload[attr] = this.dataset[payload[attr]];
                  }
                }
                this.crudServices.getData(groupItem.getDataMethod, payload).then(result => {
                  if (result.status && result.status != 200) return;
                  let foundDefault = false;
                  groupItem.options = [];
                  result.forEach(item => {
                    if (groupItem.value && item.id == groupItem.value.id) {
                      foundDefault = true;
                      groupItem.value = new PowerDropdownItem(
                        item.id,
                        item.description,
                        true,
                        item.value,
                      );
                      groupItem.options.push(
                        new PowerDropdownItem(item.id, item.description, true, item.value),
                      );
                    } else
                      groupItem.options.push(
                        new PowerDropdownItem(item.id, item.description, false, item.value),
                      );
                  });
                  if (groupItem.options.length > 0) groupItem.disabled = false;
                  if (!foundDefault) {
                    groupItem.value = null;
                    this.dataset[groupItem.key] = null;
                    this._callTrigger(
                      groupItem.key,
                      groupItem.triggerOf,
                      null,
                      groupItem.triggerCondition,
                    );
                  }
                });
              } else {
                groupItem.disabled = false;
                groupItem.value = triggerValue;
                if ((!groupItem.value || groupItem.value === 0) && groupItem.triggerOf) {
                  this.dataset[groupItem.key] = null;
                  this._callTrigger(
                    groupItem.key,
                    groupItem.triggerOf,
                    null,
                    groupItem.triggerCondition,
                  );
                }
              }
            } else {
              groupItem.disabled = true;
              groupItem.value = null;
              this.dataset[groupItem.key] = null;
              if (groupItem.type == 'ComboBox') groupItem.options = [];
              if (groupItem.triggerOf)
                this._callTrigger(
                  groupItem.key,
                  groupItem.triggerOf,
                  null,
                  groupItem.triggerCondition,
                );
            }
          }
        });
      });
    });
  }
  // #endregion Private

  // #region Observers
  _crudDatasetChanged(newValue) {
    this.dataset = Object.clone({}, newValue);
    if (newValue && this.crudConfig && this.crudConfig.sections)
      this.crudConfig.sections = this.crudConfig.sections.map(column =>
        column.map(row =>
          row.map(field => {
            if (!field.options) {
              Object.assign(field, { value: newValue[field.key] });
            } else {
              const fieldOptions = field.options.filter(ele => ele.id == newValue[field.key]);

              if (fieldOptions[0]) {
                Object.assign(field, { value: fieldOptions[0].value });
              }
            }

            return field;
          }),
        ),
      );
  }
  // #endregion Observers
}

class PowerCrud {
  constructor() {
    this.template = template;
    this.bindings = {
      crudConfig: '=?',
      crudDataset: '<?',
      cancelAction: '&?',
      confirmAction: '&?',
      errorAction: '&?',
      autoClear: '=?',
    };
    this.controller = PowerCrudController;
  }
}

angular
  .module('power-crud', ['power-crud-section'])
  .component('powerCrud', new PowerCrud());
