import angular from 'angular';

import '../power-fab/power-fab';
import '../power-dropdown/power-dropdown';
import '../power-popup/power-popup';
import '../power-toolbar-report/power-toolbar-report';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-grid-alert/power-grid-alert';
import '../power-map-generated-alerts/power-map-generated-alerts';
import '../../directives/ng-resize/ng-resize';
import { PowerReportController } from '../power-report/power-report';

import template from './power-report-generated-alerts.html';
import './power-report-generated-alerts.scss';

class PowerReportGeneratedAlertsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.legendList = [];
    this.mapModeList = [
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Áreas de Controle',
        method: 'GeoFence/Post',
        lastMapBounds: null,
        selected: true,
      },
    ];
    this.popupGeneric = null;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.addEventListener('mapLoaded', () =>
      this.$.querySelector('#report-body-map')
        .getMap()
        .onMoveEnd(() =>
          this.mapLayerList.map(mapLayer => {
            if (mapLayer.selected) this._toggleMapLayer({ ...mapLayer, selected: false });

            return mapLayer;
          }),
        ),
    );

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));

    this.$scope.$on('requestShowToast', this.__requestShowToast.bind(this));
    this.$scope.$on('requestInformationCard', this.__requestInformationCard.bind(this));
    this.$scope.$on('showPopupGeneric', this.__showPopupGeneric.bind(this));
  }
  /* */

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (this.$.querySelector('#report-body-map').getMap) this._adjustMap();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }
  /* */

  /* Private */
  _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        this.$.querySelector('#report-body-map').removeLayers(['stepMarker']);
        this._renderAllMode(this.reportDataset);
        break;
      case 'step':
        this.$.querySelector('#report-body-map').removeLayers(['alerts']);
        this.changeRow(this.stateConfig.gridConfig.page, mapMode.step);
        break;
      default:
        break;
    }
  }

  _renderAllMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    const parsedDataset = dataset.reduce(
      (acc, data) =>
        Object.assign(acc, {
          alerts: acc.alerts.concat({
            ...data,
            color: '#F29D00',
            icon: data.icon || 'directions_car',
            markerIcon: 'MarkerIcon',
          }),
        }),
      Object({ alerts: [] }),
    );

    mapComponent.renderDataset({
      dataset: parsedDataset.alerts,
      layerName: 'alerts',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#F29D00',
    });

    mapComponent.resizeMap();
    mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );

    const data = this.reportDataset[index];
    await mapComponent.renderDataset({
      dataset: [{ ...data, icon: data.icon, color: '#F29D00' }],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'all':
        mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
        break;
      case 'step':
        mapComponent.fitLayers(['stepMarker']);
        mapComponent.openPopup(0, 'stepMarker');
        break;
      default:
        break;
    }
  }

  _formatLatLng(str) {
    return str.replace(',', '.');
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        this.$.querySelector('#report-body-map').zoomTo(evtParams.tableRowData);
      }

      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _toggleMapLayer(mapLayer) {
    mapLayer.selected = !mapLayer.selected;
    this.mapLayerList = this.mapLayerList.map(layer =>
      layer.id == mapLayer.id ? mapLayer : layer,
    );
    if (mapLayer.selected) this._renderMapLayer(mapLayer.id);
    else {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayer.id ? { ...layer, lastMapBounds: null } : layer,
      );
      this.$.querySelector('#report-body-map').removeLayers([mapLayer.description]);
    }
  }

  _renderMapLayer(mapLayerId) {
    const mapLayer = this.mapLayerList.filter(layer => layer.id == mapLayerId)[0];

    const mapComponent = this.$.querySelector('#report-body-map');
    const mapBounds = mapComponent.getBounds();

    if (!mapLayer.lastMapBounds || !mapLayer.lastMapBounds.containsRect(mapBounds)) {
      this.mapLayerList = this.mapLayerList.map(layer =>
        layer.id == mapLayerId ? { ...layer, lastMapBounds: mapBounds } : layer,
      );

      return this.$http({
        url: `${this.urlApi}/${mapLayer.method}`,
        method: 'POST',
        data: {
          request: {
            filter: {
              conditions: [
                {
                  id: -1,
                  field: 'bounds',
                  value: [mapBounds.asArray().join()],
                },
              ],
            },
            page: 0,
            length: 10,
            isPaginated: false,
            sort: { direction: 'asc', name: 'description' },
          },
        },
      }).then(success => {
        let dataset = success.data.data.data.map(data => ({
          ...data,
          color: data.color || data.categoryColor,
        }));
        dataset = mapComponent.sortDatasetByBounds(dataset);
        mapComponent.renderDataset({ dataset: dataset.reverse(), layerName: mapLayer.description });
      });
    }

    return null;
  }
  /* */

  /* Protected */
  async __reportDatasetChanged(newValue) {
    if (newValue) {
      this.legendList = this.stateConfig.gridConfig.gridHeaders.reduce(
        (acc, ele) => (ele.icons ? acc.concat(ele) : acc),
        [],
      );

      if (newValue.length > 0) {
        this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
      }
    }
  }

  __createMapDataset(mapView, reportDataset) {
    const verifyFirstOrLast = index => (index === 0 ? 'first' : false);
    switch (mapView.id) {
      case 0:
        return reportDataset.reduce(
          (acc, ele, index, arr) =>
            acc.concat(
              index < arr.length - 1
                ? [
                    this.__toPoint(ele, verifyFirstOrLast(index, arr.length)),
                    this.__toLineString(ele, arr[index + 1]),
                  ]
                : [this.__toPoint(ele, arr.length > 1 ? 'last' : false)],
            ),
          [],
        );
      default:
        return reportDataset.map(ele => this.__toPoint(ele));
    }
  }

  __requestShowToast(evt, evtParams) {
    Object.assign(this.toastComponent, {
      open: true,
      text: evtParams.text,
    });
  }

  __requestInformationCard(evt, evtParams) {
    this.$scope.$apply(
      this.$scope.$broadcast('informationCardResponse', {
        dataset: { ...this.reportDataset.filter(ele => ele.id == evtParams.id)[0] },
      }),
    );
  }

  __showPopupGeneric(evt, evtParams) {
    if (evtParams.type == 'close') {
      switch (evtParams.popupId) {
        case 'popup-generic':
          this.$.querySelector('#popup-generic').toggle();
          this.$timeout(() => {
            this.popupGeneric = null;
          }, 300);
          break;
        default:
          break;
      }
    } else if (evtParams.type == 'open') {
      switch (evtParams.popupId) {
        case 'popup-generic': {
          const data = { ...evtParams.dataset };
          this.popupGeneric = {
            dataset: data,
            option: evtParams.option,
          };
          this.$.querySelector('.popup-generic .popup-generic-body').setAttribute(
            'content',
            evtParams.option.action.content,
          );

          this.$.querySelector('#popup-generic').toggle();
          break;
        }
        default:
          break;
      }
    }
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

class PowerReportGeneratedAlerts {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerReportGeneratedAlertsController;
  }
}

angular
  .module('power-report-generated-alerts', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'power-popup',
    'power-toolbar-report',
    'power-grid-alert',
    'power-map-generated-alerts',
    'power-footer',
    'power-pagination',
    'ng-resize',
  ])
  .component('powerReportGeneratedAlerts', new PowerReportGeneratedAlerts());

export { PowerReportGeneratedAlertsController };
