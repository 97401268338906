import angular from 'angular';

import { toDate } from '@power/power-components/utils/date-util.js';

import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-resize/ng-resize';
import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';

import template from './golfleet-grid-tracking-real-time.html';
import './golfleet-grid-tracking-real-time.scss';

class GolfleetGridTrackingRealTimeController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      'trackingRealTimeServices',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    trackingRealTimeServices,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, { $ngRedux, trackingRealTimeServices });

    this.pageRows = [];

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.popupHistoryTabSelected = 1;

    this.historyMessagesAndViewers = [];

    this.toastRealTimeVehicle = {
      text: null,
    };
  }

  /* Lifecycle */
  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /**/

  /* Private */
  _evtClickModalHistory(data, modalType, action, tabNumber) {
    switch (modalType) {
      case 'historyConfig':
        switch (action) {
          case 'open':
            this._toggleLoader(true);
            this.trackingRealTimeServices
              .getRealTimeChangePermissionHistory({ id: data.id })
              .then(response => {
                if (response.status && response.status !== 200) {
                  this._toggleToast('Ocorreu um erro inesperado.');
                  this._toggleLoader(false);
                  return;
                }
                this.historyList = response.data.data;
                this.$.querySelector('#popup-history-config').toggle();
                this._toggleLoader(false);
              });
            break;
          case 'close':
            this.$.querySelector('#popup-history-config').toggle();
            break;
          default:
            break;
        }
        break;

      case 'historyMessagesViewers':
        switch (action) {
          case 'open':
            this.popupHistoryTabSelected = tabNumber;
            this.$.querySelector('.popup-history-body').setAttribute('tab', tabNumber);

            this.popupHistoryTitle = `${data.reason} de ${data.plate}`;

            this._toggleLoader(true);

            this.trackingRealTimeServices
              .getMessagesAndViewersHistory({ id: data.id })
              .then(response => {
                if (response.status && response.status !== 200) {
                  this._toggleToast('Ocorreu um erro inesperado.');
                  this._toggleLoader(false);
                  return;
                }
                this.historyMessagesAndViewers = response.data.data;
                this.$.querySelector('#popup-history').toggle();
                this._toggleLoader(false);
              });
            break;
          case 'close':
            this.$.querySelector('#popup-history').toggle();
            break;
          default:
            break;
        }

        break;

      default:
        break;
    }
  }

  _evtClickPopupTab(tabNumber) {
    if (tabNumber !== this.popupHistoryTabSelected) {
      this.popupHistoryTabSelected = tabNumber;
      this.$.querySelector('.popup-history-body').setAttribute('tab', tabNumber);
    }
  }

  _toggleLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _toggleToast(message) {
    this.toastRealTimeVehicle.text = message;
    this.$.querySelector('power-toast#toast-real-time-vehicle').toggle(true);
  }

  _selectRow(column, row) {
    return (
      super._selectRow(column, row) &&
      (!Object.prototype.hasOwnProperty.call(row, column.field) || !!row[column.field])
    );
  }

  _toDate(date) {
    return toDate(date);
  }
  /* */
}

class GolfleetGridTrackingRealTime {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridTrackingRealTimeController;
  }
}

angular
  .module('golfleet-grid-tracking-real-time', [
    'power-popup',
    'power-toast',
    'ng-resize',
    'infinite-scroll',
  ])
  .component('golfleetGridTrackingRealTime', new GolfleetGridTrackingRealTime());
