import angular from 'angular';
import moment from 'moment/src/moment';

import template from './power-popup-driver-ai-tiebreaker.html';
import './power-popup-driver-ai-tiebreaker.scss';

import '../power-radio-button-group/power-radio-button-group';
import '../../directives/ng-tippy/ng-tippy';
import '../power-toast/power-toast';
import 'moment/src/locale/pt-br';
import { toDate } from '../../utils/date-util';

class PowerPopupDriverAiTiebreakerController {
  static get $inject() {
    return ['$element', '$http', 'urlApi', '$scope', '$rootScope'];
  }

  constructor($element, $http, urlApi, $scope, $rootScope) {
    Object.assign(this, { $: $element[0], $http, urlApi, $scope, $rootScope });

    this.method = '';
    this.objects = [];

    this.options = [
      {
        id: 1,
        type: 'radioGroup',
        title: 'Veículos',
        idCss: 'radio-veiculos',
        enabled: true,
        optionSelected: 0,
        methodParam: 'presetOk',
        values1: [],
      },
    ];

    this.quantidadeDias = 60;
    this.buttonAplicarEnabled = false;

    this.divAccuracyPercentageTooltipContent = document.getElementById(
      'accuracyPercentageTooltipContent',
    ).innerHTML;

    this.content = document.getElementById('deadlineExpiredTooltipContent');

    this.content.querySelector('label').innerHTML = this.quantidadeDias.toString();

    this.divDeadlineExpiredTooltipContent = this.content.innerHTML;

    this.justificationInputValue = null;

    this.fetchingData = false;

    this.vehiclePlate = '';

    this.startDateTime = '';
    this.veiId = '';

    this.historic = [];

    this.toastDriverAiTieBreaker = {
      text: '',
    };

    this.tmtIdCondutorAntigo = 0;

    this.buttonApplyDisabled = false;

    this.btnApply = 'normal';

    moment.locale('pt-BR');

    this.queryTablet = window.matchMedia('(max-width: 768px)');
    this.queryMobile = window.matchMedia('(max-width: 425px)');
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setData: this.setData.bind(this),
    });
  }

  $onDestroy() {}
  // #endregion Lifecycle

  // #region Public
  async toggle(veiId, vehiclePlate, dataHoraInicio, tmtIdAntigo) {
    if (!this.$.hasAttribute('open')) {
      this.$.setAttribute('open', '');
      this.fetchingData = true;
      this.veiId = veiId;
      this.vehiclePlate = vehiclePlate;
      this.startDateTime = dataHoraInicio;

      if (tmtIdAntigo) {
        this.tmtIdCondutorAntigo = tmtIdAntigo;
      }

      const auxDataHoraInicio = this._parseDate(new Date(dataHoraInicio.valueOf()));

      this.$http({
        url: `${this.urlApi}/AiIdentification/GetAiTieBreakerDrivers`,
        method: 'POST',
        data: {
          request: {
            veiId: this.veiId,
            dataHoraInicio: auxDataHoraInicio,
          },
        },
      })
        .then(async success => {
          if (success.status && success.status !== 200) {
            this.toastDriverAiTieBreaker.text =
              'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
            this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);

            setTimeout(() => {
              this.fetchingData = false;
              this.toggle();
            }, 3000);
            return;
          }

          if (success.data.data && success.data.data.data && success.data.data.data.length > 0) {
            const driverTieBreakerOptions = [];
            success.data.data.data.forEach((driver, driverIndex) => {
              driverTieBreakerOptions.push({
                id: driverIndex + 1,
                name: driver.name,
                feedback: driver.feedback,
                compatibilityPercent: driver.compatibilityPercent.toFixed(2),
                value: driver.driverTmtId,
                dateDriverTieBreakerAnswer: toDate(driver.dateDriverTieBreakerAnswer),
                dateDriverTieBreakerRequest: toDate(driver.dateDriverTieBreakerRequest),
              });
            });
            this.options = [
              {
                id: 1,
                type: 'radioGroup',
                title: 'Veículos',
                idCss: 'radio-veiculos',
                enabled: true,
                optionSelected: this.tmtIdCondutorAntigo,
                methodParam: 'presetOk',
                values1: driverTieBreakerOptions,
              },
            ];

            if (success.data.data.historic && success.data.data.historic.length > 0) {
              this.historic = success.data.data.historic;
            }
          } else {
            this.toastDriverAiTieBreaker.text =
              'Não foram encontrados condutores Identificados por IA';
            this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);
          }

          const dataHoje = new Date();

          const lastDay = moment(dataHoje);
          const firstDay = moment(dataHoraInicio);
          const dayIndexLimit = lastDay.diff(firstDay, 'days');

          if (dayIndexLimit < this.quantidadeDias) {
            if (tmtIdAntigo == this.tmtIdCondutorAntigo) {
              this.btnApply = 'disabled';
            }
          } else {
            this.btnApply = 'tippy';
          }
        })
        .finally(() =>
          setTimeout(() => {
            this.fetchingData = false;
            if (this.$scope.$$phase === null && this.$rootScope.$$phase === null)
              this.$scope.$apply();
          }, 100),
        );
    } else {
      this.$.removeAttribute('open');
      this.options = [
        {
          id: 1,
          type: 'radioGroup',
          title: 'Veículos',
          idCss: 'radio-veiculos',
          enabled: true,
          optionSelected: 0,
          methodParam: 'presetOk',
          values1: [],
        },
      ];
      this.justificationInputValue = null;
      this.vehiclePlate = '';
      this.startDateTime = '';
      this.veiId = '';
      this.tmtIdCondutorAntigo = 0;
      this.historic = [];
      this.toastDriverAiTieBreaker = {
        text: '',
      };
      this.btnApply = 'normal';
    }
  }

  setData(data) {
    Object.assign(this, data);
  }
  // #endregion Public

  // #region Private
  _btnApply() {
    if (this.options && this.options[0].optionSelected == 0) {
      this.toastDriverAiTieBreaker.text = 'Selecione uma opção de Condutor';
      this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);
      return;
    }
    if (!this.justificationInputValue) {
      this.toastDriverAiTieBreaker.text = 'O campo de justificativa precisa ser preenchido';
      this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);
      return;
    }
    if (this.justificationInputValue.length > 500) {
      this.toastDriverAiTieBreaker.text = 'A justificativa não pode exceder 500 caractéres';
      this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);
      return;
    }
    if (this.justificationInputValue.length < 10) {
      this.toastDriverAiTieBreaker.text = 'A justificativa precisa ter pelo menos 10 caractéres';
      this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);
      return;
    }

    const auxDataHoraInicio = new Date(this.startDateTime.valueOf());

    this.fetchingData = true;

    this.$http({
      url: `${this.urlApi}/AiIdentification/UpdateAiTieBreakerDriver`,
      method: 'POST',
      data: {
        request: {
          veiId: this.veiId,
          dataHoraInicio: auxDataHoraInicio,
          tmtIdCondutorNovo: this.options[0].optionSelected,
          tmtIdCondutorAntigo: this.tmtIdCondutorAntigo,
          justificativa: this.justificationInputValue,
        },
      },
    }).then(async success => {
      const response = success.data.data;

      if ((success.status && success.status !== 200) || !response) {
        this.toastDriverAiTieBreaker.text =
          'Ocorreu um erro ao processar sua solicitação, por favor tente novamente';
        this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);
      } else {
        setTimeout(() => {
          this.fetchingData = false;
          this.toggle();
          this.$.dispatchEvent(new CustomEvent('driverUpdated'));
        }, 100);
      }
    });
  }

  _parseDate(date) {
    return new Date(
      new Date(date)
      .setUTCHours(date.getUTCHours() - date.getTimezoneOffset() / 60)
    );
    // return new Date(date.setHours(date.getHours() - 3));
  }

  _clickRadioOption(value) {
    if (this.btnApply != 'tippy') {
      if (this.tmtIdCondutorAntigo == value) {
        this.btnApply = 'disabled';
      } else {
        this.btnApply = 'normal';
      }
      setTimeout(() => {
        if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) this.$scope.$apply();
      }, 100);
    }
  }

  _showToast(event) {
    event.stopPropagation();

    this.toastDriverAiTieBreaker.text =
      'Indica o percentual de chance de que seja este o condutor, de acordo com o algoritmo da inteligência artificial.';
    this.$.querySelector('power-toast#toast-driver-ai-tiebreaker').toggle(true);
  }

  // #endregion Private
}

class PowerPopupDriverAiTiebreaker {
  constructor() {
    this.template = template;
    this.bindings = {
      textHeader: '=?',
    };
    this.controller = PowerPopupDriverAiTiebreakerController;
  }
}
angular
  .module('power-popup-driver-ai-tiebreaker', [
    'power-radio-button-group',
    'ng-tippy',
    'power-toast',
  ])
  .component('powerPopupDriverAiTiebreaker', new PowerPopupDriverAiTiebreaker());
