import angular from 'angular';

import '../../directives/infinite-scroll/infinite-scroll';
import {
  PowerFilterController,
  FilterCondition,
  PowerFilter,
} from '../power-filter/power-filter';

import template from './power-filter-compound.html';
import './power-filter-compound.scss';

class PowerFilterCompoundController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi'];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor($element, $scope, $http, urlApi) {
    super($element, $scope, $http, urlApi);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setFilterValue: this.setFilterValue.bind(this),
    });

    this.description = this.description || 'Carregando...';

    this.primary = {
      _search: '',
      _toggleAll: false,
      field: this.field.primary,
      plural: this.plural.primary,
      singular: this.singular.primary,
      historicalData: this.historicalData,
      options: this.options ? this.options.primary : [],
      requestApi: this.requestApi.primary,
      requestData: this.requestData ? this.requestData.primary : {},
    };

    this.secondary = {
      _search: '',
      _toggleAll: false,
      field: this.field.secondary,
      plural: this.plural.secondary,
      singular: this.singular.secondary,
      historicalData: this.historicalData,
      options: this.options ? this.options.secondary : [],
      requestApi: this.requestApi.secondary,
      requestData: this.requestData ? this.requestData.secondary : {},
    };

    if (!this.activeView) this.activeView = 'primary';

    this._getOptions('primary').finally(this._initializeFilter.bind(this));

    this.$scope.$watch(() => this.primary._search, this.__primarySearchChanged.bind(this));

    this.$scope.$watch(() => this.secondary._search, this.__secondarySearchChanged.bind(this));
  }
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }

  setFilterValue(primaryValue, secondaryValue) {
    this.condition = [
      new FilterCondition(this.filterId, this.primary.field, primaryValue),

      new FilterCondition(this.filterId, this.secondary.field, secondaryValue),
    ];
  }
  /* */

  /* Private */
  _initializeFilter() {
    if (this.condition) {
      const conditions = {};
      if (this.condition[0] && this.condition[1]) {
        conditions.primary = Object.assign([], this.condition[0].value);
        conditions.secondary = Object.assign([], this.condition[1].value);
      } else {
        conditions.primary = this.condition.value[0];
        conditions.secondary = this.condition.value[1];
      }

      this._selectOption(
        'primary',
        this.primary.options.filter(option => conditions.primary.includes(option.value)),
      );
      if (conditions.secondary.length > 0) {
        this.secondary.requestData = { id: conditions.primary[0] };
        this._getOptions('secondary').then(() => {
          this._selectOption(
            'secondary',
            this.secondary.options.filter(option => conditions.secondary.includes(option.value)),
          );
          this._toggleView();
        });
      }
    } else {
      this._formatDescription([], []);
      this.setFilterValue([], []);
    }
  }

  _changeActiveView(activeView, requestData) {
    if (requestData) {
      const primarySelectedOptions = this.primary.options.filter(option => option.selected);
      this.secondary.requestData = { id: requestData.value };
      this._selectOption(
        'primary',
        !this.condition[0].value.includes(requestData.value)
          ? primarySelectedOptions.concat(requestData)
          : primarySelectedOptions.filter(option => option.value != requestData.value),
      );
      this._getOptions('secondary').finally(() => {
        this.activeView = activeView;
        this.$scope.$evalAsync();
      });
    } else {
      this.activeView = activeView;
      if (activeView == 'primary') {
        Object.assign(this.secondary, {
          options: this.secondary.options.map(option => Object.assign(option, { selected: false })),
          _toggleAll: false,
        });
        this._formatDescription(
          this.primary.options.filter(option => option.selected),
          [],
        );
      }
    }
  }

  _formatDescription(primaryValue, secondaryValue) {
    if (!Array.isArray(primaryValue) || !Array.isArray(secondaryValue)) return;
    const primaryDescription = primaryValue[0]
      ? this._applyFormatter(primaryValue[0].description)
      : '';
    const secondaryDescription = secondaryValue[0]
      ? this._applyFormatter(secondaryValue[0].description)
      : '';
    if (primaryValue.length == 0)
      this.description = this._formatDescriptionWords(
        `Qualquer ${this.primary.singular} ou ${this.secondary.singular}`,
      );
    else if (secondaryValue.length == 0) {
      if (primaryValue.length == 1)
        this.description = this._formatDescriptionWords(
          `Apenas ${this.primary.singular} ${primaryDescription}`,
        );
      else
        this.description = this._formatDescriptionWords(
          `${primaryValue.length} ${this.primary.plural}`,
        );
    } else if (secondaryValue.length == 1)
      this.description = this._formatDescriptionWords(
        `Apenas ${this.primary.singular} ${primaryDescription} e` +
          ` ${this.secondary.singular} ${secondaryDescription}`,
      );
    else
      this.description = this._formatDescriptionWords(
        `${this.primary.singular} ${primaryDescription},` +
          ` ${secondaryValue.length} ${this.secondary.plural}`,
      );
  }

  _getOptions(from) {
    if (!from) return new Promise((resolve, reject) => reject());
    if (!this[from].requestApi)
      return new Promise(resolve => {
        this[from].options.map(item => ({ ...item, selected: false }));
        resolve();
      });
    return this.$http({
      url: `${this.urlApi}/${this[from].requestApi}`,
      method: 'POST',
      data: { ...this[from].requestData },
    }).then(
      success => {
        this[from].options = Object.assign([], success.data.data);
      },
      () => {
        this[from].options = [];
      },
    );
  }

  _toggleView() {
    const containerNode = this.$.querySelector('#filter-list-container');
    if (containerNode) {
      if (this._activeView == 'primary') {
        this._activeView = 'secondary';
        Object.assign(containerNode.style, { transform: 'translateX(100%)' });
      } else {
        this._activeView = 'primary';
        Object.assign(containerNode.style, { transform: 'translateX(0)' });
      }
    }
  }

  _toggleAllOptions(from) {
    this[from]._toggleAll = !this[from]._toggleAll;
    this[from].options = this[from].options.map(option =>
      this._stringIncludes(option, this[from]._search)
        ? Object.assign(option, { selected: this[from]._toggleAll })
        : option,
    );
    const selectedOptions = {
      primary: this.primary.options.filter(option => option.selected),
      secondary: this.secondary.options.filter(option => option.selected),
    };
    switch (from) {
      case 'primary':
        this.setFilterValue(
          selectedOptions[from].map(option => option.value),
          [],
        );
        break;
      case 'secondary':
        this.setFilterValue(
          this.condition[0].value[0],
          selectedOptions[from].map(option => option.value),
        );
        break;
      default:
        break;
    }
    this._formatDescription(selectedOptions.primary, selectedOptions.secondary);
  }

  _selectOption(from, selectOptions) {
    if (!from || !selectOptions) return;
    const filterById = (options, id) => options.filter(option => option.id == id);
    this[from].options = this[from].options.map(option =>
      filterById(selectOptions, option.id)[0]
        ? Object.assign(option, { selected: !option.selected })
        : option,
    );
    const selectedOptions = {
      primary: this.primary.options.filter(option => option.selected),
      secondary: this.secondary.options.filter(option => option.selected),
    };
    this._formatDescription(selectedOptions.primary, selectedOptions.secondary);
    switch (from) {
      case 'primary':
        this.setFilterValue(
          selectedOptions[from].map(option => option.value),
          [],
        );
        break;
      case 'secondary':
        this.setFilterValue(
          this.condition[0].value[0],
          selectedOptions[from].map(option => option.value),
        );
        break;
      default:
        break;
    }
    this._verifyToggleAll(from);
  }

  _verifyToggleAll(from) {
    if (!from) return;
    const filteredOptions = this[from].options.filter(option =>
      this._stringIncludes(option, this[from]._search),
    );
    const filteredSelectedOptions = filteredOptions.filter(option => option.selected);
    this[from]._toggleAll =
      filteredOptions.length > 0 && filteredSelectedOptions.length == filteredOptions.length;
  }

  _primarySearchFilterOption(value) {
    if (!this.primary._search) {
      return true;
    }
    if (!this.primary._search) {
      return true;
    }
    return new RegExp(
      this.primary._search
        .toLowerCase()
        .replace(/^( *, *)+|( *, *)+$/g, '')
        .replace(/( *, *)+/g, '|'),
    ).test(value.description.toLowerCase());
  }

  _secondarySearchFilterOption(value) {
    if (!this.secondary._search) {
      return true;
    }
    return new RegExp(
      this.secondary._search
        .toLowerCase()
        .replace(/^( *, *)+|( *, *)+$/g, '')
        .replace(/( *, *)+/g, '|'),
    ).test(value.description.toLowerCase());
  }

  /* */

  /* Observers */
  __primarySearchChanged() {
    this._verifyToggleAll('primary');
  }

  __secondarySearchChanged() {
    this._verifyToggleAll('secondary');
  }
  /* */
}

class PowerFilterCompound extends PowerFilter {
  constructor() {
    super();
    this.template = template;
    this.controller = PowerFilterCompoundController;
  }
}

angular
  .module('power-filter-compound', ['infinite-scroll'])
  .component('powerFilterCompound', new PowerFilterCompound());
