import angular from 'angular';
import moment from 'moment/src/moment';
import 'moment/src/locale/pt-br';

import template from './power-pagination-register.html';
import './power-pagination-register.scss';

class PowerPaginationRegisterController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux'];
  }

  constructor($element, $scope, $ngRedux) {
    Object.assign(this, { $: $element[0], $scope, $ngRedux });

    this._appBehavior = $ngRedux.connect(behavior =>
      Object({
        isSingleSignon: behavior.session.isSingleSignon,
      }),
    )(this);

    moment.locale('pt-BR');
  }

  $onDestroy() {
    this._appBehavior();
  }

  changePage(toPage) {
    if (toPage > 0 && toPage <= this.lastPage) {
      this.changeRegisterFunction({ toPage });
    }
  }

  changeRow(toRow) {
    const toPage = Math.ceil(toRow / this.pageSize);

    if (toPage > 0 && toPage <= this.lastPage) {
      this.changeRegisterFunction({ page: toPage, row: toRow });
    }
  }

  toggleCard() {
    const cardElement = this.$.querySelector('.power-register-card');

    if (cardElement.hasAttribute('open')) {
      cardElement.removeAttribute('open');
    } else {
      cardElement.setAttribute('open', '');
    }
  }

  _getStreetViewLink(latitude, longitude) {
    return `https://maps.google.com/maps?layer=c&q=${latitude},${longitude}&cbll=${latitude},${longitude}&cbp=11,0,0,0,0&z=17&ll=${latitude},${longitude}`;
  }

  _rowOnPage() {
    return this.actualRow;
  }

  _rowOnPageFormatted() {
    return `${
      this.actualRow - (Math.ceil(this.actualRow / this.pageSize) - 1) * this.pageSize
    }`.padStart(2, '0');
  }

  _actualPageFormatted() {
    return `${Math.ceil(this.actualRow / this.pageSize)}`.padStart(2, '0');
  }

  _actualRowData() {
    const rowOnPage =
      this.actualRow - (Math.ceil(this.actualRow / this.pageSize) - 1) * this.pageSize;
    return (this.pageRows || [])[rowOnPage - 1];
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _formatDate(date) {
    return this._toDate(date).toLocaleString();
  }

  _getFormatedDate(date) {
    return this._formatDate(date).replace(',', '').split(' ')[0];
  }

  _getFormatedHour(date) {
    return this._formatDate(date).replace(',', '').split(' ')[1];
  }
}

class PowerPaginationRegister {
  constructor() {
    this.template = template;
    this.bindings = {
      pageSize: '=?',
      actualRow: '=?',
      lastRow: '=?',
      actualPage: '=',
      lastPage: '=',
      pageRows: '=',
      changeRegisterFunction: '&',
    };
    this.controller = PowerPaginationRegisterController;
  }
}

angular
  .module('power-pagination-register', [])
  .component('powerPaginationRegister', new PowerPaginationRegister());

export { PowerPaginationRegisterController };
