/* global H: true isIframe: true */
import moment from 'moment';

import { PowerMapHereProvider } from '../../../power-map/providers/here/power-map.here-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-positions.here-provider.scss';

class PowerMapPositionsHereProvider extends PowerMapHereProvider {
  constructor(context, $element, $ngRedux, $rootScope, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    Object.assign(this, { $rootScope });

    this.routeTimelineInterval = null;
    this.routeTimelinePosition = 0;

    this.routeMarkers = null;
    this.routeLinestrings = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      openPopup: this.openPopup.bind(this),
      setTimelineDataset: this.setTimelineDataset.bind(this),
      toggleTimelineControl: this.toggleTimelineControl.bind(this),
      updateTimelinePosition: this.updateTimelinePosition.bind(this),
    });

    super.$onInit();
  }

  $onDestroy() {
    clearInterval(this.context.routeTimelineEvent);
  }
  /**/

  /* Public */
  removeLayers(layerNameList = ['default']) {
    const removingTimeline =
      layerNameList.filter(layer => layer == 'routeMarkers' || layer == 'routeLinestrings').length <
      layerNameList.length;
    if (this.context.routeTimelineEvent && removingTimeline) {
      clearInterval(this.context.routeTimelineEvent);
      Object.assign(this, {
        routeTimelineEvent: null,
        routeTimelinePosition: this.routeMarkers.length - 1,
      });
    }
    super.removeLayers(layerNameList);
  }

  toggleTimelineControl(show) {
    this.$.querySelector('#route-timeline-control').style.visibility = show ? 'inherit' : 'hidden';
  }

  setTimelineDataset({ markers, linestrings }) {
    Object.assign(this, {
      routeMarkers: markers,
      routeLinestrings: linestrings,
      routeTimelinePosition: markers.length - 1,
    });
    this._renderRouteTimeline();
  }

  updateTimelinePosition(evtParams) {
    this.routeTimelinePosition = evtParams.pathPosition;

    if ('routeMarkers' in this.mapLayers && this.mapLayers.routeMarkers.getObjects().length > 0) {
      const marker = this.mapLayers.routeMarkers.getObjects()[this.routeTimelinePosition];
      this.map.setZoom(16, true);
      this.map.setCenter(marker.getPosition(), true);

      this._openBubblePopup({
        position: marker.getPosition(),
        content: marker.getData().bubbleContent,
      });
    }
  }
  /**/

  /* Private */
  _createMarkerIcon({ color, icon, ignicao: ignition, direcao: direction }) {
    if (!ignition) {
      return super._createMarkerIcon({ color, icon });
    }

    const createIcon = iconString => {
      if (!iconString) return '';
      return `
          <div style="color: #fff;cursor: pointer;transform: rotate(-${direction}deg);">
            <i class="material-icons" style="margin-top: -2px;cursor: pointer;">
              ${iconString}
            </i>
          </div>
        `;
    };
    return new H.map.DomIcon(
      `
        <div style="margin: -20px 0px 0px -20px; z-index: 0;transform: matrix(1, 0, 0, 1, 378, 209); position: absolute;">
          <div style="width:40px; height:40px; transform: rotate(${direction}deg);">
            <svg xmlns="https://www.w3.org/2000/svg" width="40" height="60" style="margin-top:-20px;">

              <foreignObject x="0" y="0" width="40" height="60" style="">
                <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                  <i class="material-icons" style="color:${
                    color || 'var(--primary-color)'
                  };cursor: pointer;font-size: 24px;margin-bottom:1px; margin-top: -4px;">
                    navigation
                  </i>
                  <div style="background:${
                    color || 'var(--primary-color)'
                  };border-radius: 100%;display:flex;justify-content:center;align-items: center;height: 32px;width: 32px;">
                    ${createIcon(icon)}
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
          `,
    );
  }

  _createRouteIcon(properties) {
    const svgSize = properties.first || properties.last ? 36 : 24;
    const svgSizeWithShadow = properties.first || properties.last ? 40 : 28;

    const { eventoGerador } = properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';
    const iconColor = isVmaxEvent ? '#DECE00' : '#FFF';

    if (properties.status == 4) {
      return new H.map.DomIcon(
        `<svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow}"
            style="margin: -${svgSizeWithShadow / 2}px 0 0 -${svgSizeWithShadow / 2}px">
            <circle
              r="${svgSize / 4}"
              cx="${svgSizeWithShadow / 2}"
              cy="${svgSizeWithShadow / 2}"
              fill="#2196f3">
            </circle>
            ${
              properties.first
                ? `<foreignObject x="15" y="9" width="10" height="20"><span style="color: #FFF; font-size: 14px; line-height: ${
                    svgSizeWithShadow / 2
                  }px;"> A </span></foreignObject>`
                : ''
            }
            ${
              properties.last
                ? `<foreignObject x="15" y="10" width="10" height="20"><span style="color: #FFF; font-size: 14px; line-height: ${
                    svgSizeWithShadow / 2
                  }px;"> B </span></foreignObject>`
                : ''
            }
          </svg>`,
      );
    }

    if (properties.status == 2 || properties.status == 3) {
      return new H.map.DomIcon(`
        <svg xmlns="https://www.w3.org/2000/svg"
          viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
          width="${svgSizeWithShadow}"
          height="${svgSizeWithShadow}"
          style="margin: -${svgSizeWithShadow / 2}px 0 0 -${svgSizeWithShadow / 2}px">
          <rect
            height="${(svgSize * 2) / 3}"
            x="${svgSize / 6 + 2}"
            y="${svgSize / 6 + 2}" rx="3"
            ry="3"
            width="${(svgSize * 2) / 3}"
            stroke-width="${svgSize / 6}"
            stroke="#2196f3" fill="${iconColor}">
          </rect>
          ${
            properties.first
              ? `<foreignObject x="15" y="9" width="10" height="20"><span style="color: #2196f3; font-size: 14px; line-height: ${
                  svgSizeWithShadow / 2
                }px;"> A </span></foreignObject>`
              : ''
          }
          ${
            properties.last
              ? `<foreignObject x="15" y="10" width="10" height="20"><span style="color: #2196f3; font-size: 14px; line-height: ${
                  svgSizeWithShadow / 2
                }px;"> B </span></foreignObject>`
              : ''
          }
        </svg>
      `);
    }

    if (!properties.ignicao || properties.first || properties.last) {
      return new H.map.DomIcon(`
        <svg xmlns="https://www.w3.org/2000/svg"
          viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow}"
          width="${svgSizeWithShadow}"
          height="${svgSizeWithShadow}"
          style="margin: -${svgSizeWithShadow / 2}px 0 0 -${svgSizeWithShadow / 2}px">
          <circle
            r="${svgSize / 3}"
            cx="${svgSizeWithShadow / 2}"
            cy="${svgSizeWithShadow / 2}"
            stroke-width="${svgSize / 6}"
            stroke="#2196f3"
            fill="${iconColor}">
          </circle>
          ${
            properties.first
              ? `<foreignObject x="15" y="9" width="10" height="20"><span style="color: #2196f3; font-size: 14px; line-height: ${
                  svgSizeWithShadow / 2
                }px;"> A </span></foreignObject>`
              : ''
          }
          ${
            properties.last
              ? `<foreignObject x="15" y="10" width="10" height="20"><span style="color: #2196f3; font-size: 14px; line-height: ${
                  svgSizeWithShadow / 2
                }px;"> B </span></foreignObject>`
              : ''
          }
        </svg>
      `);
    }

    return new H.map.DomIcon(`
      <div style="
        margin: -${svgSizeWithShadow / 2}px 0px 0px -${svgSizeWithShadow / 2}px;
        z-index: 0;
        position: absolute;"
      >
        <div style="
          width:${svgSizeWithShadow}px;
          height:${svgSizeWithShadow}px;
          transform: rotate(${properties.direcao}deg);"
        >
          <svg xmlns="https://www.w3.org/2000/svg"
            viewBox="0 0 ${svgSizeWithShadow} ${svgSizeWithShadow * 2 - 8}"
            width="${svgSizeWithShadow}"
            height="${svgSizeWithShadow * 2 - 8}"
            style="margin-top: -${svgSizeWithShadow - 8}px">
            <foreignObject x="0" y="0" width="28" height="48" style="">
              <div style="display:flex;justify-content:center;align-items: center;width: 100%;height: 100%;flex-direction: column;">
                <i class="material-icons" style="${
                  isVmaxEvent ? 'opacity: 0' : 'color:#2196f3'
                } ;cursor: pointer;font-size: 24px;margin-bottom:1px; margin-top: -4px;">
                  navigation
                </i>
                <div style="background:${iconColor};
                  border: ${svgSize / 6}px solid #2196f3;
                  border-radius: 100%;
                  display:flex;
                  justify-content:center;
                  align-items: center;
                  height: ${svgSize / 2}px;
                  width: ${svgSize / 2}px;">
                </div>
              </div>
            </foreignObject>
          </svg>
        </div>
      </div>
    `);
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    const { eventoGerador } = geoJson.properties;
    const isVmaxEvent = eventoGerador && eventoGerador.toUpperCase() == 'VMAX';

    return new H.map.DomMarker(
      { lat, lng },
      {
        icon:
          markerIcon ||
          (geoJson.properties.markerIcon === 'RouteIcon'
            ? this._createRouteIcon
            : this._createMarkerIcon)(geoJson.properties),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="${!isVmaxEvent ? 'mapPopupHeader' : 'mapPopupHeaderVmax'}">
              <span>${geoJson.properties.placaComApelido}</span>
            </div>
            <div id="mapPopupBody">
              <div style="display: ${geoJson.properties.logradouro ? 'block' : 'none'}">
                <b>Endereço:</b>
                <br>
                <span>${geoJson.properties.logradouro}</span>
              </div>
              <div style="display: ${geoJson.properties.municipio ? 'block' : 'none'}">
                <b>Cidade - Estado:</b>
                <br>
                <span>${geoJson.properties.municipio} - ${geoJson.properties.estado}</span>
              </div>
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${this._toDate(geoJson.properties.dataHora).toLocaleString()}</span>
              </div>
              <div>
                <b>Velocidade:</b>
                <br>
                <div class="mapPopupBodyItem">
                  <span>
                    <i class="material-icons"
                      style="font-size: 11px; color: ${
                        !geoJson.properties.ignicao ? '#980A1A' : '#4AAE4E'
                      }">
                      lens
                    </i>
                    ${geoJson.properties.velocidade} Km/h
                    <i class="material-icons"
                      style="font-size: 11px; color: #949494;">
                      ${
                        !geoJson.properties.gpsStatus
                          ? 'signal_cellular_connected_no_internet_4_bar'
                          : 'network_cell'
                      }
                    </i>
                  </span>
                  ${!isVmaxEvent ? '' : "<span class='vmaxTag'>VMAX</span>"}
                </div>
              </div>
              <div>
                <b>Condutor:</b>
                <br>
                <span>${geoJson.properties.condutor || 'Não identificado'}</span>
              </div>
              ${
                isIframe()
                  ? ''
                  : `
                <div style="margin-top:8px; text-align:center">
                  <a class="gs-link"
                    target="_blank"
                    href="https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
      },
    );
  }

  _geoJsonToCircle({ geoJson }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.Circle({ lat, lng }, geoJson.properties.radius, {
      data: {
        ...geoJson.properties,
        bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
      },
      style: {
        fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
        strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
      },
    });
  }

  _geoJsonToPolygon({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    const [[lng, lat]] = coordinates;

    return new H.map.Polygon(
      coordinates.reduce((acc, coordinate) => {
        const [longitude, latitude] = coordinate;
        acc.pushLatLngAlt(latitude, longitude);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToMultiPolygon({ geoJson }) {
    const { coordinates } = geoJson.geometry;
    const [[[lng, lat]]] = coordinates;

    return new H.map.Polygon(
      coordinates.reduce((acc, [polygonArray]) => {
        acc.push(
          new H.geo.Polygon(
            polygonArray.reduce((lineStringAcc, latLngArray) => {
              const [longitude, latitude] = latLngArray;
              lineStringAcc.pushLatLngAlt(latitude, longitude);
              return lineStringAcc;
            }, new H.geo.LineString()),
          ),
        );
        return acc;
      }, new H.geo.MultiPolygon([])),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
          <div id="mapPopupHeader">
            <span>${geoJson.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${geoJson.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${geoJson.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${geoJson.properties.privacy || '---'}</span>
            </div>
            <div>
              <b>Status:</b>
              <br>
              <span>${geoJson.properties.providerStatusDesc || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${Number(lat).toFixed(4)} </span>
            <span> Lon: ${Number(lng).toFixed(4)} </span>
          </div>
        `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToPolyline({ geoJson }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;

    return new H.map.Polyline(
      geoJson.geometry.coordinates.reduce((acc, coordinate) => {
        const [longitude, latitude] = coordinate;
        acc.pushLatLngAlt(latitude, longitude);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          lineWidth: 6,
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToRectangle({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    const [[lng, lat]] = coordinates;

    return new H.map.Rect(
      coordinates.reduce((acc, coordinate) => {
        const [longitude, latitude] = coordinate;
        acc.pushLatLngAlt(latitude, longitude);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _toggleRouteTimeline() {
    if (!this.routeMarkers || !this.routeLinestrings)
      // eslint-disable-next-line no-console
      return console.warn('Missing routeMarkers or/and routeLinestrings Layers');

    if (!this.context.routeTimelineEvent) {
      this.context.routeTimelineEvent = setInterval(() => {
        this._renderRouteTimeline().then(() => {
          if (this.routeTimelinePosition < this.routeMarkers.length - 1) {
            this.routeTimelinePosition += 1;
            this.$scope.$emit('updateTimelineSlider', {
              value: this.routeTimelinePosition,
            });
          } else this._endRouteTimeline();
        });
      }, 2000);
      this._renderRouteTimeline().then(() => {
        if (this.routeTimelinePosition == this.routeMarkers.length - 1)
          this.routeTimelinePosition = 0;
        else this.routeTimelinePosition += 1;
        this.$scope.$emit('updateTimelineSlider', {
          value: this.routeTimelinePosition,
        });
      });
    } else {
      clearInterval(this.context.routeTimelineEvent);
      this.context.routeTimelineEvent = null;
      this._renderRouteTimeline();
    }

    return null;
  }

  _endRouteTimeline() {
    clearInterval(this.context.routeTimelineEvent);
    this.context.routeTimelineEvent = null;

    Object.assign(this, {
      routeTimelinePosition: this.routeMarkers.length - 1,
    });

    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }

    this.$scope.$emit('updateTimelineSlider', {
      value: this.routeMarkers.length - 1,
    });

    this._renderRouteTimeline();
  }

  _renderRouteTimeline() {
    if (this.mapLayers.routeMarkers) {
      if (!this.mapLayers.routeMarkers.removeAll) this.map.removeLayer(this.mapLayers.routeMarkers);
      else this.map.removeObject(this.mapLayers.routeMarkers);

      delete this.mapLayers.routeMarkers;
    }

    return this.renderDataset({
      dataset: this.routeMarkers,
      layerName: 'routeMarkers',
      type: 'MarkerFeatureGroup',
      useCluster: false,
      clusterColor: '#2196f3',
    })
      .then(() =>
        this.renderDataset({
          dataset: this.routeLinestrings,
          layerName: 'routeLinestrings',
          type: 'LinestringFeatureGroup',
        }),
      )
      .then(() => {
        if (!this.context.routeTimelineEvent) {
          this.fitLayers(['routeLinestrings']);
        } else if ('getObjects' in this.mapLayers.routeMarkers) {
          const markersLayers = this.mapLayers.routeMarkers.getObjects();

          if (markersLayers.length < 1) return;

          const lastMarker = markersLayers[markersLayers.length - 1];

          this.map.setZoom(this.routeTimelinePosition <= 1 ? 16 : this.map.getZoom(), true);
          this.map.setCenter(lastMarker.getPosition(), true);

          this._openBubblePopup({
            position: lastMarker.getPosition(),
            content: lastMarker.getData().bubbleContent,
          });
        }
      });
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }
  /**/

  /* Observers */
  /**/
}

export { PowerMapPositionsHereProvider };
