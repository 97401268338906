import angular from 'angular';
import 'ng-redux';

import '../power-toast/power-toast';
import '../power-popup/power-popup';
import '../power-single-checkbox/power-single-checkbox';
import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-photo-indexation-stand-alone.html';
import './power-photo-indexation-stand-alone.scss';

/**
 * FleetCamProvider Type (Enum)
 */
export const FleetCamProviderEnum = {
  R1: 1,
  V3: 2,
  R1_AND_V3: 3,
};

class PowerPhotoIndexationStandAloneController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', '$http', '$state', 'urlApi'];
  }

  constructor($element, $scope, $ngRedux, $http, $state, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $ngRedux,
      $http,
      $state,
      urlApi,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        /* Session Storage */
        session: behavior.session,
      });
    })(this);

    this.toastText = '';
    this.dataKeys = [];
    this.images = [];
    this.pageSize = 10;
    this.photoBlur = '';
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      removeAndReindex: this.removeAndReindex.bind(this),
    });

    if (this.session.fleetCamProvider === FleetCamProviderEnum.R1) {
      this._getDriverImageKeys();
      this.$scope.$watch(() => this.dataKeys, this._loadDriverImages.bind(this));
      this.$scope.$watch(() => this.images.length, this._checkIfFinished.bind(this));
    } else {
      this._getDriverImages();
    }

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    if (this.session.trainingMode) {
      this.photoBlur = 'blurred';
    }
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  removeAndReindex() {
    this.removeImage();
  }
  /* */

  removeImage() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.toastText = 'Deletando as imagens selecionadas';
    this.$.querySelector('#indexation-toast').toggle(true);

    this.$http({
      url: `${this.urlApi}/FleetCam/DeleteDriverIndexedFaces`,
      method: 'POST',
      data: {
        request: {
          list: this.selectedRows.map(item => item.key),
        },
      },
    }).then(result => {
      if (result.data.data !== true) {
        this.toastText = 'Ocorreu um erro ao deletar a imagem, tente novamente mais tarde';
        this.$.querySelector('#indexation-toast').toggle(true);
        return;
      }

      this.selectedRows = [];
      this.images = this.images.filter(item => !item.selected);

      if (this.session.fleetCamProvider !== FleetCamProviderEnum.V3) {
        this.reindexIA();
        this.toastText =
          'Imagens Deletadas. Treinando a Inteligencia Artificial de reconhecimento facial...';
        this.$.querySelector('#indexation-toast').toggle(true);
      } else {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.$.querySelector('#indexation-toast').toggle(false);
      }
    });
  }

  reindexIA() {
    this.$http({
      url: `${this.urlApi}/FleetCam/ResetDriverFaces`,
      method: 'POST',
      data: {
        request: {
          driverid: this.driverid,
        },
      },
    }).then(result => {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      if (result.status && result.status !== 200) {
        this.$.querySelector('#indexation-toast').toggle(true);
        Object.assign(this, {
          toast: { text: 'Ops! Ocorreu um erro com a solicitação, tente novamente' },
        });
        return;
      }
      this.toastText = 'I.A. treinada com sucesso';

      setTimeout(() => {
        this.$.querySelector('#indexation-toast').toggle(false);
      }, 3600);
    });
  }

  /* Private */

  _getDriverImageKeys() {
    this.$http({
      url: `${this.urlApi}/FleetCam/GetDriverImageKeysR1`,
      method: 'POST',
      data: {
        request: {
          driverid: this.driverid,
          limit: -1,
        },
      },
    }).then(success => {
      const { data } = success.data;

      if (success.status && success.status !== 200) {
        this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
        Object.assign(this, { toast: { text: data.text } });
        return;
      }

      if (data) {
        this.dataKeys = data.keys;

        if (this.dataKeys.length == 0) {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        }
      }
    });
  }

  _getDriverImages() {
    this.$http({
      url: `${this.urlApi}/FleetCam/GetDriverImages`,
      method: 'POST',
      data: {
        request: {
          driverUlid: this.driverulid,
          limit: -1,
        },
      },
    })
      .then(success => {
        const { data } = success.data;

        if (success.status && success.status !== 200) {
          this.$.querySelector('power-toast#form-fleet-policy-toast').toggle(true);
          Object.assign(this, { toast: { text: data.text } });
          return;
        }

        if (data?.length > 0) {
          this.images = [];

          data.forEach(item => {
            this.images.push({
              ...{},
              image: item.image,
              key: item.key,
              selected: false,
            });
          });
        }
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _loadDriverImages() {
    this.images = [];

    if (this.dataKeys.length < 1) {
      return;
    }

    this.dataKeys.forEach(key => {
      this.$http({
        url: `${this.urlApi}/FleetCam/GetDriverImageR1`,
        method: 'POST',
        data: {
          request: {
            key,
          },
        },
      })
        .then(result => result.data.data)
        .then(result => {
          this.images.push({
            ...{},
            key: result.key,
            image: result.image,
            selected: false,
          });
        });
    });
  }

  _checkIfFinished() {
    if (
      this.images.length === this.dataKeys.length &&
      this.images.length > 0 &&
      this.dataKeys.length > 0
    ) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  _toggleCardSelection(indexationItem) {
    if (
      this.maxSelectedRows &&
      this.selectedRows.length >= this.maxSelectedRows &&
      !indexationItem.selected
    ) {
      this.toastText = `É possível selecionar no máximo ${this.maxSelectedRows} fotos para deletar`;
      this.$.querySelector('#indexation-toast').toggle(true);

      setTimeout(() => {
        this.$.querySelector('#indexation-toast').toggle(false);
      }, 3600);
      return;
    }
    indexationItem.selected = !indexationItem.selected;
    this.selectedRows = this.images.filter(item => item.selected);
  }

  _viewPhoto(event, data) {
    event.stopPropagation();
    this.viewPhotoList = [
      {
        url: this._getImageUrl(data),
        ready: true,
      },
    ];
    this._toggleViewPhotoPopup();
  }

  _toggleViewPhotoPopup() {
    this.$.querySelector('#popup-indexation-view-photo').toggle();
  }

  _toggleCardLoaderById(loaderId) {
    this.$.querySelector(`#${loaderId}`).toggle(false);
  }

  _getImageUrl(data) {
    return this.session.fleetCamProvider === FleetCamProviderEnum.R1
      ? `url(data:image/jpeg;base64,${data.image})`
      : `url(${data.image})`;
  }
  /* */

  /* Observers */
  /* */
}

class PowerPhotoIndexationStandAlone {
  constructor() {
    this.template = template;
    this.bindings = {
      page: '=',
      pageSize: '=',
      driverid: '=',
      driverulid: '=',
      selectedRows: '=',
      maxSelectedRows: '=',
    };
    this.controller = PowerPhotoIndexationStandAloneController;
  }
}

angular
  .module('power-photo-indexation-stand-alone', [
    'ngRedux',
    'power-toast',
    'power-popup',
    'infinite-scroll',
    'power-single-checkbox',
  ])
  .component('powerPhotoIndexationStandAlone', new PowerPhotoIndexationStandAlone());
