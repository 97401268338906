import angular from 'angular';
import * as Comlink from 'comlink';
import 'angular-sanitize';

import { toDate } from '@power/power-components/utils/date-util.js';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-driver-ai-tiebreaker/power-popup-driver-ai-tiebreaker';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-resize/ng-resize';
import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import { GolfleetGridMultiEntitiesController } from '../golfleet-grid-multi-entities/golfleet-grid-multi-entities';

import template from './golfleet-grid-temp-violation.html';
import './golfleet-grid-temp-violation.scss';

export const IdentificationTypeEnum = {
  FACEID_IDENTIFICATION: 3,
  IDENTIFICATION_IMAGE_PENDING: 4,
  IA_IDENTIFICATION: 6,
  IA_IDENTIFICATION_PENDING: 7,
};

class GolfleetGridTempViolationController extends GolfleetGridMultiEntitiesController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
  ) {
    super($element, $ngRedux, $scope, $state, $http, $timeout, $filter, commonServices, urlApi);

    this.toastText = '';

    this.searchDriver = '';

    this.driverList = [{ id: null, description: 'Não identificado' }, { holder: true }];

    this.driverImagePopup = {
      showIndex: 0,
      detailList: [],
      shouldReload: false,
    };

    this.onClickEvent = null;

    this.__appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        session: behavior.session,
      }),
    )(this);

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./golfleet-grid-temp-violation.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this._getDriverList().then(result =>
      Object.assign(this.driverList, this.driverList.concat(result.data.data)),
    );
  }
  /* */

  /* Public */
  /* */

  /* Private */
  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _evtRefreshGrid() {
    Object.assign(this, {
      toastText: 'Alteração de condutor enviado para processamento com sucesso!',
    });
    this.$.querySelector('power-toast#grid-toast').toggle(true);
    this.getDataset();
  }

  _toggleDriverIconPopup(open, row) {
    // @NOTE: se for identificação por IA ou IA Pendente
    if (row.tipoIdentificacao == 6 || row.tipoIdentificacao == 7) {
      const popupDriverAi = this.$.querySelector('power-popup-driver-ai-tiebreaker');

      if (
        row.idCondutor != null ||
        (row.idCondutor == null && row.condutor != 'Em Processamento')
      ) {
        if (popupDriverAi) {
          const requestInitialDate = this._toDate(new Date(row.dataHoraInicioViagem));
          popupDriverAi.toggle(row.idVeiculo, row.placa, requestInitialDate, row.idCondutor);
        }
      }
    }

    this._toggleDriverImagePopup(open, row);
  }

  _toggleDriverImagePopup(open, row) {
    // @NOTE: se for identificação diferente de reconhecimento facial, a função é encerrada.
    if (row && row.tipoIdentificacao != 3 && row.tipoIdentificacao != 4) {
      return;
    }

    this.searchDriver = '';
    if (!this.onClickEvent)
      this.onClickEvent = this.$.querySelector('.power-dropdown-button').addEventListener(
        'click',
        this._focusSearch.bind(this),
      );
    if (open) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      this.$http({
        url: `${this.urlApi}/FleetCam/GetDriversFace`,
        method: 'POST',
        data: {
          request: {
            identificationType: row.tipoIdentificacao,
            beginDate: this._parseDate(new Date(row.dataHoraInicioViagem)),
            endDate: this._parseDate(
              row.dataHoraFimViagem ? new Date(row.dataHoraFimViagem) : new Date(),
            ),
            vehicleId: row.idVeiculo,
          },
        },
      })
        .then(
          result => {
            Object.assign(this.driverImagePopup, {
              showIndex: 0,
              detailList: result.data.data.map(detail =>
                Object.assign(detail, {
                  latitude: row.latitude,
                  longitude: row.longitude,
                  driverList: this._parseDriverList(detail),
                  selectedDriver: {
                    id: result.data.data[0].mrvTmtId || null,
                    ulid: result.data.data[0].tmtUlid || null,
                  },
                  previousSelectedDriver: detail.mrvTmtId || null,
                }),
              ),
            });
            this.$.querySelector('power-popup').toggle();
          },
          error => {
            Object.assign(this, { toastText: error.data.data[0] });
            this.$.querySelector('power-toast#grid-toast').toggle(true);
          },
        )
        .finally(() => {
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
          if (
            this.driverImagePopup.detailList.length > 0 &&
            this.driverImagePopup.detailList[0].image
          ) {
            const drawImageInterval = setInterval(() => {
              if (document.querySelector('#driver-image-popup-body').clientWidth) {
                this._drawImage(
                  this.driverImagePopup.detailList[0].image.isUrl
                    ? this.driverImagePopup.detailList[0].image.result
                    : `data:image/jpeg;base64,${this.driverImagePopup.detailList[0].image.result}`,
                  document.createElement('canvas'),
                  this.$.querySelector('#driver-image-popup-body'),
                );

                if (this.session.trainingMode) {
                  const canvas = this.$.querySelector('canvas');
                  canvas.classList.add('blurred');
                }
                clearInterval(drawImageInterval);
              }
            }, 250);
          }
        });
    } else {
      if (this.driverImagePopup.shouldReload) {
        this.getDataset();
        this.driverImagePopup.shouldReload = false;
      }
      this.$.querySelector('power-popup').toggle();
    }
  }

  _changeDriver(index) {
    this.driverImagePopup.showIndex = index;
    this._drawImage(
      this.driverImagePopup.detailList[index].image.isUrl
        ? this.driverImagePopup.detailList[index].image.result
        : `data:image/jpeg;base64,${this.driverImagePopup.detailList[index].image.result}`,
      document.createElement('canvas'),
      this.$.querySelector('#driver-image-popup-body'),
    );

    if (this.session.trainingMode) {
      const canvas = this.$.querySelector('#driver-image-popup-body > canvas');
      canvas.classList.add('blurred');
    }
  }

  _setDriver(selectedDriver) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    const driverImageDetail = this.driverImagePopup.detailList[this.driverImagePopup.showIndex];
    this.$http({
      url: `${this.urlApi}/FleetCam/IdentifyDriver`,
      method: 'POST',
      data: {
        request: {
          tmtId: selectedDriver.id,
          tmsId: driverImageDetail.tmsId,
          mrvId: driverImageDetail.mrvId,
          mrvVeiId: driverImageDetail.mrvVeiId,
          mrvDataHoraExibicao: new Date(driverImageDetail.mrvDataHoraExibicao),
          evento: driverImageDetail.evento,
          latitude: driverImageDetail.latitude,
          longitude: driverImageDetail.longitude,
          imageName: driverImageDetail.driverFaces,
          bucket: driverImageDetail.bucketDriverFaces,
          tmtUlid: selectedDriver.ulid,
        },
      },
    })
      .then(
        () => {
          this.driverImagePopup.shouldReload = true;
          Object.assign(this, { toastText: 'Condutor selecionado' });
          this.$.querySelector('power-toast#grid-toast').toggle(true);
        },
        () => {
          Object.assign(this, {
            toastText: 'Ops, não foi possível selecionar o Condutor',
          });
          this.$.querySelector('power-toast#grid-toast').toggle(true);
        },
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _getDriverList() {
    return this.$http({
      url: `${this.urlApi}/Driver/GetNames`,
      method: 'POST',
    });
  }

  _getDriverDescription(detail, defaultText) {
    if (!detail) return defaultText;
    const selectedDriver = detail.driverList.filter(
      driver => driver.id == detail.selectedDriver.id,
    )[0];
    if (selectedDriver) return selectedDriver.description;
    return defaultText;
  }

  _parseDriverList(detail) {
    const driverList = Object.clone([], this.driverList);
    if (driverList.find(driver => driver.id == detail.mrvTmtId)) return driverList;
    return driverList.map(driver =>
      !driver.holder
        ? driver
        : Object.assign(driver, {
            id: detail.mrvTmtId,
            description: detail.tmtNome,
          }),
    );
  }

  _drawImage(url, canvas, canvasContainer) {
    this.$.querySelector('#driver-image-popup-loader').toggle(true);
    if (canvasContainer.querySelector('canvas'))
      canvasContainer.replaceChild(canvas, canvasContainer.querySelector('canvas'));
    else canvasContainer.appendChild(canvas);
    Object.assign(canvas, {
      width: canvasContainer.clientWidth,
      height: canvasContainer.clientHeight,
    });

    const image = new Image();
    const ctx = canvas.getContext('2d');
    let lastX = canvas.width / 2;
    let lastY = canvas.height / 2;
    let dragStart;
    let dragged;

    const redraw = function redraw() {
      const p1 = ctx.transformedPoint(0, 0);
      const p2 = ctx.transformedPoint(canvas.width, canvas.height);
      ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);

      if (canvas.width > image.width) {
        ctx.drawImage(image, 0, 0);
      } else {
        ctx.drawImage(image, 0, 0, canvas.width, (canvas.width * image.height) / image.width);
      }

      ctx.save();
    };
    const zoom = function zoom(clicks) {
      const pt = ctx.transformedPoint(lastX, lastY);
      ctx.translate(pt.x, pt.y);
      // eslint-disable-next-line no-restricted-properties
      const factor = Math.pow(1.1, clicks);
      ctx.scale(factor, factor);
      ctx.translate(-pt.x, -pt.y);
      redraw();
    };
    const handleScroll = function handleScroll(evt) {
      // eslint-disable-next-line no-nested-ternary
      const delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      if (delta) zoom(delta);
      return evt.preventDefault() && false;
    };
    const handleMouseUp = function handleMouseUp(evt) {
      dragStart = null;
      if (!dragged) zoom(evt.shiftKey ? -1 : 1);
    };
    const handleMouseDown = function handleMouseDown(evt) {
      document.body.style.userSelect = 'none';
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragStart = ctx.transformedPoint(lastX, lastY);
      dragged = false;
    };
    const handleMouseMove = function handleMouseMove(evt) {
      lastX = evt.offsetX || evt.pageX - canvas.offsetLeft;
      lastY = evt.offsetY || evt.pageY - canvas.offsetTop;
      dragged = true;
      if (dragStart) {
        const pt = ctx.transformedPoint(lastX, lastY);
        ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y);
        redraw();
      }
    };
    // eslint-disable-next-line no-shadow
    const trackTransforms = function trackTransforms(ctx) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const savedTransforms = [];
      let xform = svg.createSVGMatrix();
      ctx.getTransform = () => xform;

      const { save } = ctx;
      ctx.save = () => {
        savedTransforms.push(xform.translate(0, 0));
        return save.call(ctx);
      };

      const { restore } = ctx;
      ctx.restore = () => {
        xform = savedTransforms.pop();
        return restore.call(ctx);
      };

      const { scale } = ctx;
      ctx.scale = (sx, sy) => {
        xform = xform.scaleNonUniform(sx, sy);
        return scale.call(ctx, sx, sy);
      };

      const { rotate } = ctx;
      ctx.rotate = radians => {
        xform = xform.rotate((radians * 180) / Math.PI);
        return rotate.call(ctx, radians);
      };

      const { translate } = ctx;
      ctx.translate = (dx, dy) => {
        xform = xform.translate(dx, dy);
        return translate.call(ctx, dx, dy);
      };

      const { transform } = ctx;
      ctx.transform = (a, b, c, d, e, f) => {
        const m2 = svg.createSVGMatrix();
        Object.assign(m2, { a, b, c, d, e, f });
        xform = xform.multiply(m2);
        return transform.call(ctx, a, b, c, d, e, f);
      };

      const { setTransform } = ctx;
      ctx.setTransform = (a, b, c, d, e, f) => {
        Object.assign(xform, { a, b, c, d, e, f });
        return setTransform.call(ctx, a, b, c, d, e, f);
      };

      const pt = svg.createSVGPoint();
      ctx.transformedPoint = (x, y) => {
        Object.assign(pt, { x, y });
        return pt.matrixTransform(xform.inverse());
      };
    };

    image.onload = () => {
      trackTransforms(ctx);
      redraw();
      setTimeout(() => zoom(0));
      this.$.querySelector('#driver-image-popup-loader').toggle(false);
    };

    image.src = url;

    canvas.addEventListener('mousedown', handleMouseDown, false);
    canvas.addEventListener('mousemove', handleMouseMove, false);
    canvas.addEventListener('mouseup', handleMouseUp, false);

    canvas.addEventListener('DOMMouseScroll', handleScroll, false);
    canvas.addEventListener('mousewheel', handleScroll, false);
  }

  _focusSearch() {
    setTimeout(() => this.$.querySelector('search-slot input').focus(), 250);
  }

  _selectRow(column, row) {
    const result = super._selectRow(column, row);

    switch (column.type) {
      case 'Icon':
        return !!row.latitude && !!row.longitude;
      case 'DriverIcon':
        return (
          [
            IdentificationTypeEnum.FACEID_IDENTIFICATION,
            IdentificationTypeEnum.IDENTIFICATION_IMAGE_PENDING,
            IdentificationTypeEnum.IA_IDENTIFICATION,
            IdentificationTypeEnum.IA_IDENTIFICATION_PENDING,
          ].includes(row.tipoIdentificacao) && !!row[column.field]
        );
      default:
        return result;
    }
  }

  _toDate(date) {
    return toDate(date);
  }

  _selectDriver(driverId, driverUlid, showIndex) {
    this.driverImagePopup.detailList[showIndex].selectedDriver = {
      id: driverId,
      ulid: driverUlid,
    };
  }
  /* */
}

class GolfleetGridTempViolation {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridTempViolationController;
  }
}

angular
  .module('golfleet-grid-temp-violation', [
    'ngSanitize',
    'power-dropdown',
    'power-popup',
    'power-popup-driver-ai-tiebreaker',
    'power-toast',
    'ng-resize',
    'infinite-scroll',
  ])
  .component('golfleetGridTempViolation', new GolfleetGridTempViolation());
