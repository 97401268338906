/* global H: true */
import { PowerMapHereProvider } from '../../../power-map/providers/here/power-map.here-provider';
import './power-map-points-of-interest.here-provider.scss';

class PowerMapPointsOfInterestHereProvider extends PowerMapHereProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.context.pointsOfInterestController = {
      callback: null,
      type: 'point',
      positionList: [],
      renderPointDrawDataset: () => {
        const { markerDataset } = this.context.pointsOfInterestController.positionList.reduce(
          (acc, position, index) => {
            acc.markerDataset = [
              ...acc.markerDataset,
              {
                geoJson: {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [position.lng, position.lat],
                  },
                  properties: {
                    latitude: position.lat,
                    longitude: position.lng,
                    isDrawPoint: true,
                    drawIndex: index,
                  },
                },
              },
            ];

            return acc;
          },
          { markerDataset: [] },
        );
        this.renderDataset({ dataset: markerDataset, layerName: 'drawMarker' });
      },
      // Tap Event
      tapEventListener: evt => {
        if (this.context.pointsOfInterestController.type === 'point')
          this.context.pointsOfInterestController.pointTapHandler(evt);
      },
      pointTapHandler: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset) {
          this._removeSearchMarker();

          if (
            (targetDataset.latitude !=
              this.context.pointsOfInterestController.positionList[0]?.lat &&
              targetDataset.longitude !=
                this.context.pointsOfInterestController.positionList[0]?.lng) ||
            this.context.pointsOfInterestController.positionList.length == 0
          ) {
            if (!targetDataset?.id) {
              this.context.pointsOfInterestController.positionList = [
                this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY),
              ];
            } else {
              this.context.pointsOfInterestController.positionList = [
                { lat: targetDataset.latitude, lng: targetDataset.longitude },
              ];
            }

            this.context.pointsOfInterestController.renderPointDrawDataset();
            this.setCurrentPoint({
              latitude: this.context.pointsOfInterestController.positionList[0].lat,
              longitude: this.context.pointsOfInterestController.positionList[0].lng,
            });
            this.toggleNearBySearch(false);
          }
        }
      },
      // Drag Event
      dragEventListener: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset.isDrawPoint && this.context.pointsOfInterestController.type === 'point') {
          this.context.pointsOfInterestController.pointTapHandler(evt);
        }
      },
      // Drag Start Event
      dragStartEventListener: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset.isDrawPoint) this.behavior.disable();

        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grabbing';
        }
      },
      // Drag End Event
      dragEndEventListener: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset.isDrawPoint) {
          this.behavior.enable();
          this.context.pointsOfInterestController.renderPointDrawDataset();
        }

        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grab';
        }
      },
      // Pointer Move Event
      pointerEnterEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grab';
        }
      },
      pointerLeaveEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'pointer';
        }
      },
    };

    this.currentPoint = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      startDraw: this.startDraw.bind(this),
      endDraw: this.endDraw.bind(this),
      cancelDraw: this.cancelDraw.bind(this),
      setCurrentPoint: this.setCurrentPoint.bind(this),
      toggleNearBySearch: this.toggleNearBySearch.bind(this),
      requestGeocoding: this.requestGeocoding.bind(this),
    });

    super.$onInit();
  }
  /**/

  /* Public */
  startDraw({ callback, dataset }) {
    this.$.setAttribute('draw', '');
    this.context.pointsOfInterestController.callback = callback;
    this.map.addEventListener(
      'tap',
      this.context.pointsOfInterestController.tapEventListener,
      true,
    );
    this.map.addEventListener(
      'drag',
      this.context.pointsOfInterestController.dragEventListener,
      true,
    );
    this.map.addEventListener(
      'dragstart',
      this.context.pointsOfInterestController.dragStartEventListener,
      true,
    );
    this.map.addEventListener(
      'dragend',
      this.context.pointsOfInterestController.dragEndEventListener,
      true,
    );

    this.map.addEventListener(
      'pointermove',
      this.context.pointsOfInterestController.pointerEnterEventListener,
      true,
    );
    this.map.addEventListener(
      'pointerleave',
      this.context.pointsOfInterestController.pointerLeaveEventListener,
      true,
    );

    if (dataset?.geoJson) {
      this.selectDrawShape('point');
      this.context.pointsOfInterestController.positionList = [
        { lat: dataset.latitude, lng: dataset.longitude },
      ];
      this.context.pointsOfInterestController.renderPointDrawDataset();
      const zoomEditItem = () => {
        const { bbox } = dataset.geoJson;
        this.fitBounds(new H.geo.Rect(bbox[1], bbox[0], bbox[3], bbox[2]));
      };

      if (!this.mapLayers.geofences) {
        this.awaitRender().then(() => zoomEditItem());
        return;
      }

      zoomEditItem();
    }
  }

  endDraw() {
    if (this.context.pointsOfInterestController.callback) {
      this.context.pointsOfInterestController.callback({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            this.context.pointsOfInterestController.positionList[0]
              ? this.context.pointsOfInterestController.positionList[0].lng
              : null,
            this.context.pointsOfInterestController.positionList[0]
              ? this.context.pointsOfInterestController.positionList[0].lat
              : null,
          ],
        },
        properties: {},
      });
      this.context.pointsOfInterestController.callback = null;
    }
    this.cancelDraw();
  }

  cancelDraw() {
    this.$.removeAttribute('draw');
    this.map.removeEventListener(
      'tap',
      this.context.pointsOfInterestController.tapEventListener,
      true,
    );
    this.map.removeEventListener(
      'drag',
      this.context.pointsOfInterestController.dragEventListener,
      true,
    );
    this.map.removeEventListener(
      'dragstart',
      this.context.pointsOfInterestController.dragStartEventListener,
      true,
    );
    this.map.removeEventListener(
      'dragend',
      this.context.pointsOfInterestController.dragEndEventListener,
      true,
    );

    this.context.pointsOfInterestController.positionList = [];
    this.removeLayers(['drawCircle', 'drawMarker', 'drawLinestrings']);
    this.$.dispatchEvent(new CustomEvent('drawLayerRemoved', { bubbles: true, composed: true }));
  }

  selectDrawShape(shape) {
    this.context.pointsOfInterestController.type = shape;
    this.context.pointsOfInterestController.positionList = [];
    this.removeLayers(['drawCircle', 'drawMarker', 'drawLinestrings']);
  }

  setCurrentPoint(dataset) {
    this.currentPoint = dataset;
  }

  toggleNearBySearch(isClickIcon) {
    this._addSearchMarkerToCurrentPoint();
    if (this.currentPoint) {
      const cardGeoVehicle = document.querySelector('.card-geo-vehicle');

      if (
        !isClickIcon &&
        cardGeoVehicle.hasAttribute('active') &&
        cardGeoVehicle.getElementsByTagName('li').length > 0
      ) {
        this._searchNearBy(this.currentPoint.latitude, this.currentPoint.longitude);
      } else if (!cardGeoVehicle.hasAttribute('active')) {
        cardGeoVehicle.setAttribute('active', '');
        this._searchNearBy(this.currentPoint.latitude, this.currentPoint.longitude);
      } else {
        cardGeoVehicle.removeAttribute('active');
        this.context._nearbyVehicleList = [];
      }
    }
  }

  requestGeocoding(address) {
    this.removeLayers(['drawMarker']);
    super.requestGeocoding(address);

    const cardGeoVehicle = document.querySelector('.card-geo-vehicle');
    cardGeoVehicle.removeAttribute('active');
    this.context._nearbyVehicleList = [];
  }
  /**/

  /* Private */
  _initializeMap() {
    super._initializeMap();
    this.ui.removeControl('distancemeasurement');
  }

  _geoJsonToMarker({ geoJson }) {
    switch (geoJson.properties.categoryType) {
      case 1:
        geoJson.properties.color = '#315A94';
        break;
      case 2:
        geoJson.properties.color = '#8A4B08';
        break;
      case 3:
        geoJson.properties.color = '#EE1131';
        break;
      case 4:
        geoJson.properties.color = '#FFBF00';
        break;
      case 5:
        geoJson.properties.color = '#04B404';
        break;
      default:
        geoJson.properties.color = '#555555';
        break;
    }

    this.map.addEventListener(
      'tap',
      this.context.pointsOfInterestController.tapEventListener,
      true,
    );

    const [lng, lat] = geoJson.geometry.coordinates;
    const marker = new H.map.DomMarker(
      { lat, lng },
      {
        icon: this._createMarkerIcon(geoJson.properties.color),
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.name}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.description || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(6)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(6)} </span>
            </div>
          `,
        },
      },
    );
    marker.draggable = true;
    return marker;
  }

  _createMarkerIcon(color) {
    return new H.map.DomIcon(
      `
    <svg xmlns="http://www.w3.org/2000/svg"
    width="28px" height="36px" style="margin: -32px 0px 0px -14px; z-index: 3; transform: matrix(1, 0, 0, 1, 690, 192); position: absolute;">
      <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
      <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
      <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${
        color || 'var(--primary-color)'
      }"></path></svg>
    `,
    );
  }

  _searchNearBy(lat, lng) {
    this.$http({
      url: `${this.urlApi}/Vehicle/GetVehiclesByCoordinatesInPointOfInterest`,
      method: 'POST',
      data: {
        latitude: lat,
        longitude: lng,
      },
    }).then(
      success => {
        this.context._nearbyVehicleList = success.data.data;
      },
      () => {
        this.context._nearbyVehicleList = [];
      },
    );
  }

  _removeSearchMarker() {
    const markerSearch = this.map.getObjects();
    for (let i = 0; i < markerSearch.length; i++) {
      // eslint-disable-next-line no-undef
      if (markerSearch[i] instanceof H.map.Marker) {
        if (markerSearch[i] != this._tempMarker) this.map.removeObject(markerSearch[i]);
      }
    }
  }

  _addSearchMarkerToCurrentPoint() {
    if (!this.currentPoint) {
      const markerSearch = this.map.getObjects();
      for (let i = 0; i < markerSearch.length; i++) {
        // eslint-disable-next-line no-undef
        if (markerSearch[i] instanceof H.map.Marker) {
          this.setCurrentPoint({
            latitude: markerSearch[i].b.lat,
            longitude: markerSearch[i].b.lng,
          });
        }
      }
    }
  }

  _showMarker(vehicle) {
    if (this._tempMarker) {
      this.map.removeObject(this._tempMarker);
    }
    this._tempMarker = new H.map.Marker({
      lat: vehicle.latitude,
      lng: vehicle.longitude,
    });

    this.map.addObject(this._tempMarker);
    this.zoomTo(vehicle);
  }
}

export { PowerMapPointsOfInterestHereProvider };
