import angular from 'angular';

import '../../directives/infinite-scroll/infinite-scroll';
import { PowerFilterController, PowerFilter } from '../power-filter/power-filter';

import template from './power-filter-select.html';
import './power-filter-select.scss';

class PowerFilterSelectController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi'];
  }

  /* Lifecycle */
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }
  /* */

  /* Private */
  _initializeFilter() {
    if (this.condition)
      this._selectOption(
        this.options.filter(option => this.condition.value.includes(option.value))[0],
      );
    else if (this.default)
      this._selectOption(this.options.filter(option => this.default.includes(option.value))[0]);
    else {
      this.setFilterValue([]);
      this._formatDescription([]);
    }
  }

  _formatDescription(selectedOptions) {
    if (!Array.isArray(selectedOptions)) return;
    this.description = this._formatDescriptionWords(selectedOptions[0].description);
  }

  _selectOption(selectOption) {
    this.options = this.options.map(option =>
      Object.assign(option, { selected: selectOption.id == option.id }),
    );
    const selectedOptions = this.options.filter(option => option.selected);
    this.setFilterValue(selectedOptions.map(option => option.value));
    this._formatDescription(selectedOptions);
  }
  /* */
}

class PowerFilterSelect extends PowerFilter {
  constructor() {
    super();
    this.controller = PowerFilterSelectController;
    this.template = template;
  }
}

angular
  .module('power-filter-select', ['infinite-scroll'])
  .component('powerFilterSelect', new PowerFilterSelect());
