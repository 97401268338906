import angular from 'angular';

import { FilterCondition } from '../power-filter/power-filter';

import template from './power-filter-range-advanced.html';
import './power-filter-range-advanced.scss';

class PowerFilterRangeAdvancedController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });

    this._minValue = null;
    this._maxValue = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });

    this.description = this.description || 'Carregando...';
    this.selectors = this.selectors || [];
    this.filters = this.filters || [];

    this._initializeFilter();
  }

  $onDestroy() {}
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }

  // setFilterValue(value) {
  //   this.condition[0] = new FilterCondition(this.id, this.field, value);
  // }

  setFilterValue(value, conditionId) {
    this.filters.forEach((filter, index) => {
      if (conditionId === filter.id)
        this.condition[index] = new FilterCondition(filter.id, filter.field, value);
    });
  }
  /* */

  /* Private */
  _initializeFilter() {
    if (!this.condition) {
      this.condition = this.filters.map(filter => {
        const condition = new FilterCondition(
          filter.id,
          filter.field,
          filter.default || [null, null],
        );
        return condition;
      });
    }

    this._formatDescription();
  }

  _formatDescriptionWords(str) {
    const formatWord = index => (index == 0 ? 'toUpperCase' : 'toLowerCase');
    return str.split(' ').reduce((acc, word, index) => {
      if (
        !word ||
        ((word[0] == '^' || word[0] == '!') && !word[1]) ||
        ((word[1] == '^' || word[1] == '!') && !word[2])
      )
        return acc;
      if (word[0] == '!' && word[1] == '!') return `${acc} ${word.slice(2)}`;
      if (word[0] == '^' && word[1] == '^')
        return `${acc} ${word[2][formatWord(0)]()}${word.slice(3)[formatWord(0)]()}`;
      if (word[0] == '^')
        return `${acc} ${word[1][formatWord(0)]()}${word.slice(2)[formatWord(1)]()}`;
      return `${acc} ${word[0][formatWord(index)]()}${word.slice(1)[formatWord(1)]()}`;
    }, '');
  }

  _formatDescription() {
    const conditions = this.condition.filter(c => c.value[0] || c.value[1]);

    if (conditions.length === 0) {
      this.description = this._formatDescriptionWords(`Qualquer ${this.singular}`);
    } else if (conditions.length === 1) {
      const [condition] = conditions;
      const [min, max] = condition.value;

      const filter = this.filters.find(f => f.field === condition.field);

      if (!min)
        this.description = this._formatDescriptionWords(
          `${filter.singular} abaixo de ${filter.prefix} ${max} ${filter.sufix}`,
        );
      else if (!max)
        this.description = this._formatDescriptionWords(
          `${filter.singular} acima de ${filter.prefix} ${min} ${filter.sufix}`,
        );
      else
        this.description = this._formatDescriptionWords(
          `${filter.singular} entre ${filter.prefix} ${min}` +
            ` ${filter.sufix} e ${filter.prefix} ${max} ${filter.sufix}`,
        );
    } else {
      const count = conditions.reduce((acc, condition) => {
        const [min, max] = condition.value;
        if (min) acc++;
        if (max) acc++;
        return acc;
      }, 0);

      this.description = this._formatDescriptionWords(`${count} condições de ${this.singular}`);
    }
  }

  _clearMinValue(filterId) {
    const condition = this.condition.find(item => item.id === filterId);
    condition.value[0] = null;
    this._formatDescription();
  }

  _clearMaxValue(filterId) {
    const condition = this.condition.find(item => item.id === filterId);
    condition.value[1] = null;
    this._formatDescription();
  }

  _filterSelectorClick(filterSelector) {
    this.filters
      .filter(item => item.selector)
      .forEach(item => {
        const condition = this.condition.find(c => c.id === item.id);
        condition.value = [null, null];
      });

    this.selectors.forEach(item => {
      if (item.selector === filterSelector.selector) item.selected = !filterSelector.selected;
      else item.selected = false;
    });

    this._formatDescription();
  }

  _getActiveSelector() {
    return this.selectors.find(item => item.selected) || {};
  }

  _getCondition(filterId) {
    return this.condition.find(item => item.id === filterId) || {};
  }

  _onFocus(event) {
    event.target.parentElement.setAttribute('focus', '');
  }

  _onBlur(event) {
    event.target.parentElement.removeAttribute('focus');
  }
  /* */

  /* Observers */
  __minValueChanged(filterId) {
    const condition = this.condition.find(item => item.id === filterId);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(condition.value[0]) || condition.value[0] <= 0) {
      this._clearMinValue(filterId);
    } else {
      condition.value[0] = Number(condition.value[0]);
      this._formatDescription();
    }
  }

  __maxValueChanged(filterId) {
    const condition = this.condition.find(item => item.id === filterId);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(condition.value[1]) || condition.value[1] <= 0) {
      this._clearMaxValue(filterId);
    } else {
      condition.value[1] = Number(condition.value[1]);
      this._formatDescription();
    }
  }
  /* */
}

class PowerFilterRangeAdvanced {
  constructor() {
    this.controller = PowerFilterRangeAdvancedController;
    this.template = template;
    this.bindings = {
      filterId: '=?',
      filterKey: '=?',
      field: '=?',
      singular: '=?',
      icon: '=?',
      description: '=?',
      visible: '=?',
      condition: '=?',
      filters: '=?',
      selectors: '=?',
    };
  }
}

angular
  .module('power-filter-range-advanced', [])
  .component('powerFilterRangeAdvanced', new PowerFilterRangeAdvanced());
