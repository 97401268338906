import angular from 'angular';

import template from './power-single-checkbox.html';
import './power-single-checkbox.scss';

class PowerSingleCheckboxController {
  static get $inject() {
    return ['$element', '$rootScope', '$scope'];
  }

  constructor($element, $rootScope, $scope) {
    Object.assign(this, { $: $element[0], $rootScope, $scope });

    this.model = !this.model && this.model !== 0 ? false : this.model;
    this.id = this.id || `single-checkbox-${Math.random().toString()}`;
    this.name = this.name || 'single-checkbox';

    this.keydownElementEvent = this._keydownElement.bind(this);
  }

  $onInit() {
    this.$.addEventListener('keydown', this.keydownElementEvent);
  }

  $onDestroy() {
    this.$.removeEventListener('keydown', this.keydownElementEvent);
  }

  _keydownElement(evt) {
    // 32. Space
    if (evt.keyCode == 32) this.model = !this.model;

    if (!this.$scope.$$phase && !this.$rootScope.$$phase) this.$scope.$apply();
  }
}

class PowerSingleCheckbox {
  constructor() {
    this.template = template;
    this.bindings = {
      id: '=?',
      name: '=?',
      model: '=',
      label: '=?',
      color: '=?',
    };
    this.controller = PowerSingleCheckboxController;
  }
}

angular
  .module('power-single-checkbox', [])
  .component('powerSingleCheckbox', new PowerSingleCheckbox());
