/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-period/power-period';
import '@power/power-components/components/power-radio-button-group/power-radio-button-group';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/directives/infinite-scroll/infinite-scroll';
import { PowerReportAlertsController } from '@power/power-components/components/power-report-alerts/power-report-alerts';

import '@power/power-components/components/power-card-alert/power-card-alert';
import '@power/power-components/components/power-custom-checkbox-list/power-custom-checkbox-list';
import '@power/power-components/components/power-simple-card-list/power-simple-card-list';

import template from './golfleet-report-alerts.html';
import './golfleet-report-alerts.scss';

class GolfleetReportAlertsController extends PowerReportAlertsController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      '$sce',
      '$rootScope',
    ];
  }

  /**
   * Creates an instance of ReportAlertsController.
   * @memberof ReportAlertsController
   */
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    $sce,
    $rootScope,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
      $sce,
      $rootScope,
    );

    Object.assign(this, { $sce, $rootScope });

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        session: behavior.session,
      }),
    )(this);

    this.driverIdentificationTypes = [
      {
        icon: 'insert_link',
        title: 'Identificação por Software',
        description:
          'Identifica automaticamente a viagem com o condutor que esteja vinculado ao veículo e todos os eventos da viagem (inclusive alertas em tempo real) são também automaticamente vinculados ao condutor.',
      },
      {
        icon: 'gs_rfid',
        title: 'Identificação por Acessório',
        description:
          'Realiza o reconhecimento do condutor mediante o uso do cartão RFID/iButton, vinculando-o à viagem e em todos os incidentes a ela relacionados, como infrações, além de alertas em tempo real após a identificação, tais como excesso de velocidade, entrada/saída de áreas de controle, entre outros.',
      },
      {
        icon: 'gs_ia_rec',
        title: 'Identificação por IA (GolfleetID)',
        description:
          'Após desligar o veículo, nossa IA entra em ação: ela relaciona dados do veículo e do aplicativo GolfleetID para identificar o condutor. Isso estabelece automaticamente a ligação entre o condutor e a viagem, assim como quaisquer eventos associados, como infrações, por exemplo.',
        validateModule: ['IDENTIFICACAO_IA'],
      },
      {
        icon: 'bluetooth',
        title: 'Identificação por Bluetooth (GolfleetID)',
        description:
          'Realiza o reconhecimento do condutor mediante a conexão Bluetooth do equipamento no veículo com o GolfleetID, vinculando-o à viagem e em todos os incidentes a ela relacionados, como infrações, além de alertas em tempo real após a identificação, tais como excesso de velocidade, entrada/saída de áreas de controle, entre outros.',
        validateModule: ['IDENT_BLUETOOTH_BUZZER', 'IDENT_BLUETOOTH_DESBLOQUEIO'],
      },
      {
        icon: 'person_outline',
        title: 'Identificação por Reconhecimento Facial',
        description:
          'Realiza o reconhecimento do condutor mediante fotos realizadas pelo equipamento no veículo, vinculando-o à viagem e aos incidentes a ela relacionados, como infrações, além de alertas em tempo real após a identificação, tais como excesso de velocidade, entrada/saída de áreas controle, entre outros.',
      },
    ];
  }

  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
}

class GolfleetReportAlerts {
  /**
   * Creates an instance of ReportAlerts.
   * @memberof ReportAlerts
   */
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportAlertsController;
  }
}

angular
  .module('golfleet-report-alerts', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-fab',
    'power-toolbar',
    'power-dropdown',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-radio-button-group',
    'power-toast',
    'power-simple-card-list',
    'power-card-alert',
    'power-custom-checkbox-list',
  ])
  .component('golfleetReportAlerts', new GolfleetReportAlerts());
