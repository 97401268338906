import angular from 'angular';
import moment from 'moment';
import 'ng-redux';
import { toDate, toDateTimeInput, toUTCDate } from '@power/power-components/utils/date-util.js';

import { ReportStateConfig } from '@power/power-components/components/power-state-config/power-state-config';

import '@power/power-components/components/power-toast/power-toast.js';
import template from './golfleet-form-order-service.html';
import './golfleet-form-order-service.scss';

import '@power/power-components/components/power-popup/power-popup';
import '../golfleet-popup-address/golfleet-popup-address';

import { RoutesConfig, GetRouteConfig } from '../../configs/routes/routes.config.js';

class ServiceVehicleItem {
  constructor(id, plate, serviceDescription, serviceDone) {
    this.vehicleId = id;
    this.plate = plate;
    this.service = serviceDescription;
    this.serviceDone = serviceDone;
  }
}

class GolfleetFormOrderServiceController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
      'crudServices',
      '$sce',
    ];
  }

  /**
   * Creates an instance of GolfleetFormOrderServiceController.
   * @memberof GolfleetFormOrderServiceController
   */
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    urlApi,
    crudServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi,
      crudServices,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* Session Storage */
        state: behavior.state,
        session: behavior.session,

        /* State Storage */
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.formConfig = [];
    this.form = [];
    this.staticFormData = [];
    this.canSubmit = true;
    this.data = null;
    this.disableSolicitationFields = false;

    this.priorityOptions = [
      {
        id: 0,
        value: 1,
        description: 'Baixa',
        selected: true,
      },
      {
        id: 1,
        value: 2,
        description: 'Média',
        selected: false,
      },
      {
        id: 2,
        value: 3,
        description: 'Alta',
        selected: false,
      },
    ];

    this.dataParamsVehiclesList = {
      idOs: null,
      startDate: null,
      finalDate: null,
    };

    this.utilizationsOsList = [];

    this.AuthorizationServiceVehicles = [];

    this.formConfigReady = false;
    this.formViewData = [];
    this.comboboxToLoad = [];
    this.staticFormData = [];
    this.osEditConfig = this._getOsIdFromState();
    this.dataset = null;

    this.model = {
      statusOs: false,
      status: 0,
    };

    this.disableFields = false;

    this.addressSelected = null;
  }

  /* Lifecycle */
  $onInit() {
    this.dataset = Object.clone({}, this.crudDataset);
    // this.$scope.$watch(() => this.model.startDate, this.__startDateChanged.bind(this));
    // this.$scope.$watch(() => this.model.finalDate, this.__finalDateChanged.bind(this));

    document
      .getElementById('initialDate')
      ?.addEventListener('focusout', this.__onStartDateChanged.bind(this));

    document
      .getElementById('finalDate')
      ?.addEventListener('focusout', this.__onFinalDateChanged.bind(this));

    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.$scope.$watch(() => JSON.stringify(this.model), this._setDisableFields.bind(this));

    this.$scope.$on('optionSelectedChange', (event, eventObject) =>
      this._callOptionSelectedChange(eventObject),
    );
    this.$scope.$on('changeAddress', (event, eventObject) => this._setAddress(eventObject));

    this._getFormConfig();
    this._checkReady();
    this.model.priority = 1;
  }

  _callOptionSelectedChange(eventObject) {
    this.AuthorizationServiceVehicles = [];
    eventObject.forEach(arg => {
      if (
        this.model.vehiclesServices &&
        this.model.vehiclesServices.filter(vei => vei.vehicleId === arg.id).length
      ) {
        const veicService = this.model.vehiclesServices.filter(vei => vei.vehicleId === arg.id)[0];
        if (veicService) {
          const { service, serviceDone } = veicService;
          this.AuthorizationServiceVehicles.push(
            new ServiceVehicleItem(arg.id, arg.description, service, serviceDone),
          );
        }
      } else if (arg.enable) {
        this.AuthorizationServiceVehicles.push(
          new ServiceVehicleItem(arg.id, arg.description, '', false),
        );
      } else if (!arg.enable) {
        this._removeVehicleSelectionById(arg.id);
      }
    });
  }

  _setAddress(eventObject) {
    this.addressSelected = eventObject;
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _checkReady() {
    setTimeout(() => {
      if (this.formConfigReady) {
        this._getFormData();
        this._formViewDataParser();
        this._setDeleteButtonState();
        this._setDisableFields();
        this.$scope.$apply();
      } else {
        this._checkReady();
      }
    }, 300);
  }

  _getOsIdFromState() {
    const route = this.state.routeList.filter(x => x.routeTail);
    if (route[0]) {
      const { stateConfig } = route[0];
      return {
        idOs: route[0].routeTail,
        getDataMethod: stateConfig.getDataMethod,
      };
    }
    return null;
  }

  _getFormConfig() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.commonServices.getFormConfiguration('OrderService').then(data => {
      if (data.form) {
        this.formConfig = data.form;
        const sections = this.formConfig.map(item => item.sections);
        this.comboboxToLoad = sections.forEach(section => {
          section.forEach(arg => {
            arg.items.forEach(item => {
              if (item.type == 'combobox' && item.loadFrom == 'RoutesConfig') {
                const routes = Object.keys(RoutesConfig);
                routes.forEach(route => {
                  if (
                    RoutesConfig[route].data &&
                    RoutesConfig[route].data.routeLink &&
                    RoutesConfig[route].data.routeName
                  ) {
                    const routeName =
                      RoutesConfig[route].data.routeLink.toUpperCase().includes('ADM') &&
                      !RoutesConfig[route].data.routeName.toUpperCase().includes('ADM')
                        ? `${RoutesConfig[route].data.routeName} Adm`
                        : RoutesConfig[route].data.routeName;
                    if (
                      !RoutesConfig[route].data.routeLink.toUpperCase().includes('TEMP') &&
                      !RoutesConfig[route].data.routeLink.toUpperCase().includes('FORM') &&
                      !RoutesConfig[route].data.routeLink.toUpperCase().includes('INFOADDON') &&
                      !RoutesConfig[route].data.routeLink
                        .toUpperCase()
                        .includes('TRAVELSBYUTILIZATION')
                    ) {
                      item.options.push({
                        id: RoutesConfig[route].data.routeLink,
                        description: RoutesConfig[route].data.routeSubName
                          ? RoutesConfig[route].data.routeSubName
                          : routeName,
                        value: RoutesConfig[route].data.routeLink,
                        selected: false,
                      });
                    }
                  }
                });
                item.options.sort((a, b) =>
                  a.description.replace(/[ÀÁÂÃÄÅ]/g, 'A') > b.description.replace(/[ÀÁÂÃÄÅ]/g, 'A')
                    ? 1
                    : -1,
                );
              }
            });
          });
        });
      }
      this.formConfigReady = true;
    });
  }

  _evtClickBtnPowerPopup() {
    this.$.querySelector('#golfleet-popup-select-address').togglePopup();
  }

  _formViewDataParser() {
    this._syncScope();
  }

  _syncScope() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) this.$scope.$apply();
  }

  _getFormData() {
    if (this.osEditConfig) {
      const { idOs, getDataMethod } = this.osEditConfig;
      const payload = {
        id: idOs,
      };
      this.crudServices
        .getData(getDataMethod, payload)
        .then(data => {
          this.data = data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: true },
              bubbles: true,
              composed: true,
            }),
          );

          if (data) {
            this.model = data;
            this.addressSelected = this.model.address;
            const address = {
              latitude: this.model.lat,
              longitude: this.model.lon,
              label: this.addressSelected.replace(/ *\([^)]*\) */g, ''),
            };
            this.model.startDate = this._toDate(this.data.startDate);
            this.model.finalDate = this._toDate(this.data.finalDate);
            document.querySelector('#golfleet-popup-select-address').setDataSet(address);
            this.model.idsListaVeiculos = this.model.vehiclesServices.map(vei => vei.vehicleId);
            if (this._showLastUtilizations()) {
              this._getLastUtillizationsOs();
            }
            this.disableSolicitationFields =
              this._disableSolicitationAndAuthorizationSectionFields();

            this.formConfig.map(config => {
              if (config.sections) {
                config.sections.forEach(section => {
                  if (section.items && section.items.length > 0) {
                    section.items.map(item => {
                      const field = this.data[`${item.field}`];
                      if (this.disableSolicitationFields) {
                        item.disabled = true;
                        item.triggerBlock = true;
                      }

                      item.value = field;

                      if (
                        (item.type == 'combobox' && item.value) ||
                        (item.type == 'combobox' && typeof item.value == 'boolean')
                      ) {
                        if (item.options && item.options.length > 0) {
                          item.options.forEach(option => {
                            option.selected = option.value == item.value;
                          });
                        }
                      }

                      if (item.inputType == 'DateTimeOffset' && field) {
                        item.value = toDateTimeInput({ date: toDate(field) });
                      }

                      if (item.inputMaskFormat && item.value) {
                        item.value = item.value.toString();
                      }

                      return item;
                    });
                  }
                });
              }
              return config;
            });

            this._syncScope();
          } else {
            this.$.querySelector('power-toast#form-os-crud-toast').toggle(true);
            Object.assign(this, {
              toast: {
                text: 'Nenhum dado encontrado.',
              },
            });
          }
        })
        .finally(() => {
          if (this.data) {
            const datepickers = this.$.querySelectorAll('power-crud-datepicker');
            for (let i = 0; i < datepickers.length; i++) {
              const field = datepickers[i].getAttribute('field');
              datepickers[i].setDate(this.data[field]);
            }
            this._syncScope();
          }

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    } else {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
    this.formReady = true;
  }

  _showLastUtilizations() {
    return this.model.statusOs && this.model.status > 1 && this.model.status != 5;
  }

  _removeVehicleSelectionById(id) {
    this.$.querySelector('#idsListaVeiculos').removeSelectedById(id);
  }

  _getUtilizationByVeiId(id) {
    return this.utilizationsOsList.find(value => value.id == id);
  }

  _disableSolicitationAndAuthorizationSectionFields() {
    return this.formConfigReady && this.model.status > 1;
  }

  _disableSolicitationFields() {
    this.$.querySelector('#solicitation-title').setDisabled(true);
  }

  _getServiceStatusByVeiId(id) {
    if (id && this.model.vehiclesServices && this.model.vehiclesServices.length > 0) {
      let { serviceDone } = this.model.vehiclesServices.find(value => value.vehicleId === id);
      serviceDone = !serviceDone;
      this.model.vehiclesServices.find(value => value.vehicleId === id).serviceDone = serviceDone;
      return serviceDone;
    }
    return null;
  }

  _getLastUtillizationsOs() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.model.idsListaVeiculos.forEach(vei => {
      const payload = {
        vehicleId: vei,
        startDate: this.model.startDate,
        finalDate: this.model.finalDate,
      };

      const msgError = 'Ocorreu um erro ao carregar as informações.';
      this.$http({
        url: `${this.urlApi}/OrderService/GetOrderServiceLastUtilizations`,
        method: 'POST',
        data: payload,
      })
        .then(
          success => {
            if (success.status && success.status !== 200) {
              this.toastObj = {
                text: msgError,
              };
              this.$.querySelector('#toast-obj').toggle(true);
            } else if (success.data && !success.data.hasError) {
              this.stateList = [...success.data.data];
              const result = {
                id: vei,
                utilizations: this.stateList,
              };
              this.utilizationsOsList.push(result);
            } else {
              this.toastObj = {
                text: msgError,
              };
              this.$.querySelector('#toast-obj').toggle(true);
            }
          },
          () => {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#toast-obj').toggle(true);
          },
        )
        .finally(() =>
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          ),
        );
    });
  }

  _toggleListItem(vehicleId, item) {
    const listItem = this.$.querySelector(`#summary-row-${vehicleId}-${item}`);
    if (listItem) {
      this.$.querySelector(`#summary-row-${vehicleId}-${item}`).toggle();
    }
  }

  _setDeleteButtonState() {
    const button = this.$.querySelector('.delete-btn');
    if (button) {
      if (!this.osEditConfig) {
        button.setAttribute('disabled', '');
      } else if (this.osEditConfig && button.hasAttribute('disabled')) {
        button.removeAttribute('disabled');
      }
    }
  }

  _setDisableFields() {
    this.disableFields =
      this.formConfigReady && this._disableSolicitationAndAuthorizationSectionFields();
  }

  _deleteCrudForm() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const request = { objects: [{ objectId: this.osEditConfig.idOs }] };

    this.crudServices
      .callDeleteMethod('OrderService/DeleteOrderService', request)
      .then(
        success => {
          if (success.data.data) {
            this.$.querySelector('power-toast#form-os-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Ordem de serviço excluída' } });
            setTimeout(() => {
              this._backTo(1);
            }, 1500);
          } else {
            this.$.querySelector('power-toast#form-os-crud-toast').toggle(true);
            Object.assign(this, {
              toast: { text: 'Ocorreu um erro ao excluir a ordem de serviço' },
            });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _goToLinkUtilizationReport(vehicleId) {
    const routeLink = 'utilizationReport';
    const routeConfig = GetRouteConfig(routeLink);
    if (routeConfig) {
      const initialDate = moment(this.model.startDate).utcOffset(-3)._d;
      const finalDate = moment(this.model.finalDate).utcOffset(-3)._d;
      const result = {
        ...routeConfig.data,
        stateConfig: routeConfig.data.stateConfig({
          filters: [
            {
              id: 1,
              default: [initialDate, finalDate],
            },
            {
              id: 2,
              activeView: 'entity',
              activeEntity: 'vehicle',
              default: [vehicleId],
              incluedHistoricalData: false,
            },
          ],
        }),
      };
      this.$state.go(routeLink, {}, { reload: true });
      this.$ngRedux.dispatch({
        type: 'NEXT_ROUTE',
        data: result,
      });
    }
  }

  _goToLinkPositionsReport(data, vehicleId) {
    const initialDate = moment(data.inicio).utcOffset(-3)._d;
    const finalDate = moment(data.fim).utcOffset(-3)._d;

    const getDataFilters = [
      {
        id: 0,
        default: [initialDate, finalDate],
      },
    ];

    const report = {
      routeName: 'Relatório de Posições',
      routeLink: 'positionsReport',
      linkStateConfig: {
        screenName: 'PositionsReport',
        gridName: 'List',
        toolbarName: 'List',
      },
      getDataMethod: 'TelematicsReport/Post',
      backPagination: false,
      getDataFixedParams: {
        id: vehicleId,
        vehicleIds: vehicleId,
      },
    };

    const stateConfig = new ReportStateConfig({
      isAdm: this.onAdmNavigationMode,
      viewMode: 'grid',
      getDataMethod: report.getDataMethod,
      backPagination: report.backPagination,
      getDataFilters,
      getDataFixedParams: report.getDataFixedParams,
    });
    Object.assign(
      stateConfig,
      Object.keys(report.linkStateConfig).reduce(
        (acc, item) => {
          acc[item] = report.linkStateConfig[item];
          return acc;
        },
        { ...stateConfig },
      ),
    );

    this.$ngRedux.dispatch({
      type: 'NEXT_ROUTE',
      data: {
        routeName: report.routeName,
        routeSubName: report.linkStateConfig?.routeSubName,
        routeLink: report.routeLink,
        routeTail: report.getDataFixedParams.id,
        stateConfig,
      },
    });
    this.$state.go(report.routeLink, { tail: report.getDataFixedParams.id });
  }

  _validateFields(parsedData) {
    const requiredInfo = 'Campo obrigatório';
    const requiredSelect = 'Selecione ao menos um veículo';
    const msgDataFim = 'Data Fim anterior que Data Início';
    const msgVeicSemOs = 'Informe o serviço para todos os veículos selecionados';
    const emptyValues = [];

    if (!this.model.title) {
      this.$.querySelector('#solicitation-title').setWarning(requiredInfo);
      emptyValues.push('titulo');
    } else {
      this.$.querySelector('#solicitation-title').setWarning('');
    }

    if (!this.model.requesterId) {
      this.$.querySelector('#solicitanteId').setWarning(requiredInfo);
      emptyValues.push('solicitante');
    } else {
      this.$.querySelector('#solicitanteId').setWarning('');
    }

    if (!this.addressSelected) {
      this.$.querySelector('.warning-address').innerText = requiredInfo;
      emptyValues.push('address');
    } else {
      this.$.querySelector('.warning-address').innerText = '';
    }

    if (!this.model.startDate) {
      this.$.querySelector('#initialDate').setWarning(requiredInfo);
      emptyValues.push('initialDate');
    } else {
      this.$.querySelector('#initialDate').setWarning('');
    }

    if (!this.model.finalDate) {
      this.$.querySelector('#finalDate').setWarning(requiredInfo);
      emptyValues.push('finalDate');
    } else {
      this.$.querySelector('#finalDate').setWarning('');
    }

    if (this.AuthorizationServiceVehicles.length == 0) {
      this.$.querySelector('.warning-services').innerText = requiredSelect;
      emptyValues.push('vehicleServices');
    } else if (this.AuthorizationServiceVehicles.find(vei => vei.service == '')) {
      this.$.querySelector('.warning-services').innerText = msgVeicSemOs;
      emptyValues.push('vehicleServices');
    } else {
      this.$.querySelector('.warning-services').innerText = '';
    }

    if (
      this.model.startDate &&
      this.model.finalDate &&
      this.model.finalDate < this.model.startDate
    ) {
      this.$.querySelector('#finalDate').setWarning(msgDataFim);
      emptyValues.push('finalDate');
    }

    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              item.warning = '';
              if (
                parsedData.statusOs == 1 &&
                item.required &&
                !item.value &&
                item.inputType != 'Boolean'
              ) {
                item.warning = requiredInfo;
                emptyValues.push(item);
              }
              if (item.field == 'reasonAuthorization' && parsedData.statusOs == 0 && !item.value) {
                item.warning = requiredInfo;
                emptyValues.push(item);
              }
              return item;
            });
          }
        });
      }
      return config;
    });
    if (emptyValues.length > 0) {
      return false;
    }
    return true;
  }

  _confirmCrudForm() {
    const parsedData = {};
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.formConfig.forEach(form =>
      form.sections.forEach(section =>
        section.items
          .filter(x => x.value || x.value === 0 || x.inputType == 'Boolean')
          .map(item => {
            parsedData[item.field] = item.value;
            return parsedData;
          }),
      ),
    );

    // fields validation
    if (this._validateFields(parsedData)) {
      if (this.osEditConfig) {
        parsedData.id = this.osEditConfig.idOs;
      }
      // recover form values
      const objRequest = {
        id: this.model.id,
        title: this.model.title,
        requesterId: this.model.requesterId,
        priority: this.model.priority,
        address: this.addressSelected,
        addressNumber: this.model.addressNumber,
        startDate:  toUTCDate({ date: this.model.startDate }),
        finalDate: toUTCDate({ date: this.model.finalDate }),
        // startDate:  this.model.startDate,
        // finalDate: this.model.finalDate,
        observation: this.model.observation,
        vehiclesServices: this.AuthorizationServiceVehicles,
      };
      // recover from form config
      ({
        statusOs: objRequest.statusOs,
        reasonAuthorization: objRequest.reasonAuthorization,
        authorizationDate: objRequest.authorizationDate,
        authorizingAgent: objRequest.authorizingAgent,
        alertPco: objRequest.alertPco,
      } = parsedData);

      this._upsertOrderService(objRequest);
    } else {
      this.$.querySelector('power-toast#form-os-crud-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
        },
      });
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  _upsertOrderService(request) {
    this.crudServices
      .callApiMethod('OrderService/UpsertOrderService', request)
      .then(
        success => {
          if (success.status == 200 && success.data.data && success.data.hasError === false) {
            this.$.querySelector('power-toast#form-os-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Solicitação de ordem de serviço adicionada.' } });
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            switch (success.status) {
              case 406:
              case 422:
                this.toastText = success.data.data.toString();
                break;
              default:
                this.toastText = success.data.data
                  ? success.data.data.toString()
                  : 'Ocorreu um erro ao salvar solicitação de ordem de serviço';
                break;
            }
            this.$.querySelector('power-toast#form-os-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: this.toastText } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _cancelCrudForm() {
    this._backTo(1);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }

  _evtClickBtnPopup(type, popupId, dataset) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-share': {
          this.sharePopup.itemSelected = null;
          this.$.querySelector('#popup-share').toggle();
          break;
        }
        case 'popup-confirmation': {
          this.confirmationPopup.cancel();
          break;
        }
        case 'popup-video': {
          this.$.querySelector('#popup-video').toggle();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-share': {
          this.$.querySelector('#popup-share').toggle();
          this.formConfig
            .filter(config => config.sections)
            .map(config => config.sections)
            .reduce((acc, sections) => acc.concat(...sections), [])
            .filter(section => section.items && section.items.length > 0)
            .forEach(section => {
              section.items.forEach(item => {
                if (item.field === this.sharePopup.actionField) {
                  this.sharePopup.itemSelected.forEach(selectedItem => {
                    item.value.push({
                      id: selectedItem.id,
                      description: selectedItem.description,
                      extra: this.sharePopup.extra,
                      icon: selectedItem.icon,
                    });
                  });
                }
              });
            });

          break;
        }
        case 'popup-confirmation': {
          this.confirmationPopup.confirm();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'item') {
      if (this.sharePopup.itemSelected.find(item => item.id === dataset.id)) {
        this.sharePopup.itemSelected = this.sharePopup.itemSelected.filter(
          item => item.id !== dataset.id,
        );
      } else {
        this.sharePopup.itemSelected.push(dataset);
      }
    }
  }

  _showField(item) {
    if (item.field == 'link') {
      return item.typeLink.some(value => value === this.typeLink);
    }
    return true;
  }

  _getFieldName(item) {
    return item.name;
  }

  _getPropertyValue(data, prop) {
    if (data) {
      const levels = prop.split('.');
      return levels.reduce((acc, item) => acc[item], data);
    }
    return null;
  }

  /* Observers */
  __startDateChanged(newValue) {
    if (newValue) {
      this.AuthorizationServiceVehicles = [];
      this.dataParamsVehiclesList.startDate = newValue;
      if (this.model.startDate && this.model.finalDate) {
        const { idOs } = Object(this.osEditConfig);
        this.dataParamsVehiclesList.finalDate = this.model.finalDate;
        this.dataParamsVehiclesList.idOs = idOs;
        this.$.querySelector('#idsListaVeiculos').updateComboBoxOptions(
          this.dataParamsVehiclesList,
        );
      }
    }
  }

  __onStartDateChanged() {
    this.AuthorizationServiceVehicles = [];
    if (this.model.startDate && this.model.finalDate) {
      const { idOs } = Object(this.osEditConfig);
      this.dataParamsVehiclesList.startDate = this.model.startDate;
      this.dataParamsVehiclesList.finalDate = this.model.finalDate;
      this.dataParamsVehiclesList.idOs = idOs;
      this.$.querySelector('#idsListaVeiculos').updateComboBoxOptions(this.dataParamsVehiclesList);
    }
  }

  __onFinalDateChanged() {
    if (!this._disableSolicitationAndAuthorizationSectionFields()) {
      this.AuthorizationServiceVehicles = [];
      if (this.model.startDate && this.model.finalDate) {
        const { idOs } = Object(this.osEditConfig);
        this.dataParamsVehiclesList.startDate = this.model.startDate;
        this.dataParamsVehiclesList.finalDate = this.model.finalDate;
        this.dataParamsVehiclesList.idOs = idOs;
        this.$.querySelector('#idsListaVeiculos').updateComboBoxOptions(
          this.dataParamsVehiclesList,
        );
      }
    }
  }

  __finalDateChanged(newValue) {
    if (newValue && !this._disableSolicitationAndAuthorizationSectionFields()) {
      this.AuthorizationServiceVehicles = [];
      this.dataParamsVehiclesList.finalDate = newValue;
      if (this.model.startDate && this.model.finalDate) {
        const { idOs } = Object(this.osEditConfig);
        this.dataParamsVehiclesList.startDate = this.model.startDate;
        this.dataParamsVehiclesList.idOs = idOs;
        this.$.querySelector('#idsListaVeiculos').updateComboBoxOptions(
          this.dataParamsVehiclesList,
        );
      }
    }
  }
  /* */
}

class GolfleetFormOrderService {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetFormOrderServiceController;
  }
}

angular
  .module('golfleet-form-order-service', [
    'ng-tippy',
    'power-toast',
    'golfleet-file-list',
    'power-popup',
    'golfleet-popup-workshop',
    'golfleet-popup-address',
  ])
  .component('golfleetFormOrderService', new GolfleetFormOrderService());
