import 'ng-redux';

import { isValidEmail } from '../../utils/email-util.js';

import '../power-toast/power-toast';

import './power-form-driver-crud.scss';

class PowerDropdownItem {
  /**
   * Creates an instance of PowerDropdownItem.
   * @param {Number} id
   * @param {String} description
   * @param {Boolean} selected
   * @param {any} value
   * @memberof PowerDropdownItem
   */
  constructor(id, description, selected, value) {
    this.id = id;
    this.description = description;
    this.selected = selected;
    this.value = value;
  }
}

class PowerFormDriverCrudController {
  static get $inject() {
    return [
      '$element',
      '$sce',
      '$ngRedux',
      '$rootScope',
      '$state',
      '$http',
      '$scope',
      'commonServices',
      'urlApi',
      'crudServices',
    ];
  }

  /**
   * Creates an instance of PowerFormDriverCrudController.
   * @memberof PowerFormDriverCrudController
   */
  constructor(
    $element,
    $sce,
    $ngRedux,
    $rootScope,
    $state,
    $http,
    $scope,
    commonServices,
    urlApi,
    crudServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $ngRedux,
      $rootScope,
      $state,
      $http,
      $scope,
      commonServices,
      urlApi,
      crudServices,
    });

    this.__appInheritBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* Session Storage */
        state: behavior.state,

        /* State Storage */
        stateConfig: currentState ? currentState.stateConfig : {},
        modules: behavior.session.modules,
        userEmail: behavior.session.userEmail,
      });
    })(this);

    this.formConfig = [];
    this.form = [];
    this.staticFormData = [];
    this.canSubmit = true;
    this.data = null;

    this.formConfigReady = false;
    this.formViewData = [];
    this.comboboxToLoad = [];
    this.staticFormData = [];
    this.itemsAccident = null;
    this.itemsAccidentFormatted = null;
    this.driverEditConfig = this._getDriverIdFromState();
    this.dataset = null;

    this.parsedDataInsert = {};
  }

  /* Lifecycle */
  $onInit() {
    this.dataset = Object.clone({}, this.crudDataset);

    this.$scope.$on('triggerRequest', (event, eventObject) =>
      this._callTrigger(
        eventObject.triggerKey,
        eventObject.triggerOf,
        eventObject.triggerValue,
        eventObject.triggerCondition,
      ),
    );

    this._getFormConfig();
    this._checkReady();
  }

  _checkReady() {
    setTimeout(() => {
      if (this.formConfigReady) {
        this._getFormData();
        this._formViewDataParser();
        this._setDeleteButtonState();
      } else {
        this._checkReady();
      }
    }, 300);
  }

  _getDriverIdFromState() {
    const route = this.state.routeList.filter(x => x.routeTail);
    if (route[0]) {
      const { stateConfig } = route[0];
      return {
        idDriver: route[0].routeTail,
        getDataMethod: stateConfig.getDataMethod,
      };
    }
    return null;
  }

  _getFormConfig() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.commonServices.getFormConfiguration('Driver').then(data => {
      if (data.form) {
        this.formConfig = data.form;
        const sections = this.formConfig.map(item => item.sections);
        this.comboboxToLoad = sections.reduce((acc, item) => {
          item.forEach(arg => {
            const cb = arg.items.filter(c => c.type == 'grid' && c.getDataMethod);
            cb.forEach(e => {
              const payload = e.getDataParams;
              this._getDataSet(e.getDataMethod, payload);
            });
            if (cb.length > 0) acc.push(cb);
          });
          return acc;
        }, []);
      }
      this.formConfigReady = true;
    });
  }

  _getDataSet(dataMethod, payload) {
    this.crudServices.getData(dataMethod, payload).then(result => {
      this.itemsAccident = result;
    });
  }

  _formViewDataParser() {
    this._syncScope();
  }

  _syncScope() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) this.$scope.$apply();
  }

  _getFormData() {
    if (this.driverEditConfig) {
      const { idDriver, getDataMethod } = this.driverEditConfig;
      const payload = {
        id: idDriver,
      };
      this.crudServices
        .getData(getDataMethod, payload)
        .then(data => {
          this.data = data;

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: true },
              bubbles: true,
              composed: true,
            }),
          );

          if (data) {
            this.formConfig.map(config => {
              if (config.sections) {
                config.sections.forEach(section => {
                  if (section.items && section.items.length > 0) {
                    section.items.map(item => {
                      const field = this.data[`${item.field}`];
                      item.value = field;

                      if (item.type == 'combobox' && item.value) {
                        item.options.forEach(option => {
                          option.selected = option.value == item.value;
                        });

                        if (item.field == 'idDriver') {
                          item.disabled = true;
                          item.triggerBlock = true;
                        }
                      }

                      if (item.inputMaskFormat && item.value) {
                        item.value = item.value.toString();
                      }

                      if (item.type === 'Chip') {
                        if (typeof item.value == 'undefined') {
                          item.value = [];
                        }

                        // To post accessories, it is necessary to pass the
                        // description in the id field
                        if (item.fieldId) {
                          item.value.map(row => {
                            row.id = row[item.fieldId];
                            return row;
                          });
                        }
                      }

                      return item;
                    });
                  }
                });
              }
              return config;
            });
          } else {
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, {
              toast: {
                text: 'Nenhum dado encontrado.',
              },
            });
          }
        })
        .finally(() => {
          if (this.data) {
            const datepickers = this.$.querySelectorAll('power-crud-datepicker');
            for (let i = 0; i < datepickers.length; i++) {
              const field = datepickers[i].getAttribute('field');
              datepickers[i].setDate(this._FormatStringDate(this.data[field]));
            }
          }

          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    } else {
      this.formConfig.map(config => {
        if (config.sections) {
          config.sections.forEach(section => {
            if (section.items && section.items.length > 0) {
              section.items.map(item => item);
            }
          });
        }
        return config;
      });

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }

    this.formReady = true;
  }

  _setDeleteButtonState() {
    const button = this.$.querySelector('.delete-btn');
    if (button) {
      if (!this.driverEditConfig) {
        button.setAttribute('disabled', '');
      } else if (this.driverEditConfig && button.hasAttribute('disabled')) {
        button.removeAttribute('disabled');
      }
    }
  }

  _deleteCrudForm() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const request = { objects: [{ objectId: this.driverEditConfig.idDriver }] };

    this.crudServices
      .callDeleteMethod('Driver/DeleteDriver', request)
      .then(
        success => {
          if (success.data.data) {
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Condutor excluído' } });
            setTimeout(() => {
              this._backTo(2);
            }, 1500);
          } else {
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Ocorreu um erro ao excluir o condutor' } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _validateNumberFields(item) {
    if (item.simpleValue == 0) {
      item.simpleValue = null;
    }

    return item.simpleValue;
  }

  _validateFields() {
    const emptyValues = [];

    this.formConfig.map(config => {
      if (config.sections) {
        config.sections.forEach(section => {
          if (section.items && section.items.length > 0) {
            section.items.map(item => {
              item.warning = '';
              if (item.required && !item.value) {
                item.warning = 'Campo obrigatório';
                emptyValues.push(item);
              }
              if (item.field === 'email') {
                if (item.value) {
                  if (!isValidEmail(item.value)) {
                    item.warning = 'Email inválido';
                    emptyValues.push(item);
                  }
                }
              }

              return item;
            });
          }
        });
      }
      return config;
    });
    if (emptyValues.length > 0) {
      return false;
    }
    return true;
  }

  _confirmCrudForm() {
    const parsedData = {};
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    // fields validation
    if (this._validateFields()) {
      if (this.driverEditConfig) {
        parsedData.id = this.driverEditConfig.idDriver;
      }
      // recover form values
      this.formConfig.forEach(form =>
        form.sections.forEach(section =>
          section.items
            .filter(x => x.value)
            .map(item => {
              if (this.driverEditConfig && item.value.id) {
                parsedData[item.field] = item.value.id;
              } else if (item.type === 'Chip') {
                parsedData[item.field] = item.value.map(data => ({ id: data.id }));
              } else {
                parsedData[item.field] = item.value;
              }
              return parsedData;
            }),
        ),
      );
      if (this.driverEditConfig) {
        this._updateDriver(parsedData);
      } else {
        this._createDriver(parsedData);
      }
    } else {
      this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
      Object.assign(this, {
        toast: {
          text: 'Algumas informações são obrigatórias e precisam ser preenchidas.',
        },
      });
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  _updateDriver(request) {
    this.crudServices
      .callApiMethod('Driver/UpdateDriver', request)
      .then(
        success => {
          if (success.status == 200 && success.data.data && success.data.hasError === false) {
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Editado com sucesso!' } });
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            switch (success.status) {
              case 406:
              case 422:
                this.toastText = success.data.data.toString();
                break;
              default:
                this.toastText = success.data.data
                  ? success.data.data.toString()
                  : 'Ocorreu um erro ao salvar o condutor';
                break;
            }
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: this.toastText } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _createDriver(request) {
    this.crudServices
      .callApiMethod('Driver/CreateDriver', request)
      .then(
        success => {
          if (success.status == 200 && success.data.data && success.data.hasError === false) {
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: 'Condutor adicionado.' } });
            setTimeout(() => {
              this._cancelCrudForm();
            }, 1500);
          } else {
            switch (success.status) {
              case 406:
              case 422:
                this.toastText = success.data.data.toString();
                break;
              default:
                this.toastText = success.data.data
                  ? success.data.data.toString()
                  : 'Ocorreu um erro ao salvar o condutor';
                break;
            }
            this.$.querySelector('power-toast#form-driver-crud-toast').toggle(true);
            Object.assign(this, { toast: { text: this.toastText } });
          }
        },
        () => {},
      )
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _removeErrorAttr(item) {
    item.error = false;
  }

  _removeLimitValuesErrorAttr(item) {
    // to clear the zeros when click in field uncomment the line below
    // this._validateNumberFields(item);

    item.limitValuesInvalid = false;
  }

  _cancelCrudForm() {
    this._backTo(1);
  }

  _backTo(index) {
    const backToState = this._reverseRouteList()[index];
    this.$ngRedux.dispatch({
      type: 'PREVIOUS_ROUTE',
      data: { index: this.state.routeList.length - 1 - index },
    });
    this.$state.go(backToState.routeLink, { tail: backToState.routeTail });
  }

  _reverseRouteList() {
    return Object.assign([], this.state.routeList).reverse();
  }

  _callTrigger(triggerKey, triggerOf, triggerValue, triggerCondition) {
    if (!this.formConfig) return;
    this.formConfig.forEach(form =>
      form.sections.forEach(section =>
        section.items
          .filter(x => x.key && x.key == triggerOf)
          .forEach(groupItem => {
            if (!triggerCondition || (triggerCondition && triggerValue == triggerCondition)) {
              if (groupItem.required === false) groupItem.required = true;
              if (groupItem.type == 'combobox') {
                const payload = { ...groupItem.getDataParams };
                if (this.dataset) {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const attr in payload) {
                    if (payload[attr] == triggerKey) payload[attr] = triggerValue;
                    else payload[attr] = this.dataset[payload[attr]];
                  }
                }
                this.crudServices.getData(groupItem.getDataMethod, payload).then(result => {
                  if (result.status && result.status != 200) return;
                  let foundDefault = false;
                  groupItem.options = [];
                  result.forEach(item => {
                    if (groupItem.value && item.id == groupItem.value) {
                      foundDefault = true;
                      groupItem.value = new PowerDropdownItem(
                        item.id,
                        item.description,
                        true,
                        item.value,
                      );
                      groupItem.options.push(
                        new PowerDropdownItem(item.id, item.description, true, item.value),
                      );
                    } else
                      groupItem.options.push(
                        new PowerDropdownItem(item.id, item.description, false, item.value),
                      );
                  });
                  if (groupItem.options.length > 0) groupItem.disabled = false;
                  if (!foundDefault) {
                    groupItem.value = null;
                    this.dataset[groupItem.key] = null;
                    this._callTrigger(
                      groupItem.key,
                      groupItem.triggerOf,
                      null,
                      groupItem.triggerCondition,
                    );
                  }
                });
              } else {
                groupItem.disabled = false;
                groupItem.value = triggerValue;
                if ((!groupItem.value || groupItem.value === 0) && groupItem.triggerOf) {
                  this.dataset[groupItem.key] = null;
                  this._callTrigger(
                    groupItem.key,
                    groupItem.triggerOf,
                    null,
                    groupItem.triggerCondition,
                  );
                }
              }
            } else {
              groupItem.disabled = true;
              groupItem.value = null;
              this.dataset[groupItem.key] = null;
              if (groupItem.type == 'combobox') groupItem.options = [];
              if (groupItem.triggerOf)
                this._callTrigger(
                  groupItem.key,
                  groupItem.triggerOf,
                  null,
                  groupItem.triggerCondition,
                );
            }
          }),
      ),
    );
  }

  evtClickBtnPowerPopup(type) {
    if (type == 'primaryBtn') {
      this.itemsAccidentFormatted = this.itemsAccident.selected.map(x => x.description).join(', ');
    }
    this.$.querySelector('#popup-items-option-selected').toggle();
  }

  _actionFunction(itemReturn) {
    const json = {};
    this.__callApiMethod(
      itemReturn.getDataMethod,
      json,
      {
        success: null,
        error: 'Ops! Erro ao carregar as informações',
      },
      true,
    ).then(result => {
      if (result && !result.hasError) {
        this.sharePopup = {
          open: true,
          title: itemReturn.actionTitle,
          icon: itemReturn.actionIcon,
          list: result.data,
          method: itemReturn.actionMethod,
          itemSelected: [],
          json: {
            type: this.stateConfig.type,
            objects: [{ objectId: this.driverEditConfig ? this.driverEditConfig.idDriver : '' }],
          },
          text: `${itemReturn.text} foi ${itemReturn.actionText}`,
          actionField: itemReturn.actionField,
        };

        // Set already selected items to INDISPONÍVEL
        this.formConfig
          .filter(config => config.sections)
          .map(config => config.sections)
          .reduce((acc, sections) => acc.concat(...sections), [])
          .filter(section => section.items && section.items.length > 0)
          .forEach(section => {
            section.items.forEach(item => {
              if (item.field === itemReturn.actionField) {
                if (item.value && item.value.length > 0) {
                  item.value.forEach(data => {
                    const selectedItem = this.sharePopup.list.find(
                      listItem => listItem.id === data.id,
                    );
                    if (selectedItem) {
                      selectedItem.extra = 'INDISPONÍVEL';
                    }
                  });
                }
              }
            });
          });

        this.$.querySelector('#popup-share').toggle();
      }
    });
  }

  __callApiMethod(method, params, messages, showLoader) {
    const msgSuccess = (messages && messages.success) || null;

    const msgError = (messages && messages.error) || null;

    if (showLoader)
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: params,
    })
      .then(
        success => {
          if (success.status && success.status !== 200) {
            if (msgError) {
              this.toastObj = {
                text: msgError,
              };
              this.$.querySelector('#form-driver-crud-toast').toggle(true);
            }
            return null;
          }
          if (success.data && !success.data.hasError) {
            if (msgSuccess) {
              this.toastObj = {
                text: msgSuccess,
              };
              this.$.querySelector('#form-driver-crud-toast').toggle(true);
            }

            return success.data;
          }
          if (msgError) {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#form-driver-crud-toast').toggle(true);
          }

          return null;
        },
        error => {
          console.error(error);
          if (msgError) {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#form-driver-crud-toast').toggle(true);
          }

          return null;
        },
      )
      .finally(() => {
        if (showLoader)
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
      });
  }

  _evtClickBtnPopup(type, popupId, dataset) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-share': {
          this.sharePopup.itemSelected = null;
          this.$.querySelector('#popup-share').toggle();
          break;
        }
        case 'popup-confirmation': {
          this.confirmationPopup.cancel();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-share': {
          this.$.querySelector('#popup-share').toggle();
          this.formConfig
            .filter(config => config.sections)
            .map(config => config.sections)
            .reduce((acc, sections) => acc.concat(...sections), [])
            .filter(section => section.items && section.items.length > 0)
            .forEach(section => {
              section.items.forEach(item => {
                if (item.field === this.sharePopup.actionField) {
                  this.sharePopup.itemSelected.forEach(selectedItem => {
                    item.value.push({
                      id: selectedItem.id,
                      description: selectedItem.description,
                      extra: this.sharePopup.extra,
                      icon: selectedItem.icon,
                    });
                  });
                }
              });
            });

          break;
        }
        case 'popup-confirmation': {
          this.confirmationPopup.confirm();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'item') {
      if (this.sharePopup.itemSelected.find(item => item.id === dataset.id)) {
        this.sharePopup.itemSelected = this.sharePopup.itemSelected.filter(
          item => item.id !== dataset.id,
        );
      } else {
        this.sharePopup.itemSelected.push(dataset);
      }
    }
  }

  _popupItemSelected(item) {
    if (this.sharePopup.itemSelected) {
      return this.sharePopup.itemSelected.find(i => i.id === item.id);
    }
    return false;
  }

  _removeChipItem(itemToBeRemoved, list, msgQuestion, msgText, msgQuestionValue, msgTextValue) {
    this.$.querySelector('#popup-confirmation').toggle();
    this.confirmationPopup = {
      open: true,
      text:
        msgQuestion && msgQuestionValue != ''
          ? msgQuestion.split('<item>').join(msgQuestionValue)
          : msgQuestion || 'Deseja realmente executar esta ação?',
      subtext:
        msgText && msgTextValue != ''
          ? msgText.split('<item>').join(msgTextValue)
          : msgText || null,
      confirm: () => {
        this.formConfig.map(config => {
          if (config.sections) {
            config.sections.forEach(section => {
              if (section.items && section.items.length > 0) {
                section.items.map(item => {
                  if (item.field === list) {
                    item.value = item.value.filter(i => i.id !== itemToBeRemoved.id);
                  }

                  return item.value;
                });
              }
            });
          }
          return config;
        });

        this.$.querySelector('#popup-confirmation').toggle();
      },
      cancel: () => {
        this.$.querySelector('#popup-confirmation').toggle();
      },
    };
  }

  _FormatStringDate(data) {
    if (!data) return null;

    const day = data.split('/')[0];
    const month = data.split('/')[1];
    const year = data.split('/')[2];

    return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
  }

  _filterItemByModule(itemList) {
    if (this.modules) {
      return itemList.filter(
        item =>
          !item.validateModule || this.modules.some(module => item.validateModule.includes(module)),
      );
    }
    return itemList;
  }
}

export { PowerFormDriverCrudController };
