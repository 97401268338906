import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-toolbar/power-toolbar';
import '@power/power-components/components/power-grid/power-grid';
import '../golfleet-chart-motion-log/golfleet-chart-motion-log';
import '../golfleet-motion-log/golfleet-motion-log';
import '../golfleet-map-motion-log/golfleet-map-motion-log';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-tippy/ng-tippy';
import '@power/power-components/directives/ng-resize/ng-resize';

import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-motion-log.html';
import './golfleet-report-motion-log.scss';

class GolfleetReportMotionLogController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  /* Lifecycle */
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.mapModeList = [{ type: 'all', description: 'Todos os Pontos', selected: true }];
    this.mapLayerList = [
      {
        id: 0,
        description: 'Fleet Rec',
        layer: 'points',
        selected: true,
      },
    ];
  }

  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    const matchTablet = window.matchMedia('(max-width: 768px)');
    const matchTabletEvt = evt =>
      evt.matches && this.stateConfig.viewMode == 'split' ? this.changeView('grid') : null;
    matchTablet.addListener(matchTabletEvt);

    this.$scope.$on('goToLink', this._goToLink.bind(this));
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));

    if (this.stateConfig.viewMode) {
      this.changeView(this.stateConfig.viewMode);
    } else {
      this.changeView('grid');
    }

    if (this.stateConfig.isCustomReport && this.stateConfig.customReportId) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.$.querySelector('power-navigation-date').setup({
          path: this.stateConfig.navigation.date,
          minPath: this.stateConfig.navigation.dateMinPath,
        });

        const showReportNotFoundMessage = () => {
          this.toastText = 'O relatório selecionado não existe ou foi removido';
          this.$.querySelector('power-toast#report-toast').toggle(true);
        };

        this.reportServices
          .getUserReportConfiguration({
            _id: this.stateConfig.customReportId,
          })
          .then(success => {
            if (success.status && success.status == 200 && success.data.data) {
              const { data } = success.data;

              if (data) {
                this.userReportConfiguration = {
                  ...data,
                  filterConfig: JSON.parse(data.filterConfig),
                  gridConfig: JSON.parse(data.gridConfig),
                };

                return;
              }
            }
            this.userReportConfiguration = null;
            showReportNotFoundMessage();
          })
          .catch(() => {
            this.userReportConfiguration = null;
            showReportNotFoundMessage();
          })
          .finally(() => {
            if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
              this.$scope.$broadcast('getHeaders', {
                screenName: this.stateConfig.screenName,
                gridName: this.stateConfig.gridName,
                userGridConfig: this.userReportConfiguration
                  ? this.userReportConfiguration.gridConfig
                  : null,
              });

              this._getStateConfig();
            } else {
              const stateGridConfig = this.stateConfig.gridConfig;

              this.$scope.$broadcast('getHeaders', {
                screenName: this.stateConfig.screenName,
                gridName: this.stateConfig.gridName,
                page: !this.userReportConfiguration ? stateGridConfig.page : undefined,
                pageSize: !this.userReportConfiguration ? stateGridConfig.pageSize : undefined,
                userGridConfig:
                  !this.isDrillDownRoute && !!this.userReportConfiguration
                    ? this.userReportConfiguration.gridConfig
                    : undefined,
              });
              this.$scope.$broadcast('getDataset', {
                filter: {
                  conditions: this.stateConfig.filterConditions,
                },
                isPaginated: stateGridConfig.backPagination,
                ...this.stateConfig.getDataFixedParams,
              });

              document
                .querySelector('#app-component')
                .querySelector('power-filter-menu')
                .validateFilters();
            }
          });
      });
    } else if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
        });
        this._getStateConfig();
      });
    } else {
      this.$scope.$on('getDatasetReady', () => {
        this.$.querySelector('power-navigation-date').setup({
          path: this.stateConfig.navigation.date,
          minPath: this.stateConfig.navigation.dateMinPath,
        });

        this.$scope.$broadcast('getDataset', {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: this.stateConfig.navigation.date[this.stateConfig.navigation.date.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        });

        document
          .querySelector('#app-component')
          .querySelector('power-filter-menu')
          .validateFilters();
      });
    }
  }
  /* */

  /* Public */
  changeView(viewMode, fitLayer = true) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('motion-log-view');
        if (mapComponent.getMap) {
          this._adjustMap(mapComponent.getMap(), fitLayer);
        }
        break;
      case 'chart':
        this.$.setAttribute('chart-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('motion-log-view');
        this.$.querySelector('#report-body-chart').updateChart();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('motion-log-view');
        this.$.querySelector('#report-body-chart').updateChart();
        this.$.querySelector('#report-body-motion-log').updateSimulation();
        break;
      case 'motionLog':
        this.$.setAttribute('motion-log-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('split-view');
        this.$.querySelector('#report-body-motion-log').updateSimulation();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        this.$.removeAttribute('chart-view');
        this.$.removeAttribute('motion-log-view');
        break;
    }
  }
  /* */

  /* Private */
  async _renderAllMode(dataset) {
    this._showGlobalLoader(true);

    const mapComponent = this.$.querySelector('#report-body-map');

    await mapComponent.renderDataset({
      dataset: dataset.filter(data => data.latitude && data.longitude),
      layerName: 'all',
      type: 'MarkerFeatureGroup',
      useCluster: false,
      clusterColor: '#2196f3',
      icon: 'gs_fleet',
    });

    mapComponent.resizeMap();
    mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);

    this._showGlobalLoader(false);
  }

  _showGlobalLoader(state = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: state },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _adjustMap(map, fitLayer) {
    this.$.querySelector('#report-body-map').resizeMap();

    if (!fitLayer) return;

    this.$.querySelector('#report-body-map').fitLayers(['all']);
  }
  /* */

  /* Observers */
  __reportDatasetChanged(newValue) {
    if (Array.isArray(newValue) && newValue.length > 0) {
      this._renderAllMode(newValue);
      this._adjustMap(null, true);
    }

    this.dataset3d = newValue?.map(item => ({
      ...item,
      time: new Date(item.dataHora).toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo' }),
      velocity: item.velocidade,
      x: item.longitudinal,
      z: item.lateral,
      y: item.vertical,
    }));
  }
  /* */
}

class GolfleetReportMotionLog {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportMotionLogController;
  }
}

angular
  .module('golfleet-report-motion-log', [
    'ngRedux',
    'ng-tippy',
    'power-dropdown',
    'power-toolbar',
    'power-grid',
    'golfleet-chart-motion-log',
    'golfleet-motion-log',
    'golfleet-map-motion-log',
    'power-footer',
    'power-pagination',
    'power-toast',
    'ng-resize',
  ])
  .component('golfleetReportMotionLog', new GolfleetReportMotionLog());
