import angular from 'angular';
import { eventOnce } from '@power/power-components/utils/event-once';

import '@power/power-components/components/power-popup/power-popup';

import template from './golfleet-popup-fuel-exceptions.html';
import './golfleet-popup-fuel-exceptions.scss';

class GolfleetPopupFuelExceptionsController {
  static get $inject() {
    return ['$element', '$scope'];
  }

  constructor($element, $scope) {
    Object.assign(this, { $: $element[0], $scope });
    this.popupLimitTabSelected = 1;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });
  }

  $onDestroy() {}

  /**/

  toggle() {
    eventOnce({
      element: this.$,
      event: 'transitionend',
      trigger: () => this.$.querySelector('#golfleet-popup-fuel-exceptions').toggle(),
      action: () => !this.$.hasAttribute('open') && this._evtClickPopupTab(1),
    });
  }

  _evtClickPopupTab(tabNumber) {
    if (tabNumber !== this.popupLimitTabSelected) {
      this.popupLimitTabSelected = tabNumber;
      this.$.querySelector('.tabclass').setAttribute('tab', tabNumber);
    }
  }

  /**/

  /**/
}

class GolfleetPopupFuelExceptions {
  constructor() {
    this.template = template;
    this.bindings = {
      parameters: '=?',
    };
    this.controller = GolfleetPopupFuelExceptionsController;
  }
}
angular
  .module('golfleet-popup-fuel-exceptions', ['power-popup'])
  .component('golfleetPopupFuelExceptions', new GolfleetPopupFuelExceptions());
