import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '@power/power-components/components/power-footer/power-footer';
import '../golfleet-grid-speed-limit/golfleet-grid-speed-limit';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';
import '@power/power-components/directives/ng-tippy/ng-tippy';

import template from './golfleet-report-ranking-speed-limit.html';
import './golfleet-report-ranking-speed-limit.scss';

class GolfleetReportRankingSpeedLimitController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.legacyEquipaments = { status: 0, list: [], count: 0, highlight: true }; // 0. loading, 1. ok, 2. no data, 3. error
    this.msgLegacyEquipament = ' equipamentos incompatíveis não constam nesta listagem';
    this.checkViolationDesconsider = false;

    this.listType = null;
  }

  /* Lifecycle */
  $onInit() {
    const filterEquipaments = this.stateConfig.filterConditions.filter(
      item => item.field == 'vehicles' || item.field == 'driver',
    )[0];

    if (
      filterEquipaments &&
      filterEquipaments.value &&
      (filterEquipaments.value.length === 0 ||
        filterEquipaments.value.length == filterEquipaments.total)
    ) {
      this.commonServices.getLegacyEquipaments('LegacyEquipaments/GetLegacyEquipaments').then(
        success => {
          if (success.data && success.data.hasError) {
            this.legacyEquipaments.status = 3;
          } else if (success.data.data && success.data.data.total > 0) {
            this.legacyEquipaments.list = success.data.data.equipaments;
            this.legacyEquipaments.count = success.data.data.total;
            this.legacyEquipaments.highlight = success.data.data.highlight;
            this.legacyEquipaments.status = 1;
          } else this.legacyEquipaments.status = 2;
        },
        () => {
          this.legacyEquipaments.status = 3;
        },
      );
    } else this.legacyEquipaments.status = 0;

    super.$onInit();
  }
  /* */

  /* Private */
  _callAction(action) {
    switch (action.actionType) {
      case 'share':
        this.$.querySelector('power-popup-share').setData({
          type: this.stateConfig.type,
          getMethod: action.getDataMethod,
          shareMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({ objectId: row.id })),
        });
        this.$.querySelector('power-popup-share').toggle();
        break;
      default:
        break;
    }
  }

  _goToLink(evt, evtParams) {
    // Via
    if (evtParams.tableRowData.rua) this.listType = `Listagem ${evtParams.tableRowData.rua}`;
    // Vehicle & Driver
    else if (evtParams.tableRowData.name) this.listType = `Listagem ${evtParams.tableRowData.name}`;

    super._goToLink(evt, evtParams);
  }

  _clickCheckboxViolation() {
    this.checkViolationDesconsider = !this.checkViolationDesconsider;
  }

  _evtClickPopupLegacyEquipament() {
    this.$.querySelector('#popup-legacy-equipament').toggle();
  }
  /* */
}

class GolfleetReportRankingSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportRankingSpeedLimitController;
  }
}
angular
  .module('golfleet-report-ranking-speed-limit', [
    'ngRedux',
    'ng-tippy',
    'power-fab',
    'power-dropdown',
    'power-toolbar-report',
    'golfleet-grid-speed-limit',
    'power-footer',
    'power-pagination',
    'power-popup',
  ])
  .component('golfleetReportRankingSpeedLimit', new GolfleetReportRankingSpeedLimit());
