import angular from 'angular';

import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-custom-checkbox-list.html';
import './power-custom-checkbox-list.scss';

class PowerCustomCheckboxListController {
  static get $inject() {
    return ['$scope'];
  }

  constructor($scope) {
    /*
		dataset:
			id
			description
			value (*normally, same as id)
			selected (default => false)
		* */

    Object.assign(this, { $scope });

    this.checkAll = false;
    this.search = this.search || true;
    this.itensSelected = this.itensSelected || [];
    this.inputSearchList = '';

    this.dataset = [];

    this.$scope.$watch(() => this.inputSearchList, this.__inputSearchListChanged.bind(this));
    this.$scope.$watch(() => this.dataset, this.__datasetChanged.bind(this));
  }

  evtClickCheckAll() {
    this.checkAll = !this.checkAll;
    this.dataset.forEach(item => {
      if (this._stringIncludes(item.description, this.inputSearchList)) {
        item.selected = this.checkAll;
      }
    });

    this.itensSelected = this.checkAll ? this.dataset.filter(item => item.selected) : [];
  }

  evtClickItem(item) {
    item.selected = !item.selected;

    this.itensSelected = this.dataset.filter(item2 => item2.selected);

    this.checkAll = this.itensSelected.length === this.dataset.length;
  }

  _stringIncludes(str, includes) {
    return str.toLocaleLowerCase().includes(includes.toLocaleLowerCase());
  }

  __inputSearchListChanged() {
    if (!this.dataset || this.dataset.length === 0) {
      this.checkAll = false;
      return;
    }

    const filteredItems = this.dataset.filter(item =>
      this._stringIncludes(item.description, this.inputSearchList),
    );
    const itemsSelected = filteredItems.filter(item => item.selected);

    this.checkAll = filteredItems.length > 0 && itemsSelected.length === filteredItems.length;
  }

  __datasetChanged() {
    this.inputSearchList = '';
  }
}

class PowerCustomCheckboxList {
  constructor() {
    this.template = template;
    this.bindings = {
      search: '=?',
      dataset: '=',
      onChange: '&?',
      itensSelected: '=?',
      checkAll: '=?',
    };
    this.controller = PowerCustomCheckboxListController;
  }
}

angular
  .module('power-custom-checkbox-list', ['infinite-scroll'])
  .component('powerCustomCheckboxList', new PowerCustomCheckboxList());
