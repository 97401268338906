import angular from 'angular';

import template from './power-header-drag-n-drop.html';
import './power-header-drag-n-drop.scss';

import '../../directives/ng-touchstart/ng-touchstart';

class PowerHeaderDragNDropController {
  static get $inject() {
    return ['$element', '$rootScope', '$state', '$scope'];
  }

  constructor($element, $rootScope, $state, $scope) {
    Object.assign(this, {
      $: $element[0],
      $rootScope,
      $state,
      $scope,
    });

    this.items = [];
    this.dragHandler = false;
    this.hideUnselected = false;

    this.replaceHeaderText = '';
    this.complementHeaderText = '';

    this.didDrag = false;
    this.hoverIndex = null;
    this.draggedIndex = null;
  }

  /* Lifecycle */
  $onInit() {}
  /* */

  /* Private */
  _insert() {
    if (this.draggedIndex === null) {
      return;
    }

    const temp = this.items[this.draggedIndex];
    this.items.splice(this.draggedIndex, 1);
    this.items.splice(this.hoverIndex, 0, temp);

    this.draggedIndex = null;
    this.hoverIndex = null;
    this.didDrag = false;

    this._requestUpdate();
  }

  _autoScroll(item, mousePosition, mouseOffset) {
    if (this.didDrag === false) {
      return;
    }

    const itemBounding = item.getBoundingClientRect();
    const contentBounding = this.$.querySelector('[drag-n-drop-container]').getBoundingClientRect();
    const componentBounding = this.$.getBoundingClientRect();
    const bottomScrollOffset =
      itemBounding.top + itemBounding.height - componentBounding.top - componentBounding.height;

    const itemAbsoluteTop = mousePosition - this.$.getBoundingClientRect().y + this.$.scrollTop;

    if (bottomScrollOffset >= 0 && itemAbsoluteTop < contentBounding.height) {
      this.$.scrollTo({ top: this.$.scrollTop + 1 });
      this._updatePosition(item, mousePosition, mouseOffset);
      window.requestAnimationFrame(() => this._autoScroll(item, mousePosition, mouseOffset));
    } else if (itemBounding.top < componentBounding.top) {
      this.$.scrollTo({ top: this.$.scrollTop - 1 });
      this._updatePosition(item, mousePosition, mouseOffset);
      window.requestAnimationFrame(() => this._autoScroll(item, mousePosition, mouseOffset));
    }
  }

  _updatePosition(item, mousePosition, mouseOffset) {
    const absoluteTop = mousePosition - this.$.getBoundingClientRect().y + this.$.scrollTop;
    const visualHoverIndex = Math.max(Math.floor(absoluteTop / 48), 0);
    const virtualHoverIndex = this._virtualHoverIndex(item, visualHoverIndex);

    item.style.top = `${absoluteTop - mouseOffset}px`;

    if (virtualHoverIndex !== this.hoverIndex) {
      this.hoverIndex = virtualHoverIndex;
      this._requestUpdate();
    }
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  _headerTitle(title) {
    if (this.complementHeaderText && this.replaceHeaderText) {
      if (Array.isArray(title)) {
        return title
          .map(item => item.replace(this.replaceHeaderText, this.complementHeaderText))
          .join(' ');
      }
      return title.replace(this.replaceHeaderText, this.complementHeaderText);
    }
    return Array.isArray(title) ? title.join(' ') : title;
  }

  _virtualHoverIndex(item, visualHoverIndex) {
    const hoverElement = this.$.querySelectorAll('.drag-n-drop-item')[visualHoverIndex];

    if (hoverElement) {
      return parseInt(hoverElement.getAttribute('arr-index'));
    }
    if (this.hoverIndex === null) {
      return parseInt(item.getAttribute('arr-index'));
    }

    return this.hoverIndex;
  }

  _toggleHeader(header) {
    header.show = !header.show;

    this.$.dispatchEvent(new CustomEvent('toggleHeader', { detail: { header } }));
  }
  /* */

  /* Public */
  /* */

  /* Observers */
  _onStartItemDrag(dragEvent) {
    if (
      this.dragHandler === true &&
      (dragEvent.target.hasAttribute('draggable') === false ||
        dragEvent.target.getAttribute('draggable') === 'false')
    ) {
      return;
    }

    const ctrl = this;

    let moveEvent;
    let upEvent;
    let getClientY;
    let getPageY;

    if (dragEvent instanceof TouchEvent) {
      moveEvent = 'touchmove';
      upEvent = 'touchend';
      getClientY = e => e.touches[0].clientY;
      getPageY = e => e.touches[0].pageY;
    } else {
      moveEvent = 'mousemove';
      upEvent = 'mouseup';
      getClientY = e => e.clientY;
      getPageY = e => e.pageY;
    }
    dragEvent.preventDefault();

    this.$.querySelector('[drag-n-drop-container]').style.height = `${this.$.scrollHeight}px`;

    const item = dragEvent.currentTarget;
    const itemMouseOffset = getClientY(dragEvent) - item.getBoundingClientRect().top;

    this.draggedIndex = parseInt(item.getAttribute('arr-index'));

    item.style.position = 'absolute';
    item.style.zIndex = 2;

    const moveItem = evt => {
      this._updatePosition(item, getPageY(evt), itemMouseOffset);
      window.requestAnimationFrame(() => this._autoScroll(item, getPageY(evt), itemMouseOffset));
    };

    const __onMoveEvent = evt => {
      this.didDrag = true;
      moveItem(evt);
      this.$.dispatchEvent(new CustomEvent('dragMoved'));
    };

    document.addEventListener(moveEvent, __onMoveEvent);
    moveItem(dragEvent);

    this.$.addEventListener(upEvent, function __onUpEvent() {
      document.removeEventListener(moveEvent, __onMoveEvent);
      this.removeEventListener(upEvent, __onUpEvent);

      ctrl._insert();
      item.style.position = 'relative';
      item.style.zIndex = 1;
      item.style.top = '0';

      ctrl.$.dispatchEvent(new CustomEvent('dragEnded'));
    });

    this.$.dispatchEvent(new CustomEvent('dragStarted'));
  }
  /* */
}

class PowerHeaderDragNDrop {
  constructor() {
    this.template = template;
    this.bindings = {
      items: '=',
      dragHandler: '<?',
      hideUnselected: '<?',
      /* complement header title */
      replaceHeaderText: '=?',
      complementHeaderText: '=?',
    };
    this.controller = PowerHeaderDragNDropController;
  }
}

angular
  .module('power-header-drag-n-drop', ['power-dropdown', 'ng-touchstart'])
  .component('powerHeaderDragNDrop', new PowerHeaderDragNDrop());
