import angular from 'angular';
import 'ng-redux';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-dropdown/power-dropdown';
import '../golfleet-grid-reports/golfleet-grid-reports';
import '@power/power-components/components/power-footer/power-footer';
import '../golfleet-report-reports-tips/golfleet-report-reports-tips';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-popup-report-auto-send/power-popup-report-auto-send';
import '../golfleet-popup-report-share/golfleet-popup-report-share';
import '@power/power-components/components/power-popup-list/power-popup-list';
import '@power/power-components/components/power-toolbar/power-toolbar';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';
import { GetRouteConfig } from '../../configs/routes/routes.config';

import template from './golfleet-report-reports.html';
import './golfleet-report-reports.scss';

class GolfleetReportReportsController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
    );

    Object.assign(this, { $rootScope });

    this.actionMenuController = {
      share: () => {
        this.userShareController.toggle();
      },
      addMenu: () => {
        this._setSelectedRowsAsMenuItem(true).then(this._reloadUserMenu);
      },
      removeMenu: () => {
        this._setSelectedRowsAsMenuItem(false).then(this._reloadUserMenu);
      },
      delete: () => {
        this._deleteSelectedItems();
      },
    };

    this.userShareController = {
      toggle: async () => {
        const popupElement = this.$.querySelector('#golfleet-popup-report-share');

        if (popupElement.getAttribute('open') == null) {
          this._toogleLoader(true);
          const result = await popupElement.loadData(this.selectedRows.map(item => item._id));
          this._toogleLoader(false);

          if (result.reports.length === 0) {
            const { length } = this.selectedRows;
            const _toastText = `Você não possui permissão para editar ${
              length > 1 ? 'os itens selecionados' : 'o item selecionado'
            }!`;

            Object.assign(this, { _toastText });
            this.$.querySelector('power-toast#reports-toast').toggle(true);

            if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
              this.$scope.$apply();
            }

            return;
          }

          if (result.reports.length < this.selectedRows.length) {
            const noAccessCount = this.selectedRows.length - result.reports.length;
            const _toastText = `Você não possui permissão para editar ${noAccessCount} relatório${
              noAccessCount === 1 ? '' : 's'
            }!`;

            Object.assign(this, { _toastText });
            this.$.querySelector('power-toast#reports-toast').toggle(true);

            if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
              this.$scope.$apply();
            }
          }
        }

        popupElement.toggle();
      },

      onSaved: () => {
        this.requestDataset();
      },
    };

    this.__registerEventListeners();

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        state: behavior.state,
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    if (
      (this.currentState.routeLink == 'reportsUser' ||
        this.currentState.routeLink == 'reportsShare') &&
      this.currentState.stateConfig.getDataFilters?.length == 0 &&
      !this.state.showHelp
    ) {
      const routeConfig = GetRouteConfig(this.currentState.routeLink);
      const result = {
        ...routeConfig.data,
        stateConfig: routeConfig.data.stateConfig({
          filters: [
            {
              notIn: this.currentState.routeLink == 'reportsShare',
              field: 'ownerId',
              default: [this.userId],
            },
          ],
        }),
      };

      this.$state.go(this.currentState.routeLink, {}, { reload: true });

      this.$ngRedux.dispatch({
        type: 'NEXT_ROUTE_SINGLE',
        data: result,
      });
    }
  }

  // #region Lifecycle
  $onInit() {
    super.$onInit();
    const { showHelp } = this.state;
    if (showHelp) {
      this.$timeout(() => this.$.querySelector('#golfleet-popup-report-reports-tips').toggle());
      this.state.showHelp = null;
    }
  }

  $onDestroy() {
    this.__removeEventListeners();
    this.__appBehavior();
  }
  // #endregion Lifecycle

  _callAction(item) {
    const actionMenuItem = this.actionMenuController[item.type];

    if (actionMenuItem) {
      actionMenuItem();
    }
  }

  async _reloadUserMenu() {
    return document.querySelector('#app-navigation-menu').refreshMenuNavigationList();
  }

  async _setSelectedRowsAsMenuItem(isMenuItem) {
    this._toogleLoader(true);

    return new Promise(resolve => {
      this.selectedRows.forEach((row, index, rows) => {
        this.reportServices
          .updateUserReportConfigurationIsMenuItem({
            _id: row._id,
            isMenuItem,
          })
          .then(response => {
            const { data } = response.data;
            if (data && index === rows.length - 1) {
              resolve();
            }
          });
      });
    }).then(() => {
      if (this.selectedRows.length === 1) {
        Object.assign(this, {
          _toastText: `O relatório selecionado foi ${
            isMenuItem ? 'incluído no' : 'removido do'
          } menu lateral.`,
        });
      } else {
        Object.assign(this, {
          _toastText: `Os relatórios selecionados foram ${
            isMenuItem ? 'incluídos no' : 'removidos do'
          } menu lateral.`,
        });
      }

      this.$.querySelector('power-toast#reports-toast').toggle(true);
      this._toogleLoader(false);
    });
  }

  async _deleteSelectedItems() {
    this._toogleLoader(true);

    const ids = this.selectedRows.map(row => row._id);
    const data = await this._deleteUserReportConfiguration(ids);
    await this._reloadUserMenu();

    this._toogleLoader(false);
    this.requestDataset();

    const result = {
      deleted: data.filter(item => item.deleted),
      undeleted: data.filter(item => !item.deleted),
    };

    if (result.undeleted.length > 0) {
      if (result.deleted.length === 0) {
        Object.assign(this, {
          _toastText: 'Não foi possível remover os relatórios selecionados.',
        });
      } else {
        Object.assign(this, {
          _toastText: `Apenas ${result.deleted.length} ${
            result.deleted.length.length === 1
              ? 'relatório foi deletado'
              : 'relatórios foram deletados'
          }.`,
        });
      }
    } else if (this.selectedRows.length === 1) {
      Object.assign(this, { _toastText: 'Relatório removido com sucesso!' });
    } else {
      Object.assign(this, { _toastText: 'Relatórios removidos com sucesso!' });
    }

    this.$.querySelector('power-toast#reports-toast').toggle(true);
  }

  async _deleteUserReportConfiguration(ids) {
    const deleteConfiguration = async id =>
      new Promise(resolve => {
        this.reportServices.deleteUserReportConfiguration({ _id: id }).then(response => {
          if (response.status === 200) {
            const { data } = response.data;
            resolve({ id, deleted: !!data });
          } else {
            resolve({ id, deleted: false });
          }
        });
      });

    return Promise.all(ids.map(id => deleteConfiguration(id)));
  }

  _toogleLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  __registerEventListeners() {
    this.$.querySelector('#golfleet-popup-report-share').addEventListener(
      'saved',
      this.userShareController.onSaved.bind(this),
    );
  }

  __removeEventListeners() {
    this.$.querySelector('#golfleet-popup-report-share').removeEventListener(
      'saved',
      this.userShareController.onSaved.bind(this),
    );
  }

  _evtClickBtnTipsPopup() {
    this.$.querySelector('#golfleet-popup-report-reports-tips').toggle();
  }
}

class GolfleetReportReports {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportReportsController;
  }
}

angular
  .module('golfleet-report-reports', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'golfleet-grid-reports',
    'power-footer',
    'golfleet-report-reports-tips',
    'power-pagination',
    'power-popup',
    'power-popup-report-auto-send',
    'golfleet-popup-report-share',
    'power-popup-list',
    'power-toolbar',
  ])
  .component('golfleetReportReports', new GolfleetReportReports());
