/* global H: true isIframe: true */
import { PowerMapHereProvider } from '../../../power-map/providers/here/power-map.here-provider';
import '../../../../helpers/is-iframe/is-iframe';
import './power-map-generated-alerts.here-provider.scss';

class PowerMapGeneratedAlertsHereProvider extends PowerMapHereProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    const conditionLen = geoJson.properties.conditions.length;
    return new H.map.DomMarker(
      { lat, lng },
      {
        icon:
          markerIcon ||
          this._createMarkerIcon({
            ...geoJson.properties,
            icon: geoJson.properties.icon || 'directions_car',
          }),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div class="flex-row i-center information-card-header">
              <i class="material-icons">notifications</i>
              <span>
                ${
                  !conditionLen
                    ? 'Nenhuma condição'
                    : `${conditionLen} ${conditionLen > 1 ? 'condições' : 'condição'}`
                }
              </span>
            </div>
            <div class="flex-column list-conditions">
              ${
                !conditionLen
                  ? 'Nenhuma condição'
                  : geoJson.properties.conditions.reduce(
                      (ac, ele) => `${ac}<div class="flex-row i-center">
                <i class="material-icons">${ele.icon}</i>
                <span>${ele.descriptionWithValue}</span>
              </div>`,
                      '',
                    )
              }
            </div>
            <div id="mapPopupHeader">
              <span>${geoJson.properties.vehiclePlate}</span>
            </div>
            <div id="mapPopupBody">
              <div style="display: ${geoJson.properties.endereco ? 'block' : 'none'}">
                <b>Endereço:</b>
                <br>
                <span>${geoJson.properties.address}</span>
              </div>
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${geoJson.properties.formattedDate}</span>
              </div>
              <div>
                <b>Velocidade:</b>
                <br>
                <span>
                  ${geoJson.properties.speed}
                </span>
              </div>
              <div>
                <b>Condutor:</b>
                <br>
                <span>${geoJson.properties.driverName || 'Não identificado'}</span>
              </div>
              ${
                isIframe()
                  ? ''
                  : `
                <div style="margin-top:8px;text-align:center">
                  <a class="gs-link"
                    target="_blank"
                    href="${`https://maps.google.com/maps?layer=c&q=${lat},${lng}&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`}">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
      },
    );
  }

  _geoJsonToCircle({ geoJson }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.Circle({ lat, lng }, geoJson.properties.radius, {
      data: {
        ...geoJson.properties,
        bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(lat).toFixed(4)} </span>
              <span> Lon: ${parseFloat(lng).toFixed(4)} </span>
            </div>
          `,
      },
      style: {
        fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
        strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
      },
    });
  }

  _geoJsonToPolygon({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Polygon(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToMultiPolygon({ geoJson }) {
    const { coordinates } = geoJson.geometry;

    return new H.map.Polygon(
      coordinates.reduce((acc, [polygonArray]) => {
        acc.push(
          new H.geo.Polygon(
            polygonArray.reduce((lineStringAcc, latLngArray) => {
              const [lng, lat] = latLngArray;
              lineStringAcc.pushLatLngAlt(lat, lng);
              return lineStringAcc;
            }, new H.geo.LineString()),
          ),
        );
        return acc;
      }, new H.geo.MultiPolygon([])),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
          <div id="mapPopupHeader">
            <span>${geoJson.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${geoJson.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${geoJson.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${geoJson.properties.privacy || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
          </div>
        `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToPolyline({ geoJson }) {
    return new H.map.Polyline(
      geoJson.geometry.coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          lineWidth: 6,
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToRectangle({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Rect(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }
  /**/

  /* Observers */
  /**/
}

export { PowerMapGeneratedAlertsHereProvider };
