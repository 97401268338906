function isValidEmail(email) {
  const regexInv = new RegExp(
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.{1})+[a-z0-9](?:\.?[a-z0-9-]*[a-z0-9])$/,
  );

  if (!email) {
    return false;
  }

  const splittedMail = email.split(/(@)/);
  if (
    !email.includes('@') ||
    !regexInv.test(email) ||
    (!splittedMail[2] && splittedMail[2] == '')
  ) {
    return false;
  }
  return true;
}

export { isValidEmail };
