import angular from 'angular';
import moment from 'moment/src/moment';
import * as Comlink from 'comlink';
import 'moment/src/locale/pt-br';
import '@power/power-components/components/power-accordion/power-accordion';

import template from './golfleet-navigation-hierarchy.html';
import './golfleet-navigation-hierarchy.scss';

class GolfleetNavigationHierarchyController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
    });

    this.icon = 'gs_units';
    this.path = [];
    this.hidden = true;
    this.apiMethod = '';
    this.apiParams = '';
    this.description = '';
    this.hierarchyTree = [];
    this.parsedHierarchyPath = [];

    moment.locale('pt-BR');

    this.worker = new Worker('./golfleet-navigation-hierarchy.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      setup: this.setup.bind(this),
      getPath: this.getPath.bind(this),
      setDrillUp: this.setDrillUp.bind(this),
      setDrillDown: this.setDrillDown.bind(this),
      setDrillThrough: this.setDrillThrough.bind(this),
      setPathGranularity: this.setPathGranularity.bind(this),
    });

    this.$.setAttribute('hidden', '');
  }

  $onDestroy() {
    this.worker.terminate();
  }
  /* */

  /* Public */
  async setup(params = {}) {
    const { path = [] } = params;
    let hierarchyTree = [];

    hierarchyTree = [await this._requestPath()];

    if (path.length > 0) {
      await Promise.all(
        path.map(async (pathItem, index) => {
          if (index === 0) {
            return;
          }

          const { value, level } = pathItem;
          const hierarchyItem = await this._requestPath({ value, level });

          hierarchyTree[index] = hierarchyItem;
        }),
      );
    }

    await this.workerService.setup({ path, hierarchyTree });

    await this._syncState();

    this.hidden = false;

    this.$.removeAttribute('hidden', '');
  }

  async setDrillUp(params = {}) {
    const { level = 0 } = params;

    await this.workerService.setDrillUp({ level });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setDrillUp'));
  }

  async setDrillDown(params = {}) {
    const { hierarchy } = params;
    const { value, level = this.path.length } = hierarchy;

    const nextHierarchyBranch = await this._requestPath({ value, level });

    await this.workerService.setDrillDown({ hierarchy, nextHierarchyBranch });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setDrillDown'));
  }

  async setDrillThrough(params = {}) {
    const { level, hierarchy } = params;
    const { value } = hierarchy;

    const nextHierarchyBranch = await this._requestPath({ value, level });

    await this.workerService.setDrillThrough({ level, hierarchy, nextHierarchyBranch });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setDrillThrough'));
  }

  async setPathGranularity(granularity = 'default') {
    await this.workerService.setPathGranularity({ granularity });

    await this._syncState();

    if (this.$.querySelector(`power-dropdown[open]`)) {
      this.$.querySelector('power-dropdown[open]').toggle();
    }

    if (this.$.querySelector(`power-accordion[open]`)) {
      this.$.querySelector(`power-accordion[open]`).toggle();
    }

    this.$.dispatchEvent(new CustomEvent('setPathGranularity'));
  }

  getPath() {
    return this.path;
  }
  /* */

  /* Private */
  async _syncState() {
    this.path = await this.workerService.path;
    this.description = await this.workerService.description;
    this.hierarchyTree = await this.workerService.hierarchyTree;

    this.parsedHierarchyPath = this._parseHierarchyPath();

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }

    this.$.dispatchEvent(new CustomEvent('syncState'));
  }

  async _requestPath(params = {}) {
    const { value = 0, level = 0 } = params;

    const response = await this.$http({
      url: `${this.urlApi}/${this.apiMethod}`,
      method: 'POST',
      data: {
        request: {
          filter: {
            conditions: [
              { id: 0, field: 'value', value: [value] },
              { id: 1, field: 'level', value: [level] },
            ],
          },
        },
      },
    });

    return response.data.data.item;
  }

  _parseHierarchyPath() {
    return this.path.map((pathItem, index, arr) => {
      let icon = '';

      if (arr.length === 1) {
        icon = './assets/images/active-node.svg';
      } else if (index === 0) {
        icon = './assets/images/node-top.svg';
      } else if (index === arr.length - 1) {
        icon = './assets/images/active-node-bottom.svg';
      } else {
        icon = './assets/images/node-mid.svg';
      }

      return { ...pathItem, icon };
    });
  }
  /* */

  /* Observers */
  __onCloseDropdown() {
    this.$.querySelectorAll(`power-accordion[open]`).forEach(nodeItem => {
      nodeItem.toggle();
    });
  }

  __onToggleMenuItem(evt) {
    if (evt.target.hasAttribute('open')) {
      this.$.querySelectorAll(`power-accordion[open]:not([id="${evt.target.id}"])`).forEach(
        nodeItem => {
          nodeItem.toggle();
        },
      );
    }
  }
  /* */
}

class GolfleetNavigationHierarchy {
  constructor() {
    this.template = template;
    this.bindings = {
      icon: '=?',
      apiMethod: '=?',
      apiParams: '=?',
    };
    this.controller = GolfleetNavigationHierarchyController;
  }
}

angular
  .module('golfleet-navigation-hierarchy', ['ngSanitize', 'power-accordion'])
  .component('golfleetNavigationHierarchy', new GolfleetNavigationHierarchy());

export { GolfleetNavigationHierarchyController, GolfleetNavigationHierarchy };
