import angular from 'angular';

import '../power-dropdown/power-dropdown';

import template from './power-period.html';
import './power-period.scss';

const DaysOfWeek = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

class Interval {
  constructor(starts, ends = null, bitArray = [], isValid = true) {
    this.starts = starts;
    this.ends = ends;
    this.bitArray = bitArray;
    this.isValid = isValid;
  }
}

class Period {
  static get Type() {
    return {
      FULL_HOUR: 24,
      HALF_HOUR: 48,
    };
  }

  constructor(description, bitArray = [], intervals = []) {
    this.description = description;
    this.bitArray = bitArray;
    this.intervals = intervals;
  }

  static ParseNumberToBitArray(value, periodType) {
    return Period.ConvertStringOfBitsToArray(
      Number(value).toString(2).padStart(periodType, String(0)),
    );
  }

  static ParseBitArrayToIntervalArray(value) {
    let intervals = [];
    let lastValueItem = 0;

    value.forEach((bit, index) => {
      if (bit) {
        if (bit !== lastValueItem) {
          intervals.push(new Interval(index));
        }
      } else if (bit !== lastValueItem) {
        const item = intervals[intervals.length - 1];
        item.ends = index;
      }

      lastValueItem = bit;
    });

    if (!intervals.length) {
      intervals = [new Interval(0, 0)];
    }

    intervals.forEach(interval => {
      if (interval.ends === null) {
        interval.ends = value.length;
      }

      let bits = '';
      for (let index = 0; index < value.length; index++) {
        if (index >= interval.starts && index < interval.ends) {
          bits = bits.concat(1);
        } else {
          bits = bits.concat(0);
        }
      }

      interval.bitArray = Period.ConvertStringOfBitsToArray(bits);
    });

    return intervals;
  }

  static ParseIndexToHourString(value, periodType) {
    const index = typeof value === 'number' ? value : periodType;

    const hours = i => `${parseInt(i / 2)}`.padStart(2, String(0));
    const minutes = i => (i % 2 == 0 ? '00' : '30');

    if (periodType === Period.Type.HALF_HOUR) {
      return `${hours(index)}:${minutes(index)}`;
    }

    return `${index}:00`.padStart(5, String(0));
  }

  /**
   * @param {Interval[]} intervals
   * @param {Number} periodType
   */
  static JoinIntervalsBitArray(intervals, periodType) {
    return Period.ConvertStringOfBitsToArray(
      intervals
        .reduce((acc, item) => {
          item.bitArray.forEach((bit, index) => {
            if (bit) {
              const bits = acc.split('');
              bits[index] = bit;
              acc = bits.join('');
            }
          });
          return acc;
        }, String(0).repeat(periodType))
        .padStart(periodType, String(0)),
    );
  }

  static ConvertStringOfBitsToArray(value) {
    return value.split('').map(bit => Number(bit));
  }
}

class PowerPeriodController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$http', 'urlApi', '$ngRedux'];
  }

  constructor($element, $scope, $rootScope, $http, urlApi, $ngRedux) {
    Object.assign(this, { $: $element[0], $scope, $rootScope, $http, urlApi, $ngRedux });

    this.__appBehavior = this.$ngRedux.connect(behavior =>
      Object({ isTrip: behavior.session.isTrip }),
    )(this);

    this.periodController = { daysOfWeek: [] };
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getValueAsArrayOfBits: this.getValueAsArrayOfBits.bind(this),
      getValue: this.getValue.bind(this),
      setValue: this.setValue.bind(this),
      clearValue: this.clearValue.bind(this),
      setMaxValue: this.setMaxValue.bind(this),
      getHoursDescription: this.getHoursDescription.bind(this),
      getPeriodType: this.getPeriodType.bind(this),
      toggleValueOptions: this.toggleValueOptions.bind(this),
      toggleMaxValueOptions: this.toggleMaxValueOptions.bind(this),
    });

    this.$scope.$watch(
      () => JSON.stringify(this.periodController.daysOfWeek),
      this.__valueChangedObserver.bind(this),
    );

    this.periodType = this.isTrip ? Period.Type.HALF_HOUR : Period.Type.FULL_HOUR;
    this.setValue(this.value);
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  getValueAsArrayOfBits() {
    return this.periodController.daysOfWeek.map(dayOfWeek => {
      const arrayBits = Period.JoinIntervalsBitArray(dayOfWeek.intervals, this.periodType);
      return arrayBits;
    });
  }

  getValue() {
    const data = this.getValueAsArrayOfBits();

    return data.reduce((acc, arrayBits) => {
      acc.push(parseInt(arrayBits.join(''), 2));
      return acc;
    }, []);
  }

  setValue(value = []) {
    DaysOfWeek.forEach((dayOfWeek, index) => {
      const _value = Array.isArray(value) ? value : [value];

      const bitArray = Period.ParseNumberToBitArray(_value[index] || 0, this.periodType);
      const intervals = Period.ParseBitArrayToIntervalArray(bitArray);

      const periodItem = new Period(dayOfWeek, bitArray, intervals);
      this.periodController.daysOfWeek[index] = periodItem;
    });
  }

  clearValue() {
    const value = String(0)
      .repeat(DaysOfWeek.length)
      .split('')
      .map(item => Number(item));

    this.setValue(value);
  }

  setMaxValue() {
    const aux = parseInt(String(1).repeat(this.periodType), 2);
    const value = [];

    for (let i = 0; i < DaysOfWeek.length; i++) {
      value.push(aux);
    }

    this.setValue(value);
  }

  getHoursDescription() {
    const arrayOfBits = this.getValueAsArrayOfBits();

    // eslint-disable-next-line arrow-body-style
    const count = arrayOfBits.reduce((bitArrayTotal, bitArray) => {
      return (
        bitArrayTotal +
        bitArray.reduce((bitItemTotal, bitItem) => {
          if (bitItem > 0) return bitItemTotal + 1;
          return bitItemTotal;
        }, 0)
      );
    }, 0);

    if (this.periodType === Period.Type.HALF_HOUR && count > 0) {
      if ((count / 2) % 1 == 0) {
        return `${parseInt(count / 2, 10)} horas semanais`;
      }

      return `${parseInt(count / 2, 10)} horas e 30 minutos semanais`;
    }

    return `${count} horas semanais`;
  }

  getPeriodType() {
    return this.periodType;
  }

  toggleValueOptions() {
    const value = this.getValueAsArrayOfBits().map(bits => {
      const result = bits.reduce((acc, bit) => `${acc}${bit === 1 ? 0 : 1}`, '');
      return parseInt(result, 2);
    });

    this.setValue(value);
  }

  toggleMaxValueOptions() {
    const maxValueSelectedOnly = this.getValueAsArrayOfBits().every(bits => {
      const currentBitsValue = bits.join('');
      return currentBitsValue === `${1}`.repeat(this.periodType);
    });

    const value = this.getValueAsArrayOfBits().map(() => {
      const result = `${maxValueSelectedOnly ? 0 : 1}`.repeat(this.periodType);
      return parseInt(result, 2);
    });

    this.setValue(value);
  }

  /* */

  /* Private */
  _intervalsValidate(dayOfWeek) {
    if (dayOfWeek.intervals.length === 1) {
      dayOfWeek.intervals[0].isValid = true;
      return;
    }

    const arrayBitSum = value => value.reduce((acc, bit) => acc + bit, 0);

    const hasConflict = (bitArrayMajor, bitArrayMinor) =>
      bitArrayMinor.find((bitMinor, index) => {
        const result = bitMinor === 1 && bitArrayMajor[index] === 1;
        return result;
      });

    const intervals = [...dayOfWeek.intervals].sort(
      (a, b) => arrayBitSum(a.bitArray) - arrayBitSum(b.bitArray),
    );
    intervals.reverse().forEach((interval, intervalIndex, array) => {
      const isInvalid = !!array.find((item, itemIndex) => {
        if (intervalIndex <= itemIndex) return false;
        return hasConflict(interval.bitArray, item.bitArray);
      });

      interval.isValid = !isInvalid;
    });
  }

  _parseIndexValueToHourString(value) {
    return Period.ParseIndexToHourString(value, this.periodType);
  }

  _userCanAddInterval(dayOfWeek) {
    const bitArray = Period.JoinIntervalsBitArray(dayOfWeek.intervals, this.periodType);
    const hasSelection = !!bitArray.find(bit => bit === 1);
    const isAllBitsSelecteds = !!bitArray.every(bit => bit === 1);

    return !isAllBitsSelecteds && hasSelection;
  }

  _intervalIndexIsValid(dayOfWeek, intervalIndex, bitIndex) {
    if (dayOfWeek.intervals.length === 1) return true;
    return !dayOfWeek.intervals.find((interval, index) => {
      if (intervalIndex === index) return false;
      return Boolean(interval.bitArray[bitIndex]);
    });
  }

  _addInterval(dayOfWeek) {
    const intervalBitArray = Period.ConvertStringOfBitsToArray(String(0).repeat(this.periodType));
    dayOfWeek.intervals.push(new Interval(0, 0, intervalBitArray));
  }

  _removeInterval(dayOfWeek, intervalIndex) {
    dayOfWeek.intervals = dayOfWeek.intervals.reduce((acc, interval, index) => {
      if (index === intervalIndex) return acc;
      return acc.concat(interval);
    }, []);

    dayOfWeek.bitArray = Period.JoinIntervalsBitArray(dayOfWeek.intervals, this.periodType);
    this._intervalsValidate(dayOfWeek);
  }

  _setIntevalStartsIndex(dayOfWeek, interval, intervalIndex, bitIndex) {
    const intervalBitArray = [...interval.bitArray].map((_, index) => {
      const bitValue = index === bitIndex || (index >= bitIndex && index < interval.ends) ? 1 : 0;
      return bitValue;
    });

    [dayOfWeek.intervals[intervalIndex]] = Period.ParseBitArrayToIntervalArray(intervalBitArray);
    dayOfWeek.bitArray = Period.JoinIntervalsBitArray(dayOfWeek.intervals, this.periodType);
    this._intervalsValidate(dayOfWeek);
  }

  _setIntevalEndsIndex(dayOfWeek, interval, intervalIndex, bitIndex) {
    const intervalBitArray = [...interval.bitArray].map((_, index) => {
      if (index < bitIndex) {
        if (index >= interval.starts) return 1;
        return 0;
      }
      return bitIndex === index ? 1 : 0;
    });

    [dayOfWeek.intervals[intervalIndex]] = Period.ParseBitArrayToIntervalArray(intervalBitArray);
    dayOfWeek.bitArray = Period.JoinIntervalsBitArray(dayOfWeek.intervals, this.periodType);
    this._intervalsValidate(dayOfWeek);
  }
  /* */

  /* Observers */
  __valueChangedObserver() {
    this.$.dispatchEvent(new CustomEvent('valueChanged', { detail: { value: this.getValue() } }));
  }
  /* */
}

class PowerPeriod {
  constructor() {
    this.bindings = {
      value: '=?',
    };
    this.template = template;
    this.controller = PowerPeriodController;
  }
}

angular
  .module('power-period', ['power-dropdown'])
  .component('powerPeriod', new PowerPeriod());
