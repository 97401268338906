/* eslint new-cap: ["error", { "newIsCap": false }] */
import angular from 'angular';
import 'ng-redux';

import '../power-dropdown/power-dropdown';

import template from './power-select.html';
import './power-select.scss';

class PowerSelectController {
  static get $inject() {
    return ['$element', '$sce', '$rootScope', '$state', '$http', '$scope', 'urlApi'];
  }

  /**
   * Creates an instance of PowerSelectController.
   * @memberof PowerSelectController
   */
  constructor($element, $sce, $rootScope, $state, $http, $scope, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $sce,
      $rootScope,
      $state,
      $http,
      $scope,
      urlApi,
    });

    this.options = [];
    this.label = '';
    this.field = '';
  }

  /* Lifecycle */
  $onInit() {}

  /**
   * Private
   */
  _getSelectedOption() {
    let [optionSelected] = this.options.filter(option => option.selected);

    if (!optionSelected && this.options.length > 0) {
      this.options[0].selected = true;
      [optionSelected] = this.options;
    }

    return optionSelected ? optionSelected.value : '';
  }

  _disabled() {
    return this.options.length < 2;
  }

  _selectOption(option) {
    // eslint-disable-next-line no-return-assign
    this.options.forEach(itemOption => {
      itemOption.selected = itemOption.value == option.value;
    });

    this.$scope.$emit('change_selected_item', option);
  }
}

class PowerSelect {
  constructor() {
    this.template = template;
    this.bindings = {
      options: '=?',
      label: '=',
      field: '=',
    };
    this.controller = PowerSelectController;
  }
}

angular
  .module('power-select', ['power-dropdown'])
  .component('powerSelect', new PowerSelect());
