import angular from 'angular';

import template from './golfleet-chat.html';
import './golfleet-chat.scss';

class GolfleetChatController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });

    this._postionElement();
  }

  $onDestroy() {}
  /* */

  /* Public */
  toggle() {
    if (this.$.hasAttribute('open')) {
      this.$.removeAttribute('open');
      this.$.dispatchEvent(
        new CustomEvent('toggle', {
          detail: {
            open: false,
          },
        }),
      );
    } else {
      this._postionElement();
      this._setChatContent(this.$);

      this.$.dispatchEvent(new CustomEvent('onOpen'));
      this.$.setAttribute('open', '');
      this.$.dispatchEvent(
        new CustomEvent('toggle', {
          detail: {
            open: true,
          },
        }),
      );
    }
  }
  /* */

  /* Private */
  _setChatContent(element) {
    if (element.setAttribute && !element.classList.contains('golfleet-chat-container')) {
      element.setAttribute('chat-content', '');
    }

    if (!element.hasChildNodes()) return null;
    return element.childNodes.forEach(node => this._setChatContent(node));
  }

  _postionElement() {
    const button = this.$.querySelector('.golfleet-chat-button');
    const chat = this.$.querySelector('.golfleet-chat');
    /* Column Position */
    if (this.positionTop) chat.style.top = `${Number(this.positionTop)}px`;
    else if (this.positionBottom)
      chat.style.bottom = `${Number(this.positionBottom) + button.clientHeight}px`;
    /* Row Position */
    if (this.positionLeft) chat.style.left = `${Number(this.positionLeft)}px`;
    else if (this.positionRight) chat.style.right = `${Number(this.positionRight)}px`;
  }
  /* */
}

class GolfleetChat {
  constructor() {
    this.template = template;
    this.bindings = {
      positionTop: '@?',
      positionBottom: '@?',
      positionLeft: '@?',
      positionRight: '@?',
    };
    this.transclude = {
      buttonSlot: '?buttonSlot',
      headerSlot: '?headerSlot',
      bodySlot: '?bodySlot',
      footerSlot: '?footerSlot',
    };
    this.controller = GolfleetChatController;
  }
}

angular.module('golfleet-chat', []).component('golfleetChat', new GolfleetChat());
