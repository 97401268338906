import angular from 'angular';
import moment from 'moment';

import { toDateTimeOffset, toDateOffset, toTimeOffset } from '../../utils/date-util.js';

import 'ng-redux';

import '../power-dropdown/power-dropdown';
import '../power-footer/power-footer';
import '../power-popup/power-popup';
import '../power-toast/power-toast';
import '../power-toolbar/power-toolbar';
import '../power-crud/power-crud';
import '../power-share-tree/power-share-tree';
import '../power-period-preview/power-period-preview';
import { ReportStateConfig, RecordStateConfig } from '../power-state-config/power-state-config';

import '../../directives/infinite-scroll/infinite-scroll';
import '../../directives/ng-tippy/ng-tippy';

import template from './power-record.html';
import './power-record.scss';

class PowerRecordController {
  static get $inject() {
    return ['$element', '$ngRedux', '$state', '$scope', '$http', 'recordServices', 'urlApi'];
  }

  constructor($element, $ngRedux, $state, $scope, $http, recordServices, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      recordServices,
      urlApi,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* Session Storage */
        onAdmNavigationMode: behavior.session.onAdmNavigationMode,
        isSingleSignon: behavior.session.isSingleSignon,
        isTrip: behavior.session.isTrip,
        /* State Storage */
        stateList: behavior.state.routeList,
        lastState:
          behavior.state.routeList.length > 1
            ? behavior.state.routeList[behavior.state.routeList.length - 2]
            : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        isVideoTelemetry: behavior.session.isVideoTelemetry,
        modules: behavior.session.modules,
      });
    })(this);

    this.stateData = null;
    this.tabSelected = 0;
    this.tabsPrevButtonDisabled = true;
    this.tabsNextButtonDisabled = false;

    this.showVehicleSummary = true;

    this.toastObj = { text: '' };

    this._getDataset();
  }

  /* Lifecycle */
  $onInit() {
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    this.$scope.$on('stateDataRequest', this._onStateDataRequest.bind(this));
    this.$scope.$on('showToast', (evt, data) => {
      this.$.querySelector('#toast-obj').toggle(true);
      this.toastObj = { text: data.text };
    });

    this.$.querySelector('#tabs-list').addEventListener(
      'scroll',
      this._onTabsListScroll.bind(this),
    );
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  onEditCancel() {
    this.$.querySelector('#popup-edit-crud').toggle();
    setTimeout(() => {
      this.editCrud = null;
    });
  }

  onEditConfirm(method, data) {
    const payload = {
      request: {
        id: this.stateData.id,
      },
    };

    payload.request = Object.keys(data).reduce(
      (acc, item) => {
        acc[item] = data[item];
        return acc;
      },
      { ...payload.request },
    );

    this.editCrud = null;
    this.$.querySelector('#popup-edit-crud').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.recordServices
      .requestAction(method, payload)
      .then(
        success => {
          if (success.data.hasError) {
            this.toastObj.text = 'Ocorreu um erro ao editar, tente novamente!';
            this.$.querySelector('#toast-obj').toggle(true);
            return;
          }
          const recordRouteName = this.currentState.routeName;
          this.toastObj.text = 'Editado com sucesso!';
          this.$.querySelector('#toast-obj').toggle(true);
          this.$state.go(this.stateConfig.routeLink, {
            tail: this.stateConfig.routeTail,
          });
          this.recordServices
            .callApiMethod(this.editConfig.actionMethod, {
              id: this.stateData.id,
            })
            .then(auxSuccess => {
              this.stateData = auxSuccess.data.data;
              if (recordRouteName === this.currentState.routeName) {
                this.currentState.routeName = this.stateData[this.stateConfig.title];
              }
            });
        },
        error => {
          switch (error.status) {
            case 406:
            case 422:
              this.toastObj.text = error.data.data.toString();
              break;
            default:
              this.toastObj.text = 'Ocorreu um erro ao editar, tente novamente!';
              break;
          }
          this.$.querySelector('#toast-obj').toggle(true);
        },
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }
  /* */

  /* Private */
  _onStateDataRequest() {
    this._getDataset();
  }

  _getDataset() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    const msgError = 'Ops! Ocorreu um erro ao carregar as informações.';
    this.$http({
      url: `${this.urlApi}/${this.stateConfig.getDataMethod}`,
      method: 'POST',
      data: { ...this.stateConfig.getDataFixedParams },
    })
      .then(
        success => {
          if (success.status && success.status !== 200) {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#toast-obj').toggle(true);
          } else if (success.data && !success.data.hasError) {
            this.stateData = { ...success.data.data };

            if ('dadosHistoricos' in success.data.data) {
              if (success.data.data.dadosHistoricos) {
                this.showVehicleSummary = false;
              }
            }
          } else {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#toast-obj').toggle(true);
          }
        },
        error => {
          console.error(error);
          this.toastObj = {
            text: msgError,
          };
          this.$.querySelector('#toast-obj').toggle(true);
        },
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _goToLink(
    routeName,
    routeLink,
    stateFixedParams,
    fixedFilters,
    linkStateConfig,
    getDataMethod,
    backPagination,
    data,
  ) {
    const getDataFixedParams = Object.keys(stateFixedParams).reduce((acc, item) => {
      if (this.getPropertyValue(data, stateFixedParams[item]))
        acc[item] = this.getPropertyValue(data, stateFixedParams[item]);
      else if (this.stateConfig.getDataFixedParams[stateFixedParams[item]])
        acc[item] = this.stateConfig.getDataFixedParams[stateFixedParams[item]];
      else acc[item] = stateFixedParams[item];
      return acc;
    }, {});
    let getDataFilters = [];

    if (backPagination === null) backPagination = true;

    if (fixedFilters)
      getDataFilters = fixedFilters.map(filter => {
        const filterDefault = filter.condition.map((condition, index) => {
          const fieldData = this.getPropertyValue(data, condition.field);
          if (filter.type == 'DateTime') {
            let conditionDate = moment(fieldData).utcOffset(-3);

            conditionDate = conditionDate.subtract(condition.value, 'days');

            if (!filter.keepTime) {
              if (index == 0) conditionDate.hour(0).minute(0).second(0).millisecond(0);
              else if (index == 1) conditionDate.hour(23).minute(59).second(59).millisecond(0);
            }

            return conditionDate._d;
          }
          return condition.value ? fieldData + condition.value : fieldData;
        });
        return {
          id: filter.id,
          default: filterDefault,
          activeView: filter.activeView,
        };
      });
    switch (routeLink) {
      case 'record':
      case 'recordVehicle': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        const stateConfig = new RecordStateConfig({
          isAdm: this.onAdmNavigationMode,
          getDataMethod,
          getDataFixedParams,
        });

        Object.assign(
          stateConfig,
          Object.keys(linkStateConfig).reduce(
            (acc, item) => {
              acc[item] = linkStateConfig[item];
              return acc;
            },
            { ...stateConfig },
          ),
        );

        this.recordServices.getRecordConfig(stateConfig.type).then(
          result => {
            Object.assign(
              stateConfig,
              Object.keys(result).reduce(
                (acc, item) => {
                  acc[item] = result[item];
                  return acc;
                },
                { ...stateConfig },
              ),
            );

            this.$ngRedux.dispatch({
              type: 'NEXT_ROUTE',
              data: {
                routeName: this.getPropertyValue(data, routeName) || routeName,
                routeSubName: linkStateConfig?.routeSubName,
                routeLink,
                routeTail: getDataFixedParams.id,
                stateConfig,
              },
            });
            this.$state.go(routeLink, { tail: getDataFixedParams.id });
          },
          () => {
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            );
          },
        );
        return;
      }
      case 'report': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        const stateConfig = new ReportStateConfig({
          isAdm: this.onAdmNavigationMode,
          viewMode: 'grid',
          getDataMethod,
          backPagination,
          getDataFilters,
          getDataFixedParams,
        });
        Object.assign(
          stateConfig,
          Object.keys(linkStateConfig).reduce(
            (acc, item) => {
              acc[item] = linkStateConfig[item];
              return acc;
            },
            { ...stateConfig },
          ),
        );
        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: this.getPropertyValue(data, routeName) || routeName,
            routeSubName: linkStateConfig?.routeSubName,
            routeLink,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
        });
        this.$state.go(routeLink, { tail: getDataFixedParams.id });
        return;
      }
      case 'streetView': {
        if (getDataFixedParams.paramsStreetView) {
          // 0. latitude, 1. longitude, 2. heading, 3. pitch, 4. zoom, 5. panoid
          const paramsStreetView = getDataFixedParams.paramsStreetView.split('|');
          window.open(
            'https://maps.google.com/maps?layer=c&q=' +
              `&cbll=${paramsStreetView[0]},${paramsStreetView[1]}` +
              `&cbp=11,${paramsStreetView[2]},0,` +
              `${paramsStreetView[4]},${paramsStreetView[3]}` +
              `&panoid=${paramsStreetView[5]}`,
            '_blank',
          );
        } else {
          window.open(
            'https://maps.google.com/maps?layer=c' +
              `&q=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              `&cbll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              '&cbp=11,0,0,0,0&z=17' +
              `&ll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}`,
            '_blank',
          );
        }
        break;
      }
      case 'form': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: this.getPropertyValue(data, routeName) || routeName,
            routeSubName: linkStateConfig?.routeSubName,
            routeLink: linkStateConfig.type,
            routeTail: getDataFixedParams.id,
            stateConfig: {
              ...this.stateConfig,
              getDataMethod,
              gridName: 'Formulário',
              filterConfig: [],
              getDataFixedParams,
            },
          },
        });

        this.$state.go(linkStateConfig.type, {
          tail: getDataFixedParams.id,
        });

        break;
      }
      default: {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        const stateConfig = new ReportStateConfig({
          isAdm: this.onAdmNavigationMode,
          viewMode: 'grid',
          getDataMethod,
          backPagination,
          getDataFilters,
          getDataFixedParams,
        });
        Object.assign(
          stateConfig,
          Object.keys(linkStateConfig).reduce(
            (acc, item) => {
              acc[item] = linkStateConfig[item];
              return acc;
            },
            { ...stateConfig },
          ),
        );
        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: data[routeName] || routeName,
            routeSubName: linkStateConfig?.routeSubName,
            routeLink,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
        });
        this.$state.go(routeLink, { tail: getDataFixedParams.id });
      }
    }
  }

  _errorAddItem() {
    this.toastObj = {
      text: 'Por favor verifique os campos',
    };
    this.$.querySelector('power-toast').toggle(true);
  }

  _evtClickToggleDetail(event) {
    if (event.currentTarget.children[0].innerHTML == 'keyboard_arrow_down') {
      event.currentTarget.children[0].innerHTML = 'keyboard_arrow_up';
      Object.assign(event.currentTarget.parentElement.nextElementSibling.style, {
        display: 'block',
      });
    } else {
      event.currentTarget.children[0].innerHTML = 'keyboard_arrow_down';
      Object.assign(event.currentTarget.parentElement.nextElementSibling.style, {
        display: 'none',
      });
    }
  }

  _actionFunction(itemReturn) {
    let json = {};
    switch (itemReturn.actionType) {
      case 'exec': {
        json.request = { vehicleId: this.stateConfig.getDataFixedParams.id };
        this.__callApiMethod(
          itemReturn.getDataMethod,
          json,
          {
            success: null,
            error: 'Ops! Erro ao carregar as informações',
          },
          true,
        ).then(result => {
          if (result && !result.hasError) {
            this.execPopup = {
              open: true,
              title: itemReturn.actionTitle
                .split('<item>')
                .join(this.stateData[this.stateConfig.title]),
              icon: itemReturn.actionIcon,
              list: result.data,
              method: itemReturn.actionMethod,
              json,
              itemSelected: null,
              text: `${itemReturn.actionText} para ${this.stateData[this.stateConfig.title]}`,
            };
            this.$.querySelector('#popup-exec').toggle();
          }
        });
        break;
      }
      case 'share': {
        this.__callApiMethod(
          itemReturn.getDataMethod,
          json,
          {
            success: null,
            error: 'Ops! Erro ao carregar as informações',
          },
          true,
        ).then(result => {
          if (result && !result.hasError) {
            this.sharePopup = {
              open: true,
              title: itemReturn.actionTitle
                .split('<item>')
                .join(this.stateData[this.stateConfig.title]),
              icon: itemReturn.actionIcon,
              list: result.data,
              method: itemReturn.actionMethod,
              itemSelected: null,
              json: {
                type: this.stateConfig.type,
                objects: [{ objectId: this.stateData.id }],
              },
              text: `${this.stateData[this.stateConfig.title]} foi ${itemReturn.actionText}`,
            };
            this.$.querySelector('#popup-share').toggle();
          }
        });
        break;
      }
      case 'edit': {
        this.editConfig = Object.clone({}, itemReturn);
        this.editConfig.crudConfig.sections.forEach(horizontalGroup => {
          horizontalGroup.forEach(verticalGroup => {
            verticalGroup.forEach(item => {
              item.value =
                this.stateData[item.key] ||
                this.stateData[item.key] === 0 ||
                this.stateData[item.key] === false
                  ? this.stateData[item.key]
                  : null;
            });
          });
        });
        this.editCrud = {
          ...this.editConfig.crudConfig,
          icon: this.stateData[this.editConfig.crudConfig.icon] || this.editConfig.crudConfig.icon,
        };
        this.$.querySelector('#popup-edit-crud').toggle();
        break;
      }
      case 'history': {
        json.request = { vehicleId: this.stateConfig.getDataFixedParams.id };
        this.__callApiMethod(
          itemReturn.getDataMethod,
          json,
          {
            success: null,
            error: 'Ops! Erro ao carregar as informações',
          },
          true,
        ).then(result => {
          if (result && !result.hasError) {
            this.historyPopup = {
              open: true,
              title: itemReturn.actionTitle,
              icon: itemReturn.actionIcon,
              list: result.data.data,
              json,
            };
            this.$.querySelector('#popup-history').toggle();
          }
        });
        break;
      }
      case 'delete': {
        json = {
          objects: [
            {
              objectId: this.stateData.id,
            },
          ],
        };
        this.$.querySelector('#popup-confirmation').toggle();
        this.confirmationPopup = {
          text: itemReturn.description,
          confirm: () => {
            const msgError = 'Ops! Ocorreu um erro, tente novamente.';
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: true },
                bubbles: true,
                composed: true,
              }),
            );
            this.$.querySelector('#popup-confirmation').toggle();
            this.$http({
              url: `${this.urlApi}/${itemReturn.actionMethod}`,
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              },
              data: { ...json },
            }).then(
              success => {
                if (success.status && success.status !== 200) {
                  this.toastObj = {
                    text: msgError,
                  };
                  this.$.querySelector('#toast-obj').toggle(true);
                  return;
                }
                this.$.dispatchEvent(
                  new CustomEvent('toggleLoader', {
                    detail: { showLoader: false },
                    bubbles: true,
                    composed: true,
                  }),
                );
                const previousIndex = this.stateList.length - 2;
                const previousRouteLink = this.lastState.routeLink;
                const previousRouteTail = this.lastState.routeTail;
                this.$ngRedux.dispatch({
                  type: 'PREVIOUS_ROUTE',
                  data: { index: previousIndex },
                });
                this.$state.go(previousRouteLink, { tail: previousRouteTail });
              },
              () => {
                this.toastObj = {
                  text: msgError,
                };
                this.$.querySelector('#toast-obj').toggle(true);
                this.$.dispatchEvent(
                  new CustomEvent('toggleLoader', {
                    detail: { showLoader: false },
                    bubbles: true,
                    composed: true,
                  }),
                );
              },
            );
          },
          cancel: () => this.$.querySelector('#popup-confirmation').toggle(),
        };
        break;
      }
      case 'confirm': {
        itemReturn.actionParameters = Object.keys(itemReturn.actionParameters).reduce(
          (acc, item) => {
            acc[item] = this.stateData[acc[item]] || acc[item];
            return acc;
          },
          { ...itemReturn.actionParameters },
        );

        json = { id: this.stateData.id, ...itemReturn.actionParameters };
        this.$.querySelector('#popup-confirmation').toggle();
        this.confirmationPopup = {
          open: true,
          text: itemReturn.text,
          confirm: () => {
            this.$.querySelector('#popup-confirmation').toggle();
            this.__callApiMethod(
              itemReturn.actionMethod,
              json,
              {
                success: itemReturn.returnMessage || 'Solicitação realizada com sucesso!',
                error: 'Erro ao realizar a solicitação',
              },
              true,
            );
          },
          cancel: () => {
            this.$.querySelector('#popup-confirmation').toggle();
          },
        };
        break;
      }
      default:
        break;
    }
  }

  _actionValue(
    method,
    fieldsParam,
    itemParam,
    msgQuestion,
    msgSuccess,
    msgError,
    msgText = '',
    msgQuestionValue = '',
    msgTextValue = '',
  ) {
    const payload = {
      id: this.stateData.id,
    };
    if (itemParam) payload.item = itemParam;
    if (fieldsParam && fieldsParam.length > 0)
      fieldsParam.forEach(item => {
        payload[item] = this.stateData[item];
      });

    this.$.querySelector('#popup-confirmation').toggle();
    this.confirmationPopup = {
      open: true,
      text:
        msgQuestion && msgQuestionValue != ''
          ? msgQuestion.split('<item>').join(msgQuestionValue)
          : msgQuestion || 'Deseja realmente executar esta ação?',
      subtext:
        msgText && msgTextValue != ''
          ? msgText.split('<item>').join(msgTextValue)
          : msgText || null,
      confirm: () => {
        this.$.querySelector('#popup-confirmation').toggle();
        this.__callApiMethod(
          method,
          payload,
          {
            success: msgSuccess || 'Ação realizada com sucesso!',
            error: msgError || 'Ocorreu um erro, tente novamente!',
          },
          true,
        ).then(result => {
          if (result && !result.hasError) this._getDataset();
        });
      },
      cancel: () => {
        this.$.querySelector('#popup-confirmation').toggle();
      },
    };
  }

  _selectTab(index) {
    this.tabSelected = index;
    this.stateConfig.tabs.forEach((tab, tabIndex) => {
      if (tab.active && tabIndex != index) tab.active = false;
    });
    if (!this.stateConfig.tabs[index].active) this.stateConfig.tabs[index].active = true;
    if (
      ['list', 'listWithoutLabel'].includes(this.stateConfig.tabs[index].data) &&
      this.stateConfig.tabs[index].method
    ) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      const msgError = 'Ops! Ocorreu um erro ao carregar as informações.';
      this.$http({
        url: `${this.urlApi}/${this.stateConfig.tabs[index].method}`,
        method: 'POST',
        data: { ...this.stateConfig.getDataFixedParams },
      })
        .then(
          success => {
            if (success.status && success.status !== 200) {
              this.toastObj = {
                text: msgError,
              };
              this.$.querySelector('#toast-obj').toggle(true);
            } else if (success.data && !success.data.hasError) {
              this.dataList = { ...success.data.data };
              this._toggleListItem(0);
            } else {
              this.toastObj = {
                text: msgError,
              };
              this.$.querySelector('#toast-obj').toggle(true);
            }
          },
          error => {
            console.error(error);
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#toast-obj').toggle(true);
          },
        )
        .finally(() =>
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          ),
        );
    }
  }

  _evtClickBtnPopup(type, popupId, dataset) {
    if (type == 'close') {
      switch (popupId) {
        case 'popup-exec':
          this.execPopup.itemSelected = null;
          this.$.querySelector('#popup-exec').toggle();
          break;
        case 'popup-share':
          this.sharePopup.itemSelected = null;
          this.$.querySelector('#popup-share').toggle();
          break;
        case 'popup-history':
          this.$.querySelector('#popup-history').toggle();
          break;
        case 'popup-confirmation':
          this.confirmationPopup.cancel();
          break;
        default:
          break;
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-exec': {
          this.$.querySelector('#popup-exec').toggle();
          Object.assign(this.execPopup.json.request, {
            commandId: this.execPopup.itemSelected.commandId,
            equipamentId: this.execPopup.itemSelected.equipamentId,
          });
          this.__callApiMethod(
            this.execPopup.method,
            this.execPopup.json,
            Object({
              success: this.execPopup.text,
              error: 'Ops! Ocorreu um erro com a solicitação, tente novamente',
            }),
            true,
          ).then(result => {
            if (result && !result.hasError) {
              this.execPopup.itemSelected = null;
            }
          });
          break;
        }
        case 'popup-share': {
          this.$.querySelector('#popup-share').toggle();
          this.__callApiMethod(
            this.sharePopup.method,
            Object.assign(this.sharePopup.json, {
              toId: this.sharePopup.itemSelected.value,
              accessLevel: null,
            }),
            Object({
              success: this.sharePopup.text,
              error: 'Ops! Ocorreu um erro com a solicitação, tente novamente',
            }),
            true,
          ).then(result => {
            if (result && !result.hasError) {
              this.sharePopup.itemSelected = null;
              this._getDataset();
              this.$.querySelectorAll('power-share-tree').forEach(node => node.getTypes());
            }
          });
          break;
        }
        case 'popup-confirmation': {
          this.confirmationPopup.confirm();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'item') {
      switch (popupId) {
        case 'popup-share': {
          this.sharePopup.itemSelected = dataset;
          break;
        }
        case 'popup-exec': {
          this.execPopup.itemSelected = dataset;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  _valueIsArray(value) {
    return Array.isArray(value);
  }

  _scrollTop() {
    this.$.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  _nextTab() {
    const tabList = this.$.querySelector('#tabs-list');
    tabList.scrollTo({
      left: tabList.scrollLeft + tabList.clientWidth / 2,
      behavior: 'smooth',
    });
  }

  _backTab() {
    const tabList = this.$.querySelector('#tabs-list');
    tabList.scrollTo({
      left: tabList.scrollLeft - tabList.clientWidth / 2,
      behavior: 'smooth',
    });
  }

  _onTabsListScroll() {
    const tabList = this.$.querySelector('#tabs-list');

    this.tabsPrevButtonDisabled = tabList.scrollLeft == 0;
    this.tabsNextButtonDisabled = tabList.scrollLeft == tabList.scrollWidth - tabList.clientWidth;

    if (!this.$scope.$$phase) this.$scope.$apply();
  }

  _showShareInformation(belong) {
    this.belongSearch = '';
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.recordServices
      .getSharedList(belong.method, { ...this.stateConfig.getDataFixedParams })
      .then(
        success => {
          belong.data = success.data.data;
          this.$.querySelector(`[data-id="${belong.id}"]`).toggle();
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        },
        () =>
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          ),
      );
  }

  _getTabContentConfigs(tabContent) {
    const configs = tabContent.config.reduce((acc, item) => {
      if (
        (item.hideInSSO && this.isSingleSignon) ||
        (item.title.toString().toUpperCase().indexOf('RECONHECIMENTO FACIAL') >= 0 &&
          !this.isVideoTelemetry)
      )
        return acc;
      return acc.concat(item);
    }, []);

    const configs2 = configs.filter(
      item =>
        !item.validateModule || this.modules.some(module => item.validateModule.includes(module)),
    );

    return configs2;
  }

  _dayDiff(firstDate, secondDate) {
    return Math.abs(Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24)));
  }

  _toggleListItem(item) {
    const listItem = this.$.querySelector(`#summary-row-${item}`);
    if (listItem) {
      this.$.querySelector(`#summary-row-${item}`).toggle();
    }
  }

  getPropertyValue(data, prop) {
    if (data) {
      const levels = prop.split('.');
      return levels.reduce((acc, item) => acc[item], data);
    }
    return null;
  }
  /* */

  /* region Protected */
  __parseData(data, item) {
    if (typeof data === 'boolean') {
      return data ? 'Sim' : 'Não';
    }
    if (data && item?.type == 'DateTime') {
      return this._toDateTimeOffset(data);
    }
    return data || '---';
  }

  _toDate(date) {
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _toDateTimeOffset(dateJson, language, timezone) {
    return toDateTimeOffset({ date: dateJson, language, timezone });
  }

  _toDateOffset(dateJson, language, timezone) {
    return toDateOffset({ date: dateJson, language, timezone });
  }

  _toTimeOffset(dateJson, language, timezone) {
    return toTimeOffset({ date: dateJson, language, timezone });
  }

  __callApiMethod(method, params, messages, showLoader) {
    const msgSuccess = (messages && messages.success) || null;

    const msgError = (messages && messages.error) || null;

    if (showLoader)
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: params,
    })
      .then(
        success => {
          if (success.status && success.status !== 200) {
            if (msgError) {
              this.toastObj = {
                text: msgError,
              };
              this.$.querySelector('#toast-obj').toggle(true);
            }
            return null;
          }
          if (success.data && !success.data.hasError) {
            if (msgSuccess) {
              this.toastObj = {
                text: msgSuccess,
              };
              this.$.querySelector('#toast-obj').toggle(true);
            }

            return success.data;
          }
          if (msgError) {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#toast-obj').toggle(true);
          }

          return null;
        },
        error => {
          console.error(error);
          if (msgError) {
            this.toastObj = {
              text: msgError,
            };
            this.$.querySelector('#toast-obj').toggle(true);
          }

          return null;
        },
      )
      .finally(() => {
        if (showLoader)
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
      });
  }
  /* */
}

class PowerRecord {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerRecordController;
  }
}

angular
  .module('power-record', [
    'ngRedux',
    'infinite-scroll',
    'ng-tippy',
    'power-dropdown',
    'power-footer',
    'power-popup',
    'power-share-tree',
    'power-toast',
    'power-toolbar',
    'power-crud',
    'power-period-preview',
  ])
  .component('powerRecord', new PowerRecord());

export { PowerRecordController };
