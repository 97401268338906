import angular from 'angular';
import '../../helpers/is-iframe/is-iframe';

import { PowerMapGeneratedAlertsHereProvider } from './providers/here/power-map-generated-alerts.here-provider';
import { PowerMapGeneratedAlertsLeafletProvider } from './providers/leaflet/power-map-generated-alerts.leaflet-provider';
import { PowerMapController } from '../power-map/power-map';

import template from './power-map-generated-alerts.html';

class PowerMapGeneratedAlertsController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new PowerMapGeneratedAlertsHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new PowerMapGeneratedAlertsLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }
}

class PowerMapGeneratedAlerts {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = PowerMapGeneratedAlertsController;
  }
}

angular
  .module('power-map-generated-alerts', [])
  .component('powerMapGeneratedAlerts', new PowerMapGeneratedAlerts());

export { PowerMapGeneratedAlertsController };
