/* global L: true */
import { PowerMapLeafletProvider } from '../../../power-map/providers/leaflet/power-map.leaflet-provider';
import './power-map-points-of-interest.leaflet-provider.scss';

class PowerMapPointsOfInterestLeafletProvider extends PowerMapLeafletProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.drawController = null;
    this.drawEndedCallback = null;
    this.currentPoint = null;
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      startDraw: this.startDraw.bind(this),
      endDraw: this.endDraw.bind(this),
      cancelDraw: this.cancelDraw.bind(this),
      setCurrentPoint: this.setCurrentPoint.bind(this),
      toggleNearBySearch: this.toggleNearBySearch.bind(this),
    });

    super.$onInit();
  }
  // #endregion Lifecycle

  // #region Public
  renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
  }) {
    return this._createDatasetLayer({ type, dataset }).then(featureGroup => {
      if (this.mapFeatureLayers[layerName])
        this.mapLayer.removeLayer(this.mapFeatureLayers[layerName]);
      if (useCluster)
        this.mapFeatureLayers[layerName] = this._createCluster({ clusterColor }).addLayer(
          featureGroup,
        );
      else if (layerName == 'draw')
        this.mapFeatureLayers[layerName].addLayer(featureGroup.getLayers()[0]);
      else this.mapFeatureLayers[layerName] = featureGroup;
      this.mapLayer.addLayer(this.mapFeatureLayers[layerName]);
    });
  }

  startDraw({ callback, dataset }) {
    this.$.setAttribute('draw', '');
    this.drawEndedCallback = callback;
    this.$.querySelector('#draw-actions').style.visibility = 'visible';
    if (!this.drawController) this._initializeMapDrawTool();
    else this.mapLayer.addControl(this.drawController);

    if (dataset?.geoJson) {
      const [longitude, latitude] = dataset.geoJson.coordinates;
      const point = L.marker([latitude, longitude]);
      this.mapFeatureLayers.draw.addLayer(point);
      const bounds = point.getLatLng().toBounds(latitude, longitude);
      this.mapLayer.fitBounds(bounds);
    }
  }

  endDraw() {
    const geoJson = this.mapFeatureLayers.draw.getLayers().map(layer => layer.toGeoJSON())[0];
    this.drawEndedCallback(geoJson);
    this.cancelDraw();
  }

  cancelDraw() {
    this.$.removeAttribute('draw');
    this.$.querySelector('#draw-actions').style.visibility = 'hidden';
    if (this.drawController?._map) this.mapLayer.removeControl(this.drawController);
    if (this.mapFeatureLayers.draw) this.mapFeatureLayers.draw.clearLayers();
    this.$.dispatchEvent(new CustomEvent('drawLayerRemoved', { bubbles: true, composed: true }));
  }

  setCurrentPoint(dataset) {
    this.currentPoint = dataset;
  }

  toggleNearBySearch(isClickIcon) {
    const cardGeoVehicle = document.querySelector('.card-geo-vehicle');

    if (
      !isClickIcon &&
      cardGeoVehicle.hasAttribute('active') &&
      cardGeoVehicle.getElementsByTagName('li').length > 0
    ) {
      this._searchNearBy(this.currentPoint.latitude, this.currentPoint.longitude);
    } else if (!cardGeoVehicle.hasAttribute('active')) {
      cardGeoVehicle.setAttribute('active', '');
      this._searchNearBy(this.currentPoint.latitude, this.currentPoint.longitude);
    } else {
      cardGeoVehicle.removeAttribute('active');
      this.context._nearbyVehicleList = [];
    }
  }
  // #endregion Public

  // #region Private
  _initializeMapDrawTool() {
    Object.assign(L.drawLocal, {
      draw: {
        toolbar: {
          actions: {
            text: 'Cancelar',
            title: 'Cancelar',
          },
          buttons: {
            marker: 'Inserir um Ponto',
            circlemarker: 'Inserir um Circulo',
            circle: 'Desenhar uma Area Circular',
            polygon: 'Desenhar uma Area',
            polyline: 'Desenhar um Caminho',
            rectangle: 'Desenhar um Retangulo',
          },
          finish: {
            text: 'Finalizar',
            title: 'Finalizar',
          },
          undo: {
            text: 'Desfazer ponto',
            title: 'Desfazer ponto',
          },
        },
        handlers: {
          marker: {
            tooltip: {
              start: 'Click no mapa para criar o Ponto',
            },
          },
          circlemarker: {
            tooltip: {
              start: 'Click no mapa para criar o Circulo',
            },
          },
          circle: {
            radius: 'Raio',
            tooltip: {
              start: 'Click e arraste para desenhar a Area Circular',
            },
          },
          polygon: {
            tooltip: {
              cont: 'Click para continua a desenhar',
              start: 'Click começar a desenhar',
              end: 'Click no primeiro ponto para finalizar',
            },
          },
          polyline: {
            error: 'Proibido criar lados cruzados',
            tooltip: {
              cont: 'Click para continua a desenhar',
              start: 'Click começar a desenhar',
              end: 'Click no ultimo ponto para finalizar',
            },
          },
          rectangle: {
            tooltip: {
              start: 'Click e arraste para desenhar o Retangulo',
            },
          },
          simpleshape: {
            tooltip: {
              end: 'Solte para finalizar',
            },
          },
        },
      },
      edit: {
        toolbar: {
          actions: {
            cancel: {
              text: 'Cancelar',
              title: 'Cancelar',
            },
            clearAll: {
              text: 'Limpar tudo',
              title: 'Limpar tudo',
            },
            save: {
              text: 'Salvar',
              title: 'Salvar',
            },
          },
          buttons: {
            edit: 'Editar',
            editDisabled: 'Nada para editar',
            remove: 'Remover',
            removeDisabled: 'Nada para remover',
          },
        },
        handlers: {
          edit: {
            tooltip: {
              text: 'Click em Cancelar para desfazer as alterações',
              subText: 'Para editar, arraste os pontos',
            },
          },
          remove: {
            tooltip: {
              text: 'Click em um objeto para remover',
            },
          },
        },
      },
    });
    this.mapFeatureLayers.draw = L.featureGroup();
    this.drawController = new L.Control.Draw({
      draw: {
        marker: true,
        circle: false,
        polygon: false,
        polyline: false,
        rectangle: false,
        circlemarker: false,
      },
      edit: {
        remove: false,
        featureGroup: this.mapFeatureLayers.draw,
        poly: {
          allowIntersection: false,
        },
      },
    });
    this.mapLayer.on(L.Draw.Event.DRAWSTART, () => {
      this.mapFeatureLayers.draw.clearLayers();
      this.$scope.$apply();
    });
    this.mapLayer.on(L.Draw.Event.CREATED, evt => {
      if (!evt.layer.options.editing)
        Object.assign(evt.layer.options, {
          editing: evt.layer.editing,
        });
      this.mapFeatureLayers.draw.addLayer(evt.layer);
    });
    this.mapLayer.on(L.Draw.Event.EDITSTART, () => {
      this.mapFeatureLayers.draw.getLayers().map(layer => {
        if (!layer.options.editing)
          Object.assign(layer.options, {
            editing: layer.editing,
          });
        return layer;
      });
    });
    this.mapLayer.addLayer(this.mapFeatureLayers.draw);
    this.mapLayer.addControl(this.drawController);
  }

  _geoJsonToLGeoJson({ geoJson }) {
    switch (geoJson.properties.categoryType) {
      case 1:
        geoJson.properties.color = '#315A94';
        break;
      case 2:
        geoJson.properties.color = '#8A4B08';
        break;
      case 3:
        geoJson.properties.color = '#EE1131';
        break;
      case 4:
        geoJson.properties.color = '#FFBF00';
        break;
      case 5:
        geoJson.properties.color = '#04B404';
        break;
      default:
        geoJson.properties.color = '#555555';
        break;
    }
    return L.geoJSON(geoJson, {
      onEachFeature: (feature, layer) => {
        layer.bindPopup(`
				  <div id="mapPopupHeader">
            <span>${feature.properties.name}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${feature.properties.description || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${feature.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${feature.properties.privacy || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(feature.properties.latitude).toFixed(6)} </span>
            <span> Lon: ${parseFloat(feature.properties.longitude).toFixed(6)} </span>
          </div>
			`);
      },
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon: this._createMarkerIcon(feature.properties.color),
        }).on('click', this._markerOnClick.bind(this)),
    }).getLayers()[0];
  }

  _markerOnClick(e) {
    this.setCurrentPoint({
      latitude: e.latlng.lat,
      longitude: e.latlng.lng,
    });
    this.toggleNearBySearch(false);
  }

  _createMarkerIcon(color) {
    return L.divIcon({
      className: 'leaflet-marker-icon',
      iconSize: [20, 20],
      html: `
        <div style="margin: -10px 0px 0px -10px; z-index: 0; position: absolute;">
          <div style="width:40px; height:40px;">
            <svg xmlns="https://www.w3.org/2000/svg" width="40" height="40">
              <path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"></path>
              <path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"></path>
              <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="${
                color || 'var(--primary-color)'
              }"></path></svg>
            </svg>
          </div>
        </div>
      `,
    });
  }

  _searchNearBy(lat, lng) {
    this.$http({
      url: `${this.urlApi}/Vehicle/GetVehiclesByCoordinatesInPointOfInterest`,
      method: 'POST',
      data: {
        latitude: lat,
        longitude: lng,
      },
    }).then(
      success => {
        this.context._nearbyVehicleList = success.data.data;
      },
      () => {
        this.context._nearbyVehicleList = [];
      },
    );
  }

  _showMarker(vehicle) {
    if (this._tempMarker) {
      this.mapLayer.removeLayer(this._tempMarker);
    }
    this._tempMarker = L.marker([vehicle.latitude, vehicle.longitude]);

    this._tempMarker.addTo(this.mapLayer);
    this.zoomTo(vehicle);
  }
  // #endregion Private

  // #region Observers
  // #endregion Observers
}

export { PowerMapPointsOfInterestLeafletProvider };
