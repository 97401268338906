import { app } from './app.module';

app
  .constant('version', 'process.app.version')
  .constant('mobile', false)
  .constant('urlApi', 'process.app.apiUrl')
  .constant('urlWebSocket', 'process.app.webSocketUrl')
  .constant('tipoApp', 19)
  .constant('playStore', 'process.app.playStore')
  .constant(
    'isProgressiveWebApp',
    window.sessionStorage.getItem('utmSource') === 'progressive-web-app',
  )
  .constant(
    'isTrustedWebActivity',
    window.sessionStorage.getItem('utmSource') === 'trusted-web-activity',
  )
  .constant('_months', [
    { id: 1, desc: 'Janeiro' },
    { id: 2, desc: 'Fevereiro' },
    { id: 3, desc: 'Março' },
    { id: 4, desc: 'Abril' },
    { id: 5, desc: 'Maio' },
    { id: 6, desc: 'Junho' },
    { id: 7, desc: 'Julho' },
    { id: 8, desc: 'Agosto' },
    { id: 9, desc: 'Setembro' },
    { id: 10, desc: 'Outubro' },
    { id: 11, desc: 'Novembro' },
    { id: 12, desc: 'Dezembro' },
  ])
  .constant('_today', new Date())
  .constant('_fullYear', new Date().getFullYear())
  .constant('_applications', [
    { id: 'GOLFLEET', name: 'Golfleet', displayName: 'Telemetria' },
    { id: 'TRACKING', name: 'Tracking', displayName: 'Rastreamento' },
  ]);
