import angular from 'angular';
import '@power/power-components/helpers/is-iframe/is-iframe';

import { GolfleetMapMotionLogHereProvider } from './providers/here/golfleet-map-motion-log.here-provider';
import { GolfleetMapMotionLogLeafletProvider } from './providers/leaflet/golfleet-map-motion-log.leaflet-provider';
import { PowerMapController } from '@power/power-components/components/power-map/power-map';

import template from './golfleet-map-motion-log.html';

class GolfleetMapMotionLogController extends PowerMapController {
  static get $inject() {
    return ['$element', '$ngRedux', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $ngRedux, $scope, $http, urlApi) {
    super($element, $ngRedux, $scope, $http, urlApi);

    if (this.providerName === 'HERE') {
      this.provider = new GolfleetMapMotionLogHereProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    } else {
      // this.providerName === 'LEAFLET'
      this.provider = new GolfleetMapMotionLogLeafletProvider(
        this,
        $element,
        $ngRedux,
        $scope,
        $http,
        urlApi,
      );
    }
  }

  /* Public */
  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
    icon,
  }) {
    return this.provider.renderDataset({
      dataset,
      layerName,
      type,
      useCluster,
      clusterColor,
      icon,
    });
  }
  /**/
}

class GolfleetMapMotionLog {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetMapMotionLogController;
  }
}

angular
  .module('golfleet-map-motion-log', ['power-map'])
  .component('golfleetMapMotionLog', new GolfleetMapMotionLog());
