import angular from 'angular';
import moment from 'moment';
import 'ng-redux';

import '../power-toast/power-toast';
import '../power-popup/power-popup';
import '../power-single-checkbox/power-single-checkbox';
import '../../directives/infinite-scroll/infinite-scroll';

import template from './power-photo-indexation.html';
import './power-photo-indexation.scss';

/** * FleetCamProvider Type (Enum) */
export const FleetCamProviderEnum = {
  R1: 1,
  V3: 2,
  R1_AND_V3: 3,
};

/**  * Identification Type (Enum)  */
export const IdentificationTypeEnum = {
  FACEID_IDENTIFICATION: 3,
  IDENTIFICATION_IMAGE_PENDING: 4,
};

export const IdentificationTypeIconEnum = {
  1: 'insert_link',
  2: 'gs_rfid',
  5: 'bluetooth',
  6: 'gs_ia_rec',
  7: 'gs_ia_rec',
};

class PowerPhotoIndexationController {
  static get $inject() {
    return ['$element', '$scope', '$ngRedux', '$http', '$state', 'urlApi'];
  }

  constructor($element, $scope, $ngRedux, $http, $state, urlApi) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $ngRedux,
      $http,
      $state,
      urlApi,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      return Object({
        /* State Storage */
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        /* Session Storage */
        session: behavior.session,
      });
    })(this);

    this.toastText = '';
    this.optionsList = [];
    this._optionsList = [];
    this.viewPhotoList = [];
    this.selectedItems = [];
    this.indexationList = [];
    this.photoBlur = '';
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getDataset: this.getDataset.bind(this),
      selectAllOrNone: this.selectAllOrNone.bind(this),
      toggleSelectedItems: this.toggleSelectedItems.bind(this),
      showIndexationOptions: this.showIndexationOptions.bind(this),
    });

    this._loadOptionsList();

    this.$scope.$watch(() => this.page, this.__onPageChanged.bind(this));
    this.$scope.$watch(() => this.pageSize, this.__onPageSizeChanged.bind(this));

    if (this.session.trainingMode) {
      this.photoBlur = 'blurred';
    }
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  /* Public */
  getDataset(dataset) {
    this.selectedItems = [];

    this.indexationList = dataset.map((data, index) => {
      const {
        idVeiculo,
        tipoIdentificacao,
        dataHora,
        dataHoraInicioViagem,
        dataHoraIgnicaoLigada,
        dataHoraFimViagem,
        dataHoraFimViagemRegra,
        condutor,
        placa,
        alias,
      } = data;

      const indexationItem = {
        data: {},
        params: {
          idVeiculo: idVeiculo || this.stateConfig.getDataFixedParams.id,
          tipoIdentificacao,
          dataHora,
          dataHoraInicioViagem,
          dataHoraIgnicaoLigada,
          dataHoraFimViagem,
          dataHoraFimViagemRegra,
        },
        placa,
        alias,
        condutor,
        selected: dataset.selected,
        ready: false,
        error: false,
      };

      if (index >= (this.page - 1) * this.pageSize && index < this.page * this.pageSize)
        this._requestIndexationItemData(indexationItem);

      return indexationItem;
    });
  }

  toggleSelectedItems() {
    this.indexationList = this.indexationList.map((item, index) =>
      Object.assign(item, {
        selected: (() => {
          if (!item.ready || item.error) return false;
          if (index < (this.page - 1) * this.pageSize || index >= this.page * this.pageSize)
            return item.selected;

          return !item.selected;
        })(),
      }),
    );
    this.selectedItems = this.indexationList.filter(item => item.selected);
  }

  selectAllOrNone() {
    const selectedItems = this.indexationList.filter(
      (item, index) =>
        index >= (this.page - 1) * this.pageSize &&
        index < this.page * this.pageSize &&
        (item.selected || !item.ready || item.error),
    );
    this.indexationList = this.indexationList.map((item, index) =>
      Object.assign(item, {
        selected: (() => {
          if (!item.ready || item.error) return false;
          if (index < (this.page - 1) * this.pageSize || index >= this.page * this.pageSize)
            return item.selected;

          return selectedItems.length !== this.pageSize;
        })(),
      }),
    );
    this.selectedItems = this.indexationList.filter(item => item.selected);
  }

  showIndexationOptions(overrideSelectedItems) {
    if (overrideSelectedItems) {
      this.indexationList = this.indexationList.map(item =>
        Object.assign(item, { selected: false }),
      );
      overrideSelectedItems.forEach(overrideItem => {
        this._toggleCardSelection(overrideItem);
      });
    }

    const preSelectedOptionId = this.selectedItems.reduce((acc, item, index) => {
      if (index === 0) return item.data.mrvTmtId || 0;
      if (item.data.mrvTmtId !== acc) return 0;
      return acc;
    }, 0);
    this.optionsList = this._optionsList.reduce(
      (acc, option) => {
        if (option.id === preSelectedOptionId) {
          acc.splice(1, 0, { ...option, selected: true });
          return acc;
        }
        return acc.concat({ ...option, selected: false });
      },
      [
        {
          id: 0,
          description: 'Não identificado',
          images: null,
          selected: preSelectedOptionId === 0,
        },
      ],
    );

    this._toggleIndexationOptions();
  }
  /* */

  /* Private */
  _requestIndexationItemData(indexationItem) {
    if (!this.session.isTrip || this.session.isSingleSignon) {
      indexationItem.error = true;
      indexationItem.ready = true;
      return;
    }

    if (!indexationItem.params.tipoIdentificacao) {
      indexationItem.error = true;
      indexationItem.ready = true;
      return;
    }

    if (
      indexationItem.params.tipoIdentificacao &&
      ![
        IdentificationTypeEnum.FACEID_IDENTIFICATION,
        IdentificationTypeEnum.IDENTIFICATION_IMAGE_PENDING,
      ].includes(indexationItem.params.tipoIdentificacao)
    ) {
      indexationItem.error = true;
      indexationItem.ready = true;
      indexationItem.showName = true;
      indexationItem.defaultIcon =
        IdentificationTypeIconEnum[indexationItem.params.tipoIdentificacao];
      return;
    }

    this.$http({
      url: `${this.urlApi}/FleetCam/GetDriversFace`,
      method: 'POST',
      data: {
        request: {
          vehicleId: indexationItem.params.idVeiculo,
          identificationType: indexationItem.params.tipoIdentificacao,
          beginDate: this._parseDate(
            new Date(
              indexationItem.params.dataHoraInicioViagem ||
                indexationItem.params.dataHoraIgnicaoLigada ||
                indexationItem.params.dataHora,
            ),
          ),
          endDate: this._parseDate(
            new Date(
              indexationItem.params.dataHoraFimViagem ||
                indexationItem.params.dataHoraFimViagemRegra ||
                indexationItem.params.dataHora,
            ),
          ),
        },
      },
    }).then(
      result => {
        indexationItem.data = { ...result.data.data[0] };
        indexationItem.ready = true;
      },
      () => {
        indexationItem.error = true;
        indexationItem.ready = true;
      },
    );
  }

  _toDate(date) {
    if (!date) return '- - -';
    return moment(date).utcOffset(-3 - moment(date).utcOffset() / 60)._d;
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _toggleCardSelection(indexationItem) {
    indexationItem.selected = !indexationItem.selected;
    this.selectedItems = this.indexationList.filter(item => item.selected);
  }

  _toggleViewPhotoPopup() {
    this.$.querySelector('#popup-indexation-view-photo').toggle();
  }

  _toggleIndexationOptions() {
    this.searchText = '';
    this.limitIndex = 0;
    this.$.querySelector('#popup-indexation-options').toggle();
  }

  _viewPhoto(photo) {
    this.viewPhotoList = [
      {
        url: photo.isUrl ? photo.result : `data:image/jpeg;base64,${photo.result}`,
        ready: true,
      },
    ];

    this._toggleViewPhotoPopup();
  }

  _viewOptionPhoto(optionItem) {
    this.viewPhotoList = [];

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    if (this.session.fleetCamProvider !== FleetCamProviderEnum.R1) this._getImagesV3(optionItem);
    else this._getImagesR1(optionItem);
  }

  _getImagesR1(optionItem) {
    const requestOptionPhotoKeys = optionId =>
      this.$http({
        url: `${this.urlApi}/FleetCam/GetDriverImageKeysR1`,
        method: 'POST',
        data: {
          request: {
            driverId: optionId,
            limit: 5,
          },
        },
      }).then(result => result.data.data.keys);
    const requestOptionPhoto = imageKey =>
      this.$http({
        url: `${this.urlApi}/FleetCam/GetDriverImageR1`,
        method: 'POST',
        data: {
          request: {
            key: imageKey,
          },
        },
      }).then(result => result.data.data.image);

    requestOptionPhotoKeys(optionItem.id)
      .then(keyList => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        if (keyList.length > 0) {
          keyList.forEach(key => {
            const photoItem = { url: '', ready: false };
            this.viewPhotoList = this.viewPhotoList.concat(photoItem);
            requestOptionPhoto(key).then(photoUrl => {
              photoItem.url = `data:image/jpeg;base64,${photoUrl}`;
              photoItem.ready = true;
            });
          });
          this._toggleViewPhotoPopup();
        } else {
          this.toastText = 'A opção não possui imagem para ser exibida';
          this.$.querySelector('#indexation-toast').toggle(true);
        }
      })
      .catch(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _getImagesV3(optionItem) {
    this.$http({
      url: `${this.urlApi}/FleetCam/GetDriverImages`,
      method: 'POST',
      data: {
        request: {
          driverUlid: optionItem.ulid,
          limit: 5,
        },
      },
    })
      .then(success => {
        const { data } = success.data;

        if (success.status && success.status !== 200) {
          this.$.querySelector('#indexation-toast').toggle(true);
          Object.assign(this, { toast: { text: data.text } });
          return;
        }

        if (data?.length > 0) {
          data.forEach(item => {
            this.viewPhotoList.push({
              url: item.image,
              ready: true,
            });
          });
          this._toggleViewPhotoPopup();
        } else {
          this.toastText = 'A opção não possui imagem para ser exibida';
          this.$.querySelector('#indexation-toast').toggle(true);
        }
      })
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  _loadOptionsList() {
    this.optionsList = [];
    this._optionsList = [];

    this.$http({
      url: `${this.urlApi}/Driver/GetNames`,
      method: 'POST',
    })
      .then(result => result.data.data)
      .then(optionsList => {
        this._optionsList = optionsList.map(option => ({
          id: option.id,
          description: option.description,
          ulid: option.extraId,
          images: [],
          selected: false,
        }));
      });
  }

  _selectOption(optionItem) {
    this.optionsList = this.optionsList.map(option => ({
      ...option,
      selected: option.id === optionItem.id,
    }));
  }

  _requestIndexation() {
    const optionSelected = this.optionsList.filter(item => item.selected)[0];

    if (!optionSelected && this.selectedItems.length === 0) return;

    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const selectedDriver = this.optionsList.filter(item => item.selected)[0];

    this.$http({
      url: `${this.urlApi}/FleetCam/IdentifyDrivers`,
      method: 'POST',
      data: {
        request: {
          driverId: selectedDriver.id,
          tmtUlid: selectedDriver.ulid,
          list: this.selectedItems.map(item => ({
            tmtId: item.data.mrvTmtId,
            tmsId: item.data.tmsId,
            mrvId: item.data.mrvId,
            mrvVeiId: item.data.mrvVeiId,
            mrvDataHoraExibicao: new Date(item.data.mrvDataHoraExibicao),
            evento: item.data.evento,
            imageName: item.data.driverFaces,
            bucket: item.data.bucketDriverFaces,
          })),
        },
      },
    }).then(
      () => {
        this.$.querySelector('#popup-indexation-options').toggle();
        this.updateFunction({ keepPage: true });
      },
      () => {
        this.$.querySelector('#popup-indexation-options').toggle();
        this.toastText = 'Não foi possivel fazer a identificação';
        this.$.querySelector('#indexation-toast').toggle(true);
      },
    );
  }

  _enableRequestIndexation() {
    const optionSelected = this.optionsList.filter(item => item.selected)[0];
    return !optionSelected;
  }
  /* */

  /* Observers */
  __onPageChanged(newValue) {
    if (!this.indexationList || this.indexationList.length === 0) return;
    for (let index = (newValue - 1) * this.pageSize; index < newValue * this.pageSize; index += 1) {
      if (index >= this.indexationList.length) break;
      if (!this.indexationList[index].ready)
        this._requestIndexationItemData(this.indexationList[index]);
    }
  }

  __onPageSizeChanged(newValue) {
    if (!this.indexationList || this.indexationList.length === 0) return;
    for (let index = (this.page - 1) * newValue; index < this.page * newValue; index += 1) {
      if (index >= this.indexationList.length) break;
      if (!this.indexationList[index].ready)
        this._requestIndexationItemData(this.indexationList[index]);
    }
  }

  _getImageUrl(data) {
    return data.isUrl ? `url(${data.result})` : `url(data:image/jpeg;base64,${data.result})`;
  }
  /* */
}

class PowerPhotoIndexation {
  constructor() {
    this.template = template;
    this.bindings = {
      page: '=',
      pageSize: '=',
      selectedItems: '=?',
      updateFunction: '&',
    };
    this.controller = PowerPhotoIndexationController;
  }
}

angular
  .module('power-photo-indexation', [
    'ngRedux',
    'power-toast',
    'power-popup',
    'infinite-scroll',
    'power-single-checkbox',
  ])
  .component('powerPhotoIndexation', new PowerPhotoIndexation());
