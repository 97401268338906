import angular from 'angular';
import 'jquery';

import 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import template from './golfleet-support-slider.html';
import './golfleet-support-slider.scss';

class GolfleetSupportSliderController {
  static get $inject() {
    return ['$element', '$timeout', 'commonServices'];
  }

  constructor($element, $timeout, commonServices) {
    Object.assign(this, { $: $element[0], $timeout, commonServices });

    this.slider = document.querySelector('.slider');
    this.slides = [...this.slider.children];
    this.slides[0].setAttribute('active', '');
  }

  /* Lifecycle */
  $onInit() {}
  /* */

  next() {
    const activeIndex = this.slides.indexOf(this.slider.querySelector('[active]'));
    this.$.querySelectorAll('[active]').forEach(slide => slide.removeAttribute('active'));

    if (activeIndex + 1 !== this.slides.length) {
      this.slides[activeIndex + 1].setAttribute('active', '');
    } else {
      this.slides[0].setAttribute('active', '');
    }
  }

  previous() {
    const activeIndex = this.slides.indexOf(this.slider.querySelector('[active]'));
    this.$.querySelectorAll('[active]').forEach(slide => slide.removeAttribute('active'));

    if (activeIndex !== 0) {
      this.slides[activeIndex - 1].setAttribute('active', '');
    } else {
      this.slides[this.slides.length - 1].setAttribute('active', '');
    }
  }
}

class GolfleetSupportSlider {
  constructor() {
    this.template = template;
    this.bindings = {
      configData: '=',
    };
    this.controller = GolfleetSupportSliderController;
  }
}

angular
  .module('golfleet-support-slider', [])
  .component('golfleetSupportSlider', new GolfleetSupportSlider());
