/* global H: true */
import { PowerMapHereProvider } from '@power/power-components/components/power-map/providers/here/power-map.here-provider';
import './power-map-geofences.here-provider.scss';

class PowerMapGeofencesHereProvider extends PowerMapHereProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);

    this.context.geofencesController = {
      callback: null,
      type: 'polygon',
      positionList: [],
      measureDistance: (position1, position2) => {
        const earthRadius = 6378e3;
        if (!position1 || !position1) {
          return 0;
        }
        const φ1 = (position1.lat * Math.PI) / 180;
        const φ2 = (position2.lat * Math.PI) / 180;
        const λ1 = (position1.lng * Math.PI) / 180;
        const λ2 = (position2.lng * Math.PI) / 180;
        const Δφ = φ2 - φ1;
        const Δλ = λ2 - λ1;
        const a =
          Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadius * c;
      },
      measurePosition: (position, distance) => {
        const earthRadius = 6378e3;
        const φ1 = (position.lat * Math.PI) / 180;
        const λ1 = (position.lng * Math.PI) / 180;
        const initialBearing = this.context.geofencesController.measureBearing(position, {
          lat: position.lat,
          lng: position.lng + 0.001,
        });
        const φ2 = Math.asin(
          Math.sin(φ1) * Math.cos(distance / earthRadius) +
            Math.cos(φ1) * Math.sin(distance / earthRadius) * Math.cos(initialBearing),
        );
        const λ2 =
          λ1 +
          Math.atan2(
            Math.sin(initialBearing) * Math.sin(distance / earthRadius) * Math.cos(φ1),
            Math.cos(distance / earthRadius) - Math.sin(φ1) * Math.sin(φ2),
          );
        return { lat: (φ2 * 180) / Math.PI, lng: (λ2 * 180) / Math.PI };
      },
      measureBearing: (position1, position2) => {
        const φ1 = (position1.lat * Math.PI) / 180;
        const φ2 = (position2.lat * Math.PI) / 180;
        const λ1 = (position1.lng * Math.PI) / 180;
        const λ2 = (position2.lng * Math.PI) / 180;
        const Δλ = λ2 - λ1;
        return Math.atan2(
          Math.sin(Δλ) * Math.cos(φ2),
          Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ),
        );
      },
      renderCircleDrawDataset: () => {
        const [center, radius] = this.context.geofencesController.positionList;
        const circleDataset = [
          {
            geoJson: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [center.lng, center.lat],
              },
              properties: {
                radius: this.context.geofencesController.measureDistance(center, radius),
                latitude: center.lat,
                longitude: center.lng,
                isDrawPoint: true,
              },
            },
          },
        ];
        const markerDataset = [
          {
            geoJson: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [center.lng, center.lat],
              },
              properties: {
                latitude: center.lat,
                longitude: center.lng,
                isDrawPoint: true,
              },
            },
          },
          {
            geoJson: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [radius.lng, radius.lat],
              },
              properties: {
                latitude: radius.lat,
                longitude: radius.lng,
                isDrawPoint: true,
                isDrawRadius: true,
              },
            },
          },
        ];

        this.renderDataset({ dataset: circleDataset, layerName: 'drawCircle' });
        this.renderDataset({ dataset: markerDataset, layerName: 'drawMarker' });
      },
      renderPolygonDrawDataset: () => {
        const { markerDataset, linestringDataset } =
          this.context.geofencesController.positionList.reduce(
            (acc, position, index, arr) => {
              if (index > 0) {
                const previousPosition = arr[index - 1];
                acc.linestringDataset = [
                  ...acc.linestringDataset,
                  {
                    geoJson: {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: [
                          [previousPosition.lng, previousPosition.lat],
                          [position.lng, position.lat],
                        ],
                      },
                      properties: {},
                    },
                  },
                ];
              }
              if (arr.length > 2 && index === arr.length - 1) {
                const firstPosition = arr[0];
                acc.linestringDataset = [
                  ...acc.linestringDataset,
                  {
                    geoJson: {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: [
                          [position.lng, position.lat],
                          [firstPosition.lng, firstPosition.lat],
                        ],
                      },
                      properties: {
                        dashed: true,
                      },
                    },
                  },
                ];
              }

              acc.markerDataset = [
                ...acc.markerDataset,
                {
                  geoJson: {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: [position.lng, position.lat],
                    },
                    properties: {
                      latitude: position.lat,
                      longitude: position.lng,
                      isDrawPoint: true,
                      drawIndex: index,
                    },
                  },
                },
              ];

              return acc;
            },
            { markerDataset: [], linestringDataset: [] },
          );

        this.renderDataset({ dataset: markerDataset, layerName: 'drawMarker' });
        this.renderDataset({ dataset: linestringDataset, layerName: 'drawLinestrings' });
      },
      // Tap Event
      tapEventListener: evt => {
        if (this.context.geofencesController.type === 'circle')
          this.context.geofencesController.circleTapHandler(evt);
        else if (this.context.geofencesController.type === 'polygon')
          this.context.geofencesController.polygonTapHandler(evt);
      },
      circleTapHandler: evt => {
        const position = this.map.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY,
        );
        this.context.geofencesController.positionList = [
          position,
          this.context.geofencesController.measurePosition(position, 150),
        ];

        this.context.geofencesController.renderCircleDrawDataset();
      },
      polygonTapHandler: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset.isDrawPoint)
          this.context.geofencesController.positionList.splice(targetDataset.drawIndex, 1);
        else
          this.context.geofencesController.positionList =
            this.context.geofencesController.positionList.concat(
              this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY),
            );

        this.context.geofencesController.renderPolygonDrawDataset();
      },
      // Drag Event
      dragEventListener: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset.isDrawPoint) {
          if (this.context.geofencesController.type === 'circle')
            this.context.geofencesController.circleDragHandler(evt, targetDataset);
          else if (this.context.geofencesController.type === 'polygon')
            this.context.geofencesController.polygonDragHandler(evt, targetDataset);
        }
      },
      circleDragHandler: (evt, targetDataset) => {
        if (targetDataset.isDrawPoint) {
          const [center, radius] = this.getLayer('drawMarker').getObjects();
          const [circle] = this.getLayer('drawCircle').getObjects();
          const position = this.map.screenToGeo(
            evt.currentPointer.viewportX,
            evt.currentPointer.viewportY,
          );

          if (targetDataset.isDrawRadius) {
            const distance = this.context.geofencesController.measureDistance(
              center.getPosition(),
              position,
            );
            if (distance >= 150) {
              radius.setPosition(position);
              circle.setRadius(distance);
              this.context.geofencesController.positionList = [
                center.getPosition(),
                radius.getPosition(),
              ];
            }
          } else {
            radius.setPosition({
              lat: position.lat + (radius.getPosition().lat - center.getPosition().lat),
              lng: position.lng + (radius.getPosition().lng - center.getPosition().lng),
            });
            center.setPosition(position);
            circle.setCenter(position);
            this.context.geofencesController.positionList = [
              center.getPosition(),
              radius.getPosition(),
            ];
          }
        }
      },
      polygonDragHandler: (evt, targetDataset) => {
        const position = this.map.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY,
        );

        if (targetDataset.isDrawPoint) {
          evt.target.setPosition(position);
          this.context.geofencesController.positionList.splice(
            targetDataset.drawIndex,
            1,
            position,
          );
        }
      },
      // Drag Start Event
      dragStartEventListener: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset.isDrawPoint) this.behavior.disable();

        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grabbing';
        }
      },
      // Drag End Event
      dragEndEventListener: evt => {
        const targetDataset = 'getData' in evt.target ? evt.target.getData() : {};

        if (targetDataset.isDrawPoint) {
          this.behavior.enable();
          if (this.context.geofencesController.type === 'circle')
            this.context.geofencesController.renderCircleDrawDataset();
          else if (this.context.geofencesController.type === 'polygon')
            this.context.geofencesController.renderPolygonDrawDataset();
        }

        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grab';
        }
      },
      // Pointer Move Event
      pointerEnterEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'grab';
        }
      },
      pointerLeaveEventListener: evt => {
        if (evt.target && 'getElement' in evt.target) {
          evt.target.getElement().style.cursor = 'pointer';
        }
      },
    };
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      startDraw: this.startDraw.bind(this),
      endDraw: this.endDraw.bind(this),
      cancelDraw: this.cancelDraw.bind(this),
    });

    super.$onInit();
  }
  /**/

  /* Public */
  startDraw({ callback, dataset }) {
    this.$.setAttribute('draw', '');
    this.context.geofencesController.callback = callback;
    this.map.addEventListener('tap', this.context.geofencesController.tapEventListener, true);
    this.map.addEventListener('drag', this.context.geofencesController.dragEventListener, true);
    this.map.addEventListener(
      'dragstart',
      this.context.geofencesController.dragStartEventListener,
      true,
    );
    this.map.addEventListener(
      'dragend',
      this.context.geofencesController.dragEndEventListener,
      true,
    );

    this.map.addEventListener(
      'pointermove',
      this.context.geofencesController.pointerEnterEventListener,
      true,
    );
    this.map.addEventListener(
      'pointerleave',
      this.context.geofencesController.pointerLeaveEventListener,
      true,
    );

    if (dataset && dataset.geoJson) {
      if (dataset.radius) {
        const center = { lat: dataset.latitude, lng: dataset.longitude };
        this.selectDrawShape('circle');
        this.context.geofencesController.positionList = [
          center,
          this.context.geofencesController.measurePosition(center, dataset.radius),
        ];
        this.context.geofencesController.renderCircleDrawDataset();
      } else {
        const [coordinates] = dataset.geoJson.coordinates;
        this.selectDrawShape('polygon');
        this.context.geofencesController.positionList = coordinates.reduce(
          (acc, item, index, arr) => {
            if (index === arr.length - 1) return acc;
            return acc.concat({ lat: item[1], lng: item[0] });
          },
          [],
        );
        this.context.geofencesController.renderPolygonDrawDataset();
      }

      const zoomEditItem = () => {
        const { bbox } = dataset.geoJson;
        this.fitBounds(new H.geo.Rect(bbox[1], bbox[0], bbox[3], bbox[2]));
      };

      if (!this.mapLayers.geofences) {
        this.awaitRender().then(() => {
          setTimeout(() => {
            zoomEditItem();
          }, 50);
        });
        return;
      }

      zoomEditItem();
    }
  }

  endDraw() {
    if (this.context.geofencesController.callback) {
      this.context.geofencesController.callback(
        this.context.geofencesController.type === 'polygon'
          ? {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [
                  this.context.geofencesController.positionList.reduce(
                    (acc, position, index, arr) => {
                      acc = acc.concat([[position.lng, position.lat]]);

                      if (index === arr.length - 1) acc = acc.concat([[arr[0].lng, arr[0].lat]]);

                      return acc;
                    },
                    [],
                  ),
                ],
              },
              properties: {},
            }
          : {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [
                  this.context.geofencesController.positionList[0]
                    ? this.context.geofencesController.positionList[0].lng
                    : null,
                  this.context.geofencesController.positionList[0]
                    ? this.context.geofencesController.positionList[0].lat
                    : null,
                ],
              },
              properties: {
                radius: this.context.geofencesController.measureDistance(
                  this.context.geofencesController.positionList[0],
                  this.context.geofencesController.positionList[1],
                ),
              },
            },
      );
      this.context.geofencesController.callback = null;
    }
    this.cancelDraw();
  }

  cancelDraw() {
    this.$.removeAttribute('draw');
    this.map.removeEventListener('tap', this.context.geofencesController.tapEventListener, true);
    this.map.removeEventListener('drag', this.context.geofencesController.dragEventListener, true);
    this.map.removeEventListener(
      'dragstart',
      this.context.geofencesController.dragStartEventListener,
      true,
    );
    this.map.removeEventListener(
      'dragend',
      this.context.geofencesController.dragEndEventListener,
      true,
    );

    this.context.geofencesController.positionList = [];
    this.removeLayers(['drawCircle', 'drawMarker', 'drawLinestrings']);
    this.$.dispatchEvent(new CustomEvent('drawLayerRemoved', { bubbles: true, composed: true }));
  }

  selectDrawShape(shape) {
    this.context.geofencesController.type = shape;
    this.context.geofencesController.positionList = [];
    this.removeLayers(['drawCircle', 'drawMarker', 'drawLinestrings']);
  }
  /**/

  /* Private */
  _initializeMap() {
    super._initializeMap();
    this.ui.removeControl('distancemeasurement');
  }

  _geoJsonToMarker({ geoJson }) {
    const [lng, lat] = geoJson.geometry.coordinates;
    const marker = new H.map.DomMarker({ lat, lng }, { data: { ...geoJson.properties } });
    marker.draggable = true;
    return marker;
  }

  _geoJsonToCircle({ geoJson }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.Circle({ lat, lng }, geoJson.properties.radius, {
      data: {
        ...geoJson.properties,
        bubbleContent: geoJson.properties.isDrawPoint
          ? ''
          : `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
      },
      style: {
        fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
        strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
      },
    });
  }

  _geoJsonToPolygon({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Polygon(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToMultiPolygon({ geoJson }) {
    const { coordinates } = geoJson.geometry;

    return new H.map.Polygon(
      coordinates.reduce((acc, [polygonArray]) => {
        acc.push(
          new H.geo.Polygon(
            polygonArray.reduce((lineStringAcc, latLngArray) => {
              const [lng, lat] = latLngArray;
              lineStringAcc.pushLatLngAlt(lat, lng);
              return lineStringAcc;
            }, new H.geo.LineString()),
          ),
        );
        return acc;
      }, new H.geo.MultiPolygon([])),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
          <div id="mapPopupHeader">
            <span>${geoJson.properties.description}</span>
          </div>
          <div id="mapPopupBody">
            <div>
              <b>Descrição:</b>
              <br>
              <span>${geoJson.properties.extendedDescription || '---'}</span>
            </div>
            <div>
              <b>Categoria:</b>
              <br>
              <span>${geoJson.properties.categoryName || '---'}</span>
            </div>
            <div>
              <b>Privacidade:</b>
              <br>
              <span>${geoJson.properties.privacy || '---'}</span>
            </div>
            <div>
              <b>Status:</b>
              <br>
              <span>${geoJson.properties.providerStatusDesc || '---'}</span>
            </div>
          </div>
          <div id="mapPopupFooter">
            <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
            <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
          </div>
        `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }

  _geoJsonToPolyline({ geoJson }) {
    return new H.map.Polyline(
      geoJson.geometry.coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            ${
              geoJson.properties.latitude && geoJson.properties.longitude
                ? `
                <div id="mapPopupFooter">
                  <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
                  <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
                </div>
                `
                : ''
            }
          `,
        },
        style: {
          lineWidth: 6,
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
          lineDash: geoJson.properties.dashed ? [1, 7, 1] : [],
          lineDashOffset: geoJson.properties.dashed ? 1 : 0,
        },
      },
    );
  }

  _geoJsonToRectangle({ geoJson }) {
    const [coordinates] = geoJson.geometry.coordinates;
    return new H.map.Rect(
      coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: geoJson.properties.isDrawPoint
            ? ''
            : `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.description}</span>
            </div>
            <div id="mapPopupBody">
              <div>
                <b>Descrição:</b>
                <br>
                <span>${geoJson.properties.extendedDescription || '---'}</span>
              </div>
              <div>
                <b>Categoria:</b>
                <br>
                <span>${geoJson.properties.categoryName || '---'}</span>
              </div>
              <div>
                <b>Privacidade:</b>
                <br>
                <span>${geoJson.properties.privacy || '---'}</span>
              </div>
              <div>
                <b>Status:</b>
                <br>
                <span>${geoJson.properties.providerStatusDesc || '---'}</span>
              </div>
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${parseFloat(geoJson.properties.latitude).toFixed(4)} </span>
              <span> Lon: ${parseFloat(geoJson.properties.longitude).toFixed(4)} </span>
            </div>
          `,
        },
        style: {
          fillColor: this._hexToRgba(geoJson.properties.color, 0.33),
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }
  /* */

  /* Observers */
  /**/
}

export { PowerMapGeofencesHereProvider };
