/* eslint-disable no-restricted-syntax */
/* global L: true isIframe: true */
import * as Comlink from 'comlink';
import '../../../../helpers/is-iframe/is-iframe'
import './power-map.leaflet-provider.scss';

class PowerMapLeafletProvider {
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    Object.assign(this, { context, $: $element[0], $ngRedux, $scope, $http, urlApi });

    this.mapLayer = null;
    this.mapFeatureLayers = { default: null };
    this.mapHeatLayerOptions = {
      minOpacity: 0.3,
      gradient: {
        0.15: '#0000FF',
        0.3: '#00FFFF',
        0.45: '#00FF00',
        0.6: '#FFFF00',
        0.75: '#FF0000',
      },
    };

    this.worker = new Worker('./power-map.leaflet-provider.worker.js');
    this.workerServices = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    this.mapLayer = this._initializeMap();

    this.mapLayer.on('enterFullscreen', this.enterFullscreen.bind(this));
    this.mapLayer.on('exitFullscreen', this.exitFullscreen.bind(this));

    setTimeout(() => {
      this.resizeMap();
      this.$.dispatchEvent(
        new CustomEvent('mapLoaded', { detail: {}, bubbles: true, composed: true }),
      );
    }, 500);
  }

  $onDestroy() {
    this.worker.terminate();
  }
  /* */

  /* Public */
  async awaitRender() {
    return new Promise(resolve => {
      const resizeInterval = setInterval(() => {
        if (this.$.offsetWidth > 0) {
          clearInterval(resizeInterval);
          resolve(true);
        }
      });
    });
  }

  requestGeocoding(address) {
    this.$http({
      url: `${this.urlApi}/GeoCoding/Post`,
      method: 'POST',
      data: {
        request: { street: address },
      },
    }).then(
      success => {
        if (success.status && success.status !== 200) return;
        if (success.data.data)
          this.mapLayer.setView([success.data.data.x, success.data.data.y], 18, { animate: true });
      },
      () => {},
    );
  }

  async renderDataset({
    dataset,
    layerName = 'default',
    type = 'FeatureGroup',
    useCluster,
    clusterColor = '#D60F2C',
  }) {
    const objectList = await this._createDatasetLayer({ type, dataset });

    if (this.mapFeatureLayers[layerName]) {
      this.mapLayer.removeLayer(this.mapFeatureLayers[layerName]);
    }

    if (useCluster) {
      this.mapFeatureLayers[layerName] = this._createCluster({ clusterColor }).addLayer(objectList);
    } else {
      this.mapFeatureLayers[layerName] = objectList;
    }

    this.mapLayer.addLayer(this.mapFeatureLayers[layerName]);
  }

  removeLayers(layerNameList = ['default']) {
    layerNameList.map(layerName => {
      if (this.mapFeatureLayers[layerName])
        this.mapLayer.removeLayer(this.mapFeatureLayers[layerName]);

      return this.mapLayer;
    });
  }

  resizeMap() {
    if (this.mapLayer._container.offsetWidth > 0) this.mapLayer.invalidateSize();
  }

  async zoomTo(data, zoom = 18) {
    const [geoJson] = await this.workerServices.parseToCircleDataset({ dataset: [data] });

    if (geoJson.geometry.coordinates.every(coordinate => !!coordinate)) {
      this.mapLayer.setView(geoJson.geometry.coordinates.reverse(), zoom, { animate: true });
    }
  }

  fitBounds(bounds) {
    this.mapLayer.fitBounds(bounds);
  }

  fitLayers(layerNameList = ['default'], heatLayerName) {
    if (heatLayerName && this.mapFeatureLayers[heatLayerName]) {
      this.mapLayer.fitBounds(this.mapFeatureLayers[heatLayerName]._latlngs);
    }

    if (layerNameList.length > 0) {
      const bounds = L.featureGroup(
        layerNameList
          .filter(name => this.mapFeatureLayers[name])
          .map(name => this.mapFeatureLayers[name]),
      ).getBounds();

      if (Object.values(bounds)[0]) {
        this.mapLayer.fitBounds(bounds);
      }
    }
  }

  getBounds(layerNameList, heatLayerName) {
    const getBoundsFunctions = bounds => ({
      asArray: () => [
        bounds._southWest.lng,
        bounds._southWest.lat,
        bounds._northEast.lng,
        bounds._northEast.lat,
      ],
      containsRect: rect =>
        bounds._northEast.lat >= rect._northEast.lat &&
        bounds._northEast.lng >= rect._northEast.lng &&
        bounds._southWest.lat <= rect._southWest.lat &&
        bounds._southWest.lng <= rect._southWest.lng,
    });

    if (!layerNameList) {
      if (heatLayerName && heatLayerName in this.mapFeatureLayers) {
        const heatLayerBounds = this.mapFeatureLayers[heatLayerName]._latlngs;
        return Object.assign(heatLayerBounds, { ...getBoundsFunctions(heatLayerBounds) });
      }

      const bounds = this.mapLayer.getBounds();
      return Object.assign(bounds, { ...getBoundsFunctions(bounds) });
    }

    const bounds = L.featureGroup(
      layerNameList.map(name => this.mapFeatureLayers[name]),
    ).getBounds();

    return Object.assign(bounds, { ...getBoundsFunctions(bounds) });
  }

  sortDatasetByBounds(dataset) {
    return [...dataset].sort((a, b) => {
      const aBound = L.bounds(a.geoJson.bbox.slice(0, 2), a.geoJson.bbox.slice(2, 4));
      const bBound = L.bounds(b.geoJson.bbox.slice(0, 2), b.geoJson.bbox.slice(2, 4));
      if (aBound.intersects(bBound)) {
        return aBound.contains(bBound) ? 1 : -1;
      }
      return 0;
    });
  }

  getMap() {
    return this.mapLayer;
  }

  getLayer(layerName = 'default') {
    return this.mapFeatureLayers[layerName];
  }

  getDataFromLayer(layerName = 'default', filter) {
    return this.mapFeatureLayers[layerName].getLayers().filter(filter);
  }

  openPopup(index = 0, layer = 'default') {
    if (layer in this.mapFeatureLayers && 'getLayers' in this.mapFeatureLayers[layer]) {
      setTimeout(() => {
        this.mapFeatureLayers[layer].getLayers()[index].openPopup();
      }, 700);
    }
  }

  enterFullscreen() {
    this.$.setAttribute('fullscreen', '');
    this.mapLayer.fullscreenControl._container.querySelector(
      '.leaflet-control-zoom-fullscreen i',
    ).innerHTML = 'fullscreen_exit';
  }

  exitFullscreen() {
    this.$.removeAttribute('fullscreen');
    this.mapLayer.fullscreenControl._container.querySelector(
      '.leaflet-control-zoom-fullscreen i',
    ).innerHTML = 'fullscreen';
  }
  /* */

  /* Private */
  _initializeMap(params = {}) {
    const { mapConfigs } = this.context;

    const map = L.map('map', {
      center: [-15.34, -53.74],
      zoom: 4,
      minZoom: 3,
      maxZoom: 18,
      maxBounds: [
        [90, -180],
        [-90, 180],
      ],
      dragging: true,
      fadeAnimation: true,
      fullscreenControl: !isIframe(),
      attributionControl: false,
      fullscreenControlOptions: {
        position: 'topleft',
        title: 'Mapa em fullscreen',
        titleCancel: 'Sair do mapa em fullscreen',
        content: '<i class="material-icons">fullscreen</i>',
      },
      layers: [L.tileLayer(mapConfigs.leaflet.tileUrl)],
      ...params,
    });

    map.onMoveEnd = func => {
      map.on('zoomend', () => func());
      map.on('dragend', () => func());
    };

    return map;
  }

  async _createDatasetLayer({ type, dataset }) {
    let circleDataset = [];
    let geoJsonDataset = [];
    let datasetLayer = [];

    switch (type) {
      case 'Cluster':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(geoJson => this._geoJsonToMarker({ geoJson })),
        );
        break;
      case 'HeatLayer':
        datasetLayer = L.heatLayer(
          this._geoJsonToHeat({
            geoJsonList: await this.workerServices.parseToGeoJsonDataset({ dataset }),
          }),
          this.mapHeatLayerOptions,
        );
        break;
      case 'MarkerFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToMarker({ geoJson: data })),
        );
        break;
      case 'CircleFeatureGroup':
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = L.featureGroup(
          circleDataset.map(data => this._geoJsonToCircle({ geoJson: data })),
        );
        break;
      case 'PolygonFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToPolygon({ geoJson: data })),
        );
        break;
      case 'LinestringFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToPolyline({ geoJson: data })),
        );
        break;
      case 'RectangleFeatureGroup':
        geoJsonDataset = await this.workerServices.parseToGeoJsonDataset({ dataset });
        datasetLayer = L.featureGroup(
          geoJsonDataset.map(data => this._geoJsonToRectangle({ geoJson: data })),
        );
        break;
      case 'FeatureGroup':
      default:
        circleDataset = await this.workerServices.parseToCircleDataset({ dataset });
        datasetLayer = L.featureGroup(
          circleDataset.map(data => this._geoJsonToLGeoJson({ geoJson: data })),
        );
        break;
    }

    return datasetLayer;
  }

  _createCluster({ clusterColor }) {
    const hexToRgb = hex => {
      const curry =
        fn =>
        (...args) =>
          fn.bind(null, ...args);
      const compose =
        (...fns) =>
        x =>
          fns.reduceRight((v, f) => f(v), x);
      const exec = curry((regex, str) => regex.exec(str));
      const replace = curry((param, fn, str) => str.replace(param, fn));
      const sumMrgb = (m, r, g, b) => r + r + g + g + b + b;
      const rgbArrToRgbString = rgbArr =>
        rgbArr
          ? `${parseInt(rgbArr[1], 16)}, ` +
            `${parseInt(rgbArr[2], 16)}, ` +
            `${parseInt(rgbArr[3], 16)}`
          : '0, 0, 0';
      const execRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
      const replaceRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      const composeHexToRgb = compose(
        rgbArrToRgbString,
        exec(execRegex),
        replace(replaceRegex, sumMrgb),
      );
      return composeHexToRgb(hex);
    };
    const clustterSize = cluster => {
      if (cluster.getChildCount() <= 10) return 'power-small-cluster-icon';

      if (cluster.getChildCount() <= 100) return 'power-medium-cluster-icon';

      return 'power-large-cluster-icon';
    };
    return L.markerClusterGroup({
      removeOutsideVisibleBounds: false,
      iconCreateFunction: cluster =>
        L.divIcon({
          className: 'power-cluster-icon',
          iconSize: null,
          iconAnchor: [26, 26],
          html: `<div
                class="${clustterSize(cluster)}"
                style="border: 5px solid rgba(${hexToRgb(clusterColor)}, .2)"
              >
								<div style="border: 5px solid rgba(${hexToRgb(clusterColor)}, .5)">
									<div style="background-color: rgb(${hexToRgb(clusterColor)})">
										<span>${cluster.getChildCount()}</span>
									</div>
								</div>
							</div>`,
        }),
    });
  }

  _createMarkerIcon({ color, icon, label }) {
    const createIcon = iconString => {
      if (!iconString) return '';
      return `
        <foreignObject x="8" y="7" width="24" height="24">
          <i class="material-icons"
            style="color: #fff;
            cursor: pointer;">
            ${iconString}
          </i>
        </foreignObject>
      `;
    };

    if (label) {
      return L.divIcon({
        className: 'leaflet-marker-icon',
        iconSize: [20, 20],
        html: `
          <div style="margin: -10px 0px 0px -10px; z-index: 0; position: absolute;" ${
            label ? `data-label="${label}"` : ''
          }">
            <div style="width:40px; height:40px;">
              <svg xmlns="https://www.w3.org/2000/svg"
                width="40"
                height="40"
              >
                <circle cx="20"
                  cy="20"
                  r="16"
                  fill="${color || 'var(--primary-color)'}">
                </circle>
                ${createIcon(icon)}
              </svg>
            </div>
          </div>
        `,
      });
    }

    return L.divIcon({
      className: 'leaflet-marker-icon',
      iconSize: [20, 20],
      html: `
        <div style="margin: -10px 0px 0px -10px; z-index: 0; position: absolute;">
          <div style="width:40px; height:40px;">
            <svg xmlns="https://www.w3.org/2000/svg"
              width="40"
              height="40"
            >
              <circle cx="20"
                cy="20"
                r="16"
                fill="${color || 'var(--primary-color)'}">
              </circle>
              ${createIcon(icon)}
            </svg>
          </div>
        </div>
      `,
    });
  }

  _geoJsonToMarker({ geoJson, icon }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) =>
        L.marker(latlng, {
          ...feature.properties,
          icon: icon || this._createMarkerIcon(feature.properties),
        }),
    }).getLayers()[0];
  }

  _geoJsonToHeat({ geoJsonList }) {
    return geoJsonList.map(item => Object.assign([], item.geometry.coordinates).reverse());
  }

  _geoJsonToCircle({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) => L.circle(latlng, feature.properties),
    }).getLayers()[0];
  }

  _geoJsonToPolygon({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) => L.polygon(latlng, feature.properties),
    }).getLayers()[0];
  }

  _geoJsonToPolyline({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) => L.polyline(latlng, feature.properties),
      ...geoJson.properties,
    }).getLayers()[0];
  }

  _geoJsonToRectangle({ geoJson }) {
    return L.geoJSON(geoJson, {
      pointToLayer: (feature, latlng) => L.rectangle(latlng, feature.properties),
    }).getLayers()[0];
  }

  _geoJsonToLGeoJson({ geoJson, markerIcon }) {
    return L.geoJSON(geoJson, {
      style: { color: geoJson.properties.color },
      pointToLayer: (feature, latlng) => {
        switch (feature.type) {
          case 'Polygon':
            return L.polygon(latlng, feature.properties);
          case 'Polyline':
            return L.polyline(latlng, feature.properties);
          case 'Rectangle':
            return L.rectangle(latlng, feature.properties);
          default:
            return feature.properties.radius
              ? L.circle(latlng, feature.properties)
              : L.marker(latlng, {
                  ...feature.properties,
                  icon: markerIcon || this._createMarkerIcon(feature.properties),
                });
        }
      },
    }).getLayers()[0];
  }
  /* */

  /* Observers */
  /* */
}

export { PowerMapLeafletProvider };
