import angular from 'angular';
import * as Comlink from 'comlink';

import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';

import template from './golfleet-grid-tco-toll.html';
import './golfleet-grid-tco-toll.scss';

class GolfleetGridTcoTollController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);

    Object.assign(this, { $ngRedux });

    this.pageRows = [];
    this.selectedRows = [];
    this.isAllSelected = false;

    if (this.worker) this.worker.terminate();

    this.worker = new Worker('./golfleet-grid-tco-toll.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);
  }

  /* Lifecycle */
  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /**/

  _customAction(options) {
    const defaults = { action: '', data: {} };
    const result = Object.assign(defaults, options);

    const customAction = this.customActionsController[result.action];
    if (customAction) {
      customAction(result.data);
    }
  }

  _selectRow(column, row) {
    if (column.action && row.lancamentoManual) return true && !!row[column.field];
    if (!column.link || (!row.lancamentoManual && column.field != 'placa')) return false;
    if (!column.linkValues || column.linkValues.length === 0) return true;

    return (
      column.linkValues.filter(ele => {
        const eleValue = ele.value || ele;
        const rowValue = ele.column ? row[ele.column] : row[column.field];
        return rowValue == eleValue || (eleValue == 'notNull' && !!rowValue);
      }).length > 0 && !!row[column.field]
    );
  }

  _hasRowSelection(row) {
    return row.lancamentoManual;
  }
}

class GolfleetGridTcoToll {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridTcoTollController;
  }
}

angular
  .module('golfleet-grid-tco-toll', ['ng-resize'])
  .component('golfleetGridTcoToll', new GolfleetGridTcoToll());
