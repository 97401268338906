import angular from 'angular';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '../golfleet-grid-maintenance/golfleet-grid-maintenance';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-header-selector/power-header-selector';
import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-revision-status.html';
import './golfleet-report-revision-status.scss';

class GolfleetReportRevisionStatusController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$rootScope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'maintenanceServices',
    ];
  }

  /**
   * Creates an instance of GolfleetReportRevisionStatusController.
   * @memberof GolfleetReportRevisionStatusController
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $rootScope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    maintenanceServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    Object.assign(this, { $rootScope, maintenanceServices });

    this.msgWithoutLastRevisionDate = ' veículos sem data da última revisão';
    this.vehicleWithoutLastRevisionDate = {
      status: 0, // 0. loading, 1. ok, 2. no data, 3. error
      list: [],
      count: 0,
    };

    this.formDataManualEntry = new FormData();
    this.dataImportPopupEntryError = {};
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$scope.$on('loadDatasetComplete', this._getVehiclesWithoutLastRevisionDate.bind(this));
  }

  /* */

  exportAs() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.maintenanceServices
      .callApiMethod('Maintenance/ExportVehiclesWithoutLastRevisionDateList')
      .then(
        async success => {
          const blob = await (
            await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
          ).blob();
          const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
          const downloadLink = document.createElement('a');

          downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
          downloadLink.href = fileUrl;
          downloadLink.click();
        },
        error => error,
      )
      .finally(() => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  /* Private */
  _evtClickPopupWithoutLastRevisionDateVehicle() {
    this.statusImport = 0;
    this.disabledImportBtn = true;

    this.$.querySelector('#popup-info-vehicle-without-last-revision').toggle();

    const inputManualEntry = this.$.querySelector('#file-manual-entry');

    const labelManualEntry = this.$.querySelector('#label-manual-entry');

    inputManualEntry.onchange = () => {
      const { files } = inputManualEntry;

      if (files && files.length > 0) {
        const file = files[0];

        labelManualEntry.children[0].innerHTML = file.name;
        labelManualEntry.children[0].classList.add('has-file');

        if (file.name.indexOf('.xls') > -1 || file.name.indexOf('.xlsx') > -1) {
          this.formDataManualEntry.append('importFile', file);

          if (labelManualEntry.nextElementSibling.style.display === 'block') {
            labelManualEntry.nextElementSibling.style.display = 'none';
          }

          this.disabledImportBtn = false;
        } else {
          labelManualEntry.nextElementSibling.style.display = 'block';
        }

        this.$scope.$apply();
      } else {
        this._clearPopupManualEntry(inputManualEntry, labelManualEntry, true);
        this.disabledImportBtn = true;
      }
    };
  }

  _clearPopupManualEntry(input, label, applyScope) {
    label.children[0].innerHTML = 'Nenhum arquivo selecionado';
    label.children[0].classList.remove('has-file');
    input.value = '';
    this.formDataManualEntry.delete('importFile');

    if (applyScope) this.$scope.$apply();
  }

  _getVehiclesWithoutLastRevisionDate() {
    this.maintenanceServices
      .callApiMethod('Maintenance/GetVehiclesWithoutLastRevisionDateList')
      .then(
        success => {
          if (success.data && success.data.hasError) this.vehicleWithoutLastRevisionDate.status = 3;
          else if (success.data.data && success.data.data.length > 0) {
            Object.assign(this.vehicleWithoutLastRevisionDate, {
              list: success.data.data,
              count: success.data.data.length,
              status: 1,
            });
          } else this.vehicleWithoutLastRevisionDate.status = 2;
          this._scopeApply();
        },
        () => {
          this.vehicleWithoutLastRevisionDate.status = 3;
          this._scopeApply();
        },
      );
  }

  _evtClickBtnsPopup(method) {
    const labelManualEntry = document.querySelector('#label-manual-entry');
    const inputManualEntry = document.querySelector('#file-manual-entry');

    switch (method) {
      case 'cancel':
        this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
        this.statusImport = 0;
        this.$.querySelector('#popup-info-vehicle-without-last-revision').toggle();
        break;
      case 'ok':
        this.$.querySelector('#popup-info-vehicle-without-last-revision').toggle();
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$broadcast('getDataset', null);
        break;
      case 'more':
        this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
        this.statusImport = 0;
        break;
      case 'reform':
        this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
        this.statusImport = 0;
        break;
      case 'save':
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.reportServices
          .sendFileManualEntry('Maintenance/UploadFileLastRevisionDate', this.formDataManualEntry)
          .then(
            () => {
              this.statusImport = 1;

              this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
            },
            ({ data }) => {
              if (data && data.hasError) {
                this.statusImport = 2;
                this.dataImportPopupEntryError.fileName = labelManualEntry.children[0].innerHTML;
                this.dataImportPopupEntryError.countError = data.data.length;
                this.dataImportPopupEntryError.data = data.data;
              }
            },
          )
          .finally(() => {
            this.$scope.$broadcast('getDataset', null);
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            );
          });
        break;
      default:
        break;
    }
  }

  _scopeApply() {
    if (this.$scope.$$phase === null && this.$rootScope.$$phase === null) {
      this.$scope.$apply();
    }
  }
}

class GolfleetReportRevisionStatus {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportRevisionStatusController;
  }
}

angular
  .module('golfleet-report-revision-status', [
    'ngRedux',
    'power-fab',
    'power-toolbar-report',
    'golfleet-grid-maintenance',
    'power-footer',
    'power-pagination',
    'power-header-selector',
    'power-toast',
  ])
  .component('golfleetReportRevisionStatus', new GolfleetReportRevisionStatus());
