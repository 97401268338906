/* global H: true isIframe: true */
import { PowerMapHereProvider } from '@power/power-components/components/power-map/providers/here/power-map.here-provider';
import '@power/power-components/helpers/is-iframe/is-iframe';
import './golfleet-map-fuel-supply-management.here-provider.scss';

class GolfleetMapFuelSupplyManagementHereProvider extends PowerMapHereProvider {
  // eslint-disable-next-line no-useless-constructor
  constructor(context, $element, $ngRedux, $scope, $http, urlApi) {
    super(context, $element, $ngRedux, $scope, $http, urlApi);
  }

  /* Private */
  _geoJsonToMarker({ geoJson, markerIcon }) {
    const [[lng, lat]] = geoJson.geometry.coordinates;
    return new H.map.DomMarker(
      { lat, lng },
      {
        icon: markerIcon || this._createMarkerIcon(geoJson.properties),
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupHeader">
              <span>${geoJson.properties.title}</span>
            </div>
            <div id="mapPopupBody">
              <div style="display: ${geoJson.properties.address ? 'block' : 'none'}">
                <b>Endereço:</b>
                <br>
                <span>${geoJson.properties.address}</span>
              </div>
              <div style="display: ${geoJson.properties.city ? 'block' : 'none'}">
                <b>Cidade - Estado:</b>
                <br>
                <span>${geoJson.properties.city} - ${geoJson.properties.state}</span>
              </div>
              <div>
                <b>Data Hora:</b>
                <br>
                <span>${new Date(geoJson.properties.date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</span>
              </div>
              ${
                isIframe()
                  ? ''
                  : `<div style="margin-top:8px; text-align:center">
                  <a href="${
                    !geoJson.properties.streetView
                      ? `https://maps.google.com/maps?layer=c&q=${lat},${lng}` +
                        `&cbll=${lat},${lng}&cbp=11,0,0,0,0&z=17&ll=${lat},${lng}`
                      : `https://maps.google.com/maps?layer=c&q=&cbll=${geoJson.properties.streetView[0]},${geoJson.properties.streetView[1]}&cbp=11,${geoJson.properties.streetView[2]},0,${geoJson.properties.streetView[4]},${geoJson.properties.streetView[3]}&panoid=${geoJson.properties.streetView[5]}`
                  }"
                    target="_blank"
                    class="gs-link">
                    Ver no StreetView
                  </a>
                </div>
              `
              }
            </div>
            <div id="mapPopupFooter">
              <span> Lat: ${Number(lat).toFixed(4)} </span>
              <span> Lon: ${Number(lng).toFixed(4)} </span>
            </div>
          `,
        },
      },
    );
  }

  _geoJsonToPolyline({ geoJson }) {
    return new H.map.Polyline(
      geoJson.geometry.coordinates.reduce((acc, coordinate) => {
        const [lng, lat] = coordinate;
        acc.pushLatLngAlt(lat, lng);
        return acc;
      }, new H.geo.LineString()),
      {
        data: {
          ...geoJson.properties,
          bubbleContent: `
            <div id="mapPopupBody" style="border-radius: 8px;">
              <div>
                <b>Distância:</b>
                <br>
                <span>${geoJson.properties.distance} Km</span>
              </div>
            </div>
          `,
        },
        style: {
          lineWidth: 6,
          strokeColor: this._hexToRgba(geoJson.properties.color, 0.66),
        },
      },
    );
  }
  /**/
}

export { GolfleetMapFuelSupplyManagementHereProvider };
