import angular from 'angular';

import template from './power-footer.html';
import './power-footer.scss';

class PowerFooterController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });
  }
}

class PowerFooter {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.transclude = {
      leftSlot: '?leftSlot',
      centerSlot: '?centerSlot',
      rightSlot: '?rightSlot',
    };
    this.controller = PowerFooterController;
  }
}

angular.module('power-footer', []).component('powerFooter', new PowerFooter());
