/** @typedef {import('power-popup-users/src/power-popup-users').User} User */

import angular from 'angular';
import moment from 'moment';
import $ from 'jquery';

import '../power-dropdown/power-dropdown';
import '../power-popup/power-popup';
import '../power-popup-users/power-popup-users';
import '../power-toast/power-toast';

import template from './power-popup-report-auto-send.html';
import './power-popup-report-auto-send.scss';

// #region /* Models */
export class TabItem {
  /**
   * - Constructor
   * @param {String} name
   * @param {String} label
   * @param {Boolean} selected
   */
  constructor(name, label, selected = false) {
    this.name = name;
    this.label = label;
    this.selected = selected;
  }
}

export class RecipientUser {
  /**
   * - Constructor
   */
  constructor(icon, id, name, email, disabled = false) {
    this.icon = icon;
    this.id = id;
    this.name = name;
    this.email = email;
    this.disabled = disabled;
  }

  /**
   * Export data to save request
   */
  exportData() {
    return {
      userId: this.id,
      userEmail: this.email,
    };
  }
}

export class AutoSendConfig {
  /**
   * - Constructor
   */
  constructor({
    id,
    configId,
    fileType,
    fileName,
    digitalFile,
    sendType,
    frequencyType,
    frequencyQty,
    frequencyValue,
    frequencyStopType,
    frequencyStopQty,
    frequencyStopDate,
    _data,
    users = [],
    filterConfig,
    gridConfig,
    instanceOf,
    createdAt,
  }) {
    this.id = id || null;
    this.configId = configId || '';
    this.fileType = fileType || 'CSV';
    this.digitalFile = typeof digitalFile === 'boolean' ? digitalFile : true;
    this.fileName = fileName || '';
    this.sendType = sendType || 'LINK';
    this.frequencyType = frequencyType || 'DAY';
    this.frequencyQty = frequencyQty || 1;
    this.frequencyValue = frequencyValue || [];
    this.frequencyStopType = frequencyStopType || 'NEVER';
    this.frequencyStopQty = frequencyStopQty || 1;
    this.frequencyStopDate = frequencyStopDate || new Date();
    this._data = _data;
    this.users = users || [];
    this.filterConfig = filterConfig ? AutoSendConfig.getFilterConditions(filterConfig) : null;
    this.gridConfig = gridConfig ? AutoSendConfig.getGridConfigurations(gridConfig) : null;
    this.instanceOf = instanceOf;
    this.createdAt = createdAt || new Date();
  }

  /**
   * Export data to save request
   */
  exportData() {
    return {
      _id: this.id,
      userReportConfigurationId: this.configId,
      fileType: this.fileType,
      digitalFile: this.digitalFile,
      nameFile: this.fileName,
      type: this.sendType,
      frequencyType: this.frequencyType,
      frequencyQty: this.frequencyQty,
      frequencyValue: this.frequencyValue,
      frequencyStoppedType: this.frequencyStopType,
      frequencyStoppedQty: this.frequencyStopType == 'AFTER' ? this.frequencyStopQty : null,
      frequencyStoppedDate:
        this.frequencyStopType == 'AT'
          ? `${moment(this.frequencyStopDate).format('YYYY-MM-DD')}`
          : null,
      recipientsUsers: this.users.map(u => u.exportData()),
    };
  }

  static parse(data) {
    return new AutoSendConfig({
      id: data._id,
      configId: data.userReportConfigurationId,
      fileType: data.fileType,
      digitalFile: data.digitalFile,
      fileName: data.nameFile,
      sendType: data.type,
      frequencyType: data.frequencyType,
      frequencyQty: data.frequencyQty,
      frequencyValue: data.frequencyValue,
      frequencyStopType: data.frequencyStoppedType,
      frequencyStopQty: data.frequencyStoppedQty,
      frequencyStopDate: data.frequencyStoppedDate,
      _data: data,
      users: data.users,
      filterConfig: data.filterConfig,
      gridConfig: data.gridConfig,
      createdAt: data.createdAt,
    });
  }

  /**
   * Get icon and description from compound custom filter
   * @param {Any} item
   */
  static getCompoundCustomMetadata(item) {
    const { icon, description, activeView, activeEntity } = item;
    return { icon, description, activeView, activeEntity };
  }

  /**
   * Get grid configurations
   * @param {Any} gridConfig
   */
  static getGridConfigurations(gridConfig) {
    if (!gridConfig) return null;

    return {
      gridMongoId: gridConfig.gridMongoId,
      gridHeaders: gridConfig.gridHeaders.map(item => ({
        field: item.field,
        show: item.show,
      })),
      sortField: gridConfig.sortField,
      sortDirection: gridConfig.sortDirection,
      pageSize: gridConfig.pageSize,
    };
  }

  /**
   * Get filter conditions
   * @param {Any} filterConfig
   */
  static getFilterConditions(filterConfig) {
    if (!filterConfig) {
      return null;
    }

    return filterConfig.map(config => {
      if (config.__metadata__) {
        return config;
      }

      const { id, field, condition, type } = config;
      const __metadata__ =
        config.type != 'compoundCustom'
          ? { icon: config.icon, description: config.description }
          : AutoSendConfig.getCompoundCustomMetadata(config);

      return { id, field, condition, type, __metadata__ };
    });
  }
}
// #endregion

/**
 * - Component Controller
 */
export class PowerPopupReportAutoSendController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$state',
      '$scope',
      '$http',
      '$timeout',
      'urlApi',
      'reportServices',
    ];
  }

  /**
   * - Constructor
   */
  constructor($element, $ngRedux, $state, $scope, $http, $timeout, urlApi, reportServices) {
    Object.assign(this, {
      $: $element[0],
      $ngRedux,
      $state,
      $scope,
      $http,
      $timeout,
      urlApi,
      reportServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];
      const { userId, userEmail, userName } = behavior.session;

      return Object({
        sessionState: { userId, userEmail, userName },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.model = new AutoSendConfig({});
    this.tabItems = [
      new TabItem('data', 'Dados', true),
      new TabItem('frequency', 'Frequência', false),
      new TabItem('users', 'Destinatários', false),
    ];

    this.fileTypes = [
      { value: 'CSV', digital: true, description: 'Arquivo CSV', selected: true },
      { value: 'XLSX', digital: true, description: 'Arquivo XLSX', selected: false },
      { value: 'PDF', digital: true, description: 'Arquivo PDF (digital)', selected: false },
      { value: 'PDF', digital: false, description: 'Arquivo PDF (impressão)', selected: false },
    ];

    this.frequencyTypeOptions = [
      { value: 'DAY', description: 'Dia', selected: true },
      { value: 'WEEK', description: 'Semana', selected: false },
      { value: 'MONTH', description: 'Mês', selected: false },
    ];

    this.dayOfMonthOptions = [];
    for (let i = 1; i <= 31; i += 1) {
      const valueText = i < 10 ? `0${i}` : i.toString();
      this.dayOfMonthOptions.push({
        value: i,
        description: valueText,
        selected: false,
      });
    }
    this.dayOfMonthOptions.push({ value: 99, description: 'Último dia do Mês', selected: false });

    this.dayOfWeekOptions = [
      { value: 1, description: 'DOM', selected: false },
      { value: 2, description: 'SEG', selected: false },
      { value: 3, description: 'TER', selected: false },
      { value: 4, description: 'QUA', selected: false },
      { value: 5, description: 'QUI', selected: false },
      { value: 6, description: 'SEX', selected: false },
      { value: 7, description: 'SAB', selected: false },
    ];

    this.frequencyStopTypeOptions = [
      { value: 'NEVER', description: 'Nunca', selected: true },
      { value: 'AFTER', description: 'Após', selected: false },
      { value: 'AT', description: 'Em', selected: false },
    ];

    // eslint-disable-next-line no-undef
    $.fn.datepicker.language['pt-BR'] = {
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      daysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      dateFormat: 'dd/mm/yyyy',
      timeFormat: 'hh:ii',
      firstDay: 0,
    };

    this.alertPopup = {};
  }

  // #region /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      loadData: this.loadData.bind(this),
      setReportIcon: this.setReportIcon.bind(this),
      toggle: this.toggle.bind(this),
    });

    this.$scope.$watch(() => this.model, this.__modelChanged.bind(this));

    this.$scope.$watch(
      () => this.model.frequencyStopType,
      this.__frequencySendStopTypeChanged.bind(this),
    );
    this.$scope.$watch(
      () => this.tabItems.find(item => item.name == 'frequency').selected,
      this.__tabItemsChanged.bind(this),
    );
  }

  $onDestroy() {
    this.__appBehavior();
  }
  // #endregion

  // #region /* Public */
  /**
   * Open / Close pop-up
   * @param {TabItem} tab
   */
  toggle() {
    // togglePopup
    if (this.$.querySelector('#popup-auto-send').getAttribute('open') == null) {
      this.$.querySelector('#power-toolbar-auto-send-popup-users').requestData();
      this.$.querySelector('#popup-auto-send').toggle();
      this._scrollWorkaround('#popup-auto-send .toolbar-report-popup .filters');
    } else {
      this.$.querySelector('#popup-auto-send').toggle();
      setTimeout(() => {
        const [tabData] = this.tabItems;
        this._selectTabItem(this.tabItems.find(item => item.name == tabData.name));
      }, 500);
    }
  }

  showDeleteConfirmation() {
    this.alertPopup = {
      message: 'Deseja realmente excluir este envio automático?',
      complement: [],
      customButton: {
        action: this.delete.bind(this),
        text: 'Excluir',
      },
    };

    this.toggleWarningMessage();
  }

  toggleWarningMessage() {
    this.$.querySelector('#popup-warning-and-confirmation').toggle();
  }

  /**
   * Save Auto Send Configuration
   */
  save() {
    switch (this.model.frequencyType) {
      case 'WEEK':
        this.model.frequencyValue = this.dayOfWeekOptions
          .filter(opt => opt.selected)
          .map(opt => opt.value);
        break;
      case 'MONTH':
        this.model.frequencyValue = this.dayOfMonthOptions
          .filter(opt => opt.selected)
          .map(opt => opt.value);
        break;
      default:
    }

    function validateModel(model) {
      const errors = [];
      if (!model.fileName) {
        errors.push('Nome do envio automático');
      }
      if (model.frequencyType === 'WEEK' && model.frequencyValue.length === 0) {
        errors.push('Dia(s) da semana');
      }
      if (model.frequencyType === 'MONTH' && model.frequencyValue.length === 0) {
        errors.push('Dia(s) do mês');
      }
      if (model.users.length === 0) {
        errors.push('Destinatários');
      }
      return errors;
    }

    const errors = validateModel(this.model);

    if (errors.length > 0) {
      this.alertPopup = {
        message: 'Por gentileza, preencha os campos corretamente!',
        complement: errors,
        customButton: null,
      };

      this.toggleWarningMessage();
      return;
    }

    const saveAutoSendConfiguration = userReportConfigurationId => {
      this.toggle();
      this._toogleLoader(true);

      this.reportServices
        .saveAutoSendConfiguration({ ...this.model.exportData(), userReportConfigurationId })
        .then(response => {
          if (response.status && response.status !== 200) {
            Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
          } else {
            Object.assign(this, { _toastText: 'Envio automático salvo com sucesso!' });
            this._onSaved();
          }
          this.$.querySelector('power-toast#auto-send-toast').toggle(true);
          this._toogleLoader(false);
        });
    };

    if (!this.model.configId) {
      this.$.dispatchEvent(
        new CustomEvent('save', { detail: { callback: saveAutoSendConfiguration } }),
      );
    } else {
      saveAutoSendConfiguration(this.model.configId);
    }
  }

  delete() {
    this.toggleWarningMessage();
    this._toogleLoader(true);

    this.reportServices.deleteAutoSendConfiguration({ _id: this.model.id }).then(response => {
      if (response.status && response.status !== 200) {
        Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
        this.$.querySelector('power-toast#auto-send-toast').toggle(true);
        this._toogleLoader(false);
        return;
      }

      this.toggle();
      this._toogleLoader(false);

      Object.assign(this, { _toastText: 'Envio automático removido com sucesso!' });
      this.$.querySelector('power-toast#auto-send-toast').toggle(true);
      this._onSaved();
    });
  }

  async loadData(autoSendConfig) {
    return new Promise((resolve, reject) => {
      if (autoSendConfig) {
        this._toogleLoader(true);

        this.reportServices
          .getUserReportConfiguration({ _id: autoSendConfig.configId })
          .then(success => {
            if (success.status && success.status != 200) {
              this._toogleLoader(false);
              reject();
            }

            const { data } = success.data;

            this.model = new AutoSendConfig({
              ...autoSendConfig,
              instanceOf: data.reportName,
              filterConfig: JSON.parse(data.filterConfig),
              gridConfig: JSON.parse(data.gridConfig),
            });

            this._toogleLoader(false);
            resolve(this.model);
          });
      } else {
        this.model = new AutoSendConfig({
          configId: this.currentState.userReportId,
          fileName: `Envio Automático ${
            this.currentState.userReportName || this.currentState.routeName
          }`,
          instanceOf: this.currentState.userReportName,
          filterConfig: this.stateConfig.filterConfig,
          gridConfig: this.stateConfig.gridConfig,
        });

        resolve(this.model);
      }
    });
  }

  setReportIcon(icon) {
    this.reportIcon = icon;
  }
  // #endregion

  // #region /* Private */
  _onSaved() {
    this.$.dispatchEvent(new CustomEvent('saved'));
  }

  _getSelectedFrequencyTypeOption(prop = '') {
    const result = this.frequencyTypeOptions.find(option => option.selected);
    return prop ? result[prop] : result;
  }

  _selectFileTypeOption(option) {
    this.fileTypes.forEach(item => {
      item.selected = item.value === option.value && item.digital === option.digital;
    });

    this.model.fileType = option.value;
    this.model.digitalFile = option.digital;
  }

  _selectFrequencyTypeOption(option) {
    this.frequencyTypeOptions.forEach(item => {
      item.selected = item.value === option.value;
    });

    this.model.frequencyType = option.value;
  }

  _toggleDayOfMonthOption(option) {
    option.selected = !option.selected;
  }

  _toggleDayOfWeekOption(option) {
    option.selected = !option.selected;
  }

  _toggleAllDayOfMonth() {
    const hasSelected = this.dayOfMonthOptions.find(item => item.selected);
    const hasUnselected = this.dayOfMonthOptions.find(item => !item.selected);

    this.dayOfMonthOptions.forEach(item => {
      item.selected = hasSelected && hasUnselected ? true : !hasSelected;
    });
  }

  _toggleSelectionDayOfMonth() {
    this.dayOfMonthOptions.forEach(item => {
      item.selected = !item.selected;
    });
  }

  _selectFrequencyStopTypeOption(option) {
    this.frequencyStopTypeOptions.forEach(item => {
      item.selected = item.value == option.value;
    });

    this.model.frequencyStopType = option.value;
  }

  _getSelectedFrequencyStopTypeOption(prop = '') {
    const result = this.frequencyStopTypeOptions.find(option => option.selected);
    return prop ? result[prop] : result;
  }

  _fileOptionIsAvailable(option) {
    if (Array.isArray(this.downloadExtensions)) {
      return this.downloadExtensions.includes(option.value.toLowerCase());
    }
    return true;
  }

  /**
   * - Add selected items - users pop-up callback
   * @param {Array<User>} selectedItems
   */
  _addSelectedUsersPopupCallback(selectedItems) {
    selectedItems.forEach(item => {
      if (!this.model.users.find(r => r.id == item.id)) {
        this.model.users.push(new RecipientUser(item.icon, item.id, item.name, item.email));
      }
    });
  }

  /**
   * - Request data - users pop-up callback
   * @param {Array<User>} userItems
   */
  _requestUsersPopupCallback(userItems) {
    if (this.model.users.length === 0) {
      const recipientsUsers =
        this.model._data && this.model._data.recipientsUsers
          ? this.model._data.recipientsUsers
          : [];

      let result = [];

      userItems.forEach(item => {
        if (recipientsUsers.find(user => user.userId === item.id)) {
          if (item.id === this.sessionState.userId) {
            result = [
              new RecipientUser(item.icon, item.id, item.name, item.email, false),
              ...result,
            ];
          } else {
            result.push(new RecipientUser(item.icon, item.id, item.name, item.email, false));
          }
        }
      });

      this.model.users = result;
    }
  }

  /**
   * Open / Close users pop-up
   */
  _usersTogglePopup() {
    this.$.querySelector('#power-toolbar-auto-send-popup-users').toggle();
  }

  /**
   * Select specific item
   * @param {TabItem} tab
   */
  _selectTabItem(tab) {
    this.tabItems.forEach(item => {
      item.selected = tab.name === item.name;
    });
  }

  /**
   * Get selected item
   */
  _selectedTabItem() {
    const [item] = this.tabItems.filter(tabItem => tabItem.selected);
    return item;
  }

  /**
   * Remove user by id
   * @param {Number} id
   */
  _removeUser(id) {
    this.model.users = this.model.users.filter(user => user.id != id);
  }

  _toogleLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  /**
   * Chrome Locked-Scroll Workaround
   * @param {String} selector
   */
  _scrollWorkaround(selector) {
    const element = this.$.querySelector(selector);
    setTimeout(() => {
      element.style.overflowY = 'hidden';
    }, 1100);
    setTimeout(() => {
      element.style.overflowY = 'auto';
    }, 1200);
  }

  /**
   * Angular $$hashKey to 'undefined' Workaround
   * @param {String} key
   * @param {Any} value
   */
  _hashKeyToUndefinedWorkaround(key, value) {
    if (key === '$$hashKey') {
      return undefined;
    }
    return value;
  }

  _fieldNumberKeydownHandler($event) {
    if ([188, 190].includes($event.keyCode)) {
      this.model[$event.target.name] = Number($event.target.value || 0);
      $event.target.value = 0;
    }
  }

  _frequencyQtySumValue(increment) {
    this.model.frequencyQty += increment;

    if (this.model.frequencyQty < 1) {
      this.model.frequencyQty = 1;
    }
  }

  _frequencyStopQtySumValue(increment) {
    this.model.frequencyStopQty += increment;

    if (this.model.frequencyStopQty < 1) {
      this.model.frequencyStopQty = 1;
    }
  }

  _createDatePicker() {
    const minDate = moment().set({ hour: 0, minute: 0, second: 0 })._d;

    $(this.$.querySelector(`#datepicker-send-stop-date`))
      .datepicker({
        language: 'pt-BR',
        classes: `datepicker-report-alerts datepicker-report-alert`,
        toggleSelected: false,
        dateFormat: 'dd/mm/yyyy',
        position: 'top left',
        autoClose: true,
        navTitles: {
          days: 'MM / yyyy',
        },
        minDate,
        onSelect: (_formattedDate, date) => {
          this.model.frequencyStopDate = date;
        },
      })
      .data('datepicker');
  }
  // #endregion

  __tabItemsChanged() {
    if (
      this.tabItems.find(item => item.name == 'frequency' && item.selected) &&
      this.frequencyStopTypeOptions.find(item => item.value == 'AT' && item.selected)
    ) {
      setTimeout(() => {
        this._createDatePicker();
      }, 350);
    }
  }

  __frequencySendStopTypeChanged() {
    if (this.frequencyStopTypeOptions.find(item => item.value == 'AT' && item.selected)) {
      setTimeout(() => {
        this._createDatePicker();
      }, 350);
    }
  }

  __modelChanged() {
    this._selectFileTypeOption({ value: this.model.fileType, digital: this.model.digitalFile });
    this._selectFrequencyTypeOption({ value: this.model.frequencyType });
    this._selectFrequencyStopTypeOption({ value: this.model.frequencyStopType });

    this.dayOfMonthOptions.forEach(option => {
      option.selected = false;
    });
    this.dayOfWeekOptions.forEach(option => {
      option.selected = false;
    });

    if (this.model.frequencyType == 'WEEK') {
      this.model.frequencyValue.forEach(item => {
        this.dayOfWeekOptions
          .filter(option => option.value == item)
          .forEach(option => {
            option.selected = true;
          });
      });
    } else if (this.model.frequencyType == 'MONTH') {
      this.model.frequencyValue.forEach(item => {
        this.dayOfMonthOptions
          .filter(option => option.value == item)
          .forEach(option => {
            option.selected = true;
          });
      });
    }
  }
}

export class PowerPopupReportAutoSend {
  constructor() {
    this.template = template;
    this.bindings = {
      reportIcon: '=?',
      downloadExtensions: '=?',
    };
    this.controller = PowerPopupReportAutoSendController;
  }
}

angular
  .module('power-popup-report-auto-send', [
    'ngRedux',
    'power-dropdown',
    'power-popup',
    'power-popup-users',
    'power-toast',
  ])
  .component('powerPopupReportAutoSend', new PowerPopupReportAutoSend());
