import angular from 'angular';
import moment from 'moment/src/moment';
import * as Comlink from 'comlink';
import 'angular-sanitize';
import 'moment/src/locale/pt-br';

import '../../directives/ng-resize/ng-resize';

import template from './power-grid.html';
import './power-grid.scss';

class PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $state,
      $http,
      $timeout,
      $filter,
      commonServices,
      urlApi,
      $ngRedux,
    });

    this.pageRows = [];
    this.activeRow = {};
    this.isPaginated = false;
    this.virtualRows = [];
    this.selectedRows = [];
    this.isAllSelected = false;
    this.dateGranularity = 'sumarizado';
    this.requestingPages = [];

    this._lastPageSize = 10;

    this.downloadfallback = false;

    this.queryMobile = window.matchMedia('(max-width: 425px)');

    this.worker = new Worker('./power-grid.worker.js');
    this.workerService = Comlink.wrap(this.worker);

    this.__appInheritBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        modules: behavior.session.modules,
      }),
    )(this);

    moment.locale('pt-BR');
    moment.updateLocale('pt-BR', {
      months : [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
    });
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      getHeaders: this.getHeaders.bind(this),
      getDataset: this.getDataset.bind(this),
      changePage: this.changePage.bind(this),
      scrollToIndex: this.scrollToIndex.bind(this),
      changePageSize: this.changePageSize.bind(this),
      getActiveRow: this.getActiveRow.bind(this),
      setActiveRow: this.setActiveRow.bind(this),
    });

    this.$scope.$on('getHeaders', this.getHeaders.bind(this));
    this.$scope.$on('getDataset', this.getDataset.bind(this));
    this.$scope.$on('changePage', this.changePage.bind(this));
    this.$scope.$on('changePageSize', this.changePageSize.bind(this));

    this.$scope.$watch(() => this.gridDataset, this.__onGridDatasetChanged.bind(this));
    this.$scope.$watch(() => this.dateGranularity, this.__onDateGranularityChanged.bind(this));
    this.$scope.$watch(
      () => JSON.stringify(this.gridHeaders),
      this.__onGridHeadersChanged.bind(this),
    );

    this.$scope.$emit('getDatasetReady');

    this.$.querySelector('.table-container').addEventListener(
      'scroll',
      this.__onVirtualScroll.bind(this),
      { passive: true },
    );

    this._lastPageSize = this.pageSize;

    this._performMobileModeAdjustments();

    this.queryMobile.addListener(this.__onMobileMediaQuery.bind(this));
  }

  $onDestroy() {
    this.worker.terminate();
  }
  /* */

  /* Public */
  async selectRow(row) {
    const gridElementRow = this.$.querySelector(`table .row-index-${row._index}`);

    if (gridElementRow) {
      gridElementRow.setAttribute('pending', '');
    }

    await this.workerService.toggleRowSelection({ _index: row._index });

    this.pageRows = await this.workerService.pageRows;
    this.selectedRows = await this.workerService.selectedRows;
    this.toggleAllIcon = await this.workerService.toggleAllIcon;
    this.isAllSelected = await this.workerService.isAllSelected;

    if (this.queryMobile.matches) {
      requestAnimationFrame(() => this._virtualScrollRender());
    }

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }

    if (gridElementRow) {
      gridElementRow.removeAttribute('pending');
    }
  }

  async changePage(_, { page, payload }) {
    await this.workerService.setPage({ page });

    this.page = await this.workerService.page;
    this.pageSize = await this.workerService.pageSize;

    if (payload.isPaginated) {
      await this.getDataset(this, { ...payload, keepDataset: true });
    } else {
      this.pageRows = await this.workerService.pageRows;
    }

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  async setActiveRow({ index }) {
    await this.workerService.setActiveRow({ index });

    this.pageRows = await this.workerService.pageRows;
    this.activeRow = await this.workerService.activeRow;

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }

    this.$.dispatchEvent(
      new CustomEvent('requestSyncVisualization', { detail: { index: this.activeRow._index } }),
    );
  }

  async toggleAllRows() {
    const gridElement = this.$.querySelector('table');

    if (gridElement) {
      gridElement.setAttribute('pending', '');
    }

    await this.workerService.toggleAllRowsSelection();

    this.pageRows = await this.workerService.pageRows;
    this.selectedRows = await this.workerService.selectedRows;
    this.toggleAllIcon = await this.workerService.toggleAllIcon;
    this.isAllSelected = await this.workerService.isAllSelected;

    if (this.queryMobile.matches) {
      requestAnimationFrame(() => this._virtualScrollRender());
    }

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }

    if (gridElement) {
      gridElement.removeAttribute('pending');
    }
  }

  async changePageSize(_, { pageSize, payload }) {
    await this.workerService.setPageSize({ pageSize });

    this.page = await this.workerService.page;
    this.pageSize = await this.workerService.pageSize;
    this.lastPage = this.downloadfallback ? 1 : await this.workerService.lastPage;

    if (payload.isPaginated) {
      await this.getDataset(this, { ...payload, keepDataset: true });
    } else {
      this.pageRows = await this.workerService.pageRows;
    }

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  sortGrid(sortName) {
    if (this.queryMobile.matches) {
      this.page = 1;
    }

    if (this.sortHeader.toLowerCase() != sortName.toLowerCase()) {
      Object.assign(this, {
        sortHeader: sortName,
        sortDirection: 'asc',
      });
    } else {
      Object.assign(this, {
        sortDirection: this.sortDirection == 'desc' ? 'asc' : 'desc',
      });
    }

    this.getDataset();
  }

  getDataset(_, payload) {
    let resetDataset = false;
    this.downloadfallback = false;

    this.$.removeAttribute('download-fallback');

    if (!payload || !payload?.page || this.page >= payload.page) {
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
    }

    this.$scope.$emit('finishFilterConditions', {});

    if (payload) {
      if (!payload.keepDataset) {
        resetDataset = true;
        this.$.querySelector('.table-container').scrollTo(0, 0);
      }

      if (payload?.async) {
        Object.assign(this, {
          isPaginated: payload.isPaginated,
          datasetParams: { ...this.datasetParams, ...payload },
        });
      } else {
        Object.assign(this, {
          page: payload.page >= 0 ? payload.page : this.page,
          isPaginated: payload.isPaginated,
          datasetParams: { ...this.datasetParams, ...payload },
        });
      }

      if (payload.sort) {
        const { name, direction } = payload.sort;

        this.sortHeader = name;
        this.sortDirection = direction;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(
                column => column.sortable === name || column.field === name,
              )) ||
            gridHeader.sortable === name ||
            gridHeader.field === name
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(
                item => item.sortable === name || item.field === name,
              );
              gridHeader.selectors.forEach(item => {
                item.selected = item.selector === column.selector;
              });
            }
          }

          return gridHeader;
        });
      }

      if (payload.visibleColumns) {
        const { visibleColumns } = payload;

        this.gridHeaders = this.gridHeaders.map(gridHeader => {
          if (
            (gridHeader.columns &&
              gridHeader.columns.some(column => visibleColumns.includes(column.field))) ||
            visibleColumns.includes(gridHeader.field)
          ) {
            gridHeader.show = true;

            if (gridHeader.columns && gridHeader.selectors) {
              const column = gridHeader.columns.find(item => visibleColumns.includes(item.field));
              if (gridHeader.selectors) {
                gridHeader.selectors.forEach(item => {
                  item.selected = item.selector === column.selector;
                });
              }
            }
          }

          return gridHeader;
        });
      }
    }

    let reportColumns = {};
    let reportMaxRowsQty = 0;

    if (this.hasMaxRows) {
      reportColumns = this.gridHeaders;
      reportMaxRowsQty = this.maxRowsQty;
    }

    return this.$http({
      url: `${this.urlApi}/${this.datasetMethod}`,
      method: 'POST',
      data: {
        columns: reportColumns,
        maxrowsqty: reportMaxRowsQty,
        request: {
          ...this.datasetParams,
          page: payload?.page - 1 || this.page - 1,
          length: this.pageSize,
          sort: {
            name: this.sortHeader,
            direction: this.sortDirection,
          },
        },
      },
    })
      .then(
        async success => {
          if (success.status && success.status === 200) {
            const columns = await this.workerService.columns;
            const response = success.data.data;

            if (columns.length === 0) {
              await this.workerService.setColumns({ columns: this.gridHeaders });
            }

            this.datasetLength = response.total;
            this.complementHeaderText = response[this.complementHeaderField];
            this.lastPage = this._calcGridMaxPage(response.total, this.pageSize);
            this.page = (this.page > this.lastPage ? 1 : this.page) || 1;

            await this.workerService.setPageSize({ pageSize: this.pageSize });
            await this.workerService.setPage({ page: this.page });

            if (this.datasetLength === 0) {
              this.virtualRows = [];
            }

            if (success.data.data.fileData) {
              const blob = await (
                await fetch(
                  `data:'application/octet-stream';base64,${success.data.data.fileData.contentBase64}`,
                )
              ).blob();
              const fileUrl = window.URL.createObjectURL(blob, {
                type: success.data.data.fileData.contentType,
              });
              const downloadLink = document.createElement('a');

              downloadLink.download = `${success.data.data.fileData.fileName}${success.data.data.fileData.extension}`;
              downloadLink.href = fileUrl;
              downloadLink.click();

              this.downloadfallback = true;
              this.$.setAttribute('download-fallback', '');
            }

            if (this.isPaginated) {
              await this.workerService.addToDataset({
                page: payload?.page - 1 || this.page - 1,
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
                resetDataset,
                useVirtualScroll: true,
              });
            } else {
              await this.workerService.setDataset({
                rows: response.data,
                rowsLength: response.total,
                isPaginated: this.isPaginated,
              });
            }

            this.page = await this.workerService.page;
            this.pageSize = await this.workerService.pageSize;
            this.lastPage = success.data.data.fileData ? 1 : await this.workerService.lastPage;
            this.pageRows = await this.workerService.pageRows;
            this.activeRow = await this.workerService.activeRow;
            this.gridDataset = await this.workerService.rows;
            this.selectedRows = await this.workerService.selectedRows;
            this.toggleAllIcon = await this.workerService.toggleAllIcon;
            this.isAllSelected = await this.workerService.isAllSelected;

            if (!this.$scope.$$phase) {
              this.$scope.$apply();
            }

            this.$scope.$emit('loadDatasetComplete');
          }
        },
        async () => {
          await this.workerService.setPageSize({ pageSize: this.pageSize });
          await this.workerService.setPage({ page: 1 });
          await this.workerService.setDataset({ rows: [], isPaginated: this.isPaginated });

          this.page = await this.workerService.page;
          this.pageSize = await this.workerService.pageSize;
          this.lastPage = await this.workerService.lastPage;
          this.pageRows = await this.workerService.pageRows;
          this.gridDataset = await this.workerService.rows;
          this.selectedRows = await this.workerService.selectedRows;
          this.toggleAllIcon = await this.workerService.toggleAllIcon;
          this.isAllSelected = await this.workerService.isAllSelected;

          if (!this.$scope.$$phase) {
            this.$scope.$apply();
          }
        },
      )
      .finally(() => {
        this.$timeout(() => this.$scope.$emit('UPDATE_ROUTE'));

        this.$.dispatchEvent(
          new CustomEvent('getDataset', {
            detail: {
              page: payload?.page || this.page,
              resetDataset: resetDataset || this.firstVirtualPagination,
              datasetLength: this.datasetLength,
            },
          }),
        );

        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
      });
  }

  getHeaders(_, payload) {
    if (payload) this.headerParams = { ...payload };

    const { screenName, gridName } = this.headerParams;
    const { userGridConfig = { gridHeaders: [] } } = this.headerParams;
    const listModules = this.modules;

    return this.commonServices
      .getGridHeaders({ screenName, gridName })
      .then(
        data => {
          function getHeaders() {
            if (userGridConfig.gridHeaders.length > 0) {
              const headers = userGridConfig.gridHeaders.reduce((acc, gridHeader, index) => {
                const header = data.columns.find(column => column.field === gridHeader.field);

                if (header) {
                  const { show, selectors } = gridHeader;

                  if (Array.isArray(header.selectors) && Array.isArray(selectors)) {
                    header.selectors.forEach(item => {
                      item.selected = !!(selectors.find(s => s.selector === item.selector) || {})
                        .selected;
                    });
                  }

                  return acc.concat({ ...header, show, id: index });
                }

                return acc;
              }, []);

              const extraHeaders = data.columns.filter(
                column =>
                  !userGridConfig.gridHeaders.find(gridHeader => column.field === gridHeader.field),
              );

              return [
                ...headers,
                ...extraHeaders.map((column, index) => ({
                  ...column,
                  show: false,
                  id: headers.length + index,
                })),
              ];
            }

            return [
              ...data.columns
                .filter(
                  column => !column.validateModule || listModules.includes(column.validateModule),
                )
                .map((column, index) => ({ ...column, id: index })),
            ];
          }

          const headers = getHeaders();

          this.workerService.setColumns({ columns: headers });

          return Object.assign(this, {
            page: this.headerParams.page || 1,
            pageSize: userGridConfig.pageSize || this.headerParams.pageSize || 10,
            mainHeader: data.mainDescription,
            gridHeadersCategories: data.categories,
            gridHeaders: headers,
            mongoGridId: data._id,
            sortHeader: userGridConfig.sortField || data.sortable,
            sortDirection: userGridConfig.sortDirection || data.sortableOrder,
            complementHeaderField: data.complementHeaderField,
            replaceHeaderText: data.replaceHeaderText,
          });
        },
        () => {
          this.workerService.setColumns({ columns: [] });
          Object.assign(this, { gridHeaders: [] });
        },
      )
      .catch(() => {
        this.workerService.setColumns({ columns: [] });
        Object.assign(this, { gridHeaders: [] });
      })
      .finally(() => this.$timeout(() => this.$scope.$emit('UPDATE_ROUTE')));
  }

  getActiveRow() {
    return this.activeRow;
  }

  scrollToIndex(index) {
    this.$.querySelector('.table-container').scrollTo({
      top: index * 56,
      behavior: 'smooth',
    });
  }
  /* */

  /* Private */
  async _virtualScrollRender() {
    const scrollableElement = this.$.querySelector('.table-container');

    if (scrollableElement) {
      const rowHeight = 56;
      const nodePadding = 5;
      const visibleNodesCount =
        Math.ceil(scrollableElement.offsetHeight / rowHeight) + 2 * nodePadding;
      const virtualScrollLength = await this.workerService.virtualScrollLength;

      let offsetY = 0;
      let endNode = 0;
      let startNode = Math.floor(scrollableElement.scrollTop / rowHeight) - nodePadding;

      startNode = Math.max(0, startNode);
      offsetY = startNode * rowHeight;
      endNode = startNode + visibleNodesCount;

      this.virtualRows = await this.workerService.getRows({
        to: endNode,
        from: startNode,
      });

      this.$.style.setProperty('--virtual-scroll-header', `-${offsetY}px`);
      this.$.style.setProperty('--virtual-scroll-offset', `${offsetY}px`);
      this.$.style.setProperty(
        '--virtual-scroll-height',
        `${virtualScrollLength * rowHeight + 112}px`,
      );

      if (this.isPaginated) {
        this._backPaginationReferee(startNode, endNode);
      }

      if (!this.$scope.$$phase) {
        this.$scope.$apply();
      }
    }
  }

  async _backPaginationReferee(startNode, endNode) {
    const getDatasetAsync = row => {
      const page = row._index === 0 ? 1 : Math.ceil(row._index / this.pageSize);

      if (!this.requestingPages.includes(page)) {
        this.requestingPages.push(page);

        this.getDataset(this, {
          page,
          async: true,
          keepDataset: true,
          isPaginated: this.isPaginated,
        });
      }
    };

    const [nextRow] = await this.workerService.getRows({
      to: endNode + 1,
      from: endNode,
    });
    const [previousRow] = await this.workerService.getRows({
      to: startNode,
      from: startNode - 1,
    });
    const currentLoadingRows = [...this.virtualRows.filter(row => row._loading)];

    if (nextRow?._loading) {
      getDatasetAsync(nextRow);
    }

    if (previousRow?._loading) {
      getDatasetAsync(previousRow);
    }

    if (currentLoadingRows.length > 0) {
      for (const row of currentLoadingRows) {
        getDatasetAsync(row);
      }
    }
  }

  async _performMobileModeAdjustments() {
    if (this.queryMobile.matches) {
      requestAnimationFrame(() => this._virtualScrollRender());
    }

    this.$.querySelector('.table-container').scrollTo(0, 0);
  }

  _getRows() {
    return this.queryMobile.matches === true ? this.virtualRows : this.pageRows;
  }

  _goToLink(
    index,
    tableRowData,
    routeName,
    routeLink,
    stateFixedParams,
    linkStateConfig,
    getDataMethod,
    backPagination,
    fixedFilters,
  ) {
    this.$scope.$emit('goToLink', {
      index,
      tableRowData,
      routeName,
      routeLink,
      stateFixedParams,
      linkStateConfig,
      getDataMethod,
      backPagination,
      fixedFilters,
    });
  }

  _selectRow(column, row) {
    if (!column.link) {
      return false;
    }

    if (column.linkRoute === 'drilldown' && this.dateGranularity === 'unitario') {
      return false;
    }

    if (
      column.linkRoute === 'record' &&
      (!column.linkParameters ||
        !column.linkParameters.id ||
        !row[column.linkParameters.id] ||
        row[column.linkParameters.id] <= 0)
    ) {
      return false;
    }
    if (row[column.disabledCondition] !== undefined && row[column.disabledCondition] === true) {
      return false;
    }
    if (!column.linkValues || column.linkValues.length === 0) {
      return true;
    }

    return (
      column.linkValues.filter(ele => {
        const eleValue = ele.value || ele;
        const rowValue = ele.column ? row[ele.column] : row[column.field];
        return rowValue == eleValue || (eleValue == 'notNull' && !!rowValue);
      }).length > 0 && !!row[column.field]
    );
  }

  _headerTitle(title) {
    if (this.complementHeaderText && this.replaceHeaderText) {
      return (Array.isArray(title) ? title : [title]).map(item =>
        item.replace(this.replaceHeaderText, this.complementHeaderText),
      );
    }
    return Array.isArray(title) ? title : [title];
  }

  _isSingleColumn(header) {
    return this._getHeaderColumnsCount(header) === 1;
  }

  _calcGridMaxPage(gridTotal, gridPageSize) {
    const pagesBySize = gridTotal / gridPageSize;
    const intPagesBySize = parseInt(pagesBySize, 10);
    return pagesBySize > intPagesBySize ? intPagesBySize + 1 : intPagesBySize;
  }

  _getHeaderRowsCount(header) {
    return header.type === 'Cluster' && !this._isSingleColumn(header) ? 1 : 2;
  }

  _resetScrollPosition() {
    this.$.scrollTo(0, 0);
  }

  _getHeaderColumnsCount(header) {
    if (header.type === 'Cluster') {
      const selector = header.selectors ? header.selectors.find(item => item.selected) : null;

      const columns = header.columns.filter(column => {
        if (!selector) return !column.selector;
        return !column.selector || selector.selector === column.selector;
      });

      return columns.length;
    }

    return 1;
  }
  /* */

  /* Observers */
  async __onMobileMediaQuery() {
    this._performMobileModeAdjustments();
  }

  async __onGridDatasetChanged(gridDataset) {
    if (!gridDataset || gridDataset.length == 0) {
      this.$.setAttribute('empty', '');
      this._resetScrollPosition();
    } else {
      this.$.removeAttribute('empty');

      if (this.queryMobile.matches) {
        requestAnimationFrame(() => this._virtualScrollRender());
      }
    }
  }

  async __onDateGranularityChanged(dateGranularity) {
    await this.workerService.setDateGranularity({ dateGranularity });

    this.pageRows = await this.workerService.pageRows;

    if (this.queryMobile.matches) {
      requestAnimationFrame(() => this._virtualScrollRender());
    }

    if (!this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  __onVirtualScroll() {
    if (!this.queryMobile.matches) return;

    requestAnimationFrame(() => this._virtualScrollRender());
  }

  __onGridHeadersChanged() {
    const gridColumns = (this.gridHeaders || []).reduce((acc, gridHeader) => {
      if (gridHeader.columns && gridHeader.columns.length > 0) {
        let foundEndOfCluster = false;
        let foundStartOfCluster = false;

        gridHeader.columns.forEach((column, index, arr) => {
          const selector = gridHeader.selectors
            ? gridHeader.selectors.find(item => item.selected)
            : null;

          const showColumn = !selector
            ? gridHeader.show && !column.selector
            : gridHeader.show && (!column.selector || selector.selector === column.selector);

          let endOfCluster = false;
          let startOfCluster = false;

          if (showColumn && !foundEndOfCluster) {
            endOfCluster =
              index === arr.length - 1 ||
              (column.selector && column.selector !== arr[index + 1].selector);
            foundEndOfCluster = endOfCluster;
          }
          if (showColumn && !foundStartOfCluster) {
            startOfCluster = true;
            foundStartOfCluster = true;
          }

          Object.assign(column, {
            show: showColumn,
            cluster: gridHeader.field,
            clustered: !this._isSingleColumn(gridHeader),
            endOfCluster,
            startOfCluster,
          });
          acc.push(column);
        });
      } else {
        acc.push(gridHeader);
      }

      return acc;
    }, []);

    Object.assign(this, { gridColumns });
  }
  /* */
}

class PowerGrid {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      hasMaxRows: '=?',
      maxRowsQty: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
      /* complement header title */
      replaceHeaderText: '=?',
      complementHeaderField: '=?',
      complementHeaderText: '=?',
    };
    this.controller = PowerGridController;
  }
}

angular
  .module('power-grid', ['ngSanitize', 'ng-resize'])
  .component('powerGrid', new PowerGrid());

export { PowerGridController, PowerGrid };
