import angular from 'angular';
import moment from 'moment';
import 'ng-redux';
import { isMobile } from 'mobile-device-detect';

import '../power-fab/power-fab';
import '../power-toast/power-toast';
import { PowerReportController } from '../power-report/power-report';
import '../power-dropdown/power-dropdown';
import '../../directives/ng-tippy/ng-tippy';

import template from './power-report-wifi.html';
import './power-report-wifi.scss';

class PowerReportWifiController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
      'crudServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
    crudServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );
    Object.assign(this, { crudServices });

    this.toastText = '';

    this.legacyEquipaments = { list: [], count: 0 };

    this.removeWifi = {};

    this.wifiObj = {};

    this.invalidWifiName = false;

    this.warningMessage =
      ' Os equipamentos são compatíveis apenas com redes Wi-Fi de 2.4 GHz. Certifique-se de que a sua rede esteja configurada corretamente para garantir a melhor experiência de uso.';
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    this.stateConfig.gridConfig.page = 1;
    this.stateConfig.gridConfig.pageSize = 10;
    this._compatibleVehiclesWifi();

    this.$ngRedux.dispatch({ type: 'UPDATE_STATE' });
    this._getConfigurations();
  }

  $onDestroy() {
    this.__appBehavior();
  }
  /* */

  requestDataset() {
    this.stateConfig.gridConfig.page = 1;
    this._requestDataset();
  }

  _getConfigurations() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this._getStateConfig();

    this.$scope.$on('getDatasetReady', () => {
      this._requestDataset();
    });
  }

  _requestDataset() {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    const payload = {
      request: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
        page: this.stateConfig.gridConfig.page - 1,
        length: this.stateConfig.gridConfig.pageSize,
      },
    };

    this.$http({
      url: `${this.urlApi}/wifi/Post`,
      method: 'POST',
      data: payload,
    }).then(success => {
      const { data } = success.data;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: false },
          bubbles: true,
          composed: true,
        }),
      );

      if (success.status && success.status !== 200) {
        this.$.querySelector('power-toast#wifi-toast').toggle(true);
        Object.assign(this, { toast: { text: data.msg } });
        return;
      }

      Object.assign(this.stateConfig.gridConfig, {
        gridTotal: data.total,
        lastPage: this._getGridMaxPage(data.total, this.stateConfig.gridConfig.pageSize),
        gridDataset: data,
      });

      this.dataset = data.data;
    });
  }

  _changePage(page) {
    if (page >= 0) this.stateConfig.gridConfig.page = page;
    if (this.stateConfig.gridConfig.backPagination) this._requestDataset();
  }

  _changePageSize(pageSize) {
    Object.assign(this.stateConfig.gridConfig, {
      page: 1,
      pageSize,
    });

    if (this.stateConfig.gridConfig.backPagination) this._requestDataset();
  }

  _getGridMaxPage(gridTotal, pageSize) {
    const pagesBySize = gridTotal / pageSize;
    const intPagesBySize = parseInt(pagesBySize, 10);
    return pagesBySize > intPagesBySize ? intPagesBySize + 1 : intPagesBySize;
  }

  _compatibleVehiclesWifi() {
    this.crudServices.callApiMethod('/Wifi/GetCompatibleVehicles', {}).then(success => {
      this.legacyEquipaments.count = success.data.data.length;
      this.legacyEquipaments.list = success.data.data;
    });
  }

  _saveWifi() {
    console.log(this.wifiSsid);
    if (this.wifiObj.wifiSsid !== undefined && this.wifiObj.wifiSsid !== '') {
      this.crudServices.callApiMethod('/Wifi/UpsertWifi', this.wifiObj).then(success => {
        if (success.status == 200) {
          this.toastText = `Wi-fi ${this.wifiObj.wifiId ? 'editado' : 'cadastrado'} com sucesso.`;
          this.$.querySelector('power-toast#wifi-toast').toggle(true);
          this.wifiObj = {};
          this._requestDataset();
          this.$.querySelector('#popup-control-alert').toggle();
        } else {
          this.toastText = 'Erro ao salvar Wi-fi';
        }
        this.$.querySelector('power-toast#wifi-toast').toggle(true);
        Object.assign(this, { toast: { text: this.toastText } });
      });
    } else {
      this.$.querySelector('power-toast#wifi-toast').toggle(true);
      Object.assign(this, { toast: { text: 'Preencha o nome da Rede Wifi' } });
      this.invalidWifiName = true;
    }
  }

  _removeWifi() {
    const payload = { id: this.removeWifi.wifiId };
    this.commonServices.genericRequest('/Wifi/RemoveWifi', payload).then(success => {
      if (success.status == 200) {
        this.toastText = `Wi-fi ${this.removeWifi.wifiSsid} excluído com sucesso.`;
        this.$.querySelector('power-toast#wifi-toast').toggle(true);
        this._requestDataset();
        this.$.querySelector('#popup-confirmation').toggle();
      } else {
        this.toastText = 'Erro ao excluir wi-fi.';
      }
      this.$.querySelector('power-toast#wifi-toast').toggle(true);
      Object.assign(this, { toast: { text: this.toastText } });
    });
  }

  _changeVisiblePassword() {
    const inputPass = document.querySelector('.text-password');
    if (inputPass.getAttribute('type') == 'text') {
      inputPass.setAttribute('type', 'password');
    } else {
      inputPass.setAttribute('type', 'text');
    }
  }

  _showToastWarningMessage() {
    if (isMobile) {
      Object.assign(this, { toast: { text: this.warningMessage } });
      this.$.querySelector('power-toast#wifi-toast').toggle(true);
    }
  }

  evtClickBtnPopup(type, popupId, dataset) {
    if (type == 'open') {
      switch (popupId) {
        case 'popup-control-alert': {
          this.invalidWifiName = false;
          if (dataset) {
            this.wifiObj = { ...dataset };
          }
          this.$.querySelector('#popup-control-alert').toggle();
          break;
        }
        case 'popup-legacy-equipament': {
          this.$.querySelector('#popup-legacy-equipament').toggle();
          break;
        }
        case 'popup-confirmation': {
          this.removeWifi = { ...dataset };
          this.$.querySelector('#popup-confirmation').toggle();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'close') {
      switch (popupId) {
        case 'popup-control-alert': {
          this.$.querySelector('#popup-control-alert').toggle();
          this.wifiObj = {};
          break;
        }
        case 'popup-legacy-equipament': {
          this.$.querySelector('#popup-legacy-equipament').toggle();
          break;
        }
        case 'popup-confirmation': {
          this.$.querySelector('#popup-confirmation').toggle();
          break;
        }
        default: {
          break;
        }
      }
    } else if (type == 'primaryBtn') {
      switch (popupId) {
        case 'popup-control-alert': {
          this._saveWifi();
          break;
        }
        case 'popup-confirmation': {
          this._removeWifi();
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  evtClickSwitchButtonControl(card) {
    const payload = {
      wifiId: card.wifiId,
      wifiAtivo: card.wifiAtivo,
    };
    this.crudServices.callApiMethod('/Wifi/ActiveInactiveWifi', payload).then(
      success => {
        if ((success.data && success.data.hasError) || !success.data.data) {
          this.$.querySelector('power-toast#wifi-toast').toggle(true);
          Object.assign(this, {
            toast: { text: `Erro ao ${card.wifiAtivo ? 'ativar' : 'inativar'} wifi.` },
          });
          return;
        }
        this.$.querySelector('power-toast#wifi-toast').toggle(true);
        Object.assign(this, {
          toast: {
            text: `Wi-fi ${card.wifiSsid} ${card.wifiAtivo ? 'ativa' : 'inativada'} com sucesso.`,
          },
        });
      },
      error => {
        console.log(error);
        this.$.querySelector('power-toast#wifi-toast').toggle(true);
        Object.assign(this, { toast: { text: 'Ops! Falha ao salvar, tente novamente.' } });
        card.wifiAtivo = !card.wifiAtivo; // Volta ao status anterior
      },
    );
  }

  toDate(date, format) {
    return moment(date)
      .utcOffset(-3 - moment(date).utcOffset() / 60)
      .format(format || 'DD/MM/YYYY');
  }
}

class PowerReportWifi {
  constructor() {
    this.template = template;
    this.bindings = {
      cardDataset: '=?',
    };
    this.controller = PowerReportWifiController;
  }
}
angular
  .module('power-report-wifi', [
    'ngRedux',
    'power-fab',
    'power-dropdown',
    'ng-tippy',
    'power-toast',
  ])
  .component('powerReportWifi', new PowerReportWifi());
