import { app } from '../app.module';

import { hasTripConfiguration, hasApplicationConfiguration } from '../configs/app/system.config';
import dashboardConfigs from '../configs/app/dashboard.config';
import filterConfigs from '../configs/app/filter.config';
import formConfigs from '../configs/app/form.config';
import gridConfigs from '../configs/app/grid.config';
import toolbarConfigs from '../configs/app/toolbar.config';
import recordConfigs from '../configs/app/record.config';

/* CommonServices */
class CommonServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return [
      '$ngRedux',
      '$http',
      'deviceDetector',
      'urlApi',
      'tipoApp',
      '_applications',
      '$transitions',
    ];
  }

  constructor($ngRedux, $http, deviceDetector, urlApi, tipoApp, _applications, $transitions) {
    Object.assign(this, { $http, deviceDetector, urlApi, tipoApp, _applications, $transitions });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
        applicationType: store.state.applicationType,
      }),
    )(this);
  }

  async platformLog(logtype) {
    return this.$http({
      url: `${this.urlApi}/Log/CreateLogLoginAccess`,
      method: 'POST',
      data: Object.assign(logtype, {
        tipoApp: this.tipoApp,
        browser: this.deviceDetector.browser,
        browserVersion: this.deviceDetector.browser_version,
        applicationType: this.applicationType,
        operatingSystem: this.deviceDetector.os,
      }),
    }).then(
      success => success,
      error => error,
    );
  }

  registerDeviceId() {
    return this.$http({
      url: `${this.urlApi}/User/RegisterDeviceId`,
      method: 'POST',
      data: { deviceId: '$ngRedux' },
    }).then(
      success => success,
      error => error,
    );
  }

  getSvg(imgUrl, callback) {
    return this.$http({
      url: imgUrl,
      method: 'GET',
    }).then(
      success => (typeof callback === 'function' ? callback(success.data) : success),
      error => error,
    );
  }

  recoverPassword(json) {
    return this.$http({
      url: `${this.urlApi}/User/RedefinePassword`,
      method: 'POST',
      data: json,
    }).then(
      success => success,
      error => error,
    );
  }

  saveImage(method, json) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: json,
    }).then(
      success => success,
      error => error,
    );
  }

  systemSearch(text) {
    return this.$http({
      url: `${this.urlApi}/GeneralSearch/Search`,
      method: 'POST',
      data: { search: text },
    }).then(
      success => success,
      error => error,
    );
  }

  genericRequest(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  getLegacyEquipaments(method) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
    }).then(
      success => success,
      error => error,
    );
  }

  clickCountLegacyEquipaments() {
    return this.$http({
      url: `${this.urlApi}/User/IncreaseClickCountLegacyEquipaments`,
      method: 'POST',
    }).then(
      success => success,
      error => error,
    );
  }

  enableOrDisableSupportTip() {
    return this.$http({
      url: `${this.urlApi}/User/EnableOrDisableSupportTip`,
      method: 'POST',
    }).then(
      success => success,
      error => error,
    );
  }

  supportTip() {
    return this.$http({
      url: `${this.urlApi}/User/SupportTip`,
      method: 'POST',
    }).then(
      success => success,
      error => error,
    );
  }

  getToolbarOptions(screen, name, callback) {
    return new Promise((resolve, reject) => {
      let configName = `ToolBar_${screen}`;
      if (hasApplicationConfiguration(this.session.application, screen, 'Toolbar')) {
        configName = `${configName}_${
          this._applications.find(item => item.id === this.session.application).name
        }`;
      } else if (this.session.isTrip && hasTripConfiguration(screen)) {
        configName = `${configName}_Trip`;
      }
      configName = `${configName}_${name}`;
      const config = toolbarConfigs[configName];

      if (config) {
        if (typeof callback === 'function') {
          resolve(callback(Object.clone({}, config)));
        } else {
          resolve(Object.clone({}, config));
        }
      } else {
        reject(new Error('ToolBar > getToolbarOptions Error'));
      }
    });
  }

  getGridHeaders({ screenName, entityName, gridName }) {
    const getGridConfiguration = options => {
      let configName = `Grid_${options.id}`;
      if (hasApplicationConfiguration(this.session.application, options.id, 'Grid')) {
        configName = `${configName}_${
          this._applications.find(item => item.id === this.session.application).name
        }_${options.gridName}`;
      } else {
        configName = `${configName}_${
          options.isTrip && hasTripConfiguration(options.screenName)
            ? `Trip_${options.gridName}`
            : options.gridName
        }`;
        if (!gridConfigs[configName]) {
          configName = `Grid_${options.id}_${options.gridName}`;
        }
      }
      return gridConfigs[configName];
    };

    return new Promise((resolve, reject) => {
      if (entityName) {
        const gridConfiguration = getGridConfiguration({
          id: `${screenName}${entityName}`,
          isTrip: this.session.isTrip,
          gridName,
          screenName,
          entityName,
        });

        if (gridConfiguration) {
          resolve(Object.clone({}, gridConfiguration));
        }
      }

      const gridConfiguration = getGridConfiguration({
        id: screenName,
        isTrip: this.session.isTrip,
        gridName,
        screenName,
        entityName,
      });

      if (gridConfiguration) {
        resolve(Object.clone({}, gridConfiguration));
      } else {
        reject(new Error('Grid > getGridHeaders Error'));
      }
    });
  }

  getFormConfiguration(name) {
    return new Promise((resolve, reject) => {
      let configName = `Form_${name}`;
      if (hasApplicationConfiguration(this.session.application, name, 'Form')) {
        configName = `${configName}_${
          this._applications.find(item => item.id === this.session.application).name
        }`;
      } else if (this.session.isTrip && hasTripConfiguration(name)) {
        configName = `${configName}_Trip`;
      }
      const config = formConfigs[configName];

      if (config) {
        resolve(Object.clone({}, config));
      } else {
        reject(new Error('Form > getFormConfiguration Error'));
      }
    });
  }

  checkNewAnnouncementsLogin(loginId) {
    return new Promise(resolve => {
      this.$http({
        url: `${this.urlApi}/Announcement/CheckNewAnnouncements`,
        method: 'POST',
        data: {
          request: {},
        },
      }).then(
        success => {
          resolve(success.status === 200 && success.data.data);
        },
        error => error,
      );
      window.localStorage.setItem(`last-announcement-check-${loginId}`, new Date());
    });
  }

  needCheckAnnouncements(token, needSelectAccount, loginId) {
    const configItem = window.localStorage.getItem(`last-announcement-check-${loginId}`);

    if (configItem) {
      const lastAnnouncementCheckDate = new Date(
        window.localStorage.getItem(`last-announcement-check-${loginId}`),
      );
      const actualDate = new Date();
      const diffDays = actualDate.getDate() - lastAnnouncementCheckDate.getDate();
      if (token && !needSelectAccount && diffDays > 0) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }
}
app.factory('commonServices', CommonServices);
/* */

/* FilterServices */
class FilterServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', 'urlApi', '_applications'];
  }

  constructor($ngRedux, $http, urlApi, _applications) {
    Object.assign(this, { $ngRedux, $http, urlApi, _applications });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__appStore();
  }

  getPowerFilters(screenName, gridName) {
    return new Promise((resolve, reject) => {
      let configName = `Filter_${screenName}`;
      if (hasApplicationConfiguration(this.session.application, screenName, 'Filter')) {
        configName = `${configName}_${
          this._applications.find(item => item.id === this.session.application).name
        }`;
      } else if (this.session.isTrip && hasTripConfiguration(screenName)) {
        configName = `${configName}_Trip`;
      }

      configName = `${configName}_${gridName}`;
      const config = filterConfigs[configName];

      if (config) {
        const filters = config.filters.reduce((acc, item) => {
          if (item.hideInSSO && this.session.isSingleSignon) return acc;
          return acc.concat(item);
        }, []);

        resolve(Object.clone({}, { ...config, filters }));
      } else {
        reject(new Error('Filter > getPowerFilters Error'));
      }
    });
  }

  getData(method, json) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: json,
    });
  }
}
app.service('filterServices', FilterServices);
/* */

/* ReportServices */
class ReportServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi'];
  }

  constructor($ngRedux, $http, $timeout, urlApi) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__appStore();
  }

  getGridMaxPage(gridTotal, pageSize) {
    const pagesBySize = gridTotal / pageSize;
    const intPagesBySize = parseInt(pagesBySize, 10);
    return pagesBySize > intPagesBySize ? intPagesBySize + 1 : intPagesBySize;
  }

  getPowerGridHeaders(screenName, gridName) {
    return this.$http({
      url: `${this.urlApi}/GridConfiguration/GetGridConfiguration`,
      method: 'POST',
      data: { screenName, gridName },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success.data.data;
      },
      error => error,
    );
  }

  getPowerGridTable() {
    const paginatedPayload = {
      ...this.stateConfig.getDataFixedParams,
      isPaginated: this.stateConfig.gridConfig.backPagination,
      page: this.stateConfig.gridConfig.page - 1,
      length: this.stateConfig.gridConfig.pageSize,
      sort: {
        name: this.stateConfig.gridConfig.sortField,
        direction: this.stateConfig.gridConfig.sortDirection,
      },
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
    };
    return this.$http({
      url: `${this.urlApi}/${this.stateConfig.getDataMethod}`,
      method: 'POST',
      data: { request: paginatedPayload },
    }).then(
      success => {
        if (success.status && success.status != 200) return;
        if (this.stateConfig.gridConfig.page == 0) this.stateConfig.gridConfig.page = 1;
        const dataConfig = { ...success.data.data.config };
        if (dataConfig && this.stateConfig.gridConfig.gridMongoId != dataConfig._id) {
          Object.assign(this.stateConfig.gridConfig, {
            gridMongoId: dataConfig._id,
            gridHeaders: dataConfig.columns,
            descriptionField: dataConfig.mainDescription,
            sortField: dataConfig.sortable,
            sortDirection: dataConfig.sortableOrder,
          });
        }
        Object.assign(this.stateConfig.gridConfig, {
          gridTotal: success.data.data.total,
          lastPage: this.getGridMaxPage(
            success.data.data.total,
            this.stateConfig.gridConfig.pageSize,
          ),
          gridDataset: success.data.data.data.map(data => Object.assign(data, { selected: false })),
        });
        this.$ngRedux.dispatch({ type: 'UPDATE_STATE' });
      },
      error => error,
    );
  }

  genericRequest(method, payload) {
    if (method[0] === '/') {
      method = method.slice(1, method.length);
    }

    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  getExport(
    exportOptions,
    getExportMethod,
    gridTotal,
    getDataMethodFixedParams,
    gridName,
    gridHeaders,
    gridSort,
    stateComputedFilters,
  ) {
    const requestTime = new Date();
    const request = {
      ...getDataMethodFixedParams,
      isPaginated: true,
      page: 0,
      length: gridTotal,
      sort: gridSort,
      filter: { conditions: stateComputedFilters },
      exportOptions,
    };
    const payload = {
      name: gridName,
      columns: gridHeaders,
      requestTime: new Date(requestTime - requestTime.getTimezoneOffset() * 60000),
      request,
    };
    return this.$http({
      url: `${this.urlApi}/${getExportMethod}`,
      method: 'POST',
      data: payload,
    }).then(
      async success => {
        const blob = await (
          await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
        ).blob();
        const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
        const downloadLink = document.createElement('a');

        downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
        downloadLink.href = fileUrl;
        downloadLink.click();
      },
      error => error,
    );
  }

  sendFileManualEntry(urlMethod, data) {
    return this.$http({
      url: `${this.urlApi}/${urlMethod}`,
      method: 'POST',
      data,
      headers: { 'Content-Type': undefined },
    });
  }

  saveUserReportConfiguration(data) {
    const {
      _id,
      module,
      reportName,
      fileOffsetWidth,
      sharedUsers,
      filterConfig,
      gridConfig,
      owner,
    } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/UpsertUserReportConfiguration`,
      method: 'POST',
      data: {
        request: {
          _id,
          module,
          reportName,
          fileOffsetWidth,
          sharedUsers,
          filterConfig,
          gridConfig,
          owner,
        },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }

  getUserReportConfiguration(data) {
    const { _id } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/GetUserReportConfiguration`,
      method: 'POST',
      data: {
        request: { _id },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }

  getAllUserReportConfigurations(data) {
    const { module, limit } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/GetAllUserReportConfiguration`,
      method: 'POST',
      data: {
        request: { module, limit },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }

  deleteUserReportConfiguration(data) {
    const { _id } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/DeleteUserReportConfiguration`,
      method: 'POST',
      data: {
        request: { _id },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }

  updateUserReportConfigurationIsMenuItem(data) {
    const { _id, isMenuItem } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/UpdateIsMenuItemUserReportConfiguration`,
      method: 'POST',
      data: {
        request: { _id, isMenuItem },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }

  removeSharingUserReportConfiguration(data) {
    const { reportId, userId } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/RemoveSharing`,
      method: 'POST',
      data: {
        request: { reportId, userId },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success.data.data;
      },
      error => error,
    );
  }

  upsertUserReportShares(data) {
    const { reports, editors, viewers, excluded } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/UpsertUserReportShares`,
      method: 'POST',
      data: {
        request: { reports, editors, viewers, excluded },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success.data.data;
      },
      error => error,
    );
  }

  saveAutoSendConfiguration(data) {
    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/UpsertUserReportAutoSend`,
      method: 'POST',
      data: {
        request: { ...data },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }

  deleteAutoSendConfiguration(data) {
    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/DeleteUserReportAutoSend`,
      method: 'POST',
      data: {
        request: { ...data },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }

  getAllAutoSendConfigurations(data) {
    const { userReportConfigurationId, limit } = data;

    return this.$http({
      url: `${this.urlApi}/UserReportConfiguration/GetAllUserReportAutoSend`,
      method: 'POST',
      data: {
        request: {
          userReportConfigurationId,
          limit,
        },
      },
    }).then(
      success => {
        if (success.status && success.status != 200) return null;
        return success;
      },
      error => error,
    );
  }
}
app.service('reportServices', ReportServices);
/* */

/* DashboardServices */
class DashboardServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi', '_applications'];
  }

  constructor($ngRedux, $http, $timeout, urlApi, _applications) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi, _applications });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__appStore();
  }

  getDashboardConfig(screen) {
    return new Promise((resolve, reject) => {
      let configName = `Dashboard_${screen}`;
      if (hasApplicationConfiguration(this.session.application, screen, 'Dashboard')) {
        configName = `${configName}_${
          this._applications.find(item => item.id === this.session.application).name
        }`;
      } else if (this.session.isTrip && hasTripConfiguration(screen)) {
        configName = `${configName}_Trip`;
      }
      const config = dashboardConfigs[configName];

      if (config) {
        resolve(Object.clone({}, config));
      } else {
        reject(new Error('Dashboard > getDashboardConfig Error'));
      }
    });
  }

  getVariablesConfig(isDefault) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetVariablesConfig`,
      method: 'POST',
      data: { default: isDefault || false },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetVariablesConfig Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  setVariablesConfig(variables) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/UpsertVariablesConfig`,
      method: 'POST',
      data: {
        request: {
          valorRendMov: variables.valorRendMov || 0,
          valorCustoCom: variables.valorCustoCom || 0,
          valorSalario: variables.valorSalario || 0,
          valorHoraMes: variables.valorHoraMes || 0,
        },
      },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > UpsertVariablesConfig Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getFleetPolicyStatus(isDefault) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetFleetPolicyStatus`,
      method: 'POST',
      data: { default: isDefault || false },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetFleetPolicyStatus Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getGenericData(module, method, data) {
    return this.$http({
      url: `${this.urlApi}/${module}/${method}`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(
            'Dashboard > GetGenericData ' +
              `(${module}/${method}) ` +
              `Api Error: ${success.status}`,
          );
        return success.data;
      },
      error => error,
    );
  }

  getGraphic(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetGraphicResult`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetGraphicResult Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getIndicatorsEvolution(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetIndicatorsEvolutionResult`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetIndicatorsEvolutionResult Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getTravelledDistanceCost(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetTravelledDistanceCostResult`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetTravelledDistanceCostResult Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getViolationCost(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetViolationCostResult`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetViolationCostResult Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getViolation(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetViolationResult`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetViolationResult Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  getViolationExport(data, exportMethod) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/${exportMethod}`,
      method: 'POST',
      data: { request: data },
    }).then(
      async success => {
        const blob = await (
          await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
        ).blob();
        const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
        const downloadLink = document.createElement('a');

        downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
        downloadLink.href = fileUrl;
        downloadLink.click();
      },
      error => error,
    );
  }

  getMaxViolations(data) {
    return this.$http({
      url: `${this.urlApi}/Dashboard/GetMaxViolationsResult`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetMaxViolationsResult Api Error: ${success.status}`);
        return success.data.data;
      },
      error => error,
    );
  }

  sendFileManualEntry(data) {
    return this.$http({
      url: `${this.urlApi}/FuelModule/UploadFile`,
      method: 'POST',
      data,
      headers: { 'Content-Type': undefined },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > UploadFile Api Error: ${success.status}`);
        return success.data;
      },
      error => error,
    );
  }

  getConsumptionPreset(data) {
    return this.$http({
      url: `${this.urlApi}/FuelModule/GetConsumptionPreset`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetConsumptionPreset Api Error: ${success.status}`);
        return success.data;
      },
      error => error,
    );
  }

  validateConsumptionPreset(data) {
    return this.$http({
      url: `${this.urlApi}/FuelModule/ValidateConsumptionPreset`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > ValidateConsumptionPreset Api Error: ${success.status}`);
        return success.data;
      },
      error => error,
    );
  }

  saveConsumptionPreset(data) {
    return this.$http({
      url: `${this.urlApi}/FuelModule/SaveConsumptionPreset`,
      method: 'POST',
      data: { request: data },
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > SaveConsumptionPreset Api Error: ${success.status}`);
        return success.data;
      },
      error => error,
    );
  }

  getMainChartDriver(data) {
    return this.$http({
      url: `${this.urlApi}/DriverModule/GetGraphData`,
      method: 'POST',
      data,
    }).then(
      success => {
        if (success.status && success.status != 200)
          throw Error(`Dashboard > GetGraphData Api Error: ${success.status}`);
        return success.data;
      },
      error => error,
    );
  }
}
app.service('dashboardServices', DashboardServices);
/* */

/* CrudServices */
class CrudServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi'];
  }

  constructor($ngRedux, $http, $timeout, urlApi) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__appStore();
  }

  getData(method, json) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: json,
    }).then(
      success => success.data.data,
      error => error,
    );
  }

  callApiMethod(method, json) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: { request: json },
    }).then(
      success => success,
      error => error,
    );
  }

  callDeleteMethod(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }
}
app.service('crudServices', CrudServices);
/* */

/* RecordServices */
class RecordServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi', '_applications'];
  }

  constructor($ngRedux, $http, $timeout, urlApi, _applications) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi, _applications });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__appStore();
  }

  getRecordConfig(recordName) {
    return new Promise((resolve, reject) => {
      let configName = `Record_${recordName}`;
      if (hasApplicationConfiguration(this.session.application, recordName, 'Record')) {
        configName = `${configName}_${
          this._applications.find(item => item.id === this.session.application).name
        }`;
      } else if (this.session.isTrip && hasTripConfiguration(recordName)) {
        configName = `${configName}_Trip`;
      }
      const config = recordConfigs[configName];

      if (config) {
        resolve(Object.clone({}, config));
      } else {
        reject(new Error('Record > getRecordConfig Error'));
      }
    });
  }

  getRecordData(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  getSharedList(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  requestAction(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  callApiMethod(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  callShareMethod(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  callEditMethod(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: payload,
    });
  }

  callDeleteMethod(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }
}
app.service('recordServices', RecordServices);
/* */

/* IssueTrackerServices */
class IssueTrackerServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi'];
  }

  constructor($ngRedux, $http, $timeout, urlApi) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  $onDestroy() {
    this.__appStore();
  }

  callApiMethod(method, json) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: { request: json },
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('issueTrackerServices', IssueTrackerServices);

/* SwitchAccount */
class SwitchAccountServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$http', 'urlApi'];
  }

  constructor($http, urlApi) {
    Object.assign(this, { $http, urlApi });
  }

  getAccounts(payload) {
    return this.$http({
      url: `${this.urlApi}/Account/Accounts`,
      method: 'POST',
      data: { request: payload },
    }).then(
      success => success,
      error => error,
    );
  }

  switchAccount(payload) {
    return this.$http({
      url: `${this.urlApi.replace('/api', '')}/token`,
      method: 'POST',
      headers: {
        Accept: 'application/json, application/xml, text/plain, text/html',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  updateAccountDefault(payload) {
    return this.$http({
      url: `${this.urlApi}/Account/UpdateAccountDefault`,
      method: 'POST',
      data: { request: payload },
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('switchAccountServices', SwitchAccountServices);

/* MenuConfiguration */
class MenuConfigurationServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$http', 'urlApi'];
  }

  constructor($http, urlApi) {
    Object.assign(this, { $http, urlApi });
  }

  getMenuConfiguration() {
    return this.$http({
      url: `${this.urlApi}/MenuConfiguration/GetMenuConfiguration`,
      method: 'POST',
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('menuConfigurationServices', MenuConfigurationServices);
/* */

/* UserServices */
class UserServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$http', 'urlApi'];
  }

  constructor($http, urlApi) {
    Object.assign(this, { $http, urlApi });
  }

  CreateUserEmails(payload) {
    return this.$http({
      url: `${this.urlApi}/User/CreateUserEmails`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }

  GetAllUsers(payload) {
    return this.$http({
      url: `${this.urlApi}/User/GetAllUsers`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }
}
app.service('userServices', UserServices);
/* */

/* AddonServices */
class AddonServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$http', 'urlApi'];
  }

  constructor($http, urlApi) {
    Object.assign(this, { $http, urlApi });
  }

  requestAddon(payload) {
    return this.$http({
      url: `${this.urlApi}/PurchaseAddon/Send`,
      method: 'POST',
      data: { request: payload },
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('addonServices', AddonServices);

/* MaintenanceServices */
class MaintenanceServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi'];
  }

  constructor($ngRedux, $http, $timeout, urlApi) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  callApiMethod(method, json) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: json,
    }).then(
      success => success,
      error => error,
    );
  }

  callDeleteMethod(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }
}
app.service('maintenanceServices', MaintenanceServices);
/* */

/* WorkshopServices */
class WorkshopServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$ngRedux', '$http', '$timeout', 'urlApi'];
  }

  constructor($ngRedux, $http, $timeout, urlApi) {
    Object.assign(this, { $ngRedux, $http, $timeout, urlApi });

    this.__appStore = $ngRedux.connect(store =>
      Object({
        state: store.state,
        session: store.session,
      }),
    )(this);
  }

  upsertWorkshop(payload) {
    return this.$http({
      url: `${this.urlApi}/Workshop/UpsertWorkshop`,
      method: 'POST',
      data: {
        request: payload,
      },
    }).then(
      success => success,
      error => error,
    );
  }

  validateWorkshopDelete(payload) {
    return this.$http({
      url: `${this.urlApi}/Workshop/ValidateDeleteWorkshop`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  deleteWorkshop(method, payload) {
    return this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('workshopServices', WorkshopServices);
/* */

/* AddonServices */
class TrackingRealTimeServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$http', 'urlApi'];
  }

  constructor($http, urlApi) {
    Object.assign(this, { $http, urlApi });
  }

  activeInactiveRealTimePermission(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicleAdm/ActiveInactiveRealTimePermission`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  getRealTimeChangePermissionHistory(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicleAdm/GetRealTimeChangePermissionHistory`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  getMessagesAndViewersHistory(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicle/GetMessagesAndViewersHistory`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  getConfigRealTimeVechicle(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicle/GetConfigRealTimeVechicle`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  getReasonExcepcionalConfigList() {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicle/GetReasonExcepcionalConfigList`,
      method: 'POST',
      data: {},
    }).then(
      success => success,
      error => error,
    );
  }

  getMessagesChat(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicle/GetMessagesChat`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  activeRealTime(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicle/ActiveRealTimeVehicle`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  changeReasonRealTimeVehicle(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicle/ChangeReasonRealTimeVehicle`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }

  addRemoveTimeRealTimeVehicle(payload) {
    return this.$http({
      url: `${this.urlApi}/RealTimeVehicle/AddRemoveTimeRealTimeVehicle`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('trackingRealTimeServices', TrackingRealTimeServices);
/* */

/* IdentificationTypeServices */
class IdentificationTypeServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$http', 'urlApi'];
  }

  constructor($http, urlApi) {
    Object.assign(this, { $http, urlApi });
  }

  getIdentificationType() {
    return this.$http({
      url: `${this.urlApi}/IdentificationType/GetIdentificationType`,
      method: 'POST',
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('identificationTypeServices', IdentificationTypeServices);
/* */

/* Driver */
class DriverServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['$http', 'urlApi'];
  }

  constructor($http, urlApi) {
    Object.assign(this, { $http, urlApi });
  }

  createUserAppDriver(json) {
    return this.$http({
      url: `${this.urlApi}/driver/CreateUserAppDriver`,
      method: 'POST',
      data: { request: json },
    }).then(
      success => success,
      error => error,
    );
  }

  createUserDriverId(json) {
    return this.$http({
      url: `${this.urlApi}/driver/CreateUserDriverId`,
      method: 'POST',
      data: { request: json },
    }).then(
      success => success,
      error => error,
    );
  }

  removeAccessApp(payload) {
    return this.$http({
      url: `${this.urlApi}/driver/DeleteUserAppDriver`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }

  removeGolfleetIdAccessApp(payload) {
    return this.$http({
      url: `${this.urlApi}/driver/DeleteUserAppGolfleetId`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }

  toggleDriverIa(payload) {
    return this.$http({
      url: `${this.urlApi}/driver/UpsertDriverIa`,
      method: 'POST',
      data: payload,
    }).then(
      success => success,
      error => error,
    );
  }
}
app.service('driverServices', DriverServices);

/* Driver */
class ScriptServices {
  static get $$ngIsClass() {
    return true;
  }

  static get $inject() {
    return ['urlApi'];
  }

  constructor(urlApi) {
    Object.assign(this, { urlApi });
  }

  loadJsScript(urlApi) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = urlApi;

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    return script;
  }
}
app.service('scriptServices', ScriptServices);
/* */
