import angular from 'angular';

import { PowerCardListController } from '../power-card-list/power-card-list';

import template from './power-card-utilization-list.html';
import './power-card-utilization-list.scss';

export const IdentificationTypeEnum = {
  FACEID_IDENTIFICATION: 3,
  IDENTIFICATION_IMAGE_PENDING: 4,
};

export const IdentificationTypeIconEnum = {
  1: 'insert_link',
  2: 'gs_rfid',
  5: 'bluetooth',
  6: 'gs_ia_rec',
  7: 'gs_ia_rec',
};

class PowerCardUtilizationListController extends PowerCardListController {
  static get $inject() {
    return ['$element', '$scope', '$rootScope', '$ngRedux', '$state', '$http', 'urlApi'];
  }

  constructor($element, $scope, $rootScope, $ngRedux, $state, $http, urlApi) {
    super($element, $scope, $rootScope, $state);

    Object.assign(this, { $: $element[0], $scope, $rootScope, $ngRedux, $state, $http, urlApi });

    this._appBehavior = $ngRedux.connect(behavior =>
      Object({
        /* Session Storage */
        isSingleSignon: behavior.session.isSingleSignon,
        isTrainingMode: behavior.session.trainingMode,
      }),
    )(this);

    this.photoBlur = '';
    this.showDetails = true;
    this.auxDriverFacesCards = [];
  }

  $onInit() {
    super.$onInit();

    Object.assign(this.$, {
      setShowDetails: this.setShowDetails.bind(this),
      refreshCards: this.refreshCards.bind(this),
    });

    if (this.isTrainingMode) {
      this.photoBlur = 'blurred';
    }
  }

  $onDestroy() {
    this._appBehavior();
  }

  setShowDetails({ showDetails }) {
    this.showDetails = showDetails;
    this._requestUpdate();
  }

  refreshCards() {
    super._virtualScrollRender();
    this._requestUpdate();
  }

  async _virtualScrollRender() {
    const { startNode, endNode } = await super._virtualScrollRender();

    await this._requestDriverFaces();

    return { startNode, endNode };
  }

  async _requestDriverFaces() {
    const driverFacesPromises = [];

    if (this.resetVirtualCardList) {
      this.auxDriverFacesCards = [];
    }

    this.virtualCards.forEach(item => {
      const data = this.auxDriverFacesCards.find(d => d._index === item._index);
      if (data) {
        item._driverFaces = { ...data._driverFaces, selected: item._driverFaces.selected };
      }
    });

    for (const data of this.virtualCards) {
      driverFacesPromises.push(
        new Promise(resolve => {
          if (!data._driverFaces) {
            resolve(data);
            return;
          }

          if (!data.tipoIdentificacaoCondutor) {
            data._driverFaces.error = true;
            data._driverFaces.ready = true;
            resolve(data);
            return;
          }

          if (
            data.tipoIdentificacaoCondutor &&
            data.condutorNome &&
            ![
              IdentificationTypeEnum.FACEID_IDENTIFICATION,
              IdentificationTypeEnum.IDENTIFICATION_IMAGE_PENDING,
            ].includes(data.tipoIdentificacaoCondutor)
          ) {
            data._driverFaces.error = true;
            data._driverFaces.ready = true;
            data._driverFaces.showName = true;
            data._driverFaces.defaultIcon =
              IdentificationTypeIconEnum[data.tipoIdentificacaoCondutor];
            resolve(data);
          }

          // if (data.tipoIdentificacaoCondutor === 1 && data.condutorNome) {
          //   data._driverFaces.error = false;
          //   data._driverFaces.ready = true;
          //   resolve(data);
          //   return;
          // }

          if (data._driverFaces.ready) {
            resolve(data);
            return;
          }

          this.$http({
            url: `${this.urlApi}/FleetCam/GetDriversFace`,
            method: 'POST',
            data: {
              request: {
                vehicleId: data.veiculoId,
                identificationType: data.tipoIdentificacaoCondutor,
                beginDate: this._parseDate(new Date(data.dataHoraIgnicaoLigada)),
                endDate: this._parseDate(
                  new Date(
                    data.dataHoraFimViagem ||
                      data.dataHoraIgnicaoDesligada ||
                      data.dataHoraUltimaPosicao,
                  ),
                ),
              },
            },
          })
            .then(result => {
              if (result.data.hasError) {
                data._driverFaces.error = true;
              } else {
                data._driverFaces.data = [...result.data.data];
              }
              data._driverFaces.ready = true;
            })
            .catch(() => {
              data._driverFaces.error = true;
              data._driverFaces.ready = true;
            })
            .finally(() => {
              resolve(data);
            });
        }),
      );
    }

    const dataset = await Promise.all(driverFacesPromises);
    const gridElement = document.querySelector('#report-body-grid');
    await gridElement.updateDriverFaces(dataset);

    dataset
      .filter(data => !!data._driverFaces)
      .forEach(data => {
        if (!this.auxDriverFacesCards.find(item => item._index === data._index)) {
          this.auxDriverFacesCards.push(data);
        }
      });

    this._requestUpdate();
  }

  _parseDate(date) {
    return new Date(date.setHours(date.getHours() - 3));
  }

  _formatDate(date) {
    return this._toDate(date).toLocaleString();
  }

  _getFormatedDate(date) {
    return this._formatDate(date).replace(',', '').split(' ')[0];
  }

  _getFormatedHour(date) {
    return this._formatDate(date).replace(',', '').split(' ')[1];
  }

  _numberPadStart(value, size, character) {
    return `${value}`.padStart(size, character);
  }

  _requestUpdate() {
    if (!this.$rootScope.$$phase && !this.$scope.$$phase) {
      this.$scope.$apply();
    }
  }

  _getStreetViewLink(latitude, longitude) {
    return `https://maps.google.com/maps?layer=c&q=${latitude},${longitude}&cbll=${latitude},${longitude}&cbp=11,0,0,0,0&z=17&ll=${latitude},${longitude}`;
  }

  _cardPicturesClick(_event, data) {
    _event.stopPropagation();
    this.$.dispatchEvent(
      new CustomEvent('cardPicturesClick', {
        detail: { data },
        bubbles: true,
        composed: true,
      }),
    );
  }

  async _cardSelectionClick(event, data) {
    if (event.target?.classList.contains('card-utilization-photo')) {
      return;
    }

    this.$.dispatchEvent(
      new CustomEvent('cardSelectionClick', {
        detail: { data },
        bubbles: true,
        composed: true,
      }),
    );

    this.refreshCards();
  }

  _getImageUrl(data) {
    return data.isUrl ? `url(${data.result})` : `url(data:image/jpeg;base64,${data.result})`;
  }
}

class PowerCardUtilizationList {
  constructor() {
    this.template = template;
    this.bindings = {
      pageRows: '=?',
      gridHeaders: '=?',
      hasRowSelection: '=?',
      showDetails: '=?',
      selectedCards: '=?',
    };
    this.controller = PowerCardUtilizationListController;
  }
}

angular
  .module('power-card-utilization-list', [])
  .component('powerCardUtilizationList', new PowerCardUtilizationList());

export { PowerCardUtilizationListController, PowerCardUtilizationList };
