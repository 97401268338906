import angular from 'angular';

import '@power/power-components/components/power-fab/power-fab';
import '@power/power-components/components/power-toolbar-report/power-toolbar-report';
import '../golfleet-grid-maintenance/golfleet-grid-maintenance';
import '../golfleet-map-revision-history/golfleet-map-revision-history';
import '@power/power-components/components/power-footer/power-footer';
import '@power/power-components/components/power-pagination/power-pagination';
import '@power/power-components/components/power-header-selector/power-header-selector';
import '@power/power-components/components/power-popup-delete/power-popup-delete';
import '@power/power-components/components/power-toast/power-toast';
import { PowerReportController } from '@power/power-components/components/power-report/power-report';

import template from './golfleet-report-revision-history.html';
import './golfleet-report-revision-history.scss';

class GolfleetReportRevisionHistoryController extends PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  /**
   * Creates an instance of GolfleetReportRevisionStatusController.
   * @memberof GolfleetReportRevisionHistoryController
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    super(
      $element,
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    );

    this.mapModeList = [
      { type: 'all', description: 'Todos os Pontos', selected: true },
      { type: 'heat', description: 'Mapa de Calor', selected: false },
      { type: 'step', description: 'Ponto a ponto', selected: false, step: 0 },
    ];

    this.formDataManualEntry = new FormData();
    this.dataImportPopupEntryError = {};

    this.updateMapDataset = false;
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$scope.$watch(() => this.reportDataset, this.__reportDatasetChanged.bind(this));
  }

  /* Public */
  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });

    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (this.$.querySelector('#report-body-map').getMap) {
          if (this.updateMapDataset) {
            this.updateMapDataset = false;
            this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
          }
          this._adjustMap();
        }
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }

  changePage(page) {
    super.changePage(page);

    const gridElement = this.$.querySelector('#report-body-grid');
    const step = this.stateConfig.gridConfig.pageSize * (page - 1);

    gridElement.setActiveRow({ index: step });

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }

  changeRow(page, row) {
    if (page != this.stateConfig.gridConfig.page) {
      this.changePage(page);
    }

    if (row >= 0) {
      this._renderStepMode(row);
    }
  }

  changePageSize(pageSize) {
    super.changePageSize(pageSize);

    const step = 0;

    if (this._getSelectedMapMode().type == 'step') {
      this._renderStepMode(step);
    } else {
      this.mapModeList = this.mapModeList.map(mode =>
        mode.type == 'step' ? { ...mode, step } : mode,
      );
    }
  }
  /* */

  /* Private */
  _onClickImportButton() {
    this.statusImport = 0;
    this.$.querySelector('#popup-import').toggle();

    const inputManualEntry = this.$.querySelector('#file-manual-entry');

    const labelManualEntry = this.$.querySelector('#label-manual-entry');

    inputManualEntry.onchange = () => {
      const { files } = inputManualEntry;

      if (files && files.length > 0) {
        const file = files[0];
        const { footer } = this.stateConfig.toolbarConfig.importConfig.popup;

        labelManualEntry.children[0].innerHTML = file.name;
        labelManualEntry.children[0].classList.add('has-file');

        if (file.name.indexOf('.xls') > -1 || file.name.indexOf('.xlsx') > -1) {
          this.formDataManualEntry.append('importFile', file);
          this.formDataManualEntry.append(
            'timezone',
            // Intl.DateTimeFormat().resolvedOptions().timeZone,
            'America/Sao_Paulo',
          );

          if (labelManualEntry.nextElementSibling.style.display === 'block') {
            labelManualEntry.nextElementSibling.style.display = 'none';
          }

          footer.buttons.map(item => {
            item.disabled = item.method !== 'save';
            return item;
          });
        } else {
          labelManualEntry.nextElementSibling.style.display = 'block';

          footer.buttons.map(item => {
            item.disabled = item.method == 'save';
            return item;
          });
        }

        this.$scope.$apply();
      } else {
        this._clearPopupManualEntry(inputManualEntry, labelManualEntry, true);
      }
    };
  }

  _evtClickBtnsPopup(method) {
    const labelManualEntry = document.querySelector('#label-manual-entry');
    const inputManualEntry = document.querySelector('#file-manual-entry');

    if (method === 'cancel') {
      this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
      this.$.querySelector('#popup-import').toggle();
    } else if (method === 'ok') {
      this.$.querySelector('#popup-import').toggle();
      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );
      this.$scope.$broadcast('getDataset', null);
    } else if (method === 'more') {
      this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else if (method === 'reform') {
      this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
      this.statusImport = 0;
    } else {
      const methodUrl = this.stateConfig.toolbarConfig.importConfig.dataPostMethod;

      this.$.dispatchEvent(
        new CustomEvent('toggleLoader', {
          detail: { showLoader: true },
          bubbles: true,
          composed: true,
        }),
      );

      this.reportServices
        .sendFileManualEntry(methodUrl, this.formDataManualEntry)
        .then(
          () => {
            this.statusImport = 1;

            this._clearPopupManualEntry(inputManualEntry, labelManualEntry, false);
          },
          ({ data }) => {
            if (data && data.hasError) {
              this.statusImport = 2;
              this.dataImportPopupEntryError.fileName = labelManualEntry.children[0].innerHTML;
              this.dataImportPopupEntryError.countError = data.data.length;
              this.dataImportPopupEntryError.data = data.data;
            }
          },
        )
        .finally(() => {
          this.$scope.$broadcast('getDataset', null);
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        });
    }
  }

  _clearPopupManualEntry(input, label, applyScope) {
    const { popup } = this.stateConfig.toolbarConfig.importConfig;
    label.children[0].innerHTML = 'Nenhum arquivo selecionado';
    label.children[0].classList.remove('has-file');
    input.value = '';
    this.formDataManualEntry.delete('importFile');
    this.formDataManualEntry.delete('timezone');

    popup.footer.buttons.map(item => {
      item.disabled = item.method == 'save';
      return item;
    });

    if (applyScope) this.$scope.$apply();
  }

  _goToLink(evt, evtParams) {
    if (evtParams.routeLink == 'map') {
      if (this._getSelectedMapMode().type != 'step') {
        this._selectMapMode(this.mapModeList.filter(mode => mode.type == 'step')[0]);
      }

      if (this.stateConfig.viewMode != 'map' && this.stateConfig.viewMode != 'split') {
        if (window.innerWidth > 878) this.changeView('split');
        else this.changeView('map');
      } else {
        const mapComponent = this.$.querySelector('#report-body-map');

        if (!evtParams.tableRowData.latitude || !evtParams.tableRowData.longitude) {
          Object.assign(this, {
            toastText: 'Oficinas sem latitude/longitude',
          });

          mapComponent.resizeMap();
          mapComponent.removeLayers(['stepMarker']);
          mapComponent.zoomTo({ latitude: -15.34, longitude: -53.74 }, 4);
          this.$.querySelector('power-toast#report-toast').toggle(true);
        }

        mapComponent.zoomTo(evtParams.tableRowData);
      }

      const gridElement = this.$.querySelector('#report-body-grid');

      gridElement.setActiveRow({ index: evtParams.index }).then(() => {
        this.selectedVehicle = gridElement.getActiveRow();
      });
    } else {
      super._goToLink(evt, evtParams);
    }
  }

  _selectMapMode(mapMode) {
    if (mapMode.selected) return;
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode => ({
      ...mode,
      selected: mode.type == mapMode.type,
    }));
    switch (mapMode.type) {
      case 'all':
        mapComponent.removeLayers(['heatLayer', 'stepMarker']);
        this._renderAllMode(this.reportDataset.filter(data => data.latitude && data.longitude));
        break;
      case 'heat':
        mapComponent.removeLayers(['all', 'stepMarker']);
        this._renderHeatMode(this.reportDataset.filter(data => data.latitude && data.longitude));
        break;
      case 'step':
        mapComponent.removeLayers(['all', 'heatLayer']);
        this.changeRow(this.stateConfig.gridConfig.page, mapMode.step);
        break;
      default:
        break;
    }
  }

  _getSelectedMapMode() {
    return this.mapModeList.filter(mode => mode.selected)[0];
  }

  _getStepPosition() {
    return this.mapModeList.filter(mode => mode.type == 'step')[0].step;
  }

  async _renderStepMode(index) {
    const mapComponent = this.$.querySelector('#report-body-map');
    this.mapModeList = this.mapModeList.map(mode =>
      mode.type == 'step' ? { ...mode, step: index } : mode,
    );
    const dataPosition = this.reportDataset[index];

    if (!dataPosition.latitude || !dataPosition.longitude) {
      Object.assign(this, {
        toastText: 'Oficinas sem latitude/longitude',
      });

      mapComponent.resizeMap();
      mapComponent.removeLayers(['stepMarker']);
      mapComponent.zoomTo({ latitude: -15.34, longitude: -53.74 }, 4);
      this.$.querySelector('power-toast#report-toast').toggle(true);
      return;
    }

    await mapComponent.renderDataset({
      dataset: [dataPosition],
      layerName: 'stepMarker',
      type: 'MarkerFeatureGroup',
    });

    mapComponent.fitLayers(['stepMarker']);
    mapComponent.openPopup(0, 'stepMarker');
  }

  async _renderAllMode(dataset) {
    this._showGlobalLoader(true);

    const mapComponent = this.$.querySelector('#report-body-map');

    await mapComponent.renderDataset({
      dataset: dataset.filter(data => data.latitude && data.longitude),
      layerName: 'all',
      type: 'Cluster',
      useCluster: true,
      clusterColor: '#2196f3',
      icon: 'gs_maint',
    });

    mapComponent.resizeMap();
    mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);

    this._showGlobalLoader(false);
  }

  _renderHeatMode(dataset) {
    const mapComponent = this.$.querySelector('#report-body-map');

    mapComponent.renderDataset({ dataset, layerName: 'heatLayer', type: 'HeatLayer' }).then(() => {
      mapComponent.resizeMap();
      mapComponent.fitLayers([], 'heatLayer');
    });
  }

  _adjustMap() {
    const mapComponent = this.$.querySelector('#report-body-map');
    mapComponent.resizeMap();
    switch (this._getSelectedMapMode().type) {
      case 'all':
        mapComponent.zoomTo({ latitude: -20.933402286553182, longitude: -44.95093750000001 }, 4);
        break;
      case 'heat':
        mapComponent.fitLayers([], 'heatLayer');
        break;
      case 'step':
        mapComponent.fitLayers(['stepMarker']);
        mapComponent.openPopup(0, 'stepMarker');
        break;
      default:
        break;
    }
  }

  _showGlobalLoader(state = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: state },
        bubbles: true,
        composed: true,
      }),
    );
  }
  /* */

  /* Observers */
  __reportDatasetChanged(newValue) {
    if (newValue) {
      if (newValue.length > 0) {
        if (this.stateConfig.viewMode === 'grid') {
          this.updateMapDataset = true;
        } else {
          this._selectMapMode(Object.assign(this._getSelectedMapMode(), { selected: false }));
        }
      } else {
        this.$.querySelector('#report-body-map').removeLayers(['all', 'heatLayer', 'stepMarker']);
      }
    }
  }

  __onRequestSyncVisualization(evt) {
    super.__onRequestSyncVisualization(evt);

    const gridElement = this.$.querySelector('#report-body-grid');
    const activeRow = gridElement.getActiveRow();

    this._renderStepMode(activeRow._index);
  }
  /* */
}

class GolfleetReportRevisionHistory {
  constructor() {
    this.template = template;
    this.bindings = {};
    this.controller = GolfleetReportRevisionHistoryController;
  }
}

angular
  .module('golfleet-report-revision-history', [
    'ngRedux',
    'power-fab',
    'power-toolbar-report',
    'golfleet-grid-maintenance',
    'golfleet-map-revision-history',
    'power-footer',
    'power-pagination',
    'power-header-selector',
    'power-popup-delete',
    'power-toast',
  ])
  .component('golfleetReportRevisionHistory', new GolfleetReportRevisionHistory());
