import angular from 'angular';

import template from './power-popup-move-vehicles.html';
import './power-popup-move-vehicles.scss';

/**
 * - Component Controller
 */
class PowerPopupMoveVehiclesController {
  static get $inject() {
    return ['$element', '$http', 'urlApi'];
  }

  constructor($element, $http, urlApi) {
    Object.assign(this, { $: $element[0], $http, urlApi });

    this.moveList = [];
    this.cantMoveList = [];

    this.cantMoveActiveView = false;
    this.toId = null;
    this.method = '';
    this.objects = [];
    this.searchText = '';
  }

  // #region Lifecycle
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setData: this.setData.bind(this),
      switchCantMoveView: this.switchCantMoveView.bind(this),
    });
  }

  $onDestroy() {}
  // #endregion Lifecycle

  // #region Public
  toggle() {
    if (this.$.querySelector('#power-popup-move-vehicles').getAttribute('open') == null) {
      this.moveList = [];
      this.cantMoveActiveView = this.objects.length === 0;
      this._getMoveList();
      this.$.querySelector('#power-popup-move-vehicles').toggle();
    } else {
      this.$.querySelector('#power-popup-move-vehicles').toggle();
    }
  }

  setData(data) {
    Object.assign(this, data);
  }

  switchCantMoveView() {
    this.searchText = '';
    this.cantMoveActiveView = true;
  }
  // #endregion Public

  // #region Private
  _getMoveList() {
    this._showLoader(true);
    return this.$http({
      url: `${this.urlApi}/${this.getMethod}`,
      method: 'POST',
    })
      .then(result =>
        Object.assign(this, {
          moveList: result.data.data,
        }),
      )
      .finally(() => {
        this._showLoader(false);
      });
  }

  _moveItemClick(item) {
    this.moveList = this.moveList.map(ele => Object.assign(ele, { selected: ele.id == item.id }));
  }

  _getSelectedItem() {
    return this.moveList.filter(ele => ele.selected)[0];
  }

  _showPreviewItem(item) {
    if (this.showPreview) {
      this.showPreview({ data: item });
    }
  }

  _move() {
    this.searchText = '';
    this.$.dispatchEvent(
      new CustomEvent('move', {
        detail: {
          toId: this._getSelectedItem().value,
          method: this.moveMethod,
          objects: this.objects,
          switchCantMoveView: this.cantMoveList.length > 0,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _hasSelectedItem() {
    return this.moveList.filter(item => item.selected).length > 0;
  }

  _showLoader(show = false) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: show },
        bubbles: true,
        composed: true,
      }),
    );
  }
  // #endregion Private
}

class PowerPopupMoveVehicles {
  constructor() {
    this.template = template;
    this.bindings = {
      showPreview: '&?',
    };
    this.controller = PowerPopupMoveVehiclesController;
  }
}

angular
  .module('power-popup-move-vehicles', [])
  .component('powerPopupMoveVehicles', new PowerPopupMoveVehicles());

export { PowerPopupMoveVehiclesController };
