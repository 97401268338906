import angular from 'angular';

import { PowerFilterController, PowerFilter } from '../power-filter/power-filter';

import template from './power-filter-input.html';
import './power-filter-input.scss';

class PowerFilterInputController extends PowerFilterController {
  static get $inject() {
    return ['$element', '$scope', '$http', 'urlApi'];
  }

  constructor($element, $scope, $http, urlApi) {
    super($element, $scope, $http, urlApi);

    this._inputValue = null;
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
      setFilterValue: this.setFilterValue.bind(this),
    });

    this.sufix = this.sufix || '';
    this.prefix = this.prefix || '';
    this.description = this.description || 'Carregando...';

    this._initializeFilter();
  }

  $onDestroy() {}
  /* */

  /* Public */
  toggle() {
    document.querySelector('power-filter-menu').toggleFilter(this.filterId);
  }
  /* */

  /* Private */
  _initializeFilter() {
    if (this.condition) this._inputValue = this.condition.value[0];
    else if (this.default) this._inputValue = this.default[0];
    this.setFilterValue(this._inputValue);
    this._formatDescription(this._inputValue);
  }

  _formatDescription(value) {
    this.description = this._formatDescriptionWords(
      !value
        ? `Qualquer ${this.singular}`
        : `${this.singular} ${this.prefix} ${value} ${this.sufix}`,
    );
  }
  /* */

  /* Observers */
  __inputValueChanged() {
    if (this.inputType == 'number' && this._inputValue < 0) this._inputValue = 0;
    this.setFilterValue(this._inputValue);
    this._formatDescription(this._inputValue);
  }

  __evtKeyDownInputValue(evt) {
    if (this.inputType === 'number') {
      const regex = /^[.e-]$/g;
      if (regex.test(evt.key)) {
        evt.preventDefault();
      }
    }
  }
  /* */
}

class PowerFilterInput extends PowerFilter {
  constructor() {
    super();
    this.template = template;
    this.controller = PowerFilterInputController;
  }
}

angular
  .module('power-filter-input', [])
  .component('powerFilterInput', new PowerFilterInput());
