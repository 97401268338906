import angular from 'angular';

import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';
import '../golfleet-popup-fuel-exceptions/golfleet-popup-fuel-exceptions';

import template from './golfleet-grid-fuel-module.html';
import './golfleet-grid-fuel-module.scss';

class GolfleetGridFuelModuleController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
      '$ngRedux',
    ];
  }

  constructor(
    $element,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
    $ngRedux,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);
    Object.assign(this, {
      $: $element[0],
    });
  }

  /* Private */
  _evtClickPopupFuelException(evt) {
    evt.stopPropagation();
    this.$.querySelector('#golfleet-popup-fuel-exceptions').toggle();
  }
  /* */
}

class GolfleetGridFuelModule {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      pagename: '=?',
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
      /* complement header title */
      replaceHeaderText: '=?',
      complementHeaderField: '=?',
      complementHeaderText: '=?',
    };

    this.controller = GolfleetGridFuelModuleController;
  }
}

angular
  .module('golfleet-grid-fuel-module', [
    'ngSanitize',
    'ng-resize',
    'golfleet-popup-fuel-exceptions',
  ])
  .component('golfleetGridFuelModule', new GolfleetGridFuelModule());
