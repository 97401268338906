import angular from 'angular';
import 'angular-sanitize';
import '@power/power-components/components/power-popup/power-popup';
import '@power/power-components/components/power-toast/power-toast';
import '@power/power-components/directives/ng-resize/ng-resize';

import { PowerGridSpeedLimitController } from '@power/power-components/components/power-grid-speed-limit/power-grid-speed-limit';

import template from '@power/power-components/components/power-grid-speed-limit/power-grid-speed-limit.html';
import './golfleet-grid-speed-limit.scss';

class GolfleetGridSpeedLimitController extends PowerGridSpeedLimitController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'urlApi',
    ];
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    $element,
    $ngRedux,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    urlApi,
  ) {
    super($element, $ngRedux, $scope, $state, $http, $timeout, $filter, commonServices, urlApi);

    this.supportEmail = 'suporte@golfleet.com.br';
    this.supportPhone = '43 3315 9555';
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();
  }

  $onDestroy() {
    super.$onDestroy();
  }
  /* */

  /* Public */
  /* */

  /* Private */
  /* */

  /* Observers */
  /* */
}

class GolfleetGridSpeedLimit {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridSpeedLimitController;
  }
}

angular
  .module('golfleet-grid-speed-limit', ['ngSanitize', 'power-popup', 'power-toast', 'ng-resize'])
  .component('golfleetGridSpeedLimit', new GolfleetGridSpeedLimit());
