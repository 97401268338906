import angular from 'angular';
import 'ng-redux';
import moment from 'moment/src/moment';
import 'moment/src/locale/pt-br';
import 'angular-drag-and-drop-lists';

import '../power-fab/power-fab';
import '../power-toolbar-report/power-toolbar-report';
import '../power-dropdown/power-dropdown';
import '../power-grid/power-grid';
import '../power-footer/power-footer';
import '../power-pagination/power-pagination';
import '../power-crud/power-crud';
import '../power-popup/power-popup';
import '../power-popup-share/power-popup-share';
import '../power-popup-delete/power-popup-delete';
import '../power-toast/power-toast';
import '../power-header-selector/power-header-selector';
import { FilterCondition, PowerFilterDataKey } from '../power-filter/power-filter';
import {
  RecordStateConfig,
  ReportStateConfig,
} from '../power-state-config/power-state-config';
import { ImportRouteConfig } from '../../utils/get-route-config';

import template from './power-report.html';
import './power-report.scss';

class PowerReportController {
  static get $inject() {
    return [
      '$element',
      '$scope',
      '$ngRedux',
      '$http',
      '$state',
      '$timeout',
      'urlApi',
      'commonServices',
      'filterServices',
      'reportServices',
      'recordServices',
    ];
  }

  constructor(
    $element,
    $scope,
    $ngRedux,
    $http,
    $state,
    $timeout,
    urlApi,
    commonServices,
    filterServices,
    reportServices,
    recordServices,
  ) {
    Object.assign(this, {
      $: $element[0],
      $scope,
      $ngRedux,
      $http,
      $state,
      $timeout,
      urlApi,
      commonServices,
      filterServices,
      reportServices,
      recordServices,
    });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const routeListSize = behavior.state.routeList.length;
      const currentState = behavior.state.routeList[routeListSize - 1];
      return Object({
        /* Session Storage */
        userId: behavior.session.userId,
        isTrip: behavior.session.isTrip,
        clientId: behavior.session.clientId,
        isTrainingMode: behavior.session.trainingMode,
        isSingleSignon: behavior.session.isSingleSignon,
        onAdmNavigationMode: behavior.session.onAdmNavigationMode,
        application: behavior.session.application,
        /* State Storage */
        lastState: routeListSize > 1 ? behavior.state.routeList[routeListSize - 2] : null,
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
        isDrillDownRoute: routeListSize > 1,
        isVideoTelemetry: behavior.session.isVideoTelemetry,
        modules: behavior.session.modules,
        newRouteLink: behavior.state.routeList[behavior.state.routeList.length - 1]?.routeLink,
        navigationList: behavior.session.navigationList,
      });
    })(this);

    this.toastText = '';
    this.gridSizeList = [10, 20, 30, 50];
    this.selectedRows = [];
    this.importData = { status: 0 };
    this.exportData = {
      formData: new FormData(),
      error: {},
    };
    this.closeReportControlMoreOptions = false;
    this.userReportConfiguration = null;

    this.onShareEvent = this._shareSelectedItens.bind(this);
    this.onDeleteEvent = this._deleteSelectedItens.bind(this);
    this.onMoveEvent = this._moveSelectedItens.bind(this);

    ImportRouteConfig(this.newRouteLink).then(newRouteConfig => {
      this.newRouteMetadata = newRouteConfig ? newRouteConfig.metadata : {};
    });

    moment.locale('pt-BR');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      requestDataset: this.requestDataset.bind(this),
    });

    const matchTablet = window.matchMedia('(max-width: 768px)');
    const matchTabletEvt = evt =>
      evt.matches && this.stateConfig.viewMode == 'split' ? this.changeView('grid') : null;
    matchTablet.addListener(matchTabletEvt);

    this.$.addEventListener('move', this.onMoveEvent);
    this.$.addEventListener('share', this.onShareEvent);
    this.$.addEventListener('delete', this.onDeleteEvent);

    this.$scope.$on('goToLink', this._goToLink.bind(this));
    this.$scope.$on('UPDATE_ROUTE', () => this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' }));

    if (this.stateConfig.viewMode) {
      this.changeView(this.stateConfig.viewMode);
    } else {
      this.changeView('grid');
    }

    if (this.stateConfig.isCustomReport) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        if (this.stateConfig.customReportId) {
          const showReportNotFoundMessage = () => {
            this.toastText = 'O relatório selecionado não existe ou foi removido';
            this.$.querySelector('power-toast#report-toast').toggle(true);
          };

          this.reportServices
            .getUserReportConfiguration({
              _id: this.stateConfig.customReportId,
            })
            .then(success => {
              if (success.status && success.status == 200 && success.data.data) {
                const { data } = success.data;

                if (data) {
                  this.userReportConfiguration = {
                    ...data,
                    filterConfig: JSON.parse(data.filterConfig),
                    gridConfig: JSON.parse(data.gridConfig),
                  };

                  return;
                }
              }
              this.userReportConfiguration = null;
              showReportNotFoundMessage();
            })
            .catch(() => {
              this.userReportConfiguration = null;
              showReportNotFoundMessage();
            })
            .finally(() => {
              if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
                this.$scope.$broadcast('getHeaders', {
                  screenName: this.stateConfig.screenName,
                  gridName: this.stateConfig.gridName,
                  userGridConfig: this.userReportConfiguration
                    ? this.userReportConfiguration.gridConfig
                    : null,
                });

                this._getStateConfig();
              } else {
                const stateGridConfig = this.stateConfig.gridConfig;

                this.$scope.$broadcast('getHeaders', {
                  screenName: this.stateConfig.screenName,
                  gridName: this.stateConfig.gridName,
                  page: !this.userReportConfiguration ? stateGridConfig.page : undefined,
                  pageSize: !this.userReportConfiguration ? stateGridConfig.pageSize : undefined,
                  userGridConfig:
                    !this.isDrillDownRoute && !!this.userReportConfiguration
                      ? this.userReportConfiguration.gridConfig
                      : undefined,
                });
                this.$scope.$broadcast('getDataset', {
                  filter: {
                    conditions: this.stateConfig.filterConditions,
                  },
                  isPaginated: stateGridConfig.backPagination,
                  ...this.stateConfig.getDataFixedParams,
                });

                document
                  .querySelector('#app-component')
                  .querySelector('power-filter-menu')
                  .validateFilters();
              }
            });
        }
      });
    } else if (!this.stateConfig.filterConfig || this.stateConfig.filterConfig.length == 0) {
      this.$scope.$on('getDatasetReady', () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
        });
        this._getStateConfig();
      });
    } else {
      this.$scope.$on('getDatasetReady', () => {
        this.$scope.$broadcast('getHeaders', {
          screenName: this.stateConfig.screenName,
          gridName: this.stateConfig.gridName,
          page: this.stateConfig.gridConfig.page,
          pageSize: this.stateConfig.gridConfig.pageSize,
        });

        const dateControl = this.$.querySelector('power-date-control');

        if (dateControl) {
          const [dateCondition] = this.stateConfig.filterConfig.filter(
            filter => filter.type === 'calendar',
          );
          dateControl.updateDateValue(dateCondition);
        } else {
          this.$scope.$broadcast('getDataset', {
            filter: {
              conditions: this.stateConfig.filterConditions,
            },
            isPaginated: this.stateConfig.gridConfig.backPagination,
            ...this.stateConfig.getDataFixedParams,
          });
        }

        document
          .querySelector('#app-component')
          .querySelector('power-filter-menu')
          .validateFilters();
      });
    }

    if (this.$.querySelector('#report-body-card')) {
      this.$.querySelector('#report-body-card').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }

    if (this.$.querySelector('#report-body-grid')) {
      this.$.querySelector('#report-body-grid').addEventListener(
        'requestSyncVisualization',
        this.__onRequestSyncVisualization.bind(this),
      );
    }
    this.showShutdownPopup();
  }

  $onDestroy() {
    this.__appBehavior();
    this.$.removeEventListener('share', this.onDeleteEvent);
  }
  /* */

  /* Public */
  showShutdownPopup() {
    if (
      !this.isSingleSignon &&
      this.newRouteMetadata &&
      this.newRouteMetadata.newReportRouteName &&
      this.newRouteMetadata.endReportDate &&
      this.navigationList.find(
        n =>
          n.route === this.newRouteMetadata.newReportRouteName ||
          n.itens.find(i => i.route === this.newRouteMetadata.newReportRouteName),
      )
    ) {
      const popupElement = document.querySelector('power-popup-defaulter-warning');
      if (popupElement) {
        popupElement.showShutDownNotification();
      }
    }
  }

  requestDataset(keepPage) {
    const payload = {
      filter: {
        conditions: this.stateConfig.filterConditions,
      },
      isPaginated: this.stateConfig.gridConfig.backPagination,
    };

    if (!keepPage) payload.page = 1;

    this.$scope.$broadcast(
      'getDataset',
      Object.assign(payload, this.stateConfig.getDataFixedParams),
    );
  }

  changePage(page) {
    this.$scope.$broadcast('changePage', {
      page,
      payload: {
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        isPaginated: this.stateConfig.gridConfig.backPagination,
        ...this.stateConfig.getDataFixedParams,
      },
    });
  }

  changePageSize(pageSize) {
    this.$timeout(() => {
      this.$scope.$broadcast('changePageSize', {
        pageSize,
        payload: {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          ...this.stateConfig.getDataFixedParams,
        },
      });
    });
  }

  changeView(viewMode) {
    this.stateConfig.viewMode = viewMode;
    this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    const mapComponent = this.$.querySelector('#report-body-map');
    switch (viewMode) {
      case 'map':
        this.$.setAttribute('map-view', '');
        this.$.removeAttribute('grid-view');
        this.$.removeAttribute('split-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      case 'split':
        this.$.setAttribute('split-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('grid-view');
        if (mapComponent.resizeMap) mapComponent.resizeMap();
        break;
      default:
        this.$.setAttribute('grid-view', '');
        this.$.removeAttribute('map-view');
        this.$.removeAttribute('split-view');
        break;
    }
  }
  /* */

  /* Private */
  async _changeActiveRow(page, rowPosition) {
    const gridElement = this.$.querySelector('#report-body-grid');

    this.changePage(page);

    gridElement.setActiveRow({ index: rowPosition - 1 });
  }

  _getActiveRow() {
    const gridElement = this.$.querySelector('#report-body-grid');
    const selectedIndex = gridElement.getActiveRow()?._index || 0;

    return selectedIndex < 0 ? 0 : selectedIndex + 1;
  }

  _amountShowingHeaders() {
    return this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders
      ? this.stateConfig.gridConfig.gridHeaders.filter(ele => ele.show).length
      : 0;
  }

  _errorAddItem() {
    this.toastText = 'Por favor verifique os campos';
    this.$.querySelector('power-toast#report-toast').toggle(true);
  }

  _getStateConfig() {
    return this.filterServices
      .getPowerFilters(this.stateConfig.screenName, this.stateConfig.gridName)
      .then(getFiltersResult => {
        const getToolbarConfigAndApplyFilters = () => {
          this.commonServices
            .getToolbarOptions(
              this.stateConfig.screenName,
              this.stateConfig.toolbarName,
              getToolbarResult => {
                const {
                  addConfig,
                  editConfig,
                  viewsConfig,
                  exportConfig,
                  importConfig,
                  actionConfig,
                  saveConfig,
                  autoSendConfig,
                } = getToolbarResult;
                Object.assign(this.stateConfig.toolbarConfig, {
                  addConfig,
                  editConfig,
                  viewsConfig,
                  exportConfig,
                  importConfig,
                  actionConfig,
                  saveConfig,
                  autoSendConfig,
                });
                this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
              },
            )
            .then(() =>
              setTimeout(() => {
                const filterMenuElement = document
                  .querySelector('#app-component')
                  .querySelector('power-filter-menu');

                filterMenuElement.applyFilters();
                filterMenuElement.validateFilters();
              }, 300),
            );
        };

        if (getFiltersResult) {
          const drilldownMap = getFiltersResult.drilldownMap
            ? getFiltersResult.drilldownMap[this.lastState?.routeLink] ||
              getFiltersResult.drilldownMap
            : [];
          const filterConfigList = getFiltersResult.filters;
          this.stateConfig.filterConfig = filterConfigList.map(filterConfig =>
            Object.clone({}, filterConfig),
          );
          if (
            this.lastState &&
            drilldownMap.length > 0 &&
            this.lastState.stateConfig.filterConfig
          ) {
            const lastFilterConfigList = this.lastState.stateConfig.filterConfig;
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const drilldown = drilldownMap.filter(
                drilldown2 => drilldown2.toId == actualFilter.id,
              )[0];
              if (drilldown) {
                const previousFilter = lastFilterConfigList.filter(
                  filter => filter.id == drilldown.fromId,
                )[0];

                if (
                  previousFilter &&
                  previousFilter.type === 'calendar' &&
                  previousFilter.activeView === 'preset' &&
                  previousFilter.condition &&
                  previousFilter.condition.preset
                ) {
                  const result = {
                    ...actualFilter,
                    activeView: 'calendar',
                    condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                  };

                  result.condition.preset = null;
                  result.condition.value = this._convertCalendarPresetToDates(
                    previousFilter.condition.preset,
                  );

                  return result;
                }
                return {
                  ...actualFilter,
                  condition: !previousFilter ? null : Object.clone(previousFilter.condition),
                };
              }
              return actualFilter;
            });
          }
          if (
            this.userReportConfiguration &&
            this.userReportConfiguration.filterConfig &&
            this.userReportConfiguration.filterConfig.length > 0
          ) {
            const config = this.stateConfig.filterConfig.map(current => {
              const [filter] = this.userReportConfiguration.filterConfig.filter(
                userFilterConfig =>
                  PowerFilterDataKey(userFilterConfig) == PowerFilterDataKey(current),
              );

              if (filter) {
                if (filter.type === 'calendar') {
                  if (filter.condition.preset) {
                    filter.condition.value = this._convertCalendarPresetToDates(
                      filter.condition.preset,
                    );
                  }
                }

                const { isArray } = Array;
                const isAdvanced = filter.advanced && current.advanced;
                const selectorIsArray = isArray(current.selectors) && isArray(filter.selectors);
                const conditionIsArray = isArray(filter.condition);

                if (isAdvanced && selectorIsArray) {
                  current.selectors.forEach(item => {
                    item.selected = !!(
                      filter.selectors.find(s => s.selector === item.selector) || {}
                    ).selected;
                  });

                  if (!conditionIsArray) {
                    filter.condition = [filter.condition];
                  }
                } else if (filter.advanced && !current.advanced && conditionIsArray) {
                  const [condition] = filter.condition;
                  filter.condition = condition;
                }

                return Object.assign(current, {
                  activeEntity: filter.activeEntity,
                  activeView: filter.activeView,
                  condition: filter.condition,
                });
              }

              return current;
            });

            this.stateConfig.filterConfig = config;

            this.currentState.userReportId = this.userReportConfiguration._id;
            this.currentState.userReportName = this.userReportConfiguration.reportName;
            this.currentState.userReportCanEdit = !!this.userReportConfiguration.sharedUsers.find(
              user => user.userId == this.userId && user.canEdit,
            );
            this.currentState.userReportConfig = JSON.parse(
              JSON.stringify(this.userReportConfiguration),
            );
            this.currentState.userReportCreatedBy = this.userReportConfiguration.createdBy;
            this.currentState.userReportCreatedAt = this.userReportConfiguration.createdAt;
          }

          if (this.stateConfig.getDataFilters && this.stateConfig.getDataFilters.length > 0) {
            this.stateConfig.filterConfig = this.stateConfig.filterConfig.map(actualFilter => {
              const fixedFilters = this.stateConfig.getDataFilters.filter(
                condition =>
                  condition.id == actualFilter.id ||
                  (!!actualFilter.field && condition.field == actualFilter.field),
              )[0];

              return fixedFilters
                ? {
                    ...actualFilter,
                    condition: new FilterCondition(
                      actualFilter.id,
                      actualFilter.field ||
                        (
                          actualFilter.filters[fixedFilters.activeEntity] ||
                          actualFilter.filters[fixedFilters.activeView]
                        ).field,
                      fixedFilters.default,
                      fixedFilters.incluedHistoricalData,
                      fixedFilters.notIn,
                    ),
                  }
                : actualFilter;
            });
          }
        }

        getToolbarConfigAndApplyFilters();
      });
  }

  _callAction(action) {
    /* Chrome Locked-Scroll Workaround */
    function scrollWorkaround() {
      const scrollArea = this.$.querySelector('#popup-add .div-section-crud');
      setTimeout(() => {
        scrollArea.style.overflow = 'auto';
      }, 500);
      setTimeout(() => {
        scrollArea.style.overflow = '';
      }, 600);
    }
    switch (action.actionType) {
      case 'add':
        this.stateConfig.toolbarConfig.addConfig.dataset = null;
        this.$.querySelector('#popup-add').toggle();
        scrollWorkaround.bind(this)();
        break;
      case 'share':
        this.$.querySelector('power-popup-share').setData({
          type: this.stateConfig.type,
          getMethod: action.getDataMethod,
          shareMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({ objectId: row.id })),
        });
        this.$.querySelector('power-popup-share').toggle();
        break;
      case 'edit':
        break;
      case 'delete':
        this.$.querySelector('power-popup-delete').setData({
          deleteMethod: action.actionMethod,
          objects: this.selectedRows.map(row => ({
            id: row.id,
            description: this._getDescriptionField(row),
            value: row.id,
          })),
        });
        this.$.querySelector('power-popup-delete').toggle();
        break;
      default:
        break;
    }
  }

  _getDescriptionField(row) {
    if (Array.isArray(this.stateConfig.gridConfig.descriptionField)) {
      const descriptionFields = this.stateConfig.gridConfig.descriptionField;

      const description = descriptionFields.reduce((acc, item, index) => {
        const gridHeader = this.stateConfig.gridConfig.gridHeaders.find(i => i.field === item);

        const value =
          gridHeader.type === 'DateTime'
            ? moment(new Date(row[item])).format('DD/MM/YYYY hh:mm:ss')
            : row[item];

        if (index === 0) {
          acc = value;
        } else {
          acc = `${acc} - ${value}`;
        }

        return acc;
      }, '');

      return description;
    }

    return row[this.stateConfig.gridConfig.descriptionField];
  }

  _addItem(method, data) {
    const payload = {};
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const attr in data) {
      payload[attr] = data[attr];
    }
    this.$.querySelector('#popup-add').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    this.$http({
      url: `${this.urlApi}/${method}`,
      method: 'POST',
      data: { request: payload },
    }).then(
      () =>
        this.$timeout(() => {
          this.toastText = `${this.stateConfig.toolbarConfig.addConfig.title} adicionado.`;
          this.$.querySelector('power-toast#report-toast').toggle(true);
          this.requestDataset();
        }),
      () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = 'Erro ao adicionar o item.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _cancelAddItem() {
    this.$.querySelector('#popup-add').toggle();
    setTimeout(() => {
      this.stateConfig.toolbarConfig.addConfig.dataset = null;
    });
  }

  _editSelectedItens(action) {
    return this.$http({
      url: `${this.urlApi}/${action.actionMethod}`,
      method: 'POST',
    }).then(() => {});
  }

  _shareSelectedItens(evt) {
    this.$.querySelector('power-popup-share').toggle();
    return this.$http({
      url: `${this.urlApi}/${evt.detail.method}`,
      method: 'POST',
      data: {
        type: evt.detail.type,
        toId: evt.detail.toId,
        objects: evt.detail.objects,
      },
    }).then(
      success => {
        if (!success.data.hasError) this.toastText = 'Compartilhado com sucesso.';
        else this.toastText = 'Não foi possivel Compartilhar.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
      () => {
        this.toastText = 'Não foi possivel Compartilhar.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _deleteSelectedItens(evt) {
    this.$.querySelector('power-popup-delete').toggle();
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    return this.$http({
      url: `${this.urlApi}/${evt.detail.method}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: { objects: evt.detail.objects },
    }).then(
      success => {
        if (!success.data.hasError) {
          this.toastText = 'Excluido com sucesso.';
          this.requestDataset();
        } else this.toastText = 'Não foi possivel Excluir.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
      () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );
        this.toastText = 'Não foi possivel Excluir.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  _moveSelectedItens(evt) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );
    return this.$http({
      url: `${this.urlApi}/${evt.detail.method}`,
      method: 'POST',
      data: {
        toId: evt.detail.toId,
        objectIds: evt.detail.objects.map(item => item.objectId),
      },
    }).then(
      success => {
        if (!success.data.hasError && success.data.data) {
          this.toastText = 'Movido com sucesso.';
          this.requestDataset();
          if (evt.detail.switchCantMoveView) {
            this.$.querySelector('power-popup-move-vehicles').switchCantMoveView();
          } else {
            this.$.querySelector('power-popup-move-vehicles').toggle();
          }
        } else {
          this.toastText = 'Não foi possivel mover.';
          this.$.dispatchEvent(
            new CustomEvent('toggleLoader', {
              detail: { showLoader: false },
              bubbles: true,
              composed: true,
            }),
          );
        }
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
      () => {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        );

        this.toastText = 'Não foi possivel mover.';
        this.$.querySelector('power-toast#report-toast').toggle(true);
      },
    );
  }

  async _goToLink(evt, evtParams) {
    let getDataFilters = [];
    const getDataFixedParams = {};

    if (evtParams.backPagination === null) {
      evtParams.backPagination = false;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const attr in evtParams.stateFixedParams) {
      if (evtParams.tableRowData[evtParams.stateFixedParams[attr]]) {
        getDataFixedParams[attr] = evtParams.tableRowData[evtParams.stateFixedParams[attr]];
      } else if (
        this.stateConfig.getDataFixedParams &&
        this.stateConfig.getDataFixedParams[evtParams.stateFixedParams[attr]]
      ) {
        getDataFixedParams[attr] =
          this.stateConfig.getDataFixedParams[evtParams.stateFixedParams[attr]];
      } else {
        getDataFixedParams[attr] = evtParams.stateFixedParams[attr];
      }
    }

    if (evtParams.fixedFilters) {
      getDataFilters = evtParams.fixedFilters.map(filter => {
        const filterDefault = filter.condition.map((condition, index) => {
          const data = evtParams.tableRowData[condition.field];
          if (filter.type == 'DateTime') {
            let conditionDate = filter.keepOffset ? moment(data) : moment(data).utcOffset(-3);

            conditionDate = conditionDate.subtract(condition.value, 'days');

            if (!filter.keepTime) {
              if (index == 0) conditionDate.hour(0).minute(0).second(0).millisecond(0);
              else if (index == 1) conditionDate.hour(23).minute(59).second(59).millisecond(0);
            }
            return conditionDate._d;
          }
          if (condition.value) {
            return data ? data + condition.value : condition.value;
          }

          return data;
        });

        const hasHistoricalData = filter.condition.reduce((acc, condition) => {
          if ('incluedHistoricalData' in condition) {
            if (typeof condition.incluedHistoricalData !== 'boolean') {
              return evtParams.tableRowData[condition.incluedHistoricalData] || acc;
            }
            return condition.incluedHistoricalData || acc;
          }

          return acc;
        }, false);

        return {
          id: filter.id,
          default: filterDefault,
          activeView: filter.activeView,
          incluedHistoricalData: hasHistoricalData,
        };
      });
    }

    switch (evtParams.routeLink) {
      case 'record':
      case 'recordDriver':
      case 'recordVehicle': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new RecordStateConfig({
          isAdm: this.onAdmNavigationMode,
          getDataMethod: evtParams.getDataMethod,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        this.recordServices.getRecordConfig(stateConfig.type).then(
          result => {
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const attr in result) {
              stateConfig[attr] = result[attr];
            }
            this.$ngRedux.dispatch({
              type: 'NEXT_ROUTE',
              data: {
                routeName: evtParams.tableRowData[evtParams.routeName] || evtParams.routeName,
                routeSubName: evtParams.linkStateConfig?.routeSubName,
                routeLink: evtParams.routeLink,
                routeTail: getDataFixedParams.id,
                stateConfig,
              },
            });
            this.$state.go(evtParams.routeLink, {
              tail: getDataFixedParams.id,
            });
          },
          () => {
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            );
          },
        );

        break;
      }
      case 'report': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new ReportStateConfig({
          isAdm: this.onAdmNavigationMode,
          viewMode: 'grid',
          getDataMethod: evtParams.getDataMethod,
          backPagination: evtParams.backPagination,
          getDataFilters,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.tableRowData[evtParams.routeName] || evtParams.routeName,
            routeSubName: evtParams.linkStateConfig?.routeSubName,
            routeLink: evtParams.routeLink,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
        });
        this.$state.go(evtParams.routeLink, { tail: getDataFixedParams.id });

        break;
      }
      case 'streetView': {
        if (getDataFixedParams.paramsStreetView) {
          // 0. latitude, 1. longitude, 2. heading, 3. pitch, 4. zoom, 5. panoid
          const paramsStreetView = getDataFixedParams.paramsStreetView.split('|');
          window.open(
            'https://maps.google.com/maps?layer=c&q=' +
              `&cbll=${paramsStreetView[0]},${paramsStreetView[1]}` +
              `&cbp=11,${paramsStreetView[2]},0,` +
              `${paramsStreetView[4]},${paramsStreetView[3]}` +
              `&panoid=${paramsStreetView[5]}`,
            '_blank',
          );
        } else {
          window.open(
            'https://maps.google.com/maps?layer=c' +
              `&q=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              `&cbll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}` +
              '&cbp=11,0,0,0,0&z=17' +
              `&ll=${getDataFixedParams.latitude},${getDataFixedParams.longitude}`,
            '_blank',
          );
        }

        break;
      }
      case 'form': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.tableRowData[evtParams.routeName] || evtParams.routeName,
            routeSubName: evtParams.linkStateConfig?.routeSubName,
            routeLink: evtParams.linkStateConfig.type, // evtParams.routeLink,
            routeTail: getDataFixedParams.id,
            stateConfig: {
              ...this.stateConfig,
              getDataMethod: evtParams.getDataMethod,
              gridName: 'Formulário',
              filterConfig: [],
              getDataFixedParams,
            },
          },
        });

        this.$state.go(evtParams.linkStateConfig.type, {
          tail: getDataFixedParams.id,
        });

        break;
      }
      case 'drilldown': {
        const _granularity = ['mes', 'dia', 'hora'];
        const _momentMap = { mes: 'month', dia: 'day', hora: 'hour' };
        const navigationDateComponent = this.$.querySelector('power-navigation-date');
        let datePath = navigationDateComponent.getPath();

        const lastIndex = datePath.length - 1;
        const [_startDate, _endDate] = getDataFixedParams.date;
        const { granularity: actualGranularity } = datePath[lastIndex];
        const dataEndDate = moment(_endDate).utcOffset(-3);
        const dataStartDate = moment(_startDate).utcOffset(-3);
        const { minPath, maxGranularity } = navigationDateComponent.getConfig();

        let granularity = '';
        let format = '';

        if (
          actualGranularity === _granularity[minPath] ||
          actualGranularity === _granularity[lastIndex]
        ) {
          switch (actualGranularity) {
            case 'mes':
              granularity = 'dia';
              format = 'MMMM [de] YYYY';
              break;
            case 'dia':
              granularity = 'hora';
              format = 'DD [de] MMMM';
              break;
            case 'hora':
              granularity = 'unitario';
              format = 'DD-MM-YYYY - HH:mm';
              break;
            default:
              break;
          }

          if (actualGranularity === maxGranularity) {
            granularity = 'unitario';
          }

          datePath = [
            ...datePath,
            {
              endDate: moment(dataEndDate).endOf(_momentMap[granularity])._d,
              startDate: moment(dataStartDate).startOf(_momentMap[granularity])._d,
              description: moment(dataStartDate).format(format),
              granularity,
            },
          ];
        } else {
          switch (lastIndex) {
            case 0:
              granularity = 'mes';
              break;
            case 1:
              granularity = 'dia';
              break;
            case 2:
              granularity = 'hora';
              break;
            case 3:
              granularity = 'unitario';
              break;
            default:
              granularity = 'sumarizado';
              break;
          }

          datePath[lastIndex].granularity = granularity;
        }

        navigationDateComponent.setup({
          path: datePath,
          minPath: this.stateConfig.navigation.dateMinPath,
        });
        this.stateConfig.navigation.date = datePath;
        this.stateConfig.gridConfig.page = 1;

        this.$scope.$broadcast('getDataset', {
          filter: {
            conditions: this.stateConfig.filterConditions,
          },
          navigation: {
            date: datePath[datePath.length - 1],
          },
          isPaginated: this.stateConfig.gridConfig.backPagination,
          page: 1,
          ...this.stateConfig.getDataFixedParams,
        });

        break;
      }
      default: {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );

        const stateConfig = new ReportStateConfig({
          isAdm: this.onAdmNavigationMode,
          viewMode: 'grid',
          getDataMethod: evtParams.getDataMethod,
          backPagination: evtParams.backPagination,
          getDataFilters,
          getDataFixedParams,
        });

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const attr in evtParams.linkStateConfig) {
          stateConfig[attr] = evtParams.linkStateConfig[attr];
        }

        const routeConfig = await ImportRouteConfig(evtParams.routeLink) || {};
        const metadata = routeConfig ? routeConfig.__metadata__ : {};

        this.$ngRedux.dispatch({
          type: 'NEXT_ROUTE',
          data: {
            routeName: evtParams.tableRowData[evtParams.routeName] || evtParams.routeName,
            routeSubName: evtParams.linkStateConfig?.routeSubName,
            routeLink: evtParams.routeLink,
            routeTail: getDataFixedParams.id,
            stateConfig,
          },
          __metadata__: { ...metadata },
        });

        this.$state.go(evtParams.routeLink, { tail: getDataFixedParams.id });

        break;
      }
    }
  }

  _openImport() {
    const inputManualEntry = document.querySelector('#file-manual-entry');
    const labelManualEntry = document.querySelector('#label-manual-entry');

    this.closeReportControlMoreOptions = true;
    Object.assign(this.importData, { open: true, status: 0 });
    this.$.querySelector('#popup-import').toggle();

    inputManualEntry.onchange = () => {
      const { files } = inputManualEntry;
      if (files && files.length > 0) {
        const file = files[0];
        const { footer } = this.stateConfig.toolbarConfig.importConfig.popup;
        labelManualEntry.children[0].innerHTML = file.name;
        labelManualEntry.children[0].classList.add('has-file');
        if (file.name.includes('.xls') || file.name.includes('.xlsx')) {
          this.exportData.formData.append('importFile', file);
          if (labelManualEntry.nextElementSibling.style.display == 'block') {
            labelManualEntry.nextElementSibling.style.display = 'none';
          }
          footer.buttons = footer.buttons.map(ele =>
            Object.assign(ele, { disabled: ele.method != 'save' && ele.method != 'cancel' }),
          );
        } else {
          labelManualEntry.nextElementSibling.style.display = 'block';
          footer.buttons = footer.buttons.map(ele =>
            Object.assign(ele, { disabled: ele.method == 'save' }),
          );
        }
        this.$scope.$apply();
      } else this._clearImportManualEntry(inputManualEntry, labelManualEntry, true);
    };
  }

  _importMethod(method) {
    const labelManualEntry = document.querySelector('#label-manual-entry');
    const inputManualEntry = document.querySelector('#file-manual-entry');

    switch (method) {
      case 'cancel': {
        this.importData.open = false;
        this._clearImportManualEntry(inputManualEntry, labelManualEntry, false);
        this.$.querySelector('#popup-import').toggle();
        break;
      }
      case 'ok': {
        this.importData.open = false;
        this.$.querySelector('#popup-import').toggle();
        this.$scope.$broadcast('getDataset');
        break;
      }
      case 'reform': {
        this.importData.status = 0;
        this._clearImportManualEntry(inputManualEntry, labelManualEntry, false);
        break;
      }
      case 'save': {
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: true },
            bubbles: true,
            composed: true,
          }),
        );
        const methodUrl = this.stateConfig.toolbarConfig.importConfig.dataPostMethod;
        this.reportServices
          .sendFileManualEntry(methodUrl, this.exportData.formData)
          .then(
            () => {
              this.importData.status = 1;
              this._clearImportManualEntry(inputManualEntry, labelManualEntry, false);
            },
            ({ data }) => {
              if (data && data.hasError) {
                this.importData.status = 2;
                Object.assign(this.exportData.error, {
                  data: data.data,
                  countError: data.data.length,
                  fileName: labelManualEntry.children[0].innerHTML,
                });
              }
            },
          )
          .finally(() =>
            this.$.dispatchEvent(
              new CustomEvent('toggleLoader', {
                detail: { showLoader: false },
                bubbles: true,
                composed: true,
              }),
            ),
          );
        break;
      }
      default:
        break;
    }
  }

  _clearImportManualEntry(input, label, applyScope) {
    const { popup } = this.stateConfig.toolbarConfig.importConfig;
    label.children[0].innerHTML = 'Nenhum arquivo selecionado';
    label.children[0].classList.remove('has-file');
    input.value = '';
    this.exportData.formData.delete('importFile');
    popup.footer.buttons = popup.footer.buttons.map(ele =>
      Object.assign(ele, { disabled: ele.method == 'save' }),
    );
    if (applyScope) this.$scope.$apply();
  }

  // eslint-disable-next-line consistent-return
  _verifyColumnsVisibility() {
    if (this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders) {
      return this.stateConfig.gridConfig.gridHeaders.reduce((acc, header) => {
        if (header.fixed) return acc;
        if (!header.show) return false;
        return acc;
      }, true);
    }
  }

  _toggleAllColumnsVisibility() {
    if (this.stateConfig.gridConfig && this.stateConfig.gridConfig.gridHeaders) {
      const showAll = !this._verifyColumnsVisibility();
      this.stateConfig.gridConfig.gridHeaders = this.stateConfig.gridConfig.gridHeaders.map(
        header => Object.assign(header, { show: header.fixed ? header.show : showAll }),
      );
      this.$ngRedux.dispatch({ type: 'UPDATE_ROUTE' });
    }
  }

  _dayDiff(firstDate, secondDate) {
    return Math.abs(Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24)));
  }

  // REMOVER
  _exportAs(itemReturn) {
    this._exportEventHandler(itemReturn);
  }

  _saveEventHandler(data) {
    this.reportServices.saveUserReportConfiguration(data).then(success => {
      const { data: responseData } = success.data;

      if (success.status && success.status !== 200) {
        Object.assign(this, { toastText: responseData.text });
      } else {
        Object.assign(this, { toastText: `Relatório salvo com sucesso!` });
      }
      this.$.querySelector('power-toast#report-toast').toggle(true);
    });
  }

  _exportEventHandler(selectedItem) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader: true },
        bubbles: true,
        composed: true,
      }),
    );

    this.closeReportControlMoreOptions = true;

    const tableOffsetWidth = (document.querySelector('#report-body-grid table') || {}).offsetWidth;
    const requestTime = new Date();
    const payload = {
      name: this.currentState.routeName,
      columns: this.stateConfig.gridConfig.gridHeaders,
      request: {
        ...this.stateConfig.getDataFixedParams,
        isPaginated: true,
        page: 0,
        length: this.stateConfig.gridConfig.gridTotal,
        sort: {
          name: this.stateConfig.gridConfig.sortField,
          direction: this.stateConfig.gridConfig.sortDirection,
        },
        filter: {
          conditions: this.stateConfig.filterConditions,
        },
        exportOptions: {
          exportType: selectedItem.value || 'xlsx',
          digital: selectedItem.digital,
          offsetWidth: tableOffsetWidth || null,
        },
      },
      requestTime: new Date(requestTime - requestTime.getTimezoneOffset() * 60000),
    };

    return this.$http({
      url: `${this.urlApi}/${selectedItem.method}`,
      method: 'POST',
      data: payload,
    })
      .then(async success => {
        const blob = await (
          await fetch(`data:'application/octet-stream';base64,${success.data.data.contentBase64}`)
        ).blob();
        const fileUrl = window.URL.createObjectURL(blob, { type: success.data.data.contentType });
        const downloadLink = document.createElement('a');

        downloadLink.download = `${success.data.data.fileName}${success.data.data.extension}`;
        downloadLink.href = fileUrl;
        downloadLink.click();
      })
      .finally(() =>
        this.$.dispatchEvent(
          new CustomEvent('toggleLoader', {
            detail: { showLoader: false },
            bubbles: true,
            composed: true,
          }),
        ),
      );
  }

  _convertCalendarPresetToDates(preset) {
    const getDateWithoutUTF = (date, modifier = 1) =>
      moment(date).utcOffset((modifier * moment(date).utcOffset()) / 60);

    let endDate = moment()
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(999)
      .subtract(preset.interval[1], preset.intervalType);
    let startDate = moment()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .subtract(preset.interval[0], preset.intervalType);

    switch (preset.intervalType) {
      case 'years':
        endDate =
          preset.interval[1] === 0 ? endDate : endDate.month(11).date(endDate.daysInMonth());
        startDate = startDate.month(0).date(1);
        break;
      case 'months':
        endDate = preset.interval[1] === 0 ? endDate : endDate.date(endDate.daysInMonth());
        startDate = startDate.date(1);
        break;
      default:
        break;
    }

    return [getDateWithoutUTF(startDate)._d, getDateWithoutUTF(endDate)._d];
  }

  _getFilterDataKey(filter) {
    // eslint-disable-next-line no-nested-ternary
    return typeof filter.field === 'string'
      ? filter.field
      : filter.filters
      ? `${filter.type}-${Object.keys(filter.filters).join('-')}`
      : `${filter.type}-${Object.values(filter.field).join('-')}`;
  }

  _filterItemByModule(itemList) {
    return itemList.filter(
      item =>
        !item.validateModule || this.modules.some(module => item.validateModule.includes(module)),
    );
  }
  /* */

  /* Observers */
  __onRequestSyncVisualization(evt) {
    const cardElement = this.$.querySelector('#report-body-card');
    const gridElement = this.$.querySelector('#report-body-grid');

    if (cardElement && evt.target !== cardElement) {
      cardElement.scrollToIndex(evt.detail.index);
    }

    if (gridElement && evt.target !== gridElement) {
      gridElement.setActiveRow({ index: evt.detail.index });
      gridElement.scrollToIndex(evt.detail.index);
    }
  }
  /* */
}

class PowerReport {
  constructor() {
    this.template = template;
    this.transclude = {
      addActionSlot: '?addActionSlot',
      footerActionSlot: '?footerActionSlot',
    };
    this.bindings = {
      actualView: '=?',
      hasMapView: '=?',
      hasAddAction: '=?',
      hasFooterAction: '=?',
    };
    this.controller = PowerReportController;
  }
}

angular
  .module('power-report', [
    'ngRedux',
    'power-fab',
    'power-toolbar-report',
    'power-dropdown',
    'power-grid',
    'power-footer',
    'power-pagination',
    'power-popup',
    'power-crud',
    'power-popup-share',
    'power-popup-delete',
    'power-toast',
    'power-header-selector',
    'dndLists',
  ])
  .component('powerReport', new PowerReport());

export { PowerReportController };
