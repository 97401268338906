import angular from 'angular';

import template from './power-dropdown.html';
import './power-dropdown.scss';

class PowerDropdownController {
  static get $inject() {
    return ['$element'];
  }

  constructor($element) {
    Object.assign(this, { $: $element[0] });

    this.autoCloseEvent = this._autoClose.bind(this);
    this.queryMobile = window.matchMedia('(max-width: 425px)');
  }

  /* Lifecycle */
  $onInit() {
    Object.assign(this.$, {
      toggle: this.toggle.bind(this),
    });

    this._postionElement();
  }

  $onDestroy() {
    document.removeEventListener('click', this.autoCloseEvent);
  }
  /* */

  /* Public */
  toggle() {
    if (!this.$.hasAttribute('disabled')) {
      if (this.$.hasAttribute('open')) {
        this.$.dispatchEvent(new CustomEvent('close'));
        this.$.removeAttribute('open');

        document.removeEventListener('click', this.autoCloseEvent);
      } else {
        this._postionElement();
        this.$.dispatchEvent(new CustomEvent('open'));
        this.$.setAttribute('open', '');
        document.addEventListener('click', this.autoCloseEvent);
      }
    }
  }
  /* */

  /* Private */
  _postionElement() {
    const button = this.$.querySelector('.power-dropdown-button');
    const dropdown = this.$.querySelector('.power-dropdown');

    /* Column Position */
    if (this.positionTop) {
      dropdown.style.top = `${Number(this.positionTop)}px`;
    } else if (this.positionBottom) {
      dropdown.style.bottom = `${Number(this.positionBottom) + button.clientHeight}px`;
    }
    /* Row Position */
    if (this.positionLeft) {
      dropdown.style.left = `${Number(this.positionLeft)}px`;
    } else if (this.positionRight) {
      dropdown.style.right = `${Number(this.positionRight)}px`;
    }
  }

  _autoClose(evt) {
    const target = this._getEventTarget(evt);

    if (
      this._verifyEventTarget(target) ||
      target.hasAttribute('dropdown-auto-close') ||
      target.classList.contains('power-dropdown-container')
    ) {
      this.$.dispatchEvent(new CustomEvent('close'));

      // hotfix: verify event popup in mobile
      setTimeout(() => this.$.removeAttribute('open'), 100);

      document.removeEventListener('click', this.autoCloseEvent);
    }
  }

  _getEventTarget(evt) {
    let { target } = evt;

    if (evt.composed === true) {
      // eslint-disable-next-line no-restricted-syntax
      for (const element of evt.composedPath()) {
        if (element.parentElement !== null) {
          target = element;
          break;
        }
      }
    }

    return target;
  }

  _verifyEventTarget(target) {
    const fabElement = document.querySelector('power-fab');

    if (fabElement) {
      return (
        target !== this.$ &&
        this.$.contains(target) === false &&
        document.body.contains(target) &&
        !fabElement.contains(target)
      );
    }

    return target !== this.$ && this.$.contains(target) === false && document.body.contains(target);
  }
  /* */

  /* Observers */
  /* */
}

class PowerDropdown {
  constructor() {
    this.template = template;
    this.bindings = {
      positionTop: '@?',
      positionBottom: '@?',
      positionLeft: '@?',
      positionRight: '@?',
    };
    this.transclude = {
      buttonSlot: '?buttonSlot',
      headerSlot: '?headerSlot',
      searchSlot: '?searchSlot',
      listSlot: '?listSlot',
      footerSlot: '?footerSlot',
    };
    this.controller = PowerDropdownController;
  }
}

angular.module('power-dropdown', []).component('powerDropdown', new PowerDropdown());
