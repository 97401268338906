import angular from 'angular';
import moment from 'moment';
import * as Comlink from 'comlink';

import '@power/power-components/components/power-popup-list/power-popup-list';
import '@power/power-components/directives/ng-resize/ng-resize';
import { PowerGridController } from '@power/power-components/components/power-grid/power-grid';
import { AutoSendConfig } from '@power/power-components/components/power-popup-report-auto-send/power-popup-report-auto-send';
import { GetRouteConfig } from '../../configs/routes/routes.config';

import template from './golfleet-grid-reports.html';
import './golfleet-grid-reports.scss';

class GolfleetGridReportsController extends PowerGridController {
  static get $inject() {
    return [
      '$element',
      '$ngRedux',
      '$rootScope',
      '$scope',
      '$state',
      '$http',
      '$timeout',
      '$filter',
      'commonServices',
      'reportServices',
      'urlApi',
    ];
  }

  constructor(
    $element,
    $ngRedux,
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $filter,
    commonServices,
    reportServices,
    urlApi,
  ) {
    super($element, $scope, $state, $http, $timeout, $filter, commonServices, urlApi, $ngRedux);
    Object.assign(this, { $ngRedux, $rootScope, reportServices });

    this.__appBehavior = $ngRedux.connect(behavior => {
      const { userId, userEmail, userName, isTrip, isAdm } = behavior.session;
      const currentState = behavior.state.routeList[behavior.state.routeList.length - 1];

      return Object({
        sessionState: { userId, userEmail, userName, isTrip, isAdm },
        currentState: currentState || {},
        stateConfig: currentState ? currentState.stateConfig : {},
      });
    })(this);

    this.customActionsController = {
      linkToCustomReport: row => {
        const routeConfig = GetRouteConfig(row.module);

        if (routeConfig) {
          const stateParams = { isCustomReport: true, customReportId: row._id };

          const routeData = {
            ...routeConfig.data,
            stateConfig: routeConfig.data.stateConfig(stateParams),
          };

          this.$ngRedux.dispatch({
            type: 'NEXT_ROUTE_SINGLE',
            data: routeData,
          });

          this.$state.go(routeConfig.name, {}, { reload: true });
        }
      },
      linkToReport: row => {
        const routeConfig = GetRouteConfig(row.module);

        if (routeConfig) {
          const routeData = {
            ...routeConfig.data,
            stateConfig: routeConfig.data.stateConfig({
              isCustomReport: false,
              customReportId: '',
            }),
          };

          this.$state.go(routeConfig.name, {}, { reload: true });
          this.$ngRedux.dispatch({
            type: 'NEXT_ROUTE_SINGLE',
            data: routeData,
          });
        }
      },
      downloadsModal: () => {},
      autoSendsModal: row => {
        // data
        this._toogleLoader(true);
        this.reportServices
          .getAllAutoSendConfigurations({
            userReportConfigurationId: row._id,
          })
          .then(response => {
            if (response.status && response.status !== 200) {
              Object.assign(this, { _toastText: 'Ocorreu um erro inesperado.' });
              this._toogleLoader(false);
              return;
            }
            const { data } = response.data;
            this.savedAutoSendReports = [...data.map(item => AutoSendConfig.parse(item))];

            const listItems = this.savedAutoSendReports.map(item => ({
              _id: item._id,
              description: item.fileName,
              info: moment(item._data.updatedAt).format('DD-MM-YYYY'),
              icon: 'gs_autosend',
              data: item,
            }));

            const { __metadata__ } = GetRouteConfig(row.module);

            this.$.querySelector('#auto-send-popup-list-items').toggle();
            this.$.querySelector('#auto-send-popup-list-items').setListItems(listItems);

            this.$.querySelector('#power-popup-report-auto-send').setReportIcon(
              __metadata__.icon,
            );
            this._toogleLoader(false);
          });
      },
    };

    this.actualSlideEmpty = 1;
    this.slider = document.querySelector('.sliders');
    this.slides = [...this.slider.children];
    this.slides[0].setAttribute('active', '');

    if (this.worker) this.worker.terminate();
    this.worker = new Worker('./golfleet-grid-reports.worker.js');
    this.workerService = Comlink.wrap(this.worker);
  }

  /* Lifecycle */
  $onInit() {
    super.$onInit();

    this.$.querySelector('#power-popup-report-auto-send').addEventListener(
      'saved',
      this.getDataset.bind(this),
    );
  }

  $onDestroy() {
    super.$onDestroy();

    this.__appBehavior();
  }
  /**/

  _selectRow(column, row) {
    return (super._selectRow(column, row) || !!column.action) && !!row[column.field];
  }

  _customAction(options) {
    const defaults = { action: '', data: {} };
    const result = Object.assign(defaults, options);

    const customAction = this.customActionsController[result.action];
    if (customAction) {
      customAction(result.data);
    }
  }

  _autoSendListItemClick(data) {
    this.$.querySelector('#power-popup-report-auto-send')
      .loadData(data)
      .then(() => {
        setTimeout(this.$.querySelector('#power-popup-report-auto-send').toggle, 300);
      });
  }

  _toogleLoader(showLoader) {
    this.$.dispatchEvent(
      new CustomEvent('toggleLoader', {
        detail: { showLoader },
        bubbles: true,
        composed: true,
      }),
    );
  }

  _getImage(name) {
    const src = '/assets/images/gs-reports-tips/';
    return src.concat(name);
  }

  _changePageEmpty() {
    const activeIndex = this.slides.indexOf(this.slider.querySelector('[active]'));
    this.$.querySelectorAll('[active]').forEach(slide => slide.removeAttribute('active'));

    if (activeIndex + 1 !== this.slides.length) {
      this.slides[activeIndex + 1].setAttribute('active', '');
      this.actualSlideEmpty = activeIndex + 1;
    } else {
      this.slides[0].setAttribute('active', '');
      this.actualSlideEmpty = 0;
    }
  }
}

class GolfleetGridReports {
  constructor() {
    this.template = template;
    this.bindings = {
      /* common */
      datasetMethod: '=?',
      isPaginated: '=?',
      page: '=?',
      pageSize: '=?',
      pageRows: '=?',
      lastPage: '=?',
      datasetLength: '=?',
      hasRowSelection: '=?',
      dateGranularity: '=?',
      /* underscore */
      gridHeaders: '=?',
      gridDataset: '=?',
      gridHeadersCategories: '=?',
      sortHeader: '=?',
      sortDirection: '=?',
      selectedRows: '=?',
      /* duble underscore */
      mainHeader: '=?',
      mongoGridId: '=?',
      headerParams: '=?',
      datasetParams: '=?',
    };
    this.controller = GolfleetGridReportsController;
  }
}

angular
  .module('golfleet-grid-reports', [
    'power-popup-list',
    'ng-resize',
    'power-popup-report-auto-send',
  ])
  .component('golfleetGridReports', new GolfleetGridReports());
